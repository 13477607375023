import React from 'react';
import styled from 'styled-components';
import { EllipsisTooltipSpan, GetColor, Loading, TooltipPosition } from 'venn-ui-kit';
import Row from './Row';
import isEqual from 'lodash/isEqual';
import { Table as VirtualizedTable } from 'react-virtualized/dist/commonjs/Table';
import { useWindowSize } from 'venn-utils';
import { DATE_COL_MIN_WIDTH, HEADER_HEIGHT, NUMBER_COL_WIDTH, NumberCol, ROW_HEIGHT, VALUE_COL_WIDTH } from './styles';
import { ConditionalOverlay } from '../../conditional-overlay';
import { AutoSizer } from 'react-virtualized';

interface DataEditorContentProps {
  saving?: boolean;
  isReturn: boolean;
  stickyHeaderOffset?: number;

  // see corresponding props in UseDataEditorReturn for definitions of props below
  originalData: string[][];
  dataModifications: Map<number, string[]>;
  newRows: string[][];
  handleChangedCell: (coordinate: { x: number; y: number }, value: string) => void;
  handlePaste: (x: number, y: number, pastedData: string[][]) => void;
  /**
   *  When true the DataEditorContent cannot be edited and appears greyed out
   */
  disabled?: boolean;
  /**
   *  When the DataEditorContent is disabled this content will be displayed in each Row's Cell tooltip
   */
  disabledTooltipContent?: string;
  minHeight?: number; // Min height in pixels.
}

const HEADER_COL_PADDING = 10;

const getEditorWidth = (isEditingDisabled: boolean, resizeDetectorWidth?: number) => {
  const minWidth = NUMBER_COL_WIDTH + DATE_COL_MIN_WIDTH + VALUE_COL_WIDTH;
  const nonNilWidth = resizeDetectorWidth ?? minWidth;
  // Enforce minWidth if editable so that the "Click to add new date" prompt fits in 1 line.
  // Don't enforce if disabled so that the frequency toggle doesn't overlap with the editor.
  return isEditingDisabled ? nonNilWidth : Math.max(minWidth, nonNilWidth);
};

const DataEditorContent = ({
  saving,
  handleChangedCell,
  handlePaste,
  isReturn,
  originalData,
  dataModifications,
  newRows,
  stickyHeaderOffset = 0,
  disabled = false,
  minHeight = 0,
  disabledTooltipContent,
}: DataEditorContentProps) => {
  const { height: windowHeight } = useWindowSize();

  if (!originalData[0]) {
    return null;
  }

  /* When disabled to not add any new rows at the bottom of the table.
   * These rows are used by the user to input additional data.  */
  if (disabled) {
    newRows.length = 0;
  }

  const remainingHeight = windowHeight - stickyHeaderOffset;
  const rowCountWithHeader = originalData.length + newRows.length;
  // try to expand the height as necessary, but don't be too short either
  const editorHeight =
    rowCountWithHeader < remainingHeight / ROW_HEIGHT ? rowCountWithHeader * ROW_HEIGHT : remainingHeight;
  const height = Math.max(editorHeight, minHeight);

  // -1 for the header in originalData[0] and another -1 for 0-indexing
  const lastIndex = rowCountWithHeader - 2;

  // in many of the props below, we need to add 1 to the index because of the header in originalData[0]
  return (
    <ConditionalOverlay condition={!!saving} center overlay={<Loading title="Saving" />}>
      <AutoSizer disableHeight>
        {({ width }) => {
          // @ts-expect-error: fixme
          const rowRenderer = ({ className, style, index, rowData, key }) => (
            <Row
              className={className}
              style={{ ...style, width }}
              key={key}
              y={index + 1}
              rowData={rowData}
              originRowData={originalData[index + 1] ?? ['', '']}
              onChangedValue={handleChangedCell}
              handlePaste={handlePaste}
              isNewRow={index === lastIndex}
              isReturn={isReturn}
              disabled={disabled}
              disabledTooltipContent={disabledTooltipContent}
            />
          );

          // @ts-expect-error: fixme
          const headerRowRenderer = ({ className, style }) => (
            <HeaderRow className={className} style={style}>
              <HeaderNumberCol />
              <HeaderDateCol>{originalData[0]![0]!}</HeaderDateCol>
              <HeaderCol>
                <EllipsisTooltipSpan
                  maxWidth={VALUE_COL_WIDTH - HEADER_COL_PADDING}
                  position={TooltipPosition.Bottom}
                  usePortal
                >
                  {originalData[0]![1]!}
                </EllipsisTooltipSpan>
              </HeaderCol>
            </HeaderRow>
          );

          return (
            <VirtualizedTable
              scrollToIndex={lastIndex}
              height={height}
              width={getEditorWidth(disabled, width)}
              rowCount={rowCountWithHeader - 1}
              rowGetter={({ index }) =>
                index + 1 < originalData.length
                  ? (dataModifications.get(index + 1) ?? originalData[index + 1])
                  : newRows[index + 1 - originalData.length]
              }
              rowHeight={ROW_HEIGHT}
              headerHeight={HEADER_HEIGHT}
              rowRenderer={rowRenderer}
              headerRowRenderer={headerRowRenderer}
            />
          );
        }}
      </AutoSizer>
    </ConditionalOverlay>
  );
};

export default React.memo(DataEditorContent, isEqual);

const HeaderRow = styled.div`
  display: flex;
`;

const HeaderNumberCol = styled(NumberCol)`
  border-bottom: 1px solid ${GetColor.Black};
  height: ${HEADER_HEIGHT}px;
`;

const HeaderCol = styled.div`
  background-color: ${GetColor.DEPRECATED_DivergingColor.MID};
  border-bottom: 1px solid ${GetColor.Black};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 ${HEADER_COL_PADDING}px;
  width: ${VALUE_COL_WIDTH}px;
  height: ${HEADER_HEIGHT}px;
`;

const HeaderDateCol = styled(HeaderCol)`
  border-right: 2px solid ${GetColor.Black};
  width: calc(100% - ${VALUE_COL_WIDTH + NUMBER_COL_WIDTH}px);
`;
