import type { AssetTypeEnum, CustomizedBlock } from 'venn-api';
import type { CustomizableBlockSetting } from 'venn-utils';
import AnalysisSubject from '../../analysis/AnalysisSubject';
import mockFund from './fund';
import mockFundWithBenchmark from './fundWithBenchmark';
import mockPortfolio from './portfolio';

export const mockAnalysisSubjectInvestment: AnalysisSubject = new AnalysisSubject(mockFund, 'investment');

export const getMockAnalysisSubjectInvestment = (id: string): AnalysisSubject =>
  new AnalysisSubject({ ...mockFund, id }, 'investment');

export const getMockAnalysisSubjectPortfolio = (id: number): AnalysisSubject =>
  new AnalysisSubject({ ...mockPortfolio, id }, 'portfolio');

export const mockAnalysisSubjectCompositeBenchmark: AnalysisSubject = new AnalysisSubject(
  { ...mockFund, assetType: 'BENCHMARK' as AssetTypeEnum },
  'investment',
);

export const mockAnalysisSubjectInvestmentWithBenchmark: AnalysisSubject = new AnalysisSubject(
  mockFundWithBenchmark,
  'investment',
);

export const mockAnalysisSubjectPortfolio: AnalysisSubject = new AnalysisSubject(mockPortfolio, 'portfolio');

export const mockPortfolioSubjectWithComparison: AnalysisSubject = new AnalysisSubject(mockPortfolio, 'portfolio', {
  secondaryLabel: 'Last Saved',
  secondaryPortfolio: mockPortfolio,
});

export const mockAnalysisSubjectPortfolioWithStrategy: AnalysisSubject = new AnalysisSubject(
  mockPortfolio,
  'portfolio',
  { strategyId: mockPortfolio.children[0]!.id },
);

export const mockEmptyCustomizedBlock: CustomizedBlock = {
  contributionToPercentage: false,
  relativeToBenchmark: false,
  selectedFactors: [],
  selectedMetrics: [],
};

export const mockEmptyCustomizableBlockSetting = {
  accessFeatures: [],
  contributionToPercentage: false,
  customBlockType: 'PERFORMANCE_SUMMARY',
  hasFactors: false,
  id: '',
  metrics: [],
  relativeToBenchmark: false,
  title: '',
  supportsPublicInvestments: true,
  supportsPrivateInvestments: false,
} as unknown as CustomizableBlockSetting;
