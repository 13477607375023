import React from 'react';
import { isEmpty } from 'lodash';
import { useAnalysis } from '../../logic/useAnalysis';
import type { AllSubjectsErrorMessages } from '../blocks/public-private';
import { PublicPrivateErrorMessages } from '../PrivatesErrorMessages';
import type { ErrorWrapper } from './errorWrapperTypes';

const getErrorsOnly = (subjectMessages?: AllSubjectsErrorMessages) => {
  if (!subjectMessages) {
    return [];
  }

  return Object.fromEntries(
    Object.entries(subjectMessages)
      .map(([key, messages]) => [key, messages.filter((message) => message.message.type === 'ERROR')])
      .filter(([_key, messages]) => messages!.length > 0),
  );
};

export const PublicPrivateErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const { analyses } = useAnalysis(blockId);
  const subjectMessages = analyses?.[0]?.[0]?.messagesWithContext;

  const errorsOnly = getErrorsOnly(subjectMessages);
  if (!isEmpty(errorsOnly)) {
    return <PublicPrivateErrorMessages errorMessages={errorsOnly} />;
  }

  return children;
};
