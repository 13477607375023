import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import type { BlockId } from './types';
import { resetOnStudioReset } from '../effects/signalEffects';

export const studioBlockHeight = atomFamily<number, BlockId>({
  key: 'studioBlockHeight',
  default: 0,
});

export const wrappedStudioBlockHeight = selectorFamily<number, BlockId>({
  key: 'lol',
  get:
    (id) =>
    ({ get }) =>
      get(studioBlockHeight(id)),
});

/** All block IDs in the entire view. */
export const allBlockIdsState = atom<BlockId[]>({
  key: 'allBlockIdsState',
  default: [],
  effects: [resetOnStudioReset],
});

export const studioBlockCustomWidth = atomFamily<number, BlockId>({
  key: 'studioBlockCustomWidth',
  default: 1.0,
  effects: [],
});

/**
 * Represents the rows and columns of blocks for the studio scroll
 * Blocks will be packed into the same row while the total block width adds less than 1
 */
export const studioBlockLayout = selector<BlockId[][]>({
  key: 'studioBlockLayout',
  get: ({ get }) => {
    const allBlocks = get(allBlockIdsState);
    const layout: BlockId[][] = [];
    allBlocks.forEach((id) => {
      if (
        layout.length > 0 &&
        layout[layout.length - 1]!.reduce(
          (prev, cur) => get(studioBlockCustomWidth(cur)) + prev,
          get(studioBlockCustomWidth(id)),
        ) <= 1
      ) {
        layout[layout.length - 1]!.push(id);
      } else {
        layout.push([id]);
      }
    });
    return layout;
  },
});
