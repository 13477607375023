import React from 'react';

import { useRecoilValue } from 'recoil';

import { blockDisplayHeader, blockInfoGraphicType, blockSettings, customizedBlock, isReportState } from 'venn-state';

import { Block } from '../../components/core';

import { PrivateDateRange } from '../../components/secondary/date-ranges';

import { useAppPrintMode } from '../../../print';
import { DefaultSubjects } from '../../components/secondary/subjects/default-subjects';
import { getDateBehavior } from 'venn-utils';
import { DataModificationsButton } from '../../components/secondary/buttons/data-modifications-button';
import { usePrivateAnalysisBlockDataModifications } from '../../../modals';

interface PrivateCashFlowHeaderProps {
  id: string;
}

export const PrivateCashFlowHeader = ({ id }: PrivateCashFlowHeaderProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);
  const { selectedMetrics, subHeader } = useRecoilValue(customizedBlock(id));
  const blockSetting = useRecoilValue(blockSettings(id));
  const blockType = blockSetting?.customBlockType;
  const blockGraphicType = useRecoilValue(blockInfoGraphicType(id));
  const dateBehavior = getDateBehavior(blockSetting.customBlockType);
  const modifications = usePrivateAnalysisBlockDataModifications(id)?.modifications;

  return (
    <>
      <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline id={id} isReport={isReport}>
            {title}
          </Block.Headline>
          {subHeader && <Block.SubHeadline id={id}>{subHeader}</Block.SubHeadline>}
        </Block.Title>

        <Block.Metadata id={id}>
          <PrivateDateRange
            id={id}
            dateBehavior={dateBehavior}
            blockType={blockType}
            blockGraphicType={blockGraphicType}
          />
          <DataModificationsButton modifications={modifications} />
        </Block.Metadata>
      </Block.HeaderTopContainer>

      <DefaultSubjects id={id} metric={selectedMetrics[0]! || ''} relativeToBenchmark={false} />
    </>
  );
};
