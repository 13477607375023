import React, { useContext } from 'react';
import { FundListItem, ListItemConstraining, ListItemWithFrequency, ListItemWithReturns, QuickAction } from './styled';
import ExpandableList from './ExpandableList';
import type { ConstrainingInvestment } from '../PortfolioRangesUtils';
import type { ListItemType } from './InvestmentWithInlineActions';
import InvestmentWithInlineActions from './InvestmentWithInlineActions';
import type { PortfolioRange } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import RangeDebugContext from '../../../contexts/range-debug-context';

interface RangeItemsListProps {
  subject: AnalysisSubject;
  includeBenchmark: boolean;
  includeSecondary: boolean;
  investments: ConstrainingInvestment[];
  portfolioRange: PortfolioRange;
  infoType: ListItemType;
  availableStart: number;
  availableEnd: number;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  includeBenchmark,
  includeSecondary,
  investments,
  portfolioRange,
  infoType,
  availableStart,
  availableEnd,
}: RangeItemsListProps) => {
  const { onClearBenchmark, onClearComparison, onClearCategory, onFundDataUpdated, onDeleteFundFromPortfolio } =
    useContext(RangeDebugContext);

  const ListItem =
    infoType === 'frequency'
      ? ListItemWithFrequency
      : infoType === 'returns'
        ? ListItemWithReturns
        : ListItemConstraining;

  return (
    <ExpandableList initiallyShown={3}>
      {includeBenchmark && (
        <ListItem
          name="Benchmark"
          label={portfolioRange.benchmark.name}
          range={portfolioRange.benchmark}
          availableStart={availableStart}
          availableEnd={availableEnd}
          suffix=". "
        >
          <QuickAction onClick={onClearBenchmark}>Click to remove benchmark.</QuickAction>
        </ListItem>
      )}
      {includeSecondary && (
        <ListItem
          name={
            subject.type === 'portfolio'
              ? getSecondaryDisplayLabel(subject, 'Portfolio as of', 'Portfolio')
              : 'Category'
          }
          label={portfolioRange.secondary.name}
          range={portfolioRange.secondary}
          availableStart={availableStart}
          availableEnd={availableEnd}
          suffix=". "
        >
          {subject.type === 'portfolio' ? (
            <QuickAction onClick={onClearComparison}>Click to remove comparison portfolio.</QuickAction>
          ) : (
            <QuickAction onClick={onClearCategory}>Click to turn category off.</QuickAction>
          )}
        </ListItem>
      )}
      {!includeBenchmark &&
        !includeSecondary &&
        investments.length > 0 &&
        (subject.type === 'portfolio' ? (
          <FundListItem>
            Investments in your portfolio:
            <ExpandableList initiallyShown={3}>
              {investments.map(({ range }: ConstrainingInvestment) => (
                <InvestmentWithInlineActions
                  key={range.id}
                  infoType={infoType}
                  subject={subject}
                  fundId={range.id}
                  fundName={range.name}
                  range={range}
                  onFundDataUpdated={onFundDataUpdated}
                  onDeleteFundFromPortfolio={onDeleteFundFromPortfolio}
                  availableStart={availableStart}
                  availableEnd={availableEnd}
                />
              ))}
            </ExpandableList>
          </FundListItem>
        ) : (
          <InvestmentWithInlineActions
            infoType={infoType}
            subject={subject}
            fundId={subject.fund!.id}
            fundName={subject.name}
            label="Current Investment"
            range={portfolioRange.investments[0]!}
            onFundDataUpdated={onFundDataUpdated}
            onDeleteFundFromPortfolio={onDeleteFundFromPortfolio}
            availableStart={availableStart}
            availableEnd={availableEnd}
          />
        ))}
    </ExpandableList>
  );
};
