import type { SingleCustomOptionMenuItem } from 'venn-ui-kit';
import { isFactorTrendMetric } from '../factors/factorUtils';

export const PERIODS: SingleCustomOptionMenuItem[] = [
  { value: 6, label: '6M' },
  { value: 12, label: '1Y' },
  { value: 3 * 12, label: '3Y' },
  { value: 5 * 12, label: '5Y' },
  { value: 9 * 12, label: '9Y' },
];

export const getDefaultPeriod = (isFactor: boolean) => {
  if (isFactor) {
    return PERIODS[0]!;
  }
  return PERIODS[1]!;
};

export const isRollingMetric = (selectedMetrics: string[] = []) =>
  selectedMetrics?.length === 1 && selectedMetrics.find((m) => m.toLowerCase().includes('rolling'));

export const getExportRollingPeriod = (selectedMetrics: string[] = [], rollingYears?: number) => {
  const isFactorTrend = isFactorTrendMetric(selectedMetrics);
  const isRolling = isRollingMetric(selectedMetrics);
  if (!isFactorTrend && !isRolling) {
    return '--';
  }
  return getSelectedRollingPeriod(rollingYears, isFactorTrend).label;
};

export const getSelectedRollingPeriod = (
  rollingYears?: number,
  isFactorTrend?: boolean,
): SingleCustomOptionMenuItem => {
  const rollingPeriodMonths = rollingYears ? rollingYears * 12 : undefined;
  return rollingYears
    ? PERIODS.find(({ value }) => value === rollingPeriodMonths) || {
        label: `${rollingPeriodMonths}M`,
        value: rollingYears,
        isCustom: true,
      }
    : getDefaultPeriod(!!isFactorTrend);
};
