import { useCallback, useEffect, useMemo, useState } from 'react';
import type { UserData } from './logic';
import { checkHasError, convertStringToTimeseries, convertType, convertValues } from './logic';
import isEqual from 'lodash/isEqual';
import { logMessageToSentry } from 'venn-utils';

export interface UseDataEditorReturn {
  /** The original data that was passed in, converted to string values */
  originalData: string[][];
  /** Modifications to the original data by row index, if any */
  dataModifications: Map<number, string[]>;
  /**
   * New rows that were appended to the original data, guaranteed to have an incomplete row (e.g. ['', ''] or ['', '2'] or ['1/1/10', ''])
   * as its last element to allow entry of more new rows
   */
  newRows: string[][];
  /**
   * Handler for non-paste changes to the cell on row with index y and column with index x.
   * Index y for new rows start at originalData.length and end at originalData.length + newRows.length - 1
   */
  handleChangedCell: (coordinate: { x: number; y: number }, value: string) => void;
  /**
   * Handle a table of pasted changes with starting point at the cell on row with index y and column with index x.
   * Overwrites existing values spanned by the pasted data and creates new rows for the rest.
   * Index y for new rows start at originalData.length and end at originalData.length + newRows.length - 1
   */
  handlePaste: (x: number, y: number, pastedData: string[][]) => void;
  isEdited: boolean;
  hasError: boolean;
  onSave: () => void;
  resetData: () => void;
}

export const useDataEditor = (userData: UserData, onComplete: (data: number[][]) => void): UseDataEditorReturn => {
  const originalData = useMemo(() => convertValues(userData), [userData]);
  const [newRows, setNewRows] = useState<string[][]>([['', '']]);
  const [dataModifications, setDataModifications] = useState<Map<number, string[]>>(new Map());

  useEffect(() => {
    setNewRows([['', '']]);
    setDataModifications(new Map());
  }, [originalData]);

  /** Requires 0 <= y < data.length */
  const updateOriginalCell = useCallback(
    (x: number, y: number, updatedValue: string) => {
      // Make sure to make a copy before modifying
      const modifiedRow = [...(dataModifications.get(y) ?? originalData[y]!)];
      modifiedRow[x] = updatedValue;
      // remove it from modifications if it's now the same as the original data
      if (isEqual(modifiedRow, originalData[y])) {
        setDataModifications((prev) => {
          const updatedModifications = new Map(prev);
          updatedModifications.delete(y);
          return updatedModifications;
        });
      } else {
        setDataModifications((prev) => new Map(prev).set(y, modifiedRow));
      }
    },
    [dataModifications, originalData],
  );

  const updateNewRowCell = useCallback(
    (x: number, y: number, value: string) => {
      if (y >= newRows.length) {
        logMessageToSentry(
          `Tried to update new row index that's too large: ${y} (largest index ${newRows.length - 1})`,
        );
        return;
      }
      const modifiedNewRow = [...newRows[y]!];
      modifiedNewRow[x] = value;
      const updatedNewRows = [...newRows.slice(0, y), modifiedNewRow, ...newRows.slice(y + 1, newRows.length)];
      // append an empty row if the last row is now complete
      if (y === newRows.length - 1 && modifiedNewRow[0] !== '' && modifiedNewRow[1] !== '') {
        updatedNewRows.push(['', '']);
      }
      setNewRows(updatedNewRows);
    },
    [newRows],
  );

  const handleChangedCell = useCallback(
    // @ts-expect-error: fixme
    ({ x, y }, value) => {
      if (y >= originalData.length) {
        updateNewRowCell(x, y - originalData.length, value);
      } else {
        updateOriginalCell(x, y, value);
      }
    },
    [originalData.length, updateNewRowCell, updateOriginalCell],
  );

  const handlePaste = useCallback(
    (x: number, y: number, pastedData: string[][]) => {
      if (!pastedData || pastedData.length === 0) {
        return;
      }

      // Just copy paste one cell won't override all of them
      if (pastedData.length === 1 && pastedData[0]!.length === 1) {
        // Update existing row
        if (y < originalData.length) {
          updateOriginalCell(x, y, pastedData[0]![0]!);
          return;
        }
        updateNewRowCell(x, y - originalData.length, pastedData[0]![0]!);
        return;
      }

      // Handle multi-cell / multi-row changes
      // First handle pasted changes that include original data
      if (y < originalData.length) {
        setDataModifications((prev) =>
          pastedData
            .slice(0, originalData.length - y)
            .reduce((modifications, value, i) => modifications.set(y + i, value), new Map(prev)),
        );

        // and changes that could also include new data
        if (pastedData.length > originalData.length - y) {
          const dataForNewRows = pastedData.slice(originalData.length - y, pastedData.length);
          // if all data in newRows is from pastedData, make sure we add an empty row at the end
          const restData =
            dataForNewRows.length >= newRows.length ? [['', '']] : newRows.slice(dataForNewRows.length + 1);
          setNewRows([...dataForNewRows, ...restData]);
        }
        return;
      }
      // Then handle pasted changes on only the new data
      setNewRows([...newRows.slice(0, y - originalData.length), ...pastedData, ['', '']]);
    },
    [originalData.length, updateNewRowCell, updateOriginalCell, newRows],
  );

  const isEdited = useMemo(
    () => !!(dataModifications.size || newRows.find((row) => !isEqual(row, ['', '']))),
    [dataModifications, newRows],
  );

  const hasError = useMemo(() => {
    return checkHasError(newRows) || checkHasError([...dataModifications.values()]);
  }, [dataModifications, newRows]);

  const onSave = useCallback(() => {
    const updatedData = [...originalData.map((value, y) => dataModifications.get(y) ?? originalData[y]!), ...newRows];
    const parseData = convertStringToTimeseries(updatedData, convertType(userData.dataType));
    onComplete(parseData);
  }, [originalData, dataModifications, newRows, onComplete, userData.dataType]);

  const resetData = useCallback(() => {
    setDataModifications(new Map());
    setNewRows([['', '']]);
  }, []);

  return {
    originalData,
    handleChangedCell,
    handlePaste,
    isEdited,
    hasError,
    onSave,
    resetData,
    dataModifications,
    newRows,
  };
};

export default useDataEditor;
