import React from 'react';

import { getRequestSubjectFromAnalysisSubject, isHistoricalSubject } from 'venn-utils';
import { blockBenchmarkSubjects, analysisSubjects, isReportState } from 'venn-state';
import { ItemType } from 'venn-ui-kit';

import { Block } from '../../core';
import { useRecoilValue } from 'recoil';
import { HistoricalPortfolioStudioHeaderTooltips } from '../historical-portfolio/HistoricalPortfolioStudioHeaderTooltips';
import { Name } from '../legend';

interface IndividualBenchmarkProps {
  id: string;
}

export const IndividualBenchmark = ({ id }: IndividualBenchmarkProps) => {
  const allBenchmarks = useRecoilValue(analysisSubjects(useRecoilValue(blockBenchmarkSubjects(id))));
  const individualBenchmark = (() => {
    if (allBenchmarks[0]) {
      return getRequestSubjectFromAnalysisSubject(allBenchmarks[0]);
    }
    return undefined;
  })();
  const isStudio = !useRecoilValue(isReportState);

  return (
    <>
      <Block.SubjectsItem iconType={ItemType.CommonBenchmark}>
        {individualBenchmark ? <Name id={id} subject={individualBenchmark} /> : 'No Benchmark'}
      </Block.SubjectsItem>
      {individualBenchmark && isHistoricalSubject(individualBenchmark) && isStudio && (
        <HistoricalPortfolioStudioHeaderTooltips blockId={id} subject={individualBenchmark} />
      )}
    </>
  );
};
