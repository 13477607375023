import React, { useContext } from 'react';
import {
  AnalysisConfigContext,
  FundCorrelationTable,
  getFailedAnalysisTrackingProps,
  PrintContainerDimensions,
} from 'venn-components';
import type { AnalysisBlockTypeEnum } from 'venn-api';
import { PRINT_NARROW_BLOCKS, getBlocksExceptPerformance } from 'venn-utils';
import type { SharedProps } from '../types';
import FactorAnalysis from './FactorAnalysisLayout';
import { tearsheetTabs } from '../configs';
import Loading from './Loading';
import { ComponentFromBlock } from '../blocks';
import { Section, SectionHeadline, BlockRow, BlockCell } from 'venn-ui-kit';

const Tearsheet = (props: SharedProps) => {
  const {
    analyses,
    analysisConfig,
    factorLenses,
    updateAnalysisStatusForTracking,
    onResetTimeFrame,
    downloadMetaData,
  } = props;
  const { relative, subject, category, trackingId, selectedPeriod, selectedTimeFrame } = analysisConfig;
  const { actualTimeFrame } = useContext(AnalysisConfigContext);

  if (!analyses || !analyses.results || !factorLenses || factorLenses.length === 0) {
    return <Loading />;
  }
  // Filter out blocks for performancy summary, factor and correlation
  const blocks = getBlocksExceptPerformance(analysisConfig);
  const residualAnalysisBlockRows = blocks.filter(
    (block) => block === 'RESIDUAL_PERFORMANCE_SUMMARY' || block === 'CUMULATIVE_RESIDUAL_RETURN',
  );
  const stressTestingBlockRows = blocks.filter(
    (block) =>
      block.includes('DRAWDOWN') ||
      block === 'SCENARIO' ||
      block === 'NOTABLE_HISTORICAL_PERIODS' ||
      block === 'HISTORICAL_DRAWDOWN_AND_RALLY_SCANNER',
  );
  const performanceBlocks = blocks.filter(
    (block) =>
      !block.toUpperCase().includes('FACTOR') &&
      block !== 'CORRELATION' &&
      !residualAnalysisBlockRows.includes(block) &&
      !stressTestingBlockRows.includes(block),
  );
  const performanceBlockRows: AnalysisBlockTypeEnum[][] = [];
  for (const performanceBlockName of performanceBlocks ?? []) {
    const lastRow = performanceBlockRows ? performanceBlockRows[performanceBlockRows.length - 1] : null;
    if (
      lastRow &&
      lastRow.length === 1 &&
      PRINT_NARROW_BLOCKS.includes(lastRow[0]!) &&
      PRINT_NARROW_BLOCKS.includes(performanceBlockName)
    ) {
      lastRow.push(performanceBlockName);
    } else {
      performanceBlockRows.push([performanceBlockName]);
    }
  }

  const analysesPeriod = analyses?.analysesPeriod;
  const correlationTrackingProps = getFailedAnalysisTrackingProps(
    analysisConfig?.analysisTemplate?.name || '',
    `${relative ? 'Relative ' : ''}Correlation`,
    actualTimeFrame ?? selectedTimeFrame,
    analysesPeriod,
    undefined,
    subject,
    relative,
    'CORRELATION',
    trackingId,
    updateAnalysisStatusForTracking,
  );
  const canSeeCorrelation = subject || !blocks.includes('CORRELATION');
  const canSeeResidualAnalysis = residualAnalysisBlockRows.length > 0;

  return (
    <>
      <Section id={tearsheetTabs.PERFORMANCE_ANALYSIS}>
        <SectionHeadline>Performance & Risk</SectionHeadline>
        {performanceBlockRows.map((row: AnalysisBlockTypeEnum[], rowIndex: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <BlockRow key={rowIndex} avoidPageBreak>
            {row.map((block: AnalysisBlockTypeEnum, colIndex: number) => (
              <BlockCell key={block} col={colIndex} numCellsInRow={row.length}>
                <ComponentFromBlock block={block} sharedProps={props} />
              </BlockCell>
            ))}
          </BlockRow>
        ))}
        {canSeeCorrelation && (
          <PrintContainerDimensions>
            {({ width }) => (
              <FundCorrelationTable
                subject={subject}
                benchmarkRelative={relative}
                selectedTimeFrame={selectedTimeFrame}
                selectedPeriod={selectedPeriod}
                width={width}
                categoryActive={category === 'ON'}
                trackingProps={correlationTrackingProps}
                onResetTimeFrame={onResetTimeFrame}
                downloadMetaData={downloadMetaData}
              />
            )}
          </PrintContainerDimensions>
        )}
      </Section>
      <Section id={tearsheetTabs.FACTOR_ANALYSIS} pageBreak>
        <SectionHeadline>Factor Analysis</SectionHeadline>
        <FactorAnalysis {...props} />
      </Section>
      {canSeeResidualAnalysis && (
        <Section id={tearsheetTabs.RESIDUAL_ANALYSIS} pageBreak>
          <SectionHeadline>Residual Analysis</SectionHeadline>
          {residualAnalysisBlockRows.map((block: AnalysisBlockTypeEnum, rowIndex: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <BlockRow key={rowIndex} avoidPageBreak>
              <ComponentFromBlock block={block} sharedProps={props} />
            </BlockRow>
          ))}
        </Section>
      )}
      <Section id={tearsheetTabs.STRESS_TESTING} pageBreak>
        <SectionHeadline>Stress Testing</SectionHeadline>
        {stressTestingBlockRows.map((block: AnalysisBlockTypeEnum, rowIndex: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <BlockRow key={rowIndex} avoidPageBreak>
            <ComponentFromBlock block={block} sharedProps={props} />
          </BlockRow>
        ))}
      </Section>
    </>
  );
};

export default Tearsheet;
