import React from 'react';
import { Body1, getAppTitle, Radio, Headline3, Button, GetColor, IconPosition } from 'venn-ui-kit';
import styled from 'styled-components';
import type { AddeparOption } from 'venn-api';
import type { AddeparViewConfigurationMainState, ViewErrors } from './useAddeparViewConfiguration';
import EditViewConfiguration from './EditViewConfiguration';
import { ConditionalOverlay } from 'venn-components';
import { DEFAULT_RETURN_KEY } from './groupings';
import CollapedView from './CollapedView';
import { analyticsService } from 'venn-utils';
import { STEPS } from './steps';
import { RowSpaceBetween } from './styles';

const appTitle = getAppTitle();
const getAddeparAttributeConfigurationUrl = (addeparFirmBaseUrl: string) =>
  `https://${addeparFirmBaseUrl}/app/settings/attributes`;

interface AddeparStep3Props extends AddeparViewConfigurationMainState {
  groupingOptions: AddeparOption[];
  filterOptions: AddeparOption[];
  addeparFirmBaseUrl: string;
  errorMessages: ViewErrors[];
}

const AddeparStep3 = ({
  groupingOptions,
  filterOptions,
  addeparFirmBaseUrl,
  errorMessages,
  activeViewIndex,
  setActiveViewIndex,
  views,
  removeView,
  updatePortfolioPrefix,
  updateGrouping,
  addGrouping,
  removeGrouping,
  investmentReturnAttribute,
  updateInvestmentReturnAttribute,
  customReturnOption,
  isRefreshing,
  applyToAttributeOptions,
  shouldLoadActiveViewIncludedAttrs,
  addView,
}: AddeparStep3Props) => {
  const onlyHasOneView = views.length === 1;
  return (
    <Configuration>
      <ViewArea>
        <RowSpaceBetween>
          <Headline3>Views</Headline3>
          <Button
            className="qa-addepar-add-view-top"
            flat
            icon="plus"
            onClick={() => {
              addView();
              analyticsService.ctaClicked({
                purpose: 'Add View to Addepar Integration',
                text: 'Add View',
                locationOnPage: `Addepar ${STEPS[2]!.label} (top)`,
              });
            }}
            disabled={isRefreshing}
          >
            Add View
          </Button>
        </RowSpaceBetween>

        <ConditionalOverlay condition={isRefreshing} overlay="">
          {views.map(({ configUuid, portfolioPrefix, groupings, includedAttributes, filters }, index) => {
            const includedAttributesError = errorMessages[index]!.includedAttributes;
            if (index !== activeViewIndex) {
              return (
                <CollapedView
                  key={configUuid}
                  portfolioPrefix={portfolioPrefix}
                  error={errorMessages[index]!}
                  onClickExpand={() => setActiveViewIndex(index)}
                  onClickDelete={() => removeView(configUuid)}
                />
              );
            }

            return (
              <EditViewConfiguration
                key={configUuid}
                configUuid={configUuid}
                portfolioPrefix={portfolioPrefix}
                filters={filters}
                groupings={groupings}
                removeGrouping={removeGrouping}
                addGrouping={addGrouping}
                groupingOptions={groupingOptions}
                filterOptions={filterOptions}
                updateGrouping={updateGrouping}
                updatePortfolioPrefix={updatePortfolioPrefix}
                portfolioPrefixError={errorMessages[index]!.portfolioPrefix}
                includedAttributes={includedAttributes}
                applyToAttributeOptions={applyToAttributeOptions}
                includedAttributesError={includedAttributesError}
                shouldLoadActiveViewIncludedAttrs={shouldLoadActiveViewIncludedAttrs}
                onToggle={() => setActiveViewIndex(undefined)}
                onlyHasOneView={onlyHasOneView}
                removeCurrentView={() => removeView(configUuid)}
              />
            );
          })}
        </ConditionalOverlay>
        <FullButton
          className="qa-addepar-add-view"
          icon="plus"
          onClick={() => {
            addView();
            analyticsService.ctaClicked({
              purpose: 'Add View to Addepar Integration',
              text: 'Add View',
              locationOnPage: `Addepar ${STEPS[2]!.label} (bottom)`,
            });
          }}
          disabled={isRefreshing}
        >
          Add View
        </FullButton>
      </ViewArea>
      <ReturnArea>
        <Headline3>Investment return column</Headline3>
        <Body1>Method used for calculating investment return.</Body1>
        <br />
        <InvestmentReturnOption
          inputId="twr"
          checked={investmentReturnAttribute.key === DEFAULT_RETURN_KEY}
          onChange={() => updateInvestmentReturnAttribute('twr')}
          value="twr"
          disabled={isRefreshing}
          description={`The default method ${appTitle} uses for your data.`}
        >
          Adjusted TWR
        </InvestmentReturnOption>
        {customReturnOption ? (
          <InvestmentReturnOption
            inputId="custom"
            checked={investmentReturnAttribute.key !== DEFAULT_RETURN_KEY}
            onChange={() => updateInvestmentReturnAttribute('custom_return')}
            value="custom_return"
            disabled={isRefreshing}
            description="The custom attribute you set up in Addepar."
          >
            {customReturnOption?.label ?? `${appTitle} Return`}
          </InvestmentReturnOption>
        ) : (
          <div>
            <CustomAttributeHeading>Custom attribute</CustomAttributeHeading>
            <CustomAttributeInstructions1>
              In order to use a different method, you must add a custom attribute named "{appTitle} Return" in your
              Addepar account settings.
            </CustomAttributeInstructions1>
            <CustomAttributeInstructions2>
              Once you have, return here and click refresh data.
            </CustomAttributeInstructions2>
          </div>
        )}
        <Button
          dense
          link={getAddeparAttributeConfigurationUrl(addeparFirmBaseUrl)}
          icon="external-link"
          iconPosition={IconPosition.Right}
          target="_blank"
          rel="noopener noreferrer"
        >
          Addepar Account Settings
        </Button>
      </ReturnArea>
    </Configuration>
  );
};

export default AddeparStep3;

const InvestmentReturnOption = styled(Radio)`
  min-width: 228px;
  margin-right: 0;
  padding-bottom: 14px;
`;

const Configuration = styled.div`
  display: flex;
  gap: 40px;
`;

const ViewArea = styled.div`
  width: 70%;
`;

const ReturnArea = styled.div`
  width: 30%;
`;

const FullButton = styled(Button)`
  width: 100%;
  border: 1px solid ${GetColor.GreyScale.Grey30};
`;

const CustomAttributeHeading = styled(Body1)`
  font-weight: bold;
`;

const CustomAttributeInstructions1 = styled(Body1)`
  line-height: 1.2;
`;

const CustomAttributeInstructions2 = styled(Body1)`
  padding: 7px 0 14px;
`;
