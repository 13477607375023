import { useEffect, useState } from 'react';
import { autocompleteSearch } from 'venn-api';

const MSCIFundId = 'f7ca915d-77d1-43bb-b7c5-b3872947434e';

const useRecentFundId = (): string | undefined => {
  const [fundId, setFundId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchRecent = async () => {
      setLoading(true);
      try {
        const { content } = await autocompleteSearch({
          query: '',
          scopes: ['INVESTMENT'],
          count: 1,
          investmentParams: { recentlyViewed: true },
        });
        setFundId(content.results?.[0]!.fund?.id ?? content.results?.[0]!.investment?.id ?? MSCIFundId);
      } catch (e) {
        setFundId(MSCIFundId);
      }
      setLoading(false);
    };
    if (!fundId && !loading) {
      fetchRecent();
    }
  }, [fundId, loading]);

  return fundId;
};

export default useRecentFundId;
