import { useMemo } from 'react';
import type { ProxyTypeEnum } from 'venn-api';
import { useProxyTypes, isProxyTypeError } from './proxy-types';
import { type ReturnsRangeInfo, getDisabledProxyTypeMessage } from './utils';
import type { FundToBulkProxy, FundToProxy } from '../types';

type ProxyTypeValidation = Record<
  ProxyTypeEnum,
  {
    hasOnlyErrors: boolean;
    hasSomeErrors: boolean;
    investmentInfo: readonly {
      disabledMessage: string | undefined;
      hasProxyTypeError: boolean;
      investmentId: string;
    }[];
  }
>;

/**
 * @returns a map from proxy type (all proxy types considered) to an object containing the following:
 * - hasSomeErrors: whether at least one investment has an error for this proxy type
 * - hasOnlyErrors: whether all investments have an error for this proxy type
 * - investmentInfo: an array of objects, one for each investment, containing the following:
 *  - investmentId: the id of the investment
 *  - disabledMessage: a message indicating why the proxy type is disabled for the investment, or undefined if it is enabled
 *  - hasProxyTypeError: whether this error should disable the proxy type, because it is not resolvable by changing the selected proxy
 */
export const useProxyTypeValidation = (
  investments: (FundToProxy | FundToBulkProxy)[],
  rawInvestmentRanges: (ReturnsRangeInfo | null)[],
  rawProxyReturnsRange: ReturnsRangeInfo | undefined | null,
): ProxyTypeValidation => {
  const proxyTypes = useProxyTypes();
  const proxyTypeValidationResults = useMemo(
    () =>
      Object.fromEntries(
        proxyTypes.map((proxyType: ProxyTypeEnum) => {
          const investmentInfo = investments.map((investment, i) => {
            const disabledMessage = getDisabledProxyTypeMessage(
              proxyType,
              investment,
              rawInvestmentRanges[i]!,
              rawProxyReturnsRange,
            );
            return {
              investmentId: investment.id,
              disabledMessage,
              hasProxyTypeError: isProxyTypeError(disabledMessage),
            };
          });

          const hasSomeErrors = investmentInfo.some((info) => info.hasProxyTypeError);
          const hasOnlyErrors = hasSomeErrors && investmentInfo.every((info) => info.hasProxyTypeError);
          return [
            proxyType,
            {
              hasSomeErrors,
              hasOnlyErrors,
              investmentInfo,
            },
          ] as const;
        }),
      ),
    [investments, proxyTypes, rawInvestmentRanges, rawProxyReturnsRange],
  );

  return proxyTypeValidationResults as Record<ProxyTypeEnum, (typeof proxyTypeValidationResults)[string]>;
};
