import { type AxisLabelsFormatterContextObject, numberFormat, type Options as HighchartOptions } from 'highcharts';
import { compact, get, isEmpty, isNil } from 'lodash';
import { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ThemeContext } from 'styled-components';
import type {
  PeerGroupAnalysisResponse,
  PeerGroupAnalysisSubjectMetricResponse,
  PeerGroupAnalysisUniverseMetricResponse,
} from 'venn-api';
import {
  blockBenchmarkConfig,
  blockCustomizableMetrics,
  blockFonts,
  blockLimitedRequestSubjects,
  blockMetrics,
  getCustomFontSize,
  blockSubjectHasProxyState,
  type StudioRequestSubject,
} from 'venn-state';
import { getItemColor, ItemType } from 'venn-ui-kit';
import { assertNotNil, getAnalyzedSubjectFromRequestSubject, getRequestSubjectFromAnalysisSubject } from 'venn-utils';
import {
  getPeerGroupSubjectTooltip,
  getPeerGroupTooltip,
  toPercentage,
} from '../../../../charts/analysis-charts/chart-config-logic';
import { formatSubjectWithOptionalFee } from '../../../../legend/NameWithOptionalFee';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { useSubjectColors } from '../../../logic/useSubjectColors';
import { usePeerGroupChartHeight } from './usePeerGroupChartHeight';

const UNIT_OFFSET = 0.4;
const stackingOption = 'normal';
/**
 * Calculates horizontal offset for the subject's datapoint to be correctly displayed.
 *
 * The first subject should have the lowest offset (to become leftmost),
 * whereas the last subject should have the largest offset (and become rightmost);
 * the subject in the middle should get a 0.
 */
const calculateHorizontalOffset = (index: number, totalSubjects: number) => {
  const middle = Math.floor(totalSubjects / 2);
  return (index - middle) * (UNIT_OFFSET / totalSubjects);
};

interface PeerGroupChartOptions {
  columnStackOptions: HighchartOptions;
  boxOptions: HighchartOptions;
  isEmpty: boolean;
}

export const usePeerGroupsChartConfig = (data: PeerGroupAnalysisResponse): PeerGroupChartOptions => {
  const chartHeight = usePeerGroupChartHeight();
  const blockId = useBlockId();
  const { Colors } = useContext(ThemeContext);
  const selectedMetricsUnfiltered = useRecoilValue(blockMetrics(blockId));
  const subjectHasProxy = useRecoilValue(blockSubjectHasProxyState(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const selectedMetrics = selectedMetricsUnfiltered.filter((metric) => {
    const metricMetadata = assertNotNil(allMetrics.find((customizableMetric) => customizableMetric.key === metric));

    const universeDataForMetric = get(
      data.universeData,
      assertNotNil(benchmarkConfig.relative ? metricMetadata.relativeMetricPath : metricMetadata.metricPath),
    ) as unknown as PeerGroupAnalysisUniverseMetricResponse;

    return !isNil(universeDataForMetric?.constituents) && universeDataForMetric?.constituents !== 0;
  });
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const subjectsAndBenchmark: StudioRequestSubject[] = compact([
    ...subjects,
    benchmarkConfig.type === 'COMMON' && !benchmarkConfig.relative && benchmarkConfig.subject
      ? getRequestSubjectFromAnalysisSubject(benchmarkConfig.subject)
      : null,
  ]);
  const subjectsAndBenchmarkNames = subjectsAndBenchmark.map((subject) =>
    formatSubjectWithOptionalFee(subject, !!subjectHasProxy[subject.id]),
  );
  const subjectColors = useSubjectColors(subjectsAndBenchmark.map(getAnalyzedSubjectFromRequestSubject));

  const universeBars = useMemo(() => {
    return Array(selectedMetrics.length).fill(0.25);
  }, [selectedMetrics.length]);
  const [categories, yAxisBoxUnitFormat] = useMemo(() => {
    const categories = selectedMetrics.map((selectedMetric) => {
      const metricMetadata = assertNotNil(
        allMetrics.find((customizableMetric) => customizableMetric.key === selectedMetric),
      );
      return assertNotNil(benchmarkConfig.relative ? metricMetadata.relativeLabel : metricMetadata.label);
    });

    const usePercentageBoxFormat = selectedMetrics.every((selectedMetric) => {
      const metricMetadata = assertNotNil(
        allMetrics.find((customizableMetric) => customizableMetric.key === selectedMetric),
      );
      return metricMetadata.dataType === 'PERCENTAGE';
    });
    return [categories, usePercentageBoxFormat ? ('percent' as const) : ('ratio' as const)];
  }, [selectedMetrics, allMetrics, benchmarkConfig.relative]);
  const columnStackOptions: HighchartOptions = useMemo(() => {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        type: 'column' as const,
        height: chartHeight,
      },
      title: {
        text: '',
        align: 'center' as const,
      },
      legend: {
        useHTML: true,
        itemStyle: {
          fontSize: getCustomFontSize(legendFont),
        },
      },
      xAxis: {
        categories,
        labels: {
          style: {
            fontSize: getCustomFontSize(axisFont),
          },
        },
      },
      yAxis: {
        allowDecimals: true,
        gridLineColor: 'transparent' as const,
        min: -0.01, // hack to make top border be nicely visible
        max: 1.05, // add padding on the bottom of the chart (the chart has 100% on the bottom)
        reversed: true,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: 'Percentile Rank' as const,
          margin: 20,

          style: {
            fontSize: getCustomFontSize(axisFont),
          },
        },
        labels: {
          style: {
            fontSize: getCustomFontSize(axisFont),
          },
          formatter(this: AxisLabelsFormatterContextObject) {
            return `${numberFormat(toPercentage(this.value), 0)}%`;
          },
        },
      },
      plotOptions: {
        column: {
          stacking: stackingOption,
        },
        series: {
          pointRange: 1,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },

      series: [
        {
          type: 'column' as const,
          showInLegend: false,
          data: universeBars,
          stack: 'metric' as const,
          color: Colors.PeerGroupColor.GradientHigh,
          borderColor: Colors.Black,
          enableMouseTracking: false,
        },
        {
          type: 'column' as const,
          showInLegend: false,
          data: universeBars,
          stack: 'metric' as const,
          color: Colors.PeerGroupColor.GradientLow,
          borderColor: Colors.Black,
          enableMouseTracking: false,
        },
        {
          type: 'column' as const,
          showInLegend: false,
          data: universeBars,
          stack: 'metric' as const,
          color: Colors.PeerGroupColor.GradientLow,
          borderColor: Colors.Black,
          enableMouseTracking: false,
        },
        {
          type: 'column' as const,
          showInLegend: false,
          data: universeBars,
          stack: 'metric' as const,
          color: Colors.PeerGroupColor.GradientHigh,
          borderColor: Colors.Black,
          enableMouseTracking: false,
        },
        ...subjectsAndBenchmarkNames.map((name, index) => {
          return {
            name,
            color: subjectColors[index],
            showInLegend: true,
            type: 'scatter' as const,
            enableMouseTracking: true,
            tooltip: getPeerGroupSubjectTooltip(Colors, 'percent', 'stacked'),
            data: selectedMetrics.map((selectedMetric) => {
              const metricMetadata = assertNotNil(
                allMetrics.find((customizableMetric) => customizableMetric.key === selectedMetric),
              );
              const percentile =
                (
                  get(
                    data.subjectsData,
                    assertNotNil(
                      benchmarkConfig.relative ? metricMetadata.relativeMetricPath : metricMetadata.metricPath,
                    ),
                  )?.[index] as unknown as PeerGroupAnalysisSubjectMetricResponse
                )?.percentile ?? null;

              return percentile === null ? null : percentile / 100;
            }),
            pointPlacement: calculateHorizontalOffset(index, subjectsAndBenchmarkNames.length),
            marker: {
              radius: 8,
              symbol: 'circle' as const,
              lineColor: Colors.Black,
              lineWidth: 1,
            },
          };
        }),
      ],
    };
  }, [
    categories,
    universeBars,
    subjectColors,
    subjectsAndBenchmarkNames,
    allMetrics,
    selectedMetrics,
    Colors,
    data.subjectsData,
    benchmarkConfig.relative,
    chartHeight,
    axisFont,
    legendFont,
  ]);

  const boxOptions = useMemo(() => {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        height: chartHeight,
        type: 'boxplot' as const,
      },

      title: {
        text: '',
      },

      legend: {
        enabled: true,
        useHTML: true,
        itemStyle: {
          fontSize: getCustomFontSize(legendFont),
        },
      },

      xAxis: {
        categories,
        labels: {
          style: {
            fontSize: getCustomFontSize(axisFont),
          },
        },
      },

      yAxis: {
        title: {
          text: '',
        },
        labels: {
          style: {
            fontSize: getCustomFontSize(axisFont),
            formatter(this: AxisLabelsFormatterContextObject) {
              if (yAxisBoxUnitFormat === 'percent') {
                return `${numberFormat(toPercentage(this.value), 1)}%`;
              }
              return `${numberFormat(this.value, 2)}`;
            },
          },
        },
      },

      plotOptions: {
        boxplot: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 1,
              x2: 0,
              y2: 0,
            },
            stops: [
              [0, Colors.PeerGroupColor.GradientLow],
              [1, Colors.PeerGroupColor.GradientHigh],
            ],
          } as Highcharts.GradientColorObject,
          stemWidth: 1,
          lineWidth: 1,
          medianColor: Colors.Black,
          medianWidth: 1,
          whiskerLength: '20%',
          whiskerWidth: 1,
        },
      },

      series: [
        {
          name: 'Peer Group',
          color: getItemColor(Colors, ItemType.Article),
          showInLegend: false,
          type: 'boxplot' as const,
          tooltip: getPeerGroupTooltip(Colors, yAxisBoxUnitFormat),
          data: selectedMetrics.map((selectedMetric, index) => {
            const metricMetadata = assertNotNil(
              allMetrics.find((customizableMetric) => customizableMetric.key === selectedMetric),
            );
            const universeDataForThisMetric = get(
              data.universeData,
              assertNotNil(benchmarkConfig.relative ? metricMetadata.relativeMetricPath : metricMetadata.metricPath),
            ) as unknown as PeerGroupAnalysisUniverseMetricResponse;
            return {
              x: index,
              low: universeDataForThisMetric?.p5,
              q1: universeDataForThisMetric?.p25,
              median: universeDataForThisMetric?.p50,
              q3: universeDataForThisMetric?.p75,
              high: universeDataForThisMetric?.p95,
            };
          }),
        },
        ...subjectsAndBenchmarkNames.map((name, index) => {
          return {
            name,
            color: subjectColors[index],
            showInLegend: true,
            type: 'scatter' as const,
            tooltip: getPeerGroupSubjectTooltip(Colors, yAxisBoxUnitFormat, 'box'),
            data: selectedMetrics.map((selectedMetric) => {
              const metricMetadata = assertNotNil(
                allMetrics.find((customizableMetric) => customizableMetric.key === selectedMetric),
              );
              const dataForThisMetric = get(
                data.subjectsData,
                assertNotNil(benchmarkConfig.relative ? metricMetadata.relativeMetricPath : metricMetadata.metricPath),
              )?.[index] as unknown as PeerGroupAnalysisSubjectMetricResponse;
              return dataForThisMetric?.value ?? null;
            }),
            pointPlacement: calculateHorizontalOffset(index, subjectsAndBenchmarkNames.length),
            marker: {
              radius: 8,
              symbol: 'circle' as const,
              lineColor: Colors.Black,
              lineWidth: 1,
            },
          };
        }),
      ],
    };
  }, [
    categories,
    selectedMetrics,
    Colors,
    subjectColors,
    subjectsAndBenchmarkNames,
    allMetrics,
    data,
    yAxisBoxUnitFormat,
    benchmarkConfig.relative,
    chartHeight,
    legendFont,
    axisFont,
  ]);

  return {
    columnStackOptions,
    // @ts-expect-error cannot get rid of the tooltip-related error due to weird highcharts typing
    boxOptions,
    isEmpty: isEmpty(selectedMetrics),
  };
};
