import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import {
  AuthButton,
  AuthLogo,
  SignUpTerms,
  AuthenticationFooter,
  AuthFormErrors,
  CountrySelectWithLabel,
  NameInputs,
  PasswordInputs,
  EmailInput,
  AllRequiredNotes,
  Info,
  StyledHeadline1,
  AuthLoader,
  StyledCheckboxWrapper,
  ContentWrapper,
} from '../components';
import type { Country } from 'venn-api';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getAppTitle, Subtitle1, Tooltip } from 'venn-ui-kit';
import { Routes } from 'venn-utils';
import type { Field, Fields, FormValues } from 'venn-components';
import { useForm } from 'venn-components';
import { getFormErrorMessage } from '../utils';

interface SignUpInvitePageFormFields extends Fields {
  email: Field<string>;
  firstName: Field<string>;
  lastName: Field<string>;
  country: Field<number>;
  company: Field;
  activationCode: Field<number>;
  password: Field<string>;
  organizationId: Field<number>;
}

interface InviteSignupFormProps {
  fields: SignUpInvitePageFormFields;
  error: string;
  country?: Country;
  onSubmit: (values: FormValues) => void;
}

const InviteSignupForm: FC<React.PropsWithChildren<InviteSignupFormProps>> = (props) => {
  const { onSubmit, fields: inputFields, country, error } = props;

  const [termsCheckboxChecked, setTermsCheckboxChecked] = useState(false);
  const [privacyCheckboxChecked, setPrivacyCheckboxChecked] = useState(false);
  const toggleTerm = useCallback(() => setTermsCheckboxChecked((prev) => !prev), []);
  const togglePrivacyTerm = useCallback(() => setPrivacyCheckboxChecked((prev) => !prev), []);

  const [fields, isValid, isSubmitting, submit] = useForm(Object.values(inputFields), onSubmit);
  const company = fields?.company?.value;
  const code = country?.code;
  const disabled = !isValid || !termsCheckboxChecked || !privacyCheckboxChecked || isSubmitting;
  return (
    <>
      <ContentWrapper isLoading={!!isSubmitting}>
        {isSubmitting && <AuthLoader title="Loading" />}
        {!isSubmitting && (
          <FormWrapper>
            <AuthLogo />
            <Info>
              <StyledHeadline1>{`Start using ${getAppTitle()} with ${company}.`}</StyledHeadline1>
              <Subtitle1>Not your organization?</Subtitle1>
              <Subtitle1>
                Contact your organization’s admin or{' '}
                <StyledLink to={Routes.SIGN_UP_PATH}>create a new organization.</StyledLink>
              </Subtitle1>
            </Info>
            <StyledAuthForm onSubmit={submit}>
              <AllRequiredNotes />
              <NameInputs firstName={fields.firstName} lastName={fields.lastName} />
              <EmailInput disabled field={fields.email!} />
              {country && <CountrySelectWithLabel disabled field={{ ...fields.country!, value: country }} />}
              <PasswordInputs field={fields.password!} />

              <StyledCheckboxWrapper checked={termsCheckboxChecked} onChange={toggleTerm}>
                <SignUpTerms jurisdiction={code} term="eula" />
              </StyledCheckboxWrapper>
              <StyledCheckboxWrapper checked={privacyCheckboxChecked} onChange={togglePrivacyTerm}>
                <SignUpTerms jurisdiction={code} term="privacy" />
              </StyledCheckboxWrapper>
              <AuthFormErrors error={error} />
              <StyledTooltip
                showShadow
                block
                content={getFormErrorMessage(fields, fieldLabels, !termsCheckboxChecked || !privacyCheckboxChecked)}
                maxWidth={450}
              >
                <AuthButton dominant disabled={disabled} type="submit">
                  Sign up
                </AuthButton>
              </StyledTooltip>
            </StyledAuthForm>
          </FormWrapper>
        )}
      </ContentWrapper>
      <AuthenticationFooter />
    </>
  );
};

export default InviteSignupForm;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;

const StyledAuthForm = styled.form`
  max-width: 450px;
  padding: 48px 0 0;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
`;

const StyledTooltip = styled(Tooltip)`
  text-align: center;
`;

const fieldLabels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
};
