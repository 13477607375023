import type { ActionableContext, ActionEnum, TreeActionableContext, UserProfileSettings } from 'venn-api';
import { ContextService } from 'venn-api';
import { userActivity } from 'venn-utils';
import { isEmpty } from 'lodash';
import { flattenContextTree } from './getContextMenuItems';

const LAST_USED_CONTEXT_KEY = 'last_used_context';

const defaultIfEmpty = (value: string | undefined, defaultValue: string | undefined) => {
  return isEmpty(value) ? defaultValue : value;
};

export const getCurrentContext = (existingContext: string | undefined, profileSettings: UserProfileSettings) => {
  const defaultContext = profileSettings.availableContexts.length
    ? profileSettings.availableContexts[0]!.value.context.id
    : undefined;
  const allowedContexts = (profileSettings.availableContexts ?? [])
    .flatMap((tree) => flattenContextTree(tree, 0))
    .map((item) => item.context.id);
  const lastUsedContext = userActivity.getLocalStorageItemPerUser(
    LAST_USED_CONTEXT_KEY,
    profileSettings.user.id.toString(),
  );
  const currentContext = defaultIfEmpty(existingContext, defaultIfEmpty(lastUsedContext, defaultContext));
  const result = allowedContexts.includes(currentContext ?? '') ? currentContext : undefined;
  ContextService.setCurrentContext(result);
  return result;
};

export const setLastUsedContext = (userId: number, context?: string) => {
  if (context) {
    userActivity.setLocalStorageItemPerUser(LAST_USED_CONTEXT_KEY, context, userId.toString());
  } else {
    userActivity.removeLocalStorageItemPerUser(LAST_USED_CONTEXT_KEY, userId.toString());
  }
};

export const getPermissions = (currentContext: string, availableContexts: TreeActionableContext[]): Set<ActionEnum> => {
  const currentContextActions = getContext(currentContext, availableContexts)?.actionEnums ?? [];
  return new Set(currentContextActions);
};

export const contextHasAction = (contextId: string, tree: TreeActionableContext[], action: ActionEnum) => {
  const actions = getContext(contextId, tree)?.actionEnums ?? [];
  return actions.includes(action);
};

export const getContext = (contextId: string, tree: TreeActionableContext[]): ActionableContext | undefined => {
  return tree.map((child) => getContextHelper(contextId, child)).find((a) => a !== undefined);
};

const getContextHelper = (contextId: string, tree: TreeActionableContext): ActionableContext | undefined => {
  if (tree.value.context.id === contextId) {
    return tree.value;
  }
  return tree.children.map((child) => getContextHelper(contextId, child)).find((a) => a !== undefined);
};

export const getGlobalContext = (profileSettings?: UserProfileSettings) => {
  // assume global context will be on the first level of the tree
  return profileSettings?.availableContexts.find((context) => context.value.context.global);
};
