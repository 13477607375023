import { useCallback } from 'react';
import { EMPTY, getThirdPartyExportMessageRow } from '../../../../logic/exportUtils';
import { assertNotNil, convertMultipleSeriesDataToExcel, type ExcelCell } from 'venn-utils';
import { formatExportableSubjectWithOptionalFee } from '../../../../../legend';
import type { PercentileBlockExportFunctionProps } from '../types';
import { getCurrencySymbol } from '../../../charts/chartsUtils';
import { CurrencyEnum } from 'venn-api';

export const usePercentileChartExportDataFunction = ({
  subjectsSeries,
  subjectInfos,
  exportable,
}: PercentileBlockExportFunctionProps) => {
  return useCallback(() => {
    if (!exportable) {
      return [getThirdPartyExportMessageRow()];
    }

    const headers: ExcelCell[] = [
      EMPTY,
      ...subjectsSeries.map((serie, idx) => {
        if (idx === 0 || subjectsSeries[idx - 1]?.key !== serie.key) {
          const originalSubjectInfo = subjectInfos[assertNotNil(serie.key)]!;
          return {
            value: `${formatExportableSubjectWithOptionalFee(originalSubjectInfo.subject)} + ${formatExportableSubjectWithOptionalFee(assertNotNil(originalSubjectInfo.privateSubject))}`,
            bold: true,
          };
        }
        return EMPTY;
      }),
    ];
    const data = convertMultipleSeriesDataToExcel(
      subjectsSeries.map((serie) => ({
        name: serie.name,
        // skip the first row for public-private asset growth which is historical and non-exportable
        series: serie.data.slice(1),
      })),
      false,
      getCurrencySymbol(CurrencyEnum.USD),
      'QUARTERLY',
    );
    return [headers, ...data];
  }, [subjectsSeries, exportable, subjectInfos]);
};
