import type {
  AnalysisBlockTypeEnum,
  AnalysisBlock,
  AnalysisParams,
  AnalysisSubject as ApiAnalysisSubject,
  AnalysisTypeEnum,
  AnalysisView,
  Portfolio,
} from 'venn-api';
import type { NonQueryViewSettings, RollingPeriodSettings, URLLevelSettings } from 'venn-components';
import { blockLevelDefaultSettings, RollingPeriodEnum } from 'venn-components';
import type { RangeType } from 'venn-ui-kit';
import { getRangeFromString } from 'venn-ui-kit';
import { fromPortfolioComparisonTypeEnum, toCompareType } from '../components/ComparisonContextStore';
import type { AnalysisSubjectSecondaryLabel, AnalysisConfig } from 'venn-utils';
import {
  getAnalysisSubjects,
  AnalysisSubject,
  vennAppUser,
  FS,
  isEqualWithFullPeriodAmendment,
  assertNotNil,
} from 'venn-utils';
import { isEqualWith, isEqual, isNil } from 'lodash';
import type { MomentInput } from 'moment';
import moment from 'moment';
import type { SpecialFactorTemplateId } from './useTrackFailedAnalysis';

export function parseAnalysisView(view?: AnalysisView): (URLLevelSettings & NonQueryViewSettings) | undefined {
  if (!view || !view?.customAnalysisParams) {
    return undefined;
  }

  const { startDate, endDate, periodToDate } = assertNotNil(view.analysisPeriod);
  const comparisonSubject = view.subjects
    .filter((subject) => !subject.subjectInaccessible)
    .find((subject) => subject.comparisonType === 'COMPARISON');

  const historicalDrawdownBlock = findBlock(view, 'PROFORMA_DRAWDOWN');
  const hypotheticalDrawdownBlock = findBlock(view, 'FACTOR_DRAWDOWN');
  const correlationsBlock = findBlock(view, 'CORRELATION');
  const rollingReturnBlock = findBlock(view, 'ROLLING_RETURN');
  const rollingSharpeBlock = findBlock(view, 'ROLLING_SHARPE');
  const rollingVolatilityBlock = findBlock(view, 'ROLLING_VOLATILITY');
  const rollingBetaBlock = findBlock(view, 'ROLLING_BETA');
  const rollingBenchmarkCorrelationBlock = findBlock(view, 'ROLLING_BENCHMARK_CORRELATION');
  const scenariosBlock = findBlock(view, 'SCENARIO');
  const rollingFactorReturnTrendBlock = findBlock(view, 'FACTOR_CONTRIBUTION_TO_RETURN_TREND');
  const rollingFactorRiskTrendBlock = findBlock(view, 'FACTOR_CONTRIBUTION_TO_RISK_TREND');
  const rollingFactorExposureTrendBlock = findBlock(view, 'FACTOR_EXPOSURES_TREND');
  const notablePeriodsBlock = findBlock(view, 'NOTABLE_PERIODS');

  const {
    relative,
    attributionsSubject,
    attributionsView,
    factorAnalysisSortBy,
    returnsGridFrequency,
    returnsGridSubject,
    venncast,
    isTradesView,
    isPercentageMode,
    isCategoryOff,
    compareVersion,
    showCorrelationValues,
    trendChartModes,
  } = view.customViewOptions;

  const period = getRangeFromString(periodToDate);

  return {
    /* Global settings */
    relative,
    start: period ? undefined : (startDate ?? undefined),
    end: period ? undefined : (endDate ?? undefined),
    period,
    compare: fromPortfolioComparisonTypeEnum(comparisonSubject?.portfolioComparisonType),
    compareVersion,
    savedId: view.id,
    templateId: view.customTemplateId ?? view.systemTemplate,
    currentViewName: view.name,
    /* From specific blocks */
    historicalDrawdownThreshold:
      historicalDrawdownBlock?.drawdownThreshold ?? blockLevelDefaultSettings.historicalDrawdownThreshold,
    hypotheticalDrawdownThreshold:
      hypotheticalDrawdownBlock?.drawdownThreshold ?? blockLevelDefaultSettings.hypotheticalDrawdownThreshold,
    residualCorrelation: correlationsBlock?.residual ?? blockLevelDefaultSettings.residualCorrelation,
    showCorrelationValues: showCorrelationValues ?? blockLevelDefaultSettings.showCorrelationValues,
    rollingReturnPeriod:
      parseToRollingPeriodSettings(rollingReturnBlock?.rollingYears) ?? blockLevelDefaultSettings.rollingReturnPeriod,
    rollingSharpePeriod:
      parseToRollingPeriodSettings(rollingSharpeBlock?.rollingYears) ?? blockLevelDefaultSettings.rollingSharpePeriod,
    rollingBetaPeriod:
      parseToRollingPeriodSettings(rollingBetaBlock?.rollingYears) ?? blockLevelDefaultSettings.rollingBetaPeriod,
    rollingBenchmarkCorrelationPeriod:
      parseToRollingPeriodSettings(rollingBenchmarkCorrelationBlock?.rollingYears) ??
      blockLevelDefaultSettings.rollingBenchmarkCorrelationPeriod,
    rollingVolatilityPeriod:
      parseToRollingPeriodSettings(rollingVolatilityBlock?.rollingYears) ??
      blockLevelDefaultSettings.rollingVolatilityPeriod,
    scenarios: scenariosBlock?.scenarios ?? [],
    scenariosCustomParamId: scenariosBlock?.id ?? blockLevelDefaultSettings.scenariosCustomParamId,
    /* From custom options */
    attributionsSubject: attributionsSubject ?? blockLevelDefaultSettings.attributionsSubject,
    attributionsView: attributionsView ?? blockLevelDefaultSettings.attributionsView,
    factorAnalysisSortBy: factorAnalysisSortBy ?? blockLevelDefaultSettings.factorAnalysisSortBy,
    returnsGridFrequency: returnsGridFrequency ?? blockLevelDefaultSettings.returnsGridFrequency,
    returnsGridSubject: returnsGridSubject ?? blockLevelDefaultSettings.returnsGridSubject,
    venncast: venncast ?? blockLevelDefaultSettings.venncast,
    isTradesView: isTradesView ?? blockLevelDefaultSettings.isTradesView,
    isPercentageMode: isPercentageMode ?? blockLevelDefaultSettings.isPercentageMode,
    isCategoryOff: isCategoryOff ?? blockLevelDefaultSettings.isCategoryOff,
    rollingFactorReturnPeriod:
      rollingFactorReturnTrendBlock?.rollingYears ?? blockLevelDefaultSettings.rollingFactorReturnPeriod,
    rollingFactorRiskPeriod:
      rollingFactorRiskTrendBlock?.rollingYears ?? blockLevelDefaultSettings.rollingFactorRiskPeriod,
    rollingFactorExposuresPeriod:
      rollingFactorExposureTrendBlock?.rollingYears ?? blockLevelDefaultSettings.rollingFactorExposuresPeriod,
    selectedNotablePeriods:
      notablePeriodsBlock?.selectedNotablePeriods ?? blockLevelDefaultSettings.selectedNotablePeriods,
    notablePeriodsThreshold:
      notablePeriodsBlock?.notablePeriodsThreshold ?? blockLevelDefaultSettings.notablePeriodsThreshold,
    trendChartModes: trendChartModes ?? blockLevelDefaultSettings.trendChartModes,
  };
}

type ParamsAgg = [Partial<NonQueryViewSettings & URLLevelSettings>, Partial<NonQueryViewSettings>];

export const getAnalysisView = (
  name: string,
  analysisConfig: AnalysisConfig,
  settings: NonQueryViewSettings & URLLevelSettings,
  ownerContextId?: string,
  baselineView?: AnalysisView,
): Partial<AnalysisView> | undefined => {
  const { subject, selectedPeriod, selectedTimeFrame, analysisTemplate, category, relative } = analysisConfig;

  if (!subject || !analysisTemplate) {
    return undefined;
  }

  const { appUser, id, analysisBlocks } = analysisTemplate;
  const isSystemTemplate = appUser?.id === vennAppUser.id;

  const allSettings = getSettingsMapForAllBlocks(settings);

  const blockNames = analysisBlocks.map((block) => block.analysisBlockType);
  const [customViewOptions, params] = [...blockNames, id].reduce(
    ([partialSettings, partialParams]: ParamsAgg, blockName: string) => {
      if (blocksWithCustomSettings[blockName]) {
        return [{ ...partialSettings, ...allSettings[blockName] }, partialParams] as ParamsAgg;
      }
      return [partialSettings, { ...partialParams, ...allSettings[blockName] }] as ParamsAgg;
    },
    [{}, {}],
  );

  let subjects: ApiAnalysisSubject[];
  if (subject.isInvestmentInPortfolio || subject.isStrategyInPortfolio) {
    // Save the root portfolio as analysis view primary subject
    const superSubject = new AnalysisSubject(
      subject.superItem,
      'portfolio',
      settings.compare === 'none' || settings.compare === undefined
        ? undefined
        : // Mark that we have secondary portfolio to get it included in the subjects list
          // Note that we only care about the fact that it's there, and its `portfolioComparisonType`
          {
            secondaryPortfolio: { draft: true } as Portfolio,
            secondaryLabel: toCompareType(settings.compare) as AnalysisSubjectSecondaryLabel,
          },
    );
    subjects = getAnalysisSubjects(superSubject, category === 'ON');
    subjects[0]!.id = superSubject.id.toString();
  } else {
    subjects = getAnalysisSubjects(subject, category === 'ON');
  }
  if (subject.superType === 'portfolio') {
    // Full portfolio is included, and id omitted by default for primary subject
    subjects[0]!.id = subject.superId.toString();
  }
  // Clean up the `portfolio` objects in subjects: we don't need them, only their ids / types
  subjects.forEach((_subject, idx) => {
    subjects[idx]!.portfolio = undefined;
  });

  const allocationPanelOptions =
    subject.superType === 'portfolio'
      ? {
          isTradesView: settings.isTradesView,
          isPercentageMode: settings.isPercentageMode,
          portfolioVersion: subject.portfolio?.version,
          selectedStrategyId: subject.strategyId,
          compareVersion: settings.compareVersion,
        }
      : {};
  const investmentOptions = {
    isCategoryOff: settings.isCategoryOff,
  };

  return {
    name,
    id: settings.savedId,
    analysisPeriod: selectedPeriod
      ? { periodToDate: selectedPeriod }
      : { startDate: selectedTimeFrame?.startTime, endDate: selectedTimeFrame?.endTime },
    customTemplateId: isSystemTemplate ? undefined : id,
    systemTemplate: isSystemTemplate ? id : undefined,
    subjects,
    ownerContextId,
    customAnalysisParams: analysisBlocks.reduce(
      (partialParams: AnalysisParams[], block: AnalysisBlock) => [
        ...partialParams,
        ...getParamsForBlock(block.analysisBlockType, params, relative, baselineView),
      ],
      [],
    ),
    customViewOptions: {
      ...customViewOptions,
      relative,
      ...allocationPanelOptions,
      ...investmentOptions,
    },
  };
};

export const parseToRollingPeriodSettings = (
  rollingYears: RollingPeriodSettings | number | undefined,
): RollingPeriodSettings | undefined => {
  if (isNil(rollingYears)) {
    return undefined;
  }
  if (typeof rollingYears === 'number') {
    const rollingPeriod = parseRollingYears(rollingYears);
    return isNil(rollingPeriod) ? { customMonths: Math.round(rollingYears * 12) } : { period: rollingPeriod };
  }
  return rollingYears;
};

export const getYearsFromRollingPeriodSettings = (settings: RollingPeriodSettings | undefined): number | undefined => {
  if (isNil(settings)) {
    return undefined;
  }
  const period = parseRollingPeriod(settings.period);
  if (!isNil(period)) {
    return period;
  }
  return !isNil(settings.customMonths) ? settings.customMonths / 12 : undefined;
};

const parseRollingYears = (rollingYears?: number): RollingPeriodEnum | undefined => {
  switch (rollingYears) {
    case 1:
      return RollingPeriodEnum.ONE_YEAR;
    case 3:
      return RollingPeriodEnum.THREE_YEAR;
    case 5:
      return RollingPeriodEnum.FIVE_YEAR;
    default:
      return undefined;
  }
};

const parseRollingPeriod = (rollingPeriod?: RollingPeriodEnum): number | undefined => {
  switch (rollingPeriod) {
    case RollingPeriodEnum.ONE_YEAR:
      return 1;
    case RollingPeriodEnum.THREE_YEAR:
      return 3;
    case RollingPeriodEnum.FIVE_YEAR:
      return 5;
    default:
      return undefined;
  }
};

const findBlock = (view: AnalysisView, block: AnalysisTypeEnum): AnalysisParams | undefined => {
  return view.customAnalysisParams.find(({ analysisType }) => analysisType === block);
};

const getSettingsMapForAllBlocks = (
  settings: NonQueryViewSettings & URLLevelSettings,
): Record<AnalysisBlockTypeEnum | SpecialFactorTemplateId, Partial<NonQueryViewSettings & URLLevelSettings>> => {
  return {
    factor: {
      factorAnalysisSortBy: settings.factorAnalysisSortBy,
    },
    tearsheet: {
      factorAnalysisSortBy: settings.factorAnalysisSortBy,
    },
    all: {
      factorAnalysisSortBy: settings.factorAnalysisSortBy,
    },
    PERFORMANCE_SUMMARY: {},
    CUMULATIVE_RETURN: {
      venncast: settings.venncast,
    },
    RETURNS_DISTRIBUTION: {},
    ROLLING_RETURN: {
      rollingReturnPeriod: settings.rollingReturnPeriod,
    },
    ROLLING_VOLATILITY: {
      rollingVolatilityPeriod: settings.rollingVolatilityPeriod,
    },
    ROLLING_SHARPE_RATIO: {
      rollingSharpePeriod: settings.rollingSharpePeriod,
    },
    ROLLING_BETA: {
      rollingBetaPeriod: settings.rollingBetaPeriod,
    },
    ROLLING_BENCHMARK_CORRELATION: {
      rollingBenchmarkCorrelationPeriod: settings.rollingBenchmarkCorrelationPeriod,
    },
    HISTORICAL_DRAWDOWN_PERIODS: {
      historicalDrawdownThreshold: settings.historicalDrawdownThreshold,
    },
    FACTOR_CONTRIBUTION_TO_RISK: {},
    FACTOR_CONTRIBUTION_TO_RISK_TREND: {
      rollingFactorRiskPeriod: settings.rollingFactorRiskPeriod,
    },
    FACTOR_CONTRIBUTION_TO_RETURN: {},
    FACTOR_CONTRIBUTION_TO_RETURN_TREND: {
      rollingFactorReturnPeriod: settings.rollingFactorReturnPeriod,
    },
    FACTOR_CONTRIBUTION_TO_EXPOSURE: {},
    FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND: {
      rollingFactorExposuresPeriod: settings.rollingFactorExposuresPeriod,
    },
    DRAWDOWN: {
      hypotheticalDrawdownThreshold: settings.hypotheticalDrawdownThreshold,
    },
    SCENARIO: {
      scenarios: settings.scenariosCustomParamId
        ? settings.scenarios
        : // Clear the scenario ids if we don't have scenarios custom param id to save a copy of the scenario list
          // for this analysis view, and not override default scenarios.
          (settings.scenarios ?? []).map((scenario) => ({
            ...scenario,
            id: undefined,
          })),
    },
    RETURNS_GRID: {
      returnsGridFrequency: settings.returnsGridFrequency,
      returnsGridSubject: settings.returnsGridSubject,
    },
    RISK_STATISTICS: {},
    CORRELATION: {
      residualCorrelation: settings.residualCorrelation,
      showCorrelationValues: settings.showCorrelationValues,
    },
    PERFORMANCE_ATTRIBUTION: {
      attributionsView: settings.attributionsView,
      attributionsSubject: settings.attributionsSubject,
    },
    CUMULATIVE_RESIDUAL_RETURN: {},
    PERCENTAGE_DRAWDOWN: {},
    RESIDUAL_PERFORMANCE_SUMMARY: {},
    NOTABLE_HISTORICAL_PERIODS: {
      selectedNotablePeriods: settings.selectedNotablePeriods,
    },
    HISTORICAL_DRAWDOWN_AND_RALLY_SCANNER: {
      notablePeriodsThreshold: settings.notablePeriodsThreshold,
    },
    PAIRWISE_CORRELATION: {},
    TEXT_BLOCK: {},
    PORTFOLIO_BREAKDOWN: {},
  };
};

const blocksWithCustomSettings = {
  factor: true,
  tearsheet: true,
  all: true,
  PERFORMANCE_ATTRIBUTION: true,
  MARGINAL_PERFORMANCE_ATTRIBUTION: true,
  RETURNS_GRID: true,
  CUMULATIVE_RETURN: true,
  CORRELATION: true,
};

const getParamsForBlock = (
  blockName: AnalysisBlockTypeEnum,
  params: Partial<NonQueryViewSettings>,
  relative: boolean,
  baselineView?: AnalysisView,
): AnalysisParams[] => {
  switch (blockName) {
    case 'HISTORICAL_DRAWDOWN_PERIODS':
      return [
        {
          analysisType: 'PROFORMA_DRAWDOWN',
          relative,
          drawdownThreshold: params.historicalDrawdownThreshold,
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'DRAWDOWN':
      return [
        {
          analysisType: 'FACTOR_DRAWDOWN',
          relative,
          drawdownThreshold: params.hypotheticalDrawdownThreshold,
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'CORRELATION':
      return [
        {
          analysisType: 'CORRELATION',
          relative,
          residual: params.residualCorrelation,
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'ROLLING_RETURN':
      return [
        {
          analysisType: 'ROLLING_RETURN',
          relative,
          rollingYears: getYearsFromRollingPeriodSettings(params.rollingReturnPeriod),
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'ROLLING_SHARPE_RATIO':
      return [
        {
          analysisType: 'ROLLING_SHARPE',
          relative,
          rollingYears: getYearsFromRollingPeriodSettings(params.rollingSharpePeriod),
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'ROLLING_VOLATILITY':
      return [
        {
          analysisType: 'ROLLING_VOLATILITY',
          relative,
          rollingYears: getYearsFromRollingPeriodSettings(params.rollingVolatilityPeriod),
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'SCENARIO':
      const savedScenarioParams = baselineView ? findBlock(baselineView, 'SCENARIO') : undefined;
      return [
        {
          analysisType: 'SCENARIO',
          relative,
          scenarios: params.scenarios ?? [],
          id: savedScenarioParams?.id ?? baselineView?.id,
          selectedNotablePeriods: [],
        },
      ];
    case 'FACTOR_CONTRIBUTION_TO_RETURN_TREND':
      return [
        {
          analysisType: blockName,
          relative,
          rollingYears: params.rollingFactorReturnPeriod,
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'FACTOR_CONTRIBUTION_TO_RISK_TREND':
      return [
        {
          analysisType: blockName,
          relative,
          rollingYears: params.rollingFactorRiskPeriod,
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    case 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND':
      return [
        {
          analysisType: 'FACTOR_EXPOSURES_TREND',
          relative,
          rollingYears: params.rollingFactorExposuresPeriod,
          scenarios: [],
          selectedNotablePeriods: [],
        },
      ];
    default:
      return [];
  }
};

const savedViewSettingsCustomComparator = (
  a: unknown,
  b: unknown,
  key: string | number | symbol | undefined,
): boolean | undefined =>
  isNil(a) && isNil(b)
    ? true
    : Array.isArray(a) || Array.isArray(b)
      ? isEqual(a, b)
      : key === 'period'
        ? isEqualWithFullPeriodAmendment(a as RangeType, b as RangeType)
        : key === 'start' || key === 'end'
          ? // backend return timestamp is end of the day but frontend use start of the day
            moment.utc(a as MomentInput).isSame(moment.utc(b as MomentInput), 'day')
          : key === 'isCategoryOff' && FS.has('test_hide_category_ff')
            ? true
            : undefined;

export const areViewSettingsEqual = (a: unknown, b: unknown) => isEqualWith(a, b, savedViewSettingsCustomComparator);

export const getLocationWithReplacedStrategyId = (pathname: string, search: string, strategyId: number): string => {
  // There should be the following parts:
  // analysis / results / {template id} / {portfolio|investment} / {subject id} / {strategy id (optional)}
  const parts = pathname.split('/');
  const subjectTypeIdx = parts.indexOf('portfolio');
  // Subject type is not 'portfolio' or the URL structure is wrong
  if (subjectTypeIdx === -1 || subjectTypeIdx + 2 !== parts.length) {
    return `${pathname}${search}`;
  }
  // Only append strategyId to the URL if it's not specified.
  // That guarantees that the Recent Views will keep the actual strategy from the URL.
  // Saved Views selected from the list will always have only root id in the URL.
  if (subjectTypeIdx + 2 === parts.length) {
    parts.push(strategyId.toString());
  }
  return `${parts.join('/')}${search}`;
};
