import React from 'react';
import { TooltipLink, FACTOR_CONTRIBUTION_TO_RETURN_HREF } from 'venn-ui-kit';
import FactorSummaryChart from './FactorSummaryChart';
import type { SummaryChartProps } from './factorSummaryTypes';
import { convertDataToExcel } from './utils';
import { FactorAnalysisContext } from '../contexts';

const ReturnSummaryChart = ({
  data,
  title,
  subTitle,
  width,
  onClick,
  transform,
  activeFactorId,
  updateActiveFactorId,
  isFund,
  filterCount,
  chartTitleContainsPortfolioName,
  periodAnnualized,
  dataExportEvent,
  secondaryLabel,
  trackingProps,
}: SummaryChartProps) => {
  const { analysisSubject, relative, categoryActive, downloadMetaData, factorsDescriptions } =
    React.useContext(FactorAnalysisContext);

  const titleText = title || (relative ? 'Active Factor Contributions to Return' : 'Factor Contributions to Return');
  const subTitleText = subTitle || (
    <>
      {periodAnnualized
        ? 'What amount of annualized return is driven by each factor?'
        : 'What amount of cumulative return is driven by each factor?'}
      <TooltipLink
        positions={{
          top: -60,
          left: -90,
        }}
        href={FACTOR_CONTRIBUTION_TO_RETURN_HREF}
        top
      />
    </>
  );

  const excelData = convertDataToExcel(data, titleText, 'Factor', true, analysisSubject);
  return (
    <FactorSummaryChart
      metricType="return"
      data={data}
      title={titleText}
      subTitle={subTitleText}
      percentage
      waterfall
      width={width}
      factorsDescription={factorsDescriptions}
      defaultExtreme={0.04}
      onClick={onClick}
      transform={transform}
      activeFactorId={activeFactorId}
      updateActiveFactorId={updateActiveFactorId}
      relative={relative}
      categoryActive={categoryActive}
      isFund={isFund}
      downloadMetaData={downloadMetaData}
      filterCount={filterCount}
      chartTitleContainsPortfolioName={chartTitleContainsPortfolioName}
      exportData={excelData}
      dataExportEvent={dataExportEvent}
      secondaryLabel={secondaryLabel}
      trackingProps={trackingProps}
      analysisSubject={analysisSubject}
    />
  );
};

export default ReturnSummaryChart;
