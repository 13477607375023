import { compact, last } from 'lodash';
import moment from 'moment';
import { atomFamily, selectorFamily } from 'recoil';
import {
  isHistoricalSubject,
  PRIVATES_ASSET_GROWTH_BLOCKS,
  PRO_FORMA_HISTORICAL_AS_OF_DATE_PARAMETERIZED_BLOCKS,
  PRO_FORMA_HISTORICAL_BLOCKS,
  PRO_FORMA_HISTORICAL_METRICS,
} from 'venn-utils';
import { rangeDebugQuery } from '../async/rangeDebug';
import type { BlockId, Subject } from '../types';
import { blockMetrics } from './blockConfig';
import { blockSettings } from './blockSettings';
import { blockDateRange } from './dateRange';
import { requestSubjects } from './subjects';

export const CURRENT_AS_OF_DATE = 'Current';
export const LAST_UPLOADED_AS_OF_DATE = 'Last Uploaded';

/**
 * Stores selected as of date setting for blocks that allow users to pick
 * a specific as of date for historical portfolios (at the moment only Portfolio Composition block)
 */
export const blockAsOfDateSetting = atomFamily<string, BlockId>({
  key: 'blockAsOfDateSetting',
  default: CURRENT_AS_OF_DATE,
});

/**
 * Returns "as of date" given a pair of [BlockId, Subject]
 */
export const subjectAsOfDate = selectorFamily<number | undefined, [BlockId, Subject | undefined]>({
  key: 'subjectAsOfDate',
  get:
    ([blockId, subject]) =>
    ({ get }) => {
      if (!subject) {
        return undefined;
      }

      const requestSubject = get(requestSubjects([subject]))[0]!;

      // if the subject is not historical portfolio it doesn't have a concept of "as of date"
      if (!isHistoricalSubject(requestSubject)) {
        return undefined;
      }

      const isProForma = get(isProFormaBlockOrMetric(blockId));

      // If block or it's selected metrics don't treat historical portfolio as pro-forma then there is no "as of date"
      if (!isProForma) {
        return undefined;
      }

      const setting = get(blockSettings(blockId));
      const rangeDebug = get(rangeDebugQuery(subject));

      // For public-private growth simulation blocks the as of date is the minimum
      // between latest drifted allocations date and end of previous quarter
      const isPublicPrivateGrowthBlock = PRIVATES_ASSET_GROWTH_BLOCKS.includes(setting.customBlockType);
      if (isPublicPrivateGrowthBlock) {
        const dateRange = get(blockDateRange(blockId));
        const endOfPreviousQuarter = moment.utc(dateRange?.range.to).subtract(1, 'quarter').endOf('quarter').valueOf();
        return Math.min(...compact([endOfPreviousQuarter, rangeDebug?.maxEndDate]));
      }

      // If block has explicit as of date setting we just read from it and return back
      // At the moment only Portfolio Composition block has this setting
      const hasExplicitAsOfDateSetting = PRO_FORMA_HISTORICAL_AS_OF_DATE_PARAMETERIZED_BLOCKS.includes(
        setting.customBlockType,
      );
      if (hasExplicitAsOfDateSetting) {
        const blockAsOfDate = get(blockAsOfDateSetting(blockId));

        if (blockAsOfDate === CURRENT_AS_OF_DATE) {
          return rangeDebug?.maxEndDate;
        }
        if (blockAsOfDate === LAST_UPLOADED_AS_OF_DATE) {
          return last(rangeDebug?.historicalPortfolioMetadata?.allSortedAllocationDates);
        }
        return moment.utc(blockAsOfDate, 'MM/DD/YYYY').endOf('day').valueOf();
      }

      // For all other blocks (e.g. Correlations, Sensitivity Analysis, Forecast metrics in Perf & Risk)
      // we just always return current (meaning latest drifted) allocations
      return rangeDebug?.maxEndDate;
    },
});

/**
 * Returns true if block or one of it's selected metrics treat historical portfolio as pro-forma
 * Examples:
 * - Sensitivity Analysis block
 * - Forecast metrics in Performance & Risk block
 */
export const isProFormaBlockOrMetric = selectorFamily<boolean, BlockId>({
  key: 'isProFormaBlockOrMetric',
  get:
    (blockId) =>
    ({ get }) => {
      const setting = get(blockSettings(blockId));
      const selectedMetrics = get(blockMetrics(blockId));
      const customBlockType = setting.customBlockType;
      const isProFormaBlock = customBlockType && PRO_FORMA_HISTORICAL_BLOCKS.includes(customBlockType);
      const isProFormaMetric = selectedMetrics?.some((metric) => PRO_FORMA_HISTORICAL_METRICS.includes(metric));

      return isProFormaBlock || isProFormaMetric;
    },
});
