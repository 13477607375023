import { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import type { HoldingsDataProps, HoldingsTreeRow } from '../types';
import type { HoldingsCategoryInfo } from 'venn-state';
import { filterHoldingsToLevel, holdingsCategoriesData, holdingsCategoriesTree } from 'venn-state';
import { useHoldings } from './useHoldings';
import type { HoldingsCategory } from 'venn-api';
import useSectorBarHoldingsColumns from './useSectorBarHoldingsColumns';
import { isEmpty, last } from 'lodash';
import type { ValueGetterFunc } from 'ag-grid-community';
import { useHoldingsColumns } from './useHoldingsColumns';
import { assertNotNil } from 'venn-utils';
import { ThemeContext } from 'styled-components';
import type { VennColors } from 'venn-ui-kit';
import { getHoldingsCategoryColor } from 'venn-ui-kit';
import { autoGroupColumnDefStaticConstants, useAgGridStyle } from '../components/grid/AgGridThemeOverrides';

/**
 * maximum tree depth for generating the sector holdings bar chart table
 */
export const HOLDINGS_TREE_MAX_DEPTH = 3;

const hasNonzeroValues = (row: HoldingsTreeRow) => {
  return !row.value.every((v) => v === 0);
};

/**
 * Given a `topRow` category and all the `rowData` rows, they will be mapped to
 * output rows based on the `categoriesTree` passed in for structure to determine if something is a
 * leaf or a category.
 *
 * @param topRow HoldingsTreeRow of the top level category
 * @param rowData all HoldingsTreeRows to be filtered to the top level category above
 * @param categories map of category IDs to category info which holds their
 * @param categoriesTree tree of all categories used to generate the table
 */
export const transformRowData = (
  topRow: HoldingsTreeRow,
  rowData: HoldingsTreeRow[],
  categories: Record<string, HoldingsCategoryInfo>,
  categoriesTree: HoldingsCategory[],
  colors: VennColors,
) => {
  const isLeafLookup = new Map<string, boolean>();
  const checkIsLeaf = (category: HoldingsCategory, level = 1) => {
    const isLeaf = isEmpty(category.children) || level >= HOLDINGS_TREE_MAX_DEPTH;
    isLeafLookup.set(category.id.id, isLeaf);
    !isLeaf && category.children.forEach((child) => checkIsLeaf(child, level + 1));
  };

  const filteredRowData = rowData
    .filter((row) => topRow.path[0] === row.path[0])
    .filter((row) => row.path.length <= HOLDINGS_TREE_MAX_DEPTH && row.path.length > 1);

  const hasChildrenMap = new Map<string, boolean>();
  filteredRowData.forEach((row) => {
    const parentId = categories[assertNotNil(row.key)]!.parentId;
    parentId && hasChildrenMap.set(parentId, true);
  });

  categoriesTree.forEach((category) => checkIsLeaf(category));
  return filteredRowData
    .map((row, index) => {
      const rowKey = assertNotNil(row.key);
      const categoriesRow = categories[rowKey]!;
      const allocation = row.value[0]!;
      const isLeaf = !!isLeafLookup.get(categories[rowKey]!.id);
      const color = getHoldingsCategoryColor(
        categoriesRow.parentName,
        categoriesRow.name,
        'SECTOR',
        categoriesRow.level,
        colors,
      );

      return {
        ...categoriesRow,
        name: categoriesRow.id === 'unknown' ? 'Not Classified' : categoriesRow.name,
        allocation,
        label: '',
        color,
        allocationStruct: { x: index, y: row.value[0] },
        isLeaf,
        bar:
          isLeaf || !hasChildrenMap.has(categories[rowKey]!.id)
            ? [
                {
                  x: color,
                  y: allocation,
                },
              ]
            : undefined,
      };
    })
    .filter((row) => row.allocation !== 0);
};

const autoGroupColumnDef = {
  ...autoGroupColumnDefStaticConstants,
  minWidth: 210,
};

export const holdingsColumnValueGetter: ValueGetterFunc<HoldingsTreeRow> = ({ data, colDef }) => {
  const index = Number(colDef.field);
  if (data?.value[index] === 0) {
    // do not display values with zero allocation
    return '';
  }

  return data?.value[index];
};

export const useSectorHoldingsTreeGrid = ({
  selectedRefId,
  isBarChart,
}: Pick<HoldingsDataProps, 'selectedRefId' | 'isBarChart'>) => {
  const sectorCategoriesTree = useRecoilValue(holdingsCategoriesTree('SECTOR'));
  const sectorCategories = useRecoilValue(holdingsCategoriesData('SECTOR'));
  const theme = useContext(ThemeContext);
  const { Colors } = theme;
  const gridStyle = useAgGridStyle();

  const { data, categories: unfilteredCategories } = useHoldings(selectedRefId);
  const categories = useMemo(
    () => (isBarChart ? filterHoldingsToLevel(unfilteredCategories, HOLDINGS_TREE_MAX_DEPTH) : unfilteredCategories),
    [isBarChart, unfilteredCategories],
  );
  const rowData = useMemo(() => {
    if (!data) {
      return [];
    }
    const getSubTree = (category: HoldingsCategory, path: string[], allocationMap: { [k: string]: number }) => {
      const newPath = [...path, category.id.id === 'unknown' ? 'Not Classified' : category.categoryName];

      let allocation = allocationMap[category.id.id] ?? 0;

      const rowItems: HoldingsTreeRow[] = category.children?.flatMap((childCategory) => {
        const childRow = getSubTree(childCategory, newPath, allocationMap);
        allocation += childRow[0]!.value[0]!;
        return childRow;
      });

      rowItems.unshift({
        label: last(newPath) ?? '',
        key: category.id.id,
        path: newPath,
        value: [allocation],
        type: 'PERCENTAGE',
        isParent: category.children.length >= 1,
      });

      return rowItems;
    };

    if (!data?.breakdowns) return [];

    // allocation maps array per-subject
    const allocationMaps = data.breakdowns.map((breakdown) =>
      Object.fromEntries(breakdown.holdingsContributions.map((bd) => [bd.id.id, bd.allocation])),
    );

    // grouped subject holding tree rows
    const rowDataForSubjects = allocationMaps.map((allocationMap) =>
      categories.flatMap((category) => getSubTree(category, [], allocationMap)),
    );

    const rowData = rowDataForSubjects
      .reduce((rowData: HoldingsTreeRow[], rowDataForSubject: HoldingsTreeRow[]) =>
        rowData.map((row, index) => ({
          ...row,
          value: row.value.concat(rowDataForSubject[index]!.value),
        })),
      )
      .filter(hasNonzeroValues);

    return rowData;
  }, [data, categories]);

  const chartData = useMemo(() => {
    if (!isBarChart) {
      return undefined;
    }

    const topLevelCategories = rowData.filter((row) => row.path.length === 1);
    return topLevelCategories.flatMap((topRow) => [
      {
        id: topRow.path[0],
        isLeaf: false,
        level: 0,
        name: topRow.path[0],
        allocation: undefined,
      },
      ...transformRowData(topRow, rowData, sectorCategories, sectorCategoriesTree, Colors),
    ]);
  }, [rowData, sectorCategories, sectorCategoriesTree, isBarChart, Colors]);

  const hasNegative = chartData?.some((row) => row.allocation && row.allocation < -0.000001) ?? false;

  const treeColumns = useHoldingsColumns({
    selectedRefId,
    valueGetter: holdingsColumnValueGetter,
    isTree: true,
  });
  const sectorBarColumns = useSectorBarHoldingsColumns(hasNegative, theme, gridStyle);
  const columnDefs = isBarChart ? sectorBarColumns : treeColumns;

  return {
    rowData,
    chartData,
    columnDefs,
    autoGroupColumnDef,
  };
};
