import { useContext } from 'react';
import { MultiPortfolioReviewContext } from '../components/common/MultiPortfolioReviewContext';
import { recalculateAllocations } from '../review/helpers';
import { useUploadProFormaPortfolioAllocator } from '../useUniversalUploaderAllocator';
import { getRowId, useSharedMultiPortfolioRightPaneContent } from './useSharedMultiPortfolioRightPaneContent';

export const useMultiProFormaRightPaneContent = () => {
  const {
    data: { parsedResults, selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const excludedInvestments = parsedResults[selectedIndex]!.excludedInvestments;
  const portfolio = recalculateAllocations(parsedResults[selectedIndex]!.parsedPortfolio, excludedInvestments);
  const { accessMode, applyInvestmentRemap, includeInvestment, excludeInvestment } =
    useSharedMultiPortfolioRightPaneContent(portfolio);
  const { rowData, columnDefs, autoGroupColumnDefs, rowClassRules } = useUploadProFormaPortfolioAllocator({
    portfolio,
    excludedInvestments,
    accessMode,
    applyInvestmentRemap,
    includeInvestment,
    excludeInvestment,
  });
  return {
    rowData,
    columnDefs,
    autoGroupColumnDefs,
    rowClassRules,
    getRowId,
  };
};
