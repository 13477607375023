import type { CustomRow, HoldingsRowData, PortfolioBreakdownRowData, PortfolioRowData } from 'venn-components';
import type { PatternObject, SeriesPieDataOptions } from 'highcharts';
import { assertNotNil, Numbers } from 'venn-utils';
import type { HoldingsCategoryInfo } from 'venn-state';
import type { Theme, VennColors } from 'venn-ui-kit';
import { getColorOnIndex, getHoldingsCategoryColor } from 'venn-ui-kit';
import type { CustomBlockTypeEnum, CustomizableMetric } from 'venn-utils';

export interface VennSeriesPieDataOptions extends SeriesPieDataOptions {
  legend?: string;
}

function privateGetPortfolioBreakdownRowData(
  index: number,
  customRow: CustomRow,
  theme: Theme,
  metric?: CustomizableMetric,
): VennSeriesPieDataOptions[] {
  const row = customRow as PortfolioRowData<PortfolioBreakdownRowData>;
  const name = row.key ?? '';
  const value = metric?.key === 'allocation' ? row.value[0]!.weight : undefined;
  const legend = `${name}: ${Numbers.safeFormatPercentage(value, 1)}`;
  return [
    {
      name,
      legend,
      y: value,
      color: getColorOnIndex(theme.Colors, index),
    },
  ];
}

// highcharts line pattern, see https://www.highcharts.com/docs/chart-design-and-style/pattern-fills
const linePattern = 'M 0 0 L 10 10 M 9 - 1 L 11 1 M - 1 9 L 1 11';

function privateGetAssetAllocationRowData(
  customRow: CustomRow,
  categoriesData: { [key: string]: HoldingsCategoryInfo },
  colorPalette: VennColors,
): VennSeriesPieDataOptions[] {
  const row = customRow as HoldingsRowData;
  const { name } = categoriesData[row.id.id]!;
  const color = getHoldingsCategoryColor('', name ?? '', 'ASSET', 1, colorPalette);
  return [
    {
      name,
      legend: `${name} ${Numbers.safeFormatPercentage(row.allocation)}`,
      y: Math.abs(row.allocation),
      color:
        Math.sign(row.allocation) === 1
          ? color
          : ({
              pattern: {
                color,
                path: {
                  d: linePattern,
                  fill: '#000000',
                },
                width: 6,
                height: 6,
              },
            } as unknown as PatternObject),
    },
  ];
}

export function getPieChartData(
  theme: Theme,
  blockType?: CustomBlockTypeEnum,
  metric?: CustomizableMetric,
  data?: CustomRow[],
  categoriesData?: { [key: string]: HoldingsCategoryInfo },
): VennSeriesPieDataOptions[] | undefined {
  if (blockType === 'PORTFOLIO_BREAKDOWN') {
    return data?.flatMap((row, index) => privateGetPortfolioBreakdownRowData(index, row, theme, metric));
  }
  if (blockType === 'ASSET_EXPOSURE') {
    return data
      ?.filter((row) => (row as HoldingsRowData).allocation)
      ?.flatMap((row) => privateGetAssetAllocationRowData(row, assertNotNil(categoriesData), theme.Colors));
  }
  throw new Error('Unsupported Data Type');
}
