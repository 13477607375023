import type { Options } from 'highcharts';
import { compact, isEmpty, isNil, last } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import type { GrowthSimulationPublicPrivateResponse } from 'venn-api';
import { blockBenchmarkConfig, blockLimitedRequestSubjects, type StudioRequestSubject } from 'venn-state';
import { assertNotNil, getAnalyzedSubjectFromRequestSubject, getRequestSubjectFromAnalysisSubject } from 'venn-utils';
import { useBlockId } from '../../../../contexts/BlockIdContext';
import { useConfidenceLevels } from '../../../../logic/useConfidenceLevels';
import { useSubjectColors } from '../../../../logic/useSubjectColors';
import type { Serie, SubjectInfo } from '../../../charts/types';
import {
  confidenceKeyFromPercentage,
  convertToTimeseries,
  FUNDING_FAILURE_POINT_PREFIX,
  FUNDING_FAILURE_SERIES_PREFIX,
  getSaturationLevel,
  seriesNameFromPercentage,
} from '../utils';

export const usePublicPrivateGrowthPercentileChartData = (data: GrowthSimulationPublicPrivateResponse[]) => {
  const blockId = useBlockId();
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));
  const confidenceLevels = useConfidenceLevels();
  const theme = useTheme();

  const publicSubjects: StudioRequestSubject[] = compact([
    ...subjects.filter((subject) => !subject.private),
    benchmarkConfig.type === 'COMMON' && benchmarkConfig.subject
      ? getRequestSubjectFromAnalysisSubject(benchmarkConfig.subject)
      : null,
  ]);

  const subjectColors = useSubjectColors(publicSubjects.map(getAnalyzedSubjectFromRequestSubject));
  const privateSubject = assertNotNil(subjects.filter((subject) => subject.private)[0]);

  const subjectInfos = useMemo(() => {
    const mixedSubjectsInfos: SubjectInfo[] = publicSubjects.map((subject, index) => {
      return {
        subject,
        privateSubject,
        key: index,
        fundingFailureProb: 1 - data[index]!.probabilityOfSuccess,
      };
    });

    const publicSubjectsInfos: SubjectInfo[] = publicSubjects.map((subject, index) => {
      return {
        subject,
        privateSubject: undefined,
        return: data[index]!.forecastReturn,
        volitility: data[index]!.forecastVolatility,
      };
    });

    return [...mixedSubjectsInfos, ...publicSubjectsInfos];
  }, [publicSubjects, privateSubject, data]);

  const subjectsSeries = publicSubjects.flatMap((subject, index) => {
    const mixedNavData = data[index]!.publicAndPrivateNav;
    const confidenceIntervals = compact(
      confidenceLevels.map((level) => {
        const key = confidenceKeyFromPercentage(level.level * 100);
        const data = mixedNavData?.[key];
        if (!data || isEmpty(data) || !level.visible) {
          return null;
        }

        const serie: Serie = {
          name: seriesNameFromPercentage(level.level),
          data: assertNotNil(convertToTimeseries(data, 1.0)),
          color: getSaturationLevel(subjectColors[index]!, level.level),
          id: `${index}_${seriesNameFromPercentage(level.level)}`,
          linkedTo: `${FUNDING_FAILURE_SERIES_PREFIX}_${index}_${seriesNameFromPercentage(level.level)}`,
          key: index,
        };

        return serie;
      }),
    );

    return confidenceIntervals;
  });

  const fundingFailuresSeries: Options['series'] = publicSubjects.flatMap((publicSubject, index) => {
    const mixedNavData = data[index]!.publicAndPrivateNav;
    const fundingFailures = data[index]!.fundingFailures;
    return compact(
      confidenceLevels.map((level) => {
        const key = confidenceKeyFromPercentage(level.level * 100);
        const fundingFailure = fundingFailures?.[key];
        const navData = mixedNavData?.[key];
        if (isNil(fundingFailure) || isEmpty(navData) || !level.visible) {
          return null;
        }

        const lastSuccessfulData = last(navData) ?? [data[index]!.simulationRange.start, 0];

        return {
          data: [
            {
              x: lastSuccessfulData[0],
              y: lastSuccessfulData[1],
              marker: {
                enabled: false,
              },
              events: {
                mouseOver() {
                  return false;
                },
              },
            },
            {
              id: `${FUNDING_FAILURE_POINT_PREFIX}_${index}_${seriesNameFromPercentage(level.level)}`,
              x: fundingFailure.date,
              y: lastSuccessfulData[1],
              color: theme.Colors.Error,
              marker: {
                enabled: true,
                symbol: 'circle',
                radius: 4,
                states: {
                  hover: {
                    radius: 6,
                  },
                },
              },
              metadata: {
                fundingFailure,
                confidenceLevel: level,
                publicSubject,
                privateSubject,
              },
            },
          ],
          lineWidth: 2,
          dashStyle: 'Dot',
          name: `${FUNDING_FAILURE_SERIES_PREFIX}_${index}_${seriesNameFromPercentage(level.level)}`,
          id: `${FUNDING_FAILURE_SERIES_PREFIX}_${index}_${seriesNameFromPercentage(level.level)}`,
          linkedTo: `${index}_${seriesNameFromPercentage(level.level)}`,
          color: getSaturationLevel(subjectColors[index]!, level.level),
          type: 'scatter',
          // Put large negative zIndex so that subject series takes precedence over failure series for hover events
          // Also make sure that between failure series the higher confidence level takes precedence
          // E.g. 50% confidence level -> zIndex = -950
          // 75% confidence level -> zIndex = -925
          zIndex: -1000 + level.level * 100,
        };
      }),
    );
  });

  return {
    subjectsSeries,
    subjectInfos,
    fundingFailuresSeries,
  };
};
