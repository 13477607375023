import React from 'react';
import { TooltipLink, FACTOR_EXPOSURE_HREF } from 'venn-ui-kit';
import FactorSummaryChart from './FactorSummaryChart';
import type { SummaryChartProps } from './factorSummaryTypes';
import { convertDataToExcel } from './utils';
import { FactorAnalysisContext } from '../contexts';

const ExposureSummaryChart = ({
  data,
  title,
  subTitle,
  width,
  onClick,
  activeFactorId,
  updateActiveFactorId,
  isFund,
  filterCount,
  dataExportEvent,
  secondaryLabel,
  trackingProps,
}: SummaryChartProps) => {
  const { analysisSubject, relative, categoryActive, downloadMetaData, factorsDescriptions } =
    React.useContext(FactorAnalysisContext);

  const subTitleText = (
    <>
      {isFund ? 'What factors are this investment exposed to?' : 'What factors are this portfolio exposed to?'}
      <TooltipLink
        positions={{
          top: -60,
          left: -90,
        }}
        href={FACTOR_EXPOSURE_HREF}
        top
      />
    </>
  );
  const titleText = title || (relative ? 'Active Factor Exposures (β)' : 'Factor Exposures (β)');
  const excelData = convertDataToExcel(data, titleText, 'Factor Beta', false, analysisSubject);
  return (
    <FactorSummaryChart
      data={data}
      title={titleText}
      subTitle={subTitle || subTitleText}
      width={width}
      factorsDescription={factorsDescriptions}
      onClick={onClick}
      activeFactorId={activeFactorId}
      updateActiveFactorId={updateActiveFactorId}
      relative={relative}
      categoryActive={categoryActive}
      isFund={isFund}
      filterCount={filterCount}
      exportData={excelData}
      dataExportEvent={dataExportEvent}
      secondaryLabel={secondaryLabel}
      trackingProps={trackingProps}
      downloadMetaData={downloadMetaData}
      analysisSubject={analysisSubject}
    />
  );
};

export default ExposureSummaryChart;
