import type { CSSProperties } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { map } from 'lodash';
import { SORTDIR, StickyNode, FactorLensesContext, useUrlState } from 'venn-components';
import type { AnalysisSubject } from 'venn-utils';
import {
  analyticsService,
  AnalyticsUtils,
  getPrevPageTitle,
  useApi,
  deserializeBoolean,
  serializeBoolean,
  deserializeObject,
  intersectRanges,
  FREQUENCY_DATEPICKER_MODES,
} from 'venn-utils';
import type { DateRange, RangeType, Theme } from 'venn-ui-kit';
import { GetColor, ButtonLink, ZIndex, FACTOR_LENS_FAQ_HREF, getRangeFromType, getFactorMaxRange } from 'venn-ui-kit';
import { Configuration } from './components';
import type { FactorEducationConfig } from '../shared/FactorEducationUtils';
import { AxiomaEquityStyleFactorsConstruction, parseContent } from '../shared/FactorEducationUtils';
import type {
  FactorWithNonSerializedReturns as FactorEntity,
  EntityPerformance,
  FactorLensWithReturns,
  NotableFactorPeriod,
  FactorInputMapping,
  FactorPerformanceTypeEnum,
  FactorHistoryPerformance,
} from 'venn-api';
import { getFactorLensPerformance, getNotableFactorLensPeriods, SupportedErrorCodes } from 'venn-api';

import { PageHeader } from '../shared/page-header';
import Page from '../shared/Page';
import { getSubjectRange } from './components/range-logic';
import FactorInsightsContent from './components/FactorInsightsContent';
import { primaryFactorLens, useCachedLoadableValue } from 'venn-state';

export interface FactorPerformanceProps {
  factorLens?: FactorLensWithReturns;
  theme: Theme;
}

export interface LoadingError {
  message: string;
  code: number;
}

export interface FactorPerformanceState {
  loading: boolean;
  showInputs: boolean;
  /** The selected fund or portfolio whose performance is being compared to that of the factors' */
  subject?: AnalysisSubject;
  range: DateRange;
  factorPerformance: EntityPerformance[];
  inputMappings: FactorInputMapping[];
  selectedFactors: FactorEntity[];
  sortKey?: string;
  sortDir?: SORTDIR;
  error?: string;
  errorCode?: number;
  factorEducationConfig?: FactorEducationConfig;
  selectedNotablePeriod?: number;
}

const getDateRange = (subject: AnalysisSubject | undefined, range: DateRange) => {
  const { start: subjectStart, end: subjectEnd } = getSubjectRange(subject);
  const startDate =
    subjectStart && range.from && subjectStart > range.from
      ? AnalyticsUtils.dateFormat(subjectStart)
      : AnalyticsUtils.dateFormat(range.from);
  const endDate =
    subjectEnd && range.to && subjectEnd < range.to
      ? AnalyticsUtils.dateFormat(subjectEnd)
      : AnalyticsUtils.dateFormat(range.to);
  return `${startDate}-${endDate}`;
};

const FactorPerformance = ({ factorLens, theme }: FactorPerformanceProps) => {
  const primaryFactorLensValue = useCachedLoadableValue(primaryFactorLens);

  const [loading, setLoading] = useState(true);
  const [subject, setSubject] = useState<AnalysisSubject>();
  const [error, setError] = useState<LoadingError>();
  const abortableGetPerformance = useApi(getFactorLensPerformance);
  const [factorEducationConfig, setFactorEducationConfig] = useState<FactorEducationConfig>();

  const [selectedFactorsRange, setSelectedFactorsRange] = useState<DateRange>();
  const [factorPerformance, setFactorPerformance] = useState<FactorHistoryPerformance | undefined>();
  const frequency = factorPerformance?.frequency ?? 'DAILY';
  const inputMappings = factorPerformance?.inputMappings ?? [];
  const entities = factorPerformance?.entities ?? [];
  const [selectedNotablePeriod, setSelectedNotablePeriod] = useState<number>();
  const [sortKey, setSortKey] = useState('periodReturn');
  const [sortDir, setSortDir] = useState(SORTDIR.DESC);

  const [factorNames, setFactorNames] = useUrlState(
    'factors',
    factorLens?.factors.map((f) => f.name),
    JSON.stringify,
    deserializeObject<string[]>,
  );
  const [showInputs, setShowInputs] = useUrlState(
    'showInputs',
    factorNames?.length === 1,
    serializeBoolean,
    deserializeBoolean,
  );

  const selectedFactors = useMemo(() => {
    return factorLens?.factors.filter((factor) => factorNames?.includes(factor.name)) ?? [];
  }, [factorLens?.factors, factorNames]);
  const maxRange = useMemo(() => {
    const factorMaxRange = getFactorMaxRange(frequency);
    if (!selectedFactorsRange) {
      return factorMaxRange;
    }

    return intersectRanges(selectedFactorsRange, factorMaxRange) ?? factorMaxRange;
  }, [selectedFactorsRange, frequency]);

  const [manualRange, setManualRange] = useState<DateRange>({});
  const [computedRange, setComputedRange] = useState<DateRange>({});

  const requestFactorLensPerformance = useCallback(
    async (
      desiredRange: DateRange,
      shouldShowInputs: boolean,
      currentlySelectedFactors: FactorEntity[],
      currentSubject?: AnalysisSubject,
    ) => {
      setError(undefined);

      try {
        const performanceResponse = await abortableGetPerformance(factorLens!.id, true, {
          factorIds: currentlySelectedFactors.map((factor) => factor.id),
          fundId: currentSubject?.fund ? currentSubject.fund.id : undefined,
          portfolioId: currentSubject?.portfolio ? currentSubject.portfolio.id : undefined,
          showInputs: shouldShowInputs,
          start: desiredRange.from,
          end: desiredRange.to,
        });

        return performanceResponse.content;
      } catch (error) {
        if (error.name !== 'AbortError') {
          const message = error.content?.message;
          const code = error.content?.code;
          setError({
            message,
            code,
          });
          const thresholdError = code === SupportedErrorCodes.AnalysisLimitErrorCode;
          analyticsService.factorInsightsFailed({
            overThreshold: thresholdError,
          });
        }
        return undefined;
      }
    },
    [abortableGetPerformance, factorLens],
  );

  const updateFactorAnalysis = useCallback(
    (
      desiredRange: DateRange,
      shouldShowInputs: boolean,
      currentlySelectedFactors: FactorEntity[],
      currentSubject?: AnalysisSubject,
    ) => {
      updateFactorAnalysisAsync();

      async function updateFactorAnalysisAsync() {
        setLoading(true);

        const newFactorPerformance = await requestFactorLensPerformance(
          desiredRange,
          shouldShowInputs,
          currentlySelectedFactors,
          currentSubject,
        );

        setLoading(false);
        setFactorPerformance(newFactorPerformance);

        if (!newFactorPerformance) {
          return;
        }

        const factorMaxRange = getFactorMaxRange(newFactorPerformance.frequency ?? 'DAILY');
        setComputedRange(
          intersectRanges(
            factorMaxRange,
            {
              from: newFactorPerformance.start,
              to: newFactorPerformance.end,
            },
            desiredRange,
          ) ?? factorMaxRange,
        );

        setSelectedFactorsRange({
          from: newFactorPerformance.maxStart,
          to: newFactorPerformance.maxEnd,
        });

        const numberOfFactors = currentlySelectedFactors.length;
        analyticsService.factorsAnalyzed({
          numberOfFactors,
          previousPage: getPrevPageTitle(),
          factors: map(currentlySelectedFactors, 'name'),
          factorInputsOn: numberOfFactors !== 1 ? false : shouldShowInputs,
          comparatorSelected: !!currentSubject,
          dateRange: getDateRange(currentSubject, desiredRange),
        });
      }
    },
    [requestFactorLensPerformance],
  );

  const onDateSelected = useCallback(
    (dateRange: DateRange) => {
      const newRange = dateRange.period
        ? getRangeFromType(
            dateRange.period,
            maxRange,
            FREQUENCY_DATEPICKER_MODES[frequency],
            frequency,
            primaryFactorLensValue,
          )
        : {
            from: dateRange.from || manualRange.from || maxRange.from,
            to: dateRange.to || manualRange.to || maxRange.to,
          };

      setManualRange(newRange);
      updateFactorAnalysis(newRange, showInputs, selectedFactors, subject);
    },
    [
      maxRange,
      frequency,
      primaryFactorLensValue,
      manualRange.from,
      manualRange.to,
      updateFactorAnalysis,
      showInputs,
      selectedFactors,
      subject,
    ],
  );

  useRangeUrlSync(manualRange, onDateSelected);

  useEffect(() => {
    const getFactorPerformance = async () => {
      const notablePeriodsResponse = await getNotableFactorLensPeriods();
      const factors = factorLens?.factors ?? [];
      const notablePeriods = notablePeriodsResponse.content;

      setFactorEducationConfig(
        buildFactorEducationConfig(factors, notablePeriods, AxiomaEquityStyleFactorsConstruction),
      );
    };
    getFactorPerformance();
  }, [factorLens?.factors]);

  // Send segment event for factor dashboard analysis
  const trackNotablePeriodViewed = useCallback(
    (viewType: string) => {
      analyticsService.notablePeriodViewed({
        factor: selectedFactors[0]!.name,
        viewType,
        dateRange: getDateRange(subject, computedRange),
      });
    },
    [computedRange, selectedFactors, subject],
  );

  const onFactorInputsToggle = useCallback(() => {
    setShowInputs(!showInputs);
    updateFactorAnalysis(manualRange, !showInputs, selectedFactors, subject);
    analyticsService.toggleToggled({
      purpose: 'Toggle to show factor inputs',
      selection: !showInputs ? 'On' : 'Off',
      locationOnPage: 'Factor Insights header',
    });
  }, [manualRange, selectedFactors, setShowInputs, showInputs, subject, updateFactorAnalysis]);

  const onSubjectSelect = useCallback(
    (subject?: AnalysisSubject) => {
      setSubject(subject);
      updateFactorAnalysis(manualRange, showInputs, selectedFactors, subject);
    },
    [updateFactorAnalysis, manualRange, showInputs, selectedFactors],
  );

  const onFactorSelect = useCallback(
    (selectedFactors: FactorEntity[]) => {
      const updatedShowInputs = selectedFactors.length === 1;

      setShowInputs(updatedShowInputs);
      setFactorNames(selectedFactors.map((factor) => factor.name));
      setSelectedNotablePeriod(undefined);
      setComputedRange({});
      setManualRange({});
      updateFactorAnalysis({}, updatedShowInputs, selectedFactors, subject);
    },
    [setFactorNames, setShowInputs, subject, updateFactorAnalysis],
  );

  const onSort = useCallback((key: string, dir: SORTDIR) => {
    setSortKey(key);
    setSortDir(dir);
  }, []);

  const canToggleShowInputs = selectedFactors.length === 1;

  const labelFormatter = (id: string, type?: FactorPerformanceTypeEnum): CSSProperties => {
    const { Colors } = theme;
    if (type === 'FUND') {
      // is a fund (UID)
      return { fontStyle: 'italic' };
    }
    if (type === 'FACTOR_INPUT') {
      return { fill: Colors.MidGrey1 };
    }
    return {} as CSSProperties;
  };

  const onSelectNotablePeriod = useCallback(
    (notablePeriodIdx: number | undefined, trigger: string) => {
      setSelectedNotablePeriod(notablePeriodIdx);
      // Track views of notable period
      if (notablePeriodIdx === 0) {
        trackNotablePeriodViewed(trigger);
      }
    },
    [trackNotablePeriodViewed],
  );

  const onSelectNotablePeriodShifted = (slideIdx: number, trigger: string) => {
    onSelectNotablePeriod(slideIdx !== 0 ? slideIdx - 1 : undefined, trigger);
  };

  return (
    <Page>
      <HeaderWrapper>
        <PageHeader
          title="Factor Insights"
          subtitle="Analyze the historical performance of the factors in the Two Sigma Factor Lens."
        >
          <StyledButtonLink
            linkText="Factors FAQ"
            url={FACTOR_LENS_FAQ_HREF}
            ctaTrackingOptions={{
              ctaPurpose: 'Redirect to factors help site',
              ctaText: 'Go to Factors FAQ',
              ctaDestination: 'FAQ',
            }}
          />
        </PageHeader>
      </HeaderWrapper>
      <ConfigurationWrapper>
        <Configuration
          startTimestamp={computedRange.from}
          endTimestamp={computedRange.to}
          maxTimestamp={maxRange.to}
          minTimestamp={maxRange.from}
          frequency={frequency}
          factors={factorLens ? factorLens.factors : []}
          primaryFactorLens={primaryFactorLensValue}
          showInputs={showInputs}
          subject={subject}
          inputsEnabled={canToggleShowInputs}
          onFactorSelect={onFactorSelect}
          onSubjectSelect={onSubjectSelect}
          onInputsToggle={onFactorInputsToggle}
          onDateChange={onDateSelected}
          selectedFactors={selectedFactors}
        />
      </ConfigurationWrapper>
      <Main>
        <FactorInsightsContent
          loading={loading}
          showInputs={showInputs}
          subject={subject}
          range={computedRange}
          factorPerformance={entities}
          inputMappings={inputMappings}
          selectedFactors={selectedFactors}
          sortKey={sortKey}
          sortDir={sortDir}
          error={error}
          factorEducationConfig={factorEducationConfig}
          selectedNotablePeriod={selectedNotablePeriod}
          onSort={onSort}
          labelFormatter={labelFormatter}
          onFactorInputsToggle={onFactorInputsToggle}
          factorLens={factorLens}
          onSelectNotablePeriod={onSelectNotablePeriod}
          onSelectNotablePeriodShifted={onSelectNotablePeriodShifted}
        />
      </Main>
    </Page>
  );
};

export default withTheme((props: FactorPerformanceProps) => (
  <FactorLensesContext.Consumer>
    {({ primaryFactorLens }) => <FactorPerformance factorLens={primaryFactorLens} {...props} />}
  </FactorLensesContext.Consumer>
));

const Main = styled.div`
  padding: 20px;
  min-height: 900px;
  transform: translate3d(0, 0, 0);
`;

const ConfigurationWrapper = styled(StickyNode)`
  display: grid;
  z-index: ${ZIndex.Sticky};
  background-color: ${GetColor.White};
`;

const HeaderWrapper = styled.div`
  padding: 0 20px;
`;

function buildFactorEducationConfig(
  factors: FactorEntity[],
  notablePeriods: NotableFactorPeriod[],
  factorConstruction: { [key: string]: string },
): FactorEducationConfig {
  const config: FactorEducationConfig = {};
  factors.forEach((factor: FactorEntity) => {
    config[factor.name] = {
      name: factor.name,
      description: factor.inputDescription ?? factorConstruction[factor.name],
      notablePeriods: notablePeriods
        .filter((period: NotableFactorPeriod) => period.factorIds.includes(factor.id))
        .map((period: NotableFactorPeriod) => ({
          start: period.startDate,
          end: period.endDate,
          contents: parseContent(period.periodContext).map(({ content }) => content),
        })),
    };
  });
  return config;
}

function useRangeUrlSync(range: DateRange, onUpdateRange: (range: DateRange) => void) {
  const [start, setStart] = useUrlState<number | undefined>('start', undefined, undefined, undefined, true);
  const [end, setEnd] = useUrlState<number | undefined>('end', undefined, undefined, undefined, true);
  const [period, setPeriod] = useUrlState<RangeType | undefined>('period', undefined, undefined, undefined, true);

  // Run on mount to sync the URL state with the component state
  useEffect(() => {
    onUpdateRange({ from: start, to: end, period });
    // eslint-disable-next-line
  }, []);

  // Sync the component state with the URL state
  useEffect(() => {
    setStart(range.from);
    setEnd(range.to);
    setPeriod(range.period);
  }, [range.from, range.to, range.period, setEnd, setPeriod, setStart]);
}

const StyledButtonLink = styled(ButtonLink)`
  @media print {
    display: none;
  }
`;
