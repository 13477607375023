import type { Analysis, FrequencyEnum, NotablePeriod } from 'venn-api';
import type { CustomNotablePeriod, CustomizableMetric } from 'venn-utils';
import { getPeriodLengthWithFrequency } from 'venn-utils';
import { isNil } from 'lodash';

export const NotablePeriodMetadataPropertySet = ['id', 'name', 'start', 'extremumDate'] as const;
export type NotablePeriodMetadataUnionType = (typeof NotablePeriodMetadataPropertySet)[number];
export type NotablePeriodMetadata = Pick<NotablePeriod, NotablePeriodMetadataUnionType>;

export const filterMetrics = (metrics: CustomizableMetric[]) =>
  metrics.filter((metric) => ['recovery', 'amount'].includes(metric.key));
export const filterGlobalMetrics = (metrics: CustomizableMetric[]) =>
  metrics.filter((metric) => ['start', 'end', 'period'].includes(metric.key));

export const ratioToPercentageChange = (ratio?: number) => (!isNil(ratio) ? (ratio - 1) * 100 : undefined);

export const getRecoveryMonths = (
  endDate: number,
  recoveryDate: number,
  analysisFrequency?: FrequencyEnum,
): number | undefined => {
  if (endDate === recoveryDate) {
    return undefined;
  }
  const numericValue = Number.parseFloat(getPeriodLengthWithFrequency(analysisFrequency, endDate, recoveryDate));
  return Number.isNaN(numericValue) ? undefined : numericValue;
};

/** Returns an array where result[i][j] = Notable Period analysis result for i-th SELECTED, PREDEFINED period and j-th subject */
export const getPredefinedPeriodAnalyses = (
  selectedPredefinedPeriods: NotablePeriodMetadata[],
  analysesGroup: (Analysis | undefined)[][] | undefined,
): (NotablePeriod | undefined)[][] => {
  const subjectsCount = analysesGroup?.length ?? 0;

  // predefinedPeriodAnalyses[i][j] = analysis result for i-th subject and j-th predefined period (NOT j-th SELECTED
  // predefined period; the results are unfiltered, and may be ordered differently)
  const predefinedPeriodAnalyses: (NotablePeriod[] | undefined)[] | undefined = analysesGroup?.map((analyses) => {
    const currentAnalysis = analyses.find((analysis) => analysis?.analysisType === 'NOTABLE_PERIODS');
    return currentAnalysis?.notablePeriods?.flatMap((periods: NotablePeriod[]) =>
      periods?.filter((period: NotablePeriod) => period.type === 'PREDEFINED'),
    );
  });

  // result[i][j] = analysis result for i-th selected predefined period and j-th subject
  const result: (NotablePeriod | undefined)[][] = [];
  for (let i = 0; i < selectedPredefinedPeriods.length; i++) {
    result.push([]);
    for (let j = 0; j < subjectsCount; j++) {
      const subjectDataForSelectedPeriod = predefinedPeriodAnalyses?.[j]?.find(
        (period) => period?.id === selectedPredefinedPeriods[i]!.id,
      );
      result[i]!.push(subjectDataForSelectedPeriod);
    }
  }

  return result;
};

/** Returns an array where result[i][j] = Notable Period analysis for i-th CUSTOM period and j-th subject */
export const getCustomPeriodAnalyses = (
  allCustomNotablePeriods: CustomNotablePeriod[] | undefined,
  customNotablePeriods: NotablePeriodMetadata[] | undefined,
  analysesGroup: (Analysis | undefined)[][] | undefined,
): (NotablePeriod | undefined)[][] => {
  const customPeriodsCount = customNotablePeriods?.length ?? 0;
  const subjectsCount = analysesGroup?.length ?? 0;
  // filteredCustomPeriodAnalyses[i][j] = analysis result for i-th subject and j-th custom period
  const filteredCustomPeriodAnalyses: (NotablePeriod[] | undefined)[] | undefined = analysesGroup?.map(
    (analyses: (Analysis | undefined)[]) => {
      const currentAnalysis = analyses.find((analysis) => analysis?.analysisType === 'NOTABLE_PERIODS');
      const filtered = currentAnalysis?.notablePeriods?.flatMap(
        (periods: NotablePeriod[]) =>
          periods &&
          periods
            .filter((period) => period.type === 'CUSTOM')
            .map((x, index) => {
              return { ...x, id: x.id ?? allCustomNotablePeriods?.[index]!.id };
            }),
      );
      return filtered;
    },
  );

  // result[i][j] = analysis result for i-th custom period and j-th subject
  const result: (NotablePeriod | undefined)[][] = [];
  for (let i = 0; i < customPeriodsCount; i++) {
    result.push([]);
    for (let j = 0; j < subjectsCount; j++) {
      const subjectDataForSelectedPeriod = filteredCustomPeriodAnalyses?.[j]?.find(
        (period) => period?.id === customNotablePeriods?.[i]!.id,
      );
      result[i]!.push(subjectDataForSelectedPeriod);
    }
  }

  return result;
};
