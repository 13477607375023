import React from 'react';
import { useRecoilValue } from 'recoil';

import {
  blockBenchmarkConfig,
  blockBenchmarkType,
  blockCustomizableMetricsMap,
  blockInfoGraphicType,
  blockSettings,
  customizedBlock,
} from 'venn-state';

import { Block } from '../../core';
import { hasDisabledBenchmark, hasDisabledIndividualBenchmark } from 'venn-utils';

interface BenchmarkTypeProps {
  id: string;
}

const benchmarkTypes = {
  COMMON: 'Common Benchmark',
  INDIVIDUAL: 'Individual Benchmarks',
  NONE: 'No Benchmark',
};

export const BenchmarkType = ({ id }: BenchmarkTypeProps) => {
  const customBenchmarkType = useRecoilValue(blockBenchmarkType(id));
  const { selectedMetrics } = useRecoilValue(customizedBlock(id));
  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(id));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(id));
  const blockSetting = useRecoilValue(blockSettings(id));
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));
  const hasFullyDisabledBenchmark = hasDisabledBenchmark(blockSetting?.customBlockType, infoGraphicType);

  if (
    hasFullyDisabledBenchmark ||
    customBenchmarkType === 'NONE' ||
    (customBenchmarkType === 'INDIVIDUAL' && hasDisabledIndividualBenchmark(blockSetting?.customBlockType)) ||
    (customBenchmarkType === 'COMMON' && !benchmarkConfig.subject) ||
    ((blockSetting?.customBlockType === 'PRIVATE_PERFORMANCE_SUMMARY' ||
      blockSetting?.customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES') &&
      selectedMetrics
        .map((metric) => metricDefinitionsMap[metric]!)
        .every((metricDefinition) => metricDefinition.needsBenchmark !== true))
  ) {
    return null;
  }

  return <Block.MetadataItem iconType="arrows-h">{benchmarkTypes[customBenchmarkType]}</Block.MetadataItem>;
};
