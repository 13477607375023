import templates from './templates';
import type { AnalysisComparisonTypeEnum, AnalysisView } from 'venn-api';

const mockSavedView = {
  pageNumber: 0,
  resultsPerPage: 25,
  totalResults: 2,
  results: [
    {
      id: '0acaf11c-042e-4c5e-b08b-10d8d458ac8c',
      name: 'First Saved View',
      subjectId: '7eb816ed-0428-42d5-b958-5cfb46d84083',
      analysisSubjectType: 'INVESTMENT',
      subjectName: 'iShares MSCI Canada ETF',
      customTemplateId: templates[0]!.id,
      systemTemplate: null,
      numSnapshots: 0,
      created: 1592348653625,
      updated: 1592557277883,
    },

    {
      id: '0acaf11c-042e-4c5e-b08b-10d8d458ac8c',
      name: 'My Saved Portfolio',
      subjectId: '45939',
      analysisSubjectType: 'PORTFOLIO',
      subjectName: 'Portfolio test',
      customTemplateId: null,
      systemTemplate: templates[0]!.id,
      numSnapshots: 22,
      created: 1572348653625,
      updated: 1572557277883,
    },
  ],
};

export const mockView = {
  id: '71f991cc-a677-455d-adb7-6481e1cf66ef',
  name: 'test save view',
  created: 1596574918097,
  updated: 1596575080738,
  width: 1,
  template: false,
  published: false,
  owner: {
    id: 3000073,
    name: 'test',
    displayName: 'test',
    email: 'test@twosigma.com',
    active: true,
    created: 1532032184901,
    avatarId: 'd381a008-05c5-4e33-94e2-b21a0e486446',
    actualUser: false,
    accountId: 1,
  },
  customTemplateId: '',
  systemTemplate: 'factor',
  analysisPeriod: {
    startDate: 1383263999999,
    endDate: 1564790399999,
  },
  subjects: [
    {
      id: '376816',
      subjectType: 'PORTFOLIO',
      comparisonType: 'PRIMARY' as AnalysisComparisonTypeEnum,
      primary: true,
    },
    {
      id: '74ad9593-b779-4872-b948-8f248920db04',
      subjectType: 'INVESTMENT',
      comparisonType: 'BENCHMARK' as AnalysisComparisonTypeEnum,
      primary: false,
    },
  ],
  customAnalysisParams: [],
  customViewOptions: {
    isPercentageMode: false,
    factorAnalysisSortBy: 'SORT_BY_DEFAULT',
    isTradesView: false,
    relative: false,
  },
} as unknown as AnalysisView;
export default mockSavedView;
