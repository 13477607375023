import { useCallback, useMemo, useState } from 'react';
import type { ExcelCell } from 'venn-utils';
import type { ExcelSheetData } from 'venn-components';
import { compact, isNil } from 'lodash';
import type { FactorExposure, Fund, Portfolio, PortfolioConstraint } from 'venn-api';
import type { TradesStatistics } from './tradesUtils';
import { getTradesBetweenBaseAndOptimized } from './tradesUtils';
import type { PerformanceMetricRow, PerformanceRiskMetricRow } from '../components/cards/PortfolioPerformanceCardView';
import type { FactorMetricRow } from '../components/cards/FactorPerformanceCardView';

type PortfolioLabSheetName = 'constraints' | 'trades' | 'performance' | 'factor';

interface UseCombinedExcelExportDataValue {
  combinedExcelSheetData: ExcelSheetData[];
  onSetConstraintsSheetData: (data: ExcelCell[][] | undefined) => void;
  onSetTradesSheetData: (data: ExcelCell[][] | undefined) => void;
  onSetPerformanceSheetData: (data: ExcelCell[][] | undefined) => void;
  onSetFactorSheetData: (data: ExcelCell[][] | undefined) => void;
}

const useCombinedExcelExportData = (): UseCombinedExcelExportDataValue => {
  const [state, setState] = useState<{ [key in PortfolioLabSheetName]: ExcelCell[][] | undefined }>({
    constraints: undefined,
    trades: undefined,
    performance: undefined,
    factor: undefined,
  });

  const onSetSheetData = useCallback(
    (sheetName: PortfolioLabSheetName, data: ExcelCell[][] | undefined) =>
      setState((prevState) => ({
        ...prevState,
        [sheetName]: data,
      })),
    [],
  );

  const onSetConstraintsSheetData = useCallback(
    (data: ExcelCell[][] | undefined) => onSetSheetData('constraints', data),
    [onSetSheetData],
  );
  const onSetTradesSheetData = useCallback(
    (data: ExcelCell[][] | undefined) => onSetSheetData('trades', data),
    [onSetSheetData],
  );
  const onSetPerformanceSheetData = useCallback(
    (data: ExcelCell[][] | undefined) => onSetSheetData('performance', data),
    [onSetSheetData],
  );
  const onSetFactorSheetData = useCallback(
    (data: ExcelCell[][] | undefined) => onSetSheetData('factor', data),
    [onSetSheetData],
  );

  const combinedExcelSheetData: ExcelSheetData[] = useMemo(
    () =>
      compact([
        isNil(state.constraints) ? null : { sheetName: 'Constraints', data: state.constraints },
        isNil(state.trades) ? null : { sheetName: 'Trades', data: state.trades },
        isNil(state.performance) ? null : { sheetName: 'Performance vs. Current', data: state.performance },
        isNil(state.factor) ? null : { sheetName: 'Factor Performance', data: state.factor },
      ]),
    [state],
  );

  return {
    combinedExcelSheetData,
    onSetConstraintsSheetData,
    onSetTradesSheetData,
    onSetPerformanceSheetData,
    onSetFactorSheetData,
  };
};

export default useCombinedExcelExportData;

const EMPTY: ExcelCell = { value: null };
const ROUGHLY_ZERO = 0.00001;
const labelStyle = { bold: true, style: { horizontalAlignment: 'right' as const } };
const createRow = (label: string, value: string | number | null | undefined, numberProps?: boolean): ExcelCell[] => [
  { value: label, ...labelStyle },
  { value, style: { horizontalAlignment: 'left' }, ...(numberProps ? { percentage: true, digits: 2 } : {}) },
  EMPTY,
  EMPTY,
  EMPTY,
];

export const getTradesData = (
  basePortfolio: Portfolio | undefined,
  solutionPortfolio: Portfolio | undefined,
  tradeStatistics: TradesStatistics | undefined,
  solutionName: string,
): ExcelCell[][] | undefined => {
  if (isNil(basePortfolio) || isNil(solutionPortfolio) || isNil(tradeStatistics)) {
    return undefined;
  }

  const result: ExcelCell[][] = [
    [
      { value: 'Total capital moved:', ...labelStyle },
      { value: tradeStatistics.capitalMoved, digits: 2, currency: true, style: { horizontalAlignment: 'left' } },
      EMPTY,
      EMPTY,
    ],
    [
      { value: 'Number of trades:', ...labelStyle },
      { value: tradeStatistics.tradeCount, style: { horizontalAlignment: 'left' } },
      EMPTY,
      EMPTY,
    ],
    [
      { value: 'Total AUM:', ...labelStyle },
      { value: basePortfolio.allocation, digits: 2, currency: true, style: { horizontalAlignment: 'left' } },
      EMPTY,
      EMPTY,
    ],
    [EMPTY, EMPTY, EMPTY, EMPTY],
  ];

  result.push([
    { value: 'Strategy/investment', bold: true },
    { value: `Base portfolio (${basePortfolio.name}) allocation ($)`, bold: true },
    { value: `${solutionName} allocation ($)`, bold: true },
    { value: 'Trade ($)', bold: true },
  ]);

  const allocations = getTradesBetweenBaseAndOptimized(basePortfolio, solutionPortfolio);
  allocations.forEach((item) =>
    result.push([
      {
        value: `${new Array(item.level * 4).join(' ')}${item.name}${item.isNewOpportunity ? ' [NEW OPPORTUNITY]' : ''}`,
        bold: item.isInvestment,
      },
      { value: item.baseAllocation, digits: 2, bold: item.isInvestment },
      { value: item.optimizedAllocation, digits: 2, bold: item.isInvestment },
      Math.abs(item.trade) < ROUGHLY_ZERO ? { value: '--' } : { value: item.trade, digits: 2, bold: item.isInvestment },
    ]),
  );

  return result;
};

export const getPerformanceData = (
  relative: boolean,
  performanceData: PerformanceMetricRow[],
  riskData: PerformanceRiskMetricRow[],
  benchmarkName?: string,
  formattedPeriod?: string,
): ExcelCell[][] => {
  const result: ExcelCell[][] = [
    createRow('Relative to benchmark:', relative ? 'ON' : 'OFF'),
    createRow('Benchmark:', benchmarkName ?? '--'),
    createRow('Analysis Period:', formattedPeriod ?? '--'),
    [EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
    [
      EMPTY,
      { value: 'Historical', bold: true },
      { value: 'Historical (Δ vs. Current Portfolio)', bold: true },
      { value: 'Forecast', bold: true },
      { value: 'Forecast (Δ vs. Current Portfolio)', bold: true },
    ],
  ];

  const addAllMetricRows = (data: PerformanceMetricRow[] | PerformanceRiskMetricRow[]) => {
    for (const row of data) {
      result.push([
        { value: row.metricName },
        isNil(row.historical)
          ? { value: '--' }
          : { value: row.historical, percentage: row.isPercentage, digits: row.isPercentage ? 1 : 2 },
        isNil(row.historical)
          ? { value: '--' }
          : {
              value: row.historical - (row.historicalBase ?? 0),
              percentage: row.isPercentage,
              digits: row.isPercentage ? 1 : 2,
            },
        isNil(row.forecast)
          ? { value: '--' }
          : { value: row.forecast, percentage: row.isPercentage, digits: row.isPercentage ? 1 : 2, bold: true },
        isNil(row.forecast)
          ? { value: '--' }
          : {
              value: row.forecast - (row.forecastBase ?? 0),
              percentage: row.isPercentage,
              digits: row.isPercentage ? 1 : 2,
            },
      ]);
    }
  };

  result.push([{ value: 'Performance (vs. Current)', bold: true }, EMPTY, EMPTY, EMPTY, EMPTY]);
  addAllMetricRows(performanceData);

  result.push([EMPTY, EMPTY, EMPTY, EMPTY, EMPTY]);
  result.push([{ value: 'Risk (vs. Current)', bold: true }, EMPTY, EMPTY, EMPTY, EMPTY]);
  addAllMetricRows(riskData);

  return result;
};

export const getFactorData = (data: FactorMetricRow[]): ExcelCell[][] => {
  const result: ExcelCell[][] = [
    [EMPTY, { value: 'Forecast', bold: true }, EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
    [
      { value: 'Factors', bold: true },
      { value: 'Exposure (β)', bold: true },
      { value: 'Δ (vs. Current Portfolio)', bold: true },
      { value: 'Cont. to Risk', bold: true },
      { value: 'Δ (vs. Current Portfolio)', bold: true },
      { value: 'Cont. to Return', bold: true },
      { value: 'Δ (vs. Current Portfolio)', bold: true },
    ],
  ];

  for (const row of data) {
    result.push([
      { value: row.metricName, style: { italic: row.isResidual } },
      isNil(row.exposure) ? { value: '--' } : { value: row.exposure, digits: 2, bold: true },
      isNil(row.exposure) ? { value: '--' } : { value: row.exposure - (row.exposureBase ?? 0), digits: 1 },
      isNil(row.contributionToRisk)
        ? { value: '--' }
        : { value: row.contributionToRisk, digits: 2, bold: true, percentage: true },
      isNil(row.contributionToRisk)
        ? { value: '--' }
        : { value: row.contributionToRisk - (row.contributionToRiskBase ?? 0), digits: 1, percentage: true },
      isNil(row.contributionToReturn)
        ? { value: '--' }
        : { value: row.contributionToReturn, digits: 2, bold: true, percentage: true },
      isNil(row.contributionToReturn)
        ? { value: '--' }
        : { value: row.contributionToReturn - (row.contributionToReturnBase ?? 0), digits: 1, percentage: true },
    ]);
  }

  return result;
};

export type PortfolioConstraintWithStatus = PortfolioConstraint & { met: boolean };
export interface ObjectiveConstraintWithStatus {
  objective: string;
  constraint: string;
  constraintValue: number;
  actualValue: number;
  met: boolean;
}

export const getConstraintsData = (
  solutionName: string,
  basePortfolioName: string,
  constraintData: ObjectiveConstraintWithStatus | undefined,
  policyData: PortfolioConstraintWithStatus[] | undefined,
  factorBreakdownData: FactorExposure[],
  newOpportunities: Fund[],
  newOpportunitiesParentStrategyId: number | undefined,
): ExcelCell[][] => {
  const result: ExcelCell[][] = [
    createRow('Portfolio name:', basePortfolioName),
    createRow('Selected solution:', solutionName),
    [EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
    createRow('Objective:', constraintData?.objective ?? 'unknown'),
    createRow('Constraint:', constraintData?.constraint ?? 'unknown'),
    createRow('Constraint value:', constraintData?.constraintValue ?? 'unknown', true),
    createRow('Actual value for solution:', constraintData?.actualValue ?? 'unknown', true),
    createRow('Is objective constraint met:', constraintData?.met ? 'TRUE' : 'FALSE'),
  ];

  if (newOpportunities.length > 0) {
    result.push(
      [EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
      [{ value: 'NEW OPPORTUNITIES', bold: true }, EMPTY, EMPTY, EMPTY, EMPTY],
    );
    newOpportunities.forEach((opportunity) => {
      result.push([{ value: opportunity.name }, EMPTY, EMPTY, EMPTY, EMPTY]);
    });
  }

  if (!isNil(policyData)) {
    result.push(
      [EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
      [{ value: 'ALLOCATION CONSTRAINTS', bold: true }, EMPTY, EMPTY, EMPTY, EMPTY],
    );

    const allocationConstraints = policyData.filter((constraint) => constraint.constraintType === 'ALLOCATION');
    if (allocationConstraints.length === 0) {
      result.push([{ value: 'No allocation constraints' }, EMPTY, EMPTY, EMPTY, EMPTY]);
    } else {
      result.push([
        { value: 'Applies to:', bold: true },
        { value: 'Constraint:', bold: true },
        { value: 'Value:', bold: true },
        EMPTY,
        { value: 'Is met:', bold: true },
      ]);
      allocationConstraints.forEach((constraint) => {
        result.push([
          {
            value: constraint.allEntities
              ? 'All Investments'
              : compact(
                  constraint.targets.map(
                    (target) =>
                      `${target.fundName ?? target.strategyName}${
                        !isNil(newOpportunitiesParentStrategyId) &&
                        target.strategyId === newOpportunitiesParentStrategyId &&
                        !isNil(target.fundId) &&
                        newOpportunities.some((fund) => fund.id === target.fundId)
                          ? ' [NEW OPPORTUNITY]'
                          : ''
                      }`,
                  ),
                ).join(', '),
          },
          {
            value:
              constraint.condition === 'MIN'
                ? 'minimum allocation'
                : constraint.condition === 'MAX'
                  ? 'maximum allocation'
                  : 'locked allocation',
          },
          {
            value:
              constraint.valueType === 'CURRENT_VALUE'
                ? `current value (${
                    constraint.allEntities || constraint.targets.length > 1 ? '$ Mixed' : constraint.value
                  })`
                : constraint.value,
            currency:
              constraint.valueType === 'CURRENCY' ||
              (constraint.valueType === 'CURRENT_VALUE' && !constraint.allEntities && constraint.targets.length === 1),
            percentage: constraint.valueType === 'PERCENT',
            digits: 2,
          },
          constraint.valueType === 'PERCENT' ? { value: '% of the portfolio' } : EMPTY,
          { value: constraint.met ? 'TRUE' : 'FALSE' },
        ]);
      });
    }

    result.push(
      [EMPTY, EMPTY, EMPTY, EMPTY, EMPTY],
      [{ value: 'FACTOR EXPOSURE CONSTRAINTS', bold: true }, EMPTY, EMPTY, EMPTY, EMPTY],
    );

    if (factorBreakdownData.length === 0) {
      result.push([{ value: 'No exposure constraints' }, EMPTY, EMPTY, EMPTY, EMPTY]);
    } else {
      result.push([
        { value: 'Factor:', bold: true },
        { value: 'Current exposure (β):', bold: true },
        EMPTY,
        { value: 'Constraint exposure (β):', bold: true },
        { value: 'Is met:', bold: true },
      ]);

      const significant = factorBreakdownData.filter((item) => !isNil(item.exposure) && item.significant);
      const insignificant = factorBreakdownData.filter((item) => isNil(item.exposure) || !item.significant);

      [...significant, ...insignificant].forEach((breakdown) => {
        const minConstraintIdx = policyData.findIndex(
          (constraint) =>
            constraint.constraintType === 'FACTOR' &&
            breakdown.id === constraint.targets?.[0]?.factorId &&
            constraint.condition === 'MIN',
        );
        const minConstraint = minConstraintIdx === -1 ? undefined : policyData[minConstraintIdx];
        const maxConstraintIdx = policyData.findIndex(
          (constraint) =>
            constraint.constraintType === 'FACTOR' &&
            breakdown.id === constraint.targets?.[0]?.factorId &&
            constraint.condition === 'MAX',
        );
        const maxConstraint = maxConstraintIdx === -1 ? undefined : policyData[maxConstraintIdx];

        result.push([
          { value: breakdown.name, bold: breakdown.significant },
          { value: breakdown.exposure, digits: 2, bold: breakdown.significant },
          EMPTY,
          EMPTY,
          EMPTY,
        ]);

        if (!isNil(minConstraint)) {
          result.push([
            EMPTY,
            EMPTY,
            { value: 'Minimum exposure constraint (β):', ...labelStyle },
            { value: minConstraint.value, digits: 2 },
            { value: policyData[minConstraintIdx]!.met ? 'TRUE' : 'FALSE' },
          ]);
        }
        if (!isNil(maxConstraint)) {
          result.push([
            EMPTY,
            EMPTY,
            { value: 'Maximum exposure constraint (β):', ...labelStyle },
            { value: maxConstraint.value, digits: 2 },
            { value: policyData[maxConstraintIdx]!.met ? 'TRUE' : 'FALSE' },
          ]);
        }
      });
    }
  }

  return result;
};
