import React, { useCallback, useContext } from 'react';
import { withRouter, type RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import {
  StickyHeaderWithSideNav,
  UserContext,
  getMenus,
  getMenuItemById,
  type TABS,
  type SideMenuItem,
} from 'venn-components';
import { GetColor, Headline1 } from 'venn-ui-kit';
import { Routes } from 'venn-utils';
import Section from './components/Section';

interface UrlParams {
  section?: TABS;
}

const PAGE_PADDING = 60;
const PAGE_CONTENT_WIDTH = 820;
const SIDE_NAV_WIDTH = 238;

const AccountPage = ({ history, match }: RouteComponentProps<UrlParams>) => {
  const { profileSettings, hasPermission } = useContext(UserContext);
  const mfaEnabled = profileSettings?.mfaEnabled;
  const selectedSection: TABS | undefined = getMenuItemById(match.params.section).id;

  const redirectToSection = useCallback(
    (section: TABS) => {
      history.push(`${Routes.ACCOUNT_PATH}/${section}`);
    },
    [history],
  );

  const menu = getMenus()
    .filter((item) => !item.requiredPermission || hasPermission(item.requiredPermission))
    .map(
      ({ title, id, hidden }): SideMenuItem<TABS> => ({
        label: title,
        value: id,
        hidden,
        subItems: [] as SideMenuItem<TABS>[],
        badge:
          id === 'security' ? (
            <IconWrapper>
              <StyledIcon className={mfaEnabled ? 'icon-locked' : 'icon-unlocked'} />
            </IconWrapper>
          ) : undefined,
      }),
    );

  const stickyHeaderWithSideNavProps = {
    HeaderContent: (
      <TitleWrapper>
        <Headline1>Settings</Headline1>
      </TitleWrapper>
    ),
    PageContent: (
      <PageContentContainer>
        <Section />
      </PageContentContainer>
    ),
    items: menu,
    selectedItems: selectedSection,
    onSideNavClick: redirectToSection,
    pagePadding: PAGE_PADDING,
    headerSidePadding: PAGE_PADDING,
    headerBottomPadding: 50,
    pageContentContainerSelector: '.sticky-container',
    scrollStartOffset: 200,
  };

  // @ts-expect-error: TODO fix strictFunctionTypes
  return <StickyHeaderWithSideNav<TABS | undefined> {...stickyHeaderWithSideNavProps} />;
};

const IconWrapper = styled.span`
  position: absolute;
  top: -12px;
  height: 20px;
`;

const StyledIcon = styled.span`
  transform: scale(0.5);
  display: block;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 46px 0 20px 0;
  margin-bottom: 40px;
  border-bottom: solid 2px ${GetColor.PaleGrey};
`;

const PageContentContainer = styled.div`
  margin: 0 auto;
  min-width: ${PAGE_CONTENT_WIDTH - SIDE_NAV_WIDTH - 2 * PAGE_PADDING}px;
  max-width: 1200px;
`;
export default withRouter(AccountPage);
