import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ColorUtils, GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { ObjectiveType } from 'venn-utils';
import { Numbers } from 'venn-utils';
import { isNil } from 'lodash';
import type { DropdownWithDescriptionOption } from '../constraints-management';
import { DropdownWithDescription } from '../constraints-management';
import Input from '../input/Input';

const options: DropdownWithDescriptionOption<ObjectiveType>[] = [
  {
    label: 'Maximize Returns',
    value: 'maximizeReturns',
    description: 'Maximize portfolio return within a specified volatility range.',
  },
  {
    label: 'Target Return',
    value: 'targetReturn',
    description: 'Target a specific return with minimum volatility.',
  },
  {
    label: 'Maximize Sharpe',
    value: 'maximizeSharpe',
    description: 'Maximize portfolio sharpe ratio with returns greater than a specified value (%).',
  },
];
const secondaryOptions: { [key in ObjectiveType]: [string, string] } = {
  maximizeReturns: ['with volatility less than ', '%'],
  targetReturn: ['of ', '% with minimum volatility'],
  maximizeSharpe: ['with a return greater than ', '%'],
};

interface DoubleObjectiveSelectorProps {
  selectedObjective?: ObjectiveType;
  selectedConstraintValue?: number;
  onSelectObjective: (objective: ObjectiveType, value: number) => void;
  defaultMinReturn?: number;
  defaultMaxVolatility?: number;
  constraintInputFocused: boolean;
  setConstraintInputFocused: (focused: boolean) => void;
}

export const DoubleObjectiveSelector = ({
  selectedObjective,
  selectedConstraintValue,
  defaultMinReturn,
  defaultMaxVolatility,
  onSelectObjective,
  constraintInputFocused,
  setConstraintInputFocused,
}: DoubleObjectiveSelectorProps) => {
  const defaultObjective = selectedObjective ?? options[0]!.value;

  const [objective, setObjective] = useState<ObjectiveType>(defaultObjective);
  const [constraintValue, setConstraintValue] = useState<number | undefined>();

  const defaultConstraintValue =
    !isNil(selectedObjective) && !isNil(selectedConstraintValue) && objective === selectedObjective
      ? selectedConstraintValue
      : objective === 'maximizeReturns'
        ? defaultMaxVolatility
        : defaultMinReturn;

  const [secondaryLabel, setSecondaryLabel] = useState<[string, string]>(secondaryOptions[defaultObjective]);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);

  const onSelect = (updatedObjective: ObjectiveType) => {
    setObjective(updatedObjective);
    setConstraintValue(undefined);
    setInputValue('');

    setSecondaryLabel(secondaryOptions[updatedObjective]);
    setConstraintInputFocused(true);
  };

  const [secondaryPrefix, secondarySuffix] = secondaryLabel;
  const objectiveLabel = options.find((option) => option.value === objective)?.label;

  const inputPlaceholder = (isNil(defaultConstraintValue) ? undefined : defaultConstraintValue.toString()) ?? '--';

  const displayValue = constraintValue ?? defaultConstraintValue;
  const displayValueFormatted = Numbers.safeFormatNumber(displayValue, 2);

  return (
    <MultiPillButtonContainer>
      <DropdownWithDescription<ObjectiveType> options={options} onSelect={onSelect}>
        {(open, onToggle) => (
          <Tooltip
            content={
              <TooltipContent>
                <h3>Select an objective</h3>
                <div>
                  <i>({objectiveLabel})</i>
                </div>
              </TooltipContent>
            }
            position={TooltipPosition.Bottom}
            isHidden={open}
          >
            <PrimaryPill
              type="button"
              onClick={() => onToggle(!open)}
              active={open}
              isFirstChild
              className="qa-pill-button-1"
            >
              <Icon type="function" /> {objectiveLabel} <Icon type={open ? 'caret-up' : 'caret-down'} />
            </PrimaryPill>
          </Tooltip>
        )}
      </DropdownWithDescription>

      <SeparatorPill>
        <Icon type="plus" />
      </SeparatorPill>
      {constraintInputFocused ? (
        <SecondaryPill>
          {secondaryPrefix}
          <MicroInput
            autoFocus
            selectOnFocus
            placeholder={inputPlaceholder}
            value={inputValue}
            error={inputError}
            onChange={(typedValue: string) => {
              if (isValidNumber(typedValue) || typedValue === '') {
                setInputValue(typedValue);
                setInputError(false);
              }
            }}
            onBlur={() => {
              setConstraintInputFocused(false);
              if (isValidNumber(inputValue)) {
                setConstraintValue(Number.parseFloat(inputValue));
                onSelectObjective(objective, Number.parseFloat(inputValue));
                return;
              }
              if (inputValue === '' && !isNil(defaultConstraintValue)) {
                setConstraintValue(defaultConstraintValue);
                onSelectObjective(objective, defaultConstraintValue);
                return;
              }
              setInputError(true);
              setConstraintValue(undefined);
              setInputValue('');
            }}
          />
          {secondarySuffix}
        </SecondaryPill>
      ) : (
        <Tooltip
          content={
            <TooltipContent>
              <h3>Constrain set objective</h3>
              {!isNil(constraintValue ?? defaultConstraintValue) && (
                <div>
                  <i>
                    ({objectiveLabel} {secondaryPrefix}
                    {constraintValue ?? defaultConstraintValue}
                    {secondarySuffix})
                  </i>
                </div>
              )}
            </TooltipContent>
          }
          position={TooltipPosition.Bottom}
        >
          <SecondaryPillButton
            type="button"
            onClick={() => setConstraintInputFocused(true)}
            className="qa-pill-button-2"
          >
            {secondaryPrefix}
            {displayValueFormatted}
            {secondarySuffix}
          </SecondaryPillButton>
        </Tooltip>
      )}
    </MultiPillButtonContainer>
  );
};

const isValidNumber = (inputValue: string): boolean => {
  const numericValue = Number.parseFloat(inputValue);
  return !Number.isNaN(numericValue) && numericValue >= 0;
};

export const OBJECTIVE_SELECTOR_HEIGHT = 25;

export const MultiPillButtonContainer = styled.div`
  height: ${OBJECTIVE_SELECTOR_HEIGHT}px;
  border-radius: 113px;
  border: 1px solid ${GetColor.Primary.Dark};
  display: flex;
  margin-right: 10px;
`;

const HalfPillCss = css`
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
  color: ${GetColor.Primary.Dark};
  height: ${OBJECTIVE_SELECTOR_HEIGHT - 2}px;
  padding: 4px 10px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  i:first-child {
    margin-right: 4px;
  }
  i:last-child {
    margin-left: 8px;
    font-size: 16px;
    margin-top: -2px;
  }
`;

export const PrimaryPill = styled.button<{
  active: boolean;
  error?: boolean;
  isFirstChild?: boolean;
  isLastChild?: boolean;
}>`
  ${HalfPillCss}
  border-radius:  ${({ isFirstChild, isLastChild }) =>
    isFirstChild ? '113px 0 0 113px' : isLastChild ? '0 113px 113px 0' : '0 0 0 0'};

  &:hover {
    background-color: ${({ error }) => (error ? GetColor.Error : GetColor.Primary.Dark)};
    color: ${GetColor.White};
  }
  ${({ active, error }) =>
    active &&
    css`
      background-color: ${error ? GetColor.Error : GetColor.Primary.Dark};
      color: ${GetColor.White};
    `}

  ${({ error }) =>
    error &&
    css`
      background-color: ${ColorUtils.opacifyFrom(GetColor.Error, 0.1)};
      color: ${GetColor.Error};
    `}
`;

export const SeparatorPill = styled.div`
  min-width: 25px;
  padding: 0 6px;
  &:not(:first-child) {
    border-left: 1px solid ${GetColor.Primary.Dark};
  }
  &:not(:last-child) {
    border-right: 1px solid ${GetColor.Primary.Dark};
  }

  color: ${GetColor.HintGrey};
  i {
    color: ${GetColor.MidGrey1};
  }

  height: ${OBJECTIVE_SELECTOR_HEIGHT - 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SecondaryPillButton = styled.button`
  ${HalfPillCss}
  border-radius: 0 113px 113px 0;
  &:hover {
    background-color: ${GetColor.Primary.Dark};
    color: ${GetColor.White};
  }
`;

const SecondaryPill = styled.div`
  ${HalfPillCss}
  background-color: ${GetColor.White};
  color: ${GetColor.Black};
  border-radius: 0 113px 113px 0;
`;

const MicroInput = styled(Input)`
  height: 14px;
  border: none;

  & > input {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Main, 0.2)};
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 2px;
    min-width: 34px;
    max-width: 50px;
    text-align: right;
  }

  & > input::placeholder {
    color: ${GetColor.HintGrey};
  }
  & > input:-ms-input-placeholder {
    color: ${GetColor.HintGrey};
  }
  & > input::-ms-input-placeholder {
    color: ${GetColor.HintGrey};
  }
`;

const TooltipContent = styled.div`
  padding: 0 5px 5px 5px;
  text-align: center;
`;
