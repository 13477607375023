import moment from 'moment';
import { values, sortBy } from 'lodash';
import type { ReturnCell, ReturnsGridMapping } from 'venn-api';
import type { ReturnsData, ReturnValue } from './types';
import type { AnalysesResults, ReturnsFrequency } from 'venn-utils';
import type { ReturnsGridSubject } from 'venn-components';
import { periodsPerYear, getReturnsFrequencyIndexForMoment } from 'venn-utils';

interface DataDic {
  [year: number]: ReturnsData;
}

/**
 * Converts a {@link ReturnsGridMapping.returnsGridMap} to an array of annual returns.
 *
 * @see {@link getReturnsGridMapFromAnalysesResults} for easily extracting a returnsGridMap from an Analysis.
 */
export function convertToAnnualReturns(
  returnsGridMap: ReturnsGridMapping['returnsGridMap'] | undefined,
  returnsFrequency: ReturnsFrequency,
): ReturnsData[] {
  const returnsGrid = returnsGridMap?.[returnsFrequency];
  if (!returnsGrid) {
    return [];
  }

  const periodLength = periodsPerYear(returnsFrequency);

  const yearToReturnsData: DataDic = {};

  returnsGrid.returnCells.forEach((item) => {
    const date = moment.utc(item.date);
    const year = date.year();

    if (!yearToReturnsData[year]) {
      yearToReturnsData[year] = {
        year,
        returns: new Array(periodLength).fill(null),
        total: undefined,
      };
    }

    yearToReturnsData[year]!.returns[getReturnsFrequencyIndexForMoment(date, returnsFrequency)] = toReturnValue(item);
  });

  returnsGrid.yearlyCumulativeReturn.forEach((item) => {
    const date = moment.utc(item.date);
    const year = date.year();

    const totalReturnValue = toReturnValue(item);
    totalReturnValue.isProxy =
      totalReturnValue.isProxy || yearToReturnsData[year]!.returns.every((r) => r === null || r.isProxy);

    yearToReturnsData[year]!.total = totalReturnValue;
  });

  return sortBy(values(yearToReturnsData), (x) => x.year);
}

/** Used to extract a {@link ReturnsGridMapping.returnsGridMap}, which is useful for using {@link convertToAnnualReturns}. */
export function getReturnsGridMapFromAnalysesResults(
  analysis: AnalysesResults,
  type: ReturnsGridSubject,
): ReturnsGridMapping['returnsGridMap'] | undefined {
  return analysis?.results?.returnsGrid?.[type]?.returnsGridMap;
}

function toReturnValue(cell: ReturnCell): ReturnValue {
  return {
    value: cell.val,
    isInRange: cell.cellTypes.includes('ANALYSIS'),
    isProxy: cell.cellTypes.includes('PROXY'),
    isRaw: cell.cellTypes.includes('ORIGINAL'),
  };
}
