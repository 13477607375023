import { GroupCellClass, NameCellClass, SecondaryCellClass } from '../constants';
import type { CellClickedEvent, ColDef, IGroupCellRendererParams } from 'ag-grid-community';
import type { BulkManageRow } from '../types';
import { isSecondaryInvestment } from '../utils';
import { bulkManageSortFunction } from './bulkManageSortFunction';

const AUTO_GROUP_MIN_WIDTH = 200;
const AUTO_GROUP_MAX_WIDTH = 700;

const FIELD = 'name';

type CustomHeaderComponent = {
  headerComponent: ColDef<BulkManageRow, BulkManageRow[typeof FIELD]>['headerComponent'];
};
type CustomInnerCellComponent = {
  innerRenderer: ColDef<BulkManageRow, BulkManageRow[typeof FIELD]>['cellRendererParams']['innerRenderer'];
  innerRendererParams: ColDef<BulkManageRow, BulkManageRow[typeof FIELD]>['cellRendererParams']['innerRendererParams'];
};
type Props = {
  showGroupHeader: boolean;
  showCheckbox: boolean;
  customHeaderComponent?: CustomHeaderComponent;
  customInnerCellComponent?: CustomInnerCellComponent;
};

export const autoGroupColDef = ({
  showGroupHeader,
  showCheckbox,
  customHeaderComponent,
  customInnerCellComponent,
}: Props): ColDef<BulkManageRow, BulkManageRow[typeof FIELD]> => {
  return {
    headerName: 'Name',
    field: FIELD,
    flex: 3,
    minWidth: AUTO_GROUP_MIN_WIDTH,
    maxWidth: AUTO_GROUP_MAX_WIDTH,
    headerComponent: customHeaderComponent?.headerComponent,
    headerClass: showGroupHeader ? NameCellClass : undefined,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      checkbox: showCheckbox,
      suppressCount: true,
      suppressEnterExpand: true,
      suppressDoubleClickExpand: true,
      innerRenderer: customInnerCellComponent?.innerRenderer,
      innerRendererParams: customInnerCellComponent?.innerRendererParams,
    } satisfies IGroupCellRendererParams<BulkManageRow, BulkManageRow[typeof FIELD]>,
    onCellClicked: ({ node }: CellClickedEvent<BulkManageRow, BulkManageRow[typeof FIELD]>) => {
      node.setSelected(!node.isSelected());
    },
    cellClassRules: {
      [GroupCellClass]: ({ node }) => !!node?.group,
      [SecondaryCellClass]: ({ data }) => isSecondaryInvestment(data),
    },
    comparator: bulkManageSortFunction(),
  };
};
