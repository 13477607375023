import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../contexts';
import { analyticsService, FS, VennEvents } from 'venn-utils';
import { Header, Notes } from './shared';
import { Button, getAppTitle, GetColor, Tooltip, TooltipPosition } from 'venn-ui-kit';
import type { InviteValidationResponse, OperationResult, InviteValidationStatusEnum } from 'venn-api';
import { sendInvitations, validateInvitations } from 'venn-api';
import SmallBanner from './SmallBanner';
import type { EmailValidation } from '../../input';
import { EmailInput } from '../../input';

interface InviteTeamProps {
  onDidSendInvitations?: () => void;
  entryPoint?: string;
}

export enum BANNER_STATUS {
  'ERROR',
  'SUCCESS',
  'NULL',
}

export const INVITE_LIMIT_EXCEEDED_ERROR_CODE = 40059;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ entryPoint, onDidSendInvitations }: InviteTeamProps) => {
  const [emails, setEmails] = useState<EmailValidation[]>([]);
  const [sentEmailsNumber, setSentEmailsNumber] = useState<number>(0);
  const [bannerStatus, setBannerStatus] = useState<BANNER_STATUS>(BANNER_STATUS.NULL);
  const [bannerMessage, setBannerMessage] = useState<string>();
  const [hasSent, setHasSent] = useState<boolean>(false);

  const { profileSettings } = useContext(UserContext);

  const sendEmail = async () => {
    try {
      const pureEmail = emails.map((email) => email.address);
      const { content: sentEmails } = await sendInvitations(pureEmail);

      setSentEmailsNumber(sentEmails.length);
      setHasSent(true);
      setEmails([]);
      setBannerStatus(BANNER_STATUS.SUCCESS);

      analyticsService.usersInvited({
        numberOfInvites: sentEmails.length,
        entryPoint: entryPoint || 'in-app',
      });

      document.dispatchEvent(new CustomEvent(VennEvents.invitationSent));

      onDidSendInvitations?.();

      setTimeout(() => {
        setBannerStatus(BANNER_STATUS.NULL);
      }, 7000);
    } catch (e) {
      const res = await e;
      const resEmails = res?.content?.emails;
      let errorMessage = res?.content?.message ?? 'Something went wrong';

      if (res?.content.code === INVITE_LIMIT_EXCEEDED_ERROR_CODE) {
        const seatUsage = FS.getFeatureDetails('full_access_seat_cap');
        errorMessage =
          'We could not complete your request at this time because your organization ' +
          `has reached its seat maximum (${seatUsage?.usageCount} out of ${seatUsage?.threshold?.count} seats). ` +
          'Please reach out to VennCSS@twosigma.com if you would like assistance.';
      }

      if (!!resEmails && resEmails.length > 0) {
        setEmails(resEmails);
      }
      setBannerStatus(BANNER_STATUS.ERROR);
      setBannerMessage(errorMessage);
    }
  };

  const orgDomain = getDomain(profileSettings?.user.email);

  const hasInvalidEmails = emails.filter((e) => !e.valid).length > 0;
  const emailIsEmpty = emails.length === 0;
  const hasError = hasInvalidEmails || emailIsEmpty;

  const headerText = hasSent
    ? `Want to invite more teammates to ${getAppTitle()}?`
    : `Want to invite your teammates to ${getAppTitle()}?`;
  const placeHolderText = emails.length > 0 ? 'Invite more people…' : 'Invite people by email…';

  return (
    <Wrapper>
      <Header>{headerText}</Header>
      <Notes>Collaborate with colleagues by sending them an invite below.</Notes>
      <CenterBox>
        <SmallNote>Emails:</SmallNote>
        <FlexBox>
          <EmailInput
            placeholder={placeHolderText}
            values={emails}
            error={hasError && !emailIsEmpty}
            onValidate={(email) => validateEmail(email, orgDomain)}
            onChange={(values) => setEmails(values)}
          />
          <Tooltip
            content={<ErrorButtonMessage hasInvalidEmails={hasInvalidEmails} isEmpty={emailIsEmpty} />}
            position={TooltipPosition.Top}
            isHidden={!hasError}
            maxWidth={250}
          >
            <StyledButton dense onClick={sendEmail} disabled={hasError} className="qa-send-invitation">
              Send
            </StyledButton>
          </Tooltip>
        </FlexBox>
        <Hints>Only emails with your company domain can be invited.</Hints>
      </CenterBox>
      {bannerStatus === BANNER_STATUS.ERROR && <SmallBanner message={bannerMessage} error />}
      {bannerStatus === BANNER_STATUS.SUCCESS && (
        <SmallBanner message={`${sentEmailsNumber > 1 ? 'Invites' : 'Invite'} successfully sent.`} />
      )}
    </Wrapper>
  );
};

interface ErrorButtonMessageProps {
  hasInvalidEmails: boolean;
  isEmpty: boolean;
}

const ErrorButtonMessage = ({ hasInvalidEmails, isEmpty }: ErrorButtonMessageProps) => {
  if (isEmpty) {
    return <ErrorMessageWrapper>Please add at least one email before sending an invite</ErrorMessageWrapper>;
  }
  if (hasInvalidEmails) {
    return (
      <ErrorMessageWrapper>
        Please remove <ErrorText>invalid emails</ErrorText> before sending invites
      </ErrorMessageWrapper>
    );
  }
  return null;
};

function getDomain(email?: string): string | undefined {
  if (!email || !email.length) {
    return undefined;
  }
  return email.substring(email.lastIndexOf('@') + 1);
}

function getErrorMessage(status: InviteValidationStatusEnum, domain?: string) {
  const orgDomain = domain || 'yourdomain';
  switch (status) {
    case 'VALID':
      return '';
    case 'VALID_FOR_WORKSPACE':
      return `This user is already using ${getAppTitle()} and will be added to your workspace automatically`;
    case 'INVALID':
      return 'Entry is not a valid email address';
    case 'OUTSIDE_DOMAIN':
      return `Invitees must have @${orgDomain} email addresses`;
    case 'EXISTING_USER':
      return 'This email is already in the organization';
    default:
      return 'There is a problem with this email address';
  }
}

async function validateEmail(email: string, domain?: string): Promise<EmailValidation> {
  let res: OperationResult<InviteValidationResponse>;
  try {
    res = await validateInvitations([email]);
  } catch (e) {
    res = await e;
  }
  const resEmails = res?.content?.emails;
  const validationSuccessful = res?.content?.success;
  if (!!resEmails && resEmails.length > 0) {
    return { address: email, valid: validationSuccessful, message: getErrorMessage(resEmails[0]!.status, domain) };
  }
  return { address: email, valid: false, message: 'The validation service could not be reached' };
}

const ErrorText = styled.span`
  color: ${GetColor.DEPRECATED_DivergingColor.A3};
`;

const Wrapper = styled.div`
  margin-top: 70px;
  padding: 0 20px;
`;

const SmallNote = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 40px;
`;

const FlexBox = styled.div`
  > div:first-child {
    flex: 1;
    margin-right: 20px;
  }
  display: flex;
  justify-content: space-between;
`;

const CenterBox = styled.div`
  width: 420px;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  min-width: auto;
  width: 60px;
  height: 35px;
  margin-right: 0;
`;

const Hints = styled.div`
  color: ${GetColor.HintGrey};
  font-size: 14px;
  text-align: left;
  margin-top: 6px;
`;

const ErrorMessageWrapper = styled.div`
  padding: 10px 15px;
  text-align: left;
`;
