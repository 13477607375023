import type { CSSProperties } from 'react';
import React, { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';
import type { VennColors, Theme, Typography } from 'venn-ui-kit';
import { withTheme } from 'styled-components';

const getStyle = (typography: Typography, colors: VennColors): CSSProperties => ({
  fill: colors.DarkGrey,
  fontSize: 12,
  fontWeight: 700,
  fontFamily: typography.fontFamily,
});

interface ColumnLabelProps {
  text: string;
  x: number;
  y: number;
  width: number;
  lineHeight: number;
  maxLines: number;
  padding: number;
  theme: Theme;
}

interface ColumnLabelState {
  prevProps?: ColumnLabelProps;
  lines: string[];
}

interface WordMeasurementResults {
  measuredWords: WordMeasurement[];
  spaceWidth: number;
}

export interface WordMeasurement {
  word: string;
  width: number;
}

class ColumnLabel extends PureComponent<ColumnLabelProps, ColumnLabelState> {
  static getDerivedStateFromProps(props: ColumnLabelProps, state: ColumnLabelState): Partial<ColumnLabelState> | null {
    if (isEqual(props, state.prevProps)) {
      return null;
    }
    return {
      prevProps: props,
      lines: ColumnLabel.deriveLines(props),
    };
  }

  /**
   * Breaks `props.text` into lines that fit into the specified dimensions
   */
  static deriveLines(props: ColumnLabelProps): string[] {
    const { maxLines } = props;
    const maxLineWidth = props.width - 2 * props.padding;
    const { measuredWords, spaceWidth } = ColumnLabel.measureWords(props);

    const lines = [
      {
        words: [] as string[],
        width: 0,
      },
    ];

    const maxLinesExceeded = measuredWords.some(({ word, width }) => {
      const line = lines[lines.length - 1]!;

      if (line.width + spaceWidth + width <= maxLineWidth) {
        line.words.push(word);
        line.width += spaceWidth + width;
      } else {
        lines.push({
          words: [word],
          width: spaceWidth + width,
        });
      }

      return lines.length > maxLines;
    });

    const result = lines.map((l) => l.words.join(' '));

    if (maxLinesExceeded) {
      return [...result.slice(0, maxLines - 1), `${result[maxLines - 1]!.slice(0, -3)}...`];
    }

    return result;
  }

  /**
   * Returns pixel measurement information for all words in `props.text`
   *
   * TODO(VENN-22780): switch to using the measureString venn-utils utility function instead, because it is about 35x faster.
   */
  static measureWords(props: ColumnLabelProps): WordMeasurementResults {
    const words = (props.text && props.text.split(/\s+/)) || [];

    const svgRoot = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    Object.assign(textElement.style, getStyle(props.theme.Typography, props.theme.Colors));

    svgRoot.appendChild(textElement);
    document.body.appendChild(svgRoot);

    const measuredWords: WordMeasurement[] = words.map((word) => {
      textElement.textContent = word;
      return {
        word,
        width: getSvgComputeLength(textElement),
      };
    });

    textElement.textContent = '\u00A0';
    const spaceWidth = getSvgComputeLength(textElement);

    document.body.removeChild(svgRoot);

    return { measuredWords, spaceWidth };
  }

  constructor(props: ColumnLabelProps) {
    super(props);
    this.state = {
      lines: ColumnLabel.deriveLines(props),
    };
  }

  render() {
    const {
      width,
      maxLines,
      lineHeight,
      padding,
      theme: { Typography, Colors },
    } = this.props;
    const { lines } = this.state;
    const x = this.props.x + width - padding - 10;
    const y = this.props.y + (maxLines - lines.length) * lineHeight;

    return (
      <text x={x} y={y} style={getStyle(Typography, Colors)} textAnchor="end">
        {lines.map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tspan key={index} x={x} y={y} dy={`${index * lineHeight}px`}>
            {line}
          </tspan>
        ))}
      </text>
    );
  }
}

// @ts-expect-error: TODO fix strictFunctionTypes
export default withTheme(ColumnLabel);

const getSvgComputeLength = (textElement: SVGTextElement) => {
  if (!textElement || !textElement.getComputedTextLength) {
    return 0;
  }

  return textElement.getComputedTextLength();
};
