import type { PortfolioMultiUploaderView } from '../../types';
import { ColorUtils, Flexbox, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import React, { type Dispatch, type SetStateAction } from 'react';
import { useOptionPermissions } from './useOptionPermissions';
import { useHasFF } from 'venn-utils';
import { hasDescription, hasHighlightText, hasShortTitle, hasTileHeader } from '../../utils';

type OptionTileProps = {
  option: PortfolioMultiUploaderView;
  onClick: () => void;
  setViewTileHovered?: Dispatch<SetStateAction<PortfolioMultiUploaderView | undefined>>;
};

export const OptionTile = ({ option, onClick, setViewTileHovered }: OptionTileProps) => {
  const { disabled, reason } = useOptionPermissions(option);
  const hasHistoricals = useHasFF('historical_portfolios_ff');
  const title = (() => {
    if (hasShortTitle(option)) {
      return option.shortTitle(hasHistoricals) ?? option.title(hasHistoricals);
    }
    return option.title(hasHistoricals);
  })();

  return (
    <Tooltip isHidden={!disabled} content={reason}>
      <OptionTileButton
        disabled={disabled}
        data-testid={`option-tile-${option.id}`}
        aria-label={`option-tile-${option.id}`}
        key={option.id}
        onClick={onClick}
        onMouseEnter={() => setViewTileHovered && setViewTileHovered((_) => option)}
        onMouseLeave={() => setViewTileHovered && setViewTileHovered((_) => undefined)}
        className="group"
      >
        <FlexWrapper>
          {hasHistoricals && hasTileHeader(option) && (
            <StyledTileCategoryText
              className="group-hover:bg-venn-dark-blue group-hover:text-venn-white group-hover:rounded-none"
              disabled={disabled}
            >
              {option.tileHeader}
            </StyledTileCategoryText>
          )}
          <OptionTileBody>
            <Flexbox direction="column" justifyContent="flex-start" alignItems="flex-start" gap={4}>
              <OptionHeader disabled={disabled}>
                {disabled && <StyledIcon type="lock" />}
                {title}
              </OptionHeader>
              {!disabled && (
                <>
                  <OptionDescription>{hasDescription(option) && option.description(hasHistoricals)}</OptionDescription>
                  {hasHighlightText(option) && (
                    <span>
                      <Badge>{hasHistoricals ? 'NEW' : 'BETA'}</Badge>
                      &nbsp;
                      <HighlightedFeatureDescription>
                        {option.highlightText(hasHistoricals)}
                      </HighlightedFeatureDescription>
                    </span>
                  )}
                </>
              )}
            </Flexbox>
            {!disabled && <StyledAngleIcon type="angle-right" />}
          </OptionTileBody>
        </FlexWrapper>
      </OptionTileButton>
    </Tooltip>
  );
};

const PADDING_TOP = 12;
const PADDING_RIGHT = 16;
const HEADER_HEIGHT = 32;

const StyledAngleIcon = styled(Icon)`
  font-size: 16px;
  color: ${GetColor.DarkBlue};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionTileBody = styled.div`
  display: flex;
  padding-top: ${PADDING_TOP}px;
  padding-left: ${PADDING_RIGHT}px;
  padding-right: ${PADDING_RIGHT}px;
  padding-bottom: ${PADDING_TOP}px;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

const StyledTileCategoryText = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: ${(props) =>
    props.disabled
      ? ColorUtils.opacifyFrom(GetColor.GreyScale.Grey80, 0.05)
      : ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.05)};
  color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey80 : GetColor.DarkBlue)};
  top: 0px;
  width: 100%;
  padding-left: ${PADDING_RIGHT}px;
  height: ${HEADER_HEIGHT}px;
  border-radius: 4px 4px 0 0;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const OptionTileButton = styled.button<{ disabled: boolean }>`
  :disabled {
    pointer-events: none;
  }
  width: 100%;
  border: 1px solid ${(props) => (props.disabled ? GetColor.GreyScale.Grey30 : GetColor.DarkBlue)};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  &:hover {
    border-color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey30 : GetColor.DarkBlue)};
    background-color: ${(props) =>
      props.disabled
        ? ColorUtils.opacifyFrom(GetColor.GreyScale.Grey80, 0.1)
        : ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.05)};
  }
`;

const HighlightedFeatureDescription = styled.span`
  color: ${GetColor.HighlightDark};
  font-size: 14px;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  line-height: 1.5;
  font-weight: 700;
  font-size: 10px;
  height: 14px;
`;

const OptionHeader = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey80 : GetColor.DarkBlue)};
  font-size: 20px;
`;

const StyledIcon = styled(Icon)`
  font-size: 14px;
`;

const OptionDescription = styled.div`
  color: ${GetColor.GreyScale.Grey70};
  font-size: 14px;
`;
