import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { Field } from 'venn-components';
import { BrowserWarning, FormInput, createField, useForm, onAutoFillStart, onAutoFillCancel } from 'venn-components';
import {
  AuthWrapperWithFooter,
  AuthButton,
  AuthFormErrors,
  AuthLogo,
  AuthValidators,
  AuthenticationFooter,
  TryAnotherWay,
  ContentWrapper,
  AuthLoader,
} from './components';

import { getAppTitle, GetColor, getTextThemeProvider } from 'venn-ui-kit';
import type {
  AuthenticationError,
  AuthenticationStatus,
  AccountCreationRequest,
  LoginResponse,
  SignupUser,
} from 'venn-api';
import { signin } from 'venn-api';
import type { RouteComponentProps } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { ForgotPasswordRoute } from './ForgotPassword';
import { useHasFF } from 'venn-utils';

const { required, withMessage } = AuthValidators;
const emailValidator = AuthValidators.email;

const NOT_VALID_EMAIL = 'Username must be a valid email address.';
const BASE_TWO_FACTOR_MESSAGE = 'You may also use one of your backup codes here.';

interface SignInState {
  fields: SignInFormFields;
  twoFactorRequired?: boolean;
  tryAnotherWay: boolean;
  backupCodes: boolean;
}

interface SignInFormFields {
  email: Field<string>;
  password: Field<string>;
  mfaCode?: Field<string | number | null>;
}

interface SignInFormValues {
  email: string;
  password: string;
  mfaCode?: string | number;
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
`;

const StyledActionSpan = styled.span`
  font-size: 14px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.White};
`;

const AuthenticationInfo = styled.p`
  font-size: 12px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.White};
  font-weight: bold;
`;

const AuthenticationHint = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.HintGrey};
`;

const ActionNativeLink = styled.a`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;

  &:hover {
    color: ${GetColor.White};
  }
`;

const StyledAuthForm = styled.form`
  width: 330px;
  padding: 48px 0 0;
`;

const ForgotPasswordLink = styled(Link)`
  color: ${GetColor.LightGrey};
  font-size: 14px;

  &:hover {
    color: ${GetColor.White};
  }
`;

const MoreInfoLink = styled.a`
  color: ${GetColor.LightGrey};
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: ${GetColor.White};
  }
`;

const MoreInfo = styled.p`
  color: ${GetColor.LightGrey};
  font-size: 14px;
`;

interface SignInProps extends RouteComponentProps {
  error?: AuthenticationError;
  loginResponse: LoginResponse | null;
  credentials: SignupUser;
  pending?: boolean;
  onSubmit: (user: Partial<SignupUser> | Partial<AccountCreationRequest>) => Promise<AuthenticationStatus> | void;
}

const SignIn = ({ credentials, loginResponse, error, pending, onSubmit }: SignInProps) => {
  const hasMfaUpdatesFF = useHasFF('mfa_updates_ff');

  const [formState, setFormState] = useState<SignInState>({
    fields: {
      email: createField('email', credentials?.email || '', [
        required('Email'),
        withMessage(emailValidator, NOT_VALID_EMAIL),
      ]),
      password: createField('password', credentials?.password || '', required('Password')),
    },
    twoFactorRequired: false,
    tryAnotherWay: false,
    backupCodes: false,
  });

  const [autofilledFields, setAutofilledFields] = useState<Set<string>>(new Set());
  const ref = useCallback((node: HTMLInputElement | null) => {
    node?.addEventListener('animationstart', (e: AnimationEvent) => {
      if (e.animationName === onAutoFillStart.getName()) {
        setAutofilledFields((prev) => (node?.id ? new Set(prev).add(node?.id) : prev));
      } else if (e.animationName === onAutoFillCancel.getName()) {
        setAutofilledFields((prev) => {
          if (node?.id) {
            const newFields = new Set(prev);
            newFields.delete(node?.id);
            return newFields;
          }
          return prev;
        });
      }
    });
  }, []);
  const areUsernamePasswordAutofilled = useMemo(() => {
    return autofilledFields.has('email') && autofilledFields.has('password');
  }, [autofilledFields]);

  const { twoFactorRequired, tryAnotherWay, backupCodes } = formState;

  useEffect(() => {
    const twoFactor: boolean = loginResponse ? loginResponse.mfaRequired : false;
    if (twoFactor) {
      setFormState((prev) => ({
        ...prev,
        twoFactorRequired: true,
        fields: {
          ...prev.fields,
          mfaCode: createField<string | number | null>('mfaCode', null, required('Authentication code')),
        },
      }));
    }
  }, [loginResponse]);

  const handleSubmit = async (values: SignInFormValues) => {
    const { email, mfaCode, password } = values;

    let submitFields: Partial<SignupUser> = {
      email,
      password,
    };

    if (fields.mfaCode) {
      const mfaCodeString = mfaCode ? mfaCode.toString() : '';
      const mfaType = mfaCodeString.length !== 9 ? loginResponse?.primaryMfaType : 'BACKUP_CODE';

      submitFields = {
        ...submitFields,
        mfaCode: mfaCodeString,
        mfaType,
      };
    }

    setFormState((prev) => ({
      ...prev,
      fields: {
        ...prev.fields,
        email: {
          ...prev.fields.email,
          value: email,
        },
        password: {
          ...prev.fields.password,
          value: password,
        },
      },
    }));

    await onSubmit(submitFields);
  };

  const [fields, isValid, isSubmitting, submit] = useForm<SignInFormValues>(
    Object.values(formState.fields),
    handleSubmit,
  );
  const overrideDisabled = areUsernamePasswordAutofilled && !twoFactorRequired;
  const disabled = (!isValid && !overrideDisabled) || isSubmitting;

  const useAnotherWay = () => {
    setFormState((prev) => ({ ...prev, tryAnotherWay: true }));
  };

  const useBackupCodes = () => {
    setFormState((prev) => ({ ...prev, tryAnotherWay: false, backupCodes: true }));
  };

  const requestManualAuthentication = async () => {
    const { email, password } = fields;
    const submitFields: Partial<SignupUser> = {
      email: email!.value,
      password: password!.value,
      mfaType: 'MANUAL',
    };

    return signin(submitFields);
  };

  const resendCode = () => {
    const { email, password } = fields;
    const submitFields: Partial<SignupUser> = {
      email: email!.value,
      password: password!.value,
    };

    onSubmit(submitFields);
  };

  const twoFactorPrompt = useCallback(() => {
    if (backupCodes) {
      return 'Use one of your backup codes here.';
    }

    if (loginResponse?.primaryMfaType === 'SMS') {
      const { phoneNumber } = loginResponse;
      return `A text message with a verification code was sent to phone number ending in ${phoneNumber}. ${BASE_TWO_FACTOR_MESSAGE}`;
    }
    if (loginResponse?.primaryMfaType === 'EMAIL') {
      return 'A verification code was sent to your email, please enter it to continue signing in.';
    }

    return `Enter the verification code from Google Authenticator. ${BASE_TWO_FACTOR_MESSAGE}`;
  }, [backupCodes, loginResponse]);

  const twoFactorPlaceholder = useCallback(() => {
    if (backupCodes) {
      return 'Backup code';
    }

    return 'Authentication or backup code';
  }, [backupCodes]);

  const primaryIsSMS = loginResponse && loginResponse.primaryMfaType === 'SMS';
  const loading = pending ? 'Loading' : isSubmitting ? 'Authenticating' : '';

  return (
    <>
      <BrowserWarning />
      <AuthWrapperWithFooter>
        <ContentWrapper isLoading={!!loading}>
          {loading && <AuthLoader title={loading} />}
          {!loading && (
            <FormWrapper>
              <AuthLogo />
              <StyledAuthForm onSubmit={submit}>
                {!twoFactorRequired && (
                  <>
                    <FormInput
                      inputRef={ref}
                      inputId="email"
                      label="Email"
                      autoComplete="email"
                      {...fields.email}
                      name="email"
                    />
                    <FormInput
                      inputRef={ref}
                      type="password"
                      inputId="password"
                      label="Password"
                      autoComplete="password"
                      {...fields.password}
                      name="password"
                    />
                  </>
                )}
                {!tryAnotherWay && twoFactorRequired && (
                  <>
                    <AuthenticationInfo>Two-Factor Authentication</AuthenticationInfo>
                    <AuthenticationHint>{twoFactorPrompt()}</AuthenticationHint>
                    <FormInput errorHidden inputId="mfaCode" label={twoFactorPlaceholder()} {...fields.mfaCode} />
                  </>
                )}
                {tryAnotherWay ? (
                  <TryAnotherWay
                    email={fields.email!.value!.toString()}
                    requestManualAuthentication={requestManualAuthentication}
                    backupCodes={useBackupCodes}
                  />
                ) : (
                  <>
                    <AuthFormErrors error={error && error.message} />
                    <AuthButton dominant disabled={disabled} type="submit">
                      Sign in
                    </AuthButton>
                  </>
                )}
              </StyledAuthForm>
              {twoFactorRequired && !tryAnotherWay && (
                <StyledActionSpan>
                  {primaryIsSMS ? (
                    <>
                      Didn't get a code? <ActionNativeLink onClick={resendCode}>Resend</ActionNativeLink> or{' '}
                      <ActionNativeLink onClick={useAnotherWay}>try another way</ActionNativeLink>
                    </>
                  ) : (
                    <>
                      Having trouble with your authenticator app?{' '}
                      <ActionNativeLink onClick={useAnotherWay}>Try another way</ActionNativeLink>
                    </>
                  )}
                  {hasMfaUpdatesFF &&
                    loginResponse?.primaryMfaType !== 'EMAIL' &&
                    ", or contact your organization's Workspace Administrator to reset it for you under the Settings > Security tab"}
                </StyledActionSpan>
              )}
              {!twoFactorRequired && !tryAnotherWay && (
                <>
                  <ForgotPasswordLink className="qa-forgot-password" to={ForgotPasswordRoute}>
                    Forgot password?
                  </ForgotPasswordLink>
                  <MoreInfo className="qa-more-client-info">
                    {`Not a ${getAppTitle()} client?`}{' '}
                    <MoreInfoLink href={`https://${getTextThemeProvider().MarcomSiteUrl}/`} className="qa-info-link">
                      Click here
                    </MoreInfoLink>{' '}
                    to learn more and talk to our team.
                  </MoreInfo>
                </>
              )}
            </FormWrapper>
          )}
        </ContentWrapper>
        <AuthenticationFooter />
      </AuthWrapperWithFooter>
    </>
  );
};

export default withRouter(SignIn);
