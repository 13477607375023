import type { HoldingsRowData } from 'venn-components';
import type { SeriesMapDataOptions } from 'highcharts';
import { groupBy, map } from 'lodash';
import type { HoldingsCategoryInfo } from 'venn-state';
import { COUNTRIES_TO_CODE } from './countryToCode';
import sum from 'lodash/sum';

const REGIONS_DISPLAYED = new Set([
  'North America',
  'Latin America',
  'United Kingdom',
  'Europe-developed',
  'Europe-emerging',
  'Africa/Middle East',
  'Japan',
  'Australasia',
  'Asia-developed',
  'Asia-emerging',
]);

/** `getRegion` is not expected to support 'unknown' so make sure to update or filter that out before calling */
export const getRegion = (id: string, categoriesData: { [key: string]: HoldingsCategoryInfo }) => {
  if (!id || !categoriesData[id]) {
    throw new Error(`Invalid category - impossible to find ${id} in ${categoriesData}`);
  }
  let currentId = id;
  while (!REGIONS_DISPLAYED.has(categoriesData[currentId]!.name)) {
    if (!categoriesData[currentId]!.parentId) {
      throw new Error(`Invalid category - impossible to find parent for category ${categoriesData[currentId]!.name}`);
    }
    currentId = categoriesData[currentId]!.parentId!;
  }
  return categoriesData[currentId]!.name;
};

export function getMapChartData(
  categoriesData: { [key: string]: HoldingsCategoryInfo },
  data?: HoldingsRowData[],
): SeriesMapDataOptions[] {
  if (!data) return [];

  const countries = data.filter((row) => categoriesData[row.id.id]!.name in COUNTRIES_TO_CODE);
  const groupedByRegion = groupBy(countries, (row) => getRegion(row.id.id, categoriesData));
  const summedByRegion = {};
  Object.keys(groupedByRegion).forEach((key) => {
    summedByRegion[key] = sum(map(groupedByRegion[key], (value) => value.allocation));
  });

  const result = countries
    .map((row) => {
      const id = row.id.id;
      const countryName = categoriesData[id]!.name;
      const region = getRegion(id, categoriesData);
      return [COUNTRIES_TO_CODE[countryName], summedByRegion[region], region] as SeriesMapDataOptions;
    })
    .filter((row) => row[1]);

  return result;
}
