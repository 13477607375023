import React from 'react';

import { useTheme } from 'styled-components';
import type { HistoricalPortfolioAllocationItem } from 'venn-api';
import { useAnalysis } from 'venn-components';

import { assertNotNil } from 'venn-utils';
import AreaChart from '../../../studio-blocks/components/charts/AreaChart';
import type { Serie } from '../../../studio-blocks/components/charts/types';
import { useSelectedMetrics } from '../../../studio-blocks/logic/useSelectedMetrics';

interface HistoricalAllocationsTimeSeriesContentProps {
  id: string;
}

const convertItemsToSeries = (item: HistoricalPortfolioAllocationItem): Serie => {
  return {
    name: item.label,
    data: item.allocations.filter(([_, value]) => value !== 0.0),
    type: 'area',
  };
};

export const HistoricalAllocationsTimeSeriesContent = ({ id }: HistoricalAllocationsTimeSeriesContentProps) => {
  const { requests, analyses } = useAnalysis(id);

  const analysis = analyses[0]![0]!;
  const request = requests[0]!;

  const selectedMetric = useSelectedMetrics()[0]!;

  const theme = useTheme();

  const items = analysis?.historicalPortfolioAllocationsTimeSeries?.[0]?.[assertNotNil(selectedMetric?.metricPath)] as
    | HistoricalPortfolioAllocationItem[]
    | undefined;

  if (!items) {
    return null;
  }

  const series = items.map(convertItemsToSeries);

  return (
    <AreaChart theme={theme} series={series} frequency={request.frequency} yAxisUnitFormat="percent" stackingEnabled />
  );
};
