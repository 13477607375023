import React, { useContext, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { orderBy, toString } from 'lodash';
import type {
  FactorExposureFund,
  ContributionExposure,
  FactorExposureStrategy,
  FactorExposureComponents,
  PortfolioContributionFactorExposureNode,
} from 'venn-api';
import { analysis } from 'venn-api';
import type { TimeFrame } from 'venn-utils';
import { getAnalysisRequest, useApi, analyticsService } from 'venn-utils';
import { Button, Icon, GetColor, Loading, ZIndex } from 'venn-ui-kit';
import type { DataType } from './chart/Chart';
import Chart, { ChartType } from './chart/Chart';
import Explanation from './Explanation';
import Modal from './Modal';
import Context from './context';
import { contributionTypesMap, riskTypesMap } from './utils';
import Notification from '../../notification/Notification';
import { Severity as NotificationSeverityType } from '../../notification/Types';
import { TOTAL_ID, RISK_FREE_RATE_ID } from '../constants';
import { FactorAnalysisContext } from '../../contexts/factor-analysis-context';

const Body = styled.div<{ right: boolean }>`
  position: absolute;
  z-index: ${ZIndex.Cover};
  border: 1px solid ${GetColor.White};
  background: ${GetColor.White};
  display: flex;
  flex-direction: column;
  min-width: 383px;
  padding: 16px;
  box-shadow:
    0 0 2px 0 rgba(118, 131, 143, 0.5),
    0 1px 2px 0 rgba(118, 131, 143, 0.5);
  transform: translateY(-50%);
  ${({ right }) =>
    right
      ? css`
          transform: translate3d(calc(-100% - 20px), -50%, 0);
        `
      : css`
          transform: translate3d(20px, -50%, 0);
        `} &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 7px solid black;
    border-color: transparent transparent ${GetColor.White} ${GetColor.White};
    box-shadow: -3px 3px 3px 0 rgba(118, 131, 143, 0.5);
    top: 50%;
    ${({ right }) =>
      right
        ? css`
            right: -6px;
            transform: translate3d(0, -50%, 0) rotate(-135deg);
          `
        : css`
            left: -6px;
            transform: translate3d(0, -50%, 0) rotate(45deg);
          `};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 15px;
  color: ${GetColor.Grey};
  cursor: pointer;
  font-size: 18px;
  > .fas {
    font-weight: normal;
  }
`;

const Header = styled.h4`
  height: 17px;
  font-size: 14px;
  font-weight: 700;
`;

const OpenModalButtonWrapper = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const qaClass = 'qa-view-all';

export interface FactorPopoverRendererProps {
  top: number;
  left: number;
  tooltipRight?: boolean;
  timestamp?: number;
  actualTimeFrame?: TimeFrame;
  usePercentages: boolean;
  factorId: string;
  factorName: string;
  factorType: 'risk' | 'return' | 'exposure';
  factorValue: number;
  factorSignificant: boolean;
  regressionYears: number;
  onClose(): void;
}

const FactorPopoverRenderer = ({
  left,
  top,
  tooltipRight,
  onClose,
  timestamp,
  factorName,
  factorValue,
  factorId,
  factorType,
  factorSignificant,
  regressionYears,
  usePercentages,
  actualTimeFrame,
}: FactorPopoverRendererProps) => {
  const { isModalOpen, title, openModal } = useContext(Context);
  const { isHistoricalAnalysis, analysisSubject, relative, categoryActive } = useContext(FactorAnalysisContext);

  const [popoverOptions] = useState({
    analysisSubject,
    relative,
    categoryActive,
    timestamp,
    timeFrame: actualTimeFrame,
    regressionYears,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [components, setComponents] = useState<FactorExposureComponents>({
    factors: [],
    funds: [],
    strategies: [],
    portfolio: {} as PortfolioContributionFactorExposureNode,
  });
  const { strategies, funds } = components;
  const bodyRef = useRef<HTMLDivElement>(null);
  const fetchAnalysis = useApi(analysis);

  useEffect(() => {
    const loadComponents = async () => {
      const analysisType = popoverOptions.timestamp ? 'FUND_FACTOR_TREND_CONTRIBUTION' : 'FUND_FACTOR_CONTRIBUTION';
      setLoading(true);
      const trendRegressionYears = popoverOptions.timestamp ? popoverOptions.regressionYears : undefined;
      try {
        const configs = getAnalysisRequest(
          [analysisType],
          popoverOptions.analysisSubject,
          popoverOptions.timeFrame ?? {},
          popoverOptions.relative,
          popoverOptions.categoryActive,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          popoverOptions.timestamp,
          undefined,
          undefined,
          trendRegressionYears,
        );
        const { content } = await fetchAnalysis(configs);

        setComponents(content.analyses[0]!.factorExposureComponents[0]!);
      } catch (err) {
        const { content, name } = await err;
        if (name === 'AbortError') {
          return;
        }

        setError(content?.message || 'Factor contribution failed to load');
      }
      setLoading(false);
    };
    if (isHistoricalAnalysis) {
      setError('This feature does not support portfolios with historical allocations');
    } else {
      loadComponents();
    }
  }, [popoverOptions, fetchAnalysis, isHistoricalAnalysis]);

  const parseData = (
    exposures: { type: 'funds'; rows: FactorExposureFund[] } | { type: 'strategies'; rows: FactorExposureStrategy[] },
  ): (DataType & { weight: number })[] => {
    const { type } = exposures;
    const rows = exposures.rows as (FactorExposureFund | FactorExposureStrategy)[];

    const weightSum = rows.map((row) => row.weight).reduce((sum, weight) => weight + sum, 0);
    const data = orderBy(
      rows
        .filter(
          (row) =>
            row.factorContribution[factorId] !== undefined ||
            factorId === toString(TOTAL_ID) ||
            factorId === toString(RISK_FREE_RATE_ID),
        )
        .map((row) => {
          const name =
            type === 'funds' ? (row as FactorExposureFund).fundName : (row as FactorExposureStrategy).strategyName;
          if (factorId === toString(TOTAL_ID)) {
            const { totalReturnContribution } = row;
            return {
              name,
              contribution: totalReturnContribution.exposurePct,
              unweightedExposure: totalReturnContribution.unweightedExposure,
              weight: row.weight / weightSum,
            };
          }

          if (factorId === toString(RISK_FREE_RATE_ID)) {
            return {
              name,
              contribution: row.cashContribution[contributionTypesMap[factorType]].exposurePct,
              unweightedExposure: (row.cashContribution[riskTypesMap[factorType]] as ContributionExposure)
                .unweightedExposure,
              weight: row.weight / weightSum,
            };
          }

          const factorContribution = row.factorContribution[factorId]!;

          return {
            name,
            contribution: (factorContribution[contributionTypesMap[factorType]] as ContributionExposure).exposurePct,
            unweightedExposure: (factorContribution[riskTypesMap[factorType]] as ContributionExposure)
              .unweightedExposure,
            weight: row.weight / weightSum,
          };
        }),
      'contribution',
      'desc',
    );
    return data;
  };

  const fundsData = parseData({ type: 'funds', rows: funds });
  const strategiesData = parseData({
    type: 'strategies',
    rows: strategies,
  });

  const onOpenModal = () => {
    analyticsService.ctaClicked({
      destination: 'factor contributions modal',
      filled: true,
      purpose: `View investments contribution to ${factorName}`,
      locationOnPage: `${title} ${timestamp ? '(trend)' : '(summary)'}`,
      text: 'View All Investments',
      type: 'button',
    });
    openModal();
  };

  const explanation = (
    <Explanation
      timestamp={timestamp}
      regressionYears={regressionYears}
      factor={factorName}
      value={factorValue}
      usePercentages={usePercentages}
      factorSignificant={factorSignificant}
    />
  );

  return (
    <Body style={{ top, left }} right={!!tooltipRight} className="qa-popover-body" ref={bodyRef}>
      <CloseIcon onClick={onClose}>
        <Icon type="times" />
      </CloseIcon>
      <Header>{title}:</Header>
      {explanation}
      {loading ? (
        <Loading />
      ) : (
        !error && <Chart type={ChartType[factorType]} usePercentages={usePercentages} data={fundsData} />
      )}
      {error && <Notification severity={NotificationSeverityType.Error}>{error}</Notification>}
      <OpenModalButtonWrapper>
        <Button dominant dense onClick={onOpenModal} className={qaClass} disabled={isHistoricalAnalysis}>
          View all investments
        </Button>
      </OpenModalButtonWrapper>
      {isModalOpen && (
        <Modal
          name={factorName}
          usePercentage={usePercentages}
          explanation={explanation}
          type={ChartType[factorType]}
          title={title}
          loading={loading}
          error={error}
          fundsData={fundsData}
          strategiesData={strategiesData}
          subject={analysisSubject}
        />
      )}
    </Body>
  );
};

export default FactorPopoverRenderer;
