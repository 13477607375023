import { compact } from 'lodash';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import type {
  ColumnErrors,
  FileMapping,
  MappedFund,
  MultiPortfolioParseResult,
  Portfolio,
  SeriesErrorCell,
  SeriesErrorRange,
  StreamErrorEnum,
} from 'venn-api';
import { TABS } from 'venn-api';
import { type AnyDuringEslintMigration, isHistoricalPortfolio } from 'venn-utils';
import { searchPortfolioNodeId, selectStrategy } from 'venn-utils';
import DefaultSample from './components/images/examples/default.svg';
import UploadPortfoliosSample from './components/images/examples/uploadPortfolios.svg';
import UploadPrivatePortfoliosSample from './components/images/examples/uploadPrivatePortfolios.svg';
import UploadPortfolioHistoricalSample from './components/images/examples/uploadPortfoliosHistorical.svg';
import UploadPrivatesSample from './components/images/examples/uploadPrivatesSample.svg';
import UploadSample from './components/images/examples/uploadSample.svg';
import { acceptedFileTypes, acceptedPrivatesFileTypes } from './components/utils';
import type { DataUploaderMode, PortfolioMultiUploaderView } from './types';
import { DataUploaderView } from './types';
import {
  IChooseActionPortfolioView,
  ICreateMasterPortfolioView,
  ICreatePrivatePortfolioView,
  IDuplicatePortfolioView,
  INewPortfolioView,
  IReviewProFormaPortfolioView,
  IUploadPortfolioView,
  IUploadPrivatesView,
  IUploadReturnsView,
} from './constants';

interface UploadConfig {
  steps: DataUploaderView[];
  dataType: string;
  uploadType: string;
  uploadTitle: string;
  uploadSecondaryTitle: string;
  fileTypes: string;
}

export const uploadConfig: UploadConfig[] = [
  {
    dataType: 'returns',
    steps: [DataUploaderView.Upload, DataUploaderView.Review],
    uploadType: 'Returns',
    uploadTitle: 'Upload Investment Returns',
    uploadSecondaryTitle: 'Add or update data in your library of investments.',
    fileTypes: acceptedFileTypes,
  },
  {
    dataType: 'navs',
    steps: [DataUploaderView.Upload, DataUploaderView.DisplayNavMapping],
    uploadType: 'NAVs',
    uploadTitle: 'Upload Investment Navs',
    uploadSecondaryTitle: 'Update allocations from investments in your library.',
    fileTypes: acceptedFileTypes,
  },
  {
    dataType: 'init',
    steps: [DataUploaderView.Initial],
    uploadType: 'init',
    uploadTitle: 'Select a Data Type To Upload.',
    uploadSecondaryTitle: 'Add or update data in your library of investments.',
    fileTypes: '',
  },
  {
    dataType: 'privates',
    steps: [DataUploaderView.Upload, DataUploaderView.Review],
    uploadType: 'Data',
    uploadTitle: 'Upload Private Asset Data',
    uploadSecondaryTitle: 'Add or update data in your library of investments.',
    fileTypes: acceptedPrivatesFileTypes,
  },
  {
    dataType: 'portfolios',
    steps: [DataUploaderView.Upload, DataUploaderView.Review],
    uploadType: 'Portfolios',
    uploadTitle: 'Upload Portfolio Data',
    uploadSecondaryTitle:
      'Uploaded file can contain one or more portfolios, including strategy hierarchies and allocations. Visit the FAQ for more information.',
    fileTypes: acceptedFileTypes,
  },
];
export const getMapping = (funds: MappedFund[], portfolio: Portfolio, strategyId?: number): FileMapping => {
  const strategy = selectStrategy(strategyId || portfolio.id, portfolio) || portfolio;
  return {
    columns: funds.map((fund, index) => {
      // Make sure to upload NAVs within the selected strategy
      const portfolioNodeId = searchPortfolioNodeId(fund.fundId, strategy);
      // Search for the parent in the entire portfolio (obviously)
      const strategyName = searchParentStrategyName(portfolio, portfolioNodeId);
      return {
        portfolioNodeId,
        strategyName,
        fundId: fund.fundId,
        fundName: fund.fundName,
        newNav: fund.allocation,
        origin: {},
        seriesId: index,
      } as AnyDuringEslintMigration;
    }),
    // these two fields are unused for navs as opposed to mappings for uploaded returns
    fileId: 0,
    name: '',
  };
};

const searchParentStrategyName = (portfolio: Portfolio, portfolioNodeId?: number): string => {
  if (!portfolio || !portfolioNodeId || portfolio.fund || !portfolio.children) {
    return '';
  }
  let strategyName = '';
  portfolio.children.forEach((item) => {
    if (strategyName) {
      return;
    }
    if (item.id === portfolioNodeId) {
      strategyName = portfolio.name;
      return;
    }
    const searchResult = searchParentStrategyName(item, portfolioNodeId);
    strategyName = searchResult || strategyName;
  });
  return strategyName;
};

export const getSteps = (mode: DataUploaderMode): DataUploaderView[] => {
  return uploadConfig[mode]!.steps;
};

export const getErrorCounts = (errors: ColumnErrors[]) => {
  // get all the errors from each cell that's deeply nested in each ColumnError
  const allErrors: StreamErrorEnum[] = compact(
    flatMap(
      flatMap(
        flatMap(errors, (e: ColumnErrors) => e.errors),
        (e: SeriesErrorRange) => e.cells,
      ),
      (c: SeriesErrorCell) => c.errors,
    ),
  );
  return countBy(allErrors);
};

export const getTabName = (tab: TABS) => {
  switch (tab) {
    case TABS.UPLOAD:
      return 'Upload';
    case TABS.PASTE:
      return 'Paste';
    case TABS.TEMPLATES:
      return 'Templates';
    default:
      return '';
  }
};

export const getSupportingImageSrc = (view: PortfolioMultiUploaderView | undefined, hasHistoricals?: boolean) => {
  if (!view) {
    return DefaultSample;
  }
  switch (view.id) {
    case 'UPLOAD_RETURNS':
      return UploadSample;
    case 'UPLOAD_PRIVATES':
      return UploadPrivatesSample;
    case 'CREATE_PRIVATE_PORTFOLIO':
      return UploadPrivatePortfoliosSample;
    case 'CHOOSE_ACTION_PORTFOLIO':
      return hasHistoricals ? UploadPortfolioHistoricalSample : UploadPortfoliosSample;
    default:
      return undefined;
  }
};

export const isHistoricalData = (parsedData: MultiPortfolioParseResult) => {
  return parsedData.portfolioParseResults.some((parsedResult) => isHistoricalPortfolio(parsedResult.parsedPortfolio));
};

const ViewsWithShortTitle = [
  IUploadReturnsView,
  IUploadPrivatesView,
  ICreateMasterPortfolioView,
  IChooseActionPortfolioView,
  IUploadPortfolioView,
  IDuplicatePortfolioView,
  ICreatePrivatePortfolioView,
];
type ViewsWithShortTitle = (typeof ViewsWithShortTitle)[number];
export const hasShortTitle = (view: PortfolioMultiUploaderView): view is ViewsWithShortTitle => {
  return ViewsWithShortTitle.map((v) => v.id as string).includes(view.id);
};

const ViewsWithDescription = [
  IUploadReturnsView,
  IUploadPrivatesView,
  ICreateMasterPortfolioView,
  IChooseActionPortfolioView,
  IUploadPortfolioView,
  IDuplicatePortfolioView,
  INewPortfolioView,
  ICreatePrivatePortfolioView,
];
type ViewsWithDescription = (typeof ViewsWithDescription)[number];
export const hasDescription = (view: PortfolioMultiUploaderView): view is ViewsWithDescription => {
  return ViewsWithDescription.map((v) => v.id as string).includes(view.id);
};

const ViewsWithHighlightText = [IUploadPortfolioView, IChooseActionPortfolioView];
type ViewsWithHighlightText = (typeof ViewsWithHighlightText)[number];
export const hasHighlightText = (view: PortfolioMultiUploaderView): view is ViewsWithHighlightText => {
  return ViewsWithHighlightText.map((v) => v.id as string).includes(view.id);
};

const ViewsWithTileHeader = [IChooseActionPortfolioView, IUploadPortfolioView];
type ViewsWithTileHeader = (typeof ViewsWithTileHeader)[number];
export const hasTileHeader = (view: PortfolioMultiUploaderView): view is ViewsWithTileHeader => {
  return ViewsWithTileHeader.map((v) => v.id as string).includes(view.id);
};

const ViewsWithSubtitles = [
  IUploadReturnsView,
  IUploadPrivatesView,
  IUploadPortfolioView,
  IReviewProFormaPortfolioView,
  IDuplicatePortfolioView,
  INewPortfolioView,
  ICreatePrivatePortfolioView,
];
type ViewsWithSubtitles = (typeof ViewsWithSubtitles)[number];
export const hasSubtitle = (view: PortfolioMultiUploaderView): view is ViewsWithSubtitles => {
  return ViewsWithSubtitles.map((v) => v.id as string).includes(view.id);
};
