import type { DrawdownRangeAnalysis, Analysis } from 'venn-api';

export default (
  analyses: Analysis[] | undefined,
  relative: boolean,
  hasBenchmark: boolean,
): DrawdownRangeAnalysis | undefined => {
  if (!analyses) {
    return undefined;
  }
  const factorPeriodDrawdown = analyses.filter((a) => a.analysisType === 'FACTOR_DRAWDOWN_PERIOD');
  if (!factorPeriodDrawdown || factorPeriodDrawdown.length === 0) {
    return undefined;
  }
  const periodsDrawdown = relative
    ? factorPeriodDrawdown?.[1]?.factorPeriodDrawdown
    : factorPeriodDrawdown?.[0]?.factorPeriodDrawdown;
  if (!periodsDrawdown) {
    return undefined;
  }

  const subject = periodsDrawdown[0]!;
  const benchmark = hasBenchmark ? periodsDrawdown[1]! : null;
  const baseline = hasBenchmark ? periodsDrawdown[2]! : periodsDrawdown[1]!;
  const factorEnvironment = analyses.filter((a) => a.analysisType === 'FACTOR_ENVIRONMENT');
  const drawdownFactorInfoList =
    (relative
      ? factorEnvironment?.[1]?.factorEnvironment?.factors
      : factorEnvironment?.[0]?.factorEnvironment?.factors) ?? [];
  return {
    benchmarkCashContribution: benchmark?.cashContribution,
    benchmarkDrawdown: benchmark?.portfolioDrawdown, // TODO
    benchmarkResidualContribution: benchmark?.residualContribution,
    canAnalyzeBenchmark: !!benchmark,
    canAnalyzeSecondary: !!baseline,
    cashContribution: subject?.cashContribution,
    drawdownFactorInfoList,
    errorDrawdown: subject?.errorDrawdown,
    factorExposureList:
      subject.factorExposureList &&
      subject.factorExposureList.map((item, index) => ({
        benchmarkBeta: benchmark?.factorExposureList?.[index]?.portfolioBeta,
        benchmarkContribution: benchmark?.factorExposureList?.[index]?.contribution,
        contribution: item.contribution,
        factorId: item.factorId,
        factorName: item.factorName,
        secondaryContribution: baseline?.factorExposureList?.[index]?.contribution,
        secondaryPortfolioBeta: baseline?.factorExposureList?.[index]?.portfolioBeta,
        portfolioBeta: item.portfolioBeta,
      })),
    fundDrawdownContribution: subject?.fundDrawdownContribution,
    lowerErrorDrawdown: subject?.lowerErrorDrawdown,
    secondaryCashContribution: baseline?.cashContribution,
    secondaryPortfolioDrawdown: baseline?.portfolioDrawdown,
    secondaryResidualContribution: baseline?.residualContribution,
    maxBenchmarkDrawdown: benchmark?.maxDrawdown,
    maxBenchmarkDrawdownDate: benchmark?.maxDrawdownDate,
    maxDrawdown: subject?.maxDrawdown,
    maxDrawdownDate: subject?.maxDrawdownDate,
    maxSecondaryDrawdown: baseline?.maxDrawdown,
    maxSecondaryDrawdownDate: baseline?.maxDrawdownDate,
    portfolioAnalysis: subject?.portfolioAnalysis,
    portfolioDrawdown: subject?.portfolioDrawdown,
    residualContribution: subject?.residualContribution,
    strategyDrawdownContribution: subject?.strategyDrawdownContribution,
    upperErrorDrawdown: subject?.upperErrorDrawdown,
  };
};
