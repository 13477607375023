import { first, last } from 'lodash';
import type { Portfolio } from 'venn-api';
import values from 'lodash/values';
import type { ExcelCell } from 'venn-utils';
import { formatDate, formatExportValue } from 'venn-utils';
import type { HeatMapTypes, XAxisLabel } from './Types';
import { type VennColors, ColorUtils } from 'venn-ui-kit';

export const getProformaPortfolioPalette = (colors: VennColors) => values(colors.DivergingColor);
export const TOTAL_NAME = 'Total';

export const generateColor = (valueMin = 0, valueMax = 100, colors: VennColors) => {
  const palette = getProformaPortfolioPalette(colors);

  return (value: number): string => {
    if (value >= valueMax) {
      return last(palette)!;
    }

    if (value <= valueMin) {
      return first(palette)!;
    }
    const colorIndex = ((value - valueMin) / (valueMax - valueMin)) * (palette.length - 1);
    const flooredColorIndex = Math.floor(colorIndex);

    const fromColor = palette[flooredColorIndex]!;
    const toColor = palette[flooredColorIndex + 1]!;
    const ratio = colorIndex - flooredColorIndex;
    // TODO: (VENN-20577 / TYPES) as written this can easily output an array of NaNs or an empty array which would result in this function
    // returning the string '#' or the string '#NaNNaNNaN', instead we should verify fromColor and toColor aren't undefined
    // and if they are throw an error.
    return ColorUtils.mix(fromColor, toColor, ratio);
  };
};

const isPortfolioEmpty = (portfolio: Portfolio): boolean =>
  flattenPortfolioChildren(portfolio).reduce((sum, current) => {
    return sum + (current?.allocation ?? 0);
  }, portfolio.allocation || 0) === 0;

const flattenPortfolioChildren = (portfolio: Portfolio): Portfolio[] => {
  if (!portfolio || !portfolio.children) {
    return [];
  }

  return portfolio.children.reduce(
    (list, portfoliosChildren) => list.concat(flattenPortfolioChildren(portfoliosChildren)),
    [portfolio],
  );
};

const loadFactorResults = (
  excelSheet: ExcelCell[][],
  data: HeatMapTypes.Root[],
  idx: number,
  isPercentage: boolean,
  addPrefix?: boolean,
) => {
  data.forEach((factor) => {
    if (!factor.series[idx]) {
      return;
    }
    const prefix = addPrefix ? `${factor?.series?.[idx]?.portfolioType}: ` : '';

    const factorValueRow = [
      { value: factor.name },
      { value: `${prefix}${factor.series[idx]!.name}` },
      ...factor.series[idx]!.data.map((datapoint) => formatExportValue(datapoint.value, isPercentage)),
    ];
    excelSheet.push(factorValueRow);

    if (factor.name !== TOTAL_NAME) {
      const factorTstatRow = [
        { value: `${factor.name} T-stat` },
        { value: `${prefix}${factor.series[idx]!.name}` },
        ...factor.series[idx]!.data.map((datapoint) => formatExportValue(datapoint.tValue, false)),
      ];
      excelSheet.push(factorTstatRow);
    }
  });
};

const convertDataToExcel = (
  data: HeatMapTypes.Root[],
  timestamps: number[],
  title: string,
  activeTitle: string,
  relative: boolean,
  hasBaseline: boolean,
  isPercentage: boolean,
) => {
  if (!data || data.length === 0) {
    return undefined;
  }
  const header: ExcelCell[] = [{ value: relative ? activeTitle : title }, { value: 'Instrument' }];
  timestamps.forEach((date) => {
    header.push({ value: formatDate(date) });
  });

  const excelSheet = [header];
  const hasBaselineActive = hasBaseline && data[0]!.series.length > 1;
  const hasBenchmark = data[0]!.series.find(({ portfolioType }) => portfolioType === 'Benchmark') !== undefined;
  const hasCategory = data[0]!.series.find(({ portfolioType }) => portfolioType === 'Category') !== undefined;

  loadFactorResults(excelSheet, data, 0, isPercentage);
  let dataIdx = 1;
  if (hasBaselineActive) {
    loadFactorResults(excelSheet, data, dataIdx++, isPercentage, true);
  }
  if (hasBenchmark) {
    loadFactorResults(excelSheet, data, dataIdx++, isPercentage);
  }
  if (hasCategory) {
    loadFactorResults(excelSheet, data, dataIdx++, isPercentage, true);
  }
  excelSheet[0]!.forEach((row) => (row.bold = true));
  excelSheet.forEach((row) => (row[0]!.bold = true));

  return excelSheet;
};

const convertCorrelationsDataToExcel = (
  data: HeatMapTypes.Root[],
  nodeNames: XAxisLabel[],
): ExcelCell[][] | undefined => {
  if (!data || data.length === 0) {
    return undefined;
  }

  const getName = (item: HeatMapTypes.Root) => {
    if (item.type) {
      return `${item.name} (${item.type})`;
    }
    return item.name;
  };

  const xAxisNames = nodeNames.map((n) => n.name);

  const headers: ExcelCell[] = [{ value: '' }];
  xAxisNames.forEach((name) => {
    headers.push({ value: name, bold: true });
  });

  const rows: ExcelCell[][] = [];
  data.forEach((d) => {
    const row: ExcelCell[] = [{ value: getName(d), bold: true }];
    for (let i = 0; i < xAxisNames.length; i++) {
      if (d.series[0]!.data[i]!) {
        row.push({ value: d.series[0]!.data[i]!.value ?? '', digits: 2 });
      } else {
        row.push({ value: '' });
      }
    }
    rows.push(row);
  });

  return [headers, ...rows];
};

export default {
  generateColor,
  flattenPortfolioChildren,
  isPortfolioEmpty,
  convertDataToExcel,
  convertCorrelationsDataToExcel,
};
