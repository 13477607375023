import React from 'react';

import { useRecoilValue } from 'recoil';

import { blockDateRange, blockDisplayHeader, customizedBlock, isReportState } from 'venn-state';

import { Block } from '../../components/core';

import { DefaultDateRange } from '../../components/secondary/date-ranges';
import { BenchmarkType } from '../../components/secondary/benchmark-type';

import { useAppPrintMode } from '../../../print';
import { DefaultSubjects } from '../../components/secondary/subjects/default-subjects';

interface NotableHistoricalHeaderProps {
  id: string;
}

export const NotableHistoricalHeader = ({ id }: NotableHistoricalHeaderProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);
  const { selectedMetrics, relativeToBenchmark, subHeader } = useRecoilValue(customizedBlock(id));
  const dateRange = useRecoilValue(blockDateRange(id));

  return (
    <>
      <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline id={id} isReport={isReport}>
            {title}
          </Block.Headline>
          {subHeader && <Block.SubHeadline id={id}>{subHeader}</Block.SubHeadline>}
        </Block.Title>

        <Block.Metadata id={id}>
          <DefaultDateRange dateRange={dateRange} />
          <BenchmarkType id={id} />
          {relativeToBenchmark && <Block.MetadataItem iconType="calculator">Relative to Benchmark</Block.MetadataItem>}
        </Block.Metadata>
      </Block.HeaderTopContainer>

      <DefaultSubjects id={id} metric={selectedMetrics[0]! || ''} relativeToBenchmark={relativeToBenchmark} />
    </>
  );
};
