import type { Portfolio } from 'venn-api';
import { cloneDeep } from 'lodash';

export const mockHistoricalPortfolio1 = {
  id: 130,
  name: 'Portfolio 1',
  allocation: 111.55,
  netAssets: 111.55,
  leveraged: false,
  historical: true,
  rebalanceStrategy: 'QUARTERLY',
  children: [
    {
      name: 'Equity',
      allocation: 86.25,
      netAssets: 86.25,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Global Equity',
          allocation: 41.5,
          netAssets: 41.5,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Growth',
              allocation: 41.5,
              netAssets: 41.5,
              leveraged: false,
              historical: true,
              rebalanceStrategy: 'QUARTERLY',
              children: [
                {
                  name: 'Equity 1',
                  fund: {
                    id: 'a252436e-4b1b-48e3-a0c3-b035b5f0f284',
                    name: 'Equity 1',
                    uploadName: null,
                    fundSourceType: null,
                    startRange: null,
                    endRange: null,
                    returnFrequency: null,
                    created: null,
                    updated: null,
                    createdById: null,
                    updatedById: null,
                    preferredFrequency: null,
                    currency: null,
                    domicile: null,
                    netExpenseRatio: null,
                    investmentObjective: null,
                    userUploaded: null,
                    assetType: null,
                    remoteType: null,
                    shareClass: null,
                    primaryShareClass: true,
                    dataSource: null,
                    updatedByDataSource: null,
                    investmentRegion: null,
                    exchange: null,
                    exchangeCountry: null,
                    symbol: null,
                    isin: null,
                    accountNumber: null,
                    morningstarCategory: null,
                    morningstarCategoryCode: null,
                    morningstarFundId: null,
                    category: null,
                    categoryAssetType: null,
                    categoryGroup: null,
                    primaryBenchmark: null,
                    active: true,
                    live: true,
                    userEditable: false,
                    investmentSource: 'VENN',
                  },
                  allocation: 31.25,
                  netAssets: 31.25,
                  leveraged: false,
                  historical: true,
                  closingAllocationsTs: [
                    [1704067199999, 31.25],
                    [1706745599999, 33.0],
                  ],
                  rebalanceStrategy: 'QUARTERLY',
                  children: [],
                  compare: [],
                  cashBalance: 0.0,
                  netLeverage: 1.0,
                  grossPosition: 31.25,
                  longPosition: 31.25,
                  grossLeverage: 1.0,
                  longOnlyLeverage: 1.0,
                },
                {
                  name: 'Equity 2',
                  fund: {
                    id: '6b82a2bd-f946-42b1-85bc-27bd7fcf11a6',
                    name: 'Equity 2',
                    uploadName: null,
                    fundSourceType: null,
                    startRange: null,
                    endRange: null,
                    returnFrequency: null,
                    created: null,
                    updated: null,
                    createdById: null,
                    updatedById: null,
                    preferredFrequency: null,
                    currency: null,
                    domicile: null,
                    netExpenseRatio: null,
                    investmentObjective: null,
                    userUploaded: null,
                    assetType: null,
                    remoteType: null,
                    shareClass: null,
                    primaryShareClass: true,
                    dataSource: null,
                    updatedByDataSource: null,
                    investmentRegion: null,
                    exchange: null,
                    exchangeCountry: null,
                    symbol: 'ABCD',
                    isin: 'US1111111111',
                    accountNumber: null,
                    morningstarCategory: null,
                    morningstarCategoryCode: null,
                    morningstarFundId: null,
                    category: null,
                    categoryAssetType: null,
                    categoryGroup: null,
                    primaryBenchmark: null,
                    active: true,
                    live: true,
                    userEditable: false,
                    investmentSource: 'VENN',
                  },
                  allocation: 10.25,
                  netAssets: 10.25,
                  leveraged: false,
                  historical: true,
                  closingAllocationsTs: [
                    [1704067199999, 10.25],
                    [1706745599999, 11.0],
                  ],
                  rebalanceStrategy: 'QUARTERLY',
                  children: [],
                  compare: [],
                  cashBalance: 0.0,
                  netLeverage: 1.0,
                  grossPosition: 10.25,
                  longPosition: 10.25,
                  grossLeverage: 1.0,
                  longOnlyLeverage: 1.0,
                },
              ],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 41.5,
              longPosition: 41.5,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 41.5,
          longPosition: 41.5,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
        {
          name: 'US Equity',
          allocation: 44.75,
          netAssets: 44.75,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Value',
              allocation: 44.75,
              netAssets: 44.75,
              leveraged: false,
              historical: true,
              rebalanceStrategy: 'QUARTERLY',
              children: [
                {
                  name: 'Equity 4',
                  fund: {
                    id: '13760ec3-d6ea-4c3b-a450-8021ee3a191c',
                    name: 'Equity 4',
                    uploadName: null,
                    fundSourceType: null,
                    startRange: null,
                    endRange: null,
                    returnFrequency: null,
                    created: null,
                    updated: null,
                    createdById: null,
                    updatedById: null,
                    preferredFrequency: null,
                    currency: null,
                    domicile: null,
                    netExpenseRatio: null,
                    investmentObjective: null,
                    userUploaded: null,
                    assetType: null,
                    remoteType: null,
                    shareClass: null,
                    primaryShareClass: true,
                    dataSource: null,
                    updatedByDataSource: null,
                    investmentRegion: null,
                    exchange: null,
                    exchangeCountry: null,
                    symbol: null,
                    isin: null,
                    accountNumber: null,
                    morningstarCategory: null,
                    morningstarCategoryCode: null,
                    morningstarFundId: null,
                    category: null,
                    categoryAssetType: null,
                    categoryGroup: null,
                    primaryBenchmark: null,
                    active: true,
                    live: true,
                    userEditable: false,
                    investmentSource: 'VENN',
                  },
                  allocation: 44.75,
                  netAssets: 44.75,
                  leveraged: false,
                  historical: true,
                  closingAllocationsTs: [
                    [1704067199999, 44.75],
                    [1706745599999, 45.0],
                  ],
                  rebalanceStrategy: 'QUARTERLY',
                  children: [],
                  compare: [],
                  cashBalance: 0.0,
                  netLeverage: 1.0,
                  grossPosition: 44.75,
                  longPosition: 44.75,
                  grossLeverage: 1.0,
                  longOnlyLeverage: 1.0,
                },
              ],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 44.75,
              longPosition: 44.75,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 44.75,
          longPosition: 44.75,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 86.25,
      longPosition: 86.25,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
    {
      name: 'Alternatives',
      allocation: 11.15,
      netAssets: 11.15,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Relative Value',
          allocation: 11.15,
          netAssets: 11.15,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Alts 1',
              fund: {
                id: 'cf4a23f4-69df-4aca-a1b6-a313bfbee298',
                name: 'Alts 1',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 11.15,
              netAssets: 11.15,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [
                [1704067199999, 11.15],
                [1706745599999, 11.5],
              ],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 11.15,
              longPosition: 11.15,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 11.15,
          longPosition: 11.15,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 11.15,
      longPosition: 11.15,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
    {
      name: 'Fixed Income',
      allocation: 0.95,
      netAssets: 0.95,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Core',
          allocation: 0.95,
          netAssets: 0.95,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Credit 1 (Corp)',
              fund: {
                id: '2e0c7765-b454-4911-9530-d9e28fb431a6',
                name: 'Credit 1 (Corp)',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 0.95,
              netAssets: 0.95,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [
                [1704067199999, 0.95],
                [1706745599999, 1.0],
              ],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 0.95,
              longPosition: 0.95,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 0.95,
          longPosition: 0.95,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 0.95,
      longPosition: 0.95,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
    {
      name: 'Real Assets',
      allocation: 13.2,
      netAssets: 13.2,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Commodities',
          allocation: 0.95,
          netAssets: 0.95,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Commodities 1',
              fund: {
                id: '1e280139-2207-4e77-9469-a37f016bf7ee',
                name: 'Commodities 1',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 0.95,
              netAssets: 0.95,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [
                [1704067199999, 0.95],
                [1706745599999, 1.25],
              ],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 0.95,
              longPosition: 0.95,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 0.95,
          longPosition: 0.95,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
        {
          name: 'Public Real Estate',
          allocation: 12.25,
          netAssets: 12.25,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Real Estate 1',
              fund: {
                id: null,
                name: 'Real Estate 1',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 12.25,
              netAssets: 12.25,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [
                [1704067199999, 12.25],
                [1706745599999, 13.0],
              ],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 12.25,
              longPosition: 12.25,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 12.25,
          longPosition: 12.25,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 13.2,
      longPosition: 13.2,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
  ],
  compare: [],
  cashBalance: 0.0,
  netLeverage: 1.0,
  grossPosition: 111.55,
  longPosition: 111.55,
  grossLeverage: 1.0,
  longOnlyLeverage: 1.0,
} as unknown as Portfolio;

export const mockHistoricalPortfolio2 = {
  id: 129,
  name: 'Portfolio 2',
  allocation: 107.95,
  netAssets: 107.95,
  leveraged: false,
  historical: true,
  rebalanceStrategy: 'QUARTERLY',
  children: [
    {
      name: 'Equity',
      allocation: 84.0,
      netAssets: 84.0,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Global Equity',
          allocation: 41.0,
          netAssets: 41.0,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Growth',
              allocation: 41.0,
              netAssets: 41.0,
              leveraged: false,
              historical: true,
              rebalanceStrategy: 'QUARTERLY',
              children: [
                {
                  name: 'Equity 1',
                  fund: {
                    id: 'a252436e-4b1b-48e3-a0c3-b035b5f0f284',
                    name: 'Equity 1',
                    uploadName: null,
                    fundSourceType: null,
                    startRange: null,
                    endRange: null,
                    returnFrequency: null,
                    created: null,
                    updated: null,
                    createdById: null,
                    updatedById: null,
                    preferredFrequency: null,
                    currency: null,
                    domicile: null,
                    netExpenseRatio: null,
                    investmentObjective: null,
                    userUploaded: null,
                    assetType: null,
                    remoteType: null,
                    shareClass: null,
                    primaryShareClass: true,
                    dataSource: null,
                    updatedByDataSource: null,
                    investmentRegion: null,
                    exchange: null,
                    exchangeCountry: null,
                    symbol: null,
                    isin: null,
                    accountNumber: null,
                    morningstarCategory: null,
                    morningstarCategoryCode: null,
                    morningstarFundId: null,
                    category: null,
                    categoryAssetType: null,
                    categoryGroup: null,
                    primaryBenchmark: null,
                    active: true,
                    live: true,
                    userEditable: false,
                    investmentSource: 'VENN',
                  },
                  allocation: 32.0,
                  netAssets: 32.0,
                  leveraged: false,
                  historical: true,
                  closingAllocationsTs: [[1709164799999, 32.0]],
                  rebalanceStrategy: 'QUARTERLY',
                  children: [],
                  compare: [],
                  cashBalance: 0.0,
                  netLeverage: 1.0,
                  grossPosition: 32.0,
                  longPosition: 32.0,
                  grossLeverage: 1.0,
                  longOnlyLeverage: 1.0,
                },
                {
                  name: 'Equity 2',
                  fund: {
                    id: '6b82a2bd-f946-42b1-85bc-27bd7fcf11a6',
                    name: 'Equity 2',
                    uploadName: null,
                    fundSourceType: null,
                    startRange: null,
                    endRange: null,
                    returnFrequency: null,
                    created: null,
                    updated: null,
                    createdById: null,
                    updatedById: null,
                    preferredFrequency: null,
                    currency: null,
                    domicile: null,
                    netExpenseRatio: null,
                    investmentObjective: null,
                    userUploaded: null,
                    assetType: null,
                    remoteType: null,
                    shareClass: null,
                    primaryShareClass: true,
                    dataSource: null,
                    updatedByDataSource: null,
                    investmentRegion: null,
                    exchange: null,
                    exchangeCountry: null,
                    symbol: 'ABCD',
                    isin: 'US1111111111',
                    accountNumber: null,
                    morningstarCategory: null,
                    morningstarCategoryCode: null,
                    morningstarFundId: null,
                    category: null,
                    categoryAssetType: null,
                    categoryGroup: null,
                    primaryBenchmark: null,
                    active: true,
                    live: true,
                    userEditable: false,
                    investmentSource: 'VENN',
                  },
                  allocation: 9.0,
                  netAssets: 9.0,
                  leveraged: false,
                  historical: true,
                  closingAllocationsTs: [[1709164799999, 9.0]],
                  rebalanceStrategy: 'QUARTERLY',
                  children: [],
                  compare: [],
                  cashBalance: 0.0,
                  netLeverage: 1.0,
                  grossPosition: 9.0,
                  longPosition: 9.0,
                  grossLeverage: 1.0,
                  longOnlyLeverage: 1.0,
                },
              ],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 41.0,
              longPosition: 41.0,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 41.0,
          longPosition: 41.0,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
        {
          name: 'US Equity',
          allocation: 43.0,
          netAssets: 43.0,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Value',
              allocation: 43.0,
              netAssets: 43.0,
              leveraged: false,
              historical: true,
              rebalanceStrategy: 'QUARTERLY',
              children: [
                {
                  name: 'Equity 4',
                  fund: {
                    id: '13760ec3-d6ea-4c3b-a450-8021ee3a191c',
                    name: 'Equity 4',
                    uploadName: null,
                    fundSourceType: null,
                    startRange: null,
                    endRange: null,
                    returnFrequency: null,
                    created: null,
                    updated: null,
                    createdById: null,
                    updatedById: null,
                    preferredFrequency: null,
                    currency: null,
                    domicile: null,
                    netExpenseRatio: null,
                    investmentObjective: null,
                    userUploaded: null,
                    assetType: null,
                    remoteType: null,
                    shareClass: null,
                    primaryShareClass: true,
                    dataSource: null,
                    updatedByDataSource: null,
                    investmentRegion: null,
                    exchange: null,
                    exchangeCountry: null,
                    symbol: null,
                    isin: null,
                    accountNumber: null,
                    morningstarCategory: null,
                    morningstarCategoryCode: null,
                    morningstarFundId: null,
                    category: null,
                    categoryAssetType: null,
                    categoryGroup: null,
                    primaryBenchmark: null,
                    active: true,
                    live: true,
                    userEditable: false,
                    investmentSource: 'VENN',
                  },
                  allocation: 43.0,
                  netAssets: 43.0,
                  leveraged: false,
                  historical: true,
                  closingAllocationsTs: [[1709164799999, 43.0]],
                  rebalanceStrategy: 'QUARTERLY',
                  children: [],
                  compare: [],
                  cashBalance: 0.0,
                  netLeverage: 1.0,
                  grossPosition: 43.0,
                  longPosition: 43.0,
                  grossLeverage: 1.0,
                  longOnlyLeverage: 1.0,
                },
              ],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 43.0,
              longPosition: 43.0,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 43.0,
          longPosition: 43.0,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 84.0,
      longPosition: 84.0,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
    {
      name: 'Alternatives',
      allocation: 10.75,
      netAssets: 10.75,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Relative Value',
          allocation: 10.75,
          netAssets: 10.75,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Alts 1',
              fund: {
                id: 'cf4a23f4-69df-4aca-a1b6-a313bfbee298',
                name: 'Alts 1',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 10.75,
              netAssets: 10.75,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [[1709164799999, 10.75]],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 10.75,
              longPosition: 10.75,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 10.75,
          longPosition: 10.75,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 10.75,
      longPosition: 10.75,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
    {
      name: 'Fixed Income',
      allocation: 0.8,
      netAssets: 0.8,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Core',
          allocation: 0.8,
          netAssets: 0.8,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Credit 1 (Corp)',
              fund: {
                id: '2e0c7765-b454-4911-9530-d9e28fb431a6',
                name: 'Credit 1 (Corp)',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 0.8,
              netAssets: 0.8,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [[1709164799999, 0.8]],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 0.8,
              longPosition: 0.8,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 0.8,
          longPosition: 0.8,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 0.8,
      longPosition: 0.8,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
    {
      name: 'Real Assets',
      allocation: 12.4,
      netAssets: 12.4,
      leveraged: false,
      historical: true,
      rebalanceStrategy: 'QUARTERLY',
      children: [
        {
          name: 'Commodities',
          allocation: 0.9,
          netAssets: 0.9,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Commodities 1',
              fund: {
                id: '1e280139-2207-4e77-9469-a37f016bf7ee',
                name: 'Commodities 1',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 0.9,
              netAssets: 0.9,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [[1709164799999, 0.9]],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 0.9,
              longPosition: 0.9,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 0.9,
          longPosition: 0.9,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
        {
          name: 'Public Real Estate',
          allocation: 11.5,
          netAssets: 11.5,
          leveraged: false,
          historical: true,
          rebalanceStrategy: 'QUARTERLY',
          children: [
            {
              name: 'Real Estate 1',
              fund: {
                id: null,
                name: 'Real Estate 1',
                uploadName: null,
                fundSourceType: null,
                startRange: null,
                endRange: null,
                returnFrequency: null,
                created: null,
                updated: null,
                createdById: null,
                updatedById: null,
                preferredFrequency: null,
                currency: null,
                domicile: null,
                netExpenseRatio: null,
                investmentObjective: null,
                userUploaded: null,
                assetType: null,
                remoteType: null,
                shareClass: null,
                primaryShareClass: true,
                dataSource: null,
                updatedByDataSource: null,
                investmentRegion: null,
                exchange: null,
                exchangeCountry: null,
                symbol: null,
                isin: null,
                accountNumber: null,
                morningstarCategory: null,
                morningstarCategoryCode: null,
                morningstarFundId: null,
                category: null,
                categoryAssetType: null,
                categoryGroup: null,
                primaryBenchmark: null,
                active: true,
                live: true,
                userEditable: false,
                investmentSource: 'VENN',
              },
              allocation: 11.5,
              netAssets: 11.5,
              leveraged: false,
              historical: true,
              closingAllocationsTs: [[1709164799999, 11.5]],
              rebalanceStrategy: 'QUARTERLY',
              children: [],
              compare: [],
              cashBalance: 0.0,
              netLeverage: 1.0,
              grossPosition: 11.5,
              longPosition: 11.5,
              grossLeverage: 1.0,
              longOnlyLeverage: 1.0,
            },
          ],
          compare: [],
          cashBalance: 0.0,
          netLeverage: 1.0,
          grossPosition: 11.5,
          longPosition: 11.5,
          grossLeverage: 1.0,
          longOnlyLeverage: 1.0,
        },
      ],
      compare: [],
      cashBalance: 0.0,
      netLeverage: 1.0,
      grossPosition: 12.4,
      longPosition: 12.4,
      grossLeverage: 1.0,
      longOnlyLeverage: 1.0,
    },
  ],
  compare: [],
  cashBalance: 0.0,
  netLeverage: 1.0,
  grossPosition: 107.95,
  longPosition: 107.95,
  grossLeverage: 1.0,
  longOnlyLeverage: 1.0,
};

export const duplicateInvestmentMockHistoricalPortfolio1 = cloneDeep(mockHistoricalPortfolio1) as unknown as Portfolio;
// duplicate investment with fundId
duplicateInvestmentMockHistoricalPortfolio1.children[0]!.children[0]!.children[0]!.children.push({
  ...duplicateInvestmentMockHistoricalPortfolio1.children[0]!.children[0]!.children[0]!.children[0]!,
} as unknown as Portfolio);
// remove one of the dates so that we get a single error on one date only
duplicateInvestmentMockHistoricalPortfolio1.children[0]!.children[0]!.children[0]!.children[2]!.closingAllocationsTs = [
  [
    // @ts-expect-error we know this mock data is non-null
    duplicateInvestmentMockHistoricalPortfolio1.children[0].children[0].children[0].children[0]
      .closingAllocationsTs[0][0],
    // @ts-expect-error we know this mock data is non-null
    duplicateInvestmentMockHistoricalPortfolio1.children[0].children[0].children[0].children[0]
      .closingAllocationsTs[0][1] * 0.13,
  ],
];
// remove unmapped row so we can get errors in the header
duplicateInvestmentMockHistoricalPortfolio1.children[3]!.children =
  duplicateInvestmentMockHistoricalPortfolio1.children[3]!.children.slice(0, 1);

export const historicalPortfolioWithOneUnmappedInvestment = cloneDeep(mockHistoricalPortfolio1) as unknown as Portfolio;
historicalPortfolioWithOneUnmappedInvestment.children = [historicalPortfolioWithOneUnmappedInvestment.children[3]!];

export const historicalPortfolioWithoutErrors = cloneDeep(mockHistoricalPortfolio1) as unknown as Portfolio;
historicalPortfolioWithoutErrors.children = [historicalPortfolioWithoutErrors.children[0]!];
