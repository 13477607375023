import type { VennColors } from 'venn-ui-kit';
import type { LegendSeries } from './Legend';
import { LEGEND_PADDING_GAP } from '../constants';
import { CHART_PADDING } from '../../../factor-chart/constants';
import type { FactorSummaryChartData, FactorSummaryRow } from '../../factorSummaryTypes';
import { find } from 'lodash';

export function getPosition(series: LegendSeries[], y: number) {
  let currentX = CHART_PADDING;
  return series.map((item) => {
    const legendPosition = {
      x: currentX,
      y,
    };
    currentX += LEGEND_PADDING_GAP + item.width;
    return legendPosition;
  });
}

export const getLegendSeries = (data: FactorSummaryChartData, colors: VennColors): LegendSeries[][] => {
  const allSeries = [
    { name: 'Negative', color: colors.DivergingColor.A3, width: 56 },
    { name: 'Positive', color: colors.DivergingColor.B3, width: 50 },
    { name: 'Insignificant value', color: colors.Grey, width: 113 },
    { name: 'Residual', color: "url('#summary-legend-hatch')", width: 53 },
    { name: 'Risk-Free Rate', color: "url('#summary-legend-vertical-lines')", width: 90 },
    { name: 'Total', color: colors.Black, rotate: true, width: 32 },
  ];
  const rows = data?.rows ?? [];
  const firstLineSeries: LegendSeries[] = [allSeries[0]!, allSeries[1]!];
  const secondLineSeries: LegendSeries[] = [];
  if (findInsignificant(rows)) {
    secondLineSeries.push(allSeries[2]!);
  }
  if (findResidual(rows)) {
    secondLineSeries.push(allSeries[3]!);
  }
  if (findRiskFreeRate(rows)) {
    secondLineSeries.push(allSeries[4]!);
  }
  if (findTotal(rows)) {
    secondLineSeries.push(allSeries[5]!);
  }

  if (secondLineSeries.length > 3) {
    const balancedFirstLineSeries = [...firstLineSeries, secondLineSeries[0]!];
    secondLineSeries.splice(0, 1);
    return [balancedFirstLineSeries, secondLineSeries];
  }

  return [firstLineSeries, secondLineSeries];
};

const findInsignificant = (rows: FactorSummaryRow[]) =>
  find(rows, (row) => row?.mainDataPoint?.statisticallySignificant === false);
const findResidual = (rows: FactorSummaryRow[]) => find(rows, { factorName: 'Residual' });
const findRiskFreeRate = (rows: FactorSummaryRow[]) => find(rows, { factorName: 'Risk-Free Rate' });
const findTotal = (rows: FactorSummaryRow[]) => find(rows, { factorName: 'Total' });
