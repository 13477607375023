import React from 'react';
import type { CellRenderer } from './types';
import { getTypeId } from '../../fetchers';
import { APPEND_ONLY, DataUploaderMode } from '../../types';
import {
  isColumnDeleted,
  toOption,
  toOptionNumber,
  updateColumnMappingsByFundChange,
  updateMappingColumnState,
} from '../../views/mapping/helpers';
import { isCellDisabled, isDataNew } from './helpers';
import type { ErrorViewModel } from '../../views/review/helpers';
import { hasUncorrectedErrors } from '../../views/review/helpers';
import { ReturnsFundCell } from '../review-data';
import { FundCell } from '../map-data';
import type { ColumnMapping, FileMapping, FileUploadMetadata, Fund } from 'venn-api';

interface FundCellRendererProps {
  mapping: FileMapping;
  columns: ColumnMapping[];
  metadata: FileUploadMetadata;
  onChange: (newMapping: FileMapping) => void;
  columnMapping: ColumnMapping;
  readOnly: boolean;
  mode: DataUploaderMode;
  index: number;
  count: number;
  errors: ErrorViewModel[];
}

// @ts-expect-error: TODO fix strictFunctionTypes
export const FundCellRenderer: CellRenderer = ({
  mapping,
  columns,
  metadata,
  onChange,
  columnMapping,
  readOnly,
  mode,
  index,
  count,
  errors,
}: FundCellRendererProps) => {
  const { fundName, fundId, seriesId, name, currency, appendId, origin, portfolioNodeId } = columnMapping;
  const appendOnlyId = getTypeId(metadata, 'appendType', APPEND_ONLY);
  const isDeleted = isColumnDeleted(columnMapping, metadata);
  const isDisabled = isCellDisabled(columnMapping, metadata, errors, seriesId);
  const isReturns = mode === DataUploaderMode.Returns;
  const isNew = isDataNew(isReturns, !!fundId, !!portfolioNodeId);

  const settingsOptions = isReturns
    ? [
        {
          options: metadata.currency.map(toOption),
          readonly: readOnly || appendId === appendOnlyId,
          isAppendSetting: false,
        },
      ]
    : [];
  const settings: (string | number)[] = isReturns ? [currency] : [];

  if (!isNew && isReturns) {
    settingsOptions.push({
      options: metadata.appendType.map(toOptionNumber),
      readonly: readOnly || hasUncorrectedErrors(errors, seriesId),
      isAppendSetting: true,
    });
    settings.push(appendId);
  }

  const opensUp = count > 3 && index > count - 4;
  const Cell = isReturns ? ReturnsFundCell : FundCell;

  return (
    <Cell
      isNew={isNew}
      canLink={!readOnly && !isDisabled}
      opensUp={opensUp}
      onLink={(fund: Fund) => {
        const values = [appendOnlyId, columnMapping.currency, columnMapping.typeId, fund];
        onChange(
          updateMappingColumnState(
            {
              mapping,
              columns,
              values,
              seriesId,
            },
            updateColumnMappingsByFundChange,
            mode,
          ),
        );
      }}
      onLinkNew={(label) => {
        const values = [columnMapping.appendId, columnMapping.currency, columnMapping.typeId, label];
        onChange(
          updateMappingColumnState(
            {
              mapping,
              columns,
              values,
              seriesId,
            },
            updateColumnMappingsByFundChange,
            mode,
          ),
        );
      }}
      fund={fundName}
      origin={{
        row: origin?.rowIndex,
        column: origin?.colIndex,
        value: name,
      }}
      disabled={isDisabled}
      alwaysEnableAppendTypeSelector={!isDeleted}
      settingsOptions={settingsOptions}
      settings={settings}
      onSettingsChanged={(changedSettings) => {
        // no append strat for new, expected values format: [appendId, currency, typeId]
        const values = isNew
          ? [columnMapping.appendId, ...changedSettings, columnMapping.typeId]
          : isReturns // changedSettings = [currency, appendId]
            ? [changedSettings[1]!, changedSettings[0]!, columnMapping.typeId]
            : // NAVs does not allow toggling currency, so feed in existing
              [...changedSettings, columnMapping.currency, columnMapping.typeId];
        onChange(
          updateMappingColumnState(
            {
              mapping,
              columns,
              values,
              seriesId,
            },
            updateColumnMappingsByFundChange,
            mode,
          ),
        );
      }}
    />
  );
};
