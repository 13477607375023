import type { FC } from 'react';
import React from 'react';
import LegendSquare from '../../../factor-chart/LegendSquare';
import { getPosition } from './utils';
import { LEGEND_PADDING_GAP } from '../constants';

const Legend: FC<React.PropsWithChildren<LegendProps>> = ({ legendSeries, y, print }) => (
  <g>
    {legendSeries.map((legendSerie, index) => {
      const position = getPosition(legendSerie, y + LEGEND_PADDING_GAP * index);
      return legendSerie.map(({ color, rotate, name }, i) => (
        <LegendSquare
          key={name}
          color={color}
          print={print}
          rotate={rotate}
          name={name}
          x={position[i]!.x}
          y={position[i]!.y}
        />
      ));
    })}
  </g>
);

export interface LegendProps {
  legendSeries: LegendSeries[][];
  y: number;
  print: boolean;
}

export interface LegendSeries {
  name: string;
  color: string;
  width: number;
  rotate?: boolean;
}

export default Legend;
