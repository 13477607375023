import type { FC, ComponentType } from 'react';
import React from 'react';
import type { BackgroundClickEvent } from '../../../factor-summary';
import type { SummaryChartProps, FactorSummaryChartData } from '../../../factor-summary/factorSummaryTypes';
import type { ShowPopoverFuncType } from '../../../factor-chart/factor-popover/FactorPopover';
import FactorPopover from '../../../factor-chart/factor-popover/FactorPopover';
import type { TimeFrame, AnalysisSubjectSecondaryLabel } from 'venn-utils';
import type { DataExportEvent } from 'venn-api';
import type { TrackAnalysisProps } from '../../chart-errors';
import { FactorAnalysisContext } from '../../../contexts';

interface FactorSummaryChartProps {
  /**
   * FactorSummaryComponent could be RiskSummaryChart, ReturnSummaryChart or ExposureSummaryChart
   */
  FactorSummaryComponent: ComponentType<React.PropsWithChildren<SummaryChartProps>>;
  type: 'risk' | 'return' | 'exposure';
  data: FactorSummaryChartData;
  width: number;
  filterCount: number;
  usePercentages?: boolean;
  periodAnnualized?: boolean;
  dataExportEvent: DataExportEvent;
  actualTimeFrame: TimeFrame;
  secondaryLabel: AnalysisSubjectSecondaryLabel;
  trackingProps: TrackAnalysisProps;
}

const FactorSummaryChart: FC<React.PropsWithChildren<FactorSummaryChartProps>> = ({
  FactorSummaryComponent,
  data,
  width,
  filterCount,
  usePercentages,
  periodAnnualized,
  dataExportEvent,
  type,
  secondaryLabel,
  actualTimeFrame,
  trackingProps,
}) => {
  const sharedProps = {
    data,
    width,
    filterCount,
    periodAnnualized,
    dataExportEvent,
    secondaryLabel,
    trackingProps,
  };
  const { analysisSubject, relative, categoryActive, defaultFactorsName, portfolioHasMultipleFunds } =
    React.useContext(FactorAnalysisContext);

  return analysisSubject.fund ? (
    <FactorSummaryComponent isFund {...sharedProps} />
  ) : (
    <div>
      <FactorPopover
        relative={relative}
        categoryActive={categoryActive}
        usePercentages={usePercentages}
        analysisSubject={analysisSubject}
        activeFactorLens={defaultFactorsName}
        actualTimeFrame={actualTimeFrame}
      >
        {(showPopover) => (
          <FactorSummaryComponent
            isFund={false}
            onClick={portfolioHasMultipleFunds ? (event) => handleRowClick(showPopover, event, type, 3) : undefined}
            {...sharedProps}
          />
        )}
      </FactorPopover>
    </div>
  );
};

export default FactorSummaryChart;

const handleRowClick = (
  showPopover: ShowPopoverFuncType,
  event: BackgroundClickEvent,
  chartType: string,
  regressionYears: number,
) => {
  if (!regressionYears) {
    return;
  }

  showPopover({
    factorName: event.factorName,
    factorId: event.id,
    position: {
      x: event.position.x,
      y: event.position.y,
      tooltipRight: event.position.tooltipRight,
    },
    titleData: {
      factorType: chartType,
      factorValue: event.value,
      factorSignificant: event.statisticallySignificant,
      regressionYears,
    },
  });
};
