import moment from 'moment';
import type { FrequencyEnum } from 'venn-api';
import { Dates } from 'venn-utils';
import { DOWNLOAD_BLOCK_CLASS, DOWNLOAD_DATA_BLOCK_CLASS } from '../content-block/DownloadableContentBlock';
import type { ExportDataKeyValue } from '../contexts/export-context';

/** Downloadable block will have DOWNLOAD_BLOCK_CLASS */
export const getDownloadableImagesBlocks = (): NodeListOf<HTMLElement> | null => {
  if (!document) {
    return null;
  }
  return document.querySelectorAll(`.${DOWNLOAD_BLOCK_CLASS}`);
};

export const getDownloadableDataKeys = (exportData: ExportDataKeyValue): string[] => {
  if (!document) {
    return [];
  }
  const contents = document.querySelectorAll(`.${DOWNLOAD_DATA_BLOCK_CLASS}`);

  /** Collect correct visible download blocks */
  const currentVisibleBlocksName: string[] = [];
  for (let i = 0; i < contents.length; i++) {
    const content = contents[i]!;
    const title = (content as HTMLDivElement).dataset.title || content.querySelector('h3')?.innerText;
    if (title) {
      currentVisibleBlocksName.push(title);
    }
  }

  /** Filter out invisible block's data */
  return Object.keys(exportData).filter((key) => currentVisibleBlocksName.includes(key));
};

interface ScheduledOption {
  icon: string;
  label: string;
  nextTime?: string;
  hint?: string;
  value: FrequencyEnum;
  short?: string;
}

const CALENDAR_PLUS = 'calendar-plus';

export const getScheduledOptions = (): ScheduledOption[] => {
  const nextMonday = moment().startOf('isoWeek').add(1, 'week');
  const firstDayNextMonth = moment().startOf('month').add(1, 'month');

  const firstDayNextQuarter = moment().startOf('quarter').add(1, 'quarter');

  return [
    {
      label: 'Quarterly',
      hint: 'First Day/Quarter',
      nextTime: firstDayNextQuarter.format(Dates.DRAWDOWN_DATE_FORMAT),
      value: 'QUARTERLY',
      icon: CALENDAR_PLUS,
      short: 'qtr',
    },
    {
      label: 'Monthly',
      hint: 'First Day/Month',
      nextTime: firstDayNextMonth.format(Dates.DRAWDOWN_DATE_FORMAT),
      value: 'MONTHLY',
      icon: CALENDAR_PLUS,
      short: 'mth',
    },
    {
      label: 'Weekly',
      hint: 'Every Monday',
      nextTime: nextMonday.format(Dates.DRAWDOWN_DATE_FORMAT),
      value: 'WEEKLY',
      icon: CALENDAR_PLUS,
      short: 'wk',
    },
    { label: 'Off', value: 'UNKNOWN', icon: 'calendar-times' },
  ];
};
