import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AllRequiredNotes,
  Anchor,
  AuthButton,
  AuthFormErrors,
  AuthLoader,
  AuthLogo,
  AuthSubParagraph,
  ContentWrapper,
  EmailInput,
  Info,
  NameInputs,
  SignUpTerms,
  StyledCheckboxWrapper,
  StyledHeadline1,
} from '../components';
import { getAppTitle, GetColor, getTextThemeProvider, Subtitle1 } from 'venn-ui-kit';
import { Link } from 'react-router-dom';
import type { Field, Fields, FormValues } from 'venn-components';
import { COOKIE_SETTINGS, callIfFunctionalCookiesAllowed, FormInput, useForm } from 'venn-components';
import { analyticsService, AnalyticsUtils, IS_PROD } from 'venn-utils';
import type { SignupRequest } from 'venn-api';
import { attemptUserSignupV3 } from 'venn-api';
import sendHubspotTrackingInformation from './hubspotTracking';
import { useCookies } from 'react-cookie';
import type { SignUpPageState } from './SignUpPage';

interface SignUpPageFormFields extends Fields {
  email: Field;
  firstName: Field;
  lastName: Field;
  company: Field;
}

interface SignupFormProps {
  error: string;
  fields: SignUpPageFormFields;
  setPageState: React.Dispatch<React.SetStateAction<SignUpPageState>>;
}

const SignupForm = ({ fields: inputFields, error, setPageState }: SignupFormProps) => {
  const [cookies] = useCookies([COOKIE_SETTINGS]);
  const [termsCheckboxChecked, setTermsCheckbox] = useState<boolean>(false);
  const legalAgreementsSigned = [] as string[];
  if (termsCheckboxChecked) legalAgreementsSigned.push('privacy-policy');

  const onSubmit = async (values: FormValues) => {
    const { email, firstName, lastName, company } = values;

    const signupRequest: Partial<SignupRequest> = {
      email,
      firstName,
      lastName,
      organizationLegalName: company,
      legalAgreements: legalAgreementsSigned,
    };

    const trackingTraits = {
      email,
      firstName,
      lastName,
      isAccountAdmin: true, // If someone is signing up through this flow, they will be their org admin
      company: {
        name: company,
      },
      organizationName: company,
    };

    try {
      const response = await attemptUserSignupV3(signupRequest);
      const responseStatus = response?.content?.status;
      if (response.content.existingAccount) {
        setPageState((prev) => ({ ...prev, existingAccount: true }));
        return;
      }
      if (responseStatus === 'RESTRICTED') {
        analyticsService.signupFunnelTerminated({
          stepDescription: 'account details',
        });
      } else {
        if (IS_PROD) {
          // Ensure no cookies are set until we have consent
          const onCookiesAccepted = () => {
            sendHubspotTrackingInformation(signupRequest);
          };

          callIfFunctionalCookiesAllowed(cookies, onCookiesAccepted);
        }

        analyticsService.signupStepCompleted({
          stepDescription: 'account details',
        });
      }
      AnalyticsUtils.updateAccountStatus(trackingTraits, responseStatus, response?.content?.userId);
      setPageState((prev) => ({
        ...prev,
        status: responseStatus,
        signupValues: signupRequest,
        error: '',
      }));
    } catch (e) {
      const { content } = await e;
      setPageState((prev) => ({
        ...prev,
        error: content?.message || 'We were unable to complete your sign up. Please try again later.',
      }));
    }
  };

  const [fields, isValid, isSubmitting, submit] = useForm(Object.values(inputFields), onSubmit);
  const disabled = !isValid || !termsCheckboxChecked || isSubmitting;

  return (
    <ContentWrapper isLoading={!!isSubmitting}>
      {isSubmitting && <AuthLoader title="Authenticating" />}
      {!isSubmitting && (
        <>
          <SignIn />
          <FormWrapper data-testid="qa-signup">
            <AuthLogo />
            <Info>
              <StyledHeadline1>{`Sign up for ${getAppTitle()}.`}</StyledHeadline1>
              <Subtitle1>{`Does your organization already have a ${getAppTitle()} account?`}</Subtitle1>
              <Subtitle1>Contact your organization’s admin to request an invite.</Subtitle1>
            </Info>
            <StyledAuthForm onSubmit={submit}>
              <AllRequiredNotes />
              <NameInputs firstName={fields.firstName} lastName={fields.lastName} />
              <EmailInput field={fields.email!} />
              <FormInput
                autoComplete="organization"
                inputId="organization"
                required
                label="Organization Name"
                infoIconText='Full legal name of your company. E.g., "Two Sigma Investor Solutions, LP" not "Two Sigma"'
                {...fields.company}
              />
              <StyledCheckboxWrapper
                checkboxDataTestId="terms-checkbox"
                checked={termsCheckboxChecked}
                onChange={() => setTermsCheckbox(!termsCheckboxChecked)}
                required
              >
                <SignUpTerms term="privacy" />
              </StyledCheckboxWrapper>
              <AuthFormErrors error={error} />
              <StyledAuthButton dominant type="submit" disabled={disabled}>
                GET STARTED
              </StyledAuthButton>
            </StyledAuthForm>
          </FormWrapper>
        </>
      )}
      <AuthSubParagraph>
        Need help with signup?&nbsp;
        <Anchor href={`mailto:${getTextThemeProvider().supportEmail}`}>Contact Us</Anchor>
      </AuthSubParagraph>
    </ContentWrapper>
  );
};

export default SignupForm;

const SignIn = () => (
  <StyledSpan>
    Already have an account? <SignInLink to="sign-in">Sign In</SignInLink>
  </StyledSpan>
);

const FormWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledAuthForm = styled.form`
  max-width: 450px;
  padding: 20px 20px 0;
`;

const StyledAuthButton = styled(AuthButton)`
  margin-top: 20px;
`;

const StyledSpan = styled.div`
  font-size: 14px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  color: ${GetColor.White};
  text-align: right;
  padding-top: 20px;
  padding-right: 100px;
  align-self: flex-end;
`;

const SignInLink = styled(Link)`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;

  &:hover {
    color: ${GetColor.White};
  }
`;
