import React from 'react';
import { Modal, ModalFooter, ModalHeader, ModalSubhead } from '../modal';

interface ConfirmUnsavedViewModalProps {
  message: string;
  description: string | undefined;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmUnsavedViewModal = ({
  message,
  description,
  onConfirm,
  onCancel,
}: ConfirmUnsavedViewModalProps) => {
  return (
    <Modal extraPadding>
      <ModalHeader extraPadding>{message}</ModalHeader>
      {description && <ModalSubhead extraPadding>{description}</ModalSubhead>}
      <ModalFooter onCancel={onCancel} onPrimaryClick={onConfirm} primaryLabel="Yes, discard changes" />
    </Modal>
  );
};
