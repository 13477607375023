import { getTextThemeProvider } from 'venn-ui-kit';
import { VennEvents } from 'venn-utils';

export enum CookiesAllowed {
  All,
  FunctionalOnly,
  AdvertisingOnly,
  None,
}

export const COOKIE_SETTINGS = 'cookie_settings';

export const callIfFunctionalCookiesAllowed = (cookies: Record<string, unknown>, func: () => void) => {
  if (cookies[COOKIE_SETTINGS] === CookiesAllowed.All || cookies[COOKIE_SETTINGS] === CookiesAllowed.FunctionalOnly) {
    func();
  } else {
    document.addEventListener(VennEvents.functionalCookiesAccepted, func, {
      once: true,
    });
  }
};

export const REQUIRED_COOKIES = {
  // User-facing details
  description:
    'Required cookies are necessary for core website functionality such as security, authentication, and transmission of the website. Certain required cookies are set by our third party vendors and cannot be modified by us.',
  firstParty: ['csp_session_key', 'X-Csrf-Token', COOKIE_SETTINGS],
  thirdParty: ['__cfruid', '_fbp', '_ga', '_gcl_au', '_gid', '_hjid', 'BCSI-CS-{n}', 'hs_ab_test'],
  lastUpdated: '2021-07-01',
};

export const FUNCTIONAL_COOKIES = {
  // User-facing details
  description:
    'Functional cookies help us enhance functions, performance, and services on the website. Examples include cookies used to analyze site traffic, cookies used for market research, and cookies used to display advertising that is not directed to a particular individual.',
  providers: [
    'Hubspot',
    'Amplitude',
    'Google Analytics',
    'Intercom',
    'Segment',
    'Datadog',
    'Appcues',
    'LinkedIn Insight Tag',
  ],
  lastUpdated: '2021-07-01',
  // Non-user-facing
  thirdParty: [
    '__hssc',
    '__hssluid',
    '__hssrc',
    '__hstc',
    'hubspotutk',
    '_fbp',
    '_ga',
    '_gcl_au',
    'amplitude_id',
    'driftt_aid',
    'driftt_eid',
    'optimizelyEndUserId',
    'product_store_identifier',
    '__zlcmid',
    'BCSI-CS-',
    'OptanonConsent',
    '_gat',
    '_gid',
    'gtm_id',
    'intercom-id-',
    'intercom-session-',
    'ajs_anonymous_id',
    'ajs_group_id',
    'ajs_user_id',
    'seg_xid',
    'timezone',
    '_dd_s',
  ],
  hostnames: ['.amplitude.com', '.intercom.io', '.segment.com', '.datadoghq.com', '.app.hubspot.com'],
};

export const ADVERTISING_COOKIES = {
  // User-facing details
  description: `Advertising cookies track activity across websites in order to understand a viewer’s interests and direct them specific ${
    getTextThemeProvider().AppTitle
  }${getTextThemeProvider().tsParam} marketing content.`,
  providers: ['LinkedIn Ads', 'Adnxs', 'Doubleclick', 'Drawbridge', 'Google Ads', 'Adsymptotic'],
  lastUpdated: '2021-07-01',
  // Non-user-facing
  thirdParty: [
    '_ga',
    'bcookie',
    'bscookie',
    'lang',
    'li_sugr',
    'lidc',
    'UserMatchHistory',
    'anj',
    'icu',
    'usersync',
    'uuid2',
    'DSID',
    'IDE',
    'BCSI-CS-',
    '__cfduid',
    'U',
  ],
  hostnames: [
    '.adnxs.com',
    '.ads.linkedin.com',
    '.doubleclick.com',
    '.doubleclick.net',
    'www.googleadservices.com',
    'www.google.com',
    '.adsymptotic.com',
  ],
};

function deleteCookies(cookieNames: string[], hostnames: string[], window: Window) {
  const cookies = window.document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    let isInCookieNames = false;
    for (let i = 0; i < cookieNames.length; i++) {
      if (cookies[c]!.startsWith(cookieNames[i]!)) {
        isInCookieNames = true;
        break;
      }
    }
    if (isInCookieNames) {
      for (const hostname of hostnames) {
        const d = hostname.split('.');
        while (d.length > 0) {
          // Override cookie with an expiration in the past to delete cookie
          // https://stackoverflow.com/a/53573622
          const cookieBase = `${encodeURIComponent(
            cookies[c]!.split(';')[0]!.split('=')[0]!,
          )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')}; path=`;
          const p = window.location.pathname.split('/');
          window.document.cookie = `${cookieBase}/`;
          while (p.length > 0) {
            window.document.cookie = cookieBase + p.join('/');
            p.pop();
          }
          d.shift();
        }
      }
    }
  }
}

export function isTrackingBlockedBySignal() {
  return navigator.doNotTrack === '1' || navigator.globalPrivacyControl === '1';
}
export function deleteFunctionalCookies(w: Window = window) {
  deleteCookies(FUNCTIONAL_COOKIES.thirdParty, [w.location.hostname, ...FUNCTIONAL_COOKIES.hostnames], w);
}

export function deleteAdvertisingCookies(w: Window = window) {
  deleteCookies(ADVERTISING_COOKIES.thirdParty, ADVERTISING_COOKIES.hostnames, w);
}
