import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ExternalActivityListener, LegacyRelativePortal } from 'venn-ui-kit';
import { ProxyPicker, type ProxyPickerWrapperProps } from '../pickers/proxy-picker';
import type { FundProxy, FundToProxy } from '../pickers/types';
import { Modal } from '../../modal';
import { useHasFF } from 'venn-utils';
import { BulkProxyPicker, type ProxyMetaWithName } from '../pickers/proxy-picker/BulkProxyPicker';
import SidePanelOverlay from '../../side-panel-overlay/SidePanelOverlay';

type ProxyDropdownDirection = 'left' | 'right';
type DropdownVerticalDirection = 'down' | 'up';

export interface ProxyTriggerProps extends ProxySelectorState {
  onToggleSelector: () => void;
  isProxyUpdating: boolean;
}

export interface ProxySelectorProps {
  investment: FundToProxy;
  proxy: FundProxy | null;
  dropdownDirection?: ProxyDropdownDirection;
  dropdownVerticalDirection?: DropdownVerticalDirection;
  hoverAddProxy?: boolean;
  onOpen?: (fundId: string) => void;
  onClose?: (fundId: string) => void;

  /** The function to call with the new proxy (undefined if proxy was removed)
   * if the investment's proxy has been changed. */
  onProxyChange(proxy?: FundProxy): void | Promise<void>;

  usePortal?: boolean;
  portalLeftOffset?: number;
  portalTopOffset?: number;
  children: (renderProps: ProxyTriggerProps) => React.ReactNode;
  loading?: boolean;
  readOnly?: boolean;
}

interface ProxySelectorState {
  selectorOpen: boolean;
  selectedProxy: FundProxy | null;
}

const ProxySelector: React.FC<ProxySelectorProps> = (props) => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');

  const [selectorOpen, setSelectorOpen] = useState(false);
  const [selectedProxy, setSelectedProxy] = useState<FundProxy | null>(props.proxy);

  useEffect(() => {
    setSelectedProxy(props.proxy);
  }, [props.proxy]);

  const toggleSelector = useCallback(() => {
    const { onClose, onOpen, investment } = props;
    if (selectorOpen) {
      onClose?.(investment.id);
    } else {
      onOpen?.(investment.id);
    }
    setSelectorOpen(!selectorOpen);
  }, [selectorOpen, props]);

  const closeSelector = useCallback(() => {
    const { onClose, investment } = props;
    onClose?.(investment.id);
    setSelectorOpen(false);
  }, [props]);

  const saveAndCloseSelector = useCallback(
    (saveFn: () => void) => () => {
      const { onClose, investment } = props;
      setSelectorOpen(false);
      saveFn();
      onClose?.(investment.id);
    },
    [props],
  );

  const onProxyChange = useCallback(
    async (proxy?: FundProxy) => {
      const { onProxyChange } = props;
      closeSelector();
      await onProxyChange(proxy);
    },
    [props, closeSelector],
  );

  const onBulkProxyChange = async (appliedProxies: ProxyMetaWithName[]) => {
    closeSelector();
    if (appliedProxies.length === 0) {
      return;
    }
    const { proxyName, proxyType, proxyId, fundId, numLags, extrapolate } = appliedProxies[0]!;
    await onProxyChange({
      name: proxyName,
      proxyId,
      id: fundId,
      proxyType,
      numLags,
      extrapolate,
    });
  };

  const renderRelativeWrapper = useCallback(
    (children: JSX.Element) => {
      const { usePortal, portalLeftOffset, portalTopOffset } = props;
      return usePortal ? (
        <LegacyRelativePortal left={portalLeftOffset ?? 50} top={portalTopOffset}>
          {children}
        </LegacyRelativePortal>
      ) : (
        children
      );
    },
    [props],
  );

  const { investment, usePortal, children, loading, readOnly } = props;

  const renderer = ({ picker, isProxyUpdating, saveUnsavedChanges }: ProxyPickerWrapperProps) => (
    <StyledProxySelector className="qa-proxy-cell">
      {children({
        selectorOpen,
        selectedProxy,
        onToggleSelector: toggleSelector,
        isProxyUpdating: Boolean(loading) || isProxyUpdating,
      })}
      {selectorOpen
        ? renderRelativeWrapper(
            <SelectorContainer closeOnEsc noPadding>
              <ExternalActivityListener onExternalActivity={saveAndCloseSelector(saveUnsavedChanges)}>
                {picker}
              </ExternalActivityListener>
            </SelectorContainer>,
          )
        : null}
    </StyledProxySelector>
  );

  return hasBulkProxy && selectorOpen ? (
    <SidePanelOverlay.Root
      width={1062}
      margin={0}
      noPadding
      side="right"
      isOpen={selectorOpen}
      handleClose={closeSelector}
      hideTopBackButton
    >
      <BulkProxyPicker investments={[investment]} onProxyChange={onBulkProxyChange} />
    </SidePanelOverlay.Root>
  ) : (
    <ProxyPicker
      wrapperRenderer={renderer}
      reset={selectorOpen}
      proxy={selectedProxy}
      investment={investment}
      onProxyChange={onProxyChange}
      disableAutofocus={usePortal}
      loadPickerData={selectorOpen}
      closePicker={closeSelector}
      readOnly={readOnly}
    />
  );
};

const StyledProxySelector = styled.div`
  position: relative;
  width: 100%;
`;

const SelectorContainer = styled(Modal)`
  &.modal-wrapper {
    width: auto;
  }
`;

export default ProxySelector;
