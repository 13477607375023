import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import type { NotableFactorPeriod } from 'venn-api';
import type { ContentChartLegendItem, HighchartOptions } from 'venn-components';
import { ContentChartLegend } from 'venn-components';
import type { Typography, VennColors } from 'venn-ui-kit';
import { GetColor } from 'venn-ui-kit';
import useFactorPerformance from './useFactorPerformance';
import type { Factor } from './types';
import { parseContent } from '../shared/FactorEducationUtils';
import type {
  TooltipFormatterContextObject,
  GradientColorObject,
  PatternObject,
  AxisLabelsFormatterContextObject,
} from 'highcharts';
import { VennHighchart } from '../../../../../venn-components/src/highchart/Highchart';

interface NotableItemProps {
  factor: Factor;
  period: NotableFactorPeriod;
}

const NotableItem = ({ factor, period }: NotableItemProps) => {
  const factorPerformance = useFactorPerformance(factor, period);
  const { Typography, Colors } = useContext(ThemeContext);
  const legendItems: ContentChartLegendItem[] = factorPerformance
    ? [{ name: factorPerformance.name, color: Colors.DataLineColor.Gold }]
    : [];
  const chartConfig = useMemo(() => {
    if (factorPerformance) {
      return config(factorPerformance.name, period, factorPerformance.returns, Typography, Colors);
    }
    return undefined;
  }, [factorPerformance, period, Typography, Colors]);
  return (
    <NotableContainer>
      {factorPerformance && chartConfig && (
        <ChartContainer className="qa-notable-chart">
          <ContentChartLegend items={legendItems} />
          <VennHighchart className="qa-notable-item-chart" options={chartConfig} />
        </ChartContainer>
      )}
      <TextContainer>
        <PeriodName className="qa-notable-period-header">{period.name}</PeriodName>
        <List>
          {parseContent(period.periodContext).map(({ content, key }) => (
            <ListItem key={key}>{content}</ListItem>
          ))}
        </List>
      </TextContainer>
    </NotableContainer>
  );
};

function getTooltipFormatter(
  color: string | GradientColorObject | PatternObject,
  name: string,
  x: number,
  y: number,
  typography: Typography,
): string {
  return `
    <div style="font-family:${typography.fontFamily};" class="tooltip-content">
      <b>${moment.utc(x).format('MMM D, YYYY')}</b><br/>
      <div
        class="highcharts-tooltip-circle"
        style="background:${color};"></div>
      ${name}: <b>${(y * 100).toFixed(2)}%</b>
    </div>
  `;
}

const config = (
  factor: string,
  period: NotableFactorPeriod,
  returns: number[][],
  typography: Typography,
  colors: VennColors,
): HighchartOptions => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'line',
    marginTop: 0,
    marginBottom: 30,
    marginRight: 0,
    marginLeft: 0,
    zoomType: undefined,
  },
  title: {
    text: '',
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
    },
  },
  xAxis: [
    {
      type: 'datetime',
      gridLineWidth: 1,
      minorTickLength: 0,
      tickLength: 0,
      minPadding: 0.1,
      maxPadding: 0,
      startOnTick: true,
      endOnTick: false,
      showFirstLabel: false,
      crosshair: true,
      dateTimeLabelFormats: {
        day: { main: "%d&nbsp;%b.&nbsp;'%y" },
        week: { main: "%d&nbsp;%b.&nbsp;'%y" },
        month: { main: "%b.&nbsp;'%y" },
        year: { main: "%b.&nbsp;'%y" },
      },
      labels: {
        y: 20,
        useHTML: true,
        overflow: 'justify',
        style: {
          fontFamily: typography.fontFamily,
          fontSize: '14px',
        },
      },
    },
  ],
  yAxis: [
    {
      title: { text: null },
      gridLineWidth: 1,
      startOnTick: true,
      endOnTick: true,
      showFirstLabel: true,
      showLastLabel: false,
      // this gently forces the "0%" tick to show in the chart
      softMax: -0.1 * Math.min(...returns.map((r) => r[1]!)),
      minPadding: 0,
      labels: {
        align: 'right',
        formatter(this: AxisLabelsFormatterContextObject) {
          return `${(this.value * 100).toFixed(1)}%`;
        },
        style: {
          fontFamily: typography.fontFamily,
          fontSize: '14px',
        },
        x: 60,
      },
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: colors.TransparentBlack,
    style: {
      color: colors.White,
    },
    formatter(this: TooltipFormatterContextObject) {
      const { color, series, x, y } = this;
      return getTooltipFormatter(color, series.name, x, y, typography);
    },
    useHTML: true,
  },
  series: [
    {
      type: 'line',
      name: factor,
      color: colors.DataLineColor.Gold,
      data: returns.map((r) => [moment.utc(r[0]).startOf('day').valueOf(), r[1]!]),
    },
  ],
});

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 300px;
  padding: 40px 60px;
  border-top: 1px solid ${GetColor.PaleGrey};

  h1 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  ul {
    padding-inline-start: 20px;
    width: 100%;
  }

  ul li {
    font-size: 14px;
    line-height: 1.71;
    margin-top: 15px;
  }
`;

const NotableContainer = styled.div`
  display: flex;
`;

const ChartContainer = styled.div`
  flex: 1;
  border-right: 1px solid ${GetColor.PaleGrey};
  max-width: 700px;

  .tooltip-content b {
    color: ${GetColor.White};
  }

  .highcharts-tooltip-circle {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid ${GetColor.White};
  }
`;

const PeriodName = styled.div`
  font-size: 32px;
  font-weight: 300;
`;

const ListItem = styled.li`
  line-height: 20px;
`;

const List = styled.ul`
  padding-top: 18px;
  padding-left: 18px;
`;

export default NotableItem;
