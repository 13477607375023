import React from 'react';
import styled from 'styled-components';
import FooterAttribution from './FooterAttribution';
import { UserContext } from '../contexts';
import { CookiePreferencesTrigger } from '../cookie-banner';
import { GetColor } from 'venn-ui-kit';
import type { UserLanguageEnum } from 'venn-api';
import { legalDocLinks, useRecoilValueWithDefault } from 'venn-state';

export interface FooterLink {
  titles: { language: string; text: string }[];
  baseUrl: string;
}

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 60px;
  background-color: ${GetColor.DarkGrey};
  transform: translate3d(0, 0, 0);

  @media print {
    display: none;
  }
`;

const StyledFooterNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const StyledFooterNav = styled.ul`
  padding: 0;
  margin: 0;
  float: right;
  list-style-type: none;

  li {
    display: inline-block;
    margin-left: 10px;
    height: 16px;
  }
`;

const StyledFooterLinkNewTab = styled.a`
  color: ${GetColor.Primary.Main};
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: left;

  :hover,
  :focus {
    border-bottom: 1px solid ${GetColor.Primary.Main};
  }
`;

const StyledAttributionWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const StyledAttribution = styled(FooterAttribution)`
  padding-top: 10px;
  color: ${GetColor.White};
`;

interface FooterLinksProps {
  language?: UserLanguageEnum;
  jurisdiction?: string;
}

const getTitle = (link: FooterLink, inLanguage?: string): string => {
  const title = link.titles.find(({ language }) => language === inLanguage);
  return title ? title.text : link.titles[0]!.text; // if nothing found, use the first one
};

interface LocalizedFooterLinkProps {
  link: FooterLink;
  language?: UserLanguageEnum;
  jurisdiction?: string;
}

export const LocalizedFooterLink: React.FC<React.PropsWithChildren<LocalizedFooterLinkProps>> = ({
  link,
  language,
  jurisdiction,
}) => {
  const linkTarget = jurisdiction ? `${link.baseUrl}?jurisdiction=${jurisdiction}` : link.baseUrl;
  return (
    <StyledFooterLinkNewTab href={linkTarget} target="_blank" rel="noopener noreferrer">
      {getTitle(link, language)}
    </StyledFooterLinkNewTab>
  );
};

const FooterLinks: React.FC<React.PropsWithChildren<FooterLinksProps>> = ({ language, jurisdiction }) => {
  const links = useRecoilValueWithDefault(legalDocLinks, []);
  return (
    <nav>
      <StyledFooterNav>
        {links.map((link: FooterLink) => (
          <li key={link.baseUrl}>
            <LocalizedFooterLink link={link} language={language} jurisdiction={jurisdiction} />
          </li>
        ))}
        <CookiePreferencesTrigger />
      </StyledFooterNav>
    </nav>
  );
};

const Footer: React.FC = () => (
  <StyledFooter>
    <StyledFooterNavWrapper>
      <UserContext.Consumer>
        {({ profileSettings }) => (
          <FooterLinks
            language={profileSettings?.organization?.language}
            jurisdiction={profileSettings?.organization?.country}
          />
        )}
      </UserContext.Consumer>
    </StyledFooterNavWrapper>
    <StyledAttributionWrapper>
      <UserContext.Consumer>
        {({ profileSettings }) => (
          <StyledAttribution countryCode={profileSettings?.organization?.jurisdiction?.iso2Code} />
        )}
      </UserContext.Consumer>
    </StyledAttributionWrapper>
  </StyledFooter>
);

export default React.memo(Footer);
