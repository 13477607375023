import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import type { BasicTableColumn } from '../basictable/BasicTable';
import { ColumnAlign, SORTDIR } from '../basictable/BasicTable';
import type { DrawdownPeriodAnalysis, FrequencyEnum } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { Icon } from 'venn-ui-kit';
import HistoricalLineChart from './HistoricalLineChart';
import type { AnalysisSubject, AnalysisGroup } from 'venn-utils';
import {
  Dates,
  Numbers,
  getFirstNegativeDay,
  getPeriodLengthWithFrequency,
  getSecondaryDisplayLabel,
} from 'venn-utils';
import compact from 'lodash/compact';
import type { RowWithID } from '../basictable/types';
import { getLineColors } from '../charts/analysis-charts/internal/utils';

const formatDate = Dates.toDDMMMYYYY;
const formatPercentage = (value: number) => Numbers.safeFormatPercentage(value, 1);
const formatNumber = (value: number) => Numbers.safeFormatNumber(value * 100, 1);

export const getColumns = (
  subject: AnalysisSubject,
  drawdownPeriods: Partial<DrawdownPeriodAnalysis>[],
  analysisGroup: AnalysisGroup<Partial<DrawdownPeriodAnalysis>[]>,
  hasAnalyzeError: boolean,
  relative: boolean,
  frequency: FrequencyEnum | undefined,
  print: boolean,
  theme: Theme,
) => {
  const isPortfolio = subject?.type === 'portfolio';
  const hasAnalyzeBenchmark = !!subject?.activeBenchmark;
  const hasActiveBaseline = !!analysisGroup?.category;
  const hasAnalyzeSecondary = isPortfolio && hasActiveBaseline;
  const hasAnalyzeCategory = !isPortfolio && hasActiveBaseline;
  const subjectName = subject?.name;
  const benchmarkName = subject?.activeBenchmarkName;
  const categoryName = subject?.categoryGroup?.name;
  const masterPortfolioName = subject?.secondaryPortfolio?.name;

  const secondaryLabel = getSecondaryDisplayLabel(subject, undefined, 'Portfolio');
  const excelComparisonLabel = getSecondaryDisplayLabel(subject, `${masterPortfolioName} as of`, 'Portfolio');

  const start = drawdownPeriods.length ? drawdownPeriods[0]!.start : undefined;
  const end = drawdownPeriods.length ? drawdownPeriods[drawdownPeriods.length - 1]!.end : undefined;

  return getColumnsBase(
    start,
    end,
    hasAnalyzeSecondary,
    hasAnalyzeBenchmark,
    hasAnalyzeCategory,
    hasAnalyzeError,
    relative,
    isPortfolio,
    frequency,
    print,
    subjectName,
    theme,
    benchmarkName,
    categoryName,
    masterPortfolioName,
    secondaryLabel,
    excelComparisonLabel,
  );
};

const getColumnsBase = (
  start: number | undefined,
  end: number | undefined,
  hasAnalyzeSecondary: boolean,
  hasAnalyzeBenchmark: boolean,
  hasAnalyzeCategory: boolean,
  hasAnalyzeError: boolean,
  relative: boolean,
  isPortfolio: boolean,
  frequency: FrequencyEnum | undefined,
  print: boolean,
  subjectName: string,
  theme: Theme,
  benchmarkName?: string,
  categoryName?: string,
  masterPortfolioName?: string,
  comparisonLabel?: string,
  excelComparisonLabel?: string,
): BasicTableColumn<DrawdownPeriodAnalysis>[] => {
  const cellColor = theme.Colors.MidGrey1;
  const borderColor = theme.Colors.Grey;
  const [mainColor, secondaryColor, benchmarkColor, categoryColor] = getColors(
    hasAnalyzeSecondary,
    hasAnalyzeBenchmark,
    hasAnalyzeCategory,
    relative,
    theme,
  );

  return compact([
    getDrawdownPeriodColumn(relative, frequency, print),
    getRangeChartColumn(start, end, hasAnalyzeError, borderColor),
    getDrawdownLengthColumn(relative, frequency),
    getRecoveryColumn(frequency),
    getMaxDrawdownColumn(isPortfolio, subjectName, mainColor),
    getMaxDrawdownErrorColumn(hasAnalyzeError, cellColor, mainColor),
    getComparisonColumn(
      hasAnalyzeSecondary,
      secondaryColor,
      masterPortfolioName,
      comparisonLabel,
      excelComparisonLabel,
    ),
    getComparisonErrorColumn(hasAnalyzeSecondary, hasAnalyzeError, cellColor, secondaryColor),
    getBenchmarkColumn(hasAnalyzeBenchmark, relative, benchmarkName, benchmarkColor),
    getBenchmarkErrorColumn(hasAnalyzeBenchmark, hasAnalyzeError, relative, cellColor, benchmarkColor),
    getCategoryColumn(hasAnalyzeCategory, categoryName, categoryColor),
    getCategoryErrorColumn(hasAnalyzeCategory, hasAnalyzeError, cellColor, categoryColor),
  ]);
};

function getColors(
  hasAnalyzeSecondary: boolean,
  hasAnalyzeBenchmark: boolean,
  hasAnalyzeCategory: boolean,
  relative: boolean,
  theme: Theme,
) {
  const lineColors = getLineColors(theme);
  let colorIdx = 0;
  const mainColor = lineColors[colorIdx++];
  const secondaryColor = hasAnalyzeSecondary ? lineColors[colorIdx++] : undefined;
  const benchmarkColor = hasAnalyzeBenchmark && !relative ? lineColors[colorIdx++] : undefined;
  const categoryColor = hasAnalyzeCategory ? lineColors[colorIdx] : undefined;
  return [mainColor, secondaryColor, benchmarkColor, categoryColor];
}

const getDrawdownPeriodColumn = (
  relative: boolean,
  frequency: FrequencyEnum | undefined,
  print: boolean,
): BasicTableColumn<DrawdownPeriodAnalysis> => ({
  label: relative ? 'Underperformance Period' : 'Drawdown Period',
  accessor: 'start',
  headerStyle: { paddingLeft: 20 },
  cellStyle: { minWidth: print ? 100 : 215, paddingLeft: 20 },
  arrowStyle: {
    display: 'inline-block',
    position: 'static',
    transform: 'none',
    top: 'unset',
    bottom: 0,
  },
  sortable: true,
  sorted: SORTDIR.ASC, // By default, data returns from the backend sorted by date ascending
  sortTableFunc: (data: (DrawdownPeriodAnalysis & RowWithID)[], dir: SORTDIR) =>
    data.sort((a, b) => (dir === SORTDIR.ASC ? a.start - b.start : b.start - a.start)),
  cellRenderer: ({ start, end }: DrawdownPeriodAnalysis) =>
    `${formatDate(getFirstNegativeDay(start).valueOf(), frequency)} – ${formatDate(end, frequency)}`,
  excelCellRenderer: (_, value) => ({
    value: value ?? undefined,
    bold: true,
  }),
});

const getRangeChartColumn = (
  globalStart: number | undefined,
  globalEnd: number | undefined,
  hasAnalyzeError: boolean,
  borderColor: string,
): BasicTableColumn<DrawdownPeriodAnalysis> => ({
  headerRenderer: () => (
    <>
      <LegendItem>
        {getCircle(hasAnalyzeError)}
        Startpoint
      </LegendItem>{' '}
      /
      <LegendItem>
        <Icon type="times" prefix="far" />
        Trough
      </LegendItem>{' '}
      /
      <LegendItem>
        {getCircle(hasAnalyzeError)}
        Endpoint
      </LegendItem>
    </>
  ),
  label: 'Trough',
  accessor: 'range',
  headerStyle: {
    minWidth: 250,
    textAlign: 'center',
  },
  cellStyle: {
    borderLeft: `dashed 1px ${borderColor}`,
    borderRight: `dashed 1px ${borderColor}`,
  },
  cellRenderer: ({ start, end, trough, completed }: DrawdownPeriodAnalysis) => {
    const chart = (
      <HistoricalLineChart
        key={start}
        start={start}
        trough={trough}
        end={end}
        completed={completed}
        globalStart={globalStart}
        globalEnd={globalEnd}
        hasAnalyzeError={hasAnalyzeError}
      />
    );

    return <HistoricalLineChartWrapper>{chart}</HistoricalLineChartWrapper>;
  },
  excelCellRenderer: ({ trough }) => ({ value: moment.utc(trough).format('DD MMM YYYY') }),
});

const getCircle = (hasError: boolean) => (hasError ? <Icon type="circle" prefix="far" /> : <Icon type="circle" />);
const getDrawdownLengthColumn = (
  relative: boolean,
  frequency: FrequencyEnum | undefined,
): BasicTableColumn<DrawdownPeriodAnalysis> => ({
  label: relative ? 'Underperformance Length (Months)' : 'Drawdown Length (Months)',
  align: ColumnAlign.RIGHT,
  headerStyle: { width: 115 },
  sortable: true,
  sortTableFunc: (data: (DrawdownPeriodAnalysis & RowWithID)[], dir: SORTDIR) =>
    data.sort((a, b) =>
      dir === SORTDIR.ASC ? a.trough - a.start - (b.trough - b.start) : b.trough - b.start - (a.trough - a.start),
    ),
  cellRenderer: ({ start, trough }: DrawdownPeriodAnalysis) => getPeriodLengthWithFrequency(frequency, start, trough),
  excelCellRenderer: (_data, content) => ({ value: Number.isNaN(Number(content)) ? content : Number(content) }),
});

const getRecoveryColumn = (frequency: FrequencyEnum | undefined): BasicTableColumn<DrawdownPeriodAnalysis> => ({
  label: 'Recovery Period (Months)',
  accessor: 'trough',
  align: ColumnAlign.RIGHT,
  headerStyle: { width: 150, paddingLeft: 20 },
  sortable: true,
  sortTableFunc: (data: (DrawdownPeriodAnalysis & RowWithID)[], dir: SORTDIR) =>
    data.sort((a, b) =>
      dir === SORTDIR.ASC ? a.end - a.trough - (b.end - b.trough) : b.end - b.trough - (a.end - a.trough),
    ),
  cellRenderer: ({ trough, end, maxDrawdown, completed }: DrawdownPeriodAnalysis) =>
    maxDrawdown <= -1 || !completed ? '--' : getPeriodLengthWithFrequency(frequency, trough, end),
  excelCellRenderer: (_data, content) => ({ value: Number.isNaN(Number(content)) ? content : Number(content) }),
});

const getMaxDrawdownColumn = (
  isPortfolio: boolean,
  name: string,
  color?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> => ({
  label: isPortfolio ? 'Current Portfolio' : 'Investment',
  accessor: 'maxDrawdown',
  align: ColumnAlign.RIGHT,
  sortable: true,
  headerStyle: {
    width: isPortfolio ? 80 : 150,
    minWidth: isPortfolio ? 80 : 155,
    paddingRight: 15,
    color,
  },
  cellStyle: {
    paddingRight: 15,
  },
  cellRenderer: ({ maxDrawdown }: DrawdownPeriodAnalysis) => formatPercentage(maxDrawdown),
  excelCellRenderer: ({ maxDrawdown }) => ({ value: maxDrawdown, percentage: true }),
  excelHeaderRenderer: () => ({ bold: true, value: name }),
});

const getMaxDrawdownErrorColumn = (
  hasAnalyzeError: boolean,
  cellColor: string,
  headerColor?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeError
    ? {
        label: '+/-',
        accessor: 'maxDrawdownError',
        align: ColumnAlign.RIGHT,
        headerStyle: {
          color: headerColor,
        },
        cellStyle: { color: cellColor },
        cellRenderer: ({ maxDrawdownError }: DrawdownPeriodAnalysis) => formatNumber(maxDrawdownError),
        excelCellRenderer: ({ maxDrawdownError }) => ({ value: maxDrawdownError, percentage: true }),
      }
    : null;

const getComparisonColumn = (
  hasAnalyzeSecondary: boolean,
  color?: string,
  masterPortfolioName?: string,
  comparisonLabel?: string,
  exportComparisonLabel?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeSecondary
    ? {
        label: comparisonLabel ?? 'Master Portfolio',
        accessor: 'masterPortfolioMaxDrawdown',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: { color, width: 90, paddingRight: 15 },
        cellStyle: {
          paddingRight: 15,
        },
        cellRenderer: ({ masterPortfolioMaxDrawdown }: DrawdownPeriodAnalysis) =>
          formatPercentage(masterPortfolioMaxDrawdown),
        excelCellRenderer: ({ masterPortfolioMaxDrawdown }) => ({
          value: masterPortfolioMaxDrawdown,
          percentage: true,
        }),
        excelHeaderRenderer: () => ({
          bold: true,
          value: comparisonLabel === 'Master' ? masterPortfolioName : exportComparisonLabel,
        }),
      }
    : null;

const getComparisonErrorColumn = (
  hasAnalyzeSecondary: boolean,
  hasAnalyzeError: boolean,
  cellColor: string,
  headerColor?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeSecondary && hasAnalyzeError
    ? {
        label: '+/-',
        accessor: 'masterPortfolioMaxDrawdownError',
        align: ColumnAlign.RIGHT,
        cellStyle: { color: cellColor },
        headerStyle: { color: headerColor },
        cellRenderer: ({ masterPortfolioMaxDrawdownError }: DrawdownPeriodAnalysis) =>
          formatNumber(masterPortfolioMaxDrawdownError),
        excelCellRenderer: ({ masterPortfolioMaxDrawdownError }) => ({
          value: masterPortfolioMaxDrawdownError,
          percentage: true,
        }),
      }
    : null;

const getBenchmarkColumn = (
  hasAnalyzeBenchmark: boolean,
  relative: boolean,
  name?: string,
  color?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeBenchmark && !relative
    ? {
        label: 'Benchmark',
        accessor: 'benchmarkMaxDrawdown',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: { color, width: 100, paddingRight: 15 },
        cellStyle: {
          paddingRight: 15,
        },
        cellRenderer: ({ benchmarkMaxDrawdown }: DrawdownPeriodAnalysis) => formatPercentage(benchmarkMaxDrawdown),
        excelCellRenderer: ({ benchmarkMaxDrawdown }) => ({ value: benchmarkMaxDrawdown, percentage: true }),
        excelHeaderRenderer: () => ({ bold: true, value: `Benchmark ${name}` }),
      }
    : null;

const getBenchmarkErrorColumn = (
  hasAnalyzeBenchmark: boolean,
  hasAnalyzeError: boolean,
  relative: boolean,
  cellColor: string,
  headerColor?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeBenchmark && hasAnalyzeError && !relative
    ? {
        label: '+/-',
        accessor: 'benchmarkMaxDrawdownError',
        align: ColumnAlign.RIGHT,
        cellStyle: { color: cellColor, paddingRight: 20 },
        headerStyle: { color: headerColor, paddingRight: 20 },
        cellRenderer: ({ benchmarkMaxDrawdownError }: DrawdownPeriodAnalysis) =>
          formatNumber(benchmarkMaxDrawdownError),
        excelCellRenderer: ({ benchmarkMaxDrawdownError }) => ({ value: benchmarkMaxDrawdownError, percentage: true }),
      }
    : null;

const getCategoryColumn = (
  hasAnalyzeCategory: boolean,
  name?: string,
  color?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeCategory
    ? {
        label: 'Category',
        accessor: 'masterPortfolioMaxDrawdown',
        align: ColumnAlign.RIGHT,
        sortable: true,
        headerStyle: { color, width: 100, paddingRight: 15 },
        cellStyle: {
          paddingRight: 15,
        },
        cellRenderer: ({ masterPortfolioMaxDrawdown }: DrawdownPeriodAnalysis) =>
          formatPercentage(masterPortfolioMaxDrawdown),
        excelCellRenderer: ({ masterPortfolioMaxDrawdown }) => ({
          value: masterPortfolioMaxDrawdown,
          percentage: true,
        }),
        excelHeaderRenderer: (_) => ({ bold: true, value: `Category ${name}` }),
      }
    : null;

const getCategoryErrorColumn = (
  hasAnalyzeCategory: boolean,
  hasAnalyzeError: boolean,
  cellColor: string,
  headerColor?: string,
): BasicTableColumn<DrawdownPeriodAnalysis> | null =>
  hasAnalyzeCategory && hasAnalyzeError
    ? {
        label: '+/-',
        accessor: 'masterPortfolioMaxDrawdownError',
        align: ColumnAlign.RIGHT,
        cellStyle: { color: cellColor },
        headerStyle: { color: headerColor },
        cellRenderer: ({ masterPortfolioMaxDrawdownError }: DrawdownPeriodAnalysis) =>
          formatNumber(masterPortfolioMaxDrawdownError),
        excelCellRenderer: ({ masterPortfolioMaxDrawdownError }) => ({
          value: masterPortfolioMaxDrawdownError,
          percentage: true,
        }),
      }
    : null;

const HistoricalLineChartWrapper = styled.div`
  position: absolute;
  top: 4px;
  width: 100%;
`;

export const HistoricalLineChartIEWrapper = styled.div`
  position: relative;
`;

const LegendItem = styled.span`
  display: inline-block;
  margin: 0 5px;
`;
