import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import type { BulkManageRow } from '../types';
import styled, { css, useTheme } from 'styled-components';
import {
  isBulkManageAxisRow,
  isBulkManageFactorRow,
  isBulkManageFundRow,
  isBulkManageStrategyRow,
  isBulkManageTotalRow,
  isHistoricalBulkManageInvestmentRow,
} from '../utils';
import { CellLoader, ColorUtils, GetColor } from 'venn-ui-kit';
import { assertExhaustive, assertNotNil, itemHasNoReturns } from 'venn-utils';
import { emptyRangeTooltipCell, FillParentTooltip } from './tooltip-cells';
import { atStartOfDay, getDataColor, getHistoricalRowRangeLogic, getRowRangeLogic } from '../analysisPeriodUtils';
import { compact } from 'lodash';
import ProxiedInvestmentTooltipContent from '../ProxiedInvestmentTooltipContent';
import React from 'react';
import { formatDateByFrequency } from '../../manage-data';

const ANALYSIS_BAR_HEIGHT = 8;
const HISTORICAL_BAR_HEIGHT = ANALYSIS_BAR_HEIGHT / 2;
const LIQUIDATION_BAR_HEIGHT = ANALYSIS_BAR_HEIGHT;

type RangeRowValue = BulkManageRow;
export type RangeColDef = ColDef<BulkManageRow, RangeRowValue>;
export const overlapColor = ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.15);

export type BarFillType = 'striped' | 'solid' | 'diagonal striped';
export const rangeBarBackground = ({ fillType, color }: { fillType: BarFillType; color: string }) => css`
  background: ${(() => {
    switch (fillType) {
      case 'diagonal striped':
        return `repeating-linear-gradient(-45deg, ${color}, ${color} 2px, transparent 2px, transparent 4px)`;
      case 'striped':
        return `repeating-linear-gradient(to right, ${color} 0, ${color} 4px, transparent 4px,transparent 8px)`;
      case 'solid':
        return color;
      default:
        return assertExhaustive(fillType);
    }
  })()};
`;

const RangeBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
  fillType: BarFillType;
  color: string;
  height: number;
}>`
  ${rangeBarBackground}

  padding: 0;
  position: absolute;

  ${({ widthPercentage, leftMarginPercentage, height }) => css`
    height: ${height}px;
    top: calc(50% - ${Math.floor(height / 2)}px);
    width: ${widthPercentage === 0 ? '1px' : `${widthPercentage * 100}%`};
    margin-left: calc(${leftMarginPercentage * 100 ?? 0}% - ${widthPercentage === 0 ? 1 : 0}px);
  `}
`;

const RangeBarWithGap = styled(RangeBar)<{ hasGap: boolean }>`
  /* Use offsetIndex for spacing: shift margin & shrink width */
  ${({ widthPercentage, leftMarginPercentage, hasGap }) => {
    const gap = hasGap ? 1 : 0;
    return css`
      width: ${`${widthPercentage === 0 ? '1px' : `calc(${widthPercentage * 100}% - ${gap}px)`}`};
      margin-left: calc(${leftMarginPercentage * 100}% + ${gap}px - ${widthPercentage === 0 ? gap + 1 : 0}px);
    `;
  }}
`;

const OverlapBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
}>`
  background-color: ${overlapColor};
  height: 100%;
  width: ${({ widthPercentage }) => widthPercentage * 100}%;
  margin-left: ${({ leftMarginPercentage }) => leftMarginPercentage * 100 ?? 0}%;
  padding: 0;
  top: 0;
  position: absolute;
`;

type Props = ICellRendererParams<BulkManageRow, RangeRowValue> & {
  shouldShowHypotheticalReturnExtension: (row: BulkManageRow) => boolean;
};
export const RangeCellRenderer = ({ data, shouldShowHypotheticalReturnExtension }: Props) => {
  const theme = useTheme();
  if (!data || isBulkManageTotalRow(data)) {
    return null;
  }

  if (isBulkManageAxisRow(data)) {
    if (!data.commonRangeData) {
      return null;
    }
    const { earliestStart, latestEnd, frequency } = data.commonRangeData;
    return (
      <div className="absolute -top-[3px] -left-[3px] w-[calc(100%+6px)]">
        <div className="flex justify-between">
          <div className="w-[6px] h-[6px] bg-venn-grey-80 rounded-full border border-solid border-white" />
          <div className="w-[6px] h-[6px] bg-venn-grey-80 rounded-full border border-solid border-white" />
          <div className="w-[6px] h-[6px] bg-venn-grey-80 rounded-full border border-solid border-white" />
        </div>
        <div className="flex justify-between font-bold text-venn-grey-80 text-[11px]">
          <div>{formatDateByFrequency(earliestStart, frequency)}</div>
          <div>{formatDateByFrequency((earliestStart + latestEnd) / 2, frequency)}</div>
          <div>{formatDateByFrequency(latestEnd, frequency)}</div>
        </div>
      </div>
    );
  }

  if ((isBulkManageFundRow(data) && data.rangeLoading) || !data.commonRangeData) {
    return <CellLoader />;
  }

  const { earliestStart, latestEnd, fullRange, overlap } = data.commonRangeData;

  if (isBulkManageStrategyRow(data)) {
    return overlap.percentageWidth > 0 ? (
      <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
    ) : null;
  }

  if (itemHasNoReturns(data.startDate, data.endDate)) {
    return emptyRangeTooltipCell;
  }

  const zeroAllocation =
    !isBulkManageFactorRow(data) &&
    !isHistoricalBulkManageInvestmentRow(data) &&
    data.allocation === 0 &&
    !isHistoricalBulkManageInvestmentRow(data);
  const { investmentColor, proxyColor, extrapolationColor, historicalColor, hypotheticalReturnExtensionColor } =
    getDataColor(theme, zeroAllocation);

  const frequency = data.frequency;
  const startDate = assertNotNil(atStartOfDay(data.startDate));
  const endDate = assertNotNil(data.endDate);
  const proxyEndDate = isBulkManageFundRow(data) ? data.proxyEndDate : undefined;
  const extrapolateEndDate = isBulkManageFundRow(data) ? data.extrapolateEndDate : undefined;
  const finalReturnDate = Math.max(...compact([endDate, proxyEndDate, extrapolateEndDate]));

  const { proxy, investment, extrapolation } = getRowRangeLogic({
    startDate,
    endDate,
    fullRange,
    earliestStart,
    latestEnd,
    proxyStartDate: atStartOfDay(isBulkManageFundRow(data) ? data.proxyStartDate : undefined),
    proxyEndDate,
    extrapolateEndDate,
    extrapolateStartDate: atStartOfDay(isBulkManageFundRow(data) ? data.extrapolateStartDate : undefined),
  });
  const historical =
    isHistoricalBulkManageInvestmentRow(data) &&
    getHistoricalRowRangeLogic({
      fullRange,
      earliestStart,
      latestEnd,
      historicalAllocation: data.allocation,
      finalReturnDate,
    });
  const barFillType = zeroAllocation
    ? 'diagonal striped'
    : frequency && ['YEARLY', 'QUARTERLY'].includes(frequency)
      ? 'striped'
      : 'solid';
  return (
    <div className="flex flex-col relative h-full w-full justify-center gap-[2px]">
      {overlap.percentageWidth > 0 ? (
        <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
      ) : null}
      <div className="relative" style={{ height: `${ANALYSIS_BAR_HEIGHT}px` }}>
        <RangeBar
          height={ANALYSIS_BAR_HEIGHT}
          widthPercentage={investment.percentageWidth}
          leftMarginPercentage={investment.percentageStart}
          fillType={barFillType}
          color={investmentColor}
        >
          {proxy.percentageWidth > 0 && (
            <RangeBar
              height={ANALYSIS_BAR_HEIGHT}
              widthPercentage={proxy.percentageWidth}
              leftMarginPercentage={proxy.percentageStart}
              color={proxyColor}
              fillType={barFillType}
            />
          )}
          {extrapolation.percentageWidth > 0 && (
            <RangeBar
              height={ANALYSIS_BAR_HEIGHT}
              widthPercentage={extrapolation.percentageWidth}
              leftMarginPercentage={extrapolation.percentageStart}
              color={extrapolationColor}
              fillType={barFillType}
            />
          )}
        </RangeBar>
        {shouldShowHypotheticalReturnExtension(data) &&
          historical &&
          historical.hasHistorical &&
          historical.hypotheticalReturnExtension && (
            <RangeBar
              height={ANALYSIS_BAR_HEIGHT}
              widthPercentage={historical.hypotheticalReturnExtension.percentageWidth}
              leftMarginPercentage={historical.hypotheticalReturnExtension.percentageStart}
              color={hypotheticalReturnExtensionColor}
              fillType="diagonal striped"
            />
          )}
      </div>
      {historical && historical.hasHistorical && (
        <div className="relative" style={{ height: `${LIQUIDATION_BAR_HEIGHT}px` }}>
          {historical.rowRanges.map((range, index) => (
            <RangeBarWithGap
              key={`${data.fullPath}-historical-${range.percentageStart}`}
              height={HISTORICAL_BAR_HEIGHT}
              hasGap={index > 0}
              widthPercentage={range.percentageWidth}
              leftMarginPercentage={range.percentageStart}
              color={historicalColor}
              fillType={barFillType}
            />
          ))}
          {historical.isFinalAllocationLiquidation && (
            <RangeBar
              height={LIQUIDATION_BAR_HEIGHT}
              widthPercentage={0}
              leftMarginPercentage={
                (historical.rowRanges[historical.rowRanges.length - 1]?.percentageStart ?? 0) +
                (historical.rowRanges[historical.rowRanges.length - 1]?.percentageWidth ?? 0)
              }
              color={historicalColor}
              fillType={barFillType}
            />
          )}
        </div>
      )}
      <FillParentTooltip variant="overlap" maxWidth={400} content={<ProxiedInvestmentTooltipContent data={data} />} />
    </div>
  );
};
