import type { ReactNode } from 'react';
import React, { PureComponent } from 'react';
import { noop, find } from 'lodash';
import { Button } from '../button/Button';
import { TriggerableMenu } from '../menu/TriggerableMenu';
import ButtonDropdownItem from './ButtonDropdownItem';
import type { OptionType } from '../menu';

export interface ButtonDropdownOption<T> {
  value: T;
  label?: string;
  icon?: string;
  default?: boolean;
}

export interface ButtonDropdownProps<T> {
  buttonClassName?: string;
  menuClassName?: string;
  className?: string;
  options: ButtonDropdownOption<T>[];
  value?: T | null;
  onChange?: (option: ButtonDropdownOption<T>) => void;
  initialValue?: T;
  disabled?: boolean;
  children?: ReactNode;
}

export interface ButtonDropdownState<T> {
  // TODO: (VENN-20577 / TYPES) Type is slightly wrong, it should also be or undefined since it is undefined if
  // options is zero length.
  internalValue: T;
}

function getSelectedOption<T>(options: ButtonDropdownOption<T>[], value: T): ButtonDropdownOption<T> {
  const controlledValue = find(options, { value }) as ButtonDropdownOption<T> | undefined;
  if (controlledValue) return controlledValue;

  // TODO: (VENN-20577 / TYPES) This cast is slightly wrong in that options[0]! could still be undefined if options is 0 length.
  const initialValue = find(options, { default: true }) || options[0]!;
  return initialValue;
}

export class ButtonDropdown<T> extends PureComponent<ButtonDropdownProps<T>, ButtonDropdownState<T>> {
  static defaultProps = {
    value: null,
    onChange: noop,
    // TODO: (VENN-20577 / TYPES) Kinda weird that initialValue starts as string even if value isn't a string
    initialValue: '',
  };

  constructor(props: ButtonDropdownProps<T>) {
    super(props);
    this.state = {
      internalValue: getSelectedOption(props.options, props.initialValue!)?.value,
    };
  }

  updateSelected = (internalValue: T) => {
    this.setState({
      internalValue,
    });
  };

  onSelect = (option: OptionType<T>) => {
    const { onChange = noop } = this.props;
    // TODO: (VENN-20577 / TYPES) remove cast after other TODOs are resolved
    this.updateSelected(option.value as T);
    onChange(option);
  };

  render() {
    const { buttonClassName, menuClassName, className, disabled, children } = this.props;

    const options = this.props.options
      ? this.props.options.map((option) => ({
          ...option,
          children: <ButtonDropdownItem label={option.label} icon={option.icon} />,
        }))
      : [];
    const value = this.props.value || this.state.internalValue;

    const selectedOption = getSelectedOption(options, value);

    return (
      <TriggerableMenu
        initialValue={selectedOption?.value}
        options={options}
        disabled={disabled}
        onSelect={this.onSelect}
        className={className}
        innerClassName={menuClassName}
      >
        {children || (
          <Button className={buttonClassName} disabled={disabled}>
            <ButtonDropdownItem label={selectedOption?.label} icon={selectedOption?.icon} />
          </Button>
        )}
      </TriggerableMenu>
    );
  }
}

export default ButtonDropdown;
