import {
  type ColorIndex,
  type SubjectWithOptionalFee,
  subjectsAreEqualInclFees,
  allSubjectsSelector,
  subjectColorIndexState,
  convertToSubjectWithFee,
} from 'venn-state';
import { differenceWith, uniqWith } from 'lodash';
import { useRecoilCallback, useRecoilValueLoadable, waitForNone } from 'recoil';
import { useEffect, useState } from 'react';
import { getAnalyzedSubjectFromRequestSubject } from 'venn-utils';

function* makeColorIndexIterator(): Generator<ColorIndex> {
  let index = 0 as ColorIndex;
  while (true) {
    yield index++ as ColorIndex;
  }
}

const getNextColorIndex = (colorIndexIterator: Generator<ColorIndex>, usedColors: Set<ColorIndex>) => {
  let nextColor = colorIndexIterator.next();
  while (!nextColor.done) {
    if (!usedColors.has(nextColor.value)) {
      return nextColor.value;
    }
    nextColor = colorIndexIterator.next();
  }
  throw new Error('Ran out of colors to assign to subjects');
};

export const useSyncColors = () => {
  const [prevAllSubjects, setPrevAllSubjects] = useState<SubjectWithOptionalFee[] | undefined>();
  const allSubjectsLoadable = useRecoilValueLoadable(allSubjectsSelector);

  const syncColors = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (allSubjects: SubjectWithOptionalFee[]) => {
        const subjects = uniqWith(allSubjects, subjectsAreEqualInclFees);
        const prevSubjects = uniqWith(prevAllSubjects ?? [], subjectsAreEqualInclFees);

        const deletedSubjects = differenceWith(prevSubjects, subjects, subjectsAreEqualInclFees);
        deletedSubjects.forEach((subject) => reset(subjectColorIndexState(convertToSubjectWithFee(subject))));

        const colors = await snapshot.getPromise(
          waitForNone(subjects.map((subject) => subjectColorIndexState(convertToSubjectWithFee(subject)))),
        );
        const colorIndexIterator = makeColorIndexIterator();
        const usedColors = new Set(colors.filter((c) => c.state === 'hasValue').map((c) => c.getValue()));

        subjects.forEach((subject, idx) => {
          if (colors[idx]!.state !== 'hasValue') {
            set(
              subjectColorIndexState(convertToSubjectWithFee(subject)),
              getNextColorIndex(colorIndexIterator, usedColors),
            );
          }
        });
      },
    [prevAllSubjects],
  );

  useEffect(() => {
    if (allSubjectsLoadable.state !== 'hasValue') {
      return;
    }
    const allSubjects = allSubjectsLoadable.getValue().map(getAnalyzedSubjectFromRequestSubject);
    syncColors(allSubjects);
    setPrevAllSubjects(allSubjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- sync colors when all subjects changes
  }, [allSubjectsLoadable]);
};
