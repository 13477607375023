import { isNil } from 'lodash';
import React, { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { isAllocatorOpenState, openPrivateAllocatorPortfolio } from 'venn-state';
import { GetColor } from 'venn-ui-kit';
import { useHasFF, withSuspense } from 'venn-utils';
import { Constants } from '../Layout';
import { StudioAllocationPanelShimmerBlock, StudioAllocatorSubject, StudioAllocatorWindow } from './components';
import { PrivatesAllocatorPanel } from './PrivatesAllocatorPanel';
import { useUnsavedPrivatesAllocatorChangesModal } from './useUnsavedPrivatesAllocatorChangesModal';
import { ConfirmUnsavedChangesModal } from '../../unsaved-changes/ConfirmUnsavedChangesModal';

const StudioPrivatesAllocationPanel = React.memo(() => {
  const hasApInRl = useHasFF('ap_in_rl_ff');

  const [openPortfolio, setOpenPortfolio] = useRecoilState(openPrivateAllocatorPortfolio);
  const setIsAllocatorOpen = useSetRecoilState(isAllocatorOpenState);

  const onClosePanel = useCallback(() => {
    setOpenPortfolio(undefined);
    setIsAllocatorOpen(false);
  }, [setIsAllocatorOpen, setOpenPortfolio]);

  const {
    isUnsavedChangesModalOpen,
    closePanelOrTriggerUnsavedChangesModal,
    discardChangesAndClosePanel,
    closeUnsavedChangesModal,
  } = useUnsavedPrivatesAllocatorChangesModal(onClosePanel);

  const allocatorWidth = 1 + Constants.ALLOCATION_PANEL_MAIN_WIDTH;

  return (
    <StudioAllocatorWindow hasApInRl={hasApInRl}>
      <StudioAllocatorSubject width={allocatorWidth} isPrivate onClose={closePanelOrTriggerUnsavedChangesModal} />
      {!isNil(openPortfolio) && (
        <AllocationPanelWrapper data-testid="studio-privates-allocator-wrapper">
          <PrivatesAllocatorPanel />
        </AllocationPanelWrapper>
      )}
      {isUnsavedChangesModalOpen && (
        <ConfirmUnsavedChangesModal
          onProceed={discardChangesAndClosePanel}
          onReject={closeUnsavedChangesModal}
          ignoreUnsavedViewChanges
        />
      )}
    </StudioAllocatorWindow>
  );
});
StudioPrivatesAllocationPanel.displayName = 'StudioPrivatesAllocationPanel';

export default withSuspense(<StudioAllocationPanelShimmerBlock isPrivate />, StudioPrivatesAllocationPanel);

const AllocationPanelWrapper = styled.div`
  grid-area: allocator-panel;
  background-color: ${GetColor.White};
  overflow-x: hidden;
`;
