import React from 'react';
import type { Analysis, FactorLensWithReturns, Message, FrequencyEnum } from 'venn-api';
import styled from 'styled-components';
import type { DateRange } from 'venn-ui-kit';
import { GetColor, Headline2 } from 'venn-ui-kit';
import type { ComparisonSubject } from 'venn-utils';
import { analyticsService } from 'venn-utils';
import type { ComparisonAnalysisBlock } from '../analysisBlocks';
import { CompareRegularBlockWatermark, FailedAnalysisInfo, formatDateRangeForTracking } from 'venn-components';
import SubjectError from './SubjectError';

export interface AnalysisBlocksProps {
  subjects: ComparisonSubject[];
  analyses: Analysis[];
  blocks: readonly ComparisonAnalysisBlock[];
  factorLens: FactorLensWithReturns;
  subjectErrors?: (Message | undefined)[];
  setHovered: (idx: number, hovered: boolean, metricIdx?: number) => void;
  hoveredMetricIdx?: number;
  setDateRange: (dateRange: DateRange) => void;
  relative: boolean;
  isLoading: boolean;
  trackingId: number;
  dateRange: DateRange;
  hasBenchmark: boolean;
  maxFrequency: FrequencyEnum;
  isPrinting: boolean;
  /**
   * Callback to remove items from the comparison page by specifying array index in {@link subjects}
   */
  removeComparisonSubject: (idx: number) => void;
  fetchingInBackground: boolean;
  onRemoveSubject: (idx: number) => void;
}

const AnalysisBlocks: React.FC<React.PropsWithChildren<AnalysisBlocksProps>> = ({
  analyses,
  subjects,
  blocks,
  setHovered,
  subjectErrors,
  relative,
  factorLens,
  setDateRange,
  isLoading,
  trackingId,
  dateRange,
  hasBenchmark,
  maxFrequency,
  isPrinting,
  fetchingInBackground,
  removeComparisonSubject,
}) => {
  // Handle subject errors
  if (subjectErrors?.some((e) => e && e.type === 'ERROR')) {
    return (
      <SubjectError
        subjectErrors={subjectErrors}
        subjects={subjects}
        setDateRange={setDateRange}
        relative={relative}
        trackingId={trackingId}
        dateRange={dateRange}
        hasBenchmark={hasBenchmark}
        maxFrequency={maxFrequency}
        removeComparisonSubject={removeComparisonSubject}
      />
    );
  }

  return (
    <div>
      {blocks.map((block) => {
        if (isPrinting && block.hideInPrint) {
          return null;
        }
        const analysis = analyses && analyses.find((a) => a.analysisType === block.type);
        return (
          <AnalysisWrapper className="qa-analysis-wrapper" key={block.key}>
            <BlockTitle>{block.getTitle(relative)}</BlockTitle>
            {analysis?.message ? (
              <FailedAnalysisInfo
                comparisonSubjects={subjects}
                relativeToBenchmark={relative}
                error={{
                  message: analysis.message.text,
                  code: analysis.message.code,
                }}
                regressionName={block.type}
                onResetAnalysisPeriod={() => setDateRange({ from: undefined, to: undefined })}
                trackingId={trackingId}
                blockNames={[block.type]}
                blockTitles={[block.getTitle(relative)]}
                updateAnalysisStatusForTracking={(actionAnalysisStatuses) => {
                  const { succeeded } = actionAnalysisStatuses[0]!;
                  if (!succeeded) {
                    analyticsService.analysisFailed({
                      analysisName: 'Multi-subject comparison',
                      blocks: [block.getTitle(relative)],
                      dateRange: formatDateRangeForTracking(dateRange.from, dateRange.to),
                      frequency: maxFrequency,
                      hasBenchmark,
                      hasProxy: false,
                      objectType: 'multi-subject',
                      relativeToBenchmark: relative,
                    });
                  }
                }}
              />
            ) : (
              React.createElement(block.component, {
                subjects,
                analysis,
                extractor: block.extractor,
                label: block.label,
                factorLens,
                setHovered,
                relative,
                isLoading,
                isPrinting,
                fetchingInBackground,
                cellRenderer: block.cellRenderer,
              })
            )}
            <GreyBar />
            {!analysis?.message && <CompareRegularBlockWatermark type={block.type} />}
          </AnalysisWrapper>
        );
      })}
    </div>
  );
};

const BlockTitle = styled(Headline2)`
  @media print {
    margin-top: 8px;
    font-size: 18px;
  }
`;

const AnalysisWrapper = styled.div`
  // Position relative for watermarks and other absolute/relative children
  position: relative;

  margin-bottom: 40px;
  @media print {
    margin-bottom: 20px;
  }
`;

const GreyBar = styled.div`
  background-color: ${GetColor.PaleGrey};
  height: 20px;
  margin: 20px 0;
  width: 100%;
  @media print {
    display: none;
  }
`;

export default AnalysisBlocks;
