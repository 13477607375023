import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { ShowPopoverFuncType } from '../../../factor-chart/factor-popover/FactorPopover';
import FactorPopover from '../../../factor-chart/factor-popover/FactorPopover';
import { parseFactorTrendData } from './utils';
import type { OnCellClickType } from '../../../heat-map/MapWrapper';
import MapWrapper from '../../../heat-map/MapWrapper';
import type { Analysis, FactorLensWithReturns, DataExportEvent, AnalysisTypeEnum } from 'venn-api';
import { TooltipLink } from 'venn-ui-kit';
import type { FactorTrendTitle, factorAPIResponseKey } from './types';
import type { HeatMapTypes } from '../../../heat-map';
import { HeatMapUtils } from '../../../heat-map';
import FactorTrendLineChart from './components/FactorTrendLineChart';
import { RollingPeriodPicker } from '../../../rolling-period-picker/RollingPeriodPicker';
import ChartModeSwitch from './ChartModeSwitch';
import { FactorAnalysisContext } from '../../../contexts';

const { convertDataToExcel } = HeatMapUtils;

interface FactorTrendChartProps {
  analysis: Analysis;
  activeFactorLens?: FactorLensWithReturns;
  type: 'risk' | 'return' | 'exposure';
  analysisKey: factorAPIResponseKey;
  multiplier: number;
  width: number;
  factorTrendTitle: FactorTrendTitle;
  timestamps: number[];
  residualKey?: string;
  hasTotalRow?: boolean;
  hasRiskFreeRate?: boolean;
  dataExportEvent: DataExportEvent;
  isPercentage: boolean;
  regressionYears: number | undefined;
  regressionYearsOptions: { [key: string]: boolean } | undefined;
  chartReloading: AnalysisTypeEnum[];
  block: AnalysisTypeEnum;
  onUpdateRollingPeriod?: (rollingYears: number | undefined, trendBlock: AnalysisTypeEnum) => void;
  onResetAnalysisPeriod?: () => void;
  topLegendFormatter?: (value?: number | null) => string;
  chartMode: string;
  setChartMode: (block: AnalysisTypeEnum, mode: string) => void;
  tooltipLink: string;
}

const FactorTrendChartLayout: FC<React.PropsWithChildren<FactorTrendChartProps>> = (props) => {
  if (!props.analysis) {
    return null;
  }
  return <FactorTrendChart {...props} />;
};

const FactorTrendChart: FC<React.PropsWithChildren<FactorTrendChartProps>> = ({
  activeFactorLens,
  analysis,
  width,
  type,
  factorTrendTitle,
  analysisKey,
  timestamps,
  residualKey,
  hasTotalRow,
  hasRiskFreeRate,
  dataExportEvent,
  multiplier,
  isPercentage,
  chartReloading,
  block,
  topLegendFormatter,
  onUpdateRollingPeriod,
  regressionYearsOptions,
  regressionYears,
  onResetAnalysisPeriod,
  chartMode,
  setChartMode,
  tooltipLink,
}) => {
  const { Colors } = useContext(ThemeContext);

  const {
    analysisSubject,
    relative,
    categoryActive,
    portfolioHasMultipleFunds,
    downloadMetaData,
    defaultFactorsName,
    factorsDescriptions,
  } = useContext(FactorAnalysisContext);

  const { series, analyzedFactors } = useMemo(
    () =>
      parseFactorTrendData(
        analysisKey,
        analysis,
        activeFactorLens,
        type,
        analysisSubject,
        multiplier,
        Colors,
        {},
        residualKey,
        hasTotalRow,
        hasRiskFreeRate,
        analysisSubject.hasBenchmark,
        analysisSubject.activeBenchmarkName,
      ),
    [
      analysisKey,
      analysis,
      activeFactorLens,
      type,
      analysisSubject,
      multiplier,
      residualKey,
      hasTotalRow,
      hasRiskFreeRate,
      Colors,
    ],
  );

  const defaultFactors = activeFactorLens?.factors || [];
  const filterCount = defaultFactors.length - analyzedFactors;

  const sharedProps = {
    analysisSubject,
    width,
    relative,
    activeFactorLens,
    benchmarkName: analysisSubject.activeBenchmarkName,
    timestamps,
    seriesDataSize: timestamps.length,
    factorsDescriptions,
    filterCount,
    errorMessage: analysis.message,
    onUpdateRollingPeriod,
    onResetAnalysisPeriod,
    downloadMetaData,
  };
  const hasBaseline = analysisSubject && analysisSubject.hasSecondarySubject;
  const exportData = convertDataToExcel(
    series,
    timestamps,
    factorTrendTitle.title,
    factorTrendTitle.activeTitle,
    relative,
    hasBaseline,
    isPercentage,
  );

  const handleSetChartMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartMode(block, event.target.value);
  };

  const ActionsComponent = (
    <StyledContainer>
      <ActionsWrapper>
        <ChartModeSwitch block={block} onChange={handleSetChartMode} chartMode={chartMode} />
        <RollingPeriodPicker
          selectedPeriod={regressionYears}
          periods={regressionYearsOptions}
          block={block}
          onUpdateRollingPeriod={onUpdateRollingPeriod}
        />
      </ActionsWrapper>
    </StyledContainer>
  );

  const factorTitle = relative ? factorTrendTitle.activeTitle : factorTrendTitle.title;

  if (chartMode === 'lineChart') {
    return (
      <FactorTrendLineChart
        title={factorTitle}
        subtitle={
          <>
            {factorTrendTitle.subtitle}
            <TooltipLink
              positions={{
                top: -60,
                left: -90,
              }}
              href={tooltipLink}
              top
            />
          </>
        }
        data={series}
        timestamps={timestamps}
        exportData={exportData}
        downloadMetaData={downloadMetaData}
        block={block}
        dataExportEvent={dataExportEvent}
        onUpdateRollingPeriod={onUpdateRollingPeriod}
        chartReloading={chartReloading}
        errorMessage={sharedProps.errorMessage}
        onResetAnalysisPeriod={onResetAnalysisPeriod}
        ActionsComponent={ActionsComponent}
      />
    );
  }

  return (
    <div>
      <FactorPopover
        relative={relative}
        categoryActive={categoryActive}
        usePercentages={isPercentage}
        analysisSubject={analysisSubject}
        activeFactorLens={defaultFactorsName}
      >
        {(showPopover) => (
          <MapWrapper
            {...sharedProps}
            metricType={type === 'return' || type === 'risk' ? type : undefined}
            title={factorTitle}
            subtitle={
              <>
                {factorTrendTitle.subtitle}
                <TooltipLink
                  positions={{
                    top: -60,
                    left: -90,
                  }}
                  href={tooltipLink}
                  top
                />
              </>
            }
            series={series}
            block={block}
            regressionYearsOptions={regressionYearsOptions}
            chartReloading={chartReloading}
            isCellClickable={portfolioHasMultipleFunds}
            onCellClick={getCellClickHandler(
              showPopover,
              series,
              type,
              portfolioHasMultipleFunds,
              timestamps,
              regressionYears,
            )}
            dataExportEvent={dataExportEvent}
            isPercentage={isPercentage}
            exportData={exportData}
            topLegendFormatter={topLegendFormatter}
            regressionYears={regressionYears}
            ActionsComponent={ActionsComponent}
          />
        )}
      </FactorPopover>
    </div>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export default FactorTrendChartLayout;

const getCellClickHandler =
  (
    showPopover: ShowPopoverFuncType,
    series: HeatMapTypes.Root[],
    type: 'risk' | 'return' | 'exposure',
    isCellClickable: boolean,
    timestamps: number[],
    regressionYears: number | undefined,
  ): OnCellClickType =>
  (data, coords, cellPosition) => {
    if (!isCellClickable) {
      return;
    }

    showPopover({
      factorId: series[coords.y]!.factorId,
      factorName: series[coords.y]!.name,
      position: {
        x: cellPosition.x,
        y: cellPosition.y,
        tooltipRight: cellPosition.tooltipRight,
      },
      timestamp: timestamps[coords.x],
      titleData: {
        factorType: type,
        factorValue: data.value,
        regressionYears,
        factorSignificant: !data.nonSignificant,
      },
    });
  };
