import React, { useCallback, useContext, useMemo, useState } from 'react';
import type {
  TrackAnalysisProps,
  BasicTableProps,
  BasicTableColumn,
  ReturnsGridSubject,
  StyledTableType,
} from 'venn-components';
import {
  Toggle,
  ToggleOption,
  EmptyState,
  DownloadableContentBlock,
  TrackFailedAnalysis,
  TrackSuccessfulAnalysis,
  BasicTable,
  AnalysisViewContext,
  convertToAnnualReturns,
  getReturnsGridMapFromAnalysesResults,
} from 'venn-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu } from 'venn-ui-kit';
import styled from 'styled-components';
import type { ExcelCell } from 'venn-utils';
import {
  SpecialCssClasses,
  logMessageToSentry,
  getSecondaryDisplayLabel,
  DISABLE_ANALYSIS_EXPORT_MESSAGE,
} from 'venn-utils';
import type { SharedProps } from '../../types';
import getRestrictions from './restrictions';
import { getColumns } from './get-columns';
import compact from 'lodash/compact';

export interface ReturnsGridBlockProps extends SharedProps {
  trackingProps: TrackAnalysisProps;
}

const EMPTY_SHEET = [[{ value: '' }]];

const ReturnsGridBlock = ({ analyses, trackingProps, downloadMetaData }: ReturnsGridBlockProps) => {
  const { returnsGridFrequency, returnsGridSubject, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);

  const showQuarterly = useMemo(() => returnsGridFrequency === 'quarterly', [returnsGridFrequency]);
  const setShowQuarterly = useCallback(
    (quarterly: boolean) => onUpdateAnalysisViewParam({ returnsGridFrequency: quarterly ? 'quarterly' : 'monthly' }),
    [onUpdateAnalysisViewParam],
  );

  const selectedSubject = useMemo(() => returnsGridSubject, [returnsGridSubject]);
  const setSelectedSubject = useCallback(
    (updatedSubject: ReturnsGridSubject) => onUpdateAnalysisViewParam({ returnsGridSubject: updatedSubject }),
    [onUpdateAnalysisViewParam],
  );

  const [excelData, setExcelData] = useState<ExcelCell[][]>(EMPTY_SHEET);
  const options = useMemo(
    (): DropMenuItem<ReturnsGridSubject>[] =>
      analyses
        ? compact([
            { label: analyses.subject.name, value: 'subject' },
            analyses.results.returnsGrid.benchmark
              ? { label: analyses.subject.activeBenchmarkName ?? '', value: 'benchmark' }
              : null,
            analyses.subject.type === 'investment' && analyses.results.returnsGrid.category
              ? { label: 'Category', value: 'category' }
              : null,
            analyses.subject.type === 'portfolio' && analyses.results.returnsGrid.category
              ? { label: getSecondaryDisplayLabel(analyses.subject, undefined, 'Portfolio'), value: 'category' }
              : null,
          ])
        : [],
    [analyses],
  );
  const selectedSubjectEntity = options.find((i) => i.value === selectedSubject) || options[0]!;
  const restrictions = getRestrictions(analyses, selectedSubjectEntity.value, showQuarterly);
  const onPeriodChange = useCallback(
    (value: string) => {
      setShowQuarterly(value === 'quarterly');
    },
    [setShowQuarterly],
  );
  const onSubjectChange = useCallback(
    (item: DropMenuItem<ReturnsGridSubject>) => {
      setSelectedSubject(item.value);
    },
    [setSelectedSubject],
  );

  const period = restrictions.showQuarterly ? 'QUARTERLY' : 'MONTHLY';
  const periodData = useMemo(
    () =>
      analyses
        ? convertToAnnualReturns(getReturnsGridMapFromAnalysesResults(analyses, selectedSubjectEntity.value), period)
        : undefined,
    [analyses, selectedSubjectEntity.value, period],
  );
  const columns = useMemo(() => (periodData?.length ? getColumns(periodData) : []), [periodData]);
  const dataExportable = analyses?.resultsExportable?.returnsGrid?.[selectedSubjectEntity.value];
  const disableExportBtn = restrictions.disableExport || !periodData || !periodData.length;

  if (!analyses || !periodData) {
    logMessageToSentry('Failed to render returns grids');
    return null;
  }
  return (
    <DownloadableContentBlock
      header={analyses.relative ? 'Relative Calendar Returns' : 'Calendar Returns'}
      subHeader={`Returns for ${selectedSubjectEntity.label}`}
      separator
      downloadable={{
        disabled: disableExportBtn,
        tracking: {
          description: 'RETURNS_GRID',
          relativeToBenchmark: analyses.relative,
          subjectType: analyses.subject.type,
          subjectId: analyses.subject.id,
          userUploaded: false,
        },
        options: {
          fileName: `${selectedSubjectEntity.label} Returns`,
          disabledReason: disableExportBtn ? 'Data cannot be exported.' : undefined,
          metaData: downloadMetaData,
        },
        excel: dataExportable ? excelData : undefined,
        png: true,
        disableExcelExportMessage: DISABLE_ANALYSIS_EXPORT_MESSAGE,
      }}
      rightOptions={
        <OptionsWrapper className={SpecialCssClasses.NotDownloadable}>
          <StyledToggle
            value={restrictions.showQuarterly ? 'quarterly' : 'monthly'}
            onChange={onPeriodChange}
            disabled={restrictions.disableToggle}
          >
            <ToggleOption value="monthly">Monthly</ToggleOption>
            <ToggleOption value="quarterly">Quarterly</ToggleOption>
          </StyledToggle>
          <DropdownWrapper>
            <DropMenu items={options} selected={selectedSubjectEntity.value} onChange={onSubjectChange} />
          </DropdownWrapper>
        </OptionsWrapper>
      }
    >
      {periodData.length && !restrictions.error ? (
        <TrackSuccessfulAnalysis {...trackingProps}>
          <GridWrapper>
            <Table
              columns={columns}
              data={periodData}
              onExportDataChanged={setExcelData}
              rowHeight={26}
              highlightCell
            />
          </GridWrapper>
        </TrackSuccessfulAnalysis>
      ) : (
        <TrackFailedAnalysis {...trackingProps}>
          <EmptyState header="Unable to run analysis" noBorder>
            {restrictions.error || 'There are insufficient returns available to analyze.'}
          </EmptyState>
        </TrackFailedAnalysis>
      )}
    </DownloadableContentBlock>
  );
};

const Table: StyledTableType<unknown> = styled(<T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => (
  <BasicTable<T, K> {...props} />
))`
  thead {
    tr {
      height: 26px;
      th {
        padding: 0 15px 5px 0;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0;
      }
    }
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 20px;
  }
  @media print {
    display: none;
  }
`;

const StyledToggle = styled(Toggle)`
  margin: 0;
`;

const DropdownWrapper = styled.div`
  max-width: 200px;
`;

const GridWrapper = styled.div`
  overflow-x: auto;
`;

export default ReturnsGridBlock;
