import type { ColumnMapping, CurrencyEnum, FileMapping, FileUploadMetadata, Fund, Metadata } from 'venn-api';
import type { MapDataTableHeaderColumn } from '../../components/map-data';
import type { DropdownOption, DropdownValue } from '../../components/Dropdown';
import { Mixed } from '../../components/Dropdown';
import { DataUploaderMode, DO_NOT_MAP } from '../../types';
import { getTypeId } from '../../fetchers';

const DEFAULT_DELETE_TYPE = 1;

export const updateColumnMappingsByGlobalChange: MappingUpdater = (
  columns: ColumnMapping[],
  values: DropdownValue[],
): ColumnMapping[] => {
  const [metricId, isPercent, currency, appendId] = values;
  const newColumns = columns.map((c) => ({
    ...c,
    isPercent: isPercent !== Mixed ? Boolean(isPercent) : c.isPercent,
    // TODO: (VENN-20577 / TYPES) may be able to fix the casting by switching DropdownValue[] to a tuple, or using generics
    metricId: metricId !== Mixed ? (metricId as number) : c.metricId,
    currency: currency !== Mixed ? (currency as CurrencyEnum) : c.currency,
    appendId: appendId && appendId !== Mixed ? (appendId as number) : c.appendId,
  }));
  return newColumns;
};

export const updateNavColumnMappingsByGlobalChange: MappingUpdater = (
  columns: ColumnMapping[],
  values: DropdownValue[],
): ColumnMapping[] => {
  const [, appendId] = values;
  const newColumns = columns.map((c) => ({
    ...c,
    // TODO: (VENN-20577 / TYPES) may be able to fix the casting by switching DropdownValue[] to a tuple
    appendId: appendId && appendId !== Mixed ? (appendId as number) : c.appendId,
  }));
  return newColumns;
};

// `values` should be an array with the following values in this order:
// [appendId: string, currency: string, typeId: number, fund: Fund | string (optional)]
// if the last argument is a Fund, it means the column maps to an existing fund
// if it is a string, the columns maps to a new fund whose name is the given string
// @ts-expect-error: TODO fix strictFunctionTypes
export const updateColumnMappingsByFundChange: MappingUpdater = (
  columns: ColumnMapping[],
  values: DropdownValue[],
  seriesId: string,
): ColumnMapping[] => {
  const [appendId, currency, typeId, fund] = values as [number, CurrencyEnum, number, Fund | string | undefined];

  const changedFundIndex = columns.findIndex((c) => c.seriesId === seriesId);
  const changedFund = columns[changedFundIndex]!;

  const fundId = typeof fund === 'object' ? fund.id : typeof fund === 'string' ? '' : changedFund.fundId;
  const fundName = typeof fund === 'object' ? fund.name : typeof fund === 'string' ? fund : changedFund.fundName;

  return [
    ...columns.slice(0, changedFundIndex),
    {
      ...changedFund,
      appendId: appendId ?? changedFund.appendId,
      currency: currency ?? changedFund.currency,
      typeId: typeId ?? changedFund.typeId,
      fundId,
      fundName,
    },
    ...columns.slice(changedFundIndex + 1),
  ];
};

interface UpdateColumnStateProps {
  mapping: FileMapping;
  columns: ColumnMapping[];
  values: DropdownValue[];
  seriesId?: string;
}

type MappingUpdater = (
  columns: ColumnMapping[],
  changes: DropdownValue[],
  seriesId?: string,
  mode?: DataUploaderMode,
) => ColumnMapping[];

export const updateMappingColumnState = (
  { mapping, columns, values, seriesId }: UpdateColumnStateProps,
  updater: MappingUpdater,
  mode?: DataUploaderMode,
): FileMapping => ({
  ...mapping,
  columns: [...mapping.columns.filter((c) => !columns.includes(c)), ...updater(columns, values, seriesId, mode)],
});

export const getMappingHeaderColumns = (
  metadata: FileUploadMetadata,
  {
    isNew,
    mode,
    readonly,
    appendOnly,
    isPrice,
  }: { isNew?: boolean; readonly?: boolean; mode?: DataUploaderMode; appendOnly?: boolean; isPrice?: boolean },
): MapDataTableHeaderColumn[] => {
  let columns: MapDataTableHeaderColumn[];
  switch (mode) {
    case DataUploaderMode.Returns: {
      const returnsOptionsReadonly = readonly || appendOnly;
      columns = [
        { options: metadata.metricType.map(toOptionNumber), readonly: returnsOptionsReadonly },
        {
          options: [
            { label: 'Percentage', value: true },
            { label: 'Decimal', value: false },
          ],
          readonly: returnsOptionsReadonly,
          mixedLabel: 'Mixed Format',
          hide: isPrice,
        },
        {
          options: metadata.currency.map(toOption),
          readonly: returnsOptionsReadonly,
          mixedLabel: 'Mixed Currency',
        },
      ];
      break;
    }
    case DataUploaderMode.NewNavs: {
      columns = [
        {
          options: [{ label: 'NAVs', value: 'NAVs' }],
          readonly: readonly !== undefined ? readonly : true,
        },
      ];
      break;
    }
    case DataUploaderMode.Privates: {
      columns = [];
      break;
    }
    default: {
      columns = [];
    }
  }

  if (!isNew) {
    columns.push({
      options: metadata.appendType.map(toOptionNumber),
      readonly,
      mixedLabel: 'Mixed Overlap Strategy',
    });
  }

  return columns;
};

export const getGlobalValues = (columns: ColumnMapping[], mode: DataUploaderMode) => {
  const globalValues = columns.reduce(
    (memo, c) => {
      memo.metricId.add(c.metricId);
      memo.isPercent.add(c.isPercent);
      memo.currency.add(c.currency);
      if (!isColumnNew(c, mode)) {
        memo.appendId.add(c.appendId);
      }
      return memo;
    },
    {
      metricId: new Set(),
      isPercent: new Set(),
      currency: new Set(),
      appendId: new Set(),
    },
  );

  return {
    globalMetricId: globalValues.metricId.size === 1 ? Array.from(globalValues.metricId)[0] : Mixed,
    globalIsPercent: globalValues.isPercent.size === 1 ? Array.from(globalValues.isPercent)[0] : Mixed,
    globalCurrency: globalValues.currency.size === 1 ? Array.from(globalValues.currency)[0] : Mixed,
    globalAppendId: globalValues.appendId.size === 1 ? Array.from(globalValues.appendId)[0] : Mixed,
  };
};

export const toOptionNumber = (item: Metadata<number>): DropdownOption => ({
  label: item.name,
  value: item.id,
});

export const toOption = (item: Metadata<string>): DropdownOption => ({
  displayLabel: item.id,
  label: item.name,
  value: item.id,
});

export const getDeletedTypeId = (metadata?: FileUploadMetadata) => {
  return getTypeId(metadata, 'dataType', DO_NOT_MAP) || DEFAULT_DELETE_TYPE;
};

export const isColumnDeleted = (columnMapping: ColumnMapping, metadata?: FileUploadMetadata) => {
  const { typeId } = columnMapping;
  const deletedTypeId = getDeletedTypeId(metadata);
  return Number(typeId) === deletedTypeId;
};

const isColumnNew = (columnMapping: ColumnMapping, mode: DataUploaderMode) => {
  const { fundId, portfolioNodeId } = columnMapping;
  const isReturns = mode === DataUploaderMode.Returns;
  return isReturns ? !fundId : !portfolioNodeId && !fundId;
};
