import type { SeriesOptionsType } from 'highcharts';
import { compact } from 'lodash';
import isNil from 'lodash/isNil';
import { useRecoilValue } from 'recoil';
import type { GrowthSimulationPublicPrivateResponse } from 'venn-api';
import {
  blockLimitedRequestSubjects,
  blockPrivateAssetAllocation,
  blockPrivateAssetAllocationVisibility,
  blockYAxisUnitFormat,
  UnitFormat,
} from 'venn-state';
import { useColorTheme } from 'venn-ui-kit';
import { assertNotNil, getAnalyzedSubjectFromRequestSubject } from 'venn-utils';
import { formatExportableSubjectWithOptionalFee } from '../../../../../legend';
import { useBlockId } from '../../../../contexts/BlockIdContext';
import { useConfidenceLevels } from '../../../../logic/useConfidenceLevels';
import { useSubjectColors } from '../../../../logic/useSubjectColors';
import type { Serie } from '../../../charts/types';
import {
  confidenceKeyFromPercentage,
  convertToTimeseries,
  FUNDING_FAILURE_POINT_PREFIX,
  seriesNameFromPercentage,
} from '../utils';

export const usePublicPrivateGrowthNAVBreakdownChartData = (data: GrowthSimulationPublicPrivateResponse[]) => {
  const blockId = useBlockId();
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const yAxisFormat = useRecoilValue(blockYAxisUnitFormat(blockId));
  const confidenceLevel = assertNotNil(useConfidenceLevels()?.[0]);
  const targetAllocation = useRecoilValue(blockPrivateAssetAllocation(blockId));
  const isTargetAllocationVisible = useRecoilValue(blockPrivateAssetAllocationVisibility(blockId));
  const colors = useColorTheme();

  const confidenceLevelKey = confidenceKeyFromPercentage(confidenceLevel.level * 100);
  const subjectColors = useSubjectColors(subjects.map(getAnalyzedSubjectFromRequestSubject));

  const publicSubject = assertNotNil(subjects.filter((subject) => !subject.private)[0]);
  const privateSubject = assertNotNil(subjects.filter((subject) => subject.private)[0]);

  // It is guaranteed here that there are exactly two subjects: one public, one private, and the public comes first
  // The response format is a bit weird, private NAV is always attached to all public subjects, but for the private subject itself
  // the endpoint returns null. The simplest thing is to just read the data from the first index.
  const publicData = convertToTimeseries(
    (yAxisFormat === UnitFormat.PERCENT ? data[0]!.publicNavPercentage : data[0]!.publicNav)?.[confidenceLevelKey],
    1.0,
  );
  const privateData = convertToTimeseries(
    (yAxisFormat === UnitFormat.PERCENT ? data[0]!.privateNavPercentage : data[0]!.privateNav)?.[confidenceLevelKey],
    1.0,
  );

  const targetAllocationLine = isTargetAllocationVisible
    ? convertToTimeseries(
        data[0]!.publicAndPrivateNav?.[confidenceLevelKey],
        targetAllocation,
        yAxisFormat === UnitFormat.PERCENT ? 1.0 : undefined,
      )
    : null;

  const series = compact(
    subjects.map((subject, index) => {
      const subjectData = subject.private ? privateData : publicData;
      if (isNil(subjectData)) {
        return null;
      }
      const data: Serie = {
        name: `${formatExportableSubjectWithOptionalFee(subject)} NAV`,
        data: subjectData,
        color: subjectColors[index],
        type: 'column',
      };

      return data;
    }),
  ).concat(
    isNil(targetAllocationLine)
      ? []
      : [
          {
            name: 'Target Private Allocation' as const,
            data: targetAllocationLine,
            color: colors.colors.Black,
            type: 'line',
          },
        ],
  );

  const fundingFailure = data[0]?.fundingFailures?.[confidenceLevelKey];
  const mixedNav = data[0]?.publicAndPrivateNav?.[confidenceLevelKey];
  const lastSuccessfulData = mixedNav?.[mixedNav.length - 1];

  const fundingFailureSeries: SeriesOptionsType[] = !isNil(fundingFailure)
    ? [
        {
          type: 'column',
          showInLegend: false,
          color: colors.colors.Error,
          hideFromSharedTooltip: true,
          data: [
            // This fake datapoint with value of 0 is needed to fix resizing issues in RL
            {
              x: lastSuccessfulData?.[0],
              y: 0,
            },
            {
              id: `${FUNDING_FAILURE_POINT_PREFIX}_${seriesNameFromPercentage(confidenceLevel.level)}`,
              x: fundingFailure.date,
              y: yAxisFormat === UnitFormat.PERCENT ? 1 : lastSuccessfulData?.[1],
              metadata: {
                fundingFailure,
                confidenceLevel,
                publicSubject,
                privateSubject,
              },
            },
          ],
        },
      ]
    : [];

  return {
    series,
    fundingFailureSeries,
  };
};
