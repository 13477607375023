import { cloneDeep, isEmpty, isEqual, isNil, merge, sortBy, uniq } from 'lodash';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import sum from 'lodash/sum';
import type {
  ColumnErrors,
  ColumnMapping,
  FileUploadMetadata,
  Fund,
  Portfolio,
  PortfolioParseResult,
  SubjectRangeAnalysis,
} from 'venn-api';
import { assertNotNil } from '../../../../../../venn-utils/src';
import { DataUploaderMode, DO_NOT_MAP_ID, type RowData } from '../../types';
import type {
  HistoricalFundRange,
  HistoricalFundRangeAnalysis,
} from '../components/specialized/MultiHistoricalPortfolioContext';
import { isColumnDeleted } from '../mapping/helpers';
import type { DefaultPortfolioParseResultCategory } from '../components/common/MultiPortfolioReviewContext';

export interface ErrorViewModel {
  seriesId: string;
  // note that if it's a missing value, rowIndex is negative
  // and could change as the number of missing values changes
  rowIndex: number;
  date: string;
  value: string;
  isValid: boolean;
  errors: string[];
}

const areErrorsEqual = (err1: ErrorViewModel, err2: ErrorViewModel): boolean => {
  return (
    err1.seriesId === err2.seriesId &&
    // only use rowIndex to check equality if it's a real index
    (err1.rowIndex < 0 ? err1.date === err2.date : err1.rowIndex === err2.rowIndex)
  );
};

export function validateCells(newErrors: ErrorViewModel[], stateErrors: ErrorViewModel[]): ErrorViewModel[] {
  // update validity of stateErrors using the new errors
  const prevErrors = stateErrors.map((stateError) => {
    const updatedError = newErrors.find((newError) => areErrorsEqual(newError, stateError));
    if (updatedError) {
      // the error type could have changed so update it
      return {
        ...stateError,
        errors: updatedError.errors,
        isValid: false,
      };
    }
    // If we can't find a stateError in the newErrors array, that means it was corrected
    return {
      ...stateError,
      isValid: true,
    };
  });

  // there might be new errors that weren't there before so add them to the previous list of errors
  const actualNewErrors = newErrors.filter(
    (error) => !stateErrors.find((stateError) => areErrorsEqual(stateError, error)),
  );
  // maintain the order of the previous list of errors by simply appending the new errors
  return prevErrors.concat(actualNewErrors);
}

export function convertColumnErrorsToViewModels(columns: ColumnErrors[]): ErrorViewModel[] {
  return columns.reduce<ErrorViewModel[]>(
    (m, column) =>
      m.concat(
        column.errors.reduce<ErrorViewModel[]>((memo, e) => {
          const cellErrors = compact(
            e.cells.map((c) =>
              c.errors
                ? {
                    seriesId: c.seriesId,
                    rowIndex: c.index,
                    date: c.date,
                    value: c.value,
                    errors: c.errors,
                    isValid: false,
                  }
                : null,
            ),
          );
          return memo.concat(cellErrors);
        }, []),
      ),
    [],
  );
}

export function updateErrorValue(errors: ErrorViewModel[], error: ErrorViewModel, value: string): ErrorViewModel[] {
  const errorIndex = errors.indexOf(error);
  return [
    ...errors.slice(0, errorIndex),
    {
      ...error,
      value,
    },
    ...errors.slice(errorIndex + 1),
  ];
}

export function countData(columns: ColumnMapping[], mode: DataUploaderMode, isNew: boolean) {
  let filteredData;
  if (mode === DataUploaderMode.Returns) {
    filteredData = isNew ? columns.filter((c) => !c.fundId) : columns.filter((c) => !!c.fundId);
  } else {
    filteredData = isNew
      ? columns.filter((c) => !!c.fundId && c.typeId !== DO_NOT_MAP_ID)
      : columns.filter((c) => !c.fundId || c.typeId === DO_NOT_MAP_ID);
  }
  return filteredData.length;
}

/**
 * Return whether there are uncorrected errors for the given seriesId in the given list of errors.
 * @param errors if not provided, returns false
 * @param seriesId if not provided, all errors will be considered
 */
export function hasUncorrectedErrors(errors?: ErrorViewModel[], seriesId?: string): boolean {
  if (!errors) return false;
  if (!seriesId) return errors.some((e) => !e.isValid);
  return errors.some((e) => !e.isValid && e.seriesId === seriesId);
}

export const getColumnsToUpload = (columns: ColumnMapping[], metadata: FileUploadMetadata, errors: ErrorViewModel[]) =>
  columns.filter(
    (c) => c.newDataCount !== 0 && !isColumnDeleted(c, metadata) && !hasUncorrectedErrors(errors, c.seriesId),
  );

type SerializedPath = {
  tag: 'serialized';
  value: string;
};

// Safe to use because path is a number array
const PATH_SEPARATOR = ',';

export const serializePath = (path: number[]): SerializedPath => {
  return {
    tag: 'serialized',
    value: path.join(PATH_SEPARATOR),
  };
};

export const deserializePath = (value: string): number[] => {
  return value.split(PATH_SEPARATOR).map((index) => parseInt(index, 10));
};

export const isZeroAllocationFund = (node: Portfolio): boolean => {
  assertNotNil(node.fund, 'should only be called for fund nodes');
  if (node.historical) {
    const allocations = assertNotNil(
      node.closingAllocationsTs,
      'historical fund node has no closing allocations timeseries',
    );
    return allocations.every(([_, value]) => value === 0.0);
  }
  return !node.allocation;
};

/** Detect whether there are any funds inside this portfolio that need to be mapped */
export const hasUnmatchedFunds = (portfolio: Portfolio, excludedInvestments: Set<string>): boolean => {
  const hasUnmatchedFundsImpl = (portfolio: Portfolio, path: number[]): boolean => {
    if (isEmpty(portfolio.children)) {
      if (portfolio.fund) {
        // fund node
        const isExcluded = excludedInvestments.has(serializePath(path).value);
        const hasZeroAllocation = isZeroAllocationFund(portfolio);
        return isNil(portfolio.fund.id) && !isExcluded && !hasZeroAllocation;
      }
      return false; // a strategy without any children
    }
    for (const [index, child] of portfolio.children.entries()) {
      if (hasUnmatchedFundsImpl(child, [...path, index])) {
        return true;
      }
    }
    return false;
  };

  // The paths are prefixed with zero for root node
  return hasUnmatchedFundsImpl(portfolio, [0]);
};

/** Detect whether this portfolio node is a fund which needs to be mapped */
export const nodeNeedsMapping = ({ path, node: row }: RowData<Portfolio>, excludedInvestments: Set<string>) => {
  // if row is excluded no mapping is needed since it's gonna be ignored
  if (excludedInvestments.has(serializePath(path).value)) {
    return {
      isFund: !!row?.fund,
      needsMapping: false,
    };
  }

  if (row?.fund) {
    // is this a fund?
    if (row?.fund.id) {
      // if it has an id, it's already mapped
      return {
        isFund: true,
        needsMapping: false,
      };
    } // if it doesn't have an id, it needs mapping
    return {
      isFund: true,
      needsMapping: true,
    };
  } // strategy nodes don't need mapping
  return {
    isFund: false,
    needsMapping: false,
  };
};

/** Detect whether this historical portfolio node has any duplicate funds */
export const historicalNodeIsDuplicated = (
  { root, node: row, path }: RowData<Portfolio>,
  date: number | undefined,
  excludedInvestments: Set<string>,
) => {
  if (!root || !path || !row || !date || !row.fund?.id) {
    return {
      isFund: false,
      duplicateInvestment: false,
    };
  }

  // level 0 is the root, so we start at level 1
  let strategy = root;
  for (let level = 1; level < path.length - 1; level++) {
    strategy = strategy.children[path[level]!]!;
  }

  return {
    isFund: true,
    duplicateInvestment: hasDuplicatedHistoricalInvestments(strategy, row, path, date, excludedInvestments),
  };
};

export const historicalPortfolioHasDuplicatedInvestments = (
  portfolio: Portfolio,
  excludedInvestments: Set<string>,
): boolean => {
  const historicalPortfolioHasDuplicatedInvestmentsImpl = (portfolio: Portfolio, path: number[]): boolean => {
    return portfolio.children?.some((child, index) => {
      const childPath = [...path, index];
      if (child.fund) {
        return child.closingAllocationsTs?.some((dt_alloc) => {
          return hasDuplicatedHistoricalInvestments(portfolio, child, childPath, dt_alloc[0]!, excludedInvestments);
        });
      }
      return historicalPortfolioHasDuplicatedInvestmentsImpl(child, childPath);
    });
  };

  return historicalPortfolioHasDuplicatedInvestmentsImpl(portfolio, [0]);
};

export const hasDuplicatedHistoricalInvestments = (
  parentStrategy: Portfolio,
  fundNode: Portfolio,
  fundPath: number[],
  date: number,
  excludedInvestments: Set<string>,
): boolean => {
  // If node is excluded or has zero or no allocation on a given date return false
  const allocationAtDate = fundNode.closingAllocationsTs?.find((x) => x[0] === date)?.[1];

  if (excludedInvestments.has(serializePath(fundPath).value) || !allocationAtDate) {
    return false;
  }

  // Check if parent strategy contains another child with the same fund and closing allocation at the same date
  return (
    parentStrategy.children.filter((anotherChild, index) => {
      const anotherChildPath = [...fundPath.slice(0, -1), index];

      if (excludedInvestments.has(serializePath(anotherChildPath).value)) {
        return false;
      }

      return (
        anotherChild.fund?.id === fundNode.fund?.id &&
        anotherChild.closingAllocationsTs?.some((x) => x[0] === date && !!x[1])
      );
    }).length > 1
  );
};

/**
 * Recursively updates the fund of a portfolio node at a specified path.
 *
 * @param node - The root portfolio node.
 * @param path - An array of indices representing the path to the target node.
 * @param fund - The new fund to be assigned to the target node.
 * @returns The updated portfolio with the fund remapped at the specified path.
 *
 * @example
 * const portfolio = {
 *   name: 'Root Portfolio',
 *   fund: null,
 *   children: [
 *     {
 *       name: 'Child Portfolio',
 *       fund: null,
 *       children: [
 *         { name: 'Grandchild Portfolio', fund: { id: '123' }, children: [] },
 *         { name: 'Old Fund', fund: { id: 'old-fund-id' }, children: [] }
 *       ]
 *     }
 *   ]
 * };
 * const path = [0, 1];
 * const newFund = { id: 'new-fund-id', name: 'New Fund' };
 * const updatedPortfolio = remapInvestment(portfolio, path, newFund);
 * `updatedPortfolio.children[0].children[1]` will have the new fund
 */
export const remapInvestment = (node: Portfolio, path: number[], fund: Fund): Portfolio => {
  if (path.length < 1) {
    return {
      ...node,
      name: fund.name,
      fund,
    };
  }
  const [head, ...tail] = path;
  return {
    ...node,
    children: (node.children ?? []).map((child, index) => {
      if (index === head) {
        return remapInvestment(child, tail, fund);
      }
      return child;
    }),
  };
};

/** Find all funds ids within the portfolio */
export const getAllFundIds = (portfolio: Portfolio): string[] => {
  if (portfolio.fund) {
    return isNil(portfolio.fund.id) ? [] : [portfolio.fund.id];
  }

  return flatMap((portfolio.children ?? []).map((child) => getAllFundIds(child)));
};

export const sortPortfolio = (portfolio: Portfolio): Portfolio => {
  const sortPortfolioImpl = (portfolio: Portfolio) => {
    portfolio.children?.sort((a, b) =>
      isNil(a.fund) && !isNil(b.fund) ? 1 : !isNil(a.fund) && isNil(b.fund) ? -1 : a.name.localeCompare(b.name),
    );
    portfolio.children?.forEach((p) => sortPortfolioImpl(p));
  };

  const clonedPortfolio = cloneDeep(portfolio);
  sortPortfolioImpl(clonedPortfolio);
  return clonedPortfolio;
};

/**
 * Actually remove excluded and unmapped investments from portfolio.
 * This needs to be called right before we are about to send a request to backend to persist them
 */
export const removeExcludedAndUnmappedInvestments = (
  portfolio: Portfolio,
  excludedInvestments: Set<string>,
): Portfolio => {
  const excludedPaths = Array.from(excludedInvestments).map(deserializePath);

  /**
   * This "inefficient" helper function returns indices of children that should be excluded for a given path
   *
   * First, it finds out excluded paths that are direct children of a given path.
   * E.g. if current path is [0, 2, 1] then direct children can be [0, 2, 1, 1] and [0, 2, 1, 3]
   *
   * Then, it returns only last index from each direct children path (e.g. 1 and 3 in the example above)
   */
  const getDirectChildrenExcludedIndexes = (path: number[]): number[] => {
    return excludedPaths
      .filter(
        (excludedPath) => excludedPath.length === path.length + 1 && isEqual(excludedPath.slice(0, path.length), path),
      )
      .map((excludedPath) => excludedPath[excludedPath.length - 1]!);
  };

  /**
   * This dfs should run bottom-up, we first process all descendants and then the node itself.
   * If we do it top-down it will mess up indexing
   */
  const removeExcludedInvestmentsImpl = (portfolioNode: Portfolio, path: number[]) => {
    if (!isNil(portfolioNode.fund)) {
      return;
    }

    portfolioNode.children?.forEach((child, index) => {
      removeExcludedInvestmentsImpl(child, [...path, index]);
    });

    const excludedIndices = getDirectChildrenExcludedIndexes(path);

    // Exclude children by indices if they were explicitly excluded by user
    // And also exclude children that are funds without ids (unmapped)
    portfolioNode.children = portfolioNode.children?.filter(
      (child, index) => !excludedIndices.includes(index) && (!child.fund || child.fund.id),
    );
  };

  const clonedPortfolio = cloneDeep(portfolio);

  removeExcludedInvestmentsImpl(clonedPortfolio, [0]);

  return clonedPortfolio;
};

/**
 * Recalculates allocations for strategies by ignoring excluded investments.
 *
 * Note: This doesn't change allocations for excluded investments themselves, only for strategies that contain them.
 */
export const recalculateAllocations = (portfolio: Portfolio, excludedInvestments: Set<string>): Portfolio => {
  const recalculateAllocationsImpl = (portfolioNode: Portfolio, path: number[]) => {
    if (!isNil(portfolioNode.fund)) {
      return;
    }

    portfolioNode.children?.forEach((child, index) => {
      recalculateAllocationsImpl(child, [...path, index]);
    });

    portfolioNode.allocation = sum(
      portfolioNode.children
        ?.filter((_, index) => {
          return !excludedInvestments.has(serializePath([...path, index]).value);
        })
        ?.map((node) => node.allocation ?? 0.0),
    );
  };

  const clonedPortfolio = cloneDeep(portfolio);

  recalculateAllocationsImpl(clonedPortfolio, [0]);

  return clonedPortfolio;
};

export const getAllRanges = (range: SubjectRangeAnalysis): HistoricalFundRangeAnalysis =>
  merge(
    range.investmentId
      ? {
          [range.investmentId]: {
            start: range.start,
            end: range.end,
          },
        }
      : {},
    ...(range.rangeAnalyses ? range.rangeAnalyses.map(getAllRanges) : []),
  );

export const historicalNodeHasShortHistory = (allAllocs: number[][] | undefined, range?: HistoricalFundRange) => {
  if (!range || !allAllocs) {
    return false;
  }

  const allocTimestamps = allAllocs.filter((x) => !!x[1]).map((x) => x[0]!);

  if (isEmpty(allocTimestamps)) {
    return false;
  }

  const earliestAllocation = Math.min(...allocTimestamps);
  const latestAllocation = Math.max(...allocTimestamps);

  return (
    earliestAllocation < range.start ||
    latestAllocation < range.start ||
    earliestAllocation > range.end ||
    latestAllocation > range.end
  );
};

export const getFundsWithShortHistory = (
  portfolio: Portfolio,
  rangeAnalysis?: HistoricalFundRangeAnalysis,
): string[] => {
  if (!rangeAnalysis) {
    return [];
  }

  const fundsWithShortHistory: string[] = [];

  const getFundsWithShortHistoryImpl = (portfolioNode: Portfolio) => {
    const fundId = portfolioNode.fund?.id;
    if (fundId) {
      if (historicalNodeHasShortHistory(portfolioNode.closingAllocationsTs, rangeAnalysis[fundId])) {
        fundsWithShortHistory.push(assertNotNil(portfolioNode.fund?.name));
      }
    } else {
      portfolioNode.children?.forEach((child) => getFundsWithShortHistoryImpl(child));
    }
  };

  getFundsWithShortHistoryImpl(portfolio);

  return uniq(fundsWithShortHistory);
};

export const getPortfolioAllocationRange = (portfolio: Portfolio): { from: number; to: number } | undefined => {
  if (!portfolio.historical) {
    return undefined;
  }

  const allAllocationDates: number[] = [];

  const getAllPortfolioAllocationDates = (portfolioNode: Portfolio) => {
    portfolioNode.closingAllocationsTs?.forEach(([date, _]) => allAllocationDates.push(date!));
    portfolioNode.children?.forEach((child) => getAllPortfolioAllocationDates(child));
  };

  getAllPortfolioAllocationDates(portfolio);

  if (isEmpty(allAllocationDates)) {
    return undefined;
  }

  const sortedDates = sortBy(allAllocationDates);

  return {
    from: sortedDates[0]!,
    to: sortedDates[sortedDates.length - 1]!,
  };
};

export const getDefaultPortfolioParseResultCategory = (
  result: PortfolioParseResult,
): DefaultPortfolioParseResultCategory => {
  if (isNil(result.parsedPortfolio.id)) {
    return 'new' as const;
  }
  return 'existing' as const;
};
