import type { RangeAnalysisResponse, SubjectRangeAnalysis } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { clamp, isNil, sortBy } from 'lodash';
import { assertNotNil, getFormattedPeriod, logExceptionIntoSentry } from 'venn-utils';
import { now } from 'moment';
import type { Allocation, Timestamp } from './types';

const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
export const atStartOfDay = (date?: number) => {
  if (isNil(date)) {
    return date;
  }
  return date - DAY_IN_MILLIS + 1;
};
export const getRangeLogic = (
  hasBulkProxy: boolean,
  rangeAnalysis: RangeAnalysisResponse,
  primaryRangeAnalysis?: SubjectRangeAnalysis | null,
  options?: {
    short?: boolean;
  },
) => {
  const [earliestHistoricalStart, latestHistoricalEnd] = rangeAnalysis.rangeAnalyses.reduce(
    ([earliest, latest], analysis) => [
      Math.min(earliest, analysis.historicalStart ?? Number.MAX_SAFE_INTEGER),
      Math.max(latest, analysis.historicalEnd ?? Number.MIN_SAFE_INTEGER),
    ],
    [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
  );
  const earliestStart = assertNotNil(
    atStartOfDay(
      hasBulkProxy
        ? Math.min(earliestHistoricalStart, rangeAnalysis.earliestStart ?? 0)
        : (primaryRangeAnalysis?.earliestStart ?? 0),
    ),
  );
  const latestEnd = hasBulkProxy
    ? Math.max(latestHistoricalEnd, rangeAnalysis.latestEnd ?? now())
    : Math.min(rangeAnalysis.factorRange.end ?? now(), rangeAnalysis.latestEnd ?? now());
  const overlapStart = atStartOfDay(rangeAnalysis.start);
  const overlapEnd = rangeAnalysis.end;
  const fullRange = latestEnd && earliestStart ? latestEnd - earliestStart : 0;
  const frequency = rangeAnalysis.frequency;

  return {
    earliestStart,
    latestEnd,
    fullRange,
    frequency,
    overlap:
      !overlapStart || !overlapEnd || overlapStart >= overlapEnd
        ? {
            percentageStart: 0,
            percentageWidth: 0,
            length: `0 ${options?.short ? 'mos' : 'months'}`,
          }
        : {
            percentageWidth: fullRange <= 0 ? 0 : clamp((overlapEnd - overlapStart) / fullRange, 0, 1),
            percentageStart: fullRange <= 0 ? 0 : clamp((overlapStart - earliestStart) / fullRange, 0, 1),
            length: getFormattedPeriod('UNKNOWN', overlapStart, overlapEnd, options),
          },
  };
};

export type RowRangeProps = {
  /** Start date of this row's investment */
  startDate: number;
  /** End date of this row's investment */
  endDate: number;
  /** Start date of proxy, if the proxy exists */
  proxyStartDate: number | undefined;
  /** End date of proxy, if the proxy exists */
  proxyEndDate: number | undefined;
  /** Start date of extrapolation, if it is applied */
  extrapolateStartDate: number | undefined;
  /** End date of extrapolation, if it is applied */
  extrapolateEndDate: number | undefined;
  /** Length of the entire analysis period (a.k.a length of the chart) */
  fullRange: number;
  /** Start of the entire analysis period (a.k.a start of the chart) */
  earliestStart: number;
  /** End of the entire analysis period (a.k.a end of the chart) */
  latestEnd: number;
};

type HistoricalRowRangeProps = {
  /** Length of the entire analysis period (a.k.a length of the chart) */
  fullRange: number;
  /** Start of the entire analysis period (a.k.a start of the chart) */
  earliestStart: number;
  /** End of the entire analysis period (a.k.a end of the chart) */
  latestEnd: number;
  /** End of the entire investment return (a.k.a max of investment | proxy | extrapolation returns) */
  finalReturnDate: number;
  /** Historical Allocation */
  historicalAllocation: Record<Timestamp, Allocation>;
};

export type RowRange = {
  /** where the range starts, expected in [0 - 1] */
  percentageStart: number;
  /** width of this range, expected in [0 - 1] */
  percentageWidth: number;
};

export type AnalysisPeriodChartRowInfo = {
  proxy: RowRange;
  investment: RowRange;
  extrapolation: RowRange;
};
export type HistoricalAnalysisPeriodChartRowInfo =
  | {
      hasHistorical: true;
      rowRanges: RowRange[];
      isFinalAllocationLiquidation: boolean;
      hypotheticalReturnExtension?: RowRange;
    }
  | {
      hasHistorical: false;
    };
export const getHistoricalRowRangeLogic = ({
  fullRange,
  earliestStart,
  latestEnd,
  historicalAllocation,
  finalReturnDate,
}: HistoricalRowRangeProps): HistoricalAnalysisPeriodChartRowInfo => {
  if (fullRange === 0) {
    logExceptionIntoSentry(
      `Error plotting row in historical analysis period legend. Full analysis range: ${fullRange}.`,
    );
  }
  type Range = {
    start: number;
    end: number;
  };
  const sortedAllocationDates = sortBy(Object.keys(historicalAllocation).map(Number));
  // we expect to have at least one allocation date
  const finalAllocationDate = sortedAllocationDates[sortedAllocationDates.length - 1]!;
  const isFinalAllocationLiquidation = historicalAllocation[finalAllocationDate] === 0;

  // compress all non-zero allocations together so that each bar represents a contiguous range
  const compressedAllocationRanges: Range[] = sortedAllocationDates.reduce<Range[]>((ranges, date, i, arr) => {
    const alloc = historicalAllocation[date]!;
    const prevAlloc = i > 0 ? historicalAllocation[arr[i - 1]!] : 0;
    if (alloc !== 0 && prevAlloc === 0) {
      // start of a new range
      ranges.push({ start: date, end: date });
    } else if (prevAlloc !== 0) {
      ranges[ranges.length - 1]!.end = date;
    }
    if (i === arr.length - 1) {
      // end of the last range
      if (alloc !== 0) {
        ranges[ranges.length - 1]!.end = latestEnd;
      }
    }
    return ranges;
  }, []);

  // get the percentage start and width of each bar
  const rowRanges = compressedAllocationRanges.map(({ start, end }) => ({
    percentageStart: clamp((Math.max(start, earliestStart) - earliestStart) / fullRange, 0, 1),
    percentageWidth: clamp((Math.min(end, latestEnd) - Math.max(start, earliestStart)) / fullRange, 0, 1),
  }));
  const hypotheticalReturnExtension =
    finalAllocationDate > finalReturnDate
      ? {
          percentageStart: clamp((Math.max(finalReturnDate, earliestStart) - earliestStart) / fullRange, 0, 1),
          percentageWidth: clamp(
            (Math.min(finalAllocationDate, latestEnd) - Math.max(finalReturnDate, earliestStart)) / fullRange,
            0,
            1,
          ),
        }
      : undefined;
  return rowRanges.length > 0
    ? {
        hasHistorical: true,
        rowRanges,
        isFinalAllocationLiquidation,
        hypotheticalReturnExtension,
      }
    : { hasHistorical: false };
};
export const getRowRangeLogic = (info: RowRangeProps): AnalysisPeriodChartRowInfo => {
  const {
    startDate,
    endDate,
    fullRange,
    earliestStart,
    latestEnd,
    proxyEndDate,
    extrapolateStartDate,
    extrapolateEndDate,
  } = info;
  const barStart = Math.max(startDate, earliestStart);
  const barEnd = Math.min(endDate, latestEnd);
  const investmentRange = barEnd - barStart;

  if (fullRange === 0 || investmentRange === 0) {
    logExceptionIntoSentry(
      `Error plotting row in analysis period legend. Full analysis range: ${fullRange}. Investment range: ${investmentRange}`,
    );
  }

  // returned investment is a range in the entire chart
  // however, returned "proxy" and "extrapolation" ranges are defined in terms of the full investment range

  // Example. The chart displays years [2018 - 2022] (5 years).
  // Let's say the full investment range is [2018 - 2021] (4 years),
  // of which the extrapolation period is last six months of 2021,
  // and the used proxy is backfilling the entirety of 2018.
  // Then on the displayed chart:
  // investment = [0.0 start, 0.8 width]
  // proxy = [0.0 start, 0.25 width (as this a quarter of the investment!)]
  // extrapolation = [0.75 start, 0.25 width]
  return {
    investment: {
      percentageStart: clamp((barStart - earliestStart) / fullRange, 0, 1),
      percentageWidth: clamp(investmentRange / fullRange, 0, 1),
    },
    proxy: {
      percentageStart: 0, // the proxy always starts at the chart boundary
      percentageWidth: !proxyEndDate ? 0 : clamp((proxyEndDate - barStart) / investmentRange, 0, 1),
    },
    extrapolation:
      extrapolateStartDate !== undefined && extrapolateEndDate !== undefined
        ? {
            percentageStart: (extrapolateStartDate - barStart) / investmentRange,
            percentageWidth: (extrapolateEndDate - extrapolateStartDate) / investmentRange,
          }
        : {
            percentageWidth: 0,
            percentageStart: 0,
          },
  };
};

export const getDataColor = (theme: Theme, zeroAllocation: boolean) => {
  const { Colors } = theme;
  const hasReturns = !zeroAllocation;
  if (hasReturns) {
    return {
      investmentColor: Colors.MidGrey2,
      proxyColor: Colors.HighlightDark,
      extrapolationColor: Colors.HighlightLight,
      secondaryLegendColor: Colors.Black,
      historicalColor: Colors.Black,
      hypotheticalReturnExtensionColor: Colors.Orange,
    };
  }

  return {
    investmentColor: Colors.MidGrey2,
    proxyColor: Colors.HighlightLight,
    extrapolationColor: Colors.HighlightLight,
    secondaryLegendColor: Colors.Black,
    historicalColor: Colors.Black,
    hypotheticalReturnExtensionColor: Colors.Orange,
  };
};

export type DeprecatedGetDataColorProps = {
  theme: Theme;
  isGreyedOut: boolean;
  secondaryData: boolean;
  hasBulkProxy?: boolean;
};
export const deprecatedGetDataColor = ({
  theme,
  isGreyedOut,
  secondaryData,
  hasBulkProxy = false,
}: DeprecatedGetDataColorProps) => {
  const { Colors } = theme;
  if (isGreyedOut) {
    return {
      investmentColor: Colors.MidGrey2,
      proxyColor: Colors.MidGrey1,
    };
  }
  if (secondaryData) {
    return {
      investmentColor: hasBulkProxy ? Colors.MidGrey2 : Colors.DEPRECATED_DataBarColor.LightDarkBlue,
      proxyColor: hasBulkProxy ? Colors.MidGrey1 : Colors.DEPRECATED_DataBarColor.LightPaleBlue,
    };
  }
  return {
    investmentColor: hasBulkProxy ? Colors.MidGrey2 : Colors.DEPRECATED_DataLineColor.Gold,
    proxyColor: hasBulkProxy ? Colors.HighlightDark : Colors.DEPRECATED_DataLineColor.PaleGold,
  };
};
