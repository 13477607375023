import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { useIsMounted } from 'venn-utils';
import type { SingleValue } from 'react-select';

/**
 * Field name to field value mappings.
 * If the field value is an object, the object is returned.
 *
 * TODO: (VENN-20577 / TYPES) this type is wrong but it is difficult to fix it right now. May be easier in later typescript versions.
 */
export interface FormValues {
  [name: string]: AnyDuringEslintMigration;
}

/**
 * A function that returns an error message for a field given its value
 * and the values of the fields in the form. Returns undefined if there is no error.
 */
export type Validator<V> = (value: V, values?: FormValues) => string | undefined;

export const createField = <T = string>(
  name: string,
  value: T,
  validator: Validator<T> | Validator<T>[] = [],
  validatePrevious = false,
  validateOnChange = false,
): Field<T> => ({
  name,
  validator,
  value,
  validatePrevious,
  validateOnChange,
});

export interface Field<T = AnyDuringEslintMigration> {
  name: string;
  value: T;
  validator: Validator<T> | Validator<T>[];
  /** Whether to validate previous fields when this field is validated */
  validatePrevious: boolean;
  /** Whether to show errors as the user is typing */
  validateOnChange: boolean;

  touched?: boolean;
  active?: boolean;
  /** The error message to show the user */
  error?: string;
  /** Whether the field has an error or not. This is can still be true even if there is no error message to show. */
  hasError?: boolean;
}

// TODO: (VENN-20577 / TYPES) need to switch this/users of this to use generics to get rid of the AnyDuringEslintMigration
export interface Fields {
  [name: string]: AnyDuringEslintMigration;
}

export interface FormField<T = AnyDuringEslintMigration> extends Field<T> {
  onChange: (value: SingleValue<T>) => void;
  onFocus: () => void;
  onBlur: () => void;
  reset: () => void;
}

export interface FormFields {
  [name: string]: FormField;
}

/**
 * Form hook that returns
 *  [fieldsWithCallbacks, isValid, isSubmitting, submit]
 * which correspond to [
 *  <the form fields with onChange, onFocus, onBlur, reset callbacks.
 *    Use onChange() to update the value of a field, or reset() to set its value and state back to the input>,
 *  <whether the form values are valid after running the given validators on each of them>,
 *  <whether the form is in the process of being submitted>,
 *  <the onSubmit handler to pass into a <form>. This appropriately sets isSubmitting and calls the given argument onSubmit>.
 * ]
 *
 * Use a combination of isValid and isSubmitting to disable the submit button on the form.
 * Pass in {...field[name]} to a FormInput or FormSelect component
 * to show the value and inline errors on form fields.
 *
 * @param inputFields - the fields in the form (in the order in which they appear in the form if validateOnPrevious is used).
 *  If inputFields changes, note that the state is only updated if the set of field names is different.
 *  If the fields state is updated, the values of the similar fields are preserved.
 * @param onSubmit - the function to run when <submit> is called
 */
export default function useForm<V extends FormValues>(
  // TODO: (VENN-20577 / TYPES) Field[] is very general and doesn't give us type safety on the form values or the exact fields that we have
  inputFields: Field[],
  onSubmit: (values: V) => void | Promise<void>,
): [FormFields, boolean, boolean, (event: React.FormEvent) => void] {
  const [fields, setFields] = useState<Field[]>(inputFields);
  const [isValid, setValid] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const validate = useCallback(
    async (submitAfterValidation?: boolean) => {
      const values = getValues(fields);
      const validatedFields = fields.map((field) => validateField(field, values));
      const prevErrors = fields.filter((field) => field.hasError);
      const currentErrors = validatedFields.filter((field) => field.hasError);
      if (!isEqual(prevErrors, currentErrors)) {
        setFields(validatedFields);
      }

      const isFormValid = validatedFields.reduce((valid: boolean, field: Field) => valid && !field.hasError, true);
      setValid(isFormValid);
      if (isFormValid && submitAfterValidation) {
        await onSubmit(getValues(validatedFields) as V);
      }
      return validatedFields;
    },
    [fields, onSubmit],
  );

  const resetField = useCallback(
    (index: number) => () => {
      setFields((prev) => [...prev.slice(0, index), inputFields[index]!, ...prev.slice(index + 1)]);
    },
    [inputFields, setFields],
  );

  const onFieldValueChange = useCallback(
    (index: number) => (value: string) => {
      setFields((prev) => {
        const changedField = prev[index]!;
        const values = { ...getValues(prev), [changedField.name]: value };
        return [...prev.slice(0, index), touchAndValidateField(changedField, values), ...prev.slice(index + 1)];
      });
    },
    [setFields],
  );

  const onFieldFocus = useCallback(
    (index: number) => () => {
      setFields((prev) => {
        const values = getValues(prev);
        return [
          ...prev
            .slice(0, index)
            .map((field) => (prev[index]!.validatePrevious ? touchAndValidateField(field, values) : field)),
          {
            ...prev[index],
            active: true,
          },
          ...prev.slice(index + 1),
        ] as Field[];
      });
    },
    [setFields],
  );

  const onFieldBlur = useCallback(
    (index: number) => () => {
      setFields((prev) => {
        const field = {
          ...prev[index]!,
          touched: true,
          active: false,
        };
        return [
          ...prev.slice(0, index),
          {
            ...field,
            ...validateField(field, getValues(prev)),
          },
          ...prev.slice(index + 1),
        ];
      });
    },
    [setFields],
  );

  const fieldsWithCallbacks: FormFields = useMemo(
    () =>
      fields &&
      fields.reduce(
        (prev, field: Field, index: number) => ({
          ...prev,
          [field.name]: {
            ...field,
            onChange: onFieldValueChange(index),
            onFocus: onFieldFocus(index),
            onBlur: onFieldBlur(index),
            reset: resetField(index),
          },
        }),
        {},
      ),
    [fields, onFieldValueChange, onFieldFocus, onFieldBlur, resetField],
  );

  const isMountedRef = useIsMounted();
  const submit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!isSubmitting) {
        setSubmitting(true);
        await validate(true);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    },
    [isSubmitting, validate, isMountedRef],
  );

  useEffect(() => {
    validate();
  }, [validate]);

  useEffect(() => {
    // only update when the field names are different
    if (!isEqual(new Set(getFieldNames(inputFields)), new Set(getFieldNames(fields)))) {
      // populate new fields with existing values and errors
      setFields((prev) =>
        inputFields.map((field: Field) => {
          const existingField = prev.find((prevField) => prevField.name === field.name);
          return {
            ...field,
            ...(existingField
              ? {
                  value: existingField.value,
                  error: existingField.error,
                  hasError: existingField.hasError,
                  touched: existingField.touched,
                }
              : {}),
          };
        }),
      );
    }
  }, [inputFields, fields, fieldsWithCallbacks]);

  return [fieldsWithCallbacks, isValid, isSubmitting, submit];
}

const showErrorMessage = (touched: boolean, active: boolean, validateOnChange: boolean) =>
  touched && (!active || validateOnChange);

const validateField = (field: Field, values: FormValues): Field => {
  const validators = Array.isArray(field.validator) ? field.validator : [field.validator];
  const error = validators.reduce(
    (currentError: string | undefined, validator) => currentError || validator(field.value, values) || undefined,
    undefined,
  );

  return {
    ...field,
    error: showErrorMessage(!!field.touched, !!field.active, field.validateOnChange) ? error : undefined,
    hasError: !!error,
  };
};

const touchAndValidateField = (field: Field, values: FormValues) => {
  const touchedField = {
    ...field,
    touched: true,
  };
  return {
    ...field,
    ...validateField({ ...touchedField, value: values[field.name] }, values),
  };
};

const getFieldNames = (fields: Field[]) => fields.map((field: Field) => field.name);

const getValues = (fields: Field[]): FormValues =>
  fields.reduce(
    (prev: FormFields, field: Field) => ({
      ...prev,
      [field.name]: field.value,
    }),
    {},
  );
