/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import XAxisTick from './XAxisTick';
import type { GridProps, GridState } from './deriveState';
import deriveState from './deriveState';
import { withTheme } from 'styled-components';

class Grid extends PureComponent<GridProps, GridState> {
  static getDerivedStateFromProps(props: GridProps): Partial<GridState> {
    return deriveState(props);
  }

  constructor(props: GridProps) {
    super(props);
    this.state = {
      ticks: [],
      width: 0,
      height: 0,
      rowHeight: 0,
      rowsCount: 0,
      labelPosition: 0,
      middleTickIndex: 0,
    };
  }

  render() {
    const { ticks, width, height, rowHeight, rowsCount, labelPosition } = this.state;
    const {
      theme: { Colors },
    } = this.props;
    const chartGridColor = Colors.PaleGrey;
    const min = ticks[0]!;
    const max = ticks[2]!;
    return (
      <g>
        <g style={{ stroke: chartGridColor }} transform={`translate(0, ${rowHeight})`}>
          {Array.from({ length: rowsCount - 1 }, (_, index) => (
            <line key={index} x1={0.5} y1={index * rowHeight} x2={width - 0.5} y2={index * rowHeight} />
          ))}
        </g>
        {min && (
          <XAxisTick
            position={min.position}
            labelTransform={35}
            label={min.label}
            gridColor={chartGridColor}
            labelPosition={labelPosition}
            height={height + this.props.viewPort.rowHeight + 3}
            rowHeight={this.props.viewPort.rowHeight}
          />
        )}
        {max && (
          <XAxisTick
            position={max.position}
            labelTransform={-35}
            label={max.label}
            gridColor={chartGridColor}
            labelPosition={labelPosition}
            height={height + 39.5}
            rowHeight={this.props.viewPort.rowHeight}
          />
        )}
        <rect x={1} y={0} width={min ? min.position : 160} height={height} fill="#fff" fillOpacity={0} />
        <rect
          x={max ? max.position : 0}
          y={0}
          width={max ? width - max.position : 160}
          height={height}
          fill={Colors.White}
          fillOpacity={0}
        />
      </g>
    );
  }
}
/* eslint-enable react/no-unused-state */

// @ts-expect-error: TODO fix strictFunctionTypes
export default withTheme(Grid);
