import React, { useCallback, useMemo, useState } from 'react';
import { type AnalysisSubject, getProxyTypeNoun } from 'venn-utils';
import { SearchMenuBar, SearchMenuColumn, type SearchMenuItem } from '../../../../search-menu';
import type { FundReturnsRange, ItemId, ProxyTypeEnum } from 'venn-api';
import { compact, uniq } from 'lodash';
import { getDisabledProxyMessage, type SelectedProxy } from '../utils';
import type { FundToBulkProxy } from '../../types';
import { AutoProxySection } from './AutoproxySection';

interface BulkProxySearchProps {
  selectedProxy: SelectedProxy | null;
  setSelectedProxy: (proxy: SelectedProxy) => void;
  investments: FundToBulkProxy[];
  rawInvestmentReturnsRanges: FundReturnsRange[];
  selectedProxyType: ProxyTypeEnum | undefined;
  disabled?: boolean;
  shouldExtrapolate?: boolean;
  autoProxyEnabled?: boolean;
  excludedInvestmentIds?: string[];
}

export const BulkProxySearch = ({
  selectedProxy,
  setSelectedProxy,
  investments,
  rawInvestmentReturnsRanges,
  selectedProxyType,
  disabled,
  shouldExtrapolate,
  autoProxyEnabled,
  excludedInvestmentIds,
}: BulkProxySearchProps) => {
  const [currentQuery, setCurrentQuery] = useState<string>('');

  const currentSearchValue = useMemo(
    () =>
      selectedProxy
        ? ({
            category: 'investment',
            label: selectedProxy.name,
            value: { id: selectedProxy.id, name: selectedProxy.name } as AnalysisSubject,
          } as SearchMenuItem)
        : null,
    [selectedProxy],
  );

  const onSelected = useCallback(
    (selected: SearchMenuItem) => {
      if (!selected.value?.fund) {
        return;
      }
      setSelectedProxy({ name: selected.label, id: selected.value.fund.id });
    },
    [setSelectedProxy],
  );

  const getDisabledSearchResultReason = useCallback(
    (result: SearchMenuItem) => {
      if (!selectedProxyType || !result.searchResult) {
        return '';
      }
      const allReasons = compact(
        investments.map((investment, index) =>
          getDisabledProxyMessage(
            result.searchResult!,
            selectedProxyType,
            investment,
            rawInvestmentReturnsRanges?.[index] ?? null,
          ),
        ),
      );

      if (allReasons.length < investments.length) {
        return '';
      }

      const hasOneUniqueMessage = uniq(allReasons).length === 1;
      if (hasOneUniqueMessage) {
        return allReasons[0]!;
      }

      return `This investment is not eligible for ${getProxyTypeNoun(selectedProxyType)} with any of your selected investments.`;
    },
    [investments, rawInvestmentReturnsRanges, selectedProxyType],
  );

  const isOptionDisabled = useCallback(
    (option: SearchMenuItem) => {
      if (option.category === 'tag') {
        return false;
      }
      return !!getDisabledSearchResultReason(option);
    },
    [getDisabledSearchResultReason],
  );

  const excludedItems = useMemo(
    () =>
      investments
        .map((investment) => investment.id)
        .concat(excludedInvestmentIds ?? [])
        .map((id): ItemId => ({ id, type: 'FUND' })),
    [investments, excludedInvestmentIds],
  );

  const searchDisabled = !selectedProxyType || disabled;
  const showAutoproxies =
    autoProxyEnabled && !searchDisabled && investments.length === 1 && currentQuery === '' && selectedProxy === null;
  const hideResults = showAutoproxies || (currentQuery === '' && !selectedProxy);

  return (
    <>
      <SearchMenuBar
        className="ml-0.5"
        disabled={searchDisabled}
        investmentsOnly
        autofocus={false}
        proxyable
        onSelected={onSelected}
        displayResultsAsBlock={!searchDisabled}
        value={currentSearchValue}
        isClearable
        clearQueryOnBlur
        privateAssetSearchMode="PUBLIC_ONLY"
        excludedItems={excludedItems}
        location="bulk-proxy-picker"
        shortPlaceholder
        isOptionDisabled={isOptionDisabled}
        optionDisabledTooltipContent={getDisabledSearchResultReason}
        columns={[
          SearchMenuColumn.CCY,
          SearchMenuColumn.IDENTIFIER,
          SearchMenuColumn.TYPE,
          SearchMenuColumn.UNPROXIED_FREQUENCY,
          SearchMenuColumn.UNPROXIED_AVAILABLE_DATES,
        ]}
        placeholders={selectedProxy ? { ids: [{ id: selectedProxy.id, type: 'FUND' }], type: 'investment' } : undefined}
        maxResults={14}
        smallScreen
        refreshedStyling
        forceShowSearchIcon
        menuStyles={{ backgroundColor: 'transparent' }}
        onQueryChange={setCurrentQuery}
        showRecentlyAnalyzed={false}
        forceHideResults={hideResults}
      />
      {showAutoproxies && (
        <AutoProxySection
          investment={investments[0]!}
          setSelectedProxy={setSelectedProxy}
          shouldExtrapolate={shouldExtrapolate}
        />
      )}
    </>
  );
};
