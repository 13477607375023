import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FundsCorrelationsChartWrapper from './FundsCorrelationsChartWrapper';
import type { RangeType } from 'venn-ui-kit';
import { Loading } from 'venn-ui-kit';
import type { CorrelationMatrix, FundCorrelation } from 'venn-api';
import { analysis } from 'venn-api';
import { buildDataForFund, buildDataForPortfolio } from './logic';
import styled, { ThemeContext } from 'styled-components';
import StyledLoad from './StyledLoad';
import type { AnalysisSubject, TimeFrame } from 'venn-utils';
import { analyticsService, getAnalysisLabels, getAnalysisRequest, useApi } from 'venn-utils';
import { compact } from 'lodash';
import { AnalysisViewContext, AnalysisConfigContext } from '../../../contexts';
import type { FailedAnalysisError } from '../../chart-errors/types';
import type { TrackAnalysisProps } from '../../chart-errors/TrackAnalysis';
import type { DownloadMetaData } from '../../../downloadable';

const MISSING_MASTER_CODE = 10215;

interface FundCorrelationTableProps {
  subject?: AnalysisSubject;
  selectedTimeFrame: TimeFrame;
  selectedPeriod?: RangeType;
  benchmarkRelative: boolean;
  width: number;
  categoryActive: boolean;
  trackingProps: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
  onResetTimeFrame?: () => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  width,
  benchmarkRelative,
  subject,
  selectedTimeFrame,
  selectedPeriod,
  categoryActive,
  trackingProps,
  downloadMetaData,
  onResetTimeFrame,
}: FundCorrelationTableProps) => {
  const { residualCorrelation, showCorrelationValues, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const { actualTimeFrame } = useContext(AnalysisConfigContext);
  const [fundCorrelation, setFundCorrelation] = useState<FundCorrelation[] | null>(null);
  const [portfolioCorrelationMatrix, setPortfolioCorrelationMatrix] = useState<CorrelationMatrix | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [residualized, setResidualized] = useState<boolean>(residualCorrelation);
  const [showValues, setShowValues] = useState(showCorrelationValues);
  const [error, setError] = useState<FailedAnalysisError | undefined>(undefined);
  const [analysisTimeFrame, setAnalysisTimeFrame] = useState<TimeFrame | undefined>(undefined);
  const [isMissingMaster, setIsMissingMaster] = useState<boolean>(false);
  // `resultTrackingId` and `prevResidualizedRef` are used to determine when NOT to refresh analysis.
  const [resultTrackingId, setResultTrackingId] = useState<number>(-1);
  const prevResidualizedRef = useRef<boolean>(false);
  const { Colors } = useContext(ThemeContext);
  const analysisRef = useRef(useApi(analysis));

  useEffect(() => {
    setResidualized(residualCorrelation);
  }, [residualCorrelation]);

  useEffect(() => {
    setShowValues(showCorrelationValues);
  }, [showCorrelationValues]);

  useEffect(() => {
    const fetchAnalysis = async () => {
      if (!subject) {
        return;
      }

      setLoading(true);
      const configs = getAnalysisRequest(
        [subject && subject.type === 'investment' ? 'FUND_RESIDUAL_CORRELATION' : 'CORRELATION'],
        subject,
        selectedTimeFrame,
        benchmarkRelative,
        categoryActive,
        selectedPeriod,
        trackingProps.trackingId,
        undefined,
        residualized,
      );
      try {
        const { content } = await analysisRef.current(configs);
        const correlation = content?.analyses?.[0]?.fundCorrelations ?? null;
        setFundCorrelation(correlation);

        const portfolioCorrelation = content?.analyses?.[0]?.correlationMatrix ?? null;
        setPortfolioCorrelationMatrix(portfolioCorrelation);

        const [start, end] = correlation
          ? [correlation?.[0]?.correlationStart, correlation?.[0]?.correlationEnd]
          : portfolioCorrelation
            ? [portfolioCorrelation.start, portfolioCorrelation.end]
            : [undefined, undefined];
        setAnalysisTimeFrame({
          startTime: start,
          endTime: end,
        });

        const errorMessage = content?.analyses?.[0]?.message;
        // Update tracking error code appropriately
        trackingProps.errorCode = errorMessage?.code;

        setError(errorMessage && { message: errorMessage?.text, code: errorMessage?.code });
        setResultTrackingId(trackingProps.trackingId);
        setIsMissingMaster(errorMessage?.code === MISSING_MASTER_CODE);
        setLoading(false);
      } catch (e) {
        const { name, content } = await e;
        if (name === 'AbortError') {
          return;
        }
        setError({ message: content?.message || content?.text, code: undefined });
        setResultTrackingId(trackingProps.trackingId);
        setLoading(false);
      }
    };
    if (resultTrackingId !== trackingProps.trackingId || prevResidualizedRef.current !== residualized) {
      fetchAnalysis();
    }
    // ignore trackingProps as a dependency, as we're only dependent on errorCode and trackingId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    benchmarkRelative,
    categoryActive,
    selectedPeriod,
    residualized,
    resultTrackingId,
    subject,
    selectedTimeFrame,
    trackingProps.errorCode,
    trackingProps.trackingId,
  ]);

  const onResidualToggle = () => {
    setResidualized((prevResidualized: boolean) => {
      analyticsService.toggleToggled({
        purpose: 'Toggle use residual',
        selection: !prevResidualized ? 'On' : 'Off',
        locationOnPage: 'Correlations chart',
      });
      onUpdateAnalysisViewParam({ residualCorrelation: !prevResidualized });
      return !prevResidualized;
    });
  };

  const toggleShowValues = useCallback(() => {
    setShowValues((prevShowValues) => {
      onUpdateAnalysisViewParam({ showCorrelationValues: !prevShowValues });
      return !prevShowValues;
    });
  }, [setShowValues, onUpdateAnalysisViewParam]);

  const allLabels = subject
    ? getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated)
    : null;
  const labels =
    subject && allLabels
      ? compact([
          allLabels.main,
          subject.hasSecondarySubject ? allLabels.comparison : null,
          subject.activeBenchmarkId ? allLabels.benchmark : null,
          subject.categoryGroup ? allLabels.category : null,
        ])
      : null;
  const trailingLabels =
    subject && allLabels
      ? compact([
          subject.activeBenchmarkId && !benchmarkRelative ? allLabels.benchmark : null,
          subject.hasSecondarySubject ? allLabels.comparison : null,
        ])
      : null;

  const { flatNames, roots, investmentsCount } = useMemo(() => {
    if (!subject) {
      return { flatNames: null, roots: null, investmentsCount: 0 };
    }
    return fundCorrelation && labels
      ? buildDataForFund(fundCorrelation, residualized, labels, Colors)
      : portfolioCorrelationMatrix && trailingLabels
        ? buildDataForPortfolio(
            subject.strategy,
            portfolioCorrelationMatrix,
            residualized,
            subject.name,
            trailingLabels,
            Colors,
          )
        : { flatNames: null, roots: null, investmentsCount: 0 };
  }, [subject, fundCorrelation, labels, residualized, portfolioCorrelationMatrix, trailingLabels, Colors]);

  useEffect(() => {
    prevResidualizedRef.current = residualized;
  }, [residualized]);

  if (loading || !subject) {
    return <Loading />;
  }

  let prefix = '';
  if (benchmarkRelative) {
    prefix = 'Relative ';
  } else if (residualized) {
    prefix = 'Residual ';
  }
  const title = `${prefix}Correlations`;
  const subtitle = fundCorrelation
    ? 'Correlation between this investment and the 20 largest investments in your master portfolio.'
    : 'Correlations across up to 20 of the largest investments in the selected portfolio or strategy.';
  const userUploaded = subject?.fund?.userUploaded;

  const tracking = {
    ...trackingProps,
    trackingId: trackingProps.trackingId === resultTrackingId ? trackingProps.trackingId : -1,
  };

  return (
    <Wrapper className="qa-fund-correlation">
      <FundsCorrelationsChartWrapper
        data={roots ?? null}
        nodeNames={flatNames ?? null}
        seriesDataSize={roots ? Math.max(...roots.map((r) => r.series[0]!.data.length)) : 0}
        title={title}
        subtitle={subtitle}
        width={width}
        loading={loading}
        relative={benchmarkRelative}
        residualized={residualized}
        showValues={showValues}
        userUploaded={!!userUploaded}
        analysisTimeFrame={analysisTimeFrame}
        onResidualToggle={onResidualToggle}
        toggleShowValues={toggleShowValues}
        isPortfolio={subject && subject.type === 'portfolio'}
        investmentsCount={investmentsCount}
        trailingLabels={trailingLabels ?? []}
        tracking={tracking}
        error={error}
        subject={subject}
        isMissingMaster={isMissingMaster}
        actualTimeFrame={actualTimeFrame}
        categoryActive={categoryActive}
        onResetTimeFrame={onResetTimeFrame}
        downloadMetaData={downloadMetaData}
      />
      <StyledLoad loading={loading} width={width} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 20px;
`;
