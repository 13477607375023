import type { Portfolio } from 'venn-api';
import { isEqualWith } from 'lodash';

export const arePortfoliosEqual = (portfolio: Portfolio, other: Portfolio): boolean =>
  isEqualWith(portfolio, other, (_, __, key: string | number | symbol | undefined) =>
    key === 'updated' || key === 'compare' ? true : undefined,
  );

export const findInPortfolioBy = (
  portfolio: Portfolio,
  predicate: (portfolio: Portfolio) => boolean,
): Portfolio | undefined => {
  for (const node of traversePortfolio(portfolio)) {
    if (predicate(node)) {
      return node;
    }
  }
  return undefined;
};

export const findInPortfolio = (portfolio: Portfolio, id: number): Portfolio | undefined =>
  findInPortfolioBy(portfolio, (portfolio) => portfolio.id === id);

export const showReadOnlyWarning = (portfolio: Portfolio, isModelPortfolio: boolean): boolean => {
  return !!(portfolio.sourceId || portfolio.remoteId || isModelPortfolio);
};

export const isPortfolioEditable = (portfolio: Portfolio): boolean => {
  return !(portfolio.sourceId || portfolio.remoteId || portfolio.demo);
};

/**
 * Generator function performing a traversal of a Portfolio's nodes, including the root.
 */
export function* traversePortfolio<P extends { children?: P[] }>(portfolio: P | undefined): IterableIterator<P> {
  if (!portfolio) {
    return;
  }

  const childrenStack: P[][] = [[portfolio]];
  while (childrenStack.length > 0) {
    const children = childrenStack.pop()!;
    for (let i = 0; i < children.length; i++) {
      const child = children[i]!;
      yield child;
      const newChildren = child.children;
      if (newChildren) {
        childrenStack.push(newChildren);
      }
    }
  }
}
