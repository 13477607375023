import { useCallback, useContext } from 'react';
import type { Fund, Portfolio } from 'venn-api';
import { getDefaultPortfolioParseResultCategory, remapInvestment } from '../review/helpers';
import { MultiPortfolioReviewContext } from '../components/common/MultiPortfolioReviewContext';
import type { GetRowIdParams } from 'ag-grid-community';
import type { RowData } from '../../types';
import { assertExhaustive } from 'venn-utils';

export const getRowId = (row: GetRowIdParams<RowData<Portfolio>>): string => row.data.path.join('|');

export const useSharedMultiPortfolioRightPaneContent = (portfolio: Portfolio) => {
  const {
    data: { selectedIndex, parsedResults },
    actions: { updatePortfolio, includeInvestment, excludeInvestment },
  } = useContext(MultiPortfolioReviewContext);
  const accessMode = (() => {
    const selectedPortfolio = parsedResults[selectedIndex]!;
    const defaultParseResultCategory = getDefaultPortfolioParseResultCategory(selectedPortfolio);
    switch (defaultParseResultCategory) {
      case 'existing':
        return 'OVERWRITE_EXISTING' as const;
      case 'new':
        return 'CREATE_NEW' as const;
      default:
        return assertExhaustive(defaultParseResultCategory);
    }
  })();
  const applyInvestmentRemap = useCallback(
    (path: number[], fund: Fund) => {
      const newPortfolio = remapInvestment(portfolio, path, fund);
      updatePortfolio(selectedIndex, newPortfolio);
    },
    [updatePortfolio, selectedIndex, portfolio],
  );

  const includeInvestmentInSelectedPortfolio = useCallback(
    (path: number[]) => {
      includeInvestment(selectedIndex, path);
    },
    [includeInvestment, selectedIndex],
  );

  const excludeInvestmentFromSelectedPortfolio = useCallback(
    (path: number[]) => {
      excludeInvestment(selectedIndex, path);
    },
    [excludeInvestment, selectedIndex],
  );

  return {
    accessMode,
    applyInvestmentRemap,
    includeInvestment: includeInvestmentInSelectedPortfolio,
    excludeInvestment: excludeInvestmentFromSelectedPortfolio,
  };
};
