/**
 * @fileoverview This file centralizes i18n functionality such as pluralization.
 * The benefits from this centralization are:
 * - code reuse
 * - easier potential i18n changes in the future
 * - easy bug fixes
 */

/** Mapping from the noun's quantity to a pluralization result.  */
type PluralMap = {
  /** Specific form overrides based on count, often used for the 1-count form. */
  [index: number]: string;
  /** Some languages use a paucal, or 'few', form which is separate from many/other. */
  few?: string;
  /** Some languages have a 'many' plural form. */
  many?: string;
  /** The form given when no other form matches. */
  other: string;
};

/** Global regex for occurrences of the substring '{{count}}'. */
const COUNT_REGEX = /{{count}}/g;

/**
 * Convert a noun to its pluralized form dependent on a relevant count.
 *
 * Uses a {@link PluralMap}} to determine which form to use.
 * Replaces occurrences of the string '{{count}}' within the pluralMap values to the provided count.
 *
 * Examples:
 * - plural(5, {1: '{{count}} object', few: 'a few objects', other: '{{count}} objects'}) returns 'a few objects'
 * - plural(20, {1: '{{count}} object', few: 'a few objects', other: '{{count}} objects'}) returns '20 objects'
 */
export function plural(count: number, pluralMap: PluralMap): string {
  return pluralMapper().replace(COUNT_REGEX, String(count));

  function pluralMapper() {
    const FEW_START = 2;
    const FEW_END = 8;
    const MANY_START = FEW_END;

    if (count in pluralMap) return pluralMap[count]!;
    if (pluralMap.few && count >= FEW_START && count <= FEW_END) return pluralMap.few;
    if (pluralMap.many && count >= MANY_START) return pluralMap.many;
    return pluralMap.other;
  }
}

/**
 * Curried input form of {@link plural} where the pluralMap is provided in the first function invocation
 * and the count is provided in the second function invocation.
 */
export function pluralCurry(pluralMap: PluralMap) {
  return (count: number) => plural(count, pluralMap);
}
