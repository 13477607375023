import type { VennColors } from 'venn-ui-kit';
import { ColorUtils, getColorOnIndex } from 'venn-ui-kit';
import type { HeatMapTypes } from 'venn-components';
import type { SeriesLineOptions } from 'highcharts';
import { type AnyDuringEslintMigration } from 'venn-utils';

// TODO: (VENN-20577 / TYPES) this type doesn't seem to work quite right, because sometimes we use [number, number, number] and not just [number, number]
export type LineChartData = NonNullable<SeriesLineOptions['data']>;

export interface LineChartLegendItem {
  name: string;
  color: string;
  mainSerie: LineChartData;
  benchmark?: LineChartData;
  category?: LineChartData;
  masterPortfolio?: LineChartData;
  seriesTypes: SeriesTypes;
  lineWidth?: number;
  fee?: number;
  feesExcluded?: boolean;
  hasProxy?: boolean;
}

export interface SeriesTypes {
  mainSerieType?: string;
  benchmarkSerieType?: string;
  categorySerieType?: string;
}
interface Series {
  mainSerie?: HeatMapTypes.SeriesEntity;
  benchmarkSerie?: HeatMapTypes.SeriesEntity;
  categorySerie?: HeatMapTypes.SeriesEntity;
  masterPorfolioSerie?: HeatMapTypes.SeriesEntity;
  seriesTypes: SeriesTypes;
}

const getColor = (index: number, colors: VennColors) => {
  const factorsPerColor = 4;
  const colorIndex = Math.floor(index / factorsPerColor);
  return getColorOnIndex(colors, colorIndex);
};

let counter = 0;

const getItemColor = (baseColor: string, itemIndex: number, dataLength: number) => {
  if (counter > 3) {
    counter = 0;
  }
  const opacityValues = [1, 0.75, 0.5, 0.25];
  const opacity = opacityValues[counter]!;
  counter += 1;

  if (itemIndex + 1 === dataLength) {
    counter = 0;
  }
  return ColorUtils.opacify(baseColor, opacity);
};

const getSeries = (series: HeatMapTypes.SeriesEntity[]): Series => {
  const mainSerie = series.find(
    (serie) => serie.portfolioType === 'Current Portfolio' || serie.portfolioType === 'Investment',
  );
  const benchmarkSerie = series.find((serie) => serie.portfolioType === 'Benchmark');
  const categoryOrMasterPortfolioSerie = series.find(
    (serie) => serie.portfolioType === 'Category' || serie.portfolioType === 'Master Portfolio',
  );

  const seriesTypes = {
    mainSerieType: mainSerie?.portfolioType,
    benchmarkSerieType: benchmarkSerie?.portfolioType,
    categorySerieType: categoryOrMasterPortfolioSerie?.portfolioType,
  };

  return { mainSerie, benchmarkSerie, categorySerie: categoryOrMasterPortfolioSerie, seriesTypes };
};

export default (data: HeatMapTypes.Root[], timestamps: number[], colors: VennColors): LineChartLegendItem[] => {
  const results: LineChartLegendItem[] = [];

  data.forEach((item, itemIndex) => {
    const { mainSerie, benchmarkSerie, categorySerie, seriesTypes } = getSeries(item.series);
    // TODO: (VENN-20577 / TYPES) it isn't clear to me which type we should be using given that these tuples are length 3 and don't mesh with the
    // line chart series type from Highcharts
    const parsedData: AnyDuringEslintMigration = mainSerie
      ? mainSerie.data.map((serieData, index) => [timestamps[index], serieData.value, serieData.tValue])
      : [];
    const parsedBenchmark: AnyDuringEslintMigration = benchmarkSerie
      ? benchmarkSerie.data.map((serieData, index) => [timestamps[index], serieData.value, serieData.tValue])
      : undefined;
    const parsedCategory: AnyDuringEslintMigration = categorySerie
      ? categorySerie.data.map((serieData, index) => [timestamps[index], serieData.value, serieData.tValue])
      : undefined;

    const color = getColor(itemIndex, colors);
    const itemColor = getItemColor(color, itemIndex, data.length);

    const legendItem = {
      name: item.name,
      color: itemColor,
      mainSerie: parsedData,
      benchmark: parsedBenchmark,
      category: parsedCategory,
      seriesTypes,
    };

    results.push(legendItem);
  });

  return results;
};
