import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import type {
  Fund,
  InvestmentFactorForecast,
  OptimizedPortfolio,
  Portfolio,
  PortfolioPolicy,
  PortfolioSummary,
} from 'venn-api';
import { checkPortfolioPolicy, forecastInvestmentReturns } from 'venn-api';
import type { Solution } from 'venn-components';
import { PortfolioLabContext } from 'venn-components';
import type { PortfolioNodeTradeStatistic } from '../../logic/tradesUtils';
import { getFullPortfolioTradeStatistics, getTradeStatistics } from '../../logic/tradesUtils';
import { compact, isNil, uniq } from 'lodash';
import OverviewCardView from './OverviewCardView';
import type { ExcelCell, ObjectiveType } from 'venn-utils';
import { analyticsService, flattenNode, logExceptionIntoSentry, useApi } from 'venn-utils';
import type {
  ObjectiveConstraintWithStatus,
  PortfolioConstraintWithStatus,
} from '../../logic/useCombinedExcelExportData';
import { getConstraintsData, getTradesData } from '../../logic/useCombinedExcelExportData';

interface OverviewCardContainerProps {
  selectedSolution: Solution;
  solutionPortfolio: Partial<OptimizedPortfolio> | undefined;
  newOpportunities: Fund[] | undefined;
  newOpportunitiesParentStrategyId: number | undefined;
  onUpdateExportData: (constraintsData: ExcelCell[][] | undefined, tradesData: ExcelCell[][] | undefined) => void;
}

type InvestmentForecastsSet = { [key: string]: InvestmentFactorForecast };

const OverviewCardContainer = ({
  solutionPortfolio,
  selectedSolution,
  newOpportunities,
  newOpportunitiesParentStrategyId,
  onUpdateExportData,
}: OverviewCardContainerProps) => {
  const { portfolio, currentPolicyWithConstraints, objective, objectiveConstraintValue, factorBreakdownData } =
    useContext(PortfolioLabContext);

  const [allocationConstraintsMet, setAllocationConstraintsMet] = useState<number | undefined>();
  const [factorConstraintsMet, setFactorConstraintsMet] = useState<number | undefined>();
  const [loadingPolicyCheck, setLoadingPolicyCheck] = useState(false);
  const checkPolicyApiRef = useRef(useApi(checkPortfolioPolicy));

  const [objectiveMet, setObjectiveMet] = useState<boolean | undefined>();
  const loadingObjectiveCheck = isNil(solutionPortfolio?.summary);

  const [constraintsWithStatus, setConstraintsWithStatus] = useState<PortfolioConstraintWithStatus[] | undefined>();
  const [objectiveWithStatus, setObjectiveWithStatus] = useState<ObjectiveConstraintWithStatus | undefined>();

  const [investmentForecasts, setInvestmentForecasts] = useState<InvestmentForecastsSet | undefined>();
  const [loadingForecasts, setLoadingForecasts] = useState(false);
  const forecastApiRef = useRef(useApi(forecastInvestmentReturns));

  const lastTrackedSolutionRef = useRef<Portfolio | undefined>();
  const trackingPropsRef = useRef({});
  useEffect(() => {
    trackingPropsRef.current = {
      category:
        selectedSolution.category === 'Alternate' && !isNil(selectedSolution.alternateSolutionIdx)
          ? `Alternate [#${selectedSolution.alternateSolutionIdx + 1}]`
          : selectedSolution.category,
      allocationConstraintsMet,
      allocationConstraintsNotMet:
        isNil(currentPolicyWithConstraints) || isNil(allocationConstraintsMet)
          ? undefined
          : currentPolicyWithConstraints.constraints.filter(({ constraintType }) => constraintType === 'ALLOCATION')
              .length - allocationConstraintsMet,
      factorConstraintsMet,
      factorConstraintsNotMet:
        isNil(currentPolicyWithConstraints) || isNil(factorConstraintsMet)
          ? undefined
          : currentPolicyWithConstraints.constraints.filter(({ constraintType }) => constraintType === 'FACTOR')
              .length - factorConstraintsMet,
      objectiveConstraintMet: objectiveMet,
      return: solutionPortfolio?.summary?.annualizedTotalReturn,
      volatility: solutionPortfolio?.summary?.annualizedVolatility,
      volatilityConstraint:
        !isNil(objective) && objective === 'maximizeReturns' && !isNil(objectiveConstraintValue)
          ? objectiveConstraintValue / 100
          : undefined,
      returnConstraint:
        !isNil(objective) && objective !== 'maximizeReturns' && !isNil(objectiveConstraintValue)
          ? objectiveConstraintValue / 100
          : undefined,
      target: objective,
    };
  }, [
    selectedSolution,
    allocationConstraintsMet,
    currentPolicyWithConstraints,
    factorConstraintsMet,
    objectiveMet,
    solutionPortfolio,
    objective,
    objectiveConstraintValue,
  ]);

  useEffect(() => {
    setAllocationConstraintsMet(undefined);
    setFactorConstraintsMet(undefined);
    setObjectiveMet(undefined);
    setInvestmentForecasts(undefined);
  }, [solutionPortfolio]);

  useEffect(() => {
    const checkPolicy = async (
      baselinePortfolio: Portfolio,
      selectedPortfolio: Portfolio,
      portfolioPolicy: PortfolioPolicy,
    ) => {
      setLoadingPolicyCheck(true);
      try {
        const { content } = await checkPolicyApiRef.current({
          baselinePortfolio,
          portfolio: selectedPortfolio,
          portfolioPolicy,
        });
        let allocationCounter = 0;
        let factorCounter = 0;
        for (let i = 0; i < portfolioPolicy.constraints.length; i++) {
          if (portfolioPolicy.constraints[i]!.constraintType === 'ALLOCATION' && content[i]!) {
            allocationCounter++;
          } else if (portfolioPolicy.constraints[i]!.constraintType === 'FACTOR' && content[i]!) {
            factorCounter++;
          }
        }
        setAllocationConstraintsMet(allocationCounter);
        setFactorConstraintsMet(factorCounter);
        setConstraintsWithStatus(
          portfolioPolicy.constraints.map((constraint, i) => ({ ...constraint, met: content[i]! })),
        );

        if (lastTrackedSolutionRef.current !== selectedPortfolio) {
          // Don't track twice for the same solution if there were meaningless changes to baseline portfolio or policy
          // (meaningful changes to baseline/policy will trigger generating a new solution anyway)
          analyticsService.portfolioLabSolutionViewed(trackingPropsRef.current);
          lastTrackedSolutionRef.current = selectedPortfolio;
        }

        setLoadingPolicyCheck(false);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          logExceptionIntoSentry(e);
          setAllocationConstraintsMet(undefined);
          setFactorConstraintsMet(undefined);
          setConstraintsWithStatus(undefined);
          setLoadingPolicyCheck(false);
        }
      }
    };

    if (!isNil(portfolio) && !isNil(solutionPortfolio?.portfolio) && !isNil(currentPolicyWithConstraints)) {
      checkPolicy(portfolio, solutionPortfolio.portfolio, currentPolicyWithConstraints);
    }
  }, [portfolio, solutionPortfolio, currentPolicyWithConstraints]);

  useEffect(() => {
    const checkObjective = (
      selectedPortfolioSummary: PortfolioSummary,
      objectiveType: ObjectiveType,
      objectiveConstraintValue: number,
    ) => {
      const { annualizedVolatility, annualizedTotalReturn } = selectedPortfolioSummary;
      let isMet: boolean | undefined;
      let objectiveName: string | undefined;
      let constraint: string | undefined;
      let actualValue: number | undefined;
      const EPS = 0.0001; // Objective constraint has lesser precision, so the epsilon helps meet it when the difference is minor
      if (objectiveType === 'maximizeReturns' && !isNil(annualizedVolatility)) {
        isMet = annualizedVolatility <= objectiveConstraintValue / 100 + EPS;
        objectiveName = 'maximize returns';
        constraint = 'maximum volatility';
        actualValue = annualizedVolatility;
      } else if (objectiveType !== 'maximizeReturns' && !isNil(annualizedTotalReturn)) {
        isMet = objectiveConstraintValue / 100 - EPS <= annualizedTotalReturn;
        objectiveName = objectiveType === 'targetReturn' ? 'target return' : 'maximize sharpe';
        constraint = 'minimum return';
        actualValue = annualizedTotalReturn;
      } else {
        isMet = undefined;
        objectiveName = undefined;
        constraint = undefined;
        actualValue = undefined;
      }
      setObjectiveMet(isMet);
      setObjectiveWithStatus(
        isNil(isMet) || isNil(objectiveName) || isNil(constraint) || isNil(actualValue)
          ? undefined
          : {
              objective: objectiveName,
              constraint,
              constraintValue: objectiveConstraintValue / 100,
              actualValue,
              met: isMet,
            },
      );
    };
    const summary = solutionPortfolio?.summary;
    if (!isNil(summary) && !isNil(objective) && !isNil(objectiveConstraintValue)) {
      checkObjective(summary, objective, objectiveConstraintValue);
    }
  }, [solutionPortfolio, objective, objectiveConstraintValue]);

  useEffect(() => {
    const loadForecasts = async (baselinePortfolio: Portfolio, selectedPortfolio: Portfolio) => {
      setLoadingForecasts(true);
      try {
        const investments = uniq(
          compact([
            ...flattenNode(baselinePortfolio).map((item) => item.fund?.id),
            ...flattenNode(selectedPortfolio).map((item) => item.fund?.id),
          ]),
        );
        const { content } = await forecastApiRef.current(investments);
        setInvestmentForecasts(content);
        setLoadingForecasts(false);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          logExceptionIntoSentry(e);
          setInvestmentForecasts(undefined);
          setLoadingForecasts(false);
        }
      }
    };

    if (!isNil(portfolio) && !isNil(solutionPortfolio?.portfolio)) {
      loadForecasts(portfolio, solutionPortfolio.portfolio);
    }
  }, [portfolio, solutionPortfolio]);

  const tradeStatistics = useMemo(() => {
    if (isNil(solutionPortfolio?.portfolio) || isNil(portfolio) || isNil(investmentForecasts)) {
      return undefined;
    }
    const statistics = getTradeStatistics(portfolio, solutionPortfolio.portfolio);
    for (let i = 0; i < statistics.investments.length; i++) {
      statistics.investments[i] = {
        ...statistics.investments[i]!,
        forecastReturn: investmentForecasts[statistics.investments[i]!.id]?.annualizedTotalReturn,
      };
    }
    return statistics;
  }, [solutionPortfolio, portfolio, investmentForecasts]);

  const portfolioTradeStatistics: PortfolioNodeTradeStatistic[] | undefined = useMemo(() => {
    if (isNil(solutionPortfolio?.portfolio) || isNil(portfolio) || isNil(investmentForecasts)) {
      return undefined;
    }
    return getFullPortfolioTradeStatistics(solutionPortfolio.portfolio, portfolio, investmentForecasts);
  }, [solutionPortfolio, portfolio, investmentForecasts]);

  const waitingForData = isNil(portfolio) || isNil(solutionPortfolio);

  useEffect(() => {
    const solutionName = `${
      selectedSolution.category === 'Alternate' ? 'Near-Optimal' : selectedSolution.category
    } Portfolio${
      selectedSolution.category === 'Alternate' && !isNil(selectedSolution.alternateSolutionIdx)
        ? ` [#${selectedSolution.alternateSolutionIdx + 1}]`
        : ''
    }`;
    onUpdateExportData(
      getConstraintsData(
        solutionName,
        portfolio?.name ?? '',
        objectiveWithStatus,
        constraintsWithStatus,
        factorBreakdownData,
        newOpportunities ?? [],
        newOpportunitiesParentStrategyId,
      ),
      getTradesData(portfolio, solutionPortfolio?.portfolio, tradeStatistics, solutionName),
    );
  }, [
    tradeStatistics,
    portfolio,
    solutionPortfolio,
    onUpdateExportData,
    selectedSolution,
    objectiveWithStatus,
    constraintsWithStatus,
    factorBreakdownData,
    newOpportunities,
    newOpportunitiesParentStrategyId,
  ]);

  return (
    <OverviewCardView
      portfolioTradeStatistics={portfolioTradeStatistics}
      tradeStatistics={tradeStatistics}
      solutionPortfolio={solutionPortfolio?.portfolio}
      selectedSolution={selectedSolution}
      loading={loadingPolicyCheck || loadingObjectiveCheck || loadingForecasts || waitingForData}
      objectiveMet={objectiveMet}
      allocationConstraintsMet={allocationConstraintsMet}
      factorConstraintsMet={factorConstraintsMet}
    />
  );
};

export default OverviewCardContainer;
