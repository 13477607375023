import { useCallback, useEffect, useMemo, useState } from 'react';
import { v1 as uuid } from 'uuid';
import countBy from 'lodash/countBy';
import { DEFAULT_GROUPINGS, DEFAULT_PORTFOLIO_PREFIX, DEFAULT_RETURN_KEY } from './groupings';
import type {
  AddeparOption,
  AddeparOptionTypeEnum,
  AddeparAttributeValue,
  PortfolioTypeEnum,
  AddeparViewFilter,
} from 'venn-api';
import {
  getAddeparAttributeValues,
  getAddeparOptions,
  getAddeparReturnAttribute,
  getAddeparViewConfigurationList,
  updateAddeparViewConfigurationList,
  setAddeparReturnAttribute,
} from 'venn-api';
import { getAppTitle, Notifications, NotificationType } from 'venn-ui-kit';
import { logExceptionIntoSentry } from 'venn-utils';
import { isEqual } from 'lodash';

export interface ViewConfiguration {
  portfolioId?: number;
  portfolioType?: PortfolioTypeEnum;
  configUuid: string;
  portfolioPrefix: string;
  groupings: string[];
  filters: AddeparViewFilter[];
  includedAttributes: IncludedAttributeOption[];
  lookThroughCompositeSecurities?: boolean;
  currency?: string;
}

interface UseAddeparViewConfiguration {
  firmId: string | undefined;
  onClose(): void;
  refetchClientIntegrations(): void;
}

export interface ViewErrors {
  portfolioPrefix: string | undefined;
  groupings: string | undefined;
  includedAttributes: string | undefined;
}

type InvestmentReturnAttribute = 'twr' | 'custom_return';

export interface AddeparViewConfigurationMainState {
  /** The index of the view configuration currently being edited */
  activeViewIndex: number | undefined;
  /** Update the index of the view configuration currently being edited */
  setActiveViewIndex: (index: number | undefined) => void;
  /** The view configurations */
  views: ViewConfiguration[];
  /** Update the portfolio prefix of the view with the given viewId */
  updatePortfolioPrefix: (viewId: string, updated: string) => void;
  /** The available options for the view groupings */
  groupingOptions: AddeparOption[];
  /** The available options for filters */
  filterOptions: AddeparOption[];
  /** Update the grouping at groupingIndex to updatedKey for the view with the given viewId */
  updateGrouping: (viewId: string, groupingIndex: number, updatedKey: string) => void;
  /** Add a new grouping to the view with the given viewId */
  addGrouping: (viewId: string) => void;
  /** Remove the grouping at groupingIndex for the view with the given viewId */
  removeGrouping: (viewId: string, groupingIndex: number) => void;
  /** Remove the view with the given viewId */
  removeView: (viewId: string) => void;
  /** Add a new view */
  addView: () => void;
  /** The error messages for each view, in the same order as `views` */
  errorMessages: ViewErrors[];
  /** The currently selected Addepar attribute to use as the investment return */
  investmentReturnAttribute: AddeparOption;
  /** Update the selected Addepar attribute to use as the investment return */
  updateInvestmentReturnAttribute: (updated: InvestmentReturnAttribute) => void;
  /** The available custom return option. Undefined if no custom return option is available */
  customReturnOption: AddeparOption | undefined;
  /** Refresh the Addepar attributes */
  refreshOptions: () => Promise<void>;
  /** Whether the options are currently refreshing or not */
  isRefreshing: boolean;
  /** Apply the given selected state to all the attribute options of the view with the given selected values */
  applyToAttributeOptions: (viewId: string, selected: string[]) => void;
  /** Whether the active view's included attributes should be loaded / re-loaded */
  shouldLoadActiveViewIncludedAttrs: boolean;
}

export interface AddeparViewConfigurationState extends AddeparViewConfigurationMainState {
  /** True if the user shouldn't be allowed to proceed, false otherwise */
  disableContinue: boolean;
  /** The error message to display for the panel, if any */
  displayErrorMessage: string | undefined;
  /** Indicate if it's the first time to setup views */
  isFirstTimeSetup: boolean;
  /** Save configurations */
  onFinish(): void;
  /** On attempt to cancel the change */
  onCancel(): void;
  /** Show cancel confirmation modal */
  cancelModalOpen: boolean;
  /** Close the cancel confirmation modal */
  closeCancelModal(): void;
  /** On confirm to reset */
  onReset(): void;
}

export const MIN_GROUPINGS = 2;
export const ERROR_MESSAGES = {
  PORTFOLIO_PREFIX_EMPTY: 'Portfolio name prefixes can not be empty.',
  PORTFOLIO_PREFIX_UNIQUE: 'Portfolio name prefixes must be unique, please choose a different name.',
  LAST_GROUPING_SECURITY: 'The last grouping must be a Security',
  MIN_GROUPINGS: 'Add at least two groupings to continue',
  GENERIC_VIEW_ERROR: 'Please correct all view errors',
  NO_SELECTED_ATTRIBUTES: 'Select at least one attribute to continue',
};

export const DEFAULT_INV_RETURN = {
  key: DEFAULT_RETURN_KEY,
  label: 'Adjusted TWR',
  category: 'Performance Metrics',
};

// 1 stands for "all" in addepar
const DEFAULT_PORTFOLIO_ID = 1;
const DEFAULT_PORTFOLIO_TYPE = 'firm';

// only use VENN_RETURN over CUSTOM_RETURN for backwards compatibility
const VENN_RETURN = 'Venn Return';
const CUSTOM_RETURN = `${getAppTitle()} Return`;

const validateViews = (views: ViewConfiguration[]): ViewErrors[] => {
  const prefixCounts = countBy(views, 'portfolioPrefix');
  return views.map(({ portfolioPrefix, groupings, includedAttributes }) => ({
    portfolioPrefix: !portfolioPrefix
      ? ERROR_MESSAGES.PORTFOLIO_PREFIX_EMPTY
      : prefixCounts[portfolioPrefix]! > 1
        ? ERROR_MESSAGES.PORTFOLIO_PREFIX_UNIQUE
        : undefined,
    groupings: validateGroupings(groupings),
    includedAttributes:
      !includedAttributes.length || includedAttributes.filter((option) => option.selected).length
        ? undefined
        : ERROR_MESSAGES.NO_SELECTED_ATTRIBUTES,
  }));
};

const validateGroupings = (groupings: string[]) => {
  const numGroupings = groupings.length;
  if (numGroupings < MIN_GROUPINGS) return ERROR_MESSAGES.MIN_GROUPINGS;

  const lastGrouping = groupings[numGroupings - 1];
  if (lastGrouping !== 'security') {
    return ERROR_MESSAGES.LAST_GROUPING_SECURITY;
  }
  return undefined;
};

const getErrorMessageToDisplay = (errors: ViewErrors[], activeViewIndex?: number) => {
  if (activeViewIndex !== undefined && errors[activeViewIndex]?.groupings) {
    return errors[activeViewIndex]!.groupings;
  }

  return errors.filter(({ portfolioPrefix, groupings }) => !!(portfolioPrefix || groupings)).length
    ? ERROR_MESSAGES.GENERIC_VIEW_ERROR
    : undefined;
};

const getDefaultViewConfig = (existingViews: ViewConfiguration[], configUuid?: string): ViewConfiguration => {
  const existingCount = existingViews.filter(({ portfolioPrefix }) =>
    portfolioPrefix.startsWith(DEFAULT_PORTFOLIO_PREFIX),
  ).length;
  const portfolioPrefix = existingCount ? `${DEFAULT_PORTFOLIO_PREFIX} ${existingCount + 1}` : DEFAULT_PORTFOLIO_PREFIX;
  return {
    portfolioId: DEFAULT_PORTFOLIO_ID,
    portfolioType: DEFAULT_PORTFOLIO_TYPE,
    configUuid: configUuid ?? uuid(),
    portfolioPrefix,
    groupings: DEFAULT_GROUPINGS,
    includedAttributes: [],
    filters: [],
  };
};

export interface IncludedAttributeOption {
  entityId?: number;
  name: string;
  selected: boolean;
}
export const getValue = (option: IncludedAttributeOption): string => {
  return option.entityId?.toString() ?? option.name;
};

const getAPIAttributeValue = (attr: AddeparAttributeValue): string => {
  return attr.entityId?.toString() ?? attr.name;
};

const useAddeparViewConfiguration = ({
  firmId,
  onClose,
  refetchClientIntegrations,
}: UseAddeparViewConfiguration): AddeparViewConfigurationState => {
  const [activeViewIndex, setActiveViewIndex] = useState<number | undefined>(0);
  const [views, setViews] = useState([getDefaultViewConfig([])]);
  const [savedViews, setSavedViews] = useState<ViewConfiguration[]>([]);
  const [investmentReturnAttribute, setInvestmentReturnAttribute] = useState<AddeparOption>(DEFAULT_INV_RETURN);
  const [savedInvestmentReturnAttribute, setSavedInvestmentReturnAttribute] =
    useState<AddeparOption>(DEFAULT_INV_RETURN);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [groupingOptions, setGroupingOptions] = useState<AddeparOption[]>([]);
  const [filterOptions, setFilterOptions] = useState<AddeparOption[]>([]);
  const [customReturnOption, setCustomReturnOption] = useState<AddeparOption>();
  const [isFirstTimeSetup, setIsFirstTimeSetup] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const applyToAttributeOptions = (viewId: string, selected: string[]) => {
    const selectedSet = new Set(selected);
    setViews((prev) =>
      prev.map((view) =>
        view.configUuid === viewId
          ? {
              ...view,
              includedAttributes: view.includedAttributes.map((option) => ({
                ...option,
                selected: selectedSet.has(getValue(option)),
              })),
            }
          : view,
      ),
    );
  };

  const [shouldLoadActiveViewIncludedAttrs, setShouldLoadActiveViewIncludedAttrs] = useState(false);
  useEffect(() => {
    if (shouldLoadActiveViewIncludedAttrs && activeViewIndex !== undefined) {
      const loadViewIncludedAttributes = async (viewId: string) => {
        const attributeKey = views.find(({ configUuid }) => configUuid === viewId)?.groupings[0];
        if (!firmId || !attributeKey) {
          return;
        }
        const { content } = await getAddeparAttributeValues(firmId, attributeKey);
        setViews((prev) =>
          prev.map((view) =>
            view.configUuid === viewId
              ? {
                  ...view,
                  includedAttributes: content.map(({ entityId, name }) => ({ name, entityId, selected: true })),
                }
              : view,
          ),
        );
        setShouldLoadActiveViewIncludedAttrs(false);
      };
      loadViewIncludedAttributes(views[activeViewIndex]!.configUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadActiveViewIncludedAttrs, activeViewIndex]);

  const fetchOptions = useCallback(
    async (
      optionType: AddeparOptionTypeEnum,
      setState: (options: AddeparOption[]) => void,
      forceRefreshBackend: boolean,
      showError: boolean,
    ) => {
      if (firmId && !isRefreshing) {
        try {
          const options = (await getAddeparOptions(firmId, optionType, forceRefreshBackend)).content;
          setState(options);
        } catch (e) {
          if (e.name !== 'AbortError') {
            if (showError) {
              Notifications.notify(
                'Failed to retrieve Addepar attributes. Try again later.',
                NotificationType.ERROR,
                'top-right',
              );
            }
            logExceptionIntoSentry(e);
          }
        }
      }
    },
    [firmId, isRefreshing],
  );

  const refreshOptions = useCallback(
    async (forceRefreshBackend = true) => {
      setIsRefreshing(true);
      await Promise.all([
        fetchOptions('GROUPING', setGroupingOptions, forceRefreshBackend, true),
        fetchOptions('FILTER', setFilterOptions, forceRefreshBackend, true),
        fetchOptions(
          'RETURN',
          (options: AddeparOption[]) => {
            // hardcode "Venn" in "Venn Return" for backwards compatibility when we do the rebrand
            setCustomReturnOption(
              options.find(
                ({ label }) =>
                  label.toLowerCase() === VENN_RETURN.toLowerCase() ||
                  label.toLowerCase() === CUSTOM_RETURN.toLowerCase(),
              ),
            );
          },
          forceRefreshBackend,
          false,
        ),
      ]);
      setIsRefreshing(false);
    },
    [fetchOptions],
  );

  useEffect(() => {
    if (firmId) {
      refreshOptions(false);
      const fetchInvestmentReturnAttr = async () => {
        try {
          const response = (await getAddeparReturnAttribute(firmId)).content;
          const returnAttribute = response.key ? response : DEFAULT_INV_RETURN;
          setInvestmentReturnAttribute(returnAttribute);
          setSavedInvestmentReturnAttribute(returnAttribute);
        } catch (e) {
          if (e.name !== 'AbortError') {
            logExceptionIntoSentry(e);
          }
        }
      };

      const getAddeparAttributeValuesMap = async (firmId: string, topGroupIds: string[]) => {
        const uniqueTopGroupIds = [...new Set(topGroupIds)];
        const responses = await Promise.all(
          uniqueTopGroupIds.map((groupId) => getAddeparAttributeValues(firmId, groupId)),
        );
        return responses.reduce((prev, current, index) => {
          prev[topGroupIds[index]!] = current.content;
          return prev;
        }, {});
      };

      const initLoadViews = async () => {
        try {
          const response = (await getAddeparViewConfigurationList(firmId)).content;
          if (response.length === 0) {
            setIsFirstTimeSetup(true);
            setShouldLoadActiveViewIncludedAttrs(true);
          } else {
            const addeparAttributeValuesMap = await getAddeparAttributeValuesMap(
              firmId,
              response.map((r) => r.groupingIds[0]!),
            );
            const savedViews = response.map((view) => ({
              portfolioId: view.portfolioId,
              portfolioType: view.portfolioType,
              configUuid: view.viewConfigId ?? uuid(),
              portfolioPrefix: view.viewPrefix,
              groupings: view.groupingIds,
              filters: view.filters ?? [],
              lookThroughCompositeSecurities: view.lookThroughCompositeSecurities,
              currency: view.currency,
              includedAttributes: (addeparAttributeValuesMap[view.groupingIds[0]!] ?? []).map(
                (o: AddeparAttributeValue) => ({
                  entityId: o.entityId,
                  name: o.name,
                  // rootEntityIds is undefined represent for select all
                  selected: !view.rootEntityIds
                    ? true
                    : !!view.rootEntityIds.find((id) => id === getAPIAttributeValue(o)),
                }),
              ),
            }));
            setSavedViews(savedViews);
            setViews(savedViews);
          }
        } catch (e) {
          if (e.name !== 'AbortError') {
            logExceptionIntoSentry(e);
          }
        }
      };

      fetchInvestmentReturnAttr();
      initLoadViews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmId]);

  const updateInvestmentReturnAttribute = (updated: InvestmentReturnAttribute) => {
    if (updated === 'custom_return' && !customReturnOption) {
      return;
    }
    const option = updated === 'custom_return' ? customReturnOption! : DEFAULT_INV_RETURN;
    setInvestmentReturnAttribute(option);
  };

  const updatePortfolioPrefix = (viewId: string, updated: string) => {
    setViews((prev) => prev.map((view) => (view.configUuid === viewId ? { ...view, portfolioPrefix: updated } : view)));
  };

  const updateGrouping = useCallback((viewId: string, groupingIndex: number, updated: string) => {
    setViews((prev) =>
      prev.map((view) =>
        view.configUuid === viewId
          ? { ...view, groupings: view.groupings.map((g, i) => (groupingIndex === i ? updated : g)) }
          : view,
      ),
    );
    if (groupingIndex === 0) {
      setShouldLoadActiveViewIncludedAttrs(true);
    }
  }, []);

  const addGrouping = (viewId: string) => {
    setViews((prev) =>
      prev.map((view) => {
        const lastGroupingIndex = view.groupings.length - 1;
        return view.configUuid === viewId
          ? {
              ...view,
              groupings: [...view.groupings.slice(0, lastGroupingIndex), '', view.groupings[lastGroupingIndex]!],
            }
          : view;
      }),
    );
  };

  const removeGrouping = (viewId: string, groupingIndex: number) => {
    setViews((prev) =>
      prev.map((view) =>
        view.configUuid === viewId
          ? { ...view, groupings: view.groupings.filter((_, i) => groupingIndex !== i) }
          : view,
      ),
    );
    if (groupingIndex === 0) {
      setShouldLoadActiveViewIncludedAttrs(true);
    }
  };

  const addView = () => {
    const viewId = uuid();
    setViews((prev) => [...prev, getDefaultViewConfig(prev, viewId)]);
    setActiveViewIndex(views.length);
    setShouldLoadActiveViewIncludedAttrs(true);
  };

  const removeView = (viewId: string) => {
    if (views.length <= 1) {
      return;
    }

    const indexToRemove = views.findIndex((view) => view.configUuid === viewId);
    setViews((prev) => prev.filter((view) => view.configUuid !== viewId));
    // If only two views left, we would only has 1 view after deletion
    if (views.length === 2) {
      setActiveViewIndex(0);
    } else if (indexToRemove === activeViewIndex) {
      setActiveViewIndex(undefined);
    } else {
      setActiveViewIndex((prev) =>
        prev === undefined ? undefined : prev > indexToRemove ? prev - 1 : Math.min(prev, views.length - 2),
      );
    }
  };

  const onReset = useCallback(() => {
    onClose();
    setSavedViews([]);
    setViews([getDefaultViewConfig([])]);
    setInvestmentReturnAttribute(DEFAULT_INV_RETURN);
    setSavedInvestmentReturnAttribute(DEFAULT_INV_RETURN);
    setIsFirstTimeSetup(false);
    setActiveViewIndex(0);
    setGroupingOptions([]);
    setFilterOptions([]);
    setCustomReturnOption(undefined);
    setCancelModalOpen(false);
  }, [onClose]);

  const onFinish = async () => {
    if (!firmId) {
      return;
    }

    const savedViews = views.map((v) => {
      let rootEntityIds: string[] | undefined = v.includedAttributes.filter((a) => a.selected).map(getValue);
      // If all items selected, clear them to assume backend always fetch all entities even there are new ones
      if (rootEntityIds.length === v.includedAttributes.length) {
        rootEntityIds = undefined;
      }

      return {
        portfolioId: v.portfolioId,
        portfolioType: v.portfolioType,
        configUuid: v.configUuid,
        filters: v.filters.map((f) => ({ ...f, updated: undefined })), // Updated field is handled by BE
        lookThroughCompositeSecurities: v.lookThroughCompositeSecurities,
        currency: v.currency,
        firmId,
        groupingIds: v.groupings.filter((v) => !!v), // remove empty string if any
        rootEntityIds,
        viewPrefix: v.portfolioPrefix,
      };
    });

    try {
      await Promise.all([
        setAddeparReturnAttribute(firmId, { key: investmentReturnAttribute.key }),
        updateAddeparViewConfigurationList(firmId, savedViews),
      ]);
      onReset();
      refetchClientIntegrations();
    } catch (e) {
      Notifications.notify('Unable to save. Please try again later.', NotificationType.ERROR);
      logExceptionIntoSentry(e as Error);
    }
  };

  const errorMessages = useMemo(() => validateViews(views), [views]);
  const disableContinue = useMemo(
    () =>
      !!errorMessages.filter(
        ({ portfolioPrefix, groupings, includedAttributes }) => !!(portfolioPrefix || groupings || includedAttributes),
      ).length,
    [errorMessages],
  );
  const displayErrorMessage = useMemo(
    () => getErrorMessageToDisplay(errorMessages, activeViewIndex),
    [errorMessages, activeViewIndex],
  );

  const hasChanged = useMemo(
    () => !isEqual(savedViews, views) || !isEqual(savedInvestmentReturnAttribute, investmentReturnAttribute),
    [savedViews, views, savedInvestmentReturnAttribute, investmentReturnAttribute],
  );

  const onCancel = useCallback(() => {
    if (hasChanged) {
      setCancelModalOpen(true);
    } else {
      onReset();
    }
  }, [hasChanged, onReset]);

  return {
    updatePortfolioPrefix,
    groupingOptions,
    filterOptions,
    activeViewIndex,
    setActiveViewIndex,
    views,
    removeView,
    updateGrouping,
    addGrouping,
    removeGrouping,
    addView,
    errorMessages,
    disableContinue,
    displayErrorMessage,
    investmentReturnAttribute,
    updateInvestmentReturnAttribute,
    customReturnOption,
    refreshOptions: () => refreshOptions(true),
    isRefreshing,
    applyToAttributeOptions,
    shouldLoadActiveViewIncludedAttrs,
    isFirstTimeSetup,
    onFinish,
    cancelModalOpen,
    onCancel,
    onReset,
    closeCancelModal: () => setCancelModalOpen(false),
  };
};

export default useAddeparViewConfiguration;
