import { usePrivateAnalysis } from '../../../studio-blocks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockLimitedRequestSubjects, blockSettings, hasVennDataModifications } from 'venn-state';
import { type DataModificationsCategory } from '../DataModificationsModal';

import type { MessageWithContext, PrivateAnalysisError } from 'venn-api';
import flatMap from 'lodash/flatMap';
import { compact, groupBy, isEmpty, isNil } from 'lodash';
import { formatExportableSubjectWithOptionalFee } from '../../../legend';
import { useEffect } from 'react';
import { useAnalysis } from '../../../studio-blocks/logic/useAnalysis';
import { isPublicPrivateAssetGrowthBlock } from 'venn-utils';

const DATA_MODIFICATIONS_APPLIED_KEY = 'dataModificationsApplied';
const parseWarningsFromPrivateAnalysisResponse = (allErrors: { [key: string]: PrivateAnalysisError[] }[]) => {
  const warningsForEachSubject = allErrors.map((subjectErrors) => {
    if (isNil(subjectErrors)) {
      return {};
    }
    const warnings = flatMap(Object.values(subjectErrors)).filter(
      (error) => error.severity === 'WARNING' && !!error.fundInfo,
    );
    return groupBy(warnings, 'message');
  });

  return warningsForEachSubject;
};

const parseWarningsFromAnalysisResponse = (
  allMessagesWithContext: { [key: string]: MessageWithContext[] } | undefined,
) => {
  const dataModificationsApplied = (allMessagesWithContext?.[DATA_MODIFICATIONS_APPLIED_KEY] ?? []).filter(
    (message) => message.message.type === 'WARN',
  );

  return groupBy(dataModificationsApplied, 'message.text');
};

const HARDCODED_COPIES = {
  MISSING_DATA: {
    title: 'Missing Data',
    message:
      'Due to missing data, the historical NAVs of the funds listed below have been forward filled for more than 4 quarters.',
    footerMessage: 'Please confirm the available historical NAV data in each fund’s Manage Data page.',
  },
  OUTDATED_PIC: {
    title: 'Outdated Paid in Capital',
    message:
      'The last available Paid in Capital of the funds listed below are more than 4 quarters before the projection start date.',
    footerMessage:
      'Please confirm the available historical cash flow or performance data in each fund’s Manage Data page.',
  },
  OUTDATED_CUMULATIVE: {
    title: 'Outdated Cumulative Distribution',
    message:
      'The last available Cumulative Distribution of the funds listed below are more than 4 quarters before the projection start date.',
    footerMessage:
      'Please confirm the available historical cash flow or performance data in each fund’s Manage Data page.',
  },
};

// Deduplicate modifications such that each category only appears once
const deduplicate = (modifications: DataModificationsCategory[]) => {
  return Object.values(groupBy(modifications, 'title')).map((singleCategory) => {
    return {
      title: singleCategory[0]!.title,
      message: singleCategory[0]!.message,
      footerMessage: singleCategory[0]!.footerMessage,
      entities: flatMap(singleCategory.map((category) => category.entities)),
    };
  });
};

export const usePrivateAnalysisBlockDataModifications = (blockId: string) => {
  const { data } = usePrivateAnalysis(blockId);
  const blockType = useRecoilValue(blockSettings(blockId)).customBlockType;
  const [hasDataModifications, setHasDataModifications] = useRecoilState(hasVennDataModifications(blockId));
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const subjectNames = subjects.map((subject) => formatExportableSubjectWithOptionalFee(subject));

  const warnings =
    blockType !== 'PRIVATE_CASH_FLOW' || !data
      ? []
      : parseWarningsFromPrivateAnalysisResponse(
          data.cashFlows
            .map((cashflowResponse) => cashflowResponse.errors)
            .slice(0, infoGraphicType === 'PRIVATE_CASH_FLOW' ? 1 : data.cashFlows.length),
        );

  const modifications: DataModificationsCategory[] = compact(
    flatMap(
      warnings.map((warningDictionary, subjectIndex) => {
        return Object.entries(warningDictionary).map((entry) => {
          const [key, errorsList] = entry;
          const text = HARDCODED_COPIES[key];
          if (isNil(text) || isEmpty(errorsList)) {
            return null;
          }
          return {
            title: text.title,
            message: text.message,
            entities: errorsList.map((error) => {
              return `${subjects[subjectIndex]!.subjectType === 'PORTFOLIO' ? `${subjectNames[subjectIndex]!} - ` : ''}${error.fundInfo?.fundName}`;
            }),
            footerMessage: text.footerMessage,
          };
        });
      }),
    ),
  );

  const deduplicatedModifications: DataModificationsCategory[] = deduplicate(modifications);
  const hasNewDataModifications = !isEmpty(deduplicatedModifications);

  useEffect(() => {
    if (hasNewDataModifications !== hasDataModifications) {
      // persist changes to state
      setHasDataModifications((_) => hasNewDataModifications);
    }
  }, [hasDataModifications, setHasDataModifications, hasNewDataModifications]);

  return {
    modifications: deduplicatedModifications,
  };
};

export const useAnalysisBlockDataModifications = (blockId: string) => {
  const { analyses } = useAnalysis(blockId);
  const blockType = useRecoilValue(blockSettings(blockId)).customBlockType;

  const messagesWithContext = isPublicPrivateAssetGrowthBlock(blockType)
    ? analyses?.[0]?.[0]?.messagesWithContext
    : undefined;
  const [hasDataModifications, setHasDataModifications] = useRecoilState(hasVennDataModifications(blockId));

  const privateSubjects = useRecoilValue(blockLimitedRequestSubjects(blockId)).filter((subject) => subject.private);

  const warningDictionary = parseWarningsFromAnalysisResponse(messagesWithContext);

  const modifications: DataModificationsCategory[] = compact(
    Object.entries(warningDictionary).map((entry) => {
      const [key, errorsList] = entry;
      const text = HARDCODED_COPIES[key];
      if (isNil(text) || isEmpty(errorsList)) {
        return null;
      }
      return {
        title: text.title,
        message: text.message,
        entities: errorsList.map((error) => {
          return `${privateSubjects[0]!.subjectType === 'PORTFOLIO' ? `${formatExportableSubjectWithOptionalFee(privateSubjects[0]!)} - ` : ''}${error.subjectName}`;
        }),
        footerMessage: text.footerMessage,
      };
    }),
  );

  // Deduplicate modifications such that each category only appears once
  const deduplicatedModifications = deduplicate(modifications);

  const hasNewDataModifications = !isEmpty(deduplicatedModifications);
  useEffect(() => {
    if (hasNewDataModifications !== hasDataModifications) {
      // persist changes to state
      setHasDataModifications((_) => hasNewDataModifications);
    }
  }, [hasDataModifications, setHasDataModifications, hasNewDataModifications]);

  return {
    modifications: deduplicatedModifications,
  };
};
