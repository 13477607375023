import React, { type KeyboardEvent, useCallback } from 'react';
import { GetColor, KeyCodes, SelectedItemPill } from 'venn-ui-kit';
import styled from 'styled-components';
import useSearch from './useSearch';
import type { SearchBarProps } from './SearchBar';
import { Input, SearchIcon } from './SearchBar';

interface Item {
  label: string;
  value: string;
}

interface MultiSearchBarProps extends SearchBarProps {
  onDelete: (value: string) => void;
  selectedItems: Item[];
  /** input ref */
  outerRef?: React.RefObject<HTMLInputElement>;
}

const MultiSearchBar = ({
  disableAutofocus,
  debounce,
  value = '',
  onChange,
  onFocus,
  placeholder,
  selectedItems,
  onDelete,
  outerRef,
}: MultiSearchBarProps) => {
  const { inputRef, searchString, onInputChange, clearSearchQuery } = useSearch(
    value,
    onChange,
    disableAutofocus,
    debounce,
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === KeyCodes.Delete || event.keyCode === KeyCodes.Backspace) {
        if (selectedItems.length > 0 && !searchString) {
          onDelete(selectedItems[selectedItems.length - 1]!.value);
        }
      }
    },
    [onDelete, selectedItems, searchString],
  );

  return (
    <Container>
      {selectedItems.map((item) => (
        <StyledSelectedItem
          data-testid="qa-selected-item"
          label={item.label}
          value={item.value}
          key={item.value}
          // @ts-expect-error: TODO fix strictFunctionTypes
          onRemoveItem={onDelete}
        />
      ))}
      <StyledInput
        ref={outerRef || inputRef}
        type="search"
        placeholder={placeholder}
        value={searchString}
        className="qa-search-bar"
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={clearSearchQuery}
        onKeyDown={handleKeyDown}
        data-testid="qa-multi-search"
      />
      {searchString && <SearchIcon type="times-circle" onClick={clearSearchQuery} prefix="fas" />}
      {!searchString && <SearchIcon type="search" prefix="fas" />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  background-color: ${GetColor.White};
  border-radius: 4px;
  border: 1px solid ${GetColor.Grey};
  min-height: 37px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 10px 10px 0px;
  text-align: left;
  flex-wrap: wrap;
  i {
    top: 20px;
  }
  &:focus-within {
    border: 1px solid ${GetColor.Primary.Main};
  }
`;

const StyledInput = styled(Input)`
  flex: 1;
  flex-grow: 1;
  min-width: 175px;
  border: none;
`;

const StyledSelectedItem = styled(SelectedItemPill)`
  height: 24px;
  margin: 6px 5px 0;
`;

export default MultiSearchBar;
