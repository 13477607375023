import { HighchartZIndex } from 'venn-ui-kit';

/** heatmap coordinates, according to highcharts:
 *               x
 *    ___|_0_|_1_|_2_|_3_|_4_
 *     4 |
 *     3 |
 *  y  2 |
 *     1 |
 *     0 |
 */

/*
 * Function to retrieve cell dimensions from a Highchart object.
 * Checks if chart is inverted and derives each dimension from
 * the opposing axis ticks if true.
 *
 * @param chart                         Highchart object
 * @return { cellHeight, cellWidth }    Object with cell height/width values
 */
export function getCellDims(chart: Highcharts.Chart) {
  const { plotHeight, plotWidth, inverted } = chart;
  const xAxis = chart.xAxis[0]!;
  const yAxis = chart.yAxis[0]!;

  const cellWidth = plotWidth / (Object.keys(inverted ? yAxis.ticks : xAxis.ticks).length - 1);
  const cellHeight = plotHeight / (Object.keys(inverted ? xAxis.ticks : yAxis.ticks).length - 1);

  return { cellHeight, cellWidth };
}

/**
 * Draws plot lines per bar in a bar series, each being independent of
 * the other.
 * Use only for bar series type.
 *
 * @param chart      Highchart object
 * @param data       Array of numbers, should start with top bar plotline
 *                   and end with bottom bar
 * @param lineStyles  Object of styles for plotline
 */
export function drawPlotLinePerBar(chart: Highcharts.Chart, data: number[]) {
  const { plotLeft, plotTop, plotWidth, renderer } = chart;
  const { cellHeight, cellWidth } = getCellDims(chart);
  const yAxis = chart.yAxis[0]!;
  const yAxisValues = Object.keys(yAxis.ticks)
    .map((val) => Number(val))
    .sort((a, b) => (a > b ? -1 : 1));

  if (yAxis.barPlotLines) {
    yAxis.barPlotLines.forEach((svg) => svg.destroy());
  }
  yAxis.barPlotLines = data.map((dataItem, i) => {
    const top = cellHeight * i + plotTop;
    const median = yAxisValues[Math.floor(yAxisValues.length / 2)]!;
    const center =
      plotLeft +
      plotWidth / 2 -
      cellWidth * (median * (1 / yAxis.tickInterval)) -
      ((yAxisValues.length - 1) % 2 ? cellWidth * 0.5 : 0);
    const left = center + cellWidth * (1 / yAxis.tickInterval) * (dataItem * 100);
    const svg = renderer
      .path(['M', left, top, 'L', left, top + cellHeight + 2])
      .attr({
        'stroke-dasharray': '2,2',
        'stroke-width': 2,
        stroke: 'black',
        zIndex: HighchartZIndex.PlotBand.AboveSeries,
      })
      .add();
    return svg;
  });
}
