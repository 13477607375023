import React, { useCallback, useMemo } from 'react';
import BenchmarkDropMenu from './BenchmarkDropmenu';
import type { Fund, ItemId, Portfolio, PortfolioCompare } from 'venn-api';
import { AnalysisSubject } from 'venn-utils';
import type { SearchMenuItem } from 'venn-components';

const compareToAnalysisSubject = (compare: PortfolioCompare) => {
  const isPortfolio = compare?.type === 'PORTFOLIO';
  const subject = isPortfolio
    ? ({ id: compare?.portfolioId, name: compare.name } as Portfolio)
    : ({
        id: compare?.fundId,
        name: compare.name,
        assetType: compare.type === 'COMPOSITE' ? 'BENCHMARK' : undefined,
      } as Fund);
  return new AnalysisSubject(subject, isPortfolio ? 'portfolio' : 'investment');
};

interface ComparisonDropMenuProps {
  benchmarks?: PortfolioCompare[];
  disabled?: boolean;
  disabledTooltipMessage?: string;
  onSelectBenchmark: (benchmark: PortfolioCompare) => void;
  onClearBenchmark: () => void;
  resultsFilter: (item: SearchMenuItem) => boolean;
  investmentsOnly?: boolean;
  loading?: boolean;
}

const ComparisonDropMenu = ({
  benchmarks,
  disabled,
  disabledTooltipMessage,
  onClearBenchmark,
  onSelectBenchmark,
  resultsFilter,
  investmentsOnly,
  loading,
}: ComparisonDropMenuProps) => {
  const active = useMemo(() => benchmarks?.find(({ benchmark }) => benchmark), [benchmarks]);
  const value = useMemo(() => active && compareToAnalysisSubject(active), [active]);

  const benchmarksIds = useMemo(
    () =>
      benchmarks?.length
        ? benchmarks.map(
            (benchmark): ItemId => ({
              id: (benchmark.fundId ?? benchmark.portfolioId?.toString())!,
              type: benchmark.type === 'PORTFOLIO' ? benchmark.type : 'FUND',
            }),
          )
        : undefined,
    [benchmarks],
  );

  const menuOnSelectBenchmark = useCallback(
    (benchmark: AnalysisSubject) => {
      onSelectBenchmark({
        benchmark: true,
        fundId: benchmark.fund?.id,
        portfolioId: benchmark.portfolio?.id,
        historical: !!benchmark.portfolio?.historical,
        name: benchmark.name,
        primary: true,
        type:
          benchmark.type === 'portfolio'
            ? 'PORTFOLIO'
            : benchmark.fund?.assetType === 'BENCHMARK'
              ? 'COMPOSITE'
              : 'INVESTMENT',
      });
    },
    [onSelectBenchmark],
  );

  return (
    <BenchmarkDropMenu
      benchmarks={benchmarksIds}
      disabled={disabled}
      disabledTooltipMessage={disabledTooltipMessage}
      onSelectBenchmark={menuOnSelectBenchmark}
      onClearBenchmark={onClearBenchmark}
      resultsFilter={resultsFilter}
      investmentsOnly={investmentsOnly}
      value={value}
      loading={loading}
    />
  );
};

export default ComparisonDropMenu;
