import { isEmpty, isNil } from 'lodash';
import type { RefObject } from 'react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockMetrics, blockRequestSubjects, blockSettings } from 'venn-state';
import { GridWrapper } from './common';
import CashFlowChart from './components/charts/CashFlowChart';
import { PrivateAssetPerformanceChart } from './components/charts/PrivateAssetPerformanceChart';
import PrivateCashFlowGrid from './components/grid/PrivateCashFlowGrid';
import { PrivatePerformanceGrid } from './components/grid/PrivatePerformanceGrid';
import { PrivatePerformanceSummaryGrid } from './components/grid/PrivatePerformanceSummaryGrid';
import PrivatesErrorMessages, { type PrivateAnalysisSubjectError } from './components/PrivatesErrorMessages';
import StyledEmptyState from './components/StyledEmptyState';
import { useBlockId } from './contexts/BlockIdContext';
import { usePrivateAnalysis } from './logic/usePrivateAnalysis';
import { usePrivatesBenchmarkMetricError } from './logic/usePrivatesBenchmarkMetricError';
import { useSubjectColors } from './logic/useSubjectColors';
import { getAnalyzedSubjectFromRequestSubject, isPrivatesPerformanceBlock } from 'venn-utils';
import { PrivatesSubjectErrors } from './components/PrivatesSubjectErrors';
import { useSelectedMetrics } from './logic/useSelectedMetrics';
import { getAnalysisMetricLevelErrorsAndWarnings } from './logic/privatesUtils';

type PrivatesAnalysisBlockProps = Readonly<{ isReport?: boolean; downloadableContentRef?: RefObject<HTMLDivElement> }>;

const PrivatesAnalysisBlock = ({ isReport, downloadableContentRef }: PrivatesAnalysisBlockProps) => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const selectedMetricsFull = useSelectedMetrics();

  const { data, isExportable } = usePrivateAnalysis(blockId);

  const subjectColors = useSubjectColors(subjects.map(getAnalyzedSubjectFromRequestSubject));

  usePrivatesBenchmarkMetricError();

  if (subjects.length === 0) {
    return (
      <StyledEmptyState
        header="Please select a Private Portfolio or Private Investment to analyze"
        selectedRefId={blockId}
      />
    );
  }

  if (selectedMetrics.length === 0) {
    return <StyledEmptyState header="Please select at least one metric to analyze" selectedRefId={blockId} />;
  }

  if (
    selectedMetrics.length !== 1 &&
    customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES' &&
    infoGraphicType !== 'GRID'
  ) {
    return <StyledEmptyState header="Please select only one metric to analyze" selectedRefId={blockId} />;
  }
  if (isNil(data)) {
    return <StyledEmptyState header="Unknown error occurred. Please contact Venn Support" selectedRefId={blockId} />;
  }

  // detect critical errors in a cash flow chart
  // this uses legacy error handling, ultimately we'd like to deprecate .error and .errorMessages
  // only using the improved error handling using metric-specific and severity-sensitive "errors" dictionary
  if (customBlockType === 'PRIVATE_CASH_FLOW') {
    const firstSubjectErrorMessages = data?.cashFlows?.[0]?.errorMessages;
    const firstSubjectErrorMessage = data?.cashFlows?.[0]?.error;

    if (subjects.length === 1 || infoGraphicType === 'PRIVATE_CASH_FLOW') {
      if (!isEmpty(firstSubjectErrorMessages)) {
        return <PrivatesErrorMessages errorMessages={firstSubjectErrorMessages} />;
      }

      if (firstSubjectErrorMessage) {
        return <StyledEmptyState header={firstSubjectErrorMessage} selectedRefId={blockId} />;
      }
    }
  }

  // detect block-level critical subject errors in a performance block and report them
  if (isPrivatesPerformanceBlock(customBlockType)) {
    const tsResponse =
      customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES'
        ? (data?.performanceTimeSeries ?? [])
        : (data?.performanceSummary ?? []);
    const blockLevelErrors = tsResponse.flatMap((response, index) => {
      const blockLevelError = response.errors?.blockLevel;
      return (blockLevelError ?? [])
        .filter((error) => error.severity === 'ERROR')
        .map((error) => {
          return {
            subject: subjects[index]!.name,
            error,
          } as PrivateAnalysisSubjectError;
        });
    });

    if (!isEmpty(blockLevelErrors)) {
      return <PrivatesSubjectErrors errors={blockLevelErrors} />;
    }
  }

  // errors for cash flow pacing OR time series block and chart formats supporting only one metric
  if (
    (customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES' || customBlockType === 'PRIVATE_CASH_FLOW') &&
    infoGraphicType !== 'GRID'
  ) {
    const metricNames =
      customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES'
        ? [selectedMetrics[0]]
        : selectedMetricsFull.map((metric) => metric.analysisResultKey);

    const selectedData =
      (customBlockType === 'PRIVATE_CASH_FLOW' ? data?.cashFlows : data?.performanceTimeSeries) ?? [];
    const metricLevelErrors = getAnalysisMetricLevelErrorsAndWarnings(
      selectedData,
      metricNames,
      subjects,
      customBlockType === 'PRIVATE_CASH_FLOW' ? 0 : undefined,
    );

    if (!isEmpty(metricLevelErrors)) {
      return <PrivatesSubjectErrors errors={metricLevelErrors} />;
    }
  }

  if (customBlockType === 'PRIVATE_PERFORMANCE_SUMMARY') {
    return (
      <GridWrapper isReport={isReport}>
        <PrivatePerformanceSummaryGrid data={data} isExportable={isExportable} />
      </GridWrapper>
    );
  }

  if (customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES') {
    if (infoGraphicType === 'GRID') {
      return (
        <GridWrapper isReport={isReport}>
          <PrivatePerformanceGrid data={data} isExportable={isExportable} />
        </GridWrapper>
      );
    }
    // line or bar chart
    return (
      <PrivateAssetPerformanceChart
        data={data}
        downloadableContentRef={downloadableContentRef}
        isExportable={isExportable}
        subjectColors={subjectColors}
      />
    );
  }

  if (infoGraphicType === 'PRIVATE_CASH_FLOW') {
    return <CashFlowChart data={data} isExportable={isExportable} downloadableContentRef={downloadableContentRef} />;
  }

  if (infoGraphicType === 'GRID') {
    return (
      <GridWrapper isReport={isReport}>
        <PrivateCashFlowGrid data={data} isExportable={isExportable} />
      </GridWrapper>
    );
  }

  return null;
};
export default PrivatesAnalysisBlock;
