import type {
  AxisLabelsFormatterContextObject,
  AxisTitleAlignValue,
  AxisTypeValue,
  BoxPlotPoint,
  Chart,
  DashStyleValue,
  Options as HighchartOptions,
  Options,
  PlotLineOrBand,
  SeriesMapDataOptions,
  SeriesPieDataOptions,
  TooltipFormatterContextObject,
  XAxisOptions,
} from 'highcharts';
import { dateFormat, numberFormat } from 'highcharts';
import { compact, get, maxBy, merge, min, minBy, noop, round } from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import type { FrequencyEnum } from 'venn-api';
import type { Schemes, Theme, Typography, VennColors } from 'venn-ui-kit';
import {
  ColorUtils,
  getTextThemeProvider,
  HighchartZIndex,
  Icon,
  REPORT_LAB_FONT_BODY,
  Tooltip,
  TooltipPosition,
  VENNCAST_FAQ_HREF,
} from 'venn-ui-kit';
import { type AnyDuringEslintMigration, assertNotNil, Dates, formatAllocation, Numbers } from 'venn-utils';
import type { ScatterSeries } from '../../studio-blocks';
import { WORLD_MAP_COUNTRIES } from '../../studio-blocks/components/charts/map-chart/mapTopology';
import type { VennSeriesPieDataOptions } from '../../studio-blocks/logic/pieChartParsers';
import { default as HighchartsUtils } from '../../utils/highcharts';

import type {
  FullPeriodValue,
  HighchartChartType,
  PartialHighchartSerieData,
  SerieValue,
  UnitFormat,
} from '../analysis-charts/types';

export const getTooltipBackgroundColor = (colors: VennColors) => ColorUtils.hex2rgba(colors.Black, 0.9);

export const calculateLegendPadding = (legendFontSize: number) => 5 + 0.4 * legendFontSize;

export interface VennLineChartData {
  start: number;
  end: number;
  visibleVenncastStart?: number;
  visibleVenncastEnd?: number;
  series?: PartialHighchartSerieData[];
  estimated?: boolean;
  horizontalLine?: number;
  persistentBottomBar?: boolean;
}

const isEstimatedSeriesName = (seriesName: string) => seriesName === 'Estimated' || seriesName.includes('- Estimated');
const isErrorRangeSeriesName = (seriesName: string) =>
  seriesName === 'Error range' || seriesName.includes('- Error range');

const getTooltipItem = (
  point: TooltipFormatterContextObject,
  series: TooltipFormatterContextObject['series'],
  range: { y: number } | undefined,
  yAxisUnitFormat: UnitFormat = 'percent',
  currencySymbol?: string,
  customNumberFormater?: (value?: number | string | null, precision?: number) => string,
) => {
  const format = customNumberFormater ?? Numbers.safeFormatNumber;
  if (isEstimatedSeriesName(series.name) && !!range) {
    const errorDif = Math.abs(point.y - range.y);
    return `
      <br/>
      <span style='color:${point.color}'>\u25CF</span> ${series.name}
      : <b>${format(toPercentage(point.y))}%</b>
      <span>+/-${format(toPercentage(errorDif))}%</span>
    `;
  }
  if (yAxisUnitFormat === 'allocation') {
    return `<br/><span style='color:${point.color}'>\u25CF</span> ${series.name}
    : <b>${formatAllocation(point.y, true, false, undefined, false, 1)}</b>`;
  }
  if (currencySymbol) {
    return `<br/><span style='color:${point.color}'>\u25CF</span> ${series.name}
    : <b>${currencySymbol + format(point.y)}</b>`;
  }
  if (yAxisUnitFormat === 'ratio') {
    return `<br/><span style='color:${point.color}'>\u25CF</span> ${series.name}
    : <b>${format(point.y)}</b>`;
  }

  const isPercentageStackedAreaChart =
    series.type === 'area' && series.chart.options.plotOptions?.area?.stacking === 'percent';

  const value = isPercentageStackedAreaChart ? point.percentage : toPercentage(point.y);

  return `<br/><span style='color:${point.color}'>\u25CF</span> ${series.name}
  : <b>${format(value)}%</b>`;
};

export const getCustomTooltip = (
  yAxisUnitFormat: UnitFormat = 'percent',
  frequency: FrequencyEnum = 'MONTHLY',
  colors: VennColors,
  currencySymbol: string | undefined,
  customNumberFormater?: (value?: number | string | null, precision?: number) => string,
) => ({
  backgroundColor: getTooltipBackgroundColor(colors),
  borderRadius: 10,
  borderWidth: 0,
  valueDecimals: 2,
  shared: true,
  outside: false,
  useHTML: true,
  style: {
    color: colors.White,
    fontSize: REPORT_LAB_FONT_BODY,
  },
  formatter(this: TooltipFormatterContextObject) {
    if (!this || !this.points) {
      return false;
    }
    const estimatedRange = this.points.find((point: unknown) =>
      isErrorRangeSeriesName(get(point, 'series.name') ?? ''),
    );
    const tooltipFormat =
      frequency === 'DAILY' || estimatedRange ? 'D MMM YYYY' : frequency === 'QUARTERLY' ? '[Q]Q YYYY' : 'MMMM YYYY';

    // Quarterly string can be used directly for category
    let tooltipStr =
      frequency === 'QUARTERLY' && typeof this.x === 'string'
        ? `<div style="margin-bottom: -10px">${this.x}</div>`
        : `<div style="margin-bottom: -10px">${moment.utc(this.x).format(tooltipFormat)}</div>`;

    const pointsForTooltip = this.points
      .filter((point) => !isErrorRangeSeriesName(get(point, 'series.name')))
      .filter((point) => !point.series.userOptions?.hideFromSharedTooltip);
    return (tooltipStr += pointsForTooltip
      .map((point) => {
        let toolTipItem = getTooltipItem(
          point,
          point.series,
          estimatedRange,
          yAxisUnitFormat,
          currencySymbol,
          customNumberFormater,
        );

        if (pointsForTooltip.length > 1 && point.series.chart.hoverSeries === point.series) {
          toolTipItem = `<span style="font-weight:700; background-color:${ColorUtils.hex2rgba(colors.Primary.Main, 0.2)}">${toolTipItem}</span>`;
        }
        return `<span style="line-height: 1.3">${toolTipItem}</span>`;
      })
      .join(''));
  },
});

const numberFormatter = (value?: number) => {
  if (value === undefined) return '--';
  return numberFormat(value, 2);
};
const percentFormatter = (value?: number) => {
  if (value === undefined) return '--';
  return `${numberFormat(toPercentage(value), 1)}%`;
};

export const getPeerGroupSubjectTooltip = (
  colors: VennColors,
  yAxisUnitFormat: 'percent' | 'ratio',
  chartType: 'box' | 'stacked',
) => {
  return {
    backgroundColor: getTooltipBackgroundColor(colors),
    borderRadius: 10,
    borderWidth: 0,
    valueDecimals: 2,
    split: true,
    style: {
      color: colors.White,
    },
    formatter() {
      return '';
    },
    pointFormatter(this: Highcharts.Point) {
      const formatFn = yAxisUnitFormat === 'ratio' ? numberFormatter : percentFormatter;
      const tooltipStr: string = `${this.category}${chartType === 'box' ? '' : ' Percentile'}: <b>${formatFn(this.y)}</b>`;
      return tooltipStr;
    },
  };
};

export const getPeerGroupTooltip = (colors: VennColors, yAxisUnitFormat: 'percent' | 'ratio') => {
  return {
    backgroundColor: getTooltipBackgroundColor(colors),
    borderRadius: 10,
    borderWidth: 0,
    valueDecimals: 2,
    split: true,
    style: {
      color: colors.White,
    },
    formatter() {
      return '';
    },
    pointFormatter(this: TooltipFormatterContextObject): string {
      const formatFn = yAxisUnitFormat === 'ratio' ? numberFormatter : percentFormatter;
      const x = this.x;
      const currentData = this.series.data.find((data) => data.x === x);
      const boxplotValues = currentData?.options;
      // @ts-expect-error cannot get rid of this error otherwise, highcharts typing is messed up
      return `5th Percentile: ${formatFn(boxplotValues?.low)}<br>25th Percentile: ${formatFn(boxplotValues?.q1)}<br>Median: ${formatFn(boxplotValues?.median)}<br>75th Percentile: ${formatFn(boxplotValues?.q3)}<br>95th Percentile: ${formatFn(boxplotValues?.high)}<br>`;
    },
  };
};

/** Default for most charts, except maps. */
export const getDefaultChartConfig = (
  yAxisUnitFormat: UnitFormat = 'percent',
  frequency: FrequencyEnum = 'MONTHLY',
  print: boolean,
  typography: Typography,
  colors: VennColors,
  schemes: Schemes,
  axisFontSize: number,
  currencySymbol?: string,
) => {
  return {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      marginRight: 0,
      marginTop: 0,
      spacingBottom: 4,
      style: {
        fontSize: print ? REPORT_LAB_FONT_BODY : '14px',
        overflow: 'visible',
      },
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    tooltip: getCustomTooltip(yAxisUnitFormat, frequency, colors, currencySymbol),
    xAxis: {
      type: 'datetime' as AxisTypeValue,
      gridLineWidth: 1,
      gridLineColor: schemes.LineChartColors.lineColor,
      tickWidth: 0,
      lineColor: schemes.LineChartColors.lineColor,
      tickPixelInterval: 120,
      crosshair: {
        color: colors.MidGrey1,
      },
      labels: {
        style: {
          fontSize: `${axisFontSize}pt`,
          color: colors.DarkGrey,
        },
        formatter(this: AxisLabelsFormatterContextObject) {
          const min: number = this.chart?.xAxis?.[0]?.dataMin || 0;
          const max: number = this.chart?.xAxis?.[0]?.dataMax || 0;
          const range = moment.duration(max - min).asYears();

          if (range > 1 && frequency === 'DAILY') {
            // X-axis labels use month-year format, and the returns are daily. We're okay with the fact that
            // this means that the label for "Mar '17" is aligned with the return point for "28 February 2017"
            return dateFormat(Dates.CHART_DATE_FORMATS.YEAR_MONTH, moment.utc(this.value).valueOf());
          }
          const format =
            frequency === 'DAILY' ? Dates.CHART_DATE_FORMATS.MONTH_DAY : Dates.CHART_DATE_FORMATS.YEAR_MONTH;

          return dateFormat(format, HighchartsUtils.alignDatesForLowFrequencies(this.value).valueOf());
        },
      },
    },
    yAxis: {
      gridLineColor: schemes.LineChartColors.lineColor,
      gridLineWidth: 1,
      tickWidth: 1,
      tickColor: schemes.LineChartColors.lineColor,
      tickLength: 0,
      lineWidth: 1,
      lineColor: schemes.LineChartColors.lineColor,
      showLastLabel: false,
      labels: {
        style: {
          fontFamily: typography.fontFamily,
          fontSize: `${axisFontSize}pt`,
          whiteSpace: 'nowrap',
        },
        useHTML: true,
        formatter(this: AxisLabelsFormatterContextObject) {
          switch (yAxisUnitFormat) {
            case 'ratio':
              return numberFormat(this.value, 2);
            case 'allocation':
              return formatAllocation(this.value, true, false, undefined, false, 1);
            case 'percent':
            default:
              return `${numberFormat(toPercentage(this.value), 1)}%`;
          }
        },
      },
      title: {
        text: '',
        y: 20,
        x: 20,
        align: 'low' as AxisTitleAlignValue,
        rotation: 0,
        offset: 0,
      },
    },
    plotOptions: {
      series: {
        animation: false,
        events: {
          legendItemClick: () => false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              radius: 4,
            },
          },
        },
      },
    },
  };
};

export const getMinValue = (series: PartialHighchartSerieData[] | undefined, horizontalLine: number | undefined) => {
  if (!series || series.length === 0 || horizontalLine === undefined) {
    return undefined;
  }
  let minValue = horizontalLine;
  series.forEach((serie) => serie.data.forEach((item) => (minValue = Math.min(item[1], minValue))));

  return minValue;
};

export const getMaxValue = (series: PartialHighchartSerieData[] | undefined, horizontalLine: number | undefined) => {
  if (!series || series.length === 0 || horizontalLine === undefined) {
    return undefined;
  }
  let maxValue = horizontalLine;
  series.forEach((serie) => serie.data.forEach((item) => (maxValue = Math.max(item[1], maxValue))));
  return maxValue;
};

type FullPeriodValueWithColor = FullPeriodValue & { color: string };
export const createVennLineChartConfig = (
  series: SerieValue[],
  theme: Theme,
  axisFontSize: number,
  yAxisUnitFormat: 'percent' | 'ratio' | 'allocation' = 'percent',
  frequency: FrequencyEnum = 'MONTHLY',
  minStartDate: number | undefined,
  print = false,
  showDrawdownLine = false,
  currencySymbol?: string,
): HighchartOptions => {
  const horizontalLines: FullPeriodValueWithColor[] =
    compact(
      series.map((serie: SerieValue) =>
        serie.fullPeriodValueLine
          ? {
              ...serie.fullPeriodValueLine,
              color: serie.color,
            }
          : undefined,
      ),
    ) || [];

  const data = compact(series.map((s) => s.data));

  const minByHorizontalLines = minBy(horizontalLines, (hl: FullPeriodValue) => hl.value);
  const maxByHorizontalLines = maxBy(horizontalLines, (hl: FullPeriodValue) => hl.value);

  const min = getMinValue(data, minByHorizontalLines ? minByHorizontalLines.value : undefined);
  const max = getMaxValue(data, maxByHorizontalLines ? maxByHorizontalLines.value : undefined);

  const yAxisConstraint =
    horizontalLines.length > 0
      ? {
          min,
          max,
        }
      : {};
  const xAxisConstraint = minStartDate ? { min: minStartDate } : {};

  const config = getDefaultChartConfig(
    yAxisUnitFormat,
    frequency,
    print,
    theme.Typography,
    theme.Colors,
    theme.Schemes,
    axisFontSize,
    currencySymbol,
  );

  const additionalDrawdownYaxis = showDrawdownLine
    ? {
        max: 0.01,
        minRange: 0.03,
        plotLines: [
          {
            color: theme.Colors.DarkGrey,
            value: 0.0,
            width: 2,
            dashStyle: 'ShortDash' as DashStyleValue,
          },
        ],
      }
    : {};

  return {
    ...config,
    chart: {
      ...config.chart,
      height: print ? 200 : undefined,
    },
    series: data.map((serie: PartialHighchartSerieData) => ({
      ...serie,
      type: serie.type as AnyDuringEslintMigration,
    })),
    xAxis: {
      ...config.xAxis,
      ...xAxisConstraint,
      id: 'xaxis',
      labels: {
        ...config.xAxis.labels,
      },
    },
    yAxis: {
      ...config.yAxis,
      ...yAxisConstraint,
      plotLines: horizontalLines.map((hl: FullPeriodValueWithColor) => ({
        color: hl.color,
        width: 1,
        value: hl.value,
        dashStyle: 'ShortDash' as DashStyleValue,
        zIndex: HighchartZIndex.Serie.Front,
      })),
      ...additionalDrawdownYaxis,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      ...config.plotOptions,
      area: { fillOpacity: 0.2 },
    },
  };
};

export const createVennMapChartConfig = (
  series: SeriesMapDataOptions[],
  colors: VennColors,
  legendFontSize: number,
): HighchartOptions => {
  return {
    chart: {
      backgroundColor: 'transparent',
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 2,
      spacingLeft: 0,
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: false,
    },
    title: {
      text: '',
    },
    colorAxis: {
      minColor: colors.DivergingColor.B1,
      maxColor: colors.DivergingColor.B5,
      min: 0,
      max: 1,
      labels: {
        formatter() {
          return Numbers.safeFormatPercentage(this.value, 0);
        },
        style: {
          textOverflow: 'none',
          fontSize: `${legendFontSize}pt`,
        },
      },
    },
    legend: {
      padding: calculateLegendPadding(legendFontSize),
      margin: 0,
    },
    series: [
      {
        type: 'map',
        mapData: WORLD_MAP_COUNTRIES,
        name: 'Geography exposure',
        data: series,
        keys: ['hc-key', 'value', 'region-name'],
        tooltip: {
          pointFormatter() {
            return `${assertNotNil(this['region-name'])}: ${Numbers.safeFormatPercentage(this.value)}`;
          },
        },
      },
    ],
  };
};
export const createVennPieChartConfig = (
  metricName: string | undefined,
  series: SeriesPieDataOptions[] | undefined,
  theme: Theme,
  yAxisUnitFormat: 'percent' | 'ratio' = 'percent',
  height: number | undefined,
  legendFontSize: number,
  print = false,
  customLegend = false,
  allowNegativeValues = false,
): HighchartOptions => {
  const config = getDefaultChartConfig(
    yAxisUnitFormat,
    'MONTHLY',
    print,
    theme.Typography,
    theme.Colors,
    theme.Schemes,
    // We don't use an axis for pie chart
    0,
    undefined,
  );
  return {
    ...config,
    xAxis: undefined,
    yAxis: undefined,
    chart: {
      ...config.chart,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      height,
      type: 'pie',
      animation: false,
      reflow: true,
    },
    series: [
      {
        name: metricName,
        data: series,
        type: 'pie',
        innerSize: '80%',
      },
    ],
    tooltip: {
      pointFormatter() {
        return allowNegativeValues
          ? ''
          : `${this.series?.name ?? 'Series'}: <b>${Numbers.safeFormatNumber(this.percentage, 1)}%</b>`;
      },
    },
    legend: {
      enabled: !customLegend,
      verticalAlign: 'bottom',
      labelFormatter(this: VennSeriesPieDataOptions) {
        return this.legend ?? '';
      },
      itemStyle: {
        fontSize: `${legendFontSize}pt`,
      },
      padding: legendFontSize ? calculateLegendPadding(legendFontSize) : 0,
      margin: 0,
    },
    plotOptions: {
      ...config.plotOptions,
      pie: {
        animation: false,
        allowPointSelect: true,
        showInLegend: true,
        size: customLegend ? '100%' : '70%',
        center: [null, customLegend ? null : '47%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            legendItemClick() {
              return false;
            },
          },
        },
      },
    },
  };
};

export interface growthSimulationConfigProps {
  start: number | undefined;
  end: number | undefined;
  series: SerieValue[];
  theme: Theme;
  frequency: FrequencyEnum;
  print: boolean;
  currencySymbol: string;
  useLogarithmicScale: boolean;
  hasDrawdownLines: boolean;
  axisFontSize: number;
}

export const createGrowthSimulationLineChartConfig = (props: growthSimulationConfigProps) => {
  const config = createVennLineChartConfig(
    props.series,
    props.theme,
    props.axisFontSize,
    'allocation',
    props.frequency,
    undefined,
    props.print,
    props.hasDrawdownLines,
    props.currencySymbol,
  );

  return {
    ...config,
    chart: {
      ...config.chart,
      marginRight: 4,
    },
    xAxis: {
      ...config.xAxis,
      showFirstLabel: true,
      startOnTick: false,
      endOnTick: false,
      max: props.end,
    },
    yAxis: merge({}, config.yAxis, {
      startOnTick: false,
      tickPixelInterval: 50,
      type: props.useLogarithmicScale ? 'logarithmic' : ('linear' as AxisTypeValue),
    }),
  };
};

export interface BoxChartConfigProps {
  start: number | undefined;
  end: number | undefined;
  categories: string[];
  theme: Theme;
  frequency: FrequencyEnum;
  print?: boolean;
  currencySymbol: string;
  hasDrawdownLines?: boolean;
  percentiles: number[];
  colors: VennColors;
  useLogarithmicScale?: boolean;
  axisFontSize: number;
}

export const createBoxChartConfig = (props: BoxChartConfigProps) => {
  const config = getDefaultChartConfig(
    'ratio',
    props.frequency,
    props.print ?? false,
    props.theme.Typography,
    props.theme.Colors,
    props.theme.Schemes,
    props.axisFontSize,
    props.currencySymbol,
  );
  return {
    ...config,
    chart: {
      ...config.chart,
      type: 'boxplot',
    },
    xAxis: merge({}, config.xAxis, {
      labels: {
        enabled: false,
      },
    }),
    yAxis: merge({}, config.yAxis, {
      type: props.useLogarithmicScale ? 'logarithmic' : ('linear' as AxisTypeValue),
      labels: {
        formatter(this: AnyDuringEslintMigration) {
          return props.currencySymbol + this.value.toString();
        },
      },
    }),
    tooltip: {
      backgroundColor: getTooltipBackgroundColor(props.colors),
      borderRadius: 10,
      borderWidth: 0,
      valueDecimals: 2,
      shared: false,
      style: {
        color: props.colors.White,
      },
      formatter(this: TooltipFormatterContextObject) {
        const point = this.point as BoxPlotPoint;
        // TODO: (VENN-20577 / TYPES) Pointless (haha) ternary?
        const color = point ? point.color : undefined;

        return `<span style='color:${color}'>\u25CF</span>
      <p><b>${this.series?.name ?? 'Series'}</p></b><br />
      <p>${Numbers.safeFormatPercentage(props.percentiles[4], 0)} :<b> ${
        props.currencySymbol + Numbers.safeFormatNumber(point.high)
      } </b></p><br />  
      <p>${Numbers.safeFormatPercentage(props.percentiles[3], 0)} :<b> ${
        props.currencySymbol + Numbers.safeFormatNumber(point.q3)
      } </b></p><br />  
      <p>${Numbers.safeFormatPercentage(props.percentiles[2], 0)} :<b> ${
        props.currencySymbol + Numbers.safeFormatNumber(point.median)
      } </b></p><br />  
      <p>${Numbers.safeFormatPercentage(props.percentiles[1], 0)} :<b> ${
        props.currencySymbol + Numbers.safeFormatNumber(point.q1)
      } </b></p><br />
      <p>${Numbers.safeFormatPercentage(props.percentiles[0], 0)} :<b> ${
        props.currencySymbol + Numbers.safeFormatNumber(point.low)
      } </b></p><br />  
      `;
      },
    },
  };
};

/**
 * Scatter plot paddings defined as part of VENN-22406; enabling minimum boundaries around a scatter plot.
 */
const SCATTER_PLOT_PADDING = 0.05;
export const createVennScatterChartConfig = (
  series: ScatterSeries,
  xAxisFormat: 'percent' | 'ratio',
  yAxisFormat: 'percent' | 'ratio',
  xTitle: string,
  yTitle: string,
  typography: Typography,
  colors: VennColors,
  schemes: Schemes,
  legendFontSize: number,
  axisFontSize: number,
  height: number | undefined,
): Highcharts.Options => {
  const xUnit = xAxisFormat === 'percent' ? '%' : '';
  const yUnit = yAxisFormat === 'percent' ? '%' : '';

  // SoftThreshold (https://api.highcharts.com/highcharts/plotOptions.series.softThreshold) only applies to Y axis for some reason,
  // so we implement our own custom soft threshold behavior for the X axis.
  // This makes the 0 threshold 'soft' such that data can push into it with no minimum padding, but as soon as any data is past the zero threshold
  // we start using padding. This is convenient so that values like 0.01 volatility don't cause the scatter plot to start at a negative number, which would
  // be weird given negatives are impossible with volatility).
  const xSeriesMin = min(series.map((seriesPoint) => seriesPoint.data[0]![0])) || 0;
  const xAxisPadding = {
    minPadding: xSeriesMin >= 0 ? 0 : SCATTER_PLOT_PADDING,
    // We don't do anything special for maxPadding because surprisingly highcharts handles it well by default
    maxPadding: SCATTER_PLOT_PADDING,
  };
  const yAxisPadding = {
    minPadding: SCATTER_PLOT_PADDING,
    maxPadding: SCATTER_PLOT_PADDING,
  };

  return {
    chart: {
      type: 'scatter',
      marginRight: 5,
      marginTop: 0,
      style: {
        fontSize: `${axisFontSize}pt`,
        overflow: 'visible',
      },
      spacingBottom: 4,
      height,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: true,
      useHTML: true,
      itemStyle: {
        fontSize: `${legendFontSize}pt`,
      },
      padding: calculateLegendPadding(legendFontSize),
      margin: 0,
    },
    xAxis: {
      ...xAxisPadding,
      title: {
        text: xTitle,
        align: 'middle' as AxisTitleAlignValue,
      },
      gridLineWidth: 1,
      gridLineColor: schemes.LineChartColors.lineColor,
      tickWidth: 0,
      lineColor: schemes.LineChartColors.lineColor,
      startOnTick: true,
      tickPixelInterval: 120,
      showFirstLabel: true,
      showLastLabel: true,
      labels: {
        style: {
          fontSize: `${axisFontSize}pt`,
          color: colors.DarkGrey,
        },
        formatter(this: AxisLabelsFormatterContextObject) {
          return xAxisFormat === 'percent'
            ? `${numberFormat(toPercentage(this.value), 1)}%`
            : numberFormat(this.value, 2);
        },
      },
    },
    yAxis: {
      ...yAxisPadding,
      gridLineColor: schemes.LineChartColors.lineColor,
      gridLineWidth: 1,
      tickWidth: 1,
      tickColor: schemes.LineChartColors.lineColor,
      tickLength: 0,
      lineWidth: 1,
      lineColor: schemes.LineChartColors.lineColor,
      showLastLabel: false,
      labels: {
        style: {
          fontFamily: typography.fontFamily,
          fontSize: `${axisFontSize}pt`,
          whiteSpace: 'nowrap',
        },
        useHTML: true,
        formatter(this: AxisLabelsFormatterContextObject) {
          return yAxisFormat === 'percent'
            ? `${numberFormat(toPercentage(this.value), 1)}%`
            : numberFormat(this.value, 2);
        },
      },
      title: {
        text: yTitle,
        align: 'middle' as AxisTitleAlignValue,
      },
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: () => false,
        },
      },
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: schemes.LineChartColors.lineColor,
            },
          },
        },
      },
    },
    tooltip: {
      backgroundColor: getTooltipBackgroundColor(colors),
      useHTML: true,
      borderRadius: 10,
      borderWidth: 0,
      valueDecimals: 2,
      shared: true,
      style: {
        color: colors.White,
      },
      formatter(this: TooltipFormatterContextObject) {
        const x = xAxisFormat === 'percent' ? (this.x * 100).toFixed(2) + xUnit : this.x.toFixed(2);
        const y = yAxisFormat === 'percent' ? (this.y * 100).toFixed(2) + yUnit : this.y.toFixed(2);
        const color = this.point ? this.point.color : undefined;

        let tooltipStr = `<span style='color:${color}'>\u25CF</span>
        <span> ${this.series?.name ?? 'Series'}</span><br />
        <span>${xTitle}: <b> ${x} </b></span><br />`;

        if (xTitle !== yTitle) {
          tooltipStr += `<span>${yTitle}: <b> ${y} </b></span>`;
        }

        return tooltipStr;
      },
    },
    series,
  };
};

export const addVenncast = (config: Options, data: VennLineChartData, colors: VennColors, print?: boolean) => ({
  ...config,
  chart: {
    ...config.chart,
    marginBottom: data.persistentBottomBar || data.estimated ? 60 : 30,
    events: {
      ...(config?.chart?.events || {}),
      load(this: Chart) {
        ((config?.chart?.events?.load as () => void) || noop)();
        drawVenncast.bind(this, data, colors, print)();
      },
      redraw(this: Chart) {
        ((config?.chart?.events?.redraw as () => void) || noop)();
        drawVenncast.bind(this, data, colors, print)();
      },
    },
  },
  xAxis: {
    ...config.xAxis,
    plotLines: [
      // TODO: (VENN-20577 / TYPES) this doesn't handle the case where axis is an XAxisOptions[], should it?
      ...((config?.xAxis as XAxisOptions)?.plotLines || []),
      ...(data &&
        (data.estimated && data.visibleVenncastStart !== undefined && !data.persistentBottomBar
          ? [
              {
                color: colors.DataLineColor.PaleBlue,
                value: data.visibleVenncastStart,
                width: data.persistentBottomBar ? 0 : 2,
                zIndex: HighchartZIndex.PlotBand.AboveSeries,
                id: 'venncast-line',
              },
            ]
          : [])),
    ],
  },
});

export function drawVenncast(this: Chart, data: VennLineChartData, colors: VennColors, print?: boolean) {
  const xAxis = this.xAxis[0]!;
  if (!xAxis || !data || (!data.estimated && !data.persistentBottomBar)) {
    return;
  }

  // Remove all the previous plot bands
  if (Array.isArray(this.xAxisPlotBands)) {
    for (const band of this.xAxisPlotBands) {
      band.safeRemoveChild(band.element);
    }
  }

  this.xAxisPlotBands = [];

  const venncastLine = xAxis.plotLinesAndBands.find((pl) => pl.id === 'venncast-line');
  if (!venncastLine) {
    return;
  }

  const venncastLineBottomY = getXAxisPlotLineBottomY(venncastLine);
  const chartStart = 0;
  const chartEnd = xAxis.width + xAxis.pos;
  const chartWidth = chartEnd - chartStart;

  // Space below the x-axis, for the Venncast label/arrow (grey background)
  const greyFill = this.renderer
    .rect(chartStart, venncastLineBottomY + 30, chartWidth, 30)
    .attr({
      id: 'venncast-label-grey-bkg',
      fill: colors.PaleGrey,
    })
    .add();
  this.xAxisPlotBands.push(greyFill);

  if ((!data.estimated && !data.visibleVenncastStart) || !data.visibleVenncastEnd) {
    return;
  }

  const start = xAxis.toPixels(data.visibleVenncastStart as AnyDuringEslintMigration, false);
  const end = xAxis.toPixels(data.visibleVenncastEnd, false);
  if (start < 0 && end < start) {
    return;
  }

  const highlightWidth = end - start;

  // Blue highlight over the x-axis
  const xAxisPlotBand = this.renderer
    .rect(start, venncastLineBottomY, highlightWidth, 30)
    .attr({
      id: 'vanncast-xaxis-highlight',
      fill: ColorUtils.hex2rgba(colors.DataLineColor.PaleBlue, 0.2),
      zIndex: HighchartZIndex.PlotBand.AboveChart,
    })
    .add();
  this.xAxisPlotBands.push(xAxisPlotBand);

  // Black background in front of the Venncast tip
  const blackFill = this.renderer
    .rect(start, venncastLineBottomY + 30, highlightWidth, 30)
    .attr({
      id: 'venncast-label-trailing-black-bkg',
      fill: colors.Black,
    })
    .add();
  this.xAxisPlotBands.push(blackFill);

  // Venncast tip
  const isTrunc = end - start < 200;
  const width = isTrunc ? (end - start - 50 > 0 ? end - start - 50 : 0) : 160;

  // Remove this line if it was previously added, to avoid rendering multiple overlapping tips.
  // This line will be aligned perfectly with the 'venncast-line' and will render the Venncast Tip.
  xAxis.removePlotLine('venncast-tip');
  xAxis.addPlotLine({
    id: 'venncast-tip',
    color: colors.DataLineColor.PaleBlue,
    width: 2,
    value: data.visibleVenncastStart,
    zIndex: HighchartZIndex.PlotBand.AboveChart,
    label: {
      text: ReactDOMServer.renderToString(getVennTip(width, isTrunc, colors)),
      verticalAlign: 'bottom',
      rotation: 0,
      useHTML: true,
      x: 0,
      y: print ? 70 : 74,
      style: {
        fontSize: '11px',
      },
    },
  });

  // Update Venncast line size: make it longer to cover the legend on x-axis
  if (venncastLine.svgElem.d !== undefined) {
    const dArray = (venncastLine.svgElem.d as AnyDuringEslintMigration).split(' ');
    dArray[5] = Number(dArray[5]) + (data.estimated ? 60 : 30);
    venncastLine.svgElem.d = dArray.join(' ');
    (venncastLine.svgElem.element.attributes as AnyDuringEslintMigration).d.nodeValue = dArray.join(' ');
  }
}

function getXAxisPlotLineBottomY(plotLineStart: PlotLineOrBand) {
  const d = plotLineStart.svgElem.d || '';
  const dArray = (d as AnyDuringEslintMigration).split(' ');
  return Number(dArray[5]);
}

const getVennTip = (width: number, isTrunc: boolean, colors: VennColors) => {
  if (!isTrunc) {
    return (
      <div>
        <StyledVennTip
          href={VENNCAST_FAQ_HREF}
          target="_blank"
          width={width}
          className="qa-venntip"
          rel="noopener noreferrer"
          colors={colors}
        >
          <StyledIcon prefix="fas" type="info-circle" />
          <span>{getTextThemeProvider().VenncastName} Estimate Period</span>
        </StyledVennTip>
      </div>
    );
  }

  return (
    <TooltipWrapper>
      <StyledTooltip
        content={<>{getTextThemeProvider().VenncastName} Estimate Period</>}
        position={TooltipPosition.Left}
      >
        <StyledVennTip
          href={VENNCAST_FAQ_HREF}
          target="_blank"
          width={width}
          className="qa-venntip"
          rel="noopener noreferrer"
          isTrunc
          colors={colors}
        >
          <StyledIcon prefix="fas" type="info-circle" />
          <span>{getTextThemeProvider().VenncastName} Estimate Period</span>
        </StyledVennTip>
      </StyledTooltip>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  position: relative;
  height: 30px;
  margin-top: -13px;
`;

const StyledVennTip = styled.a<{ width?: number; isTrunc?: boolean; colors: VennColors }>`
  padding: 6px 9px;
  background-color: ${(props) => props.colors.DataLineColor.PaleBlue};
  border-right: 0.5px solid ${(props) => props.colors.DataLineColor.PaleBlue};
  color: ${(props) => props.colors.Black};

  &:hover {
    color: ${(props) => props.colors.White};
  }

  font-weight: bold;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;

  :after {
    top: 0;
    border-width: 15px;
    border-color: ${(props) => props.colors.DataLineColor.PaleBlue} transparent transparent transparent;
    transform: rotate(-90deg);
    position: absolute;
    left: calc(100% + 0.5px);
    border-style: solid;
    content: '';
  }

  span {
    width: ${(props) => (props.width ? props.width : '140')}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }
`;

const StyledIcon = styled(Icon)`
  padding-right: 5px;
  position: relative;
  top: -2px;
`;

const StyledTooltip = styled(Tooltip)`
  > div {
    top: -15px;
    height: 30px;
    right: calc(8px + 100%);
  }

  > a {
    left: -1px;
    bottom: -2px;
  }
`;

export const toPercentage = (value: number) => round(value * 100, 12);

export const getLineSerie = (
  returns: number[][] | undefined,
  name: string,
  color: string,
  type?: HighchartChartType,
  lastReturn?: number[],
  zIndex?: number,
  createForEmptyReturns?: boolean,
): PartialHighchartSerieData | undefined => {
  if ((!returns || returns.length === 0) && !createForEmptyReturns) {
    return undefined;
  }
  const format = (value: number) => round(value, 12);
  if (type === 'arearange') {
    let estimatedBounds = (returns || []).map(
      (data) => [data[0]!, format(data[1]!), format(data[2]!)] as [number, number, number],
    );
    if (lastReturn) {
      estimatedBounds = [[lastReturn[0]!, format(lastReturn[1]!), format(lastReturn[1]!)], ...estimatedBounds];
    }
    return {
      name,
      type: 'arearange',
      zIndex,
      color,
      data: estimatedBounds,
      fillColor: ColorUtils.hex2rgba(color, 0.1),
      lineColor: color,
      lineWidth: 0.3,
    };
  }
  if (type === 'dashline') {
    let esimatedReturn = (returns || []).map((data) => [data[0]!, format(data[1]!)] as [number, number]);
    if (lastReturn) {
      esimatedReturn = [[lastReturn[0]!, format(lastReturn[1]!)], ...esimatedReturn];
    }
    return {
      name,
      type: 'line',
      zIndex,
      color,
      data: esimatedReturn,
      dashStyle: 'ShortDot',
    };
  }
  const formattedData = (returns || []).map((data) => [data[0]!, format(data[1]!)] as [number, number]);
  if (type === 'scatter') {
    return {
      name,
      type,
      zIndex,
      color,
      data: formattedData,
      marker: {
        enabled: true,
      },
    };
  }
  return {
    name,
    type: type ?? 'line',
    zIndex,
    color,
    data: formattedData,
  };
};
