import type { History } from 'history';
import { isNil, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, ThemeContext } from 'styled-components';
import type { Portfolio, PrivatePortfolioNode } from 'venn-api';
import {
  CreatePortfolioModalWrapper,
  DataUploaderModalWrapper,
  DataUploaderMode,
  LibraryLinkFooter,
  PortfoliosContext,
  SearchMenuBar,
  SearchMenuColumn,
  type SearchMenuItem,
  SkipToContent,
  StudioPrintSettingsContext,
  UniversalUploader,
  UserContext,
} from 'venn-components';
import {
  BrandLogo,
  GetColor,
  Notifications,
  NotificationType,
  SIDE_NAV_LINK_HEIGHT,
  SIDE_NAV_WIDTH,
  SideNav,
  Tooltip,
  TooltipPosition,
  TOP_NAV_HEIGHT,
  TopNav,
  ZIndex,
} from 'venn-ui-kit';
import {
  analyticsService,
  focusUniversalSearch,
  getAnalysisPath,
  getAnalysisPathForPortfolio,
  getAnalysisViewUrl,
  getBlankStudio,
  Hotkeys,
  LibraryItemType,
  LibraryTab,
  navigateToLibrary,
  navigateToManageDataPage,
  navigateToStudioView,
  Routes,
  SpecialCssClasses,
  universalSearchClass,
  useHasFF,
  useHotkeys,
} from 'venn-utils';
import SlideoutMenu from '../../../../../studio-page/components/studio-slideout-menu/SlideoutMenu';
import AdminSwitchOrg from './AdminSwitchOrg';
import BottomLinks from './BottomLinks';
import ContextSwitcher from './ContextSwitcher';
import {
  type BaseTrackingOpts,
  getMultiUploadButtonLink,
  type NavActions,
  navigationConfigInitializer,
  navigationConfigInitializerV1,
} from './shellNavigationConfigInitializer';
import ShellNavigationLink, { TooltipContent, TOP_ICON_WIDTH } from './ShellNavigationLink';
import { ReportlabUpsell } from '../../../../../studio-page/components/studio-slideout-upsell/ReportlabUpsell';
import { PALUpsell } from '../../../../../studio-page/components/studio-slideout-upsell/PALUpsell';

interface ShellNavigationProps {
  history: History;
}

const StyledSideNav = styled(SideNav)`
  background-color: ${GetColor.Black};
  height: 100%;
  width: ${SIDE_NAV_WIDTH}px;
  z-index: ${ZIndex.Navigation};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);
  position: relative;
  overflow-y: auto;
  @media print {
    display: none;
  }
`;

const StyledLogo = styled.div<{ showTopNav?: boolean }>`
  display: flex;
  align-items: center;
  height: ${({ showTopNav }) => (showTopNav ? '100%' : `${SIDE_NAV_LINK_HEIGHT}px`)};
  ${({ showTopNav }) => showTopNav && `width: ${SIDE_NAV_WIDTH}px;`}
  padding: 10px 23px;

  &:hover,
  &:focus {
    background-color: ${GetColor.GreyScale.Grey100};
  }

  ${({ showTopNav }) =>
    (!showTopNav &&
      `
    justify-content: center;
    padding: 10px 0 10px 0;
  `) ||
    ''};
`;

const StyledNavigationLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const onLogoClick = () => {
  analyticsService.navigationTriggered({
    location: 'navigation bar',
    itemType: 'link',
    userIntent: 'navigate',
    destinationPageTitle: 'Home',
  });
};

const SEARCH_COLUMNS = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.TYPE,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

const ShellNavigationBar = ({ history }: ShellNavigationProps) => {
  const hasContextSwitching = useHasFF('context_switching');
  const hasStudioFF = useHasFF('studio_ff');
  const hasStudioReportEditor = useHasFF('studio_report_editor');
  const hasPrivatesFF = useHasFF('private_analytics');

  const mounted = useRef(false);
  const { hasPermission } = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);
  const { demoPortfolio, masterPortfolio } = useContext(PortfoliosContext);
  type SlideoutState = 'allClosed' | 'studioSlideout' | 'reportLabSlideout' | 'palUpsell' | 'rlUpsell';
  const [slideoutState, setSlideoutState] = useState<SlideoutState>('allClosed');
  const closeSlideout = (slideoutToClose: SlideoutState) => () =>
    setSlideoutState((prev) => (prev === slideoutToClose ? 'allClosed' : prev));

  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [multiUploaderOpen, setMultiUploaderOpen] = useState(false);
  const { addDisclosurePage } = useContext(StudioPrintSettingsContext);
  const { currentContext } = useContext(UserContext);

  const [lastTimeBeforeUpdate, setLastTimeBeforeUpdate] = useState<number>();
  const [createPortfolioOpen, setCreatePortfolioOpen] = useState(false);

  const closeMultiUploader = useCallback(() => setMultiUploaderOpen(false), []);
  const shouldAllowOpeningUploader = hasPermission('CREATE_PORTFOLIO') || hasPermission('UPLOAD_RETURNS');
  const onSearchSelect = useCallback(
    ({ value, category, viewInfo, helpArticle }: SearchMenuItem) => {
      switch (category) {
        case 'article':
          if (helpArticle) {
            window.open(helpArticle.url, '_blank');
          }
          return;
        case 'view':
          if (viewInfo) {
            history.push(getAnalysisViewUrl(viewInfo));
          }

          return;
        default:
          if (value) {
            if (!value.private) {
              history.push(getAnalysisPath(value.superType, value.id));
            } else {
              const subject =
                value.superType === 'private-investment'
                  ? { privateFundId: value.privateFund?.id }
                  : { privatePortfolioId: value.privatePortfolio?.id };
              navigateToManageDataPage(history, subject, 'Library', false);
            }
          }
      }
    },
    [history],
  );

  const onUploadClick = useCallback(() => {
    setUploaderOpen(true);
    setLastTimeBeforeUpdate(Date.now());
  }, []);

  const onMultiUploadClick = useCallback(() => {
    setMultiUploaderOpen(true);
    setLastTimeBeforeUpdate(Date.now());
  }, []);

  const onCreatePortfolioClick = useCallback(() => {
    if (masterPortfolio) {
      setCreatePortfolioOpen(true);
    } else {
      history.push(Routes.CREATE_PORTFOLIO);
    }
  }, [history, masterPortfolio]);

  const onOpenPortfolioLabClick = useCallback(
    (baseTrackingOpts: BaseTrackingOpts) => {
      if (history.location?.pathname?.startsWith(Routes.PORTFOLIO_LAB_PATH)) {
        // If we are already on the portfolio lab page don't trigger a nav change.
        return;
      }

      history.push(Routes.PORTFOLIO_LAB_PATH);
      analyticsService.navigationTriggered({
        ...baseTrackingOpts,
        destinationPageTitle: 'Portfolio Lab',
      });
    },
    [history],
  );

  const toggleSlideout = (state: SlideoutState) => () =>
    setSlideoutState((prev) => (prev === state ? 'allClosed' : state));
  const toggleStudioSlideout = toggleSlideout('studioSlideout');

  const toggleReportLabSlideout = toggleSlideout('reportLabSlideout');

  const togglePrivateAssetLabLearnMore = toggleSlideout('palUpsell');

  const toggleReportLabLearnMore = toggleSlideout('rlUpsell');

  const navActions: NavActions = {
    openUploaderModal: onUploadClick,
    openMultiPortfolioUploader: onMultiUploadClick,
    openCreatePortfolioModal: onCreatePortfolioClick,
    openPortfolioLab: onOpenPortfolioLabClick,
    toggleStudioSlideout,
    toggleReportLabSlideout,
    togglePrivateAssetLabLearnMore,
    toggleReportLabLearnMore,
  };

  const onCompleteUploader = useCallback(
    (mode: DataUploaderMode, uploadedFundIds?: string[]) => {
      Notifications.notify('Upload Successfully Completed!', NotificationType.SUCCESS);
      setUploaderOpen(false);
      navigateToLibrary(history, {
        tab: mode === DataUploaderMode.Privates ? LibraryTab.PrivateAssets : LibraryTab.ReturnsData,
        selectedIds: uploadedFundIds ?? [],
        selectedFilters: {
          itemType: LibraryItemType.UPLOAD,
          quickFilters: [],
          tags: [],
        },
        lastTimeBeforeUpdate,
      });
    },
    [history, lastTimeBeforeUpdate],
  );

  const onSubmitCreatePortfolio = useCallback(
    (portfolio: Portfolio, createdFromMaster: boolean) => {
      setCreatePortfolioOpen(false);
      history.push(getAnalysisPathForPortfolio(portfolio.id, undefined, createdFromMaster));
    },
    [history],
  );
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useHotkeys((e) => {
    e.preventDefault(); // make sure hotkey isn't typed into the search input
    focusUniversalSearch();
  }, Hotkeys.UNIVERSAL_SEARCH);

  const configInitializer =
    hasStudioFF || hasStudioReportEditor ? navigationConfigInitializerV1 : navigationConfigInitializer;
  const config = configInitializer(navActions, history);
  const logoTooltip = <TooltipContent>Home</TooltipContent>;
  const onPrivatePortfolioCreated = (portfolio: PrivatePortfolioNode) => {
    const newDocument = getBlankStudio(
      'TEARSHEET',
      addDisclosurePage,
      [{ privatePortfolioId: portfolio.id }],
      currentContext,
    );

    analyticsService.creatingNewStudios({
      source: 'add to studio library button',
      name: 'blank tearsheet',
    });
    navigateToStudioView(history, { newDocument, openAllocatorForPrivatePortfolio: portfolio });
  };

  const topNav = (
    <StyledTopNav className={SpecialCssClasses.HideInReports}>
      <TopLeftNavGroup>
        <HomeTooltip
          usePortal
          showShadow
          content={logoTooltip}
          position={TooltipPosition.Right}
          background={Colors.Black}
        >
          <Link
            onMouseEnter={() => setIsLogoAnimated(true)}
            onMouseLeave={() => setIsLogoAnimated(false)}
            onClick={onLogoClick}
            to={Routes.HOME_PATH}
            className="qa-navigate-to-home"
          >
            <StyledLogo showTopNav>
              <BrandLogo isAnimated={isLogoAnimated} animateOnHover />
            </StyledLogo>
          </Link>
        </HomeTooltip>
        <SearchMenuWrapper>
          {hasContextSwitching && <ContextSwitcher />}
          <SearchMenuBar
            className={universalSearchClass}
            onSelected={onSearchSelect}
            location="universalSearch"
            autofocus={false}
            defaultMenuIsOpen={false}
            showQuickFilters
            footer={LibraryLinkFooter}
            columns={SEARCH_COLUMNS}
            value={null} // remove the concept of a selected item for this search bar
            darkPlaceholder
            clearQueryOnBlur
            fixedMenuWidth
            canSearchEverything
            privateAssetSearchMode={hasPrivatesFF ? 'ALL' : 'PUBLIC_ONLY'}
          />
        </SearchMenuWrapper>
      </TopLeftNavGroup>
      <TopRightNavGroup enableArchive={hasStudioReportEditor}>
        <BottomLinks />
      </TopRightNavGroup>
    </StyledTopNav>
  );

  return (
    <>
      <SkipToContent />
      <>
        {topNav}
        <StyledSideNav className={`side-navbar ${SpecialCssClasses.HideInReports}`}>
          <div>
            <StyledNavigationLinks>
              {multiUploaderOpen && (
                <UniversalUploader
                  lastTimeBeforeUpdate={lastTimeBeforeUpdate}
                  closeUploaderSlideout={closeMultiUploader}
                  onPrivatePortfolioCreated={onPrivatePortfolioCreated}
                />
              )}
              <StaticLinksWrapper>
                {config.left.map((navigationGroup) => {
                  return navigationGroup.filter(
                    (item) => !item.requiredPermission || hasPermission(item.requiredPermission),
                  ).length > 0 ? (
                    <StyledShellNavigationGroup key={`group-${navigationGroup[0]!.navId}`}>
                      {navigationGroup
                        .filter((item) => !item.requiredPermission || hasPermission(item.requiredPermission))
                        .map((navigationConfig) => (
                          <StyledShellNavigationLink
                            wrapperStyle={navigationConfig.wrapperStyle}
                            key={navigationConfig?.navId}
                            iconFontSize={isNil(navigationConfig?.smallIcon) ? 24 : 16}
                            {...omit(navigationConfig, ['wrapperStyle'])}
                          />
                        ))}
                    </StyledShellNavigationGroup>
                  ) : null;
                })}
              </StaticLinksWrapper>
              <AdminSwitchOrg />
            </StyledNavigationLinks>
          </div>
          <Tooltip
            usePortal
            position={TooltipPosition.Right}
            content={
              shouldAllowOpeningUploader
                ? undefined
                : 'Please contact an administrator to add this feature to your workspace.'
            }
          >
            <PortfoliosAndInvestmentsLink
              disabled={!shouldAllowOpeningUploader}
              {...getMultiUploadButtonLink(navActions)}
            />
          </Tooltip>
        </StyledSideNav>
      </>
      {uploaderOpen && (
        <DataUploaderModalWrapper
          mode={DataUploaderMode.Returns}
          onCancel={() => (mounted.current ? setUploaderOpen(false) : null)}
          onCompleteNavigate={onCompleteUploader}
        />
      )}
      {createPortfolioOpen && (
        <CreatePortfolioModalWrapper
          baseline={masterPortfolio || demoPortfolio}
          canCreateFromScratch
          onClose={() => (mounted.current ? setCreatePortfolioOpen(false) : null)}
          onSubmit={onSubmitCreatePortfolio}
          onPrivatePortfolioCreated={onPrivatePortfolioCreated}
        />
      )}
      <SlideoutMenu
        isOpen={slideoutState === 'studioSlideout'}
        closeSlideoutMenu={closeSlideout('studioSlideout')}
        isReport={false}
      />
      <SlideoutMenu
        isOpen={slideoutState === 'reportLabSlideout'}
        closeSlideoutMenu={closeSlideout('reportLabSlideout')}
        isReport
      />
      {slideoutState === 'rlUpsell' && <ReportlabUpsell onClose={closeSlideout('rlUpsell')} />}
      {slideoutState === 'palUpsell' && <PALUpsell onClose={closeSlideout('palUpsell')} />}
    </>
  );
};

export default ShellNavigationBar;

const StyledShellNavigationGroup = styled.div`
  border-bottom: 1px solid ${GetColor.GreyScale.Grey80};
`;

const StaticLinksWrapper = styled.div``;

const StyledShellNavigationLink = styled(ShellNavigationLink)<{ wrapperStyle?: string }>`
  ${({ wrapperStyle }) => wrapperStyle}
`;

// The !importants on the color and background-color are necessary to override styles coming NavigationPseudoLink
const PortfoliosAndInvestmentsLink = styled(ShellNavigationLink)<{ disabled: boolean }>`
  height: 72px;
  width: 72px;
  margin: 4px;
  background-color: ${GetColor.White};
  color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey70 : GetColor.DarkBlue)} !important;

  &:hover {
    background-color: ${GetColor.GreyScale.Grey20} !important;
  }
  border-radius: 4px;
  > div {
    margin: 0 auto;
    width: 85%;
  }

  i {
    font-size: 24px;
    color: ${(props) => (props.disabled ? GetColor.GreyScale.Grey100 : GetColor.DarkBlue)};
  }

  ${(props) =>
    props.disabled &&
    css`
    {
      pointer-events: none;
    }
  `}
`;

const HomeTooltip = styled(Tooltip)`
  height: auto;
`;

const TopNavGroup = styled.div`
  display: flex;
  align-items: center;
  z-index: ${ZIndex.Navigation};
`;

const TopLeftNavGroup = styled(TopNavGroup)`
  flex-grow: 1;
`;

const TopRightNavGroup = styled(TopNavGroup)<{ enableArchive?: boolean }>`
  width: ${({ enableArchive }) => (enableArchive ? TOP_ICON_WIDTH * 4 : TOP_ICON_WIDTH * 3)}px;
`;

const StyledTopNav = styled(TopNav)`
  background-color: ${GetColor.Black};
  height: ${TOP_NAV_HEIGHT}px;
  width: 100vw;
  z-index: ${ZIndex.Navigation};
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const SearchMenuWrapper = styled.div`
  display: flex;
  column-gap: 30px;
  align-items: center;
  flex: 1;
  min-width: 900px;
  max-width: 1300px;
  @media (max-width: 900px) {
    min-width: calc(100vw - 300px);
  }
  margin-left: 9px;

  > div:first-child {
    width: 400px;
  }

  > div:last-child {
    flex: 1;
  }
`;
