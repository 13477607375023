import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type {
  AttributionSubject,
  AttributionView,
  BasicTableColumn,
  DownloadMetaData,
  TrackAnalysisProps,
} from 'venn-components';
import {
  AnalysisViewContext,
  ColumnAlign,
  DownloadableContentBlock,
  EmptyState,
  exportToExcel,
  SORTDIR,
  Toggle,
  ToggleOption,
  TrackFailedAnalysis,
  TrackSuccessfulAnalysis,
  UserContext,
} from 'venn-components';
import type { AnalysisSubject, ExcelCell, TimeFrame } from 'venn-utils';
import { Dates, getSecondaryDisplayLabel, Numbers, SpecialCssClasses } from 'venn-utils';
import type { DropMenuItem, SimpleDropMenuProps } from 'venn-ui-kit';
import {
  Body1,
  DropMenu,
  getAppTitle,
  GetColor,
  Headline2,
  Tooltip,
  TooltipLink,
  METRIC_DEFINITIONS_HREF,
} from 'venn-ui-kit';
import range from 'lodash/range';
import AttributionTable from './AttributionTable';
import type { AttributionRow } from './types';
import AttributionDateRangeMessage from './AttributionDateRangeMessage';
import compact from 'lodash/compact';
import { HIDE_FORECASTS_IN_EXPORTS_KEY, SupportedErrorCodes } from 'venn-api';

type ColumnType = BasicTableColumn<AttributionRow> & { percentage?: boolean; cumulative?: boolean };

const formatNumber = (value?: number) => Numbers.safeFormatNumber(value ?? null, 2);
const formatPercentage = (value?: number) => Numbers.safeFormatPercentage(value ?? null, 1);

const buildColumn = ({
  accessor,
  label,
  percentage,
  left,
  cumulative,
  hide,
  print,
  tooltipLink,
  singleColumn,
}: {
  accessor: string;
  label: string;
  percentage: boolean;
  left?: boolean;
  cumulative?: boolean;
  hide?: boolean; // used to conditionally hide forecasted results in print and on screen
  print?: boolean;
  tooltipLink?: string;
  singleColumn?: boolean;
}): ColumnType => ({
  label,
  accessor,
  percentage,
  align: singleColumn ? ColumnAlign.CENTER : left ? ColumnAlign.LEFT : ColumnAlign.RIGHT,
  sortable: true,
  cumulative,
  cellRenderer: (row: AttributionRow) => {
    const rowValue = row[accessor];
    const value =
      typeof rowValue === 'string' ? rowValue : percentage ? formatPercentage(rowValue) : formatNumber(rowValue);
    return (
      <span className={hide ? SpecialCssClasses.NotDownloadable : undefined}>
        {cumulative ? <i>{value}</i> : value}
      </span>
    );
  },
  headerRenderer: (label: string) => (
    <span className={hide ? (print ? SpecialCssClasses.NotDownloadable : SpecialCssClasses.HideInReports) : undefined}>
      {label}
      {tooltipLink && !(hide && !print) ? (
        <TooltipLink
          positions={{
            top: -60,
            left: -90,
          }}
          href={tooltipLink}
          top
        />
      ) : null}
    </span>
  ),
  excelCellRenderer: (cell) => ({
    value: cell[accessor] || '--',
    percentage,
    digits: percentage ? 1 : 2,
  }),
  cellStyle: hide ? { color: 'transparent' } : undefined,
  headerStyle: hide ? { color: 'transparent' } : undefined,
});

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const nameRenderer = (relative: boolean, cumulativeReturn: boolean, timeFrame: TimeFrame) => (row: AttributionRow) => (
  <StyledTooltip
    block
    usePortal
    content={
      <TipWrapper>
        <TipTitle>{`${row.name} (Historical)`}</TipTitle>
        <TipRow>
          <div>{(relative ? 'Excess Return' : 'Return') + (cumulativeReturn ? ' (Cumulative)' : '')}</div>
          <TipStat>{formatPercentage(row.return)}</TipStat>
        </TipRow>
        <TipRow>
          <div>{relative ? 'Tracking Error' : 'Volatility'}</div>
          <TipStat>{formatPercentage(row.volatility)}</TipStat>
        </TipRow>
        <TipRow>
          <div>{relative ? 'Information Ratio' : 'Sharpe'}</div>
          <TipStat>{formatNumber(row.sharpe)}</TipStat>
        </TipRow>
        {timeFrame.startTime && timeFrame.endTime && (
          <TipDate>{`(${Dates.toDDMMMYYYY(timeFrame.startTime)} - ${Dates.toDDMMMYYYY(timeFrame.endTime)})`}</TipDate>
        )}
      </TipWrapper>
    }
  >
    {row.name}
  </StyledTooltip>
);

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const superHeaderRenderer = (relative: boolean, hideForecasts: boolean) => () => (
  <SuperRow>
    <th />
    <th />
    <th colSpan={hideForecasts ? 1 : 2}>
      <SuperHeader>{relative ? 'Excess Return' : 'Return'}</SuperHeader>
    </th>
    <th colSpan={hideForecasts ? 1 : 2}>
      <SuperHeader>{relative ? 'Tracking Error' : 'Volatility'}</SuperHeader>
    </th>
    <th colSpan={hideForecasts ? 1 : 2}>
      <SuperHeader>{relative ? 'Information Ratio' : 'Sharpe'}</SuperHeader>
    </th>
  </SuperRow>
);

interface AttributionBlockProps {
  subject: AnalysisSubject;
  getRows: (AttributionView: AttributionView, AttributionSubject: AttributionSubject) => AttributionRow[];
  showTotal?: boolean;
  showTooltip?: boolean;
  cumulativeReturn?: boolean;
  relative: boolean;
  hasComparison: boolean;
  errorMessage?: string;
  errorCode?: number;
  emptyMessage?: string;
  trackingProps: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
  getAnalysisTimeFrame: (type: AttributionSubject) => TimeFrame;
  print?: boolean;
}

const AttributionBlock = ({
  subject,
  trackingProps,
  getRows,
  relative,
  cumulativeReturn,
  errorMessage,
  errorCode,
  emptyMessage,
  showTooltip,
  showTotal,
  hasComparison,
  getAnalysisTimeFrame,
  downloadMetaData,
  print,
}: AttributionBlockProps) => {
  const { settings } = useContext(UserContext);
  const { Images } = useContext(ThemeContext);
  const hideForecasts = !!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY];

  const title = 'Performance Attribution';
  const subtitle = hideForecasts
    ? 'Historical performance attribution for investments in a portfolio'
    : 'Historical and forecast performance attribution for investments in a portfolio';

  const subjectName =
    subject.type === 'investment'
      ? subject.isInvestmentInPortfolio
        ? subject.portfolio!.name
        : 'Master Portfolio'
      : subject.name;

  const { attributionsView, attributionsSubject, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);

  const view = useMemo(() => attributionsView, [attributionsView]);
  const setView = (updatedView: AttributionView) => onUpdateAnalysisViewParam({ attributionsView: updatedView });

  const selectedSubject = useMemo(() => attributionsSubject, [attributionsSubject]);
  const setSelectedSubject = (updatedSubject: AttributionSubject) =>
    onUpdateAnalysisViewParam({ attributionsSubject: updatedSubject });

  const columns: ColumnType[] = useMemo(
    () =>
      compact([
        {
          accessor: 'name',
          label: `${view === 'funds' ? 'Investment' : 'Strategy'} Name`,
          sortable: true,
          sorted: SORTDIR.ASC,
          cellRenderer: showTooltip
            ? nameRenderer(relative, !!cumulativeReturn, getAnalysisTimeFrame(selectedSubject))
            : undefined,
          excelCellRenderer: (row: AttributionRow) => ({ value: row.name }),
        },
        buildColumn({ accessor: 'weight', label: 'Weight', percentage: true }),
        buildColumn({
          accessor: 'returnContribution',
          label: 'Historical',
          percentage: true,
          cumulative: cumulativeReturn,
          singleColumn: hideForecasts,
        }),
        !hideForecasts &&
          buildColumn({
            accessor: 'returnForecastContribution',
            label: 'Forecast',
            percentage: true,
            left: true,
            hide: hideForecasts,
            print: !!print,
            tooltipLink: METRIC_DEFINITIONS_HREF,
          }),
        buildColumn({
          accessor: 'volatilityContribution',
          label: 'Historical',
          percentage: true,
          singleColumn: hideForecasts,
        }),
        !hideForecasts &&
          buildColumn({
            accessor: 'volatilityForecastContribution',
            label: 'Forecast',
            percentage: true,
            left: true,
            hide: hideForecasts,
            print: !!print,
            tooltipLink: METRIC_DEFINITIONS_HREF,
          }),
        buildColumn({
          accessor: 'sharpeContribution',
          label: 'Historical',
          percentage: false,
          singleColumn: hideForecasts,
        }),
        !hideForecasts &&
          buildColumn({
            accessor: 'sharpeForecastContribution',
            label: 'Forecast',
            percentage: false,
            left: true,
            hide: hideForecasts,
            print: !!print,
            tooltipLink: METRIC_DEFINITIONS_HREF,
          }),
      ]),
    [view, showTooltip, relative, cumulativeReturn, getAnalysisTimeFrame, selectedSubject, hideForecasts, print],
  );

  const hasComparisonOption = hasComparison && subject.hasSecondarySubject;
  const secondaryLabel = getSecondaryDisplayLabel(subject, undefined, 'Portfolio');
  const subjectOptions: DropMenuItem<AttributionSubject>[] = useMemo(
    () =>
      compact([
        { value: 'subject', label: subjectName },
        hasComparisonOption && {
          value: 'category',
          label: secondaryLabel,
        },
      ]),
    [hasComparisonOption, secondaryLabel, subjectName],
  );

  if (!hasComparisonOption && selectedSubject === 'category') {
    setSelectedSubject('subject');
  }

  const sumField = useCallback((field: string) => (value: number, row: AttributionRow) => row[field] + value, []);

  const InvestmentRows = useMemo(() => getRows('funds', selectedSubject), [getRows, selectedSubject]);
  const StrategyRows = useMemo(() => getRows('strategies', selectedSubject), [getRows, selectedSubject]);
  const rows = view === 'funds' ? InvestmentRows : StrategyRows;
  const hasStrategies = StrategyRows.length !== 0;

  if (!hasStrategies && view === 'strategies') {
    setView('funds');
  }

  const totals = useMemo(() => {
    return range(2, columns.length).map((i) => ({
      value: rows.reduce(sumField(columns[i]!.accessor!), 0),
      percentage: columns[i]!.percentage,
      cumulative: columns[i]!.cumulative,
    }));
  }, [columns, rows, sumField]);

  const excelData = useMemo(() => {
    const blankCell = { value: '' };
    const header: ExcelCell[] = compact([
      blankCell,
      blankCell,
      { value: 'Return', bold: true },
      !hideForecasts && blankCell,
      { value: 'Volatility', bold: true },
      !hideForecasts && blankCell,
      { value: 'Sharpe', bold: true },
      !hideForecasts && blankCell,
    ]);
    const total: ExcelCell[] = showTotal
      ? [
          { value: `Total${cumulativeReturn ? ' (Cumulative)' : ''}`, bold: true },
          { value: 1, percentage: true, digits: 1 },
          ...totals.map((t) => ({ ...t, digits: t.percentage ? 1 : 2 })),
        ]
      : [];
    return [header, ...exportToExcel(rows, columns), total];
  }, [columns, cumulativeReturn, hideForecasts, rows, showTotal, totals]);

  if (errorMessage) {
    return (
      <TrackFailedAnalysis {...trackingProps}>
        <StyledEmptyState
          header={
            errorCode === SupportedErrorCodes.NotAllowed
              ? `Get more with ${getAppTitle()} Pro`
              : 'Unable to run analysis'
          }
          message={
            errorCode === SupportedErrorCodes.NotAllowed
              ? `Everything in ${getAppTitle()} + More Analyses, Portfolio Analysis, and Portfolio Construction.`
              : errorMessage
          }
          backgroundImg={Images.performanceAttribution}
        />
      </TrackFailedAnalysis>
    );
  }

  const fileSubjectName =
    selectedSubject === 'subject' || subject.type !== 'portfolio'
      ? subject.name
      : getSecondaryDisplayLabel(subject, `${subject.name} as of`, `(${subject.secondaryPortfolio?.name})`);

  return (
    <TrackSuccessfulAnalysis {...trackingProps}>
      <DownloadableContentBlock
        header={(relative ? 'Relative ' : '') + title}
        subHeader={subtitle}
        downloadable={{
          png: true,
          disabled: rows.length === 0,
          excel: excelData,
          options: {
            fileName: `${fileSubjectName} - ${title}`,
            metaData: downloadMetaData,
          },
          tracking: {
            subjectType: subject.type,
            subjectId: subject.id,
            description: 'INVESTMENT_ATTRIBUTION',
            relativeToBenchmark: relative,
            userUploaded: !!subject?.fund?.userUploaded,
          },
        }}
        rightOptions={
          <ButtonWrappers className="not-downloadable">
            <StyledToggle onChange={setView} value={view} hide={!hasStrategies || subject.type === 'investment'}>
              <ToggleOption value="funds">Investments</ToggleOption>
              <ToggleOption value="strategies">Strategies</ToggleOption>
            </StyledToggle>
            <Menu items={subjectOptions} selected={selectedSubject} onChange={(o) => setSelectedSubject(o.value)} />
          </ButtonWrappers>
        }
      >
        <TableWrapper>
          {subject.isHistoricalPortfolio || rows.length === 0 ? (
            <ErrorMessage>
              <Headline2>Unable to run {title}</Headline2>
              {emptyMessage && <Body1>{emptyMessage}</Body1>}
            </ErrorMessage>
          ) : (
            <AttributionTable
              data={rows}
              columns={columns}
              sortKey="name"
              rowHeight={36}
              renderHead={superHeaderRenderer(relative, hideForecasts)}
            />
          )}
        </TableWrapper>
        {showTotal && rows.length > 1 && (
          <TotalRow scrollbar={rows.length > 5}>
            <TotalTitle>Total{cumulativeReturn && <i> (Cumulative)</i>}</TotalTitle>
            <TotalCell>{formatPercentage(1)}</TotalCell>
            {range(0, totals.length, hideForecasts ? 1 : 2).map((i) => {
              const historical = totals[i]!.percentage
                ? formatPercentage(totals[i]!.value)
                : formatNumber(totals[i]!.value);
              const forecast = hideForecasts
                ? undefined
                : totals[i + 1]!.percentage
                  ? formatPercentage(totals[i + 1]!.value)
                  : formatNumber(totals[i + 1]!.value);
              return (
                <React.Fragment key={i}>
                  <TotalCell centered={hideForecasts}>
                    {totals[i]!.cumulative ? <i>{historical}</i> : historical}
                  </TotalCell>
                  {!hideForecasts && (
                    <TotalForecastCell
                      hide={hideForecasts}
                      className={
                        hideForecasts
                          ? print
                            ? SpecialCssClasses.HiddenInDownloadable
                            : SpecialCssClasses.HideInReports
                          : ''
                      }
                    >
                      {forecast}
                    </TotalForecastCell>
                  )}
                </React.Fragment>
              );
            })}
          </TotalRow>
        )}
        <AttributionDateRangeMessage
          timeFrame={getAnalysisTimeFrame(selectedSubject)}
          subject={subject}
          selectedSubject={selectedSubject}
        />
      </DownloadableContentBlock>
    </TrackSuccessfulAnalysis>
  );
};

export default AttributionBlock;

const StyledToggle = styled(Toggle)<{ hide?: boolean }>`
  ${(props) => props.hide && 'display: none;'}
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 20px;
`;

const Menu = styled(DropMenu)`
  max-width: 250px;

  > div {
    height: 40px;
  }
` as FC<React.PropsWithChildren<SimpleDropMenuProps<AttributionSubject>>>;

const ButtonWrappers = styled.div`
  display: inline-flex;
  align-items: center;
`;

const TableWrapper = styled.div`
  border-top: 1px solid ${GetColor.Grey};
  padding-top: 20px;
  max-height: 238px;
  overflow: auto;
  @media print {
    max-height: none;
  }

  .${SpecialCssClasses.ExportAsImage} & {
    max-height: none;
  }
`;

const ErrorMessage = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TotalRow = styled.div<{ scrollbar: boolean }>`
  border-top: 2px solid ${GetColor.Grey};
  display: inline-flex;
  height: 36px;
  width: 100%;

  ${(props) => props.scrollbar && 'padding-right: 6px;'}
  div:last-child {
    padding-right: 20px;
  }

  div:first-child {
    padding-left: 20px;
  }

  align-items: center;
  font-size: 14px;
  @media print {
    font-size: 12px;
    padding-right: 0;
  }

  .${SpecialCssClasses.ExportAsImage} & {
    padding-right: 0;
  }
`;

const TotalTitle = styled.div`
  flex: 1;
`;

const TotalCell = styled.div<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'right')};
  width: ${({ centered }) => (centered ? '160px' : '80px')};
  padding: 3px 12px 3px 3px;
`;

const TotalForecastCell = styled(TotalCell)<{ hide: boolean }>`
  text-align: left;
  ${({ hide }) => hide && 'color: transparent;'}
`;

const SuperHeader = styled.div`
  border-top: solid 3px ${GetColor.Black};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  margin: 0 2px;
`;

const SuperRow = styled.tr`
  border-bottom: none !important;
`;

const TipTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const TipDate = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const TipStat = styled.div`
  padding-left: 10px;
  text-align: right;
`;

const TipWrapper = styled.div`
  width: 230px;
  padding: 10px 20px;
`;

const TipRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip)`
  height: unset;
`;

const StyledEmptyState = styled(EmptyState)`
  margin: 20px 0;
`;
