import { isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  allViewSubjectIds,
  allocatorAnalysisSubject,
  hasUnsavedChangesInPrivatesAllocator,
  openAllocatorSubject,
  openAllocatorSubjectConfig,
  openPrivateAllocatorPortfolio,
  originalAnalysisSubjectQuery,
} from 'venn-state';
import type { DropMenuItem } from 'venn-ui-kit';
import { CategorizedDropMenu, Tooltip } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { arePortfoliosEqual, useHasFF, withSuspense } from 'venn-utils';
import ConfirmationModal from '../../modals/confirmation/ConfirmationModal';
import StudioShimmerBlock from '../../studio-blocks/components/StudioShimmerBlock';
import { useSubjectSelectorItems } from './useSubjectSelectorItems';

interface StudioAllocatorSubjectSelector {
  disabled?: boolean;
  selectorMaxWidth: number;
}

const StudioAllocatorSubjectSelector = ({ disabled, selectorMaxWidth }: StudioAllocatorSubjectSelector) => {
  const hasApInRl = useHasFF('ap_in_rl_ff');

  const [selectedSubject, setSelectedSubject] = useRecoilState(openAllocatorSubject);
  const [selectedPrivatePortfolio, setSelectedPrivatePortfolio] = useRecoilState(openPrivateAllocatorPortfolio);
  const setOpenAllocatorSubjectConfig = useSetRecoilState(openAllocatorSubjectConfig);

  const subject = selectedPrivatePortfolio ? { privatePortfolioId: selectedPrivatePortfolio?.id } : selectedSubject;

  const [analysisSubjectInAllocator, setAnalysisSubjectInAllocator] = useRecoilState(allocatorAnalysisSubject(subject));
  const originalSelectedSubject = useRecoilValue(originalAnalysisSubjectQuery(subject));

  const categories = useSubjectSelectorItems();
  const viewSubjectIds = useRecoilValue(allViewSubjectIds);
  const allAnalysisSubjects = useMemo(
    () => categories.flatMap((category) => category.items).map((item) => item.value),
    [categories],
  );

  useEffect(() => {
    if (analysisSubjectInAllocator) {
      return;
    }
    const analysisSubject = allAnalysisSubjects.find((analysisSubject) =>
      !isNil(subject?.portfolioId)
        ? analysisSubject.portfolio?.id === subject?.portfolioId
        : analysisSubject.privatePortfolio?.id === subject?.privatePortfolioId,
    );
    if (!isNil(analysisSubject)) {
      setAnalysisSubjectInAllocator(analysisSubject);
    }
  }, [
    analysisSubjectInAllocator,
    setAnalysisSubjectInAllocator,
    allAnalysisSubjects,
    subject?.portfolioId,
    subject?.privatePortfolioId,
  ]);

  const [selectedSubjectToConfirm, setSelectedSubjectToConfirm] = useState<AnalysisSubject | undefined>();

  const onChangeSelectedItem = useCallback(
    (analysisSubject: AnalysisSubject) => {
      setAnalysisSubjectInAllocator(undefined);
      if (analysisSubject.type === 'portfolio') {
        setSelectedSubject({ portfolioId: analysisSubject.portfolio?.id });
        setSelectedPrivatePortfolio(undefined);
      } else {
        setSelectedPrivatePortfolio(analysisSubject.privatePortfolio);
        setSelectedSubject(undefined);
      }
    },
    [setAnalysisSubjectInAllocator, setSelectedSubject, setSelectedPrivatePortfolio],
  );

  const hasUnsavedPortfolioChanges = useMemo(() => {
    const currentPortfolio = analysisSubjectInAllocator?.portfolio;
    const originalPortfolio = originalSelectedSubject?.portfolio;
    return isNil(currentPortfolio) || isNil(originalPortfolio)
      ? isNil(currentPortfolio) !== isNil(originalPortfolio)
      : !arePortfoliosEqual(currentPortfolio, originalPortfolio);
  }, [analysisSubjectInAllocator, originalSelectedSubject]);

  const hasUnsavedPrivatePortfolioChanges = useRecoilValue(hasUnsavedChangesInPrivatesAllocator);

  const hasUnsavedChanges = !isNil(subject?.portfolioId)
    ? hasUnsavedPortfolioChanges
    : hasUnsavedPrivatePortfolioChanges;

  const hasUnsavedSubjectConfig = !isNil(analysisSubjectInAllocator?.secondaryPortfolio);

  const onSelectItem = useCallback(
    ({ value: analysisSubject }: DropMenuItem<AnalysisSubject>) => {
      if (isNil(analysisSubject.portfolio) && isNil(analysisSubject.privatePortfolio)) {
        return;
      }

      if (!isNil(subject?.portfolioId) && analysisSubject.portfolio?.id === subject?.portfolioId) {
        return;
      }
      if (!isNil(subject?.privatePortfolioId) && analysisSubject.privatePortfolio?.id === subject?.privatePortfolioId) {
        return;
      }

      if (hasUnsavedSubjectConfig) {
        setSelectedSubjectToConfirm(analysisSubject);
      } else {
        onChangeSelectedItem(analysisSubject);
      }
    },
    [subject?.portfolioId, subject?.privatePortfolioId, hasUnsavedSubjectConfig, onChangeSelectedItem],
  );

  // If a subject is deleted while it has unsaved changes, we should continue to keep it open.
  const [deletedSubjectToRetain, setDeletedSubjectToRetain] = useState<string | undefined>(undefined);

  useEffect(
    function clearWhenSubjectDeleted() {
      if (!hasApInRl) return;
      const currentSubjectId = String(subject?.portfolioId ?? subject?.privatePortfolioId ?? '');
      if (deletedSubjectToRetain === currentSubjectId) {
        return;
      }
      const hasDeletedSubject = currentSubjectId && !viewSubjectIds.includes(currentSubjectId);
      if (hasDeletedSubject) {
        if (hasUnsavedChanges) {
          setDeletedSubjectToRetain(currentSubjectId);
          return;
        }

        setSelectedSubject(undefined);
        setOpenAllocatorSubjectConfig(undefined);
        setAnalysisSubjectInAllocator(undefined);
        setSelectedPrivatePortfolio(undefined);
        setSelectedSubjectToConfirm(undefined);
      } else if (deletedSubjectToRetain) {
        setDeletedSubjectToRetain(undefined);
      }
    },
    [
      hasUnsavedChanges,
      allAnalysisSubjects,
      analysisSubjectInAllocator,
      setAnalysisSubjectInAllocator,
      setSelectedPrivatePortfolio,
      setSelectedSubject,
      subject?.portfolioId,
      subject?.privatePortfolioId,
      viewSubjectIds,
      deletedSubjectToRetain,
      setOpenAllocatorSubjectConfig,
      hasApInRl,
    ],
  );

  return (
    <>
      <Tooltip
        content="Save or reset current portfolio changes to view another portfolio allocations."
        isHidden={!hasUnsavedChanges}
        usePortal
        className="w-full"
      >
        <CategorizedDropMenu<AnalysisSubject>
          categories={categories}
          selected={
            isNil(subject?.privatePortfolioId) && isNil(subject?.portfolioId) && hasApInRl
              ? undefined
              : analysisSubjectInAllocator
          }
          disabled={disabled || hasUnsavedChanges || categories.length === 0}
          onChange={onSelectItem}
          triggerStyle={{ maxWidth: selectorMaxWidth }}
          placeholder="Select a portfolio"
          refreshedStyling
          tooltipUsePortal
        />
      </Tooltip>
      {!isNil(selectedSubjectToConfirm) && (
        <ConfirmationModal
          destructive
          className="allocator-subject-switcher-confirmation"
          header="Confirmation"
          subhead="Are you sure you want to open another portfolio allocations in this panel?"
          text="Currently selected comparison subject will be removed from the analysis."
          onCancel={() => setSelectedSubjectToConfirm(undefined)}
          onProceed={() => {
            onChangeSelectedItem(selectedSubjectToConfirm);
            setSelectedSubjectToConfirm(undefined);
          }}
        />
      )}
    </>
  );
};

export default withSuspense(<StudioShimmerBlock height={35} />, StudioAllocatorSubjectSelector);
