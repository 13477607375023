import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Button, Icon, ColorUtils, GetColor, TriggerableMenu } from 'venn-ui-kit';
import SplitButtonDropdownItem from './SplitButtonDropdownItem';

export interface SplitButtonDropdownOption {
  className?: string;
  label: string;
  onClick: (triggerKey: string) => void;
}

export interface SplitButtonDropdownProps {
  buttonClassName?: string;
  className?: string;
  mainButtonClassName?: string;
  options?: SplitButtonDropdownOption[];
  disabled?: boolean;
}

interface SplitButtonDropdownState {
  open: boolean;
}

const SPLIT_BUTTON_TRIGGER = 'button_trigger';
const SPLIT_MENU_ITEM_TRIGGER = 'menu_item_trigger';

export class SplitButtonDropdown extends PureComponent<SplitButtonDropdownProps, SplitButtonDropdownState> {
  static defaultProps: SplitButtonDropdownProps = {
    options: [],
  };

  constructor(props: SplitButtonDropdownProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { buttonClassName, className, disabled, mainButtonClassName } = this.props;
    const { open } = this.state;

    const options = this.props.options
      ? this.props.options.map((option: SplitButtonDropdownOption) => ({
          ...option,
          children: (
            <SplitButtonDropdownItem label={option.label} onClick={() => option.onClick(SPLIT_MENU_ITEM_TRIGGER)} />
          ),
        }))
      : [];

    return (
      <SplitButtonDropdownContainer>
        <SplitButton
          className={mainButtonClassName}
          disabled={disabled}
          onClick={() => options[0]!.onClick(SPLIT_BUTTON_TRIGGER)}
        >
          {options[0]!.label}
        </SplitButton>
        <StyledTriggerableMenu
          options={options}
          disabled={disabled}
          className={className}
          onToggle={this.toggleOpen}
          innerClassName="split-button-dropdown"
        >
          <SplitButtonTrigger className={buttonClassName} disabled={disabled}>
            <Icon type={open ? 'angle-up' : 'angle-down'} prefix="fas" />
          </SplitButtonTrigger>
        </StyledTriggerableMenu>
      </SplitButtonDropdownContainer>
    );
  }
}

const StyledTriggerableMenu = styled(TriggerableMenu)`
  .split-button-dropdown {
    transform: translate3d(-80%, 6px, 0);
    box-shadow: 0 2px 10px 0 ${ColorUtils.hex2rgbaFrom(GetColor.Black, 0.4)};
  }
`;

const SplitButtonTrigger = styled(Button)`
  border-radius: 0 2px 2px 0;
  min-width: 40px;
  max-width: 40px;
`;

const SplitButton = styled(Button)`
  border-radius: 2px 0 0 2px;
  margin-right: -1px;
`;

const SplitButtonDropdownContainer = styled.span`
  display: inline;
`;

export default SplitButtonDropdown;
