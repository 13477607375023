import type { HeatMapTypes, XAxisLabel } from '../../../heat-map/Types';
import { generateColor } from '../../../heat-map/utils';
import type {
  CorrelationMatrix,
  FundCorrelation,
  FundCorrelationData,
  FundCorrelationRowObject,
  FundCorrelationValue,
  NamedEntity,
  Portfolio,
} from 'venn-api';
import type { AnalysisLabelType, AnyDuringEslintMigration } from 'venn-utils';
import { flattenNode, logMessageToSentry } from 'venn-utils';
import { sortBy, groupBy, values, sumBy, compact, isNil } from 'lodash';
import type { VennColors } from 'venn-ui-kit';

export const MAX_INVESTMENTS = 20;

function getId(entity: NamedEntity): string {
  return entity.id as string;
}

export const buildDataForPortfolio = (
  strategy: Portfolio | undefined,
  matrix: CorrelationMatrix | undefined,
  residual: boolean,
  portfolioName: string,
  trailingLabels: AnalysisLabelType[],
  colors: VennColors,
): { flatNames?: XAxisLabel[]; roots?: HeatMapTypes.Root[]; investmentsCount: number } => {
  if (
    !strategy ||
    !matrix ||
    !matrix.correlations ||
    matrix.correlations.length === 0 ||
    !matrix.correlations[0]!.correlations
  ) {
    return { investmentsCount: 0 };
  }

  const strategyFunds = sortBy(
    values(
      groupBy(
        flattenNode(strategy).filter((node) => !!node.fund),
        'fund.id',
      ),
    ).map((sameFundGroup) => ({
      ...sameFundGroup[0]!,
      allocation: sumBy(sameFundGroup, 'allocation'),
    })),
    (node) => -node.allocation,
  );

  const trailingItemsCount = trailingLabels.length;

  const top20StrategyFunds = strategyFunds.slice(0, MAX_INVESTMENTS - trailingItemsCount);

  const fundIdToIndex: { [key: string]: number } = {};
  top20StrategyFunds.forEach((fund: Portfolio, idx: number) => {
    if (!fund.fund) {
      logMessageToSentry('Failed to map fundId to Index because of missing fund');
      return;
    }
    fundIdToIndex[fund.fund.id] = idx;
  });

  const flatNamesCorrelations = matrix.correlations[0]!.correlations;
  const flatNames: XAxisLabel[] = sortBy(
    flatNamesCorrelations
      .slice(0, flatNamesCorrelations.length - trailingItemsCount)
      .map(
        (row) =>
          ({
            name: row.entity.name,
            id: getId(row.entity),
          }) as XAxisLabel,
      )
      .filter((flatName: XAxisLabel) => !isNil(flatName.id) && fundIdToIndex[flatName.id] !== undefined),
    [(flatName: XAxisLabel) => fundIdToIndex[flatName.id!]],
  ).concat(
    ...compact(
      flatNamesCorrelations.slice(flatNamesCorrelations.length - trailingItemsCount).map((_, idx) =>
        trailingLabels[idx]
          ? {
              name: trailingLabels[idx]!,
              id: trailingLabels[idx]!,
            }
          : undefined,
      ),
    ),
  );

  const sortedCorrelations = sortBy(
    matrix.correlations
      .slice(0, matrix.correlations.length - trailingItemsCount)
      .filter((row: FundCorrelationRowObject) => fundIdToIndex[getId(row.entity)] !== undefined),
    (row: FundCorrelationRowObject) => fundIdToIndex[getId(row.entity)],
  );
  const trailingCorrelations = matrix.correlations.slice(matrix.correlations.length - trailingItemsCount);

  const colorGetter = generateColor(-1.0, 1.0, colors);
  const roots: HeatMapTypes.Root[] = [...sortedCorrelations, ...trailingCorrelations].map((row, idx) => {
    const isTrailing = idx >= sortedCorrelations.length;

    const name = isTrailing ? trailingLabels[idx - sortedCorrelations.length]! : row.entity.name;
    const correlations = row.correlations.slice(0, row.correlations.length - trailingItemsCount);
    const sortedRowCorrelations = sortBy(
      correlations.filter((c: FundCorrelationValue) => fundIdToIndex[getId(c.entity)] !== undefined),
      (c: FundCorrelationValue) => fundIdToIndex[getId(c.entity)],
    ).concat(row.correlations.slice(row.correlations.length - trailingItemsCount));

    return {
      name,
      type: isTrailing ? name : undefined,
      series: [
        {
          name: portfolioName,
          portfolioType: name,
          data: sortedRowCorrelations.slice(0, idx + 1).map(({ correlation }) => ({
            value: correlation,
            // TODO: (VENN-20577 / TYPES) the colorGetter(correlation && correlation.toFixed(6)) logic doesn't make much sense?
            color: colorGetter(correlation && (correlation?.toFixed(6) as AnyDuringEslintMigration)) ?? 'none',
            nonSignificant: false,
            residual,
          })),
          colors: { positive: colors.DivergingColor.B3, negative: colors.DivergingColor.A3 },
        },
      ],
    };
  });

  return { flatNames, roots, investmentsCount: strategyFunds.length + trailingItemsCount };
};

export const buildDataForFund = (
  fundCorrelations: FundCorrelation[],
  residual: boolean,
  labels: AnalysisLabelType[],
  colors: VennColors,
): { flatNames: XAxisLabel[]; roots: HeatMapTypes.Root[]; investmentsCount: number } => {
  const colorGetter = generateColor(-1.0, 1.0, colors);
  let flatNames: XAxisLabel[] = [];
  let investmentsCount = 0;
  const roots: HeatMapTypes.Root[] = [];
  fundCorrelations.forEach((fundCorrelation, index) => {
    if (!fundCorrelation) {
      return;
    }
    const masterFunds = sortBy(
      values(
        groupBy(
          flattenNode(fundCorrelation.fundCorrelationData).filter((node) => node.type === 'FUND'),
          'fundId',
        ),
      ).map((sameFundsGroup) => ({
        ...sameFundsGroup[0]!,
        allocation: sumBy(sameFundsGroup, 'allocation'),
      })),
      (node) => -node.allocation,
    );
    if (index === 0) {
      investmentsCount = masterFunds.length;
      flatNames = masterFunds
        .map((node) => ({ name: node.name, type: node.type, id: node.fundId }))
        .slice(0, MAX_INVESTMENTS);
    }
    const flatData: HeatMapTypes.DataEntity[] = masterFunds.map((node: FundCorrelationData) => ({
      value: node.correlation,
      // TODO: (VENN-20577 / TYPES) the colorGetter(correlation && correlation.toFixed(6)) logic doesn't make much sense?
      color: colorGetter(node.correlation && (node.correlation.toFixed(6) as AnyDuringEslintMigration)) ?? 'none',
      nonSignificant: false,
      residual,
    }));
    roots.push({
      name: labels[index]!,
      type: fundCorrelation.name,
      series: [
        {
          name: fundCorrelation.name,
          data: flatData.slice(0, MAX_INVESTMENTS),
          portfolioType: labels[index],
          colors: { positive: colors.DivergingColor.B3, negative: colors.DivergingColor.A3 },
        },
      ],
    });
  });
  return { flatNames, roots, investmentsCount };
};
