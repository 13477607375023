import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GetColor, Tooltip } from 'venn-ui-kit';
import { isNil } from 'lodash';

export interface SmallMultiToggleItem {
  icon: ReactNode;
  value: string;
  tooltip?: string;
}

interface SmallMultiToggleProps {
  selectedIdx?: number;
  items: SmallMultiToggleItem[];
  onChange: (selected: string, idx: number) => void;
}

const isIdxInvalid = (selectedIdx: number | undefined, itemsCount: number): boolean =>
  isNil(selectedIdx) || selectedIdx < 0 || selectedIdx >= itemsCount;

const SmallMultiToggle = ({ items, selectedIdx, onChange }: SmallMultiToggleProps) => {
  const [selected, setSelected] = useState<number>(isIdxInvalid(selectedIdx, items.length) ? 0 : selectedIdx!);

  useEffect(() => {
    if (isIdxInvalid(selectedIdx, items.length) && items.length > 0) {
      onChange(items[0]!.value, 0);
    } else if (!isNil(selectedIdx) && !isIdxInvalid(selectedIdx, items.length)) {
      setSelected(selectedIdx);
    }
  }, [selectedIdx, items, onChange]);

  if (items.length === 0) {
    return null;
  }

  return (
    <ToggleContainer length={items.length} className="qa-small-multi-toggle">
      {items.map((item, idx) => (
        <Tooltip content={item.tooltip} isHidden={isNil(item.tooltip)} key={item.value}>
          <Item
            type="button"
            selected={idx === selected}
            onClick={() => {
              setSelected(idx);
              onChange(item.value, idx);
            }}
          >
            {item.icon}
          </Item>
        </Tooltip>
      ))}
    </ToggleContainer>
  );
};

export default SmallMultiToggle;

const ITEM_WIDTH = 35;
const HEIGHT = 20;
const RADIUS = 15;

const ToggleContainer = styled.div<{ length: number }>`
  height: ${HEIGHT}px;
  border-radius: ${RADIUS}px;
  background-color: ${GetColor.Grey};
  width: ${({ length }) => length * ITEM_WIDTH}px;
  display: flex;
`;

const Item = styled.button<{ selected: boolean }>`
  font-size: 12px;
  border: 1px solid ${GetColor.Grey};
  border-radius: ${RADIUS}px;
  height: ${HEIGHT}px;
  width: ${ITEM_WIDTH}px;

  color: ${GetColor.DarkGrey};
  background-color: ${GetColor.Grey};
  ${(props) =>
    props.selected &&
    css`
      color: ${GetColor.Black};
      background-color: ${GetColor.White};
      font-weight: bold;
    `}
  &:hover {
    color: ${GetColor.Primary.Dark};
  }
`;
