import React, { useContext, useState } from 'react';
import type { BulkActionProps } from './types';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { StyledButtonIcon } from './styles';
import { getPortfolioLabPath } from 'venn-utils';
import { OptimizeInPortfolioModal, UserContext } from 'venn-components';
import type { Fund } from 'venn-api';
import { TooltipPosition } from 'venn-ui-kit';
import { noop } from 'lodash';

interface OptimizeItemsProps extends BulkActionProps, RouteComponentProps {}

const OptimizeItems = ({ items, onComplete, history }: OptimizeItemsProps) => {
  const { hasPermission } = useContext(UserContext);
  const [optimizeModal, setOptimizeModal] = useState(false);

  const portfoliosCount = items.filter((item) => item.portfolioId).length;
  const investmentsCount = items.length - portfoliosCount;

  const investmentsOnly = portfoliosCount === 0 && investmentsCount > 0;
  const singlePortfolio = portfoliosCount === 1 && investmentsCount === 0;

  // No "optimize" bulk action for multiple portfolios or mixed portfolios and investments
  if (!investmentsOnly && !singlePortfolio) {
    return null;
  }

  const singlePortfolioId = items[0]!.portfolioId;

  const actionText = singlePortfolio ? 'Optimize with Portfolio Lab' : 'Optimize in a portfolio';
  const onClick = () => {
    if (singlePortfolioId) {
      onComplete('Optimize', noop);
      history.push(getPortfolioLabPath(singlePortfolioId));
    } else {
      setOptimizeModal(true);
    }
  };

  if (!hasPermission('OPTIMIZATION')) {
    return null;
  }

  return (
    <>
      <StyledButtonIcon
        className="qa-bulk-optimize"
        onClick={onClick}
        iconType="flask"
        tooltip={actionText}
        tooltipPosition={TooltipPosition.Bottom}
        text={`${actionText} (${items.length})`}
      />
      {investmentsOnly && optimizeModal && (
        <OptimizeInPortfolioModal
          funds={items.map(
            (item) =>
              ({
                id: item.fundId,
                name: item.name,
              }) as Fund,
          )}
          closeModal={() => {
            onComplete('Optimize', noop);
            setOptimizeModal(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(OptimizeItems);
