import React, { useMemo, useRef, useState, useEffect, useContext } from 'react';
import type { AnalysisTrackingProps, SharedProps, PerformanceAnalysisContribution } from '../../types';
import {
  DownloadableContentBlock,
  FailedAnalysisInfo,
  PrintContainerDimensions,
  TrackSuccessfulAnalysis,
  UserContext,
} from 'venn-components';
import PerformanceSummaryTable from './PerformanceSummaryTable';
import toData, { extractResidualContribution } from './logic';
import { TableContainer } from './Table';
import styled from 'styled-components';
import { SpecialCssClasses, getAnalysisRequest, useApi, logExceptionIntoSentry } from 'venn-utils';
import type { AnalysisTypeEnum } from 'venn-api';
import { HIDE_FORECASTS_IN_EXPORTS_KEY, analysis } from 'venn-api';

export interface ResidualPerformanceSummaryProps extends SharedProps, AnalysisTrackingProps {}

const performanceContributions: AnalysisTypeEnum[] = ['FUND_FACTOR_CONTRIBUTION'];

export const ResidualPerformanceSummary = ({
  analysisConfig,
  analyses,
  onResetTimeFrame,
  trackingProps,
  downloadMetaData,
  updateAnalysisStatusForTracking,
}: ResidualPerformanceSummaryProps) => {
  const { settings } = useContext(UserContext);
  const hideForecasts = !!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY];
  const { subject, relative, category, trackingId, selectedPeriod, selectedTimeFrame } = analysisConfig;
  const categoryActive = category === 'ON';

  // `trackingIdState` is used to determine when NOT to refresh analysis.
  const [trackingIdState, setTrackingIdState] = useState<number>(-1);
  const mounted = useRef(true);
  const getContributionAnalysis = useApi(analysis);
  const [contributions, setContributions] = useState<PerformanceAnalysisContribution | undefined>();

  const residualReturns = analyses?.results?.residualReturnsAnalyses;
  const performance = useMemo(
    () => ({
      forecast: {
        subject: residualReturns?.subject?.forecastedResidualPerformanceSummary,
        benchmark: residualReturns?.benchmark?.forecastedResidualPerformanceSummary,
        category: residualReturns?.category?.forecastedResidualPerformanceSummary,
      },
      historical: {
        subject: residualReturns?.subject?.historicalResidualPerformanceSummary,
        benchmark: residualReturns?.benchmark?.historicalResidualPerformanceSummary,
        category: residualReturns?.category?.historicalResidualPerformanceSummary,
      },
    }),
    [residualReturns],
  );

  useEffect(() => {
    const fetchContributionsAnalysis = async () => {
      const configs = getAnalysisRequest(
        performanceContributions,
        subject,
        selectedTimeFrame,
        relative,
        categoryActive,
        selectedPeriod,
        analysisConfig.trackingId,
      );
      try {
        const { content } = await getContributionAnalysis(configs);
        if (mounted.current) {
          const contributionsRes = extractResidualContribution(configs ?? {}, content.analyses[0]!, relative);
          setContributions(contributionsRes);
        }
      } catch (e) {
        await e;
        if (e.name === 'AbortError') {
          return;
        }

        // Suppress error
        logExceptionIntoSentry(e);
      }
    };

    if (trackingIdState !== analysisConfig.trackingId) {
      fetchContributionsAnalysis();
      setTrackingIdState(analysisConfig.trackingId);
    }
  }, [
    trackingIdState,
    analyses,
    subject,
    relative,
    categoryActive,
    performance,
    contributions,
    analysisConfig.trackingId,
    selectedPeriod,
    selectedTimeFrame,
    getContributionAnalysis,
  ]);

  const { metrics, columns, excelData } = useMemo(
    () =>
      subject
        ? toData(
            analyses,
            subject.type,
            relative,
            categoryActive,
            contributions,
            subject.secondaryPortfolio,
            subject.secondaryLabel,
            () => {},
            performance,
            true,
          )
        : { metrics: undefined, columns: undefined, excelData: undefined },
    [analyses, subject, relative, categoryActive, performance, contributions],
  );

  const analysisError = residualReturns?.message;
  if (analysisError !== undefined) {
    return (
      <FailedAnalysisInfo
        subject={subject}
        analysesPeriod={analyses?.analysesPeriod}
        onResetAnalysisPeriod={onResetTimeFrame}
        error={{
          message: analysisError.text,
          code: analysisError.code,
        }}
        regressionName="Residual Performance Summary"
        relativeToBenchmark={relative}
        blockNames={['RESIDUAL_PERFORMANCE_SUMMARY']}
        blockTitles={['Residual Performance Summary']}
        trackingId={trackingId}
        updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
      />
    );
  }

  if (!subject || !metrics || !columns) {
    return null;
  }

  return (
    <TrackSuccessfulAnalysis {...trackingProps}>
      <DownloadableContentBlock
        header="Residual Performance Summary"
        subHeader={
          <SubHeader>
            Historical residual calculated using static betas from the selected period
            {!hideForecasts && <> and forecast residual calculated using your long-term forecast configurations.</>}
          </SubHeader>
        }
        downloadable={{
          png: true,
          excel: excelData,
          options: {
            fileName: `${subject.name} - Residual Performance Summary`,
            width: 800,
            metaData: downloadMetaData,
          },
          tracking: {
            subjectType: subject.type,
            subjectId: subject.id,
            description: 'RESIDUAL_PERFORMANCE_SUMMARY',
            relativeToBenchmark: relative,
            userUploaded: subject.userUploaded,
          },
        }}
      >
        <TableContainer>
          <PrintContainerDimensions>
            {({ print }) => (
              <PerformanceSummaryTable
                metrics={metrics}
                columns={columns}
                subject={subject}
                relative={relative}
                print={print}
              />
            )}
          </PrintContainerDimensions>
        </TableContainer>
      </DownloadableContentBlock>
    </TrackSuccessfulAnalysis>
  );
};

const SubHeader = styled.div`
  .${SpecialCssClasses.ExportAsImage} & {
    max-width: 600px;
  }
`;
