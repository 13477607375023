import React, { useContext } from 'react';
import type { AnalysisGroup, AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel } from 'venn-utils';
import type { FrequencyEnum, PercentageDrawdownAnalysis } from 'venn-api';
import type { SerieValue } from '../types';
import { createVennLineChartConfig } from '../chart-config-logic';
import type { AnalysisChartLegendProps } from '../internal/AnalysisChartLegend';
import AnalysisChartLegend from '../internal/AnalysisChartLegend';
import { ThemeContext } from 'styled-components';
import { useAppPrintMode } from '../../../print';
import { VennHighchart } from '../../../highchart/Highchart';

interface PercentageDrawdownChartProps {
  data: AnalysisGroup<PercentageDrawdownAnalysis>;
  subject: AnalysisSubject;
  frequency?: FrequencyEnum;
}

const createSerieValue = (name: string, color: string, data: number[][]) => {
  return {
    name,
    legendLabel: name,
    color,
    data: {
      name,
      type: 'line' as const,
      color,
      data: data.map((d) => [d[0]!, d[1]!] as [number, number]),
    },
  };
};

const PercentageDrawdownChart = ({ data, subject, frequency }: PercentageDrawdownChartProps) => {
  const series: SerieValue[] = [];

  const legendProps: AnalysisChartLegendProps = {};

  const isInvestment = subject.type === 'investment';
  const isPortfolio = subject.type === 'portfolio';

  const theme = useContext(ThemeContext);
  const print = useAppPrintMode().inPrintMode;

  const getLineColors = () => {
    const lineChartColors = theme.Schemes.LineChartColors;
    return [lineChartColors.mainColor, lineChartColors.secondaryColor, lineChartColors.comparisonColor];
  };
  const colors = getLineColors();
  let colorIndex = 0;

  if (data.subject) {
    const mainSerie = createSerieValue(subject.name, colors[colorIndex++]!, data.subject.series);

    series.push(mainSerie);
    legendProps.mainSerie = mainSerie;
  }

  if (isPortfolio && data.category && subject.hasSecondarySubject) {
    const secondaryLabel = getSecondaryDisplayLabel(subject);
    const color = colors[colorIndex++]!;
    const secondarySerie = createSerieValue(secondaryLabel, color, data.category.series);

    series.push(secondarySerie);
    legendProps.secondarySerie = secondarySerie;
  }

  if (data.benchmark && subject.activeBenchmarkName) {
    const color = colors[colorIndex++]!;
    const benchmarkSerie = createSerieValue(subject.activeBenchmarkName, color, data.benchmark.series);

    series.push(benchmarkSerie);
    legendProps.benchmarkSerie = benchmarkSerie;
  }

  if (isInvestment && data.category && subject.categoryGroup) {
    const secondaryLabel = subject.categoryGroup.name;
    const color = colors[colorIndex++]!;
    const secondarySerie = createSerieValue(secondaryLabel, color, data.category.series);

    series.push(secondarySerie);
    legendProps.categorySerie = secondarySerie;
  }

  const options = createVennLineChartConfig(
    series,
    theme,
    print ? 7.5 : 10.5,
    'percent',
    frequency,
    undefined,
    false,
    true,
  );

  return (
    <>
      <AnalysisChartLegend {...legendProps} />
      <VennHighchart className="qa-percentage-drawdown-chart" options={options} />
    </>
  );
};

export default PercentageDrawdownChart;
