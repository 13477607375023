import React from 'react';
import type { FactorLensWithReturns } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import type { FactorDescription } from '../factor-chart/tooltips/FactorSummaryTooltips';
import type { DownloadMetaData } from '../downloadable';

export type FactorAnalysisContextValue = {
  isHistoricalAnalysis: boolean;
  analysisSubject: AnalysisSubject;
  relative: boolean;
  categoryActive: boolean;
  activeFactorLens: FactorLensWithReturns | undefined;
  factorsDescriptions: FactorDescription[];
  defaultFactorsName: string | undefined;
  portfolioHasMultipleFunds: boolean;
  downloadMetaData: DownloadMetaData | undefined;
};

export const defaultFactorAnalysisContextValue: FactorAnalysisContextValue = {
  isHistoricalAnalysis: false,
  analysisSubject: {} as AnalysisSubject,
  relative: false,
  categoryActive: false,
  activeFactorLens: undefined,
  factorsDescriptions: [] as FactorDescription[],
  defaultFactorsName: undefined,
  portfolioHasMultipleFunds: false,
  downloadMetaData: undefined,
};

export const FactorAnalysisContext = React.createContext<FactorAnalysisContextValue>(defaultFactorAnalysisContextValue);

FactorAnalysisContext.displayName = 'FactorAnalysisContext';
