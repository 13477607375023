const scrollToElement = (
  target: HTMLElement | Element | null,
  startOffset: number,
  targetContainer?: HTMLElement | Element | null,
  scrollBehavior?: ScrollBehavior,
) => {
  if (!target) {
    return;
  }
  const scrollMain: HTMLElement | Element = targetContainer ?? document.getElementsByTagName('main')[0]!;
  if (!scrollMain) {
    return;
  }
  const top = calculateScrollTo(target, scrollMain, startOffset);
  if (!scrollMain.scroll) {
    return;
  }
  scrollMain.scroll({ top, behavior: scrollBehavior ?? 'smooth' });
};

export const scrollToElementById = (id: string, startOffset = 0, targetContainer?: HTMLElement | Element) => {
  const target = document.getElementById(id);
  scrollToElement(target, startOffset, targetContainer);
};

export const scrollToElementByQuerySelector = (
  querySelector: string,
  startOffset = 0,
  targetContainer?: HTMLElement | Element | null,
  scrollBehavior?: ScrollBehavior,
) => {
  const target = document.querySelector(querySelector);
  scrollToElement(target, startOffset, targetContainer, scrollBehavior);
};

export const calculateScrollTo = (target: HTMLElement | Element, container: HTMLElement | Element, startOffset = 0) => {
  const targetTop = target.getBoundingClientRect().top;
  const scrollParent = container.firstElementChild?.getBoundingClientRect()?.top ?? 0;
  return targetTop - scrollParent - startOffset;
};
