import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { ParsedPortfolioOption } from '../../review/ParsedPortfolioOption';
import { ScrollableSectionWithStickyHeader } from '../../review/ScrollableSectionWithStickyHeader';
import styled from 'styled-components';
import { UPLOAD_LEFT_CONTAINER_WIDTH } from '../../shared/layout';
import { GetColor } from 'venn-ui-kit';
import {
  MultiPortfolioReviewContext,
  type PortfolioParseResultWithReviewMetadata,
} from './MultiPortfolioReviewContext';

export const LeftSideBar = () => {
  const {
    data: { existingParsedData, newParsedData, excludedParsedData, selectedIndex },
    actions: { selectAnotherPortfolio },
  } = useContext(MultiPortfolioReviewContext);
  const renderLeftSidebarSection = ({
    title,
    parsedResults,
    index,
  }: {
    title: string;
    parsedResults: PortfolioParseResultWithReviewMetadata[];
    index: number;
  }) => {
    if (isEmpty(parsedResults)) {
      return null;
    }

    const isSelected = (index: number) =>
      index >= 0 && index < parsedResults.length && parsedResults[index]!.originalIndex === selectedIndex;

    return (
      <ScrollableSectionWithStickyHeader groupIndex={index} title={title} count={parsedResults.length}>
        {parsedResults.map((data, resultIndex) => (
          <ParsedPortfolioOption
            setSelectedIndex={selectAnotherPortfolio}
            isNextItemSelected={isSelected((resultIndex + 1) % parsedResults.length)}
            selected={isSelected(resultIndex)}
            data={data}
            key={data.originalIndex}
          />
        ))}
      </ScrollableSectionWithStickyHeader>
    );
  };
  const leftSideBarSectionProps = [
    {
      title: 'EXISTING PORTFOLIOS',
      parsedResults: existingParsedData,
    },
    {
      title: 'NEW PORTFOLIOS',
      parsedResults: newParsedData,
    },
    {
      title: 'EXCLUDED FROM UPLOAD',
      parsedResults: excludedParsedData,
    },
  ]
    .filter(({ parsedResults }) => !isEmpty(parsedResults))
    .map(({ title, parsedResults }, index) => ({ title, parsedResults, index }));
  return (
    <LeftContainer>
      {leftSideBarSectionProps.map(({ title, parsedResults, index }) =>
        renderLeftSidebarSection({
          title,
          parsedResults,
          index,
        }),
      )}
    </LeftContainer>
  );
};

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${UPLOAD_LEFT_CONTAINER_WIDTH}px;
  overflow: auto;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid ${GetColor.GreyScale.Grey30};
`;
