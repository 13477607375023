import { assert, type Nominal } from 'venn-utils';
import { isSubjectKey, subjectToKeyString, type SubjectKey } from './configuration/subjectKey';
import type { StudioRequestSubject, Subject } from './types';

export type CustomMetricRow = { [columnKey: string]: string | undefined };

/** Row-major representation of custom field data. */
export type CustomMetricGrid = { [rowKey: string]: CustomMetricRow | undefined };

export type PCBlockCustomMetricRow = {
  [columnKey: `${PortfolioComparisonColumnGroupKey}:${string}`]: string | undefined;
};
export type PCBlockCustomMetricGrid = {
  [rowKey: PortfolioComparisonRowKey]: PCBlockCustomMetricRow | undefined;
};

export type PortfolioComparisonRowKey = Nominal<string, 'PortfolioComparisonRowKey'>;
export function createPCBlockRowKey(rowIdPath: string[]): PortfolioComparisonRowKey {
  return rowIdPath.join('>') as PortfolioComparisonRowKey;
}

/** {@link CustomMetricGrid} but with key types narrowed to the key types used in the Portfolio Comparison Block. */
export type PortfolioComparisonColumnGroupKey =
  | SubjectKey
  | `${SubjectKey}`
  | `changes=${SubjectKey}&nil`
  | `changes=nil&${SubjectKey}`
  | `changes=${SubjectKey}&${SubjectKey}`;
export const isPortfolioComparisonColumnGroupKey = (key: string): key is PortfolioComparisonColumnGroupKey => {
  return isChangesKey(key) || isSubjectKey(key);
};
export function createPCBlockColumnKey(
  columnGroupKey: PortfolioComparisonColumnGroupKey,
  metricKey: string,
): `${PortfolioComparisonColumnGroupKey}:${string}` {
  assert(isPortfolioComparisonColumnGroupKey(columnGroupKey), `Invalid column group key: ${columnGroupKey}`);
  return `${columnGroupKey}:${metricKey}`;
}

function subjectToKey(subject: StudioRequestSubject | Subject): SubjectKey;
function subjectToKey(subject: undefined): 'nil';
function subjectToKey(subject: StudioRequestSubject | Subject | undefined): SubjectKey | 'nil' {
  return subject ? subjectToKeyString(subject) : 'nil';
}

export const createChangesKey = (subjects: StudioRequestSubject[] | Subject[]): PortfolioComparisonColumnGroupKey =>
  `changes=${subjectToKey(subjects[0]!)}&${subjectToKey(subjects[1]!)}`;
export const isChangesKey = (key: string) => key.startsWith('changes=');
export const splitChangesIntoSubjectKeys = (key: string) =>
  key.split('=')[1]!.split('&') as [SubjectKey | 'nil', SubjectKey | 'nil'];
