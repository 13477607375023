import React from 'react';
import type { AnalysisRequest, ExportInfo } from '../../types';
import PairwiseCorrelation from './pairwise-correlation/PairwiseCorrelation';
import PortfolioCorrelation from './pairwise-correlation/PortfolioCorrelation';
import type { AnalysesResult } from 'venn-state';
import { recoilBlockContentSize } from 'venn-state';
import Measure from 'react-measure';
import { useSetBlockSize } from '../../logic/useSetBlockSize';
import styled from 'styled-components';

interface CorrelationBlockProps extends ExportInfo {
  analysesGroup: AnalysesResult;
  requests: AnalysisRequest[];
  selectedMetricIds: string[];
}
// @see customBlockUtils.tsx
const CorrelationResultMap: { [key: string]: number } = {
  pairwiseCorrelation: 0,
  portfolioCorrelation: 1,
  residualPortfolioCorrelation: 2,
  pairwiseDownsideCorrelation: 3,
  portfolioDownsideCorrelation: 4,
};

const getCorrelationChart = (metric: string) => {
  switch (metric) {
    case 'pairwiseCorrelation':
    case 'pairwiseDownsideCorrelation':
      return PairwiseCorrelation;
    default:
      return PortfolioCorrelation;
  }
};

const CorrelationBlock = ({
  analysesGroup,
  selectedMetricIds: metrics,
  selectedRefId,
  ...exportInfo
}: CorrelationBlockProps) => {
  const onResize = useSetBlockSize(recoilBlockContentSize.transformedState(selectedRefId));
  const analysis = analysesGroup[CorrelationResultMap[metrics[0]!]!]!;
  const CorrelationChart = getCorrelationChart(metrics[0]!);

  return (
    <Measure bounds onResize={onResize}>
      {({ measureRef }) => (
        <Wrapper className="qa-correlation-block" ref={measureRef}>
          <CorrelationChart
            selectedRefId={selectedRefId}
            analysis={analysis ? analysis[0] : undefined}
            {...exportInfo}
          />
        </Wrapper>
      )}
    </Measure>
  );
};

export default CorrelationBlock;

const Wrapper = styled.div`
  display: inline-block;
  min-width: 100%;
`;
