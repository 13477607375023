import type { CSSProperties } from 'react';
import type { DataExtractor, TabularDataType } from '../types';
import type {
  Analysis,
  FactorLensWithReturns,
  ForecastedPerformanceSummary,
  PerformanceSummaryResponse,
  ReturnsFactorAnalysis,
  FactorWithNonSerializedReturns as FactorEntity,
} from 'venn-api';
import type { ComparisonSubject } from 'venn-utils';
import { rangeTypeToApiType } from 'venn-utils';
import type { RangeType } from 'venn-ui-kit';

interface RowDef<T> {
  label: string;
  getter: (data: T) => number | undefined;
  type: TabularDataType;
  hide?: boolean;
  style?: CSSProperties;
}

const PERCENTAGE = 'percentage' as TabularDataType;
const NUMERIC = 'numeric' as TabularDataType;

// @ts-expect-error: TODO fix strictFunctionTypes
export const historicalReturnsExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
) => {
  const analysisData = analysis && analysis.historicalPerformanceSummary;
  const isCumulative = analysisData && !analysis.historicalPerformanceSummary.some((p) => p?.periodAnnualized);

  const [analysisPeriodTag, fixedPeriodMetrics] = [' (Analysis Period)', ['1yr', '3yr', '5yr']];

  const rows: RowDef<PerformanceSummaryResponse>[] = [
    {
      label: `Return${analysisPeriodTag}${isCumulative ? ' (Cumulative)' : ''}`,
      getter: (data: PerformanceSummaryResponse) => data?.periodReturn,
      type: PERCENTAGE,
      hide: relative,
    },
    // @ts-expect-error: TODO fix strictFunctionTypes
    ...fixedPeriodMetrics.map((rangeType: RangeType) => {
      const apiTimePeriod = rangeTypeToApiType(rangeType);
      return {
        label: `Return (${rangeType.toUpperCase().slice(0, 2)})`,
        getter: (data: PerformanceSummaryResponse) => data?.fixedPeriodsPerformance?.[apiTimePeriod]?.periodReturn,
        type: PERCENTAGE,
        hide: relative,
      };
    }),
    {
      label: `Excess Return${analysisPeriodTag}${isCumulative ? ' (Cumulative)' : ''}`,
      getter: (data: PerformanceSummaryResponse) => data?.periodExcessReturn,
      type: PERCENTAGE,
      hide: !relative,
    },
    // @ts-expect-error: TODO fix strictFunctionTypes
    ...fixedPeriodMetrics.map((rangeType: RangeType) => {
      const apiTimePeriod = rangeTypeToApiType(rangeType);
      return {
        label: `Excess Return (${rangeType.toUpperCase().slice(0, 2)})`,
        getter: (data: PerformanceSummaryResponse) =>
          data?.fixedPeriodsPerformance?.[apiTimePeriod]?.periodExcessReturn,
        type: PERCENTAGE,
        hide: !relative,
      };
    }),
    {
      label: `Volatility${analysisPeriodTag}`,
      getter: (data: PerformanceSummaryResponse) => data?.volatility,
      type: PERCENTAGE,
      hide: relative,
    },
    // @ts-expect-error: TODO fix strictFunctionTypes
    ...fixedPeriodMetrics.map((rangeType: RangeType) => {
      const apiTimePeriod = rangeTypeToApiType(rangeType);
      return {
        label: `Volatility (${rangeType.toUpperCase().slice(0, 2)})`,
        getter: (data: PerformanceSummaryResponse) => data?.fixedPeriodsPerformance?.[apiTimePeriod]?.volatility,
        type: PERCENTAGE,
        hide: relative,
      };
    }),
    {
      label: `Tracking Error${analysisPeriodTag}`,
      getter: (data: PerformanceSummaryResponse) => data?.trackingError,
      type: PERCENTAGE,
      hide: !relative,
    },
    // @ts-expect-error: TODO fix strictFunctionTypes
    ...fixedPeriodMetrics.map((rangeType: RangeType) => {
      const apiTimePeriod = rangeTypeToApiType(rangeType);
      return {
        label: `Tracking Error (${rangeType.toUpperCase().slice(0, 2)})`,
        getter: (data: PerformanceSummaryResponse) => data?.fixedPeriodsPerformance?.[apiTimePeriod]?.trackingError,
        type: PERCENTAGE,
        hide: !relative,
      };
    }),
    {
      label: `Sharpe Ratio${analysisPeriodTag}`,
      getter: (data: PerformanceSummaryResponse) => data?.sharpe,
      type: NUMERIC,
      hide: relative,
    },
    // @ts-expect-error: TODO fix strictFunctionTypes
    ...fixedPeriodMetrics.map((rangeType: RangeType) => {
      const apiTimePeriod = rangeTypeToApiType(rangeType);
      return {
        label: `Sharpe Ratio (${rangeType.toUpperCase().slice(0, 2)})`,
        getter: (data: PerformanceSummaryResponse) => data?.fixedPeriodsPerformance?.[apiTimePeriod]?.sharpe,
        type: NUMERIC,
        hide: relative,
      };
    }),
    {
      label: `Information Ratio${analysisPeriodTag}`,
      getter: (data: PerformanceSummaryResponse) => data?.informationRatio,
      type: NUMERIC,
      hide: !relative,
    },
    // @ts-expect-error: TODO fix strictFunctionTypes
    ...fixedPeriodMetrics.map((rangeType: RangeType) => {
      const apiTimePeriod = rangeTypeToApiType(rangeType);
      return {
        label: `Information Ratio (${rangeType.toUpperCase().slice(0, 2)})`,
        getter: (data: PerformanceSummaryResponse) => data?.fixedPeriodsPerformance?.[apiTimePeriod]?.informationRatio,
        type: NUMERIC,
        hide: !relative,
      };
    }),
    {
      label: `Max Drawdown${analysisPeriodTag}`,
      getter: (data: PerformanceSummaryResponse) => data?.maxDrawdown,
      type: PERCENTAGE,
      hide: relative,
    },
    {
      label: `Max Underperformance${analysisPeriodTag}`,
      getter: (data: PerformanceSummaryResponse) => data?.maxUnderperformance,
      type: PERCENTAGE,
      hide: !relative,
    },
  ].filter((row) => !row.hide);

  return extractDataFromAnalysis(subjects, rows, analysisData);
};

// @ts-expect-error: TODO fix strictFunctionTypes
export const forecastReturnsExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
) => {
  const analysisData = analysis && analysis.forecastedPerformanceSummary;
  const rows: RowDef<ForecastedPerformanceSummary>[] = [
    {
      label: 'Return',
      getter: (data: ForecastedPerformanceSummary) => data?.annualizedReturn,
      type: PERCENTAGE,
      hide: relative,
    },
    {
      label: 'Excess Return',
      getter: (data: ForecastedPerformanceSummary) => data?.annualizedExcessReturn,
      type: PERCENTAGE,
      hide: !relative,
    },
    {
      label: 'Volatility',
      getter: (data: ForecastedPerformanceSummary) => data?.volatility,
      type: PERCENTAGE,
      hide: relative,
    },
    {
      label: 'Tracking Error',
      getter: (data: ForecastedPerformanceSummary) => data?.trackingError,
      type: PERCENTAGE,
      hide: !relative,
    },
    {
      label: 'Sharpe Ratio',
      getter: (data: ForecastedPerformanceSummary) => data?.sharpe,
      type: NUMERIC,
      hide: relative,
    },
    {
      label: 'Information Ratio',
      getter: (data: ForecastedPerformanceSummary) => data?.informationRatio,
      type: NUMERIC,
      hide: !relative,
    },
  ].filter((row) => !row.hide);

  return extractDataFromAnalysis(subjects, rows, analysisData);
};

// @ts-expect-error: TODO fix strictFunctionTypes
export const historicalRiskStatsExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
) => {
  const analysisData = analysis && analysis.historicalPerformanceSummary;
  const rows: RowDef<PerformanceSummaryResponse>[] = [
    {
      label: 'Beta To Benchmark',
      getter: (data: PerformanceSummaryResponse) => data?.betaToBenchmark,
      type: NUMERIC,
      hide: relative,
    },
    {
      label: 'Downside Correlation',
      getter: (data: PerformanceSummaryResponse) => data?.downsideCorrelation,
      type: NUMERIC,
    },
    {
      label: 'Downside Capture',
      getter: (data: PerformanceSummaryResponse) => data?.downsideCaptureRatio,
      type: PERCENTAGE,
      hide: relative,
    },
    {
      label: 'Upside Capture',
      getter: (data: PerformanceSummaryResponse) => data?.upsideCaptureRatio,
      type: PERCENTAGE,
      hide: relative,
    },
    {
      label: 'Average Down Month',
      getter: (data: PerformanceSummaryResponse) => data?.averageDownMonth,
      type: PERCENTAGE,
    },
    {
      label: 'Average Up Month',
      getter: (data: PerformanceSummaryResponse) => data?.averageUpMonth,
      type: PERCENTAGE,
    },
    {
      label: 'Downside Volatility',
      getter: (data: PerformanceSummaryResponse) => data?.downsideVolatility,
      type: PERCENTAGE,
    },
    {
      label: 'Upside Volatility',
      getter: (data: PerformanceSummaryResponse) => data?.upsideVolatility,
      type: PERCENTAGE,
    },
    {
      label: 'Batting Average',
      getter: (data: PerformanceSummaryResponse) => data?.battingAverage,
      type: PERCENTAGE,
    },
    {
      label: 'CVaR (5%)',
      getter: (data: PerformanceSummaryResponse) => data?.conditionalValueAtRisk95,
      type: PERCENTAGE,
    },
    {
      label: 'VaR (95%)',
      getter: (data: PerformanceSummaryResponse) => data?.valueAtRisk95,
      type: PERCENTAGE,
    },
    {
      label: 'VaR (97.5%)',
      getter: (data: PerformanceSummaryResponse) => data?.valueAtRisk975,
      type: PERCENTAGE,
    },
    {
      label: 'VaR (99%)',
      getter: (data: PerformanceSummaryResponse) => data?.valueAtRisk99,
      type: PERCENTAGE,
    },
    {
      label: 'Skewness',
      getter: (data: PerformanceSummaryResponse) => data?.skewness,
      type: NUMERIC,
    },
    {
      label: 'Kurtosis',
      getter: (data: PerformanceSummaryResponse) => data?.kurtosis,
      type: NUMERIC,
    },
    {
      label: 'Sortino Ratio',
      getter: (data: PerformanceSummaryResponse) => data?.sortino,
      type: NUMERIC,
    },
    {
      label: 'Calmar Ratio',
      getter: (data: PerformanceSummaryResponse) => data?.calmar,
      type: NUMERIC,
    },
    {
      label: 'Autocorrelation',
      getter: (data: PerformanceSummaryResponse) => data?.autocorrelation,
      type: NUMERIC,
    },
  ].filter((row) => !row.hide);

  return extractDataFromAnalysis(subjects, rows, analysisData);
};

// @ts-expect-error: TODO fix strictFunctionTypes
export const factorContributionToReturnExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
  factorLens: FactorLensWithReturns,
) => {
  const analysisData = analysis?.factorContributionToReturn;
  const factors = getFactors(factorLens);

  let rows: RowDef<ReturnsFactorAnalysis>[] = [
    {
      label: 'Total',
      getter: (data: ReturnsFactorAnalysis) => data?.periodTotalReturn,
      type: PERCENTAGE,
      style: {
        fontWeight: 'bold',
      },
    },
    ...factors.map((factor) => ({
      label: factor.name,
      type: PERCENTAGE,
      getter: (data: ReturnsFactorAnalysis) => data?.factors?.find((f) => f?.id === factor?.id)?.contribution,
    })),
    {
      label: 'Risk-Free Rate',
      getter: (data: ReturnsFactorAnalysis) => data?.riskFreeReturn,
      type: PERCENTAGE,
      hide: relative,
      style: {
        fontStyle: 'italic',
      },
    },
    {
      label: 'Residual',
      getter: (data: ReturnsFactorAnalysis) => data?.periodResidualReturn,
      type: PERCENTAGE,
      style: {
        fontStyle: 'italic',
      },
    },
  ];
  rows = rows.filter((row) => !row.hide);
  return extractDataFromAnalysis(subjects, rows, analysisData);
};

// @ts-expect-error: TODO fix strictFunctionTypes
export const factorContributionToRiskExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
  factorLens: FactorLensWithReturns,
) => {
  const analysisData = analysis?.factorContributionToRisk;
  const factors = getFactors(factorLens);

  const rows: RowDef<ReturnsFactorAnalysis>[] = [
    ...factors.map((factor) => ({
      label: factor.name,
      type: PERCENTAGE,
      getter: (data: ReturnsFactorAnalysis) => data?.factors?.find((f) => f?.id === factor?.id)?.contribution,
    })),
    {
      label: 'Residual',
      getter: (data: ReturnsFactorAnalysis) => data?.residualRisk,
      type: PERCENTAGE,
      style: {
        fontStyle: 'italic',
      },
    },
  ];
  return extractDataFromAnalysis(subjects, rows, analysisData);
};

// @ts-expect-error: TODO fix strictFunctionTypes
export const factorExposureExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
  factorLens: FactorLensWithReturns,
) => {
  const analysisData = analysis?.factorExposures;
  const factors = getFactors(factorLens);

  const rows: RowDef<ReturnsFactorAnalysis>[] = [
    ...factors.map((factor) => ({
      label: factor.name,
      type: NUMERIC,
      getter: (data: ReturnsFactorAnalysis) => data?.factors?.find((f) => f?.id === factor?.id)?.contribution,
    })),
  ];
  return extractDataFromAnalysis(subjects, rows, analysisData);
};

// @ts-expect-error: TODO fix strictFunctionTypes
export const pairwiseCorrelationExtractor: DataExtractor = (
  subjects: ComparisonSubject[],
  analysis: Analysis,
  relative: boolean,
) => {
  const analysisData = analysis?.pairwiseCorrelation;
  const rows: RowDef<number[]>[] = [
    ...subjects.map((subject, subjectIdx) => ({
      label: subject?.analysisSubject?.name ?? '',
      type: NUMERIC,
      getter: (data: number[]) => data && data[subjectIdx],
      hide: relative && subject.isBenchmark,
    })),
  ].filter((row) => !row.hide);

  return extractDataFromAnalysis(subjects, rows, analysisData);
};

const extractDataFromAnalysis = <T>(subjects: ComparisonSubject[], rows: RowDef<T>[], analysisData: T[]) => {
  return rows.map((row) => {
    return {
      ...row,
      ...subjects.map((subject, subjectIdx) => {
        let value;
        try {
          value = row.getter(analysisData[subjectIdx]!);
        } catch {
          // Suppress error
        }
        return {
          value,
        };
      }),
    };
  });
};

const getFactors = (factorLens: FactorLensWithReturns): FactorEntity[] => factorLens?.factors || [];
