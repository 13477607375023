import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { getTextThemeProvider, getAppTitle, Tooltip } from 'venn-ui-kit';
import find from 'lodash/find';
import omit from 'lodash/omit';
import { analyticsService, AnalyticsUtils, logExceptionIntoSentry } from 'venn-utils';
import {
  AuthButton,
  AuthLogo,
  AuthenticationFooter,
  SignUpTerms,
  CountrySelectWithLabel,
  AuthFormErrors,
  isStateRequired,
  PendingEmailConfirmation,
  StateSelect,
  PasswordInputs,
  AllRequiredNotes,
  NameInputs,
  StyledHeadline1,
  StyledCheckboxWrapper,
  AuthSubParagraph,
  Anchor,
  AuthLoader,
  ContentWrapper,
} from '../components';
import type { SignupOptions, Country, AccountCreationRequest } from 'venn-api';
import { getSignupOptions, getSupportedCountries, waitlistUserSignupV3 } from 'venn-api';
import type { Fields, Field, FormValues } from 'venn-components';
import { FormInput, FormSelect, useForm } from 'venn-components';
import { getFormErrorMessage } from '../utils';
import type { SingleValue } from 'react-select';

const DEFAULT_WAITLIST_ERROR = 'We were unable to add you to the waitlist. Please try again later.';

export interface CreatePasswordFormFields extends Fields {
  email: Field;
  password: Field;
  activationCode: Field;
  sponsorKey: Field<string | undefined>;
  firstName: Field;
  lastName: Field;
  country: Field<Country | undefined>;
  company: Field;
  state: Field<DropMenuItem | undefined>;
  aum: Field<DropMenuItem | undefined>;
  type: Field<DropMenuItem | undefined>;
  termsCheckbox: Field<boolean>;
}

interface CreatePasswordFormProps {
  pending: boolean;
  onSubmit: (values: Partial<AccountCreationRequest>) => void;
  fields: CreatePasswordFormFields;
}

interface CreatePasswordFormState {
  supportedJurisdiction: boolean;
  supportedLocality: boolean;
  localityRequired: boolean;
  error?: string;
  status: 'WAITLISTED' | undefined;
}

interface SignUpOptionsWithCountry extends SignupOptions {
  countries: Country[];
}

const CreatePasswordForm = ({ pending, onSubmit, fields: inputFields }: CreatePasswordFormProps) => {
  const [formState, setFormState] = useState<CreatePasswordFormState>({
    supportedJurisdiction: true,
    supportedLocality: true,
    localityRequired: false,
    error: undefined,
    status: undefined,
  });

  const submitForm = useCallback(
    async (values: FormValues) => {
      const { email, country, aum, type, state, activationCode, password, firstName, lastName, company, sponsorKey } =
        values;
      const signupValues = {
        activationCode,
        sponsorKey,
        aumRangeId: aum.value,
        companyType: type.value,
        countryCode: country.code,
        email,
        legalAgreements: [],
        locality: formState.localityRequired ? state?.value : undefined,
        password: formState.supportedJurisdiction ? password : undefined,
      };

      analyticsService.signupStepCompleted({
        stepDescription: 'password creation',
      });
      const status = formState.supportedJurisdiction ? undefined : 'waitlisted';
      const trackingTraits = {
        email,
        firstName,
        lastName,
        isAccountAdmin: true,
        company: {
          name: company,
        },
        organizationName: company,
      };

      AnalyticsUtils.identifyBeforeAccountCreation(trackingTraits, status);

      if (!formState.supportedJurisdiction) {
        // Join waitlist
        try {
          await waitlistUserSignupV3(signupValues);
          analyticsService.signupFunnelTerminated({
            stepDescription: 'password creation',
          });

          setFormState((prev) => ({
            ...prev,
            error: undefined,
            status: 'WAITLISTED',
          }));
        } catch (e) {
          const { content } = await e;
          setFormState((prev) => ({
            ...prev,
            error: content?.message || DEFAULT_WAITLIST_ERROR,
          }));
        }
        return;
      }
      if (values.termsCheckbox) {
        await onSubmit(signupValues);
      }
    },
    [onSubmit, formState.supportedJurisdiction, formState.localityRequired],
  );

  const [signUpOptions, setSignupOptions] = useState<SignUpOptionsWithCountry | undefined>(undefined);

  useEffect(() => {
    if (pending) {
      return;
    }
    const initOptions = async () => {
      try {
        const [signupOptions, countries] = await Promise.all([getSignupOptions(), getSupportedCountries()]);
        setSignupOptions({
          ...signupOptions.content,
          countries: countries.content,
        });
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    };

    initOptions();
  }, [pending]);

  const formFields = useMemo((): Field[] => {
    const orderedFields = [
      inputFields.activationCode,
      inputFields.sponsorKey,
      inputFields.firstName,
      inputFields.lastName,
      inputFields.email,
      inputFields.company,
      inputFields.type,
      inputFields.aum,
      inputFields.country,
      inputFields.state,
      inputFields.password,
      inputFields.termsCheckbox,
    ];
    // restricted states
    if (formState.localityRequired && !formState.supportedLocality) {
      return filterByName(orderedFields, ['password', 'termsCheckbox']);
    }
    if (formState.localityRequired) {
      return orderedFields;
    }
    if (formState.supportedJurisdiction) {
      return filterByName(orderedFields, ['state']);
    }
    // restricted countries
    return filterByName(orderedFields, ['password', 'state', 'termsCheckbox']);
  }, [inputFields, formState.supportedJurisdiction, formState.supportedLocality, formState.localityRequired]);

  const [fields, isValid, isSubmitting, submit] = useForm(formFields, submitForm);

  const toggleCheck = useCallback(() => {
    if (fields.termsCheckbox) {
      fields.termsCheckbox.onFocus();
      fields.termsCheckbox.onChange(!fields.termsCheckbox?.value);
      fields.termsCheckbox.onBlur();
    }
  }, [fields.termsCheckbox]);

  const updateCountryField = useCallback(
    (countryOption: SingleValue<Country>) => {
      if (!countryOption || !signUpOptions) {
        return;
      }
      fields.country!.onChange(countryOption);
      fields.state?.reset();
      setFormState((prev) => ({
        ...prev,
        supportedJurisdiction: isSupportedJurisdiction(countryOption, signUpOptions.countries),
        localityRequired: isStateRequired(countryOption, signUpOptions.localities),
        supportedLocality: true, // reset supportedLocality state
      }));
    },
    [signUpOptions, fields.country, fields.state],
  );

  const updateStateField = useCallback(
    (stateOption: SingleValue<DropMenuItem>) => {
      if (!stateOption || !signUpOptions) {
        return;
      }
      const localityMatch = find(signUpOptions.localities[fields.country!.value?.code], { name: stateOption.value });
      const supported = localityMatch ? !localityMatch.restricted : true;

      fields?.state?.onChange(stateOption);
      setFormState((prev) => ({
        ...prev,
        supportedLocality: supported,
        supportedJurisdiction: supported,
      }));
    },
    [signUpOptions, fields],
  );

  if (!fields || !signUpOptions) {
    return null;
  }

  const { companyTypes, aumRanges, localities } = signUpOptions;
  const typesOptions =
    (companyTypes &&
      companyTypes.map((companyType) => ({
        label: companyType.name,
        value: companyType.key,
      }))) ||
    [];
  const aumRangesOptions =
    (aumRanges &&
      aumRanges.map((aumRange) => ({
        label: aumRange.name,
        value: aumRange.id,
      }))) ||
    [];
  const countryLabel = getJurisdiction(fields.country!.value, signUpOptions.countries);
  const stateLabel = formState.localityRequired ? fields.state?.value?.value : undefined;
  const isWaitlist = !formState.supportedJurisdiction && (!formState.localityRequired || !formState.supportedLocality);
  const error = formState.error
    ? formState.error
    : isWaitlist
      ? `${getAppTitle()} is not currently supported in ${
          !formState.supportedLocality && formState.localityRequired ? stateLabel : countryLabel
        }. Please join the waitlist.`
      : undefined;
  const disabledSubmit = !isValid || (formState.localityRequired && !fields.state?.value) || isSubmitting;

  if (formState.status === 'WAITLISTED') {
    return (
      <PendingEmailConfirmation
        signupValues={{ email: fields.email!.value }}
        status={formState.status}
        countryLabel={countryLabel}
        stateLabel={stateLabel}
      />
    );
  }

  const loading = pending ? 'Loading' : isSubmitting ? 'Authenticating' : '';
  const isPasswordFieldShown = formState.supportedJurisdiction && fields.country!.value;

  return (
    <>
      <ContentWrapper isLoading={!!loading}>
        {loading && <AuthLoader title={loading} />}
        {!loading && (
          <FormWrapper>
            <AuthLogo />
            <Headline1>{`Activate your ${getAppTitle()} account.`}</Headline1>
            <StyledAuthForm aria-label="create password form" onSubmit={submit}>
              <AllRequiredNotes />
              <NameInputs disabled firstName={fields.firstName} lastName={fields.lastName} />
              <FormInput required errorHidden disabled label="Email" {...fields.email} />
              <FormInput
                errorHidden
                autoComplete="organization"
                inputId="organization"
                required
                disabled
                label="Organization Name"
                {...fields.company}
              />
              <FormSelect
                label="Organization Type"
                inputId="type"
                className="qa-type-select"
                options={typesOptions}
                disableSearch
                required
                {...fields.type!}
              />
              <FormSelect
                label="Organization Total Assets Under Management (AUM)"
                inputId="aum"
                className="qa-aum-select"
                options={aumRangesOptions}
                disableSearch
                required
                {...fields.aum!}
              />
              <CountrySelectWithLabel field={{ ...fields.country!, onChange: updateCountryField }} />
              <StateSelect
                localities={localities}
                country={fields.country!.value}
                state={fields.state?.value}
                updateStateField={updateStateField}
                error={fields.state?.error}
              />
              {isPasswordFieldShown && (
                <>
                  <PasswordInputs field={fields.password!} />
                  <StyledCheckboxWrapper checked={fields.termsCheckbox?.value ?? false} onChange={toggleCheck} required>
                    <SignUpTerms term="subscriber" jurisdiction={fields.country!.value!.code} buttonText="Activate" />
                  </StyledCheckboxWrapper>
                </>
              )}
              <AuthFormErrors error={error} />
              <AuthButtonContainer>
                <StyledTooltip
                  showShadow
                  block
                  content={getFormErrorMessage(
                    fields,
                    isPasswordFieldShown ? fieldLabels : omit(fieldLabels, ['password']),
                    formState.supportedJurisdiction && !fields.termsCheckbox?.value,
                  )}
                  maxWidth={400}
                >
                  <AuthButton dominant disabled={disabledSubmit} type="submit">
                    {isWaitlist ? 'Join the waitlist' : 'Activate'}
                  </AuthButton>
                </StyledTooltip>
              </AuthButtonContainer>
            </StyledAuthForm>
          </FormWrapper>
        )}
        <AuthSubParagraph>
          Need help activating your account?&nbsp;
          <Anchor href={`mailto:${getTextThemeProvider().supportEmail}`}>Contact Us</Anchor>
        </AuthSubParagraph>
      </ContentWrapper>
      <AuthenticationFooter />
    </>
  );
};

export default CreatePasswordForm;

const Headline1 = styled(StyledHeadline1)`
  max-width: 550px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  && input[type='checkbox'] {
    align-self: start;
    position: relative;
    top: 10px;
  }
`;

const StyledAuthForm = styled.form`
  max-width: 400px;
  padding: 10px 20px 0;
`;

const AuthButtonContainer = styled.div`
  margin-top: 40px;
`;

const StyledTooltip = styled(Tooltip)`
  text-align: center;
`;

const getJurisdiction = (country: Country, countries: Country[]) => {
  if (!country) {
    return '';
  }
  const countryMatch = find(countries, { code: country.code });
  return countryMatch ? countryMatch.displayName : 'your country';
};

const isSupportedJurisdiction = (country: Country, countries: Country[] = []) => {
  if (!country) {
    return true;
  }
  const countryMatch = find(countries, { code: country.code });
  // TODO(VENN-20115): remove eslint-disable if it is safe to do so
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  return countryMatch ? countryMatch.supportedJurisdiction === true : true;
};

const fieldLabels = {
  country: 'Country',
  password: 'Password',
  state: 'State',
  aum: 'AUM',
  type: 'Organization Type',
};

const filterByName = (fields: Field[], names: string[]) => {
  const namesToFilter = new Set(names);
  return fields.filter((field) => !namesToFilter.has(field.name));
};
