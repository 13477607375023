import { useEffect } from 'react';
import { getMetadataRows } from './exportUtils';
import type { ExportInfo } from '../types';
import type { ExcelDataFn } from '../../contexts/export-context';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader, blockExcelFunction, useScopedSetRecoilState } from 'venn-state';

interface ExportProps extends ExportInfo {
  excelDataFn: ExcelDataFn;
}

/** Update export excel data for bulk exporting, with additional metadata rows added. */
const useExportUpdate = ({ selectedRefId, excelDataFn, exportMetaData }: ExportProps) => {
  const exportSheetName = useRecoilValue(blockDisplayHeader(selectedRefId));
  const setExcelFunction = useScopedSetRecoilState(blockExcelFunction(selectedRefId));

  useEffect(() => {
    const metadataWrappedDataFn = excelDataFn
      ? () => {
          const excel = excelDataFn();

          // Make sure first meta row has enough columns
          // TODO(VER-87): DEAD CODE: to my knowledge the .map(() => ({ value: '--' })) below is useless and can be removed
          const moreCols =
            (excel[0]?.length ?? 0) > 2 ? new Array((excel[0]?.length ?? 0) - 2).map(() => ({ value: '--' })) : [];
          // Add metadata
          const excelMetadata = getMetadataRows(moreCols, exportSheetName, exportMetaData);
          return [...excelMetadata, ...excel];
        }
      : undefined;

    setExcelFunction({ excelFunction: metadataWrappedDataFn });
  }, [exportMetaData, exportSheetName, setExcelFunction, excelDataFn]);
};

export default useExportUpdate;
