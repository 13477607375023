import { isEmpty } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { blockInfoGraphicType, blockSettings, isReportState } from 'venn-state';
import { assertNotNil, logExceptionIntoSentry } from 'venn-utils';
import { GridWrapper } from '../../../common';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { PublicPrivateErrorMessages } from '../../PrivatesErrorMessages';
import StyledEmptyState from '../../StyledEmptyState';
import { PublicPrivateAssetGrowthBreakdownBlock } from './PublicPrivateAssetGrowthBreakdownBlock';
import { PublicPrivateAssetGrowthGridBlock } from './PublicPrivateAssetGrowthGridBlock';
import type { PublicPrivateAssetGrowthBlockWrapperProps } from './types';
import { PublicPrivateAssetGrowthPercentilesChart } from '../../charts/PrivateAssetGrowthPercentilesChart';

const getErrorsOnly = (subjectMessages: PublicPrivateAssetGrowthBlockWrapperProps['subjectMessages']) => {
  if (!subjectMessages) {
    return undefined;
  }

  return Object.fromEntries(
    Object.entries(subjectMessages)
      .map(([key, messages]) => [key, messages.filter((message) => message.message.type === 'ERROR')])
      .filter(([_key, messages]) => messages!.length > 0),
  );
};

export const PublicPrivateAssetGrowthBlockWrapper = ({
  exportable,
  inPrintMode,
  data,
  subjectMessages,
  downloadableContentRef,
}: PublicPrivateAssetGrowthBlockWrapperProps) => {
  const blockId = useBlockId();
  const customBlockSettings = useRecoilValue(blockSettings(blockId));
  const selectedInfoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const isReport = useRecoilValue(isReportState);

  const errorsOnly = getErrorsOnly(subjectMessages);

  if (errorsOnly && !isEmpty(errorsOnly)) {
    return <PublicPrivateErrorMessages errorMessages={errorsOnly} />;
  }

  if (!data) {
    return <StyledEmptyState selectedRefId={blockId} header="Unable to run analysis" message="Chart is empty" />;
  }

  if (selectedInfoGraphicType === 'GRID') {
    return (
      <GridWrapper isReport={isReport}>
        <PublicPrivateAssetGrowthGridBlock
          data={assertNotNil(data)}
          exportable={exportable}
          inPrintMode={inPrintMode}
          downloadableContentRef={downloadableContentRef}
        />
      </GridWrapper>
    );
  }

  switch (customBlockSettings.customBlockType) {
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
      return (
        <PublicPrivateAssetGrowthPercentilesChart
          downloadableContentRef={downloadableContentRef}
          inPrintMode={inPrintMode}
          exportable={exportable}
          data={data}
        />
      );
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
      return (
        <PublicPrivateAssetGrowthBreakdownBlock
          downloadableContentRef={downloadableContentRef}
          inPrintMode={inPrintMode}
          exportable={exportable}
          data={data}
        />
      );
    default:
      logExceptionIntoSentry('Invalid custom block type for public-private asset growth');
      return null;
  }
};
