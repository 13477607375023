import { useRecoilState, useRecoilValue } from 'recoil';
import { mapValues, compact } from 'lodash';
import type { StudioRequestSubject, SubjectInputId, SubjectWithOptionalFee } from 'venn-state';
import { multiSubjectInputGroupRequestSubjects, multiSubjectInputGroupSubjects } from 'venn-state';
import type { Portfolio } from 'venn-api';
import { assertNotNil, getBaseFee } from 'venn-utils';

const allIds = (portfolio: Portfolio): string[] =>
  compact([portfolio?.id?.toString() ?? portfolio?.fund?.id, ...(portfolio?.children ?? []).flatMap(allIds)]);

const isPrivate = (subject: SubjectWithOptionalFee) => !!subject.privateFundId || !!subject.privatePortfolioId;

export const useFees = (
  subjectInputIds: SubjectInputId[],
): [
  Record<SubjectInputId, number[]>,
  (newFees: Record<SubjectInputId, number[]>, exclusions?: Record<SubjectInputId, string[][]>) => void,
] => {
  const [subjectGroups, setSubjectGroups] = useRecoilState(multiSubjectInputGroupSubjects(subjectInputIds));
  const studioSubjects = useRecoilValue(multiSubjectInputGroupRequestSubjects(subjectInputIds));
  const baselineFees = mapValues(subjectGroups, (subjects) => subjects.map(getBaseFee));
  const setBaselineFees = (
    newFees: Record<SubjectInputId, number[]>,
    exclusions?: Record<SubjectInputId, string[][]>,
  ) => {
    const newSubjectGroups: Record<SubjectInputId, SubjectWithOptionalFee[]> = {};
    for (const subjectGroup of Object.keys(subjectGroups)) {
      newSubjectGroups[subjectGroup as SubjectInputId] = subjectGroups[subjectGroup as SubjectInputId]!.map(
        (subject, index) => {
          const studioSubject: StudioRequestSubject = studioSubjects[subjectGroup][index];
          const ids = studioSubject?.portfolio ? allIds(studioSubject.portfolio) : [studioSubject?.fund?.id];
          return {
            ...subject,
            feesMapping: isPrivate(subject)
              ? undefined
              : ids
                  .filter((id) => exclusions?.[subjectGroup][index]?.includes(id))
                  .reduce((result, item) => ({ ...result, [assertNotNil(item)]: 0 }), {
                    [assertNotNil(subject.portfolioId?.toString() ?? subject.fundId)]: newFees[subjectGroup][index],
                  }),
          };
        },
      );
    }
    setSubjectGroups(newSubjectGroups);
  };
  return [baselineFees, setBaselineFees];
};
