import type { DeepMapTo } from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import { cva } from 'class-variance-authority';

export const TOP_TOOLBAR_HEIGHT = '65px';

type ThemeProvider = (props: { theme: Theme }) => string;

export interface TopBarColor {
  DarkGrey: string;
  MidGrey2: string;
  Background: string;
  ActiveBackground: string;
  Border: string;
}

export type GetTopBarColorType = DeepMapTo<TopBarColor, ThemeProvider>;

export const getTopBarColor = ({
  isReport,
  isTemplate,
}: { isReport: boolean; isTemplate: true } | { isReport?: boolean; isTemplate: false }): GetTopBarColorType => {
  if (isTemplate) {
    return {
      DarkGrey: () => '#0000004D', // black, 30% opacity
      MidGrey2: ({ theme }) => theme.Colors.MidGrey2,
      Background: () => (isReport ? '#225457' : '#544B14'),
      ActiveBackground: ({ theme }) => (isReport ? theme.Colors.DEPRECATED_DivergingColor.B4 : '#7C7026'),
      Border: ({ theme }) => theme.Colors.GreyScale.Grey30,
    };
  }
  return {
    DarkGrey: ({ theme }) => theme.Colors.DarkGrey,
    MidGrey2: ({ theme }) => theme.Colors.MidGrey2,
    Background: ({ theme }) => theme.Colors.GreyScale.Grey90,
    ActiveBackground: ({ theme }) => theme.Colors.GreyScale.Grey70,
    Border: ({ theme }) => theme.Colors.GreyScale.Grey30,
  };
};

export const topBarButton = cva('flex items-center px-4 border-0 border-r border-solid border-venn-grey-60 h-full', {
  variants: {
    active: {
      true: 'bg-white text-venn-deep-dark-blue font-bold hover:text-venn-deep-dark-blue hover:bg-venn-grey-20',
      false: 'bg-none text-white hover:text-white hover:bg-venn-grey-80',
    },
  },
  defaultVariants: { active: false },
});
