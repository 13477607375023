import { compact, sortedUniqBy } from 'lodash';
import { selectorFamily } from 'recoil';
import type { ExportMetadataSubject, Portfolio, SubjectId } from 'venn-api';
import { batchGetExportMetadata } from 'venn-api';
import type { AnalysisSubject, CreateSerializableParam } from 'venn-utils';
import { assertExhaustive, isRequestSuccessful } from 'venn-utils';
import type { Subject } from './types';
import { allUniqViewSubjectsAndCommonBenchmarks } from './configuration/allViewSubjects';
import { blockScenarios } from './configuration/customViewOptions';
import { allBlockIdsState } from './grid';
import { blockBenchmarkSubjects } from './configuration/benchmark';
import { blockMetrics } from './configuration/blockConfig';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { analysisSubjects, isReportState, modifiedPortfolioForSubject, openAllocatorSubject } from './configuration';

const UNABLE_TO_CHECK = 'Unable to check if data can be exported.';

interface PortfolioSubject {
  id: number;
  type: 'raw-portfolio';
  portfolio: Portfolio;
}

export const toTypeSafePortfolioSubject = (portfolio: Portfolio): PortfolioSubject => {
  return { type: 'raw-portfolio' as const, id: portfolio.id, portfolio };
};

export const toTypeSafeSubjectId = (subject: Subject): SubjectId => {
  const { portfolioId, fundId, privatePortfolioId, privateFundId } = subject;

  if (portfolioId) {
    return { type: 'portfolio' as const, id: portfolioId };
  }
  if (fundId) {
    return { type: 'fund' as const, id: fundId };
  }
  if (privatePortfolioId) {
    return { type: 'private-portfolio-id' as const, id: privatePortfolioId };
  }
  if (privateFundId) {
    return { type: 'private-fund-id' as const, id: privateFundId };
  }
  throw new Error('unreachable');
};

export const toTypeSafeAnalysisSubjectId = ({
  type,
  portfolio,
  privatePortfolio,
  fund,
  privateFund,
}: AnalysisSubject) => {
  switch (type) {
    case 'portfolio':
      return toTypeSafePortfolioSubject(portfolio!);
    case 'investment':
      return { type: 'fund' as const, id: fund?.id };
    case 'private-portfolio':
      return {
        type: 'private-portfolio-id' as const,
        id: privatePortfolio?.id,
      };
    case 'private-investment':
      return {
        type: 'private-fund-id' as const,
        id: privateFund?.id,
      };
    default:
      throw assertExhaustive(type);
  }
};

type RedistributableFetchParams = CreateSerializableParam<{ subjects: ExportMetadataSubject[]; usesForecast: boolean }>;

/**
 * Separated into its own selector for optimizing caching.
 * Technically we don't need to pass in usesForecast either, and clients could handle it instead, but it
 * seemed handy to centralize the logic for response processing next to the fetch.
 *
 * NOTE: for optimal caching, subjectIds should be sorted and unique prior to using with this selector.
 */
const fetchRedistributableForSubjects = selectorFamily({
  key: 'fetchRedistributableForSubjects',
  get:
    ({ subjects, usesForecast }: RedistributableFetchParams) =>
    async () => {
      if (subjects.length === 0) {
        return true;
      }

      try {
        const response = await batchGetExportMetadata({ subjects });
        if (!isRequestSuccessful(response)) {
          Notifications.notify(UNABLE_TO_CHECK, NotificationType.ERROR);
          return false;
        }
        const content = response.content;
        const subjectRedistributable = content.subjectExportMetadata.every((metadatum) => metadatum.redistributable);
        const forecastRedistributable = content.forecastExportMetadata.every((metadatum) => metadatum.redistributable);
        return subjectRedistributable && (!usesForecast || forecastRedistributable);
      } catch (error) {
        Notifications.notify(UNABLE_TO_CHECK, NotificationType.ERROR);
        return false;
      }
    },
});

export const redistributableState = selectorFamily({
  key: 'redistributable',
  // We must use the viewId as a key or otherwise we get stale data when switching between views. That would be fine for most UI because it
  // near-instantly corrects itself, but it is much worse for a persistent toast notification to display erroneously.
  get:
    (_viewId: string) =>
    ({ get }): boolean => {
      if (!get(isReportState)) {
        return false;
      }
      const openSubject = get(openAllocatorSubject);
      const modifiedPortfolio = openSubject && get(modifiedPortfolioForSubject(openSubject));
      const blockIds = get(allBlockIdsState);

      const usesForecast = blockIds
        .flatMap((blockId) => get(blockMetrics(blockId)))
        .some((metric) => metric.includes('FORECAST'));

      // Need to account for all subjects in the allocator panel including common benchmark.
      // This is to ensure that the BYOL warning shows if a non-redistributable is added to the common benchmark regardless if used in a block or not.
      const blockSubjects = get(allUniqViewSubjectsAndCommonBenchmarks);
      const blockAnalysisSubjectsIds = get(analysisSubjects(blockSubjects)).map(toTypeSafeAnalysisSubjectId);
      const scenarioSubjectIds = blockIds
        .flatMap((blockId) => get(blockScenarios(blockId)))
        .filter((scenario) => scenario.type !== 'MACRO')
        .map((scenario) => ({
          type: 'fund' as const,
          id: scenario.fundId,
        }));

      // Also check the benchmark subjects for each block (e.g. individual benchmarks)
      const benchmarkIds = compact(blockIds.flatMap((blockId) => get(blockBenchmarkSubjects(blockId)))).map(
        toTypeSafeSubjectId,
      );

      // Subjects are sorted, unique IDs
      const subjects = sortedUniqBy(
        [scenarioSubjectIds, blockAnalysisSubjectsIds, benchmarkIds]
          .flat()
          .map((subject) =>
            // If the subject has been modified in the allocator panel, use the modified portfolio object instead
            modifiedPortfolio && subject.id === modifiedPortfolio.id
              ? toTypeSafePortfolioSubject(modifiedPortfolio)
              : subject,
          )
          .filter(
            // Filter out any portfolio ID subjects if we're already sending their raw portfolio object (e.g. common benchmark also used in blocks)
            (subject, _, self) =>
              !(subject.type === 'portfolio' && self.some((s) => s.type === 'raw-portfolio' && s.id === subject.id)),
          )
          .sort(),
        (subjectId) => subjectId.type + subjectId.id,
      );

      return get(fetchRedistributableForSubjects({ subjects, usesForecast }));
    },
});
