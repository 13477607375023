import type { FactorSummaryChartData } from './factorSummaryTypes';
import type { ExcelCell, AnalysisSubject } from 'venn-utils';
import { formatExportValue, getSecondaryDisplayLabel } from 'venn-utils';

export const convertDataToExcel = (
  data: FactorSummaryChartData,
  title: string,
  subtitle: string,
  isPercentage: boolean,
  analysisSubject: AnalysisSubject,
) => {
  if (!data) {
    return undefined;
  }
  const header: ExcelCell[] = [
    { value: title },
    { value: `${data.mainDataSetName} ${subtitle} Value` },
    { value: `${data.mainDataSetName} ${subtitle} T-stat` },
  ];
  if (data.baselineDataSetName) {
    const label = getSecondaryDisplayLabel(
      analysisSubject,
      `${data.baselineDataSetName} as of`,
      `: ${data.baselineDataSetName}`,
    );
    header.push({ value: `${label} ${subtitle} Value` }, { value: `${label} ${subtitle} T-stat` });
  }
  if (data.comparisonDataSetName) {
    header.push(
      { value: `${data.comparisonDataSetName} ${subtitle} Value` },
      { value: `${data.comparisonDataSetName} ${subtitle} T-stat` },
    );
  }
  if (data.categoryDataSetName) {
    header.push(
      { value: `Category: ${data.categoryDataSetName} ${subtitle} Value` },
      { value: `Category: ${data.categoryDataSetName} ${subtitle} T-stat` },
    );
  }
  const excelSheet: ExcelCell[][] = [header];
  data.rows.forEach((row) => {
    const excelRow: ExcelCell[] = row.mainDataPoint
      ? [
          { value: row.factorName },
          formatExportValue(row.mainDataPoint.value, isPercentage),
          formatExportValue(row.mainDataPoint.tvalue, false),
        ]
      : [{ value: row.factorName }, { value: null }, { value: null }];
    if (row.baselineDataPoint) {
      excelRow.push(
        formatExportValue(row.baselineDataPoint.value, isPercentage),
        formatExportValue(row.baselineDataPoint.tvalue, false),
      );
    }
    if (row.comparisonDataPoint) {
      excelRow.push(
        formatExportValue(row.comparisonDataPoint.value, isPercentage),
        formatExportValue(row.comparisonDataPoint.tvalue, false),
      );
    }
    if (row.categoryDataPoint) {
      excelRow.push(
        formatExportValue(row.categoryDataPoint.value, isPercentage),
        formatExportValue(row.categoryDataPoint.tvalue, false),
      );
    }
    excelSheet.push(excelRow);
  });

  excelSheet[0]!.forEach((row) => (row.bold = true));
  excelSheet.forEach((row) => (row[0]!.bold = true));
  return excelSheet;
};
