import React, { useCallback, useMemo } from 'react';
import { get, compact, findIndex } from 'lodash';
import type { NamedEntity, ReturnCell, RollingCorrelationResponse } from 'venn-api';
import { useRecoilValue } from 'recoil';
import type { AnalysisRequest } from '../../types';
import { assertNotNil, convertMultipleSeriesDataToExcel, getBaseFee, hasFeesExcluded } from 'venn-utils';
import type { GeneralChartProps, Serie } from './types';
import LineChart from './LineChart';
import BarChart from './BarChart';
import { DownloadableContentBlock } from '../../../content-block';
import useExportUpdate from '../../logic/useExportUpdate';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import StyledEmptyState from '../StyledEmptyState';
import { formatExportableNameWithOptionalFee, formatNameWithOptionalFee } from '../../../legend';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { useBlockId } from '../../contexts/BlockIdContext';
import { blockSubjectHasProxyState } from 'venn-state';

const RollingCorrelationBlock = ({
  metrics,
  selectedBlock,
  analyses,
  requests,
  downloadableContentRef,
  height,
  selectedRefId,
  exportMetaData,
  inPrintMode,
}: GeneralChartProps) => {
  const metric = metrics ? metrics[0] : undefined;
  const blockId = useBlockId();
  const subjectHasProxy = useRecoilValue(blockSubjectHasProxyState(blockId));

  const isExportable = (exportable: boolean[], reqs: AnalysisRequest[], seriesName: NamedEntity) => {
    const index = findIndex(reqs, (req) => req.subject.id === String(seriesName.id));
    if (index === -1) {
      return false;
    }
    return exportable[index];
  };

  const [series, exportableSeries] = useMemo(() => {
    if (!analyses || analyses.length < 1 || !metric) {
      return [[], []];
    }

    const series: Serie[] = [];
    const exportableSeries: Serie[] = [];

    const analysis = compact(analyses)[0]!.find((analysis) => analysis?.analysisType === 'ROLLING_CORRELATION');
    const exportable = analysis?.exportable;

    analysis?.rollingCorrelation.forEach((correlation: RollingCorrelationResponse) => {
      const ts = metric.metricPath ? get(correlation, metric.metricPath) : correlation[0];
      if (!ts) {
        return;
      }

      // TODO: VENN-24861 Fix subject and subject name logic.
      const primarySubjectName = correlation.subjectName.name; // getSubjectLabelForChart(correlation.subjectName);
      const secondarySubjectName = correlation.secondarySubjectName.name; // getSubjectLabelForChart(correlation.secondarySubjectName);
      const primarySubject = assertNotNil(
        requests.find((request) => {
          return request.subject.name === primarySubjectName;
        }),
      ).subject;
      const secondarySubject = requests.find((request) => {
        return request.subject.name === secondarySubjectName;
      })?.subject;
      const seriesName = `${formatNameWithOptionalFee(
        primarySubjectName,
        getBaseFee(primarySubject),
        hasFeesExcluded(primarySubject),
        !!subjectHasProxy[primarySubject.id],
      )} with ${formatNameWithOptionalFee(
        secondarySubjectName,
        secondarySubject ? getBaseFee(secondarySubject) : undefined,
        secondarySubject ? hasFeesExcluded(secondarySubject) : undefined,
        correlation?.secondarySubjectName?.id ? !!subjectHasProxy[correlation.secondarySubjectName.id] : undefined,
      )}`;
      const exportableSeriesName = `${formatExportableNameWithOptionalFee(
        primarySubjectName,
        getBaseFee(primarySubject),
        hasFeesExcluded(primarySubject),
      )} with ${formatExportableNameWithOptionalFee(
        secondarySubjectName,
        secondarySubject ? getBaseFee(secondarySubject) : undefined,
        secondarySubject ? hasFeesExcluded(secondarySubject) : undefined,
      )}`;
      const seriesData = convertToTimeseries(ts);
      const serie = {
        name: seriesName,
        data: seriesData,
      };
      const exportableSerie = {
        name: exportableSeriesName,
        data: seriesData,
      };
      series.push(serie);

      if (
        isExportable(exportable ?? [], requests, correlation.subjectName) &&
        isExportable(exportable ?? [], requests, correlation.secondarySubjectName)
      ) {
        exportableSeries.push(exportableSerie);
      }
    });
    return [series, exportableSeries];
  }, [analyses, metric, requests, subjectHasProxy]);

  const defaultHeader =
    selectedBlock?.relativeToBenchmark && metric?.relativeLabel
      ? metric.relativeLabel
      : !metric
        ? 'Builder Chart'
        : metric.label;

  const excelDataFn = useCallback(
    () =>
      convertMultipleSeriesDataToExcel(
        exportableSeries.map((s) => ({ name: s.name, series: s.data })),
        metric?.dataType !== 'NUMERIC',
      ),
    [exportableSeries, metric?.dataType],
  );

  useExportUpdate({ exportMetaData, excelDataFn, selectedRefId });

  const Component = selectedBlock?.infoGraphicType === 'LINE' ? LineChart : BarChart;

  const component =
    series.length > 0 ? (
      <Component
        start={requests?.[0]?.dateRange.from}
        end={requests?.[0]?.dateRange.to}
        series={series}
        frequency={requests?.[0]?.frequency ?? 'MONTHLY'}
        yAxisUnitFormat={metric?.dataType === 'NUMERIC' ? 'ratio' : 'percent'}
        inPrintMode={inPrintMode}
        height={height}
      />
    ) : (
      <StyledEmptyState selectedRefId={selectedRefId} header="Unable to run analysis" message="Chart is empty" />
    );

  if (inPrintMode) {
    return component;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: series.length === 0,
        target: downloadableContentRef,
      }}
    >
      {component}
    </DownloadableContentBlock>
  );
};

export default RollingCorrelationBlock;

const convertToTimeseries = (ts: number[][] | ReturnCell[]): number[][] => {
  if (!ts) {
    return [];
  }
  if (Array.isArray(ts[0])) {
    return ts as number[][];
  }

  return (ts as ReturnCell[]).map((t) => [t.date, t.val]);
};
