import { useMemo } from 'react';
import { compact, groupBy, isNil } from 'lodash';
import type { CustomizedBlock, InfoGraphicTypeEnum, NotablePeriod } from 'venn-api';
import { buildMetricCategories } from './buildMetricCategoriesUtils';
import Dates from '../dates';
import type { SelectableCustomNotablePeriod, SelectableNotablePeriodItem } from '../studio/custom-view-options';
import { useGetAllNotablePeriodItems, useGetSelectedAndOrderedNotablePeriodItems } from '../studio/custom-view-options';
import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import { getAppTitleSimplifiedUtil } from '../utils';
import type {
  CustomBlockTypeEnum,
  CustomizableBlockSetting,
  CustomizableMetric,
} from '../blocksettings/staticBlockConfigs';
import type { CategoryMetric } from './types';

export interface Metric {
  key: string;
  label: string;
  tooltipContent?: string;
  editable: boolean;
}

interface MetricsParserValue {
  selectedMetrics: Metric[][];
  groupedMetrics: DropMenuCheckboxItem<string>[];
  selectedFactors: Metric[];
  groupedFactors: DropMenuCheckboxItem<string>[];
  selectedNotablePeriods: Metric[] | undefined;
  groupedNotablePeriods: DropMenuCheckboxItem<string>[] | undefined;
}

/**
 * Returns the set of selectable metrics' keys for the provided blockType, or undefined if all metrics are selectable.
 * Note this is different to the available metrics for a block, as the user might not be able to select all of them
 *
 * An empty array means that all metrics are disallowed.
 */
export const getSelectableMetricKeys = (
  blockType?: CustomBlockTypeEnum,
  infoGraphicType?: InfoGraphicTypeEnum,
): string[] | undefined => {
  switch (blockType) {
    case 'NOTABLE_PERIODS':
      if (infoGraphicType === 'DISTRIBUTE_BAR') {
        // Notable Periods (vertical) bar chart ignores the metrics and always shows returns in period
        return [];
      }
      return undefined;
    case 'SCENARIO':
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
      return [];
    default:
      return undefined;
  }
};

const useMetricsParser = (
  blockSetting: CustomizableBlockSetting,
  block: CustomizedBlock,
  factorMetrics: CategoryMetric[],
  allPredefinedPeriods: NotablePeriod[],
  selectedNotablePeriodIds?: number[],
  customNotablePeriods?: SelectableCustomNotablePeriod[],
): MetricsParserValue => {
  const allowReorderBetweenMetricCategories = blockSetting.customBlockType !== 'NOTABLE_PERIODS';
  const selectedMetrics = useMemo(
    () =>
      getSelectedMetrics(
        blockSetting.metrics,
        block,
        allowReorderBetweenMetricCategories,
        blockSetting.customBlockType,
      ),
    [blockSetting.metrics, block, allowReorderBetweenMetricCategories, blockSetting.customBlockType],
  );

  const selectedNotablePeriodItems = useGetSelectedAndOrderedNotablePeriodItems(
    selectedNotablePeriodIds,
    customNotablePeriods,
    blockSetting.customBlockType,
    allPredefinedPeriods,
  );
  const selectedNotablePeriods = useMemo(
    () =>
      selectedNotablePeriodItems?.map((item) => ({
        key: `${item.id}`,
        label: item.name,
        editable: false,
      })),
    [selectedNotablePeriodItems],
  );

  const allNotablePeriodItems = useGetAllNotablePeriodItems(
    selectedNotablePeriodIds,
    customNotablePeriods,
    blockSetting?.customBlockType,
    allPredefinedPeriods,
  );
  const groupedNotablePeriods = useMemo(() => {
    if (isNil(allNotablePeriodItems)) {
      return undefined;
    }

    const { PREDEFINED: predefined, CUSTOM: custom } = groupBy(allNotablePeriodItems, 'source');

    return [
      {
        value: `${getAppTitleSimplifiedUtil()} Defined`,
        label: `${getAppTitleSimplifiedUtil()} Defined`,
        level: 0,
        checked: !!predefined?.every((period) => period.selected),
      },
      ...(predefined ?? []).map(selectablePeriodToCheckboxMenuItemMapper),
      ...(custom?.length
        ? [
            {
              value: 'Custom Periods',
              label: 'Custom Periods',
              level: 0,
              checked: custom.every((period) => period.selected),
            },
            ...(custom ?? []).map(selectablePeriodToCheckboxMenuItemMapper),
          ]
        : []),
    ];
  }, [allNotablePeriodItems]);

  const selectedFactors = useMemo(() => {
    if (!blockSetting?.hasFactors) {
      return [];
    }
    return compact(
      block.selectedFactors.map((key) => {
        const factorMetric = factorMetrics.find((f) => f.id === key);
        if (!factorMetric) {
          return undefined;
        }
        return {
          key: factorMetric.id,
          label: factorMetric.name,
          editable: false,
        };
      }),
    );
  }, [factorMetrics, blockSetting, block]);

  const groupedFactors = useMemo(
    () => buildMetricCategories(factorMetrics, block.selectedFactors),
    [factorMetrics, block.selectedFactors],
  );

  const groupedMetrics = useMemo(() => {
    const allowedMetrics = getSelectableMetricKeys(blockSetting.customBlockType, block.infoGraphicType);
    const categoryMetrics = blockSetting.metrics
      ?.filter((metric) => !allowedMetrics || allowedMetrics.includes(metric.key))
      ?.filter((metric) => metric.analysisType !== 'CUSTOM_ENTRY')
      .map((m) => ({
        id: m.key,
        name: block.relativeToBenchmark && m.relativeLabel ? m.relativeLabel : m.label,
        category: m.category,
        disabled:
          !isNil(m.supportedGraphicTypes) &&
          !isNil(block.infoGraphicType) &&
          !m.supportedGraphicTypes.includes(block.infoGraphicType),
        tooltipContent: m.tooltipContent,
      }));
    return buildMetricCategories(categoryMetrics, block.selectedMetrics);
  }, [
    blockSetting.metrics,
    block.relativeToBenchmark,
    block.selectedMetrics,
    blockSetting.customBlockType,
    block.infoGraphicType,
  ]);

  return {
    selectedMetrics,
    groupedMetrics,
    selectedFactors,
    groupedFactors,
    selectedNotablePeriods,
    groupedNotablePeriods,
  };
};

export default useMetricsParser;

export const getMetricItem = (metric: CustomizableMetric, relative?: boolean): Metric => ({
  key: metric.key,
  label: relative && metric.relativeLabel ? metric.relativeLabel : metric.label,
  tooltipContent: metric.tooltipContent,
  editable: metric.analysisType === 'CUSTOM_ENTRY',
});

export const getSelectedMetrics = (
  blockMetrics: CustomizableMetric[] | undefined,
  block: CustomizedBlock | undefined,
  allowReorderBetweenMetricCategories: boolean,
  customBlockType: CustomBlockTypeEnum | undefined,
): Metric[][] => {
  const allowedMetricsKeys = getSelectableMetricKeys(customBlockType, block?.infoGraphicType);

  const selectedMetrics =
    block?.selectedMetrics?.filter(
      (metricKey) => !allowedMetricsKeys?.length || allowedMetricsKeys.includes(metricKey),
    ) ?? [];

  if (allowReorderBetweenMetricCategories) {
    return [
      compact(
        selectedMetrics.map((key) => {
          const metric = blockMetrics?.find((m) => m.key === key);
          if (!metric) {
            return undefined;
          }
          if (
            !isNil(metric.supportedGraphicTypes) &&
            !isNil(block?.infoGraphicType) &&
            !metric.supportedGraphicTypes.includes(block.infoGraphicType)
          ) {
            return undefined;
          }
          return getMetricItem(metric, block?.relativeToBenchmark);
        }),
      ),
    ];
  }

  const categoryToMetrics = new Map<string, Metric[]>();
  const noCategoryMetrics: Metric[] = [];

  selectedMetrics.forEach((key) => {
    const metric = blockMetrics?.find((m) => m.key === key);
    if (!metric) {
      return;
    }

    const metricItem: Metric = getMetricItem(metric, block?.relativeToBenchmark);

    if (isNil(metric.category)) {
      noCategoryMetrics.push(metricItem);
      return;
    }

    if (!categoryToMetrics.has(metric.category)) {
      categoryToMetrics.set(metric.category, []);
    }
    categoryToMetrics.get(metric.category)!.push(metricItem);
  });

  const result: Metric[][] = [...categoryToMetrics.values()];
  if (noCategoryMetrics.length > 0) {
    result.push(noCategoryMetrics);
  }
  return result;
};

const selectablePeriodToCheckboxMenuItemMapper = ({
  id,
  name,
  start,
  end,
  selected,
}: SelectableNotablePeriodItem): DropMenuCheckboxItem<string> => ({
  value: `${id}`,
  label: name,
  description: `${Dates.toDDMMMYYYY(start, 'DAILY')} - ${Dates.toDDMMMYYYY(end, 'DAILY')}`,
  level: 1,
  hideCheckbox: true,
  checked: selected,
});
