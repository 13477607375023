import { selector, waitForAll } from 'recoil';
import type { Subject, SubjectWithOptionalFee } from '../types';
import { compact, isEqual, isUndefined, omitBy, uniqBy } from 'lodash';
import { subjectInputGroups, subjectInputGroupSubjects } from './input-management/subjectInput';
import { selectedBlockIdState } from '../sessionState';
import { subjectToKeyString } from './subjectKey';
import { getSubjectId } from '../utils';
import { allBlockIdsState } from '../grid';
import { blockBenchmarkSubject, blockBenchmarkSubjects } from './benchmark';
import { blockSubjects } from './subjects';
import { benchmarkInputs, benchmarkInputSubject } from './input-management/benchmarkInput';

type SubjectCategory = 'currentBlockSubjects' | 'otherBlockSubjects';

type SelectorPortfolioGroup = { [key in SubjectCategory]: Subject[] };

/** @returns the unique set of subjects using a deep equality comparison. */
export const getUniqueSubjects = (subjects: Subject[]) => uniqBy(subjects, subjectToKeyString);

/** @returns whether the subjects are deeply equal, ignoring undefined fields */
export const subjectsEqual = (subject: Subject, otherSubject: Subject) =>
  isEqual(omitBy(subject, isUndefined), omitBy(otherSubject, isUndefined));

export const allSubjects = selector<SubjectWithOptionalFee[]>({
  key: 'allSubjects',
  get: ({ get }) => {
    const allSubjectGroupIds = get(subjectInputGroups);
    return get(waitForAll(allSubjectGroupIds.map((id) => subjectInputGroupSubjects(id)))).flat();
  },
});

export const allViewPortfolioSubjects = selector<SelectorPortfolioGroup>({
  key: 'allViewPortfolioSubjects',
  get: ({ get }) => {
    const currentBlockId = get(selectedBlockIdState);
    const allCurrentSubjects = currentBlockId ? get(blockSubjects(currentBlockId)) : [];
    const currentCommonBenchmark = currentBlockId ? get(blockBenchmarkSubject(currentBlockId)) : undefined;
    const allCurrentSubjectsAndBenchmarks = getUniqueSubjects(compact([...allCurrentSubjects, currentCommonBenchmark]));
    const allViewSubjects = getUniqueSubjects(
      get(allUniqViewSubjectsAndCommonBenchmarks).filter(
        (s) => !allCurrentSubjectsAndBenchmarks.some((s2) => subjectsEqual(s, s2)),
      ),
    );

    const filteredCurrentSubjects = uniqBy(
      allCurrentSubjectsAndBenchmarks.filter((s) => s.portfolioId || s.privatePortfolioId),
      (subject) => subject.portfolioId ?? subject.privatePortfolioId,
    );
    const filteredOtherSubjects = uniqBy(
      allViewSubjects
        .filter((s) => s.portfolioId || s.privatePortfolioId)
        .filter((s) => !filteredCurrentSubjects.some((s2) => subjectsEqual(s, s2))),
      (subject) => subject.portfolioId ?? subject.privatePortfolioId,
    );

    return {
      currentBlockSubjects: filteredCurrentSubjects,
      otherBlockSubjects: filteredOtherSubjects,
    };
  },
});

export const allUniqViewSubjectsFlattened = selector({
  key: 'allUniqViewSubjectsFlattened',
  get: ({ get }) => {
    return getUniqueSubjects(get(allSubjects));
  },
});

export const allUniqBenchmarkSubjects = selector({
  key: 'allUniqBenchmarkSubjects',
  get: ({ get }) => {
    const blockIds = get(allBlockIdsState);
    const benchmarks = compact(blockIds.flatMap((blockId) => get(blockBenchmarkSubjects(blockId))));
    return getUniqueSubjects(benchmarks);
  },
});

const allUniqCommonBenchmarks = selector({
  key: 'allUniqCommonBenchmarks',
  get: ({ get }) => {
    const benchmarkIds = get(benchmarkInputs);
    const benchmarks = compact(benchmarkIds.flatMap((blockId) => get(benchmarkInputSubject(blockId))));
    return getUniqueSubjects(benchmarks);
  },
});

export const allUniqViewSubjectsAndBenchmarks = selector({
  key: 'allUniqViewSubjectsAndBenchmarks',
  get: ({ get }) => {
    const subjects = get(allUniqViewSubjectsFlattened);
    const benchmarks = get(allUniqBenchmarkSubjects);
    return getUniqueSubjects([subjects, benchmarks].flat());
  },
});

export const allUniqViewSubjectsAndCommonBenchmarks = selector({
  key: 'allUniqViewSubjectsAndCommonBenchmarks',
  get: ({ get }) => {
    const subjects = get(allUniqViewSubjectsFlattened);
    const benchmarks = get(allUniqCommonBenchmarks);
    return getUniqueSubjects([subjects, benchmarks].flat());
  },
});

export const allViewSubjectIds = selector<string[]>({
  key: 'allViewSubjectIds',
  get: ({ get }) => {
    return get(allUniqViewSubjectsFlattened).map((subject) => getSubjectId(subject));
  },
});

export const firstPortfolioInView = selector<Subject | undefined>({
  key: 'firstPortfolioInView',
  get: ({ get }) => get(allUniqViewSubjectsFlattened).find((s) => s.portfolioId || s.privatePortfolioId),
});
