import styled from 'styled-components';
import { Button, ZIndex } from 'venn-ui-kit';
import React, { useCallback, useContext, useState } from 'react';
import { acceptedFileTypes } from './utils';
import UniversalUploaderContext from '../../../contexts/universal-uploader';

interface BrowseButtonProps {
  onFileChange: (data: File) => void;
  setError: (error: string | undefined) => void;
}

export const BrowseButton = ({ onFileChange, setError }: BrowseButtonProps) => {
  const [uploadedFile, setUploadedFile] = useState<HTMLInputElement | null>();
  const { refreshedStyling } = useContext(UniversalUploaderContext);
  const onBrowse = useCallback(() => {
    setError(undefined);

    if (!uploadedFile || !uploadedFile.files) {
      return;
    }

    onFileChange(uploadedFile.files[0]!);
  }, [onFileChange, uploadedFile, setError]);

  return (
    <>
      <StyledButton refreshedStyling={refreshedStyling} dominant onClick={() => uploadedFile && uploadedFile.click()}>
        Browse
      </StyledButton>
      <HiddenFileInput
        data-testid="hidden-file-input"
        type="file"
        id="upload-file"
        accept={acceptedFileTypes}
        ref={setUploadedFile}
        onChange={onBrowse}
      />
    </>
  );
};

const HiddenFileInput = styled.input`
  display: none;
`;

const StyledButton = styled(Button)`
  margin-right: 0;
  margin-bottom: 10px;
  margin-top: 20px;
  z-index: ${ZIndex.Front};
  border-radius: 4px;
`;
