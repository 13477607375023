import { useMemo } from 'react';
import { compact, isEqual, isNil } from 'lodash';
import { useRecoilValue } from 'recoil';
import {
  allViewPortfolioSubjects,
  analysisSubjects,
  openAllocatorSubject,
  openPrivateAllocatorPortfolio,
  selectedBlockIdState,
  toSubjectOnly,
} from 'venn-state';
import type { MenuCategory, DropMenuItem } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';

/**
 * Hook meant for Allocator panel in Studio, where we show a menu with subject selection. his hook gets all the subjects
 * present in the entire view (global & unlinked configurations), filters our all non-portfolios, and groups them into
 * two groups:
 *   - subjects from currently selected config
 *   - all other subjects from that view
 */
export const useSubjectSelectorItems = (): MenuCategory<AnalysisSubject>[] => {
  const selectedBlockOrGlobal = useRecoilValue(selectedBlockIdState);
  const isGlobal = isNil(selectedBlockOrGlobal);

  const { currentBlockSubjects, otherBlockSubjects } = useRecoilValue(allViewPortfolioSubjects);
  const currentlyOpenPortfolio = useRecoilValue(openAllocatorSubject);
  const currentlyOpenPrivatePortfolio = useRecoilValue(openPrivateAllocatorPortfolio);
  const currentSubject = currentlyOpenPrivatePortfolio
    ? { privatePortfolioId: currentlyOpenPrivatePortfolio?.id }
    : currentlyOpenPortfolio;

  const currentSubjectNotInAnyGroup =
    !isNil(currentSubject) &&
    !isNil(currentSubject.portfolioId ?? currentSubject.privatePortfolioId) &&
    ![...currentBlockSubjects, ...otherBlockSubjects].find((subject) =>
      isEqual(toSubjectOnly(subject), toSubjectOnly(currentSubject)),
    );

  const currentBlockAnalysisSubjects = useRecoilValue(analysisSubjects(currentBlockSubjects));
  const otherBlockAnalysisSubjects = useRecoilValue(analysisSubjects(otherBlockSubjects));
  const currentlyOpenAnalysisSubjects = useRecoilValue(analysisSubjects(compact([currentSubject])));

  const categories: MenuCategory<AnalysisSubject>[] = useMemo(
    () =>
      compact([
        !isGlobal && currentBlockAnalysisSubjects.length
          ? {
              name: 'Selected Block Portfolios',
              items: currentBlockAnalysisSubjects.map(mapSubjectToItem),
            }
          : null,
        otherBlockAnalysisSubjects.length
          ? {
              name: isGlobal ? 'All Portfolios' : 'Other Portfolios in this view',
              items: otherBlockAnalysisSubjects.map(mapSubjectToItem),
            }
          : null,
        currentlyOpenAnalysisSubjects.length && currentSubjectNotInAnyGroup
          ? {
              name: 'Portfolios deleted from this view',
              items: currentlyOpenAnalysisSubjects.map(mapSubjectToItem),
            }
          : null,
      ]),
    [
      isGlobal,
      currentBlockAnalysisSubjects,
      otherBlockAnalysisSubjects,
      currentlyOpenAnalysisSubjects,
      currentSubjectNotInAnyGroup,
    ],
  );

  return categories;
};

const mapSubjectToItem = (subject: AnalysisSubject): DropMenuItem<AnalysisSubject> => ({
  label: subject.portfolio?.name ?? subject.name,
  value: subject,
});
