import React from 'react';
import { Button, IconPosition } from 'venn-ui-kit';
import styled from 'styled-components';
import { ErrorMessage } from './styles';
import { analyticsService } from 'venn-utils';
import { STEPS } from './steps';

const AUTH_TEXT = 'Authorize with Addepar';

interface AddeparPanelButtonsProps {
  step: number;
  addeparAuthUrl: string | undefined;
  onCancel(): void;
  goToNextStep(): void;
  goToPreviousStep(): void;
  onFinish(): void;
  errorMessage: string | undefined;
  disableAll: boolean;
  disableContinue: boolean;
  isFirstTimeSetup: boolean;
  openHelpModal(): void;
}

const AddeparPanelButtons = ({
  step,
  addeparAuthUrl,
  onCancel,
  goToPreviousStep,
  goToNextStep,
  onFinish,
  errorMessage,
  disableAll,
  disableContinue,
  isFirstTimeSetup,
  openHelpModal,
}: AddeparPanelButtonsProps) => {
  const finishText = isFirstTimeSetup ? 'Finish & Sync Now' : 'Finish & Sync on Schedule';
  const locationOnPage = `Addepar ${STEPS[step]!.label}`;

  return (
    <ButtonsContainer>
      <ButtonGroup>
        <Button
          className="qa-addepar-cancel"
          onClick={() => {
            onCancel();
            analyticsService.ctaClicked({
              purpose: 'Cancel Workflow',
              text: 'Cancel',
              locationOnPage,
            });
          }}
        >
          Cancel
        </Button>
        <Button
          className="qa-addepar-help"
          icon="question-circle"
          onClick={() => {
            openHelpModal();
            analyticsService.ctaClicked({
              purpose: `Requesting Help for Integration: ${STEPS[step]!.label}`,
              text: 'Get Help',
              locationOnPage,
            });
          }}
          disabled={disableAll}
        >
          Get Help
        </Button>
      </ButtonGroup>
      <ButtonGroup>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {step !== 0 && (
          <Button
            className="qa-addepar-back"
            onClick={() => {
              goToPreviousStep();
              analyticsService.ctaClicked({
                purpose: `Move back: ${STEPS[step - 1]!.label}`,
                text: 'Back',
                locationOnPage,
              });
            }}
            disabled={disableAll}
          >
            Back
          </Button>
        )}
        {step === 0 ? (
          <Button
            type="submit"
            dominant
            icon="external-link"
            iconPosition={IconPosition.Right}
            onClick={() => {
              analyticsService.ctaClicked({
                destination: addeparAuthUrl,
                filled: true,
                locationOnPage,
                purpose: AUTH_TEXT,
                text: AUTH_TEXT,
                type: 'button',
              });
            }}
            disabled={!addeparAuthUrl || disableAll}
            link={addeparAuthUrl}
          >
            {AUTH_TEXT}
          </Button>
        ) : step === 4 ? (
          <Button
            dominant
            onClick={() => {
              onFinish();
              analyticsService.ctaClicked({
                purpose: 'Finish Addepar Setup',
                text: finishText,
                filled: true,
                locationOnPage,
              });
            }}
          >
            {finishText}
          </Button>
        ) : (
          <Button
            dominant
            onClick={() => {
              goToNextStep();
              analyticsService.ctaClicked({
                purpose: `Move on: ${STEPS[step + 1]!.label}`,
                text: 'Continue',
                filled: true,
                locationOnPage,
              });
            }}
            disabled={!!errorMessage || disableAll || disableContinue}
          >
            Continue
          </Button>
        )}
      </ButtonGroup>
    </ButtonsContainer>
  );
};

export default AddeparPanelButtons;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

const ButtonGroup = styled.div`
  & > :not(:last-child) {
    margin-right: 20px;
  }
`;
