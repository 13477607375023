import React from 'react';
import type { Portfolio } from 'venn-api';
import { isNil, groupBy, sortBy } from 'lodash';
import { Dates } from 'venn-utils';
import type { DropMenuItem, MenuCategory } from 'venn-ui-kit';
import { Icon } from 'venn-ui-kit';
import styled from 'styled-components';

export const FIRST_VERSION_IN_GROUP_CLASSNAME = 'qa-version-in-group-first';
export const LAST_VERSION_IN_GROUP_CLASSNAME = 'qa-version-in-group-last';
export const ITEM_CLASSNAME = 'qa-item';
export const ROW_HEIGHT = 40;

const formatCreatedDate = (updated?: number): string =>
  isNil(updated) ? '--' : Dates.toDayMonthYearTime(updated, true);

export const toPortfolioMenuItems = (group: Portfolio[]): DropMenuItem<Portfolio>[] =>
  group.map((item, idx) =>
    toPortfolioVersionMenuItem(
      item,
      `${idx === 0 ? FIRST_VERSION_IN_GROUP_CLASSNAME : ''} ${
        idx === group.length - 1 ? LAST_VERSION_IN_GROUP_CLASSNAME : ''
      }`,
    ),
  );

export const toPortfolioVersionMenuItem = (version: Portfolio, className?: string): DropMenuItem<Portfolio> => {
  const isIntegration = version.remoteType === 'CUSTODIAN' || version.remoteType === 'EXTERNAL';
  return {
    value: { ...version, draft: true },
    label: formatCreatedDate(version.updated),
    description: isIntegration
      ? `Integration: ${version.dataSource}`
      : `Saved by: ${version.updatedBy?.displayName ?? '(unknown)'}`,
    icon: isIntegration ? <Icon type="link" /> : undefined,
    style: {
      height: ROW_HEIGHT,
      paddingLeft: 21,
    },
    className,
  };
};

export interface GroupByDateItem {
  updated?: number;
}

export const groupByMonths = <T extends GroupByDateItem>(
  versions: T[],
  skipFirst: number,
  toMenuItems: (group: T[]) => DropMenuItem<T>[],
): MenuCategory<T>[] => {
  if (versions.length <= skipFirst) {
    return [];
  }
  const groupedByMonths = groupBy<T>(versions.slice(skipFirst), (version: T) =>
    isNil(version.updated) ? undefined : Dates.toFullMonthOptionalYear(version.updated),
  );
  const groupKeys = sortBy(Object.keys(groupedByMonths), [(key: string) => groupedByMonths[key]![0]!.updated]);
  return groupKeys.reverse().map((key) => ({
    name: Dates.toFullMonthOptionalYear(groupedByMonths[key]![0]!.updated!, true),
    items: toMenuItems(groupedByMonths[key]!),
  }));
};

export const toGeneralMenuItems = <T extends GroupByDateItem>(group: T[]): DropMenuItem<T>[] =>
  group.map((item) => ({
    value: item,
    label: formatCreatedDate(item.updated),
  }));

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  padding: 7px 20px 12px 0px;
`;
