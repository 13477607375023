import React from 'react';
import type { Country, Locality } from 'venn-api';
import { FormSelect } from 'venn-components';
import type { DropMenuItem } from 'venn-ui-kit';
import find from 'lodash/find';
import type { SingleValue } from 'react-select';

interface Localities {
  [key: string]: Locality[];
}

interface StateSelectProps {
  country: Country;
  state: DropMenuItem;
  localities: Localities;
  updateStateField: (stateOption: SingleValue<DropMenuItem>) => void;
  error?: string;
}

export const isStateRequired = (country: Country, localities: Localities): boolean =>
  Boolean(country && localities && localities[country.code]);

const StateSelect = ({ country, state, updateStateField, localities, error }: StateSelectProps) => {
  if (!isStateRequired(country, localities)) {
    return null;
  }
  const countryLocalities = localities ? localities[country.code]! : [];
  const options = countryLocalities.map((locality) => ({
    label: locality.name,
    value: locality.name,
  }));
  const localityMatch = state ? find(countryLocalities, { name: state.value }) : false;

  return (
    <FormSelect
      required
      name="state"
      label="State / Province"
      inputId="state"
      value={localityMatch ? state : null}
      className="qa-state-select"
      options={options}
      onChange={updateStateField}
      error={error}
    />
  );
};

export default StateSelect;
