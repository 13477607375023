import React, { useState, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { intersectionBy } from 'lodash';
import {
  getProxyOptionsForFund,
  type ProxyOption,
  type ProxyTypeEnum,
  type FundReturnsRange,
  getCustomProxyOptionForFund,
} from 'venn-api';
import { Icon, Notifications, NotificationType, RadioGroup, ShimmerBlock, Tooltip } from 'venn-ui-kit';
import {
  createProxyOptionsKey,
  getFormattedProxyType,
  logExceptionIntoSentry,
  ReturnsCalculations,
  useFetchApiConditionally,
  useQueries,
  withSuspense,
} from 'venn-utils';

import { Badge } from '@/components/ui/badge';

import { getCategoryProxyTypeMetadata, sortCategoryOptions, type SelectedProxy } from './utils';
import type { FundToBulkProxy } from '../types';
import { BulkProxySearch } from './components/BulkProxySearch';
import InterpolationSnapshot from '../../proxy-cell/InterpolationSnapshot';
import { BulkProxyLegend } from './BulkProxyLegend';

interface BulkCategoryOptionProps {
  investments: FundToBulkProxy[];
  proxyType?: ProxyTypeEnum;
  selectedProxy: SelectedProxy | null;
  numLags?: number;
  onSelect: (selected: SelectedProxy | null, numLags?: number) => void;
  rawInvestmentReturnsRanges: FundReturnsRange[];
  selectedProxyType: ProxyTypeEnum | undefined;
}

interface RadioOption {
  element: JSX.Element;
  value: string;
  index: number;
  disabled?: boolean;
}

const ADDITIONAL_OPTIONS = 2; // the radio group has two additional options we're manually including: search input and a text category

const TextCategory = ({ singleProxy, proxyType }: { singleProxy: boolean; proxyType?: ProxyTypeEnum }) => {
  if (!proxyType) {
    return null;
  }

  const proxyTypeMetadata = getCategoryProxyTypeMetadata(proxyType);
  const lowerCaseProxyTypeName = getFormattedProxyType(proxyType).toLowerCase();

  return (
    <div className="border border-solid border-venn-grey-30 border-b-0 p-4 flex flex-col">
      <div className="flex items-center justify-between">
        <span className="font-bold leading-normal">Or select a category below</span>
        {singleProxy && <BulkProxyLegend />}
      </div>
      <span className="text-venn-grey-80">
        Categories represent public indices that can be used for {lowerCaseProxyTypeName}.{' '}
        <a
          className="font-bold text-venn-dark-blue"
          target="_blank"
          rel="noopener noreferrer"
          href={proxyTypeMetadata.helpLink}
        >
          Learn more
        </a>
      </span>
    </div>
  );
};

const RadioCategory = ({ singleProxy, proxyOption }: { singleProxy: boolean; proxyOption: ProxyOption }) => {
  const { Colors } = useTheme();

  const { warning, rawCumulativeReturn, interpolatedCumulativeReturn } = proxyOption;
  const { displayName: label, indexName: description } = proxyOption.category;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col ml-0.5">
        <div className="flex items-center gap-1.5">
          {singleProxy && warning === 'BELOW_THRESHOLD' && (
            <Tooltip content="This proxy sits outside our recommended threshold for this investment." usePortal>
              <Icon className="text-venn-error" type="exclamation-triangle" />
            </Tooltip>
          )}
          <span className="leading-normal">{label}</span>
          {singleProxy && warning !== 'BELOW_THRESHOLD' && (
            <Badge variant="success" className="flex items-center gap-1">
              <Icon type="check" />
              Within Threshold
            </Badge>
          )}
        </div>
        <span className="text-[12px] text-venn-grey-80">{description}</span>
      </div>
      {singleProxy && (
        <div className="max-w-96">
          <InterpolationSnapshot
            primarySeries={ReturnsCalculations.toDataPoints(rawCumulativeReturn ?? [])}
            overlaySeries={ReturnsCalculations.toDataPoints(interpolatedCumulativeReturn ?? [])}
            subjectLineColor={Colors.HighlightDark}
            markerRadius={1.5}
          />
        </div>
      )}
    </div>
  );
};

const ShimmerFallback = () => {
  return (
    <div className="mt-3">
      {Array.from({ length: 8 }, (_, index) => (
        <ShimmerBlock
          height={70}
          key={`shimmer-${index}`}
          className="w-full border border-solid border-venn-grey-30 mb-0"
        />
      ))}
    </div>
  );
};

const InternalBulkCategoryOptions = ({
  investments,
  proxyType,
  numLags = 0,
  selectedProxy,
  onSelect,
  selectedProxyType,
  rawInvestmentReturnsRanges,
}: BulkCategoryOptionProps) => {
  const { Colors } = useTheme();

  const sortedProxyOptions = useProxyOptions(investments, proxyType, numLags);
  const singleProxy = investments.length === 1;

  const options: RadioOption[] = useMemo(
    () =>
      sortedProxyOptions.map((option, index) => ({
        element: <RadioCategory singleProxy={singleProxy} proxyOption={option} />,
        value: option.category.indexId,
        index,
        disabled: singleProxy && !option.proxiedCumulativeReturn,
      })),
    [sortedProxyOptions, singleProxy],
  );

  const isCategoryProxy = useIsCategoryProxy(selectedProxy, options);

  const [customSelectedProxy, setCustomSelectedProxy] = useState<SelectedProxy | null>(
    !isCategoryProxy ? selectedProxy : null,
  );

  const customProxyOption = useFetchApiConditionally(
    customSelectedProxy != null,
    getCustomProxyOptionForFund,
    investments[0]!.id,
    customSelectedProxy?.id ?? '',
    proxyType,
    numLags ?? 0,
  )?.result;

  const [selectedIndex, setSelectedIndex] = useState<number>(() =>
    !!selectedProxy && isCategoryProxy
      ? options.findIndex((option) => option.value === selectedProxy.id) + ADDITIONAL_OPTIONS // +2 for the search and text options
      : 0,
  );

  const onSelectCustomProxy = useCallback(
    (customProxy: SelectedProxy) => {
      onSelect(customProxy);
      setCustomSelectedProxy(customProxy);
    },
    [onSelect],
  );

  const combinedOptions = useMemo(() => {
    const allCategoryIds = sortedProxyOptions.map((option) => option.category.indexId);

    const searchOption = {
      element: (
        <div className="flex flex-col gap-2">
          {singleProxy && (
            <div className="flex items-center gap-1.5">
              {customProxyOption?.warning === 'BELOW_THRESHOLD' && (
                <Tooltip content="This proxy sits outside our recommended threshold for this investment." usePortal>
                  <Icon className="text-venn-error" type="exclamation-triangle" />
                </Tooltip>
              )}
              <span className="font-bold leading-normal">Search for a custom investment</span>
            </div>
          )}
          <div className="flex flex-wrap items-start justify-end lg:justify-between gap-8 relative">
            {/* Select control width is being overwritten to prevent search results from being truncated when width of parent decreases  */}
            <div className="flex flex-col gap-2 flex-grow w-full sm:[&_.select\_\_control]:w-[calc(100%-theme(space.96)-theme(space.8))]">
              <BulkProxySearch
                selectedProxy={customSelectedProxy}
                setSelectedProxy={onSelectCustomProxy}
                rawInvestmentReturnsRanges={rawInvestmentReturnsRanges}
                selectedProxyType={selectedProxyType}
                investments={investments}
                disabled={selectedIndex !== 0}
                excludedInvestmentIds={allCategoryIds}
              />
            </div>
            {singleProxy && (
              <div className="w-96 h-8 sm:absolute right-0 top-0">
                <InterpolationSnapshot
                  primarySeries={ReturnsCalculations.toDataPoints(customProxyOption?.rawCumulativeReturn ?? [])}
                  overlaySeries={ReturnsCalculations.toDataPoints(
                    customProxyOption?.interpolatedCumulativeReturn ?? [],
                  )}
                  subjectLineColor={Colors.HighlightDark}
                  markerRadius={1.5}
                />
              </div>
            )}
          </div>
        </div>
      ),
      value: 'custom',
      index: 0,
    };

    const textOption = {
      element: <TextCategory singleProxy={singleProxy} proxyType={proxyType} />,
      value: 'text',
      index: 1,
      showRadio: false,
    };
    return [searchOption, textOption, ...options];
  }, [
    selectedIndex,
    sortedProxyOptions,
    options,
    proxyType,
    rawInvestmentReturnsRanges,
    selectedProxyType,
    customSelectedProxy,
    customProxyOption,
    investments,
    onSelectCustomProxy,
    singleProxy,
    Colors.HighlightDark,
  ]);

  const onCategoryChange = useCallback(
    (value: string) => {
      const selectedOptionIndex = combinedOptions.findIndex((option) => option.value === value);
      setSelectedIndex(selectedOptionIndex);

      if (value === 'custom') {
        onSelect(customSelectedProxy, customProxyOption?.suggestedNumLags);
      } else {
        const selectedOption = sortedProxyOptions.find((option) => option.category.indexId === value);
        selectedOption &&
          onSelect(
            { name: selectedOption.category.indexName, id: selectedOption.category.indexId },
            selectedOption.suggestedNumLags,
          );
      }
    },
    [combinedOptions, customSelectedProxy, sortedProxyOptions, onSelect, customProxyOption?.suggestedNumLags],
  );

  return (
    <div className="flex flex-col mt-3">
      <RadioGroup
        groupName="bulk-proxy-group"
        options={combinedOptions}
        onChange={onCategoryChange}
        className="border border-solid border-venn-grey-30 border-b-0 p-4 hover:bg-venn-grey-10 last:border-b mr-0"
        defaultOption={combinedOptions[selectedIndex ?? 0]?.value}
      />
    </div>
  );
};

const useProxyOptions = (
  investments: FundToBulkProxy[],
  proxyType?: ProxyTypeEnum,
  numLags?: number,
): ProxyOption[] => {
  const queries = useMemo(() => {
    if (!investments.length || !proxyType) {
      return [];
    }

    return investments.map(({ id }) => ({
      queryKey: createProxyOptionsKey(id, proxyType, numLags ?? 0),
      queryFn: () => getProxyOptionsForFund(id, proxyType, numLags ?? 0).then((response) => response.content),
      onError: (error: Parameters<typeof logExceptionIntoSentry>[0]) => {
        Notifications.notify('Unable to load suggested proxy categories', NotificationType.ERROR);
        logExceptionIntoSentry(error);
      },
      suspense: true,
    }));
  }, [investments, proxyType, numLags]);

  const results = useQueries({ queries });

  const sortedProxyOptions = useMemo(() => {
    const proxyOptions = results.reduce<ProxyOption[]>((acc, result) => {
      if (result.data) {
        if (acc.length === 0) {
          return result.data;
        }
        return intersectionBy(acc, result.data, 'category.indexId');
      }

      return acc;
    }, []);

    return proxyOptions.sort(sortCategoryOptions);
  }, [results]);

  return sortedProxyOptions;
};

const useIsCategoryProxy = (initialProxy: SelectedProxy | null, options: RadioOption[]): boolean => {
  return options.some((option) => option.value === initialProxy?.id);
};

export const BulkCategoryOptions = withSuspense(<ShimmerFallback />, React.memo(InternalBulkCategoryOptions));
