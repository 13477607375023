import React from 'react';
import { TooltipLink, FACTOR_CONTRIBUTION_TO_RISK_HREF } from 'venn-ui-kit';
import FactorSummaryChart from './FactorSummaryChart';
import type { SummaryChartProps } from './factorSummaryTypes';
import { convertDataToExcel } from './utils';
import { FactorAnalysisContext } from '../contexts';

const RiskSummaryChart = ({
  data,
  title,
  subTitle,
  width,
  onClick,
  transform,
  activeFactorId,
  updateActiveFactorId,
  isFund,
  filterCount,
  dataExportEvent,
  secondaryLabel,
  trackingProps,
}: SummaryChartProps) => {
  const { analysisSubject, relative, categoryActive, downloadMetaData, factorsDescriptions } =
    React.useContext(FactorAnalysisContext);

  const titleText = title || (relative ? 'Active Factor Contributions to Risk' : 'Factor Contributions to Risk');
  const excelData = convertDataToExcel(data, titleText, 'Factor', true, analysisSubject);
  return (
    <FactorSummaryChart
      metricType="risk"
      data={data}
      title={titleText}
      subTitle={
        subTitle ?? (
          <>
            What percent of total risk is driven by each factor?
            <TooltipLink
              positions={{
                top: -60,
                left: -90,
              }}
              href={FACTOR_CONTRIBUTION_TO_RISK_HREF}
              top
            />
          </>
        )
      }
      percentage
      waterfall
      width={width}
      factorsDescription={factorsDescriptions}
      onClick={onClick}
      transform={transform}
      activeFactorId={activeFactorId}
      updateActiveFactorId={updateActiveFactorId}
      relative={relative}
      categoryActive={categoryActive}
      isFund={isFund}
      downloadMetaData={downloadMetaData}
      filterCount={filterCount}
      exportData={excelData}
      dataExportEvent={dataExportEvent}
      secondaryLabel={secondaryLabel}
      trackingProps={trackingProps}
      analysisSubject={analysisSubject}
    />
  );
};

export default RiskSummaryChart;
