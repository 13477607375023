import React, { useContext } from 'react';
import type { InvestmentRange } from 'venn-api';
import { Button, Icon, Link as LinkStyle } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { getSecondaryDisplayLabel, MANAGE_DATA_SECTION, useHasFF } from 'venn-utils';
import type { RangeDebugGroup } from '../../../hooks/useRangesDebug';
import { getRangeFrequency } from '../PortfolioRangesUtils';
import {
  EmptyStateContent,
  FundListItem,
  Frequency,
  ListItemWithFrequency,
  QuickAction,
  FloatRight,
} from '../components/styled';
import ExpandableList from '../components/ExpandableList';
import { isInvalidFrequency } from '../components/FrequencyDebug';
import RangeDebugContext from '../../../contexts/range-debug-context';
import InvestmentWithInlineActions from '../components/InvestmentWithInlineActions';
import { ManageDataLink, ManagePortfolioDataTrigger } from '../../../manage-data';

interface ErrorConstrainedFrequencyProps {
  subject: AnalysisSubject;
  rangeDebugGroup?: RangeDebugGroup;
  regressionName: string;
  availableStart: number;
  availableEnd: number;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  rangeDebugGroup,
  regressionName,
  availableStart,
  availableEnd,
}: ErrorConstrainedFrequencyProps) => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');
  const { onClearBenchmark, onClearComparison, onClearCategory, onFundDataUpdated, onDeleteFundFromPortfolio } =
    useContext(RangeDebugContext);
  const portfolioRange = rangeDebugGroup?.primary?.response?.portfolioRange;
  if (!portfolioRange) {
    return null;
  }

  const totalFrequency = getRangeFrequency(portfolioRange);

  const invalidFrequencyPrimaryInvestments: InvestmentRange[] = (portfolioRange.investments || []).filter(
    (investmentRange: InvestmentRange) => isInvalidFrequency(investmentRange.frequency),
  );

  const [secondaryInvalidFrequency, benchmarkInvalidFrequency] = [
    portfolioRange?.secondary?.frequency,
    portfolioRange?.benchmark?.frequency,
  ].map((frequency) => isInvalidFrequency(frequency));

  // @ts-expect-error: fixme
  const customRenderer = (onClick) => (
    <div>
      <p>
        Go to{' '}
        <button type="button" onClick={onClick} className="text-venn-dark-blue hover:text-venn-dark-blue/90 font-bold">
          Manage Data
        </button>{' '}
        to apply proxies.
      </p>
      <Button className="print:hidden" onClick={onClick} dominant refreshedStyling>
        Manage Data
      </Button>
    </div>
  );

  return (
    <EmptyStateContent style={{ maxWidth: '100%' }}>
      Cannot run {regressionName} for a <Frequency>{totalFrequency}</Frequency> analysis, <Frequency>daily</Frequency>{' '}
      or <Frequency>monthly</Frequency> frequency is required.
      {hasBulkProxy ? (
        // TODO(houssein): Refactor ManageDataLink to a custom navigation hook
        <ManageDataLink
          subject={subject}
          defaultEditData
          customRenderer={customRenderer}
          forceSection={MANAGE_DATA_SECTION.ANALYSIS_RANGE}
          errorState
        />
      ) : (
        <>
          {' '}
          The following items are constraining your frequency:
          <ExpandableList initiallyShown={3}>
            {benchmarkInvalidFrequency && (
              <ListItemWithFrequency
                name="Benchmark"
                label={subject.activeBenchmarkName}
                range={portfolioRange?.benchmark}
                suffix=". "
              >
                <QuickAction onClick={onClearBenchmark}>Click to remove benchmark.</QuickAction>
              </ListItemWithFrequency>
            )}
            {secondaryInvalidFrequency && (
              <ListItemWithFrequency
                name={
                  subject.type === 'portfolio'
                    ? getSecondaryDisplayLabel(subject, 'Portfolio as of', 'Portfolio')
                    : 'Category'
                }
                label={portfolioRange.secondary.name}
                range={portfolioRange?.secondary}
                suffix=". "
              >
                {subject.type === 'portfolio' ? (
                  <QuickAction onClick={onClearComparison}>Click to remove comparison portfolio.</QuickAction>
                ) : (
                  <QuickAction onClick={onClearCategory}>Click to turn category off.</QuickAction>
                )}
              </ListItemWithFrequency>
            )}
            {invalidFrequencyPrimaryInvestments.length > 0 &&
              (subject.type === 'portfolio' ? (
                <FundListItem>
                  Investments in your portfolio:
                  <ExpandableList initiallyShown={3}>
                    {invalidFrequencyPrimaryInvestments.map((investmentRange: InvestmentRange) => (
                      <InvestmentWithInlineActions
                        key={investmentRange.id}
                        infoType="frequency"
                        subject={subject}
                        fundId={investmentRange.id}
                        fundName={investmentRange.name}
                        range={investmentRange}
                        onFundDataUpdated={onFundDataUpdated}
                        onDeleteFundFromPortfolio={onDeleteFundFromPortfolio}
                        availableStart={availableStart}
                        availableEnd={availableEnd}
                      />
                    ))}
                  </ExpandableList>
                </FundListItem>
              ) : (
                <InvestmentWithInlineActions
                  infoType="frequency"
                  subject={subject}
                  fundId={subject.fund!.id}
                  fundName={subject.name}
                  label="Current Investment"
                  range={portfolioRange.investments[0]!}
                  onFundDataUpdated={onFundDataUpdated}
                  onDeleteFundFromPortfolio={onDeleteFundFromPortfolio}
                  availableStart={availableStart}
                  availableEnd={availableEnd}
                />
              ))}
          </ExpandableList>
          <ManagePortfolioDataTrigger hidden={subject.type !== 'portfolio'}>
            {(openManageDataPage) => (
              <FloatRight>
                <LinkStyle>
                  <button type="button" onClick={openManageDataPage}>
                    <Icon type="question-circle" /> How is my available analysis period calculated?
                  </button>
                </LinkStyle>
              </FloatRight>
            )}
          </ManagePortfolioDataTrigger>
        </>
      )}
    </EmptyStateContent>
  );
};
