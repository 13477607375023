import React from 'react';
import ReviewPortfolioViewSubtitle from './components/page-parts/ReviewPortfolioViewSubtitle';
import type { PortfolioMultiUploaderViewTemplate } from './types';
import {
  MULTI_PORTFOLIO_UPLOADER_FAQ_HREF,
  PORTFOLIO_CONSTRUCTION_FAQ_HREF,
  PRIVATE_ASSET_LAB_FAQ_HREF,
  PRIVATE_ASSET_UPLOADER_FAQ_HREF,
  RETURNS_UPLOADER_FAQ_HREF,
} from 'venn-ui-kit';
import { StyledFaqLink } from './views/shared/layout';

export const IChooseActionView = {
  id: 'CHOOSE_ACTION',
  title: (hasHistorical) =>
    hasHistorical ? 'Create or update investments & portfolios' : 'Create and/or update portfolios and investments',
  requiredPermissions: [],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IUploadReturnsView = {
  id: 'UPLOAD_RETURNS',
  title: () => 'Upload investment return data',
  shortTitle: (hasHistorical) => (hasHistorical ? 'Returns' : undefined),
  subtitle: (
    <>
      Add or update data in your library of investments. Visit the <StyledFaqLink link={RETURNS_UPLOADER_FAQ_HREF} />{' '}
      for more information.
    </>
  ),
  description: (hasHistorical) =>
    hasHistorical ? 'Investments based on returns data' : 'Add new or update existing returns-based investment(s)',
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IReviewReturnsView = {
  id: 'REVIEW_RETURNS',
  title: () => 'Review and validate returns data',
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IUploadPrivatesView = {
  id: 'UPLOAD_PRIVATES',
  title: () => 'Upload private asset data',
  shortTitle: (hasHistorical) => (hasHistorical ? 'Private assets' : 'Cash flows'),
  subtitle: (
    <>
      Add or update data in your library of investments. Visit the{' '}
      <StyledFaqLink link={PRIVATE_ASSET_UPLOADER_FAQ_HREF} /> for more information.
    </>
  ),
  description: (hasHistorical) =>
    hasHistorical
      ? 'Investments based on cash flow and performance data'
      : 'Add new or update existing private asset investment(s), including cash flows and other private asset metadata',
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: ['private_analytics'],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IReviewPrivatesView = {
  id: 'REVIEW_PRIVATES',
  title: () => 'Review and validate private asset data',
  requiredPermissions: ['UPLOAD_RETURNS'],
  requiredFeatures: ['private_analytics'],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const ICreateMasterPortfolioView = {
  id: 'CREATE_MASTER_PORTFOLIO',
  title: () => 'Create master pro forma portfolio',
  shortTitle: (hasHistorical) => (hasHistorical ? 'Create master pro forma portfolio' : undefined),
  description: () => 'Pro forma portfolio using static allocations, used for returns-based analytics',
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IChooseActionPortfolioView = {
  id: 'CHOOSE_ACTION_PORTFOLIO',
  title: (hasHistorical) =>
    hasHistorical
      ? 'Create or update returns-based portfolios'
      : 'Create or update pro forma portfolio(s) using Portfolio Builder',
  shortTitle: (hasHistorical) => (hasHistorical ? 'Returns' : 'Returns-based portfolio(s)'),
  description: (hasHistorical) =>
    hasHistorical
      ? 'Portfolios based on returns data'
      : 'Pro forma portfolio using static allocations, used for returns-based analytics',
  highlightText: (hasHistorical) =>
    hasHistorical
      ? 'Upload multiple portfolios including historical allocations'
      : 'Now upload multiple portfolios from a single file!',
  tileHeader: 'Portfolio Builder',
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IUploadPortfolioView = {
  id: 'UPLOAD_PORTFOLIO',
  title: (hasHistorical) => (hasHistorical ? 'Upload portfolio data' : 'Upload portfolio data using Portfolio Builder'),
  shortTitle: () => 'Upload portfolio data',
  subtitle: (
    <>
      Uploaded file can contain one or more portfolios, including strategy hierarchies and allocations. Visit the{' '}
      <StyledFaqLink link={MULTI_PORTFOLIO_UPLOADER_FAQ_HREF} /> for more information.
    </>
  ),
  description: (hasHistorical) =>
    hasHistorical
      ? 'Update existing or create new portfolio(s) by uploading NAVs, strategy hierarchies, and allocation dates from a spreadsheet'
      : 'Update existing or create new portfolio(s) by uploading NAVs and strategy hierarchies from a spreadsheet.',
  highlightText: (hasHistorical) =>
    hasHistorical
      ? 'Upload multiple portfolios including historical allocations'
      : 'Upload multiple portfolios’ NAVs & strategy hierarchies in a single file!',
  tileHeader: 'Portfolio Builder',
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IReviewProFormaPortfolioView = {
  id: 'REVIEW_PRO_FORMA_PORTFOLIO',
  title: () => 'Preview and adjust portfolio data',
  subtitle: () => <ReviewPortfolioViewSubtitle />,
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IReviewHistoricalPortfolioView = {
  id: 'REVIEW_HISTORICAL_PORTFOLIO',
  title: () => 'Preview and adjust historical portfolio data',
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const IPortfolioUploadConfirmationView = {
  id: 'PORTFOLIO_UPLOAD_CONFIRMATION',
  title: () => 'Portfolio data successfully uploaded',
  requiredPermissions: ['CREATE_PORTFOLIO', 'EDIT_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

const NewPortfolioSubtitle = () => {
  return (
    <>
      Give your portfolio a name. Visit the <StyledFaqLink link={PORTFOLIO_CONSTRUCTION_FAQ_HREF} /> for more
      information.
    </>
  );
};
export const IDuplicatePortfolioView = {
  id: 'DUPLICATE_PORTFOLIO',
  title: () => 'Duplicate an existing portfolio',
  shortTitle: (hasHistorical) => (hasHistorical ? 'Duplicate an existing portfolio' : undefined),
  subtitle: NewPortfolioSubtitle(),
  description: () => 'Copy existing portfolio as a starting point to edit further',
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const INewPortfolioView = {
  id: 'NEW_PORTFOLIO',
  title: () => 'Build a new portfolio in Analysis',
  subtitle: NewPortfolioSubtitle(),
  description: () => "Build your portfolio by adding investments from Venn's Data Library and assigning allocations",
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: [],
} as const satisfies PortfolioMultiUploaderViewTemplate;

export const ICreatePrivatePortfolioView = {
  id: 'CREATE_PRIVATE_PORTFOLIO',
  title: () => 'Create new private asset portfolio',
  shortTitle: (hasHistorical) => (hasHistorical ? 'Private assets' : 'Private asset portfolio'),
  subtitle: (
    <>
      Give your portfolio a name. Then, either select an existing portfolio template or create a new portfolio from
      scratch. Visit the <StyledFaqLink link={PRIVATE_ASSET_LAB_FAQ_HREF} /> for more information.
    </>
  ),
  description: (hasHistorical) =>
    hasHistorical
      ? 'Portfolios based on cash flow and performance data'
      : 'Contains only private asset data and is used for private asset-specific analytics',
  requiredPermissions: ['CREATE_PORTFOLIO'],
  requiredFeatures: ['private_analytics'],
} as const satisfies PortfolioMultiUploaderViewTemplate;
