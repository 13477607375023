import type { ExcelCell, CustomizableMetric, CustomBlockTypeEnum } from 'venn-utils';
import { assertNotNil } from 'venn-utils';
import type { CustomRow, PortfolioBreakdownRowData, PortfolioRowData, AnalysisRequest } from '../types';
import { compact } from 'lodash';
import { EMPTY } from './exportUtils';

export function parsePortfolioBreakdownExcel(
  requests: AnalysisRequest[],
  customBlockType: CustomBlockTypeEnum | undefined,
  metrics: CustomizableMetric[] | undefined,
  customRows: CustomRow[] | undefined,
): ExcelCell[][] {
  if (requests.length === 0 || metrics?.length === 0) {
    return [];
  }

  if (customBlockType === 'PORTFOLIO_BREAKDOWN') {
    return compact([
      ...getPortfolioBreakdownExcelHeaderRows(requests, metrics),
      ...assertNotNil(customRows).map((customRow) =>
        parsePortfolioBreakdownExcelRow(requests, metrics, customRow as PortfolioRowData<PortfolioBreakdownRowData>),
      ),
    ]);
  }

  return [];
}

function getPortfolioBreakdownExcelHeaderRows(
  requests: AnalysisRequest[],
  metrics: CustomizableMetric[] | undefined,
): ExcelCell[][] {
  if (metrics?.length === 1) {
    return [
      [
        EMPTY,
        {
          value: requests[0]!.subject.name,
          bold: true,
        },
      ],
      [
        {
          value: 'Strategy',
          bold: true,
        },
        {
          value: metrics[0]!.label,
          bold: true,
        },
      ],
    ];
  }
  return [];
}

function parsePortfolioBreakdownExcelRow(
  requests: AnalysisRequest[],
  metrics: CustomizableMetric[] | undefined,
  row: PortfolioRowData<PortfolioBreakdownRowData>,
): ExcelCell[] | undefined {
  if (metrics?.length === 1) {
    return [
      {
        value: row.key,
        bold: row.isStrategy,
      },
      {
        value: metrics[0]!.key === 'allocation' ? row.value[0]!.weight : undefined,
        percentage: true,
      },
    ];
  }
  return undefined;
}
