import React from 'react';
import { TooltipPosition } from 'venn-ui-kit';
import { PercentageSpan, CumulativeReturnSnapshotChart } from 'venn-components';
import { StyledHint } from './StyledComponents';
import type { RecentFactorPerformance } from 'venn-api';
import { CUMULATIVE_TOOLTIP_BACKGROUND, StyledCumulativeTooltip } from './CumulativeTooltip';

const renderYTDReturn = (item: RecentFactorPerformance) => {
  if (item.ytdCumulativeReturn) {
    return (
      <p>
        YTD:&nbsp;
        <PercentageSpan
          className="tooltip-value"
          value={
            item.ytdCumulativeReturn.length > 0 ? item.ytdCumulativeReturn[item.ytdCumulativeReturn.length - 1]![1]! : 0
          }
        />
      </p>
    );
  }
  return null;
};

interface FactorSummaryTooltipProps {
  item: RecentFactorPerformance;
}

const FactorSummaryTooltip = ({ item }: FactorSummaryTooltipProps) => {
  return (
    <StyledCumulativeTooltip
      background={CUMULATIVE_TOOLTIP_BACKGROUND}
      position={TooltipPosition.Top}
      content={
        <div>
          <h1>Cumulative Return</h1>
          {renderYTDReturn(item)}

          <StyledHint>Click the name to dive into the factor.</StyledHint>
        </div>
      }
    >
      <span>
        <CumulativeReturnSnapshotChart
          cumulativeReturn={item.ytdCumulativeReturn as [number, number][]}
          highlightLastPoint
        />
      </span>
    </StyledCumulativeTooltip>
  );
};

export default React.memo(FactorSummaryTooltip);
