import type { ExcelCell } from 'venn-utils';
import { convertSeriesToExcel } from 'venn-utils';
import type { ChartDataWithRollingPeriods } from './types';
import type { RollingPeriodSettings } from 'venn-components';
import { isNil } from 'lodash';

export const getRollingReturnExportData = (
  chartData: ChartDataWithRollingPeriods | undefined,
  rollingPeriod: RollingPeriodSettings,
  relative: boolean,
): ExcelCell[][] | undefined => {
  if (!chartData) {
    return undefined;
  }
  const series = !isNil(rollingPeriod.period)
    ? chartData?.main?.data[rollingPeriod.period]
    : chartData?.main?.customData;
  const data =
    convertSeriesToExcel(chartData?.main?.name || '', series?.[relative ? 'relative' : 'absolute'] ?? []) ?? [];
  return data.map((item, i) =>
    i === 0
      ? item.map((col) => ({ value: col }))
      : [{ value: item[0]! }, { value: parseFloat(item[1]!), percentage: true }],
  );
};
