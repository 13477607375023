import type { AnalysesPeriod, TimeFrame, TrendRollingPeriods, AnalysisConfig } from 'venn-utils';
import { getChunkedAnalysisRequests, flattenChunkedRequestOrResponse, getDefaultAnalysisType } from 'venn-utils';
import type {
  Analysis,
  AnalysisRequest,
  AnalysisResponse,
  GeneralAnalysisTemplate,
  Message,
  OperationResult,
  Portfolio,
  UserProfileSettings,
  UserSettings,
} from 'venn-api';
import { analysis } from 'venn-api';
import { getAnalysisTypeEnumFromTemplate } from '../blocks';
import trackAnalysis from './trackAnalysis';

export interface FetchAnalysisResult {
  analyses?: Analysis[];
  request?: Partial<AnalysisRequest>;
  analysesError?: Partial<Message>;
  analysesPeriod?: AnalysesPeriod;
  loading?: boolean;
  actualTimeFrame?: TimeFrame;
}

export default async (
  analysisConfig: AnalysisConfig,
  userSettings: UserSettings,
  masterPortfolio: Portfolio | undefined,
  templates: GeneralAnalysisTemplate[],
  trendRollingPeriods: TrendRollingPeriods,
  profileSettings: UserProfileSettings | undefined,
  selectedNotablePeriods?: number[],
  abortSignal?: AbortSignal,
): Promise<FetchAnalysisResult> => {
  const { analysisTemplate, relative, subject, category, trackingId, selectedTimeFrame, selectedPeriod } =
    analysisConfig;
  if (!analysisTemplate) {
    return {};
  }

  const chunkedConfigs = getChunkedAnalysisRequests(
    getAnalysisTypeEnumFromTemplate(analysisTemplate),
    subject,
    selectedTimeFrame,
    relative,
    category === 'ON',
    trendRollingPeriods,
    selectedPeriod,
    undefined,
    trackingId,
    selectedNotablePeriods,
  );

  if (!chunkedConfigs || chunkedConfigs.length === 0 || !chunkedConfigs[0]!.analyses || !subject) {
    return {};
  }
  try {
    const results = await Promise.all(
      chunkedConfigs.map(async (groupedConfig) => analysis(groupedConfig, abortSignal)),
    ).then((data) => data.map((result: OperationResult<AnalysisResponse>) => result.content));

    const content: Partial<AnalysisResponse> = flattenChunkedRequestOrResponse(results);
    const configs: Partial<AnalysisRequest> = flattenChunkedRequestOrResponse(chunkedConfigs);
    const actualTimeFrame = {
      startTime: content.startTime,
      endTime: content.endTime,
    };

    const defaultTemplateId = getDefaultAnalysisType(subject.type, userSettings, templates, profileSettings);

    trackAnalysis({
      analysisConfig,
      content,
      configs,
      relative,
      userSettings,
      masterPortfolio,
      defaultTemplateId,
      actualTimeFrame,
      profileSettings,
    });

    return {
      analyses: content.analyses,
      request: configs,
      analysesError: content?.subjectErrors?.[0] || content?.subjectErrors?.[1] || content?.subjectErrors?.[2], // api could have error on other field
      analysesPeriod: {
        // note that it's possible that any/all of these are undefined
        maxStartTime: content.maxStartTime,
        maxEndTime: content.maxEndTime,
        frequency: content.maxFrequency,
        startTime: content.startTime,
        endTime: content.endTime,
      },
      actualTimeFrame,
    };
  } catch (e) {
    if (e.name !== 'AbortError') {
      const { content } = await e;
      return {
        analysesError: content as Message,
      };
    }
    return {
      analysesError: undefined,
    };
  }
};
