import React, { useMemo, useContext, useCallback } from 'react';
import type { ReturnCell } from 'venn-api';
import { ThemeContext } from 'styled-components';
import type { GeneralChartProps, Serie } from './types';
import UserContext from '../../../contexts/user-context';
import { DownloadableContentBlock } from '../../../content-block';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { getCurrencySymbol } from './chartsUtils';
import {
  convertMultipleSeriesDataToExcel,
  getBaseFee,
  getComparisonLabelForBlockLegend,
  hasFeesExcluded,
  Numbers,
} from 'venn-utils';
import useExportUpdate from '../../logic/useExportUpdate';
import { compact } from 'lodash';
import BoxChart from './BoxChart';
import StyledEmptyState from '../StyledEmptyState';
import { formatExportableNameWithOptionalFee, formatNameWithOptionalFee } from '../../../legend';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';

const BoxChartParent = ({
  metrics,
  selectedBlock,
  analyses,
  requests,
  downloadableContentRef,
  height,
  subjectColors,
  inPrintMode,
  exportMetaData,
  selectedRefId,
}: GeneralChartProps) => {
  const theme = useContext(ThemeContext);
  const metric = metrics ? metrics[0] : undefined;
  const userContext = useContext(UserContext);
  const workspaceCurrency = userContext.profileSettings?.organization?.currency;
  const useLogarithmicScale = selectedBlock?.logarithmicScale ?? true;

  const request = requests[0]!;
  const start = new Date();
  const end = new Date();

  end.setFullYear(start.getFullYear() + (request?.forecastWindow ?? 10));

  const isExportable = (exportable: boolean[], index: number) => {
    if (index === -1) {
      return false;
    }
    return exportable[index];
  };

  const [series, exportableSeries] = useMemo(() => {
    if (!analyses || analyses.length < 1 || !metric || !requests) {
      return [[], []];
    }

    const analysis = analyses[0]![0]!;

    if (!analysis || !analysis.growthSimulation) {
      return [[], []];
    }

    if (compact(analysis.growthSimulation).length !== analysis.growthSimulation.length) {
      // check that growthsimulations does not contain any null values
      return [[], []];
    }

    const series: Serie[] = [];
    const exportableSeries: Serie[] = [];

    analysis.growthSimulation.forEach((simulation, subIdx) => {
      const data: number[] = [];
      const fee = getBaseFee(requests[subIdx]!.subject);
      const name =
        (subIdx === requests.length
          ? request.benchmark?.name
          : getComparisonLabelForBlockLegend(requests[subIdx]!.subject)) ?? '';

      const nameWithFee = `${formatNameWithOptionalFee(
        name,
        fee,
        hasFeesExcluded(requests[subIdx]!.subject),
      )}, Return (forecast): ${Numbers.safeFormatPercentage(
        simulation.forecastReturn,
      )}, Volatility (forecast): ${Numbers.safeFormatPercentage(simulation.forecastVolatility)}`;

      const exportableNameWithFee = `${formatExportableNameWithOptionalFee(
        name,
        fee,
        hasFeesExcluded(requests[subIdx]!.subject),
      )}, Return (forecast): ${Numbers.safeFormatPercentage(
        simulation.forecastReturn,
      )}, Volatility (forecast): ${Numbers.safeFormatPercentage(simulation.forecastVolatility)}`;

      simulation.growthSimulations.forEach((growthSimulation) => {
        const ts = growthSimulation.forecast;
        if (isExportable(analysis.exportable ?? [], subIdx)) {
          exportableSeries.push({
            name: exportableNameWithFee,
            data: convertToTimeseries(ts),
          });
        }
        const lastElement = growthSimulation.forecast.pop();
        if (lastElement) {
          data.push(lastElement[1]!);
        }
      });

      series.push({
        name: nameWithFee,
        color: subjectColors[subIdx],
        data: [data],
      });
    });

    return [series, exportableSeries];
  }, [metric, analyses, requests, request.benchmark?.name, subjectColors]);

  const defaultHeader =
    selectedBlock?.relativeToBenchmark && metric?.relativeLabel
      ? metric.relativeLabel
      : !metric
        ? 'Builder Chart'
        : metric.label;

  const excelDataFn = useCallback(
    () =>
      convertMultipleSeriesDataToExcel(
        exportableSeries.map((s) => ({ name: s.name, series: s.data })),
        false,
        getCurrencySymbol(workspaceCurrency),
      ),
    [exportableSeries, workspaceCurrency],
  );

  useExportUpdate({ selectedRefId, exportMetaData, excelDataFn });

  const component =
    requests.length > 0 && series.length > 0 && requests[0]!.forecastConfidenceLevels ? (
      <BoxChart
        start={start.getTime()}
        end={end.getTime()}
        series={series}
        frequency={requests?.[0]?.frequency ?? 'MONTHLY'}
        yAxisUnitFormat="ratio"
        inPrintMode={inPrintMode}
        height={height}
        currency={workspaceCurrency}
        percentiles={requests[0]!.forecastConfidenceLevels}
        theme={theme}
        useLogarithmicScale={useLogarithmicScale}
      />
    ) : (
      <StyledEmptyState selectedRefId={selectedRefId} header="Unable to run analysis" message="Chart is empty" />
    );

  if (inPrintMode) {
    return component;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: series.length === 0,
        target: downloadableContentRef,
      }}
    >
      {component}
    </DownloadableContentBlock>
  );
};

export default BoxChartParent;

const convertToTimeseries = (ts: number[][] | ReturnCell[]): number[][] => {
  if (!ts) {
    return [];
  }
  if (Array.isArray(ts[0])) {
    return ts as number[][];
  }

  return (ts as ReturnCell[]).map((t) => [t.date, t.val]);
};
