import type {
  Analysis as APIAnalysis,
  AnalysisRequest,
  AnalysisTypeEnum,
  RollingAnalysisResponse,
  Message,
  ReturnsHistogram,
} from 'venn-api';
import type {
  AnalysisSubject,
  AnalysisGroup,
  AnalysesPeriod,
  AnalysesResults,
  PerformanceAnalysis,
  FactorAnalysis,
  AttributionAnalysis,
} from 'venn-utils';
import findIndex from 'lodash/findIndex';

function buildEmpty<T>(empty?: T): AnalysisGroup<T> {
  return {
    subject: empty,
    benchmark: empty,
    category: empty,
  };
}

export function buildGroup<T>(results: T[] | null, indices: number[], message?: Message): AnalysisGroup<T> {
  if (!results) {
    return {
      subject: undefined,
      benchmark: undefined,
      category: undefined,
      message,
    };
  }
  return {
    subject: indices[0]! > -1 ? results[indices[0]!] : undefined,
    benchmark: indices[1]! > -1 ? results[indices[1]!] : undefined,
    category: indices[2]! > -1 ? results[indices[2]!] : undefined,
    message,
  };
}

function createGroup(analyses: APIAnalysis[], indicies: number[]) {
  return function createGroupFn<T>(
    type: AnalysisTypeEnum,
    getter: (analysis: APIAnalysis) => T[],
    defaultValue?: T,
  ): AnalysisGroup<T> {
    const exposures = analyses.filter((a) => a.analysisType === type);
    if (!exposures.length) {
      return buildEmpty<T>(defaultValue);
    }
    const data = exposures[0]!;
    return buildGroup(getter(data), indicies, data.message);
  };
}

const buildPerformance = (analyses: APIAnalysis[], indicies: number[]): PerformanceAnalysis => {
  const create = createGroup(analyses, indicies);
  return {
    historical: create('PERFORMANCE_SUMMARY_HISTORICAL', (a) => a.historicalPerformanceSummary),
    forecast: create('PERFORMANCE_SUMMARY_FORECAST', (a) => a.forecastedPerformanceSummary),
  };
};

const buildAttributions = (analyses: APIAnalysis[], indicies: number[]): AttributionAnalysis => {
  const create = createGroup(analyses, indicies);
  return {
    historicalContribution: create('INVESTMENT_ATTRIBUTION', (a) => a.historicalPerformanceAttributions),
    forecastContribution: create('INVESTMENT_ATTRIBUTION', (a) => a.forecastedPerformanceAttributions),
  };
};

const buildFactor = (analyses: APIAnalysis[]): FactorAnalysis => ({
  summary: {
    risk: getFilteredAnalyses(analyses, 'FACTOR_CONTRIBUTION_TO_RISK'),
    return: getFilteredAnalyses(analyses, 'FACTOR_CONTRIBUTION_TO_RETURN'),
    exposure: getFilteredAnalyses(analyses, 'FACTOR_EXPOSURES'),
  },
  trend: {
    risk: getFilteredAnalyses(analyses, 'FACTOR_CONTRIBUTION_TO_RISK_TREND'),
    return: getFilteredAnalyses(analyses, 'FACTOR_CONTRIBUTION_TO_RETURN_TREND'),
    exposure: getFilteredAnalyses(analyses, 'FACTOR_EXPOSURES_TREND'),
  },
});

const buildRolling = (
  type: AnalysisTypeEnum,
  getter: (analysis: APIAnalysis) => RollingAnalysisResponse[],
  analyses: APIAnalysis[],
  indicies: number[],
): AnalysisGroup<RollingAnalysisResponse>[] => {
  const filtered = analyses.filter((a) => a.analysisType === type);
  if (!filtered.length) {
    return [];
  }
  const rollings = filtered.slice(0, 3);
  return rollings.map((analysis) => buildGroup(getter(analysis), indicies, analysis.message));
};

const getFilteredAnalyses = (analyses: APIAnalysis[], analysisType: AnalysisTypeEnum): APIAnalysis | undefined => {
  if (!analyses) {
    return undefined;
  }
  const filteredAnalyses = analyses.filter((a) => a.analysisType === analysisType);
  if (filteredAnalyses.length === 0) {
    return undefined;
  }
  return filteredAnalyses[0];
};

export default (
  request: Partial<AnalysisRequest> | undefined,
  analyses: APIAnalysis[],
  subject: AnalysisSubject,
  relative: boolean,
  analysesPeriod?: AnalysesPeriod,
): AnalysesResults => {
  const subjectIndex = 0;
  const benchmarkIndex = findIndex(request?.subjects, (s) => s.comparisonType === 'BENCHMARK');
  const comparisonIndex = findIndex(
    request?.subjects,
    (s) => s.comparisonType === 'COMPARISON' || s.comparisonType === 'CATEGORY',
  );
  const indices = [subjectIndex, benchmarkIndex, comparisonIndex];
  const create = createGroup(analyses, indices);
  const baseline = subject?.hasSecondarySubject ? subject.secondaryPortfolio : undefined;
  return {
    analysesPeriod,
    baseline,
    subject,
    relative,
    results: {
      performance: buildPerformance(analyses, indices),
      returnsHistogram: create<ReturnsHistogram>('RETURNS_HISTOGRAM', (a) => a.returnsHistogram),
      rollingSharpe: buildRolling('ROLLING_SHARPE', (a) => a.rollingSharpe, analyses, indices),
      rollingBeta: buildRolling('ROLLING_BETA', (a) => a.rollingBeta, analyses, indices),
      rollingBenchmarkCorrelation: buildRolling(
        'ROLLING_BENCHMARK_CORRELATION',
        (a) => a.rollingBenchmarkCorrelation,
        analyses,
        indices,
      ),
      cumulativeReturns: create('CUMULATIVE_RETURN', (a) => a.cumulativeReturns, []),
      rollingVolatility: buildRolling('ROLLING_VOLATILITY', (a) => a.rollingVolatility, analyses, indices),
      factorAnalysis: buildFactor(analyses), // This needs to be deprecated
      factorsContributionsToRisk: create('FACTOR_CONTRIBUTION_TO_RISK', (a) => a.factorContributionToRisk),
      factorExposures: create('FACTOR_EXPOSURES', (a) => a.factorExposures),
      factorDrawdown: create('FACTOR_DRAWDOWN', (a) => a.factorDrawdown),
      proformaDrawdown: create('PROFORMA_DRAWDOWN', (a) => a.proformaDrawdown),
      venncast: create('VENNCAST', (a) => a.venncast),
      returnsGrid: create('RETURNS_GRID', (a) => a.returnsGrids),
      attributions: buildAttributions(analyses, indices),
      residualReturnsAnalyses: create('RESIDUAL_RETURNS_ANALYSIS', (a) => a.residualReturnsAnalyses),
      percentageDrawdown: create('PERCENTAGE_DRAWDOWN_ANALYSIS', (a) => a.percentageDrawdown),
      rollingReturn: buildRolling('ROLLING_RETURN', (a) => a.rollingReturn, analyses, indices),
      notablePeriods: create('NOTABLE_PERIODS', (a) => a.notablePeriods),
    },
    resultsExportable: {
      cumulativeReturns: create('CUMULATIVE_RETURN', (a) => a.exportable, true),
      returnsGrid: create('RETURNS_GRID', (a) => a.exportable, true),
      rollingReturn: create('ROLLING_RETURN', (a) => a.exportable, true),
    },
  };
};

export const findAnyDataExportDisabled = (exportableGroup?: AnalysisGroup<boolean>) =>
  exportableGroup?.subject === false || exportableGroup?.benchmark === false || exportableGroup?.category === false;
