import React, { useCallback, useContext, useMemo } from 'react';
import type { GeneralChartProps } from './types';
import factorLensesContext from '../../../contexts/factor-lenses-context';
import { ThemeContext } from 'styled-components';
import HeatMap from '../../../heat-map/HeatMap';
import { getProformaPortfolioPalette } from '../../../heat-map/utils';
import {
  generalDataToFactorTrendData,
  mapAnalysisTypeToFactorTrendDataType,
  convertHeatMapDataToExcel,
} from './factorTrendUtils';
import { FactorTrendNoData } from './FactorTrendNoData';
import { numberTopLegendFormatter, percentTopLegendFormatter } from './heatMapUtils';
import useExportUpdate from '../../logic/useExportUpdate';
import {
  getSecondarySubjectFromRequests,
  getSubjectFromRequestSubject,
  measureString,
  useHasFF,
  ptToPx,
} from 'venn-utils';
import { GetTypography } from 'venn-ui-kit';
import { compact, isNil, max } from 'lodash';
import { useRecoilValue } from 'recoil';
import {
  analysisSubjectQuery,
  recoilBlockContentSize,
  blockDisplayHeader,
  blockFonts,
  getCustomFontSize,
  allProxyInfoState,
} from 'venn-state';
import Measure from 'react-measure';
import { useSetBlockSize } from '../../logic/useSetBlockSize';
import { useBlockWidth } from '../../contexts/BlockWidthContext';

const RIGHT_MARGIN = 4;

export const FactorTrendHeatMapParent = ({
  metrics = [],
  analyses = [],
  requests,
  selectedBlock,
  selectedRefId,
  exportMetaData,
  inPrintMode,
}: GeneralChartProps) => {
  const hasCanvasHeatmap = useHasFF('canvas_heatmap_ff');
  const allProxy = useRecoilValue(allProxyInfoState);
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(selectedRefId));
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(selectedRefId));
  const onResize = useSetBlockSize(recoilBlockContentSize.transformedState(selectedRefId));
  const title = useRecoilValue(blockDisplayHeader(selectedRefId));
  const { primaryFactorLens } = useContext(factorLensesContext);
  const theme = useContext(ThemeContext);
  const subject = requests[0]!.subject;
  const analysisSubject = useRecoilValue(analysisSubjectQuery(getSubjectFromRequestSubject(subject)));

  const currentMetric = metrics[0];
  const showAsPercentage = currentMetric?.dataType === 'PERCENTAGE';
  const topLegendFormatter = showAsPercentage ? percentTopLegendFormatter : numberTopLegendFormatter;
  const metricType = currentMetric && mapAnalysisTypeToFactorTrendDataType[currentMetric.analysisResultKey!];
  const metricTypeForHeatMap = metricType === 'return' || metricType === 'risk' ? metricType : undefined;
  const defaultFactors = primaryFactorLens?.factors || [];
  const factorsDescriptions = defaultFactors.map(({ id, description }) => ({ id, description }));

  const secondRequestSubjectIsSecondary = !isNil(
    getSecondarySubjectFromRequests(requests[0]?.subject, requests?.[1]?.subject),
  );

  const filteredRequests = useMemo(
    () => compact([requests[0], secondRequestSubjectIsSecondary ? requests[1] : undefined]),
    [requests, secondRequestSubjectIsSecondary],
  );
  const { series, timestamps, exportableSeries, analyzedFactors } = useMemo(
    () =>
      generalDataToFactorTrendData({
        metric: metrics[0],
        requests: filteredRequests, // limit to 1 subject + optionally second subject, if it's secondary to the first
        analysisSubject,
        analyses,
        activeFactorLens: primaryFactorLens,
        theme,
        selectedFactors: selectedBlock?.selectedFactors,
        allProxy,
      }),
    [
      metrics,
      filteredRequests,
      analysisSubject,
      analyses,
      primaryFactorLens,
      theme,
      selectedBlock?.selectedFactors,
      allProxy,
    ],
  );

  const excelDataFn = useCallback(
    () =>
      convertHeatMapDataToExcel(exportableSeries, timestamps, title, false, currentMetric?.dataType === 'PERCENTAGE') ||
      [],
    [currentMetric?.dataType, exportableSeries, timestamps, title],
  );

  useExportUpdate({
    selectedRefId,
    exportMetaData,
    excelDataFn,
  });

  const width = useBlockWidth().raw.innerWidth;

  const factorsColumnWidth = useMemo(() => {
    const FACTOR_COLUMN_PADDING = 15;
    return (
      (max(
        series.map((serie) =>
          measureString(serie.name, {
            fontSize: getCustomFontSize(axisFont),
            fontFamily: GetTypography.fontFamily({ theme }),
            fontWeight: 'normal',
          }),
        ),
      ) ?? 0) +
      FACTOR_COLUMN_PADDING * 2
    );
  }, [axisFont, series, theme]);

  return (
    <div data-testid="qa-factor-trend-heat-map-parent">
      {!series.length || !timestamps.length ? (
        <FactorTrendNoData />
      ) : (
        <Measure bounds onResize={onResize}>
          {({ measureRef }) => {
            return (
              <div ref={measureRef}>
                <HeatMap
                  isPercentage={showAsPercentage}
                  factorsColumnWidth={factorsColumnWidth}
                  metricType={metricTypeForHeatMap}
                  palette={getProformaPortfolioPalette(theme.Colors)}
                  factorsDescriptions={factorsDescriptions}
                  print={!!inPrintMode}
                  showSubjectsValues={!inPrintMode}
                  series={series}
                  seriesColumnWidth={inPrintMode ? 0 : undefined}
                  filterCount={defaultFactors.length - analyzedFactors}
                  seriesDataSize={timestamps.length}
                  timestamps={timestamps}
                  topLegendFormatter={topLegendFormatter}
                  width={width - RIGHT_MARGIN}
                  cellHeight={ptToPx(axisFont.fontSizePt) * 1.85}
                  displaySubjectNames
                  theme={theme}
                  useCanvas={hasCanvasHeatmap}
                  axisFontSize={getCustomFontSize(axisFont)}
                  legendFontSize={getCustomFontSize(legendFont)}
                  marginTop="-10px"
                  exportMarginTop="-10px"
                />
              </div>
            );
          }}
        </Measure>
      )}
    </div>
  );
};
