import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  type ProxyTypeEnum,
  bulkSetProxy,
  type DetailedProxyMetadata,
  removeFundProxy,
  type ProxyMeta,
} from 'venn-api';
import {
  Icon,
  Notifications,
  NotificationType,
  PROXY_FAQ_HREF,
  Link as LinkStyle,
  Loading,
  ShimmerBlock,
} from 'venn-ui-kit';
import ProxyTypeOptions from './ProxyTypeOptions';
import { analyticsService, invalidate, useHasFF, withSuspense } from 'venn-utils';
import { typeSupportsCategoryPicker, type SelectedProxy } from './utils';
import { useBulkProxyErrorMessage } from './useProxyErrorMessage';
import { useInvestmentsReturnsRanges, useProxyReturnsRange } from './useInvestmentsReturnsRanges';
import { partition } from 'lodash';
import { ExtrapolationToggle, useExtrapolateToggle } from './components/ExtrapolationToggle';
import { ProxySummaryTable } from './ProxySummaryTable';
import SidePanelOverlay from '../../../side-panel-overlay/SidePanelOverlay';
import type { FundToBulkProxy, FundToProxy } from '../types';
import { BulkCategoryOptions } from './BulkCategoryOptions';
import { SingleInvestmentInfo } from './components/SingleInvestmentInfo';
import { BulkProxySearch } from './components/BulkProxySearch';
import { BulkProxyLegend } from './BulkProxyLegend';
import { DesmoothingLag } from './DesmoothingLag';
import { useProxyDataQuery, useRangeAnalysisQueryBatched } from '../../../hooks';

export type ProxyMetaWithName = ProxyMeta & { proxyName: string };

interface BulkProxyPickerProps {
  investments: (FundToProxy | FundToBulkProxy)[];
  onProxyChange?: (appliedProxies: ProxyMetaWithName[], failedToProxyFunds: (FundToProxy | FundToBulkProxy)[]) => void;
}

const BulkProxyHeader = () => (
  <SidePanelOverlay.Header.Container paddingTop="default">
    <SidePanelOverlay.Header.Main
      title="Proxy investments"
      subtitle={
        <>
          Proxies will be applied across your entire workspace.{' '}
          <LinkStyle>
            <a className="text-venn-dark-blue" href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          </LinkStyle>
        </>
      }
    />
  </SidePanelOverlay.Header.Container>
);

const BulkProxyPickerInternal = ({ investments, onProxyChange }: BulkProxyPickerProps) => {
  const investmentIds = investments.map((investment) => investment.id);
  const proxyDataByFund = useProxyDataQuery(investmentIds);
  const rangeDataByFund = useRangeAnalysisQueryBatched(investmentIds);
  const singleProxy = investments.length === 1;

  const hasExtrapolationFf = useHasFF('extrapolation_ff');
  const { onClose } = useContext(SidePanelOverlay.Context);
  const [selectedProxyType, setSelectedProxyType] = useState<ProxyTypeEnum | undefined>(
    () => getUniqueProxy(investments, proxyDataByFund)?.proxyType,
  );
  const [selectedProxy, setSelectedProxy] = useState<SelectedProxy | null>(() =>
    getUniqueProxy(investments, proxyDataByFund),
  );
  const [isProxiesUpdating, setIsProxiesUpdating] = useState(false);

  const extrapolationToggleProps = useExtrapolateToggle(investments, selectedProxyType, proxyDataByFund);
  const { shouldExtrapolate } = extrapolationToggleProps;
  const supportsCategoryPicker = typeSupportsCategoryPicker(selectedProxyType!);

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(investments, proxyDataByFund, rangeDataByFund);
  const { data: rawProxyReturnsRange } = useProxyReturnsRange(selectedProxy?.id);

  const validationState = useBulkProxyErrorMessage({
    investments,
    rawInvestmentReturnsRanges,
    rawProxyReturnsRange,
    selectedProxy,
    selectedProxyType,
    extrapolate: shouldExtrapolate,
  });

  const suggestedNumLags = useMemo(() => {
    return singleProxy && validationState.state === 'ready'
      ? investments[0] && validationState.investmentInfo[investments[0].id]?.suggestedLags
      : 0;
  }, [validationState, singleProxy, investments]);

  const [numLags, setNumLags] = useState(
    (singleProxy ? investments[0] && proxyDataByFund[investments[0].id]?.numLags : 0) || suggestedNumLags,
  );

  const onLagUpdate = (numLags: number) => {
    setNumLags(numLags);
  };

  const onSelectCategory = (proxy: SelectedProxy | null, lags?: number | undefined) => {
    setSelectedProxy(proxy);
    if (lags) {
      setNumLags(lags);
    }
  };

  const onSaveProxy = useCallback(async () => {
    if (validationState.state !== 'ready' || !selectedProxyType || !selectedProxy) {
      return;
    }
    setIsProxiesUpdating(true);
    const [investmentsWithErrors, investmentsWithoutErrors] = partition(
      investments,
      (investment) => validationState.investmentInfo[investment.id]?.disableSave,
    );
    try {
      const results = await bulkSetProxy(
        investmentsWithoutErrors.map((investment) => ({
          proxyId: selectedProxy.id,
          fundId: investment.id,
          proxyType: selectedProxyType,
          extrapolate: shouldExtrapolate,
          numLags: singleProxy ? (numLags ?? 0) : (validationState.investmentInfo[investment.id]?.suggestedLags ?? 0),
        })),
      );
      await invalidate(
        'investments',
        investmentsWithoutErrors.map(({ id }) => id),
      );
      onProxyChange?.(
        results.content.map((proxyMeta) => ({ ...proxyMeta, proxyName: selectedProxy.name })),
        investmentsWithErrors,
      );

      analyticsService.bulkProxyApplied({
        numSelected: investments.length,
        numApplied: investmentsWithoutErrors.length,
        numIneligible: investmentsWithErrors.length,
        extrapolate: shouldExtrapolate,
        proxyType: selectedProxyType,
      });
      Notifications.notify(
        `${investmentsWithoutErrors.length} proxies updated successfully.`,
        NotificationType.SUCCESS,
      );
    } catch (e) {
      onProxyChange?.([], investments);
      Notifications.notify('Failed to update proxies.', NotificationType.ERROR);
    } finally {
      setIsProxiesUpdating(false);
      onClose();
    }
  }, [
    investments,
    onProxyChange,
    onClose,
    validationState,
    selectedProxyType,
    shouldExtrapolate,
    selectedProxy,
    numLags,
    singleProxy,
  ]);

  const proceedMessage = useMemo(() => {
    const validInvestments =
      validationState.state === 'ready'
        ? investments.filter((investment) => !validationState.investmentInfo[investment.id]?.disableSave).length
        : investments.length;
    if (validInvestments !== investments.length) {
      return `Apply proxy to ${validInvestments} of ${investments.length} investments`;
    }
    return 'Apply proxy';
  }, [validationState, investments]);

  const removeProxyProps = useMemo(() => {
    const investment = investments[0];
    if (!investment) {
      return {};
    }
    const proxyData = proxyDataByFund[investment.id];
    if (!singleProxy || !proxyData) {
      return {};
    }
    const removeProxy = async () => {
      setIsProxiesUpdating(true);
      try {
        await removeFundProxy(investment.id);
        Notifications.notify('Proxy settings successfully removed.', NotificationType.SUCCESS);
        invalidate('investment', investment.id);
        onProxyChange?.([proxyData], []);
      } catch (e) {
        onProxyChange?.([], [investment]);
        Notifications.notify('Failed to remove proxy.', NotificationType.ERROR);
      } finally {
        onClose();
        setIsProxiesUpdating(false);
      }
    };
    return {
      secondaryDisabled: isProxiesUpdating,
      secondaryLabel: 'Remove Proxy',
      onSecondaryClick: removeProxy,
      secondaryDestructive: true,
    };
  }, [investments, proxyDataByFund, singleProxy, isProxiesUpdating, onProxyChange, onClose]);

  return (
    <>
      {isProxiesUpdating && (
        <div className="absolute z-intercom-front-2 top-0 left-0 size-full flex place-items-center bg-white bg-opacity-30 opacity-0 animate-[fade-in-opacity_1s_ease-in-out_1s_1_normal_forwards]">
          <Loading pageLoader />
        </div>
      )}
      <BulkProxyHeader />
      <StyledSidePanelBody>
        <Body>
          {singleProxy && investments[0] && (
            <SingleInvestmentInfo
              investment={investments[0]}
              proxyData={proxyDataByFund[investments[0].id]}
              rangeData={rangeDataByFund[investments[0].id]}
            />
          )}

          <div className="flex flex-row items-baseline gap-6">
            <ProxyTypeOptions
              investments={investments}
              rawInvestmentRanges={rawInvestmentReturnsRanges}
              selectedProxyRange={rawProxyReturnsRange}
              selectedProxyType={selectedProxyType}
              onSelectProxyType={setSelectedProxyType}
              showInvalidTypes
            />
            {hasExtrapolationFf && <ExtrapolationToggle {...extrapolationToggleProps} />}
            <DesmoothingLag
              investments={investments}
              selectedProxyType={selectedProxyType}
              numLags={numLags || suggestedNumLags}
              suggestedNumLags={suggestedNumLags}
              onLagUpdate={onLagUpdate}
              proxyData={investments[0] ? proxyDataByFund[investments[0].id] : undefined}
            />
          </div>
          <div className="flex items-center justify-between mr-4">
            {singleProxy && supportsCategoryPicker ? (
              <>
                <SearchLabel>Select a proxy</SearchLabel>
                <BulkProxyLegend />
              </>
            ) : (
              <SearchLabel>Select an investment to use as a proxy</SearchLabel>
            )}
          </div>
        </Body>

        {supportsCategoryPicker ? (
          <BulkCategoryOptions
            investments={investments}
            proxyType={selectedProxyType}
            selectedProxy={selectedProxy}
            onSelect={onSelectCategory}
            rawInvestmentReturnsRanges={rawInvestmentReturnsRanges}
            selectedProxyType={selectedProxyType}
            numLags={numLags || suggestedNumLags}
          />
        ) : (
          <div className="mt-2 p-3 border border-solid rounded border-venn-grey-30">
            <BulkProxySearch
              selectedProxy={selectedProxy}
              setSelectedProxy={setSelectedProxy}
              rawInvestmentReturnsRanges={rawInvestmentReturnsRanges}
              selectedProxyType={selectedProxyType}
              investments={investments}
              shouldExtrapolate={shouldExtrapolate}
              autoProxyEnabled
            />
          </div>
        )}
        <ProxySummaryTable
          proxyType={selectedProxyType}
          investments={investments}
          validationState={validationState}
          proxyDataByFund={proxyDataByFund}
        />
      </StyledSidePanelBody>
      <SidePanelOverlay.Footer
        cancelLabel={
          <div className="flex items-center gap-1.5 text-[12px]">
            <Icon type="angle-left" /> BACK
          </div>
        }
        onCancel={isProxiesUpdating ? undefined : onClose}
        onPrimaryClick={onSaveProxy}
        primaryLabel={proceedMessage}
        primaryDisabled={validationState.state === 'loading' || !!validationState.error || isProxiesUpdating}
        {...removeProxyProps}
        fixed
      />
    </>
  );
};

export const BulkProxyPicker = withSuspense(
  <>
    <BulkProxyHeader />
    <ShimmerBlock />
  </>,
  BulkProxyPickerInternal,
);

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const StyledSidePanelBody = styled(SidePanelOverlay.Body)`
  margin-bottom: 80px;
`;

function getUniqueProxy(investments: FundToBulkProxy[], proxyData: Record<string, DetailedProxyMetadata | undefined>) {
  const proxyInfo = investments[0] ? proxyData[investments[0].id] : undefined;
  const id = proxyInfo?.proxyId;
  const name = proxyInfo?.proxyName;
  const proxyType = proxyInfo?.proxyType;
  return id &&
    name &&
    investments.every(
      (investment) => proxyData[investment.id]?.proxyId === id && proxyData[investment.id]?.proxyType === proxyType,
    )
    ? {
        id,
        name,
        proxyType,
      }
    : null;
}
