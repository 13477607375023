import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { compact, isEmpty } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import type { FundingFailure, GrowthSimulationPublicPrivateResponse } from 'venn-api';
import {
  blockBenchmarkConfig,
  blockLimitedRequestSubjects,
  blockSettings,
  type StudioRequestSubject,
} from 'venn-state';
import { getItemColor, TooltipPosition } from 'venn-ui-kit';
import { assertNotNil, Dates, getRequestSubjectFromAnalysisSubject } from 'venn-utils';
import { convertRequestSubjectToItemType } from '../../../../../analysis/compare/compareUtils';
import { formatData } from '../../../../../data-grid/gridUtils';
import { formatExportableSubjectWithOptionalFee } from '../../../../../legend';
import { HEADER_LAST_COL_OF_COL_GROUP_CLASS } from '../../../../components/grid/AgGridThemeOverrides';
import { useMeasureGridText } from '../../../../../utils/grids';
import { useBlockId } from '../../../../contexts/BlockIdContext';
import {
  CURRENCY_CLASS_PREFIX,
  DUAL_SUBJECT_HEADER_RENDERER,
  RIGHT_ALIGN_CLASS,
  VALUE_CELL_RENDERER,
} from '../../../../customAnalysisContants';
import { type ConfidenceLevel, useConfidenceLevels } from '../../../../logic/useConfidenceLevels';
import FundingFailureTooltipContent from '../../../FundingFailureTooltipContent';
import { confidenceKeyFromPercentage } from '../utils';

export interface PublicPrivateAssetGrowthGridRow {
  date: number;
  nav: { [key: string]: number }[];
  confidenceLevelValue: number[][];
  confidenceLevelFundingFailures: Record<string, FundingFailure>[];
}

const getEmptyRow = (timestamp: number) => {
  return {
    date: timestamp,
    confidenceLevelValue: [[], []],
    nav: [{}, {}],
    confidenceLevelFundingFailures: [{}, {}],
  };
};

type GetCellRendererParams = {
  subjectIndex: number;
  level: ConfidenceLevel;
  publicSubject: StudioRequestSubject;
  privateSubject: StudioRequestSubject;
};

const getCellRendererParams = ({ subjectIndex, level, publicSubject, privateSubject }: GetCellRendererParams) => {
  return ({ data }: ICellRendererParams<PublicPrivateAssetGrowthGridRow>) => {
    const fundingFailure =
      data?.confidenceLevelFundingFailures[subjectIndex]![confidenceKeyFromPercentage(level.level * 100)];
    return {
      errorTooltipContent: fundingFailure ? (
        <FundingFailureTooltipContent
          fundingFailure={fundingFailure}
          confidenceLevel={level}
          publicSubject={publicSubject}
          privateSubject={privateSubject}
        />
      ) : null,
      errorTooltopPosition: TooltipPosition.Left,
    };
  };
};

export const usePublicPrivateAssetGrowthGridData = (
  data: GrowthSimulationPublicPrivateResponse[],
): PublicPrivateAssetGrowthGridRow[] => {
  const blockId = useBlockId();
  const confidenceLevels = useConfidenceLevels();
  const customBlockSettings = useRecoilValue(blockSettings(blockId));
  const navBreakdownView = customBlockSettings.customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN';
  const ts: { [key: string]: PublicPrivateAssetGrowthGridRow } = {};

  data?.forEach((_, subjectIndex) => {
    if (!data[subjectIndex]) {
      return;
    }

    confidenceLevels.forEach((level) => {
      const key = confidenceKeyFromPercentage(level.level * 100);
      const mixedNavData = data[subjectIndex]!.publicAndPrivateNav?.[key];
      const fundingFailure = data[subjectIndex]!.fundingFailures?.[key];
      if (!mixedNavData || isEmpty(mixedNavData) || !level.visible) {
        return;
      }
      mixedNavData.forEach((datapoint, index) => {
        const timestamp = datapoint[0]!;
        const publicPrivateNav = datapoint[1]!;
        // previous entry for this timestamp
        const tsRow = ts[timestamp] ?? getEmptyRow(timestamp);

        (tsRow.confidenceLevelValue[subjectIndex] as number[] | undefined)?.push(publicPrivateNav);
        if (navBreakdownView) {
          tsRow.nav[subjectIndex] = {
            privateNav: data[subjectIndex]!.privateNav[key]![index]![1]!,
            publicNav: data[subjectIndex]!.publicNav[key]![index]![1]!,
            privateNavPercent: data[subjectIndex]!.privateNavPercentage[key]![index]![1]!,
            publicNavPercent: data[subjectIndex]!.publicNavPercentage[key]![index]![1]!,
          };
        }
        ts[timestamp] = tsRow;
      });

      if (fundingFailure) {
        const tsRow = ts[fundingFailure.date] ?? getEmptyRow(fundingFailure.date);

        tsRow.confidenceLevelFundingFailures[subjectIndex]![key] = fundingFailure;
        ts[fundingFailure.date] = tsRow;
      }
    });
  });
  return Object.values(ts);
};

export const usePublicPrivateAssetGrowthGridColumnDefs = (): (
  | ColDef<PublicPrivateAssetGrowthGridRow>
  | ColGroupDef<PublicPrivateAssetGrowthGridRow>
)[] => {
  const measureText = useMeasureGridText();
  const blockId = useBlockId();
  const theme = useTheme();
  const confidenceLevels = useConfidenceLevels();
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));
  const customBlockSettings = useRecoilValue(blockSettings(blockId));
  const percentilesView = customBlockSettings.customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES';

  const privateSubject = assertNotNil(subjects.filter((subject) => subject.private)[0]);

  const publicSubjects: StudioRequestSubject[] = compact([
    ...subjects.filter((subject) => !subject.private),
    percentilesView && benchmarkConfig.type === 'COMMON' && benchmarkConfig.subject
      ? getRequestSubjectFromAnalysisSubject(benchmarkConfig.subject)
      : null,
  ]);

  return [
    {
      headerName: '',
      marryChildren: true,
      children: [
        {
          headerName: 'Date',
          headerClass: () => ['TEXT', RIGHT_ALIGN_CLASS, HEADER_LAST_COL_OF_COL_GROUP_CLASS],
          valueGetter: ({ data }) => formatYearQuarter(data?.date),
          minWidth: measureText('Q1 2024', 'normal'),
          maxWidth: measureText('Q1 2024', 'normal'),
        },
      ],
    },
    ...publicSubjects.map((subject, subjectIndex) => {
      const publicSubjectName = formatExportableSubjectWithOptionalFee(subject);
      const privateSubjectName = formatExportableSubjectWithOptionalFee(privateSubject);

      return {
        headerName: `${publicSubjectName} + ${privateSubjectName}`,
        cellClass: () => [RIGHT_ALIGN_CLASS],
        headerGroupComponent: DUAL_SUBJECT_HEADER_RENDERER,
        headerGroupComponentParams: {
          subject1: {
            color: getItemColor(theme.Colors, convertRequestSubjectToItemType(subject)),
            subject,
            isCommonBenchmark: false,
            noLink: true,
          },
          subject2: {
            color: getItemColor(theme.Colors, convertRequestSubjectToItemType(privateSubject)),
            subject: privateSubject,
            isCommonBenchmark: false,
            noLink: true,
          },
        },
        marryChildren: true,
        children: percentilesView
          ? confidenceLevels
              .filter((level) => level.visible)
              .map(
                (level, confidenceIndex) =>
                  ({
                    headerName: formatData(level.level, 'PERCENTAGE', 0),
                    cellClass: () => [`${CURRENCY_CLASS_PREFIX}$`, RIGHT_ALIGN_CLASS],
                    cellRenderer: VALUE_CELL_RENDERER,

                    cellRendererParams: getCellRendererParams({
                      subjectIndex,
                      level,
                      publicSubject: subject,
                      privateSubject,
                    }),

                    valueGetter: ({ data }) => data?.confidenceLevelValue[subjectIndex]![confidenceIndex],

                    valueFormatter: ({ data }) =>
                      formatData(data?.confidenceLevelValue[subjectIndex]![confidenceIndex], 'CURRENCY', 2),
                  }) as ColDef<PublicPrivateAssetGrowthGridRow>,
              )
              .reverse()
          : ([
              {
                headerName: `${privateSubjectName} NAV`,
                cellRenderer: VALUE_CELL_RENDERER,
                cellRendererParams: getCellRendererParams({
                  subjectIndex,
                  level: confidenceLevels[0]!,
                  publicSubject: subject,
                  privateSubject,
                }),
                cellClass: () => [`${CURRENCY_CLASS_PREFIX}$`, RIGHT_ALIGN_CLASS],
                valueGetter: ({ data }) => data?.nav[subjectIndex]!.privateNav,
                valueFormatter: ({ data }) => formatData(data?.nav[subjectIndex]!.privateNav, 'CURRENCY', 2),
              },
              {
                headerName: `${publicSubjectName} NAV`,
                cellRenderer: VALUE_CELL_RENDERER,
                cellRendererParams: getCellRendererParams({
                  subjectIndex,
                  level: confidenceLevels[0]!,
                  publicSubject: subject,
                  privateSubject,
                }),
                cellClass: () => [`${CURRENCY_CLASS_PREFIX}$`, RIGHT_ALIGN_CLASS],
                valueGetter: ({ data }) => data?.nav[subjectIndex]!.publicNav,
                valueFormatter: ({ data }) => formatData(data?.nav[subjectIndex]!.publicNav, 'CURRENCY', 2),
              },
              {
                headerName: `${privateSubjectName} NAV (% of Total)`,
                cellRenderer: VALUE_CELL_RENDERER,
                cellRendererParams: getCellRendererParams({
                  subjectIndex,
                  level: confidenceLevels[0]!,
                  publicSubject: subject,
                  privateSubject,
                }),
                cellClass: () => ['PERCENTAGE', RIGHT_ALIGN_CLASS],
                valueGetter: ({ data }) => data?.nav[subjectIndex]?.privateNavPercent,
                valueFormatter: ({ data }) => formatData(data?.nav[subjectIndex]?.privateNavPercent, 'PERCENTAGE', 2),
              },
              {
                headerName: `${publicSubjectName} NAV (% of Total)`,
                cellRenderer: VALUE_CELL_RENDERER,
                cellRendererParams: getCellRendererParams({
                  subjectIndex,
                  level: confidenceLevels[0]!,
                  publicSubject: subject,
                  privateSubject,
                }),
                cellClass: () => ['PERCENTAGE', RIGHT_ALIGN_CLASS],
                valueGetter: ({ data }) => data?.nav[subjectIndex]?.publicNavPercent,
                valueFormatter: ({ data }) => formatData(data?.nav[subjectIndex]?.publicNavPercent, 'PERCENTAGE', 2),
              },
            ] as ColDef<PublicPrivateAssetGrowthGridRow>[]),
      };
    }),
  ];
};

const formatYearQuarter = (date?: number) => {
  return date ? Dates.toQuarterYear(date) : '--';
};
