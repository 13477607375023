import { set } from 'dot-prop-immutable';
import type { Portfolio } from 'venn-api';

type PartialPortfolioWithChildren = Partial<Portfolio> & Pick<Portfolio, 'children'>;
/**
 * This updates a portfolio node given the root portfolio, and the node id (aka strategy id).
 * The updateFn function gives the found portfolio in parameter and you must return the modified (but immutable) portfolio in return.
 */
export const updateAt = (
  root: PartialPortfolioWithChildren,
  nodeId: number | undefined,
  updateFunction: (p: PartialPortfolioWithChildren) => Portfolio,
): Portfolio => {
  const path = buildPath(root, nodeId);
  const modified = path ? set(root, path, updateFunction) : updateFunction(root);
  return modified as Portfolio;
};

const buildPath = (root: Portfolio | Partial<Portfolio>, nodeId: number | undefined): string | null => {
  const path = buildPathRecursive(root, nodeId, '');
  if (path && path?.length > 1) {
    return path.substring(1, path.length);
  }

  return path;
};

const buildPathRecursive = (
  root: Portfolio | Partial<Portfolio>,
  nodeId: number | undefined,
  path = '',
): string | null => {
  if (!nodeId) {
    return path;
  }

  if (root.id === nodeId) {
    return path;
  }

  if (!root.children) {
    return null;
  }

  for (let i = 0; i < root.children.length; i++) {
    const innerPath = buildPathRecursive(root.children[i]!, nodeId, `${path}.children.${i}`);
    if (innerPath) {
      return innerPath;
    }
  }

  return null;
};
