import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilCallback, useRecoilValue, waitForAll } from 'recoil';
import { type BlockId, type SubjectWithOptionalFee } from '../types';
import { createChangesKey, createPCBlockColumnKey, type PortfolioComparisonColumnGroupKey } from '../customFieldTypes';
import { allBlockIdsState } from '../grid';
import { blockSubjects } from './subjects';
import { blockCustomMetricSettingsState, blockSettings } from './blockSettings';
import { subjectToKeyString } from './subjectKey';
import { subjectsAreEqual } from '../utils';
import { cleanEmptyFromGrid, pcBlock_customField_gridData } from './customMetrics';
import { cloneDeep, sum } from 'lodash';
import { assertExhaustive, makePromise, analyticsService } from 'venn-utils';

/**
 * A side-effect component to clear custom field data when it becomes stale due to Subject based changes.
 * This ensures that when a subject is removed, and then re-added at some later date, the custom field data is not still present.
 * This component should be mounted at the root of the Studio/RL content.
 */
export const SyncSubjectsToCustomFields = React.memo(function SyncSubjectsToCustomFields() {
  // This component is a no-op, but it's used to trigger the effect. By encapsulating the effect into this component, it won't cause
  // unnecessary rerenders for the entire component tree.
  useSyncSubjectsToCustomFields();
  return null;
});

const useSyncSubjectsToCustomFields = () => {
  const allBlockIds = useRecoilValue(allBlockIdsState);
  const allBlockSubjectSets = useRecoilValue(waitForAll(allBlockIds.map((blockId) => blockSubjects(blockId))));
  const allBlockSettings = useRecoilValue(waitForAll(allBlockIds.map((id) => blockSettings(id))));

  const onPcBlockSubjectsChanged = useOnPcBlockSubjectsChanged();

  /** Keyed by block id rather than index because index is not stable between rerenders. */
  const blockIdtoPreviousBlockSubjects = useRef<{
    [blockId: BlockId]: SubjectWithOptionalFee[] | undefined;
  } | null>(null);

  useEffect(() => {
    const prevBlockSubjectSets = blockIdtoPreviousBlockSubjects.current;
    blockIdtoPreviousBlockSubjects.current = Object.fromEntries(
      allBlockSubjectSets.map((blockSubjects, i) => [allBlockIds[i], blockSubjects]),
    );

    allBlockIds.forEach((blockId, i) => {
      const blockSetting = allBlockSettings[i]!;
      const prevSubjects = prevBlockSubjectSets?.[blockId];
      const currSubjects = allBlockSubjectSets[i]!;

      if (blockSetting.supportsCustomMetrics) {
        switch (blockSetting.customBlockType) {
          case 'PORTFOLIO_COMPARISON':
            onPcBlockSubjectsChanged(blockId, prevSubjects, currSubjects);
            break;
          default:
            assertExhaustive(blockSetting);
        }
      }
    });
  }, [allBlockIds, allBlockSettings, allBlockSubjectSets, onPcBlockSubjectsChanged]);
};

/**
 * Returns a function that should be invoked whenever the subjects change for a Portfolio Comparison block.
 * Exported for testing. Generally use {@link SyncSubjectsToCustomFields} instead.
 */
export function useOnPcBlockSubjectsChanged() {
  const clearColumnGroup = useRecoilCallback(
    (recoil) =>
      async (blockId: BlockId, groupKey: PortfolioComparisonColumnGroupKey): Promise<number> => {
        const customMetrics = await recoil.snapshot.getPromise(blockCustomMetricSettingsState(blockId));
        const [cellsClearedPromise, resolveCellsCleared] = makePromise<number>();

        recoil.set(pcBlock_customField_gridData(blockId), (oldGrid) => {
          const newGrid = cloneDeep(oldGrid);
          let cellsCleared = 0;

          for (const rowObject of Object.values(newGrid)) {
            if (rowObject) {
              for (const metric of customMetrics) {
                const columnKey = createPCBlockColumnKey(groupKey, metric.key);
                if (rowObject[columnKey] !== undefined) {
                  cellsCleared++;
                }
                delete rowObject[columnKey];
              }
            }
          }

          resolveCellsCleared(cellsCleared);
          return cleanEmptyFromGrid(newGrid);
        });

        return cellsClearedPromise;
      },
    [],
  );

  const onSubjectsChange = useCallback(
    (blockId: BlockId, prevSubjects: SubjectWithOptionalFee[] | undefined, currSubjects: SubjectWithOptionalFee[]) => {
      if (!prevSubjects || currSubjects === prevSubjects) {
        return;
      }

      const currSubjectsSet = new Set(currSubjects.map(subjectToKeyString));

      const cellsClearedPromises: Promise<number>[] = [];
      prevSubjects.forEach((prevSubject) => {
        if (!prevSubject) return;
        const prevSubjectKey = subjectToKeyString(prevSubject);
        const subjectWasDeleted = !currSubjectsSet.has(prevSubjectKey);
        if (subjectWasDeleted) {
          cellsClearedPromises.push(clearColumnGroup(blockId, prevSubjectKey));
        }
      });
      if (cellsClearedPromises.length) {
        Promise.all(cellsClearedPromises).then((cellsCleared) => {
          analyticsService.customMetricsCleared({ type: 'Subject', cellsCleared: sum(cellsCleared) });
        });
      }

      const currDeltaSubjects = currSubjects.slice(0, 2);
      const prevDeltaSubjects = prevSubjects.slice(0, 2);
      if (
        currDeltaSubjects.length !== prevDeltaSubjects.length ||
        !currDeltaSubjects.every((subject, i) => subjectsAreEqual(subject, prevDeltaSubjects[i]))
      ) {
        clearColumnGroup(blockId, createChangesKey(prevDeltaSubjects)).then((cellsCleared) =>
          analyticsService.customMetricsCleared({ type: 'Changes', cellsCleared }),
        );
      }
    },
    [clearColumnGroup],
  );

  return onSubjectsChange;
}
