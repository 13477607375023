import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Body1, GetColor, TextAlign } from 'venn-ui-kit';
import { Input } from '../../../../input/Input';
import { Numbers } from 'venn-utils';
import type { BenchmarkAction } from '../../state/benchmarkActions';
import { BenchmarkUIView } from '../../state/benchmarkActions';
import type { BenchmarksCompositeState, BenchmarkCompositeChild } from '../../state/compositeState';
import BenchmarksCompositeFooter from './BenchmarksCompositeFooter';
import BenchmarksCompositeRow from './BenchmarksCompositeRow';
import BenchmarkLabels from './BenchmarkLabels';

export interface BenchmarksCompositeViewProps {
  active: BenchmarksCompositeState;
  isNew: boolean;
  onAction(action: BenchmarkAction): void;
  selfExit?(): void;
}

interface BenchmarksCompositeViewState {
  validationError: string | null;
}

class BenchmarksCompositeView extends Component<BenchmarksCompositeViewProps, BenchmarksCompositeViewState> {
  state: BenchmarksCompositeViewState = {
    validationError: null,
  };

  handleRowUpdate = (previous: BenchmarkCompositeChild, updated: BenchmarkCompositeChild) => {
    this.props.onAction({
      type: 'BenchmarkEditCompositeChild',
      payload: {
        previous,
        updated,
      },
    });
  };

  handleRowAdd = () => {
    this.props.onAction({
      type: 'BenchmarkAppendNewCompositeChild',
    });
  };

  handleRowRemove = (row: BenchmarkCompositeChild) => {
    this.props.onAction({
      type: 'BenchmarkRemoveCompositeChild',
      payload: row,
    });
  };

  handleRename = (name: string) => {
    this.props.onAction({
      type: 'BenchmarkRenameComposite',
      payload: name,
    });
  };

  handleSubmit = () => {
    const validationResult = this.validate();
    this.setState({ validationError: validationResult });
    if (!validationResult) {
      this.props.onAction({
        type: 'BenchmarkCreateComposite',
        payload: this.props.active,
      });
    }
  };

  handleCancel = () => {
    if (this.props.selfExit) {
      this.props.selfExit();
      return;
    }
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'View',
          viewId: BenchmarkUIView.ListView,
        },
      },
    });
  };

  handleExit = () => {
    this.props.onAction({
      type: 'BenchmarkUINavigation',
      payload: {
        target: {
          kind: 'Exit',
        },
      },
    });
  };

  getTotalWeight(onlyForValidRows?: boolean): number {
    const rows = onlyForValidRows
      ? this.props.active.children.filter((child) => child.fund !== null)
      : this.props.active.children;
    return rows.reduce((weight, child) => weight + Number(child.weight), 0);
  }

  getFormattedTotalWeight(): string {
    return Numbers.formatNumber(this.getTotalWeight(), 1);
  }

  validateRow(row: BenchmarkCompositeChild, index: number): string | null {
    const weight = Number(row.weight);
    const name = row.fund ? row.fund.label : null;
    const position = index + 1;

    if (weight === 0 && name !== null) {
      return `Investment #${position}, "${name}" must have a weight greater than 0.`;
    }
    if (weight !== 0 && name === null) {
      return `Investment #${position}, with weight ${weight}%, is not specified.`;
    }
    return null;
  }

  validate(): string | null {
    if (this.props.active.name.trim() === '') {
      return 'Composite benchmark must have a name.';
    }
    const rows = this.props.active.children;
    for (let i = 0; i < rows.length; i++) {
      const validationResult = this.validateRow(rows[i]!, i);
      if (validationResult !== null) {
        return validationResult;
      }
    }
    const activeRows = rows.filter((row) => row.fund !== null);
    if (activeRows.length === 0) {
      return 'Composite benchmark must include at least one investment.';
    }

    const totalWeight = this.getTotalWeight(true /* only for valid rows */);
    if (totalWeight !== 100) {
      return 'Composite benchmark must have a total weight of 100%.';
    }

    return null;
  }

  render() {
    const { validationError } = this.state;
    return (
      <>
        <Container>
          <h2>Select a group of investments.</h2>
          <p>Weights must sum to 100%.</p>
          <Name>
            <Input placeholder="Composite benchmark name" value={this.props.active.name} onChange={this.handleRename} />
          </Name>
          <BenchmarkLabels />
          {this.props.active.children.map((child, index) => (
            <BenchmarksCompositeRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              data={child}
              onChange={(updated) => this.handleRowUpdate(child, updated)}
              onRemove={this.props.active.children.length > 1 ? () => this.handleRowRemove(child) : undefined}
            />
          ))}
          <AddAnotherInvestment onClick={this.handleRowAdd}>Add another investment...</AddAnotherInvestment>
          <TotalWeight>
            <TotalWeightLabel>Total (%)</TotalWeightLabel>
            <Input disabled textAlign={TextAlign.Right} type="text" value={this.getFormattedTotalWeight()} />
          </TotalWeight>
        </Container>
        {validationError && <Error>{validationError}</Error>}
        <BenchmarksCompositeFooter
          isNew={this.props.isNew}
          onAddButtonClick={this.handleSubmit}
          onCancelButtonClick={this.handleExit}
          onExitWithoutSavingClick={this.handleCancel}
          hideExitWithoutSaving={!!this.props.selfExit}
        />
      </>
    );
  }
}

export default BenchmarksCompositeView;

const Error = styled(Body1)`
  color: ${GetColor.Error};
  text-align: right;
  margin-right: 10px;
`;

const Container = styled.div<{ height?: number }>`
  flex-grow: 1;
  padding: 35px 10px 0;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
      overflow-x: hidden;
      overflow-y: auto;
    `}

  h2 {
    color: ${GetColor.Black};
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px;
  }

  p {
    margin: 0px 10px;
    font-size: 14px;
    color: ${GetColor.DarkGrey};
  }
`;

const Name = styled.div`
  margin: 15px 28px 5px 10px;
  & > div {
    width: 100%;
  }
  input {
    width: 100%;
  }
`;

const AddAnotherInvestment = styled.a`
  display: block;
  padding: 10px;
  margin: 5px 28px 5px 10px;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    background-color: ${GetColor.PaleGrey};
  }
`;

const TotalWeight = styled.div`
  margin: 25px 10px 0;
  display: flex;
  flex-direction: column;
  width: 56px;

  > input {
    color: #757575;
    font-weight: normal;
    text-align: right;
  }
`;

const TotalWeightLabel = styled.div`
  margin-bottom: 4px;
  width: 56px;
  text-align: right;
  color: ${GetColor.Black};
  font-size: 11px;
  font-weight: bold;
`;
