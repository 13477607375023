import { getAppTitle } from 'venn-ui-kit';

const appTitle = getAppTitle();
export const STEPS = [
  {
    key: 0,
    label: 'Connect account',
    description: `Clicking “Authorize with Addepar” below will direct you to the Addepar website to allow you to log in to your Addepar account and confirm the data that ${appTitle} will be authorized to use. You will then be redirected back here to continue set-up.`,
  },
  {
    key: 1,
    label: 'Integration overview',
    description: `The data you have in Addepar will be interpreted a little differently in ${appTitle}'s system.`,
  },
  {
    key: 2,
    label: 'Set integration parameters',
    description: `These parameters determine how your Addepar data is translated into your ${appTitle} portfolio, including naming and data structure.`,
  },
  {
    key: 3,
    label: 'Preview data import',
    description: `Based on your settings from the previous step, your Addepar data will appear in ${appTitle} as below. Proofread the sample data to ensure it is correct before continuing to sync your full account.`,
  },
  {
    key: 4,
    label: 'Sync data',
    description: `${appTitle} syncs your Addepar data once per day.`,
  },
];

export const MIN_STEP = STEPS[0]!.key;
export const MAX_STEP = STEPS[STEPS.length - 1]!.key;

export const VIEW_CONFIG_STEP = 2;
