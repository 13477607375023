import React, { useCallback } from 'react';
import { analyticsService } from 'venn-utils';
import { onExportData } from '../../downloadable/helper';
import DataExportButton from '../DataExportButton';
import type { UserData } from '../data-editor/logic';

interface InvestmentDownloadButton {
  disabled?: boolean;
  userData: UserData;
  absolutePosition?: boolean;
  subjectId: string | number;
}

const InvestmentDownloadButton = ({
  userData,
  subjectId,
  disabled,
  absolutePosition = true,
}: InvestmentDownloadButton) => {
  const onExport = useCallback(() => {
    analyticsService.dataExported({
      objectType: 'investment',
      outputDescription: 'returns',
      outputFileType: 'xlsx',
      relativeToBenchmark: false,
      userUploaded: true,
      subjectId: String(subjectId),
    });
    const data = userData.data;
    const isPercentage = userData.dataType === 1;
    if (!data || !data[0]) {
      return;
    }

    const parseData = data.map((item, i) => (i === 0 ? item : [item[0]!, parseFloat(item[1]!)]));
    onExportData(parseData, `${data[0][1]} Return`, isPercentage ? ['B'] : undefined);
  }, [userData, subjectId]);
  return <DataExportButton onExport={onExport} disabled={disabled} absolutePosition={absolutePosition} />;
};

export default InvestmentDownloadButton;
