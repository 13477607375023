import every from 'lodash/every';
import type { DropMenuCheckboxItem } from '../types';

export const getCheckedParents = <T>(items: DropMenuCheckboxItem<T>[]) => {
  const hierarchy = buildHierarchy(items);
  return getCheckedGroups(hierarchy);
};

/**
 * Builds the trigger button content, from a list of checked items
 * @param items Array of checkbox menu items
 */
export function buildLabel<T>(items: DropMenuCheckboxItem<T>[], metricText?: string): string {
  const metricsText = metricText ? `${metricText}s ` : '';
  const singleMetricText = metricText ? `${metricText} ` : '';

  if (every(items, { checked: true })) {
    return `All ${metricsText}Selected`;
  }

  if (every(items, { checked: false })) {
    return 'None';
  }

  const allChecked = items.filter((i) => i.checked);
  const checkedParents = getCheckedParents(items);
  const checkedNumber = allChecked.length - checkedParents.length;

  if (checkedNumber === 1) {
    return `1 ${singleMetricText}Selected`;
  }

  return `${checkedNumber} ${metricsText}Selected`;
}

interface LastParentDictionary<T> {
  [key: number]: CheckboxItemWithChildren<T>;
}

class CheckboxItemWithChildren<T> implements DropMenuCheckboxItem<T> {
  public children: CheckboxItemWithChildren<T>[];

  private _checked: boolean;

  private _indeterminate?: boolean;

  constructor(
    private original: DropMenuCheckboxItem<T>,
    public parent?: CheckboxItemWithChildren<T>,
  ) {
    this.children = [];
    if (original) {
      this._checked = original.checked;
      this._indeterminate = original.indeterminate;
    }
  }

  get label() {
    return this.original.label ?? '';
  }

  get value() {
    return this.original.value;
  }

  get checked() {
    return this._checked;
  }

  get indeterminate() {
    return this._indeterminate;
  }

  get modified() {
    return this.checked === this.original.checked
      ? this.original
      : {
          ...this.original,
          checked: this.checked,
        };
  }

  get childrenCount(): number {
    let count = 0;
    this.children.forEach((child) => {
      count += child.childrenCount + 1;
    });
    return count;
  }

  toggle() {
    this.check(!this.checked);
  }

  allChildrenChecked() {
    let checked = true;
    this.children.forEach((child) => {
      checked = checked && child.checked && child.allChildrenChecked();
    });
    return checked;
  }

  private check(value: boolean) {
    this._checked = value;
    this._indeterminate = false;
    this.children.forEach((child) => child.check(value));
    this.ensureParents();
  }

  private ensureParents() {
    if (this.parent) {
      this.parent._checked = this.parent.allChildrenChecked();
      this.parent.ensureParents();
    }
  }
}

/**
 * Build a hierarchical structured based on checkbox menu items with levels.
 * Uses the levels to figure out the parent/children hierarchy
 * @param items Array of checkbox menu items
 */
export function buildHierarchy<T>(items: DropMenuCheckboxItem<T>[]): CheckboxItemWithChildren<T>[] {
  const root: CheckboxItemWithChildren<T>[] = [];
  const lastParents: LastParentDictionary<T> = {};
  items.forEach((item) => {
    const newItem = new CheckboxItemWithChildren<T>(item);
    if (item.level === 0 || !item.level) {
      root.push(newItem);
      lastParents[0] = newItem;
    } else {
      const parent = lastParents[item.level - 1]!;
      parent.children.push(newItem);
      newItem.parent = parent;
      lastParents[item.level] = newItem;
    }
  });
  return root;
}

function find<T>(value: T, children: CheckboxItemWithChildren<T>[]): CheckboxItemWithChildren<T> | null {
  // eslint-disable-next-line:prefer-for-of
  for (let i = 0; i < children.length; i++) {
    const item = children[i]!;
    if (item.value === value) {
      return item;
    }
    const childFound = find(value, item.children);
    if (childFound) {
      return childFound;
    }
  }
  return null;
}

function collapse<T>(items: CheckboxItemWithChildren<T>[]): DropMenuCheckboxItem<T>[] {
  return items.reduce<DropMenuCheckboxItem<T>[]>(
    (prev, current) => [...prev, current.modified, ...collapse(current.children)],
    [],
  );
}

/**
 * Ensure that all the hierarchy of checkbox items remains consistent.
 * For instance, toggeling a parent will toggle all children, and check parents.
 * @param items Array of checkbox menu items
 * @param modifiedItem Item that needs to be toggled
 */
export function checkHierarchyItem<T>(
  items: DropMenuCheckboxItem<T>[],
  modifiedItem: DropMenuCheckboxItem<T>,
): DropMenuCheckboxItem<T>[] {
  const hierarchy = buildHierarchy(items);
  const item = find(modifiedItem.value, hierarchy);
  if (item) {
    item.toggle();
  }
  return collapse(hierarchy);
}

function getCheckedGroups<T>(items: CheckboxItemWithChildren<T>[]): CheckboxItemWithChildren<T>[] {
  const results: CheckboxItemWithChildren<T>[] = [];
  getCheckedGroupsRecursive(items, results);
  return results;
}

function getCheckedGroupsRecursive<T>(
  items: CheckboxItemWithChildren<T>[],
  results: CheckboxItemWithChildren<T>[],
): void {
  items.forEach((item) => {
    if (item.checked && (!item.parent || !item.parent.checked)) {
      if (item.allChildrenChecked() && item.childrenCount > 0) {
        results.push(item);
      }
    }
    getCheckedGroupsRecursive(item.children, results);
  });
}
