import React, { useContext, useMemo } from 'react';
import { RiskSummaryChart, ReturnSummaryChart, ExposureSummaryChart } from '../../../factor-summary';
import { SortByOption } from '../../../configuration/SortBy';
import EmptyState from '../../../empty-state/EmptyState';
import { generateExportEvent } from 'venn-utils';
import { compareFactorSummaryRows, parseFactorSummaryToSortableData, filterFactorsInsignificantData } from './utils';
import type { FactorChartsProps, FactorsData } from './types';
import FactorSummaryChart from './FactorSummaryChart';
import FailedAnalysisInfo from '../../chart-errors/FailedAnalysisInfo';
import { AnalysisConfigContext, FactorAnalysisContext } from '../../../contexts';

interface FactorSummaryChartsProps extends FactorChartsProps {
  sortBy: SortByOption;
}

const FactorSummaryCharts = ({
  analysisGroup,
  width,
  sortBy,
  analysesPeriod,
  onResetTimeFrame,
  block,
  trackingProps,
}: FactorSummaryChartsProps) => {
  const { activeFactorLens, analysisSubject, categoryActive, relative } = useContext(FactorAnalysisContext);

  const defaultFactors = activeFactorLens?.factors || [];
  const { actualTimeFrame } = useContext(AnalysisConfigContext);
  // In case we are using specific blocks, we need to search all return values to find whether or not the
  // period is annualized
  const periodAnnualized =
    analysisGroup?.return?.factorContributionToReturn?.[0]?.periodAnnualized ||
    analysisGroup?.risk?.factorContributionToRisk?.[0]?.periodAnnualized ||
    analysisGroup?.exposure?.factorExposures?.[0]?.periodAnnualized;
  const isFund = analysisSubject.type === 'investment';
  const showCategoryColumn = categoryActive && isFund && !!analysisSubject.categoryGroup;
  let factorsData: FactorsData[] = useMemo(
    () =>
      parseFactorSummaryToSortableData(analysisGroup, activeFactorLens, !!analysisSubject.activeBenchmarkId, isFund),
    [analysisGroup, activeFactorLens, analysisSubject.activeBenchmarkId, isFund],
  );
  if (!factorsData || factorsData.length === 0) {
    return <EmptyState header="There is something wrong with the chart" />;
  }
  if (sortBy !== SortByOption.SortByDefault) {
    factorsData = factorsData
      .slice(0)
      .sort((left: FactorsData, right: FactorsData) => compareFactorSummaryRows(left, right, sortBy));
  }
  const secondaryLabel = analysisSubject?.secondaryLabel;
  // Get dataset name
  const mainDataSetName = analysisSubject?.name;
  const secondaryDataSetName = analysisSubject.hasSecondarySubject
    ? analysisSubject?.secondaryPortfolio?.name
    : undefined;
  const comparisonDataSetName = relative ? undefined : analysisSubject.activeBenchmarkName;
  const categoryDataSetName = showCategoryColumn ? analysisSubject?.categoryGroup?.name : undefined;
  // Get dataset
  const riskData = filterFactorsInsignificantData(
    mainDataSetName,
    secondaryDataSetName,
    comparisonDataSetName,
    categoryDataSetName,
    factorsData,
    'risk',
    isFund,
    showCategoryColumn,
  );
  const returnData = filterFactorsInsignificantData(
    mainDataSetName,
    secondaryDataSetName,
    comparisonDataSetName,
    categoryDataSetName,
    factorsData,
    'return',
    isFund,
    showCategoryColumn,
  );
  const exposureData = filterFactorsInsignificantData(
    mainDataSetName,
    secondaryDataSetName,
    comparisonDataSetName,
    categoryDataSetName,
    factorsData,
    'exposure',
    isFund,
    showCategoryColumn,
  );
  // riskData will have +1 row because it has residual row,
  // returnData will have +3 rows because it has residual row, risk-free rate row and total row,
  // exposureData will have the exact rows number we want here:
  const filterCount = defaultFactors.length - exposureData.rows.length;
  if (!riskData?.rows.length && !returnData?.rows.length && !exposureData?.rows.length) {
    const { trackingId, updateAnalysisStatusForTracking, blockNames, blockTitles } = trackingProps;
    return (
      <FailedAnalysisInfo
        subject={analysisSubject}
        analysesPeriod={analysesPeriod}
        onResetAnalysisPeriod={onResetTimeFrame}
        error={{
          message:
            analysisGroup?.risk?.message?.text ||
            analysisGroup?.return?.message?.text ||
            analysisGroup?.exposure?.message?.text ||
            'No factors show significant results.',
          code:
            analysisGroup?.risk?.message?.code ||
            analysisGroup?.return?.message?.code ||
            analysisGroup?.exposure?.message?.code,
        }}
        regressionName="Factor Summary"
        relativeToBenchmark={relative}
        categoryActive={categoryActive}
        blockNames={blockNames}
        blockTitles={blockTitles}
        updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
        trackingId={trackingId}
      />
    );
  }

  const sharedProps = {
    width,
    filterCount,
    actualTimeFrame,
    secondaryLabel,
  };

  const investmentType = analysisSubject.type === 'portfolio' ? 'PORTFOLIO' : 'INVESTMENT';
  const userUploaded = !!analysisSubject?.fund?.userUploaded;

  return (
    <>
      {!block || block === 'FACTOR_CONTRIBUTION_TO_EXPOSURE' ? (
        <FactorSummaryChart
          data={exposureData}
          type="exposure"
          dataExportEvent={generateExportEvent(
            investmentType,
            analysisSubject?.id,
            'FACTOR_EXPOSURES',
            userUploaded,
            relative,
          )}
          {...sharedProps}
          FactorSummaryComponent={ExposureSummaryChart}
          trackingProps={trackingProps}
        />
      ) : null}
      {!block || block === 'FACTOR_CONTRIBUTION_TO_RISK' ? (
        <FactorSummaryChart
          data={riskData}
          type="risk"
          usePercentages
          {...sharedProps}
          FactorSummaryComponent={RiskSummaryChart}
          dataExportEvent={generateExportEvent(
            investmentType,
            analysisSubject?.id,
            'FACTOR_CONTRIBUTION_TO_RISK',
            userUploaded,
            relative,
          )}
          trackingProps={trackingProps}
        />
      ) : null}
      {!block || block === 'FACTOR_CONTRIBUTION_TO_RETURN' ? (
        <FactorSummaryChart
          data={returnData}
          type="return"
          dataExportEvent={generateExportEvent(
            investmentType,
            analysisSubject?.id,
            'FACTOR_CONTRIBUTION_TO_RETURN',
            userUploaded,
            relative,
          )}
          usePercentages
          {...sharedProps}
          FactorSummaryComponent={ReturnSummaryChart}
          periodAnnualized={periodAnnualized}
          trackingProps={trackingProps}
        />
      ) : null}
    </>
  );
};

export default FactorSummaryCharts;
