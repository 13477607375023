import { pick } from 'lodash';
import React, { useEffect } from 'react';
import { PAGE_CLASS, PAGE_WRAPPER_CLASS } from '../PageComponent';
import { ObjectPrettyPrinter } from './ObjectPrettyPrinter';

const REFRESH_INTERVAL_MS = 250;

export const CssInfo = () => {
  const styles = useCssInfo();

  return (
    <>
      <h3>CSS Info</h3>
      <p>Shows only a pre-defined subset of computed styles</p>
      <ObjectPrettyPrinter object={styles} />
    </>
  );
};

const useCssInfo = () => {
  const [styles, setStyles] = React.useState<Record<string, unknown>>({});

  useEffect(() => {
    const updateStyles = () => {
      setStyles(getStyles());
    };

    updateStyles();
    const iid = setInterval(updateStyles, REFRESH_INTERVAL_MS);
    return () => clearInterval(iid);
  }, []);

  return styles;
};

const getStyles = () => {
  const someAgGridCell = document.querySelector<HTMLElement>('.ag-cell');
  const pageWrapper = document.getElementsByClassName(PAGE_WRAPPER_CLASS);
  const pages = document.getElementsByClassName(PAGE_CLASS);

  const pageWrapperStyles = Object.fromEntries(
    Array.from(pageWrapper).map((pageWrapper, index) => [
      `page-${index}`,
      {
        page: getComputedStyles(pages[index]!, PAGE_STYLES),
        pageWrapper: getComputedStyles(pageWrapper, PAGE_WRAPPER_STYLES),
      },
    ]),
  );

  const newStyles = {
    'first ag-grid cell': someAgGridCell ? getComputedStyles(someAgGridCell, CELL_STYLES) : 'no ag-grid cells found',
    ...pageWrapperStyles,
  };
  return newStyles;
};

/** Picks a subset of the styles from the provided element's computed styles object. */
export const getComputedStyles = (el: Element, stylesToPick: (keyof CSSStyleDeclaration)[]) => {
  return pick(window.getComputedStyle(el), stylesToPick);
};

const PAGE_STYLES = [
  'height',
  'position',
  'transform',
  'overflow',
  'contain',
  'pageBreakBefore',
  'pageBreakAfter',
  'pageBreakInside',
] satisfies (keyof CSSStyleDeclaration)[];
const PAGE_WRAPPER_STYLES = PAGE_STYLES;
const CELL_STYLES = ['font'] satisfies (keyof CSSStyleDeclaration)[];
