import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import BaseCorrelationChart from '../../../correlation-chart/BaseCorrelationChart';
import type { HeatMapTypes, XAxisLabel } from '../../../heat-map';
import { HeatMapUtils } from '../../../heat-map';
import type { AnalysisLabelType, AnalysisSubject, TimeFrame } from 'venn-utils';
import { logMessageToSentry } from 'venn-utils';
import DownloadableContentBlock from '../../../content-block/DownloadableContentBlock';
import ResidualCorrelationToggle from './ResidualToggle';
import FundCorrelationDateRangeMessage from './FundCorrelationDateRangeMessage';
import CorrelationRowTooltip from '../../../factor-chart/tooltips/CorrelationRowTooltip';
import type { TrackAnalysisProps } from '../../chart-errors/TrackAnalysis';
import { TrackFailedAnalysis, TrackSuccessfulAnalysis } from '../../chart-errors/TrackAnalysis';
import type { FailedAnalysisError } from '../../chart-errors/types';
import CorrelationError from './CorrelationError';
import type { DownloadMetaData } from '../../../downloadable';
import ShowCorrelationValuesToggle from './ShowCorrelationValuesToggle';

const { convertCorrelationsDataToExcel } = HeatMapUtils;

interface FundsCorrelationsChartWrapperProps {
  title: string;
  subtitle?: string;
  data: HeatMapTypes.Root[] | null;
  width: number;
  loading: boolean;
  seriesDataSize: number;
  nodeNames: XAxisLabel[] | null;
  relative: boolean;
  residualized: boolean;
  showValues: boolean;
  userUploaded: boolean;
  analysisTimeFrame?: TimeFrame;
  onResidualToggle: () => void;
  toggleShowValues: () => void;
  isPortfolio?: boolean;
  investmentsCount: number;
  trailingLabels: AnalysisLabelType[];
  tracking: TrackAnalysisProps;
  error?: FailedAnalysisError;
  subject?: AnalysisSubject;
  isMissingMaster: boolean;
  actualTimeFrame: TimeFrame;
  categoryActive: boolean;
  downloadMetaData?: DownloadMetaData;
  onResetTimeFrame?: () => void;
}

const StyledWrapper = styled.div`
  @media print {
    margin-bottom: 50px;
  }
  position: relative;
`;

const FundsCorrelationsChartWrapper = (props: FundsCorrelationsChartWrapperProps) => {
  const {
    title,
    subtitle,
    width,
    data,
    loading,
    nodeNames,
    seriesDataSize,
    relative,
    residualized,
    userUploaded,
    analysisTimeFrame,
    onResidualToggle,
    isPortfolio,
    investmentsCount,
    trailingLabels,
    tracking,
    error,
    subject,
    isMissingMaster,
    actualTimeFrame,
    categoryActive,
    downloadMetaData,
    onResetTimeFrame,
    showValues,
    toggleShowValues,
  } = props;

  const portfolioName = get(data, [0, 'series', 0, 'name']);
  const excelData = useMemo(
    () => (data && nodeNames ? convertCorrelationsDataToExcel(data, nodeNames) : undefined),
    [data, nodeNames],
  );

  const tooltipRenderer = useCallback(
    (columnId: number, rowId: number) => {
      if (!data || !nodeNames) {
        logMessageToSentry('Failed to render fund correlations tooltip because of missing data');
        return null;
      }

      return (
        <CorrelationRowTooltip
          investment={nodeNames[columnId]!.name}
          details={data[rowId]!.series.map((serie) => ({
            portfolioName: serie.portfolioType,
            value: serie.data[columnId]!.value,
          }))}
        />
      );
    },
    [data, nodeNames],
  );

  return (
    <StyledWrapper>
      <DownloadableContentBlock
        header={title}
        subHeader={subtitle}
        downloadable={{
          disabled: loading || !data,
          png: true,
          excel: excelData,
          tracking: {
            description: 'FUND_RESIDUAL_CORRELATION',
            relativeToBenchmark: relative,
            subjectType: 'investment',
            subjectId: subject?.id,
            userUploaded,
          },
          options: {
            fileName: `${title} - ${portfolioName}`,
            metaData: downloadMetaData,
          },
        }}
        rightOptions={
          <>
            <ResidualCorrelationToggle
              disabled={relative}
              toggled={residualized && !relative}
              onToggle={onResidualToggle}
            />
            <ShowCorrelationValuesToggle
              hidden={!!error && !loading}
              showValues={showValues}
              onToggle={toggleShowValues}
            />
          </>
        }
      >
        {error && !loading ? (
          <TrackFailedAnalysis {...tracking}>
            <CorrelationError
              error={error}
              subject={subject}
              isMissingMaster={isMissingMaster}
              actualTimeFrame={actualTimeFrame}
              categoryActive={categoryActive}
              trackingProps={tracking}
              onResetTimeFrame={onResetTimeFrame}
            />
          </TrackFailedAnalysis>
        ) : (
          <TrackSuccessfulAnalysis {...tracking}>
            <BaseCorrelationChart
              showValues={showValues}
              loading={loading}
              width={width - 2}
              data={data}
              seriesDataSize={seriesDataSize}
              nodeNames={nodeNames}
              labelFormatter={(id, type) =>
                trailingLabels.includes(id!) || trailingLabels.includes(type!) ? { fontStyle: 'italic' } : {}
              }
              tooltipRenderer={tooltipRenderer}
            />
            <FundCorrelationDateRangeMessage
              analysisTimeFrame={analysisTimeFrame}
              isPortfolio={isPortfolio}
              totalInvestmentsCount={investmentsCount}
            />
          </TrackSuccessfulAnalysis>
        )}
      </DownloadableContentBlock>
    </StyledWrapper>
  );
};

export default FundsCorrelationsChartWrapper;
