import React from 'react';

import { ItemType } from 'venn-ui-kit';

import { Block } from '../../core';
import { Name } from '../legend';
import { getRequestSubjectFromAnalysisSubject, type AnalysisSubject, isHistoricalSubject } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { isReportState } from 'venn-state';
import { HistoricalPortfolioStudioHeaderTooltips } from '../historical-portfolio/HistoricalPortfolioStudioHeaderTooltips';

interface CommonBenchmarkProps {
  id: string;
  benchmarkSubject: AnalysisSubject;
}

export const CommonBenchmark = ({ id, benchmarkSubject }: CommonBenchmarkProps) => {
  const requestSubject = getRequestSubjectFromAnalysisSubject(benchmarkSubject);
  const isStudio = !useRecoilValue(isReportState);
  return (
    <Block.SubjectsItem iconType={ItemType.CommonBenchmark}>
      <Name id={id} subject={requestSubject} />
      {isHistoricalSubject(requestSubject) && isStudio && (
        <HistoricalPortfolioStudioHeaderTooltips blockId={id} subject={requestSubject} />
      )}
    </Block.SubjectsItem>
  );
};
