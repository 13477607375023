import type { ViewConfiguration } from './useAddeparViewConfiguration';
import { useCallback, useState } from 'react';
import type { AddeparPortfolioPreview } from 'venn-api';
import { getAddeparDataPreview } from 'venn-api';
import { getValue } from './useAddeparViewConfiguration';
import type { ReadonlyPortfolio } from 'venn-components';
import { logExceptionIntoSentry, useQuery } from 'venn-utils';
import { Notifications, NotificationType } from 'venn-ui-kit';

const ADDEPAR_PREVIEW_CACHE_KEY = 'ADDEPAR_PREVIEW';
const ONE_HOUR = 1000 * 60 * 60;

const MAX_INVESTMENTS_DATA_LIBRARY = 1;
const MAX_PER_LEVEL_PORTFOLIO_PREVIEW = 3; // non-investments
const MAX_INVESTMENTS_PORTFOLIO_PREVIEW = 2;

interface UseAddeparViewPreview {
  firmId: string;
  views: ViewConfiguration[];
}

interface ViewPreview {
  portfolioNames: string[];
  samplePortfolio: ReadonlyPortfolio;
}

const toTruncatedReadonlyPortfolio = (
  id: string,
  preview: AddeparPortfolioPreview,
  collapsed = false,
): ReadonlyPortfolio => {
  const areChildrenInvestments = preview.children?.[0]?.children == null;
  const truncatedChildren = preview.children
    ?.filter((child) => child.value > 0)
    .slice(0, areChildrenInvestments ? MAX_INVESTMENTS_PORTFOLIO_PREVIEW : MAX_PER_LEVEL_PORTFOLIO_PREVIEW);
  return {
    id,
    name: preview.name,
    allocation: preview.value,
    children:
      truncatedChildren?.map((child, index) =>
        toTruncatedReadonlyPortfolio(child.name, child, collapsed || index < truncatedChildren.length - 1),
      ) ?? [],
    collapsed,
  };
};

const useAddeparViewPreview = ({ firmId, views }: UseAddeparViewPreview) => {
  const [selectedViewIndex, setSelectedViewIndex] = useState(0);

  const onUpdateViewToPreview = useCallback(
    (viewIndex: number) => {
      if (viewIndex < 0 || viewIndex >= views.length) {
        return;
      }
      setSelectedViewIndex(viewIndex);
    },
    [views],
  );

  const selectedView = views[selectedViewIndex]!;
  const { data: viewPreview, isLoading } = useQuery<ViewPreview>(
    [ADDEPAR_PREVIEW_CACHE_KEY, selectedView.portfolioPrefix, selectedView.groupings, selectedView.includedAttributes],
    async () => {
      const { content } = await getAddeparDataPreview(firmId, {
        firmId,
        groupingIds: selectedView.groupings.filter((g) => !!g), // remove empty string if any
        rootEntityIds: selectedView.includedAttributes.filter((a) => a.selected).map(getValue),
        viewPrefix: selectedView.portfolioPrefix,
      });
      return {
        portfolioNames: content.portfolioNames.slice(0, MAX_INVESTMENTS_DATA_LIBRARY),
        samplePortfolio: toTruncatedReadonlyPortfolio(selectedView.configUuid, content.portfolioPreview),
      };
    },
    {
      onError: (error) => {
        Notifications.notify(
          'Unable to load preview. You can click Continue without previewing the data or try again later.',
          NotificationType.ERROR,
        );
        return logExceptionIntoSentry(error as Error);
      },
      staleTime: ONE_HOUR,
    },
  );

  return {
    selectedViewIndex,
    isLoading,
    viewPreview,
    onUpdateViewToPreview,
  };
};

export default useAddeparViewPreview;
