import React, { useContext, useMemo } from 'react';
import type { HeatMapTypes } from './Types';
import Row from './Map/Row';
import type { MouseEventType } from './Map/Cell';
import { ThemeContext } from 'styled-components';
import { isNil } from 'lodash';

interface MapRendererProps {
  series: HeatMapTypes.Root[];
  cellHeight: number;
  cellWidth: number;
  onCellMouseEnter: MouseEventType;
  onCellClick?: MouseEventType;
  leftOffset?: number;
  rightOffset?: number;
  isCellClickable?: boolean;
  print?: boolean;
  showValues?: boolean;
  fontSize?: string;
}

const CrossHatchPattern = ({
  id,
  size,
  color,
  backgroundColor,
  strokeWidth,
}: {
  id: string;
  size: number;
  color: string;
  backgroundColor: string;
  strokeWidth: number;
}) => (
  <pattern id={id} patternUnits="userSpaceOnUse" width={size} height={size}>
    <rect width="3" height="3" fill={backgroundColor} />
    <path
      d="M 0,3 l 3,-3 M -0.75,0.75 l 1.5,-1.5 M 2.25,3.75 l 1.5,-1.5"
      strokeWidth={strokeWidth}
      shapeRendering="auto"
      stroke={color}
      strokeLinecap="square"
    />
  </pattern>
);

const MapRenderer = ({
  series,
  cellHeight,
  cellWidth,
  onCellMouseEnter,
  onCellClick,
  leftOffset,
  rightOffset,
  isCellClickable,
  print,
  showValues,
  fontSize,
}: MapRendererProps) => {
  const { Colors } = useContext(ThemeContext);

  const onMouseOver = useMemo(
    () => (mouseEvent: React.MouseEvent<SVGElement, MouseEvent>) => {
      if (mouseEvent.target instanceof Element) {
        if (isNil(mouseEvent.target.getAttribute('data-x')) || isNil(mouseEvent.target.getAttribute('data-y'))) {
          return;
        }
        onCellMouseEnter({
          columnId: Number(mouseEvent.target.getAttribute('data-x')),
          rowId: Number(mouseEvent.target.getAttribute('data-y')),
        });
      }
    },
    [onCellMouseEnter],
  );

  return (
    <g>
      <defs>
        <CrossHatchPattern
          id="trend-diagonal-hatch"
          size={3}
          strokeWidth={1}
          color={Colors.HintGrey}
          backgroundColor={Colors.White}
        />
      </defs>

      <g fill={print ? Colors.HintGrey : 'url(#trend-diagonal-hatch)'} onMouseOver={onMouseOver}>
        {series.map((serie, i) => (
          <Row
            key={serie.name}
            leftOffset={leftOffset}
            rightOffset={rightOffset}
            rowData={serie?.series?.[0]?.data ?? []}
            cellWidth={cellWidth}
            cellHeight={cellHeight}
            rowId={i}
            onCellClick={onCellClick}
            isCellClickable={isCellClickable}
            print={print}
            showValue={showValues}
            fontSize={fontSize}
          />
        ))}
      </g>
    </g>
  );
};

export default React.memo(MapRenderer);
