import { isNil } from 'lodash';
import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import type { PrintSettings, LogoDetails } from 'venn-api';
import {
  storeOrganizationLogo,
  getOrganizationPrintSettings,
  storeOrganizationPrintSettings,
  deletePrintLogoById,
  getPrintLogoIds,
  setPrintLogoAsDefaultById,
} from 'venn-api';
import { STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE, StudioPrintSettingsContext, UserContext } from 'venn-components';
import {
  logExceptionIntoSentry,
  STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE,
  STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
  STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE,
} from 'venn-utils';

const isWhitespaceOrEmpty = (str: string) => {
  return !/\S/.test(str);
};

function hasFontSizeChanged(
  currentFontSize: number | undefined | null,
  originalFontSize: number | undefined | null,
  defaultFontSize: number,
): boolean {
  return (currentFontSize ?? defaultFontSize) !== (originalFontSize ?? defaultFontSize);
}

const StudioPrintSettingsStore: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { profileSettings } = useContext(UserContext);
  const [organizationPrintSettings, setOrganizationPrintSettings] = useState<PrintSettings>({} as PrintSettings);
  const [originalPrintSettings, setOriginalPrintSettings] = useState<PrintSettings>({} as PrintSettings);
  const [loading, setLoading] = useState<boolean>(false);
  const [allLogos, setAllLogos] = useState<LogoDetails[]>([]);
  const [defaultLogoId, setDefaultLogoId] = useState<string | undefined>(undefined);
  const hasDefault = defaultLogoId !== undefined;

  /*
   * The reason we have a check for empty disclosures is to ensure we only add a page if the user's custom
   * disclosure is set to something other than an empty string.  Also, if we update our default disclosures
   * to be blank we also don't want to add a disclosure page.
   *
   * Custom disclosure is only considered if it is not nil, otherwise there are no custom disclosures
   * but there might potentially be Venn-provided default disclosures.
   *
   * Adding a disclosure page by default only applies to report lab and only if disclosures are not empty.
   */
  const organizationDefaultDisclosure = STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE(
    organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name,
  );
  const disclosureToUse = isNil(organizationPrintSettings?.disclosure)
    ? organizationDefaultDisclosure
    : organizationPrintSettings.disclosure;
  const addDisclosurePage = !isWhitespaceOrEmpty(disclosureToUse);

  const getAllLogos = useCallback(async () => {
    try {
      const logos = (await getPrintLogoIds()).content;
      setAllLogos(logos);
      const defaultLogo = logos.filter((x) => x.default)[0];
      setDefaultLogoId(defaultLogo?.id);
    } catch (e) {
      logExceptionIntoSentry(e);
    }
  }, []);

  const loadSettings = useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([
        getAllLogos(),
        getOrganizationPrintSettings().then(({ content }) => {
          const settings = {
            ...content,
            organizationName: !content.organizationName?.length ? undefined : content.organizationName,
          };
          setOrganizationPrintSettings(settings);
          setOriginalPrintSettings(settings);
        }),
      ]);
    } catch (e) {
      logExceptionIntoSentry(e);
    } finally {
      setLoading(false);
    }
  }, [getAllLogos]);

  const storeSettings = useCallback(
    async (settings: PrintSettings) => {
      setLoading(true);

      try {
        await storeOrganizationPrintSettings(settings);
        await loadSettings();
      } catch (e) {
        logExceptionIntoSentry(e);
        setLoading(false);
        return;
      }

      setLoading(false);
    },
    [loadSettings],
  );

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const setOrganizationName = useCallback((organizationName: string) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      organizationName,
    }));
  }, []);
  const setDisclaimer = useCallback((disclaimer?: string) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      disclaimer,
    }));
  }, []);
  const setCoverPageDisclosure = useCallback((coverPageDisclosure?: string) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      coverPageDisclosure,
    }));
  }, []);
  const setDisclosureFontSize = useCallback((disclosureFontSize?: number) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      legalFontOverrides: {
        ...organizationPrintSettings.legalFontOverrides,
        disclosureFontSize,
      },
      disclosureFontSize,
    }));
  }, []);
  const setDisclaimerFontSize = useCallback((disclaimerFontSize?: number) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      legalFontOverrides: {
        ...organizationPrintSettings.legalFontOverrides,
        disclaimerFontSize,
      },
      disclaimerFontSize,
    }));
  }, []);
  const setCoverPageDisclosureFontSize = useCallback((coverPageDisclosureFontSize?: number) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      legalFontOverrides: {
        ...organizationPrintSettings.legalFontOverrides,
        coverPageDisclosureFontSize,
      },
      coverPageDisclosureFontSize,
    }));
  }, []);
  const setDisclosure = useCallback((disclosure?: string) => {
    setOrganizationPrintSettings((organizationPrintSettings) => ({
      ...organizationPrintSettings,
      disclosure,
    }));
  }, []);
  const needsSaving =
    organizationPrintSettings.organizationName !== originalPrintSettings.organizationName ||
    organizationPrintSettings.coverPageDisclosure !== originalPrintSettings.coverPageDisclosure ||
    organizationPrintSettings.disclosure !== originalPrintSettings.disclosure ||
    organizationPrintSettings.disclaimer !== originalPrintSettings.disclaimer ||
    hasFontSizeChanged(
      organizationPrintSettings.coverPageDisclosureFontSize,
      originalPrintSettings.coverPageDisclosureFontSize,
      STUDIO_COVER_PAGE_DISCLOSURE_DEFAULT_FONT_SIZE,
    ) ||
    hasFontSizeChanged(
      organizationPrintSettings.disclosureFontSize,
      originalPrintSettings.disclosureFontSize,
      STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
    ) ||
    hasFontSizeChanged(
      organizationPrintSettings.disclaimerFontSize,
      originalPrintSettings.disclaimerFontSize,
      STUDIO_DEFAULT_DISCLAIMER_FONT_SIZE,
    );

  const uploadOrganizationLogo = useCallback(
    async (input: HTMLInputElement | null, isDefault: boolean) => {
      if (!input || !input.files) {
        return;
      }

      setLoading(true);

      try {
        const image = input.files[0]!;
        await storeOrganizationLogo(image, isDefault);
        await getAllLogos();
      } catch (e) {
        logExceptionIntoSentry(e);
        setLoading(false);
        return;
      }

      setLoading(false);
    },
    [getAllLogos],
  );
  const deleteLogo = useCallback(
    async (logoId?: string) => {
      if (!logoId) {
        return;
      }

      await deletePrintLogoById(logoId);
      await getAllLogos();
    },
    [getAllLogos],
  );

  const setDefaultLogo = useCallback(
    async (logoId?: string) => {
      if (!logoId) {
        return;
      }

      await setPrintLogoAsDefaultById(logoId);
      await getAllLogos();
    },
    [getAllLogos],
  );

  // All consumers will rerender if the actual object changes reference
  const contextValue = useMemo(
    () => ({
      hasDefault,
      addDisclosurePage,
      defaultLogoId,
      allLogos,
      needsSaving,
      organizationPrintSettings,
      setOrganizationName,
      setDisclaimer,
      setCoverPageDisclosure,
      setDisclosure,
      setDisclaimerFontSize,
      setCoverPageDisclosureFontSize,
      setDisclosureFontSize,
      storeSettings,
      uploadOrganizationLogo,
      loading,
      deleteLogo,
      setDefaultLogo,
      getAllLogos,
      loadSettings,
    }),
    [
      addDisclosurePage,
      allLogos,
      defaultLogoId,
      deleteLogo,
      getAllLogos,
      hasDefault,
      loadSettings,
      loading,
      needsSaving,
      organizationPrintSettings,
      setCoverPageDisclosure,
      setDefaultLogo,
      setDisclaimer,
      setDisclosure,
      setDisclaimerFontSize,
      setCoverPageDisclosureFontSize,
      setDisclosureFontSize,
      setOrganizationName,
      storeSettings,
      uploadOrganizationLogo,
    ],
  );
  return <StudioPrintSettingsContext.Provider value={contextValue}>{children}</StudioPrintSettingsContext.Provider>;
};

export default StudioPrintSettingsStore;
