import type { FundSeriesData } from 'venn-api';
import moment from 'moment';
import compact from 'lodash/compact';
import { convertSeriesDataToExcel } from 'venn-utils';

export type DataType = 'RETURN' | 'PRICE';

export interface UserData {
  dataType: number;
  data: string[][];
}

export const convertFundSeriesToUserData = (fundName: string, series: FundSeriesData): UserData => ({
  dataType: series.type,
  data: convertSeriesDataToExcel(fundName, series)!,
});

export const convertType = (type: number) => (type === 1 ? 'RETURN' : 'PRICE');

const avoidOverflow = (value: number): number => parseFloat(value.toFixed(12));
// Fix js floating calculation
const convertToPercentage = (value: string): string => avoidOverflow(parseFloat(value) * 100).toString();

export const checkHasDiff = (a: string, b: string): boolean => parseFloat(a) - parseFloat(b) !== 0;

export const convertValues = (userData?: UserData): string[][] => {
  if (!userData) {
    return [];
  }
  const { data, dataType } = userData;
  return data.map((row, index) => {
    if (index === 0) {
      return row;
    }
    const date = row[0]!;
    const value = convertType(dataType) === 'RETURN' ? convertToPercentage(row[1]!) : row[1]!;

    return [date, value];
  });
};

export const convertStringToTimeseries = (data: string[][], dataType?: DataType): number[][] =>
  compact(
    data.map((row) => {
      const date = parseDate(row[0]!);
      const value = parseFloat(row[1]!);
      if (Number.isNaN(date) || Number.isNaN(value)) {
        return null;
      }
      // If it's return, we should convert from percentage to real number
      return [date, dataType === 'RETURN' ? avoidOverflow(value / 100) : value];
    }),
  );

export const checkHasError = (data: string[][]) => {
  for (let i = 0; i < data.length; i++) {
    const row = data[i]!;
    const date = parseDate(row[0]!);
    const value = parseFloat(row[1]!);
    if ((Number.isNaN(date) || Number.isNaN(value)) && (row[0]! !== '' || row[1]! !== '')) {
      return true;
    }
  }

  return false;
};

export const parseDate = (date: string): number => {
  const supportDate = [
    'MM/DD/YYYY',
    'MM/DD/YY',
    'MM-DD-YYYY',
    'MM-DD-YY',
    'M/DD/YYYY',
    'M/DD/YY',
    'M/D/YYYY',
    'M/D/YY',
    'DD/M/YYYY',
    'DD/M/YY',
    'D/M/YY',
    'DD/MM/YY',
    'MMM-YYYY',
    'MMM/YYYY',
    'MMM-YY',
    'MMM/YY',
    'MM/YY',
    'MM-YY',
  ];
  return moment.utc(date, supportDate).valueOf();
};
