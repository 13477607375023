import React from 'react';
import type { InfoGraphicTypeEnum } from 'venn-api';
import { FIGURE_TEXT } from 'venn-utils';
import type { CustomizableMetric } from 'venn-utils';
import { StyledErrorState } from './StyledErrorState';

interface UnsupportedChartProps {
  infoGraphicType?: InfoGraphicTypeEnum;
  metrics?: CustomizableMetric[];
  selectedRefId: string;
}

export const UnsupportedChartFormat = ({ selectedRefId, infoGraphicType, metrics }: UnsupportedChartProps) => {
  const message =
    !infoGraphicType || !metrics || metrics.length !== 1
      ? 'Please change chart format to the supported one.'
      : `${FIGURE_TEXT[infoGraphicType]!} format is not supported for ${metrics[0]!.label} metric.`;

  return (
    <div data-testid="qa-unsupported-chart">
      <StyledErrorState selectedRefId={selectedRefId} header="Unsupported chart format." message={message} />
    </div>
  );
};
