import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Header } from '../components/page-parts';
import { UploaderFaqLinkShared } from '../components/FaqLink';
import { DataUploaderMode } from '../types';
import { ModalFooter } from '../../../modal';
import { uploadConfig } from '../utils';
import { Button, Flexbox, GetColor, NewBadge } from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';

interface UploadProps {
  onCancel: () => void;
  onSelectMode: (arg0: DataUploaderMode) => void;
}

const SelectDataType = ({ onCancel, onSelectMode }: UploadProps) => {
  const mode = DataUploaderMode.Initial;
  const navigateToReturns = useCallback(() => {
    analyticsService.ctaClicked({
      destination: 'Returns uploader (V2)',
      purpose: 'Upload returns',
    });
    onSelectMode(DataUploaderMode.Returns);
  }, [onSelectMode]);

  const navigateToPrivates = useCallback(() => {
    analyticsService.ctaClicked({
      destination: 'Privates uploader',
      purpose: 'Upload private asset data',
    });
    onSelectMode(DataUploaderMode.Privates);
  }, [onSelectMode]);
  return (
    <>
      <Header>
        <h1>{uploadConfig[mode]!.uploadTitle}</h1>
      </Header>

      <Flexbox direction="column" grow={1} alignItems="center" justifyContent="center">
        <Flexbox direction="column" rowGap={16}>
          <Flexbox direction="row" columnGap={32}>
            <Flexbox direction="column" rowGap={16} css={[widthCss]}>
              <Title>Investment Return Data</Title>
              <Paragraph>Time series of returns used for returns-based analysis</Paragraph>
            </Flexbox>
            <Flexbox direction="column" rowGap={16} css={[widthCss]}>
              <Flexbox direction="row" alignItems="center" columnGap={10}>
                <NewBadge noMargin />
                <Title>Private Asset Data</Title>
              </Flexbox>
              <Paragraph>Cash flows and other data specifically associated with private assets</Paragraph>
            </Flexbox>
          </Flexbox>
          <Flexbox direction="row" columnGap={32}>
            <UploadButton dominant noMargin onClick={navigateToReturns} data-testid="qa-navigate-returns-upload">
              Upload
            </UploadButton>
            <UploadButton dominant noMargin onClick={navigateToPrivates} data-testid="qa-navigate-privates-upload">
              Upload
            </UploadButton>
          </Flexbox>
          <Flexbox direction="row" columnGap={160}>
            <span>
              <HelpText>Questions? Visit the {UploaderFaqLinkShared(DataUploaderMode.Returns)}.</HelpText>
            </span>
            <span>
              <HelpText>Questions? Visit the {UploaderFaqLinkShared(DataUploaderMode.Privates)}.</HelpText>
            </span>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      <StyledModalFooter onCancel={onCancel} />
    </>
  );
};

export default SelectDataType;

const widthCss = css`
  width: 300px;
  min-width: 300px;
`;

const UploadButton = styled(Button)`
  ${widthCss};
  font-size: 11px;
  text-transform: uppercase;
`;

const HelpText = styled.div`
  color: ${GetColor.DarkGrey};
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.15;
  margin: 0;
`;

const StyledModalFooter = styled(ModalFooter)`
  border-radius: 4px;
`;
