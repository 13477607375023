import { useState, useEffect, useRef, useMemo, useLayoutEffect, useContext } from 'react';
import type {
  RangeDebugResponse,
  AnalysisComparisonTypeEnum,
  OperationResult,
  AnalysisResponse,
  AnalysisRequest,
  FrequencyEnum,
  Message,
  Portfolio,
} from 'venn-api';
import { analysis } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { useApi, getAnalysisRequest } from 'venn-utils';
import PortfoliosContext from '../contexts/portfolios-context';

export interface RangeDebugResult {
  response: RangeDebugResponse | null;
  subjectError: Message | null;
}

export interface RangeDebugGroup {
  primary: RangeDebugResult;
  secondary?: RangeDebugResult;
  benchmark?: RangeDebugResult;
  master?: RangeDebugResult;
  maxStartTime: number;
  maxEndTime: number;
  maxFrequency: FrequencyEnum;
}

export default (
  subject: AnalysisSubject | undefined,
  categoryActive: boolean,
  configTrackingId: number,
  includeMasterAsComparisonForInvestment?: boolean,
) => {
  const { masterPortfolio } = useContext(PortfoliosContext);
  const includeMaster = includeMasterAsComparisonForInvestment && subject?.type === 'investment';

  const [loading, setLoading] = useState<boolean>(false);
  const [rangeDebugGroup, setRangeDebugGroup] = useState<RangeDebugGroup | undefined>(undefined);
  const [masterRangeDebug, setMasterRangeDebug] = useState<RangeDebugResponse | undefined>(undefined);

  const rangeDebugApiRef = useRef(useApi(analysis));

  const isMount = useRef<boolean>(false);
  useLayoutEffect(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
    };
  }, []);

  const trackingIdRef = useRef<number>(-1);

  const configs = useMemo(
    () =>
      getAnalysisRequest(
        ['RANGE_DEBUG'],
        subject,
        {},
        false,
        categoryActive,
        undefined,
        configTrackingId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        includeMaster ? masterPortfolio : undefined,
      ),
    [categoryActive, subject, includeMaster, masterPortfolio, configTrackingId],
  );

  useEffect(() => {
    const refresh = async () => {
      setLoading(true);
      try {
        const debugResult = await rangeDebugApiRef.current(configs);
        if (!isMount.current) {
          return;
        }
        setRangeDebugGroup(parseRangeDebugResults(configs, debugResult, subject, includeMaster));
        setMasterRangeDebug(parseMasterRangeDebug(configs, debugResult, includeMaster, masterPortfolio));
        setLoading(false);
      } catch (e) {
        if (e.name !== 'AbortError' && isMount.current) {
          setRangeDebugGroup(undefined);
          setMasterRangeDebug(undefined);
          setLoading(false);
        }
      }
    };
    if (configTrackingId !== trackingIdRef.current) {
      refresh();
    }
  }, [configTrackingId, subject, configs, includeMaster, masterPortfolio]);

  useEffect(() => {
    if (configTrackingId !== trackingIdRef.current) {
      trackingIdRef.current = configTrackingId;
    }
  }, [configTrackingId]);

  return { loading, rangeDebugGroup, masterRangeDebug };
};

function parseMasterRangeDebug(
  configs: Partial<AnalysisRequest> | undefined,
  response: OperationResult<AnalysisResponse>,
  includeMaster?: boolean,
  masterPortfolio?: Portfolio,
): RangeDebugResponse | undefined {
  if (!includeMaster) {
    return undefined;
  }

  const masterSubjectIdx = configs?.subjects?.findIndex((subject) => subject.comparisonType === 'COMPARISON') ?? -1;
  if (masterSubjectIdx === -1) {
    return undefined;
  }
  const masterRange = response.content.analyses[0]?.rangeDebug[masterSubjectIdx] ?? {};

  return {
    ...masterRange,
    insufficientRange: !!response.content.subjectErrors?.[masterSubjectIdx] || masterPortfolio?.allocation === 0,
  } as RangeDebugResponse;
}

function parseRangeDebugResults(
  configs: Partial<AnalysisRequest> | undefined,
  response: OperationResult<AnalysisResponse>,
  subject?: AnalysisSubject,
  includeMaster?: boolean,
): RangeDebugGroup | undefined {
  const primary = getRangeDebugForSubject('PRIMARY', configs, response);
  if (!primary) {
    return undefined;
  }

  return {
    primary,
    secondary: subject
      ? getRangeDebugForSubject(subject.type === 'investment' ? 'CATEGORY' : 'COMPARISON', configs, response)
      : undefined,
    master: includeMaster ? getRangeDebugForSubject('COMPARISON', configs, response) : undefined,
    benchmark: getRangeDebugForSubject('BENCHMARK', configs, response),
    maxStartTime: response.content.maxStartTime,
    maxEndTime: response.content.maxEndTime,
    maxFrequency: response.content.maxFrequency,
  };
}

function getRangeDebugForSubject(
  subjectComparisonType: AnalysisComparisonTypeEnum,
  configs: Partial<AnalysisRequest> | undefined,
  response: OperationResult<AnalysisResponse>,
): RangeDebugResult | undefined {
  const subjectIdx = configs?.subjects?.findIndex((sub) => sub.comparisonType === subjectComparisonType) ?? -1;
  const rangeDebug = response.content.analyses[0]?.rangeDebug;
  const errors = response.content.subjectErrors;
  const subjectError = errors?.length === configs?.subjects?.length ? errors?.[subjectIdx] : null;
  return {
    response: rangeDebug && rangeDebug.length === configs?.subjects?.length ? rangeDebug[subjectIdx]! : null,
    subjectError: subjectError ?? null,
  };
}
