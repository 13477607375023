import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import type { ExcelCell, ExcelWorkbook } from 'venn-utils';
import { getBaseFee, getCurrentTimeStamp } from 'venn-utils';
import {
  allSubjectsWithExcludedInvestmentFees,
  exportingToExcel,
  hasUnsavedPortfolioChangesInAllocator,
  hasUnsavedChangesInPrivatesAllocator,
  viewExcelFunctions,
  analysisViewNameState,
} from 'venn-state';
import type { ExcelSheetData } from 'venn-components';
import { onExportMultiSheets } from 'venn-components';
import { getAppTitle, Notifications, NotificationType } from 'venn-ui-kit';
import { compact } from 'lodash';
import saveAs from 'file-saver';

export const generateName = (name: string | undefined, hasUnsavedPortfolioChanges: boolean) =>
  name ? `${name}${hasUnsavedPortfolioChanges ? ' Unsaved Portfolio' : ''}.xlsx` : 'Unsaved View.xlsx';

export const useStudioExcelExport = (
  logExport: (props: { exportTo: 'Excel'; isSuccessful: boolean; blockCount: number }) => void,
) => {
  const [isExporting, setIsExporting] = useRecoilState(exportingToExcel);
  const [currentToastId, setCurrentToastId] = useState<React.ReactText>();
  const blockExcelFunctions = useRecoilValue(viewExcelFunctions);
  const resetBlockExcelFunctions = useResetRecoilState(viewExcelFunctions);

  const onExcelExportDownload = useRecoilCallback(
    ({ snapshot }) =>
      async (excelFunctions: { excelFunction: (() => ExcelCell[][]) | undefined }[]) => {
        const hasUnsavedReturnsPortfolioChanges = await snapshot.getPromise(hasUnsavedPortfolioChangesInAllocator);
        const hasUnsavedPrivatesPortfolioChanges = await snapshot.getPromise(hasUnsavedChangesInPrivatesAllocator);
        const hasUnsavedPortfolioChanges = hasUnsavedReturnsPortfolioChanges || hasUnsavedPrivatesPortfolioChanges;
        const allSubjectsWithExclusions = await snapshot.getPromise(allSubjectsWithExcludedInvestmentFees);
        const currentName = await snapshot.getPromise(analysisViewNameState);
        const name = generateName(currentName, hasUnsavedPortfolioChanges);
        const sheetsData: ExcelSheetData[] = [
          {
            sheetName: 'metadata',
            data: [
              [
                {
                  value: 'Export at',
                  bold: true,
                },
                { value: getCurrentTimeStamp() },
                { value: '' },
              ],
              [
                {
                  value: 'Name',
                  bold: true,
                },
                { value: name },
              ],
              ...(allSubjectsWithExclusions.length === 0
                ? []
                : [
                    [],
                    [
                      {
                        value: '* Certain investments are excluded from the stated advisory fees in this spreadsheet.',
                        style: {
                          wrapText: true,
                        },
                      },
                    ],
                    [
                      {
                        value:
                          "The following portfolios' investments have been excluded from the stated advisory fees:",
                        style: {
                          wrapText: true,
                        },
                      },
                    ],
                    [
                      {
                        value: 'Portfolio',
                        bold: true,
                      },
                      {
                        value: 'Fee',
                        bold: true,
                      },
                      {
                        value: 'Excluded Investments',
                        bold: true,
                      },
                    ],
                    ...allSubjectsWithExclusions.map(({ subject, excluded }) => [
                      {
                        value: subject.name,
                      },
                      {
                        value: getBaseFee(subject),
                        percentage: true,
                      },
                      {
                        value: excluded.map(({ name }) => name).join(', '),
                      },
                    ]),
                  ]),
            ],
          },
        ];

        excelFunctions.forEach((dataFn) => {
          if (!dataFn.excelFunction) return;

          const data = dataFn.excelFunction();
          sheetsData.push({
            // Second cell is the sheet name
            sheetName: (data[0]![1]!.value as string) ?? `${getAppTitle()} Analysis`,
            data,
          });
        });

        logExport({
          exportTo: 'Excel',
          blockCount: sheetsData.length - 1,
          isSuccessful: true,
        });

        onExportMultiSheets(sheetsData, name, (wb, _) => {
          // TODO: (VENN-20577 / TYPES) There are types packages for xlsxPopulate that we could start using or fork.
          (wb as ExcelWorkbook).outputAsync().then((blob: Blob) => {
            saveAs(blob, name);
          });
        });
      },
    [logExport],
  );

  useEffect(() => {
    if (isExporting && blockExcelFunctions.every((func) => func !== undefined)) {
      onExcelExportDownload(compact(blockExcelFunctions));
      setIsExporting(false);
      Notifications.notifyUpdate(currentToastId, 'Downloaded excel data', NotificationType.SUCCESS);
    }
  }, [isExporting, blockExcelFunctions, currentToastId, onExcelExportDownload, setIsExporting]);

  const onExcelExport = useCallback(() => {
    const toastId = Notifications.notify('Downloading excel data', NotificationType.LOADING);
    setCurrentToastId(toastId);
    setIsExporting(true);
    // Rest all current excel functions to make sure we have the most up to date
    resetBlockExcelFunctions();
  }, [setIsExporting, resetBlockExcelFunctions]);

  return onExcelExport;
};
