import React, { useState, useEffect, useLayoutEffect, useContext, useRef, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import type { AnalysisViewSearchResult } from 'venn-api';
import { deleteAnalysisView, searchAnalysisView, scheduleExportForView } from 'venn-api';
import type { BasicTableColumn } from 'venn-components';
import {
  StickyNode,
  TypeaheadSearchBar,
  ConditionalOverlay,
  ExportHistoryModal,
  ConfirmationModal,
  SORTDIR,
  UserContext,
  useSavedViewListWithExtraName,
  StripedTable,
} from 'venn-components';
import type { ItemType } from 'venn-ui-kit';
import { GetColor, ZIndex, Pagination, Loading } from 'venn-ui-kit';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { useApi, useModal, analyticsService, logExceptionIntoSentry, scrollToElementByQuerySelector } from 'venn-utils';

import type { SearchState, ConfirmationType } from './types';
import { PAGE_SIZE, REPORTING_TABLE_CLASSNAME, defaultSearchState } from './constants';

import { useLibraryStateURLSynchronizer } from './utils/useLibraryStateURLSynchronizer';
import { search } from './utils/search';
import { useRecentSearches } from './utils/useRecentSearches';

import { getColumns, getRowStyles } from './columnsRenderer';
import TableAnnotation from './TableAnnotation';
import useQuickFilters from './QuickFilters';
import EmptyState from './EmptyState';
import ZeroSavedViewsEmptyState from './ZeroSavedViewsEmptyState';
import { SimpleFilter } from '../../home-page/src/components';
import { SavedViewDetails } from './SavedViewDetails';
import type * as H from 'history';

// Min height should not be smaller than the height of side nav to show all options
const MIN_HEIGHT = 500;

export const ReportingTable = withRouter(({ history }: RouteComponentProps) => {
  const userContext = useContext(UserContext);
  const pageLoaded = useRef(false);
  const initialLoading = useRef(true);
  const { queryParams, updateSearchParam, resetQueryParams, toggleParamItem } = useLibraryStateURLSynchronizer(
    history as H.History<{ shouldListenerIgnore?: boolean | undefined }>,
    pageLoaded,
  );
  const { page = 1, name = '', sortBy, order, filters = [], itemTypes = [] } = queryParams;
  const { recentSearches, saveRecentSearch } = useRecentSearches();
  const [searchState, setSearchState] = useState<SearchState>(defaultSearchState);
  const searchApi = useApi(searchAnalysisView);
  const fetchResults = useCallback(
    () =>
      search(
        name,
        page,
        filters,
        itemTypes,
        sortBy,
        order,
        setSearchState,
        searchApi,
        initialLoading,
        userContext.hasPermission,
      ),
    [name, page, filters, itemTypes, sortBy, order, setSearchState, searchApi, userContext.hasPermission],
  );
  const { loading, results, totalCount } = searchState;

  const { resultsWithTemplates } = useSavedViewListWithExtraName(results);

  const [selectedView, setSelectedView] = useState<AnalysisViewSearchResultWithUIState>();
  const [isHistoryModalOpen, openHistoryModal, closeHistoryModal] = useModal();
  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useModal();
  const [confirmationType, setConfirmationType] = useState<ConfirmationType>();

  const selectedFilters = [itemTypes, filters] as [ItemType[], string[]];

  useLayoutEffect(() => {
    if (!loading) {
      scrollToElementByQuerySelector(`.${REPORTING_TABLE_CLASSNAME}`, 0, null, 'auto');
    }
  }, [loading]);

  useEffect(() => {
    if (!pageLoaded.current) {
      return;
    }

    fetchResults();
  }, [fetchResults]);

  const onPageChange = useCallback(
    (index: number) => {
      updateSearchParam('page', index.toString());
    },
    [updateSearchParam],
  );

  const handleSearchFilter = useCallback(
    (searchQuery: string) => {
      setSearchState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      updateSearchParam('name', searchQuery);
    },
    [updateSearchParam],
  );

  const handleApplyFilters = useCallback(
    (key: string, value: string | string[]) => {
      setSearchState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      updateSearchParam(key, value);
    },
    [updateSearchParam],
  );

  const handleReset = useCallback(() => {
    setSearchState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    resetQueryParams();
  }, [resetQueryParams]);

  const onSort = useCallback((key: string, dir: SORTDIR) => updateSearchParam('sortBy', key, dir), [updateSearchParam]);

  const handleHistoryModalOpen = useCallback(
    (item: AnalysisViewSearchResultWithUIState) => {
      setSelectedView(item);
      openHistoryModal();
      analyticsService.ctaClicked({
        destination: undefined,
        text: String(item.numScheduledExport),
        purpose: 'Open export history modal',
        type: 'link',
        filled: false,
        subjectId: item.subjectId,
        viewId: item.id,
      });
    },
    [openHistoryModal],
  );

  const handleAction = useCallback(
    (item: AnalysisViewSearchResultWithUIState, confirmationType: ConfirmationType) => {
      openConfirmationModal();
      setSelectedView(item);
      setConfirmationType(confirmationType);
    },
    [openConfirmationModal, setSelectedView],
  );

  const onDelete = useCallback(
    async (item: AnalysisViewSearchResult) => {
      try {
        analyticsService.ctaClicked({
          destination: undefined,
          filled: false,
          locationOnPage: 'Saved views table',
          purpose: 'Delete saved view',
          text: 'delete icon',
          type: 'button',
        });

        await deleteAnalysisView(item?.id);
        closeConfirmationModal();
        setConfirmationType(undefined);
        const deletedLastResultFromPage = results.length === 1 && totalCount > 1;
        const pageNumber = page > 1 && deletedLastResultFromPage ? page - 1 : page;

        if (pageNumber !== page) {
          onPageChange(pageNumber);
          return;
        }
        fetchResults();
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    },
    [closeConfirmationModal, fetchResults, onPageChange, page, results.length, totalCount],
  );

  const selectView = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>,
      item: AnalysisViewSearchResult,
    ) => {
      if (!e.currentTarget.contains(e.target as Node)) {
        return;
      }
      const destination = ['REPORT', 'REPORT_TEMPLATE'].includes(item.analysisViewType)
        ? 'Report Lab'
        : ['TEARSHEET', 'TEARSHEET_TEMPLATE'].includes(item.analysisViewType)
          ? 'studio page'
          : 'Analysis/Compare Results';

      analyticsService.navigationTriggered({
        destinationPageTitle: `${destination}`,
        itemType: 'link',
        location: 'Reporting table',
        userIntent: 'navigate',
      });
    },
    [],
  );

  const onCancel = useCallback(
    async (item: AnalysisViewSearchResult) => {
      try {
        analyticsService.ctaClicked({
          destination: undefined,
          filled: false,
          locationOnPage: 'Saved views table',
          purpose: 'Cancel scheuled exports',
          text: 'cancel icon',
          type: 'button',
        });
        await scheduleExportForView({
          scheduledExportId: item.scheduledId,
          scheduledFrequency: 'UNKNOWN',
          analysisViewId: item.id,
        });
        closeConfirmationModal();
        setConfirmationType(undefined);
        fetchResults();
      } catch (e) {
        logExceptionIntoSentry(e);
      }
    },
    [fetchResults, closeConfirmationModal],
  );

  const handleProceed = useCallback(() => {
    if (!selectedView) {
      return;
    }
    if (confirmationType === 'DELETE') {
      onDelete(selectedView);
      return;
    }
    onCancel(selectedView);
  }, [onDelete, selectedView, onCancel, confirmationType]);

  const { Colors } = useContext(ThemeContext);
  const showEmptyState = isEmpty(resultsWithTemplates) && !loading;
  const canClearQuery = Boolean(name);
  const canClearFilters = !isEmpty(filters);

  const zeroSavedViews = showEmptyState && !canClearQuery && !canClearFilters && !itemTypes.length;
  const { id: userId } = userContext?.profileSettings?.user || {};

  const sections = useQuickFilters();

  if (initialLoading.current) {
    return <Loading />;
  }

  const searchBar = pageLoaded.current && (
    <TypeaheadSearchBar
      placeholder="Search Views by Name"
      value={name}
      onChange={handleSearchFilter}
      onBlur={() => saveRecentSearch(name)}
      dropdownOptions={recentSearches}
      dropdownOptionsHeader="Recent Searches"
      debounce={300}
      disableAutofocus
    />
  );

  return zeroSavedViews ? (
    <ZeroSavedViewsEmptyState />
  ) : (
    <>
      <ContentWrapper className={REPORTING_TABLE_CLASSNAME}>
        <TableAnnotationContainer>
          <SearchAndFiltersContainer>
            <div style={{ width: 300, marginRight: 30, marginTop: 5 }}>{searchBar}</div>
            <SimpleFiltersContainer>
              {sections.map((section, index) => (
                <SimpleFilter
                  applyLabel="Select"
                  menuWidth={295}
                  closeOnOnlyClick
                  singleSelection={!section.checkbox}
                  key={section.key}
                  label={section.title}
                  initialSelected={selectedFilters[index]!}
                  items={section.items}
                  onFilter={(updated) => handleApplyFilters(section.key, updated)}
                />
              ))}
            </SimpleFiltersContainer>
          </SearchAndFiltersContainer>
          <ActionBar>
            <TableAnnotation
              results={results}
              fetchResults={fetchResults}
              onPageChange={onPageChange}
              totalCount={totalCount}
              page={page}
              filters={{ quickFilters: filters, itemTypes }}
              toggleParamItem={toggleParamItem}
              userId={userId}
              handleReset={handleReset}
            />
            <div>
              <Pagination
                pagesCount={Math.ceil(totalCount / PAGE_SIZE)}
                selectedPage={page}
                onPageChange={onPageChange}
              />
            </div>
          </ActionBar>
        </TableAnnotationContainer>
        <ConditionalOverlay condition={!!loading} className="qa-reporting-table">
          <StripedTable<BasicTableColumn<AnalysisViewSearchResultWithUIState>, AnalysisViewSearchResultWithUIState>
            columns={getColumns(
              setSearchState,
              handleAction,
              selectView,
              history,
              userId,
              undefined,
              undefined,
              userContext?.hasPermissionForResource,
            )}
            data={resultsWithTemplates}
            onSort={onSort}
            sortingIsExternal
            sortDir={order === 'desc' ? SORTDIR.DESC : SORTDIR.ASC}
            sortKey={sortBy}
            rowStyle={(item: AnalysisViewSearchResultWithUIState) => getRowStyles(item, Colors)}
            expandable
            expandableContent={(item: AnalysisViewSearchResultWithUIState) => (
              <SavedViewDetails
                item={item}
                onScheduledExportClick={(item) => {
                  handleHistoryModalOpen(item);
                }}
              />
            )}
          />
        </ConditionalOverlay>
        {showEmptyState ? (
          <EmptyState onClear={handleReset} canClearQuery={canClearQuery} canClearFilters={canClearFilters} />
        ) : null}
      </ContentWrapper>
      {isHistoryModalOpen && selectedView ? (
        <ExportHistoryModal viewId={selectedView.id} viewName={selectedView.name} onClose={closeHistoryModal} />
      ) : null}
      {isConfirmationModalOpen && selectedView ? (
        <ConfirmationModal
          destructive
          subhead={`Are you sure you want to ${
            confirmationType === 'DELETE' ? 'remove' : 'cancel scheduled exports for'
          } ${selectedView.name}?`}
          text={`You will no longer be able to ${
            confirmationType === 'DELETE' ? 'access' : 'receive scheduled exports for'
          } this saved view or any related export history.`}
          onCancel={closeConfirmationModal}
          onProceed={handleProceed}
        />
      ) : null}
    </>
  );
});

const ContentWrapper = styled.div`
  min-height: ${MIN_HEIGHT}px;
`;

const TableAnnotationContainer = styled(StickyNode)`
  display: flex;
  flex-direction: column;
  background: ${GetColor.White};
  z-index: ${ZIndex.StickyFront};
  border-bottom: 1px solid ${GetColor.Grey};
`;

const SearchAndFiltersContainer = styled.div`
  padding-top: 27px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SimpleFiltersContainer = styled.div`
  margin-right: 51px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 10px;
`;
