import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import noop from 'lodash/noop';
import PortfolioNamesTooltip from '../factor-chart/tooltips/PortfolioNamesTooltip';
import ViewPortRenderer from './ViewPortRenderer';
import type { BackgroundClickEvent } from './charts/summary-chart/Background';
import SummaryChart from './charts/summary-chart/SummaryChart';
import BarsChartRowsProvider from './charts/BarsChartRowsProvider';
import WaterfallChartRowsProvider from './charts/WaterfallChartRowsProvider';
import { getLabels } from '../factor-chart';
import Legend from './charts/legend/Legend';
import { getLegendSeries } from './charts/legend/utils';
import type { FactorSummaryChartData } from './factorSummaryTypes';
import {
  WIDE_VALUE_COLUMN_OFFSET,
  LEGEND_LINE_HEIGHT,
  LEGEND_TOP,
  FACTOR_SUMMARY_CHART_TRANSFORM,
  FACTOR_SUMMARY_CHART_NARROW_TRANSFORM,
} from './charts/constants';
import { getSvgHeight } from './charts/utils';
import type { FactorDescription } from '../factor-chart/tooltips/FactorSummaryTooltips';
import FactorTooltips from '../factor-chart/tooltips/FactorSummaryTooltips';
import FactorChartSvg from './charts/FactorChartSvg';
import type { TrackAnalysisProps } from '../charts/chart-errors';
import { TrackSuccessfulAnalysis, TrackFailedAnalysis } from '../charts/chart-errors';
import EmptyState from '../empty-state/EmptyState';
import type { DataExportEvent } from 'venn-api';
import type { ExcelCell, AnalysisSubjectSecondaryLabel, AnalysisSubject } from 'venn-utils';
import { RISK_FREE_RATE_ID, RESIDUAL_ID } from '../factor-chart/constants';
import DownloadableContentBlock from '../content-block/DownloadableContentBlock';
import { toDownloadTrackingEvent } from '../downloadable/helper';
import compact from 'lodash/compact';
import type { DownloadMetaData } from '../downloadable';
import { useAppPrintMode } from '../print';

const Wrapper = styled.div`
  margin-bottom: 20px;
  svg {
    overflow: visible !important;
  }
  @media print {
    margin-bottom: 0px;
    margin-right: 0px;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
`;

const StyledEmptyState = styled(EmptyState)`
  margin: 20px;
`;

export interface FactorSummaryChartProps {
  activeFactorId?: number | undefined;
  data: FactorSummaryChartData;
  defaultExtreme?: number;
  factorsDescription?: FactorDescription[];
  onClick?: (event: BackgroundClickEvent) => void;
  percentage?: boolean;
  subTitle?: string | React.ReactNode;
  title: string;
  transform?: number;
  updateActiveFactorId?: (activeFactorId: number) => void;
  waterfall?: boolean;
  width: number;
  relative?: boolean;
  categoryActive: boolean;
  isFund?: boolean;
  filterCount?: number;
  chartTitleContainsPortfolioName?: boolean;
  dataExportEvent: DataExportEvent;
  exportData?: ExcelCell[][];
  secondaryLabel: AnalysisSubjectSecondaryLabel;
  metricType?: 'risk' | 'return';
  trackingProps: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
  analysisSubject: AnalysisSubject;
}

const FactorSummaryChart = ({
  data,
  percentage,
  defaultExtreme,
  factorsDescription,
  title,
  subTitle,
  waterfall,
  width,
  onClick,
  updateActiveFactorId,
  activeFactorId,
  relative,
  categoryActive,
  isFund,
  filterCount,
  chartTitleContainsPortfolioName,
  dataExportEvent,
  exportData,
  secondaryLabel,
  metricType,
  transform,
  trackingProps,
  downloadMetaData,
  analysisSubject,
}: FactorSummaryChartProps) => {
  const { Colors } = useContext(ThemeContext);
  const legendSeries = getLegendSeries(data, Colors);
  // If we only have one line's legend, we will show narrow gap
  const adjustTransform =
    legendSeries[1]!.length > 0 ? FACTOR_SUMMARY_CHART_TRANSFORM : FACTOR_SUMMARY_CHART_NARROW_TRANSFORM;
  const adjustedTransform = transform || adjustTransform;
  const chartTransform = `translate(0, ${adjustedTransform})`;
  const filename = chartTitleContainsPortfolioName ? title : `${title} - ${data.mainDataSetName}`;

  const { inPrintMode } = useAppPrintMode();

  return (
    <ViewPortRenderer
      width={width}
      print={inPrintMode}
      hideBaselineColumn={data.baselineDataSetName === undefined}
      hideComparisonColumn={data.comparisonDataSetName === undefined}
      hideCategoryColumn={data.categoryDataSetName === undefined}
      render={(viewPort) => {
        const showPrintStyle = inPrintMode;
        const footerHeight = 0;
        const chartHeight = getSvgHeight(viewPort, data.rows.length, inPrintMode, adjustTransform, filterCount);
        const trackedSvgChart = data.rows.length ? (
          <TrackSuccessfulAnalysis {...trackingProps}>
            <FactorChartSvg viewPort={viewPort} footerHeight={footerHeight} chartHeight={chartHeight}>
              {legendSeries && <Legend print={inPrintMode} legendSeries={legendSeries} y={LEGEND_TOP} />}
              <g transform={chartTransform}>
                <SummaryChart
                  ChartRowProvider={waterfall ? WaterfallChartRowsProvider : BarsChartRowsProvider}
                  mainDataSetName={data.mainDataSetName}
                  baselineDataSetName={data.baselineDataSetName}
                  comparisonDataSetName={data.comparisonDataSetName}
                  categoryDataSetName={data.categoryDataSetName}
                  percentage={!!percentage}
                  factors={data.rows}
                  activeFactorId={activeFactorId}
                  updateActiveFactorId={updateActiveFactorId}
                  breakdownAvailable={onClick !== undefined}
                  viewPort={viewPort}
                  onClick={onClick || noop}
                  onDismissPopup={noop}
                  defaultExtreme={defaultExtreme}
                  relative={relative}
                  categoryActive={categoryActive}
                  isFund={isFund}
                  transform={adjustedTransform}
                  filterCount={filterCount}
                  showPrintStyle={showPrintStyle}
                  secondaryLabel={secondaryLabel}
                  analysisSubject={analysisSubject}
                />
              </g>
            </FactorChartSvg>
          </TrackSuccessfulAnalysis>
        ) : (
          <TrackFailedAnalysis {...trackingProps}>
            <StyledEmptyState header="No factors show significant results." />
          </TrackFailedAnalysis>
        );

        const dataLabels = getLabels(
          data.mainDataSetName,
          data.baselineDataSetName,
          data.comparisonDataSetName,
          data.categoryDataSetName,
          categoryActive,
          relative,
          isFund,
          undefined,
          secondaryLabel,
          analysisSubject.secondaryPortfolio?.updated,
        );
        const portfolioNamesTooltipsLeftOffset = viewPort.width - WIDE_VALUE_COLUMN_OFFSET * dataLabels.length;
        const topOffset = adjustedTransform - LEGEND_LINE_HEIGHT * 2 - 5;
        return (
          <Wrapper>
            <DownloadableContentBlock
              header={title}
              subHeader={subTitle}
              downloadable={{
                png: true,
                excel: exportData,
                options: {
                  fileName: filename,
                  metaData: downloadMetaData,
                },
                tracking: toDownloadTrackingEvent(dataExportEvent),
              }}
            >
              <TooltipContainer>
                <FactorTooltips
                  topOffset={adjustedTransform}
                  itemHeight={viewPort.rowHeight}
                  series={data.rows}
                  itemWidth={undefined}
                  descriptions={compact([
                    ...(factorsDescription ?? []),
                    metricType === 'return'
                      ? {
                          id: RISK_FREE_RATE_ID,
                          description: 'Represents the return of short-term sovereign bond yields.',
                        }
                      : undefined,
                    metricType
                      ? {
                          id: RESIDUAL_ID,
                          description: `Represents the ${metricType} not explained by the Two Sigma Factor Lens.`,
                        }
                      : undefined,
                  ])}
                />
                {dataLabels.length > 0 && (
                  <PortfolioNamesTooltip
                    labels={dataLabels}
                    columnWidth={WIDE_VALUE_COLUMN_OFFSET}
                    topOffset={topOffset}
                    leftOffset={portfolioNamesTooltipsLeftOffset}
                    isFund={isFund}
                  />
                )}
              </TooltipContainer>
              {trackedSvgChart}
            </DownloadableContentBlock>
          </Wrapper>
        );
      }}
    />
  );
};

export default FactorSummaryChart;
