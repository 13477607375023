import React, { useState, useContext, useCallback, useMemo } from 'react';
import type { FactorChartsProps, FactorAnalysisGroup } from './types';
import { EXPOSURE_BLOCK, RISK_BLOCK, RETURN_BLOCK } from './types';
import FactorTrendChart from './FactorTrendChart';
import { getFactorChartTimeStamps, parseTrendResults, getTargetBlocksResult } from './utils';
import type { AnalysisSubjectType } from 'venn-utils';
import {
  FACTOR_EXPOSURE_HREF,
  FACTOR_CONTRIBUTION_TO_RISK_HREF,
  FACTOR_CONTRIBUTION_TO_RETURN_HREF,
} from 'venn-ui-kit';
import {
  generateExportEvent,
  logMessageToSentry,
  getAnalysisRequest,
  analyticsService,
  AnalyticsUtils,
  useApi,
} from 'venn-utils';
import FailedAnalysisInfo from '../../chart-errors/FailedAnalysisInfo';
import { TrackSuccessfulAnalysis } from '../../chart-errors/TrackAnalysis';
import type { AnalysisTypeEnum } from 'venn-api';
import { analysis } from 'venn-api';
import { AnalysisViewContext, FactorAnalysisContext } from '../../../contexts';
import useTrendExportMetadata from './useTrendExportMetadata';

const FactorTrendCharts = ({
  analysisGroup,
  width,
  analysesPeriod,
  selectedTimeFrame,
  onResetTimeFrame,
  selectedPeriod,
  block,
  onPrintUpdate,
  trackingProps,
  isPrint,
}: FactorChartsProps) => {
  const [analysisResults, setAnalysisResults] = useState(getTargetBlocksResult(analysisGroup, block));
  const [chartReloading, setChartReloading] = useState<AnalysisTypeEnum[]>([]);
  const getAnalysis = useApi(analysis);
  const { onUpdateAnalysisViewParam, trendChartModes } = useContext(AnalysisViewContext);
  const { analysisSubject, relative, categoryActive, activeFactorLens } = useContext(FactorAnalysisContext);

  const investmentType = analysisSubject.type === 'portfolio' ? 'PORTFOLIO' : 'INVESTMENT';

  const [chartModes, setChartModes] = useState(trendChartModes);

  const handleSetChartMode = (chartModeBlock: AnalysisTypeEnum, mode: string) => {
    const updatedChartModes = {
      ...chartModes,
      [chartModeBlock]: mode,
    };
    setChartModes(updatedChartModes);
    onUpdateAnalysisViewParam({ trendChartModes: updatedChartModes });
  };

  const fetchTrendAnalysis = useCallback(
    async (rollingYears: number | undefined, trendBlocks: AnalysisTypeEnum[]) => {
      if (!analysisSubject) {
        logMessageToSentry('Failed to fetch summary analysis for missing subject');
        return;
      }
      setChartReloading(trendBlocks);
      const configs = getAnalysisRequest(
        trendBlocks,
        analysisSubject,
        selectedTimeFrame,
        relative,
        categoryActive,
        selectedPeriod,
        trackingProps.trackingId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        rollingYears,
        {
          rollingFactorExposuresPeriod: trendBlocks.includes(EXPOSURE_BLOCK) ? rollingYears : undefined,
          rollingFactorRiskPeriod: trendBlocks.includes(RISK_BLOCK) ? rollingYears : undefined,
          rollingFactorReturnPeriod: trendBlocks.includes(RETURN_BLOCK) ? rollingYears : undefined,
        },
      );

      try {
        const { content } = await getAnalysis(configs);
        const { updatedTrend, updatedView } = parseTrendResults(content.analyses, rollingYears);
        setAnalysisResults((prev) => {
          const updatedAnalysisResults = { ...prev, ...updatedTrend };
          if (onPrintUpdate) {
            onPrintUpdate(updatedAnalysisResults);
          }
          return updatedAnalysisResults;
        });
        setChartReloading([]);
        onUpdateAnalysisViewParam(updatedView);
        const start = content?.startTime ?? content?.maxStartTime;
        const end = content?.endTime ?? content?.maxEndTime;

        if (rollingYears) {
          trendBlocks.forEach((trendBlock) => {
            analyticsService.chartModified({
              objectType: investmentType,
              chart: trendBlock.replace(/_/g, ' ').toLowerCase(),
              rollingPeriod: rollingYears.toString(),
              objectName: analysisSubject.name,
              dateRange: `${AnalyticsUtils.dateFormat(start)}-${AnalyticsUtils.dateFormat(end)}`,
            });
          });
        }
      } catch (e) {
        if (e.name !== 'AbortError') {
          setChartReloading([]);
        }
      }
    },
    [
      analysisSubject,
      getAnalysis,
      investmentType,
      onUpdateAnalysisViewParam,
      selectedTimeFrame,
      selectedPeriod,
      onPrintUpdate,
      categoryActive,
      relative,
      trackingProps.trackingId,
    ],
  );

  const onResetRollingPeriod = useCallback(() => {
    fetchTrendAnalysis(undefined, [EXPOSURE_BLOCK, RISK_BLOCK, RETURN_BLOCK]);
  }, [fetchTrendAnalysis]);

  const onUpdateRollingPeriod = useCallback(
    (rollingYears: number | undefined, trendBlock: AnalysisTypeEnum) => {
      fetchTrendAnalysis(rollingYears, [trendBlock]);
    },
    [fetchTrendAnalysis],
  );

  const { rolling, factorTrendTitle, regressionYears, regressionYearsOptions } = useMemo(
    () => parseAnalysisTrendResults(analysisResults, analysisSubject.type),
    [analysisResults, analysisSubject.type],
  );

  useTrendExportMetadata(regressionYears.exposure, regressionYears.return, regressionYears.risk, isPrint);
  if (!analysisResults) {
    return null;
  }

  if (
    !analysisResults?.risk?.factorContributionToRiskTrend?.[0]?.length &&
    !analysisResults?.return?.factorContributionToReturnTrend?.[0]?.length &&
    !analysisResults?.exposure?.factorExposuresTrend?.[0]?.length
  ) {
    const { trackingId, updateAnalysisStatusForTracking, blockNames, blockTitles } = trackingProps;
    return (
      <FailedAnalysisInfo
        subject={analysisSubject}
        analysesPeriod={analysesPeriod}
        onResetAnalysisPeriod={onResetTimeFrame}
        error={{
          message: analysisResults?.risk?.message?.text || 'No factors show significant results.',
          code: analysisResults?.risk?.message?.code,
        }}
        regressionName="Factor Trend"
        isFactorTrend
        relativeToBenchmark={relative}
        categoryActive={categoryActive}
        blockNames={blockNames}
        blockTitles={blockTitles}
        updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
        trackingId={trackingId}
        onResetRollingPeriod={onResetRollingPeriod}
      />
    );
  }

  const sharedProps = {
    activeFactorLens,
    width,
    chartReloading,
    onUpdateRollingPeriod,
    onResetAnalysisPeriod: onResetTimeFrame,
  };

  const userUploaded = !!analysisSubject?.fund?.userUploaded;

  return (
    <TrackSuccessfulAnalysis {...trackingProps}>
      {analysisResults?.exposure && (!block || block === 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND') ? (
        <FactorTrendChart
          type="exposure"
          analysisKey="factorExposuresTrend"
          block={EXPOSURE_BLOCK}
          factorTrendTitle={factorTrendTitle.exposure}
          analysis={analysisResults.exposure}
          multiplier={1}
          regressionYears={regressionYears.exposure}
          regressionYearsOptions={regressionYearsOptions.exposure}
          timestamps={getFactorChartTimeStamps(rolling.exposure)}
          isPercentage={false}
          topLegendFormatter={(value) => numberFormatter.format(value ?? 0)}
          {...sharedProps}
          dataExportEvent={generateExportEvent(
            investmentType,
            analysisSubject.id,
            EXPOSURE_BLOCK,
            userUploaded,
            relative,
          )}
          chartMode={chartModes[EXPOSURE_BLOCK]}
          setChartMode={handleSetChartMode}
          tooltipLink={FACTOR_EXPOSURE_HREF}
        />
      ) : null}
      {analysisResults?.risk && (!block || block === RISK_BLOCK) ? (
        <FactorTrendChart
          type="risk"
          factorTrendTitle={factorTrendTitle.risk}
          analysis={analysisResults.risk}
          multiplier={100}
          block={RISK_BLOCK}
          timestamps={getFactorChartTimeStamps(rolling.risk)}
          analysisKey="factorContributionToRiskTrend"
          residualKey="residualRisk"
          regressionYears={regressionYears.risk}
          regressionYearsOptions={regressionYearsOptions.risk}
          isPercentage
          {...sharedProps}
          dataExportEvent={generateExportEvent(investmentType, analysisSubject.id, RISK_BLOCK, userUploaded, relative)}
          chartMode={chartModes[RISK_BLOCK]}
          setChartMode={handleSetChartMode}
          tooltipLink={FACTOR_CONTRIBUTION_TO_RISK_HREF}
        />
      ) : null}
      {analysisResults?.return && (!block || block === RETURN_BLOCK) ? (
        <FactorTrendChart
          type="return"
          analysisKey="factorContributionToReturnTrend"
          factorTrendTitle={factorTrendTitle.return}
          analysis={analysisResults.return}
          block={RETURN_BLOCK}
          multiplier={100}
          timestamps={getFactorChartTimeStamps(rolling.return)}
          residualKey="periodResidualReturn"
          regressionYears={regressionYears.return}
          regressionYearsOptions={regressionYearsOptions.return}
          hasTotalRow
          hasRiskFreeRate={!relative}
          isPercentage
          {...sharedProps}
          dataExportEvent={generateExportEvent(
            investmentType,
            analysisSubject.id,
            RETURN_BLOCK,
            userUploaded,
            relative,
          )}
          chartMode={chartModes[RETURN_BLOCK]}
          setChartMode={handleSetChartMode}
          tooltipLink={FACTOR_CONTRIBUTION_TO_RETURN_HREF}
        />
      ) : null}
    </TrackSuccessfulAnalysis>
  );
};

export default FactorTrendCharts;

const numberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const parseAnalysisTrendResults = (analysisResults: FactorAnalysisGroup | undefined, type: AnalysisSubjectType) => {
  const rolling = {
    risk: analysisResults?.risk?.factorContributionToRiskTrend?.[0],
    return: analysisResults?.return?.factorContributionToReturnTrend?.[0],
    exposure: analysisResults?.exposure?.factorExposuresTrend?.[0],
  };
  const analysisAnnualized = analysisResults?.return?.factorContributionToReturnTrend?.[0]?.[0]?.periodAnnualized;

  const regressionYears = {
    risk: analysisResults?.risk?.factorContributionToRiskTrend?.[0]?.[0]?.regressionYears,
    return: analysisResults?.return?.factorContributionToReturnTrend?.[0]?.[0]?.regressionYears,
    exposure: analysisResults?.exposure?.factorExposuresTrend?.[0]?.[0]?.regressionYears,
  };

  const regressionYearsOptions = {
    risk: analysisResults?.risk?.factorContributionToRiskTrend?.[0]?.[0]?.regressionYearsOptions,
    return: analysisResults?.return?.factorContributionToReturnTrend?.[0]?.[0]?.regressionYearsOptions,
    exposure: analysisResults?.exposure?.factorExposuresTrend?.[0]?.[0]?.regressionYearsOptions,
  };

  const returnType = analysisAnnualized ? 'annualized' : 'cumulative';
  const factorTrendTitle = {
    risk: {
      title: 'Factor Contributions to Risk',
      subtitle: 'What percent of total risk is driven by each factor over time?',
      activeTitle: 'Active Factor Contributions to Risk',
    },
    return: {
      title: 'Factor Contributions to Return',
      subtitle: `What amount of ${returnType} return is driven by each factor?`,
      activeTitle: 'Active Factor Contributions to Return',
    },
    exposure: {
      title: 'Factor Exposures (β)',
      subtitle: `What factors is this ${type} exposed to over time?`,
      activeTitle: 'Active Factor Exposures (β)',
    },
  };
  return {
    rolling,
    factorTrendTitle,
    regressionYears,
    regressionYearsOptions,
  };
};
